import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette, typography }) => ({
  tab: {
    textTransform: 'none',
    padding: spacing(16),
    width: '100%',
    ...typography.h5,
  },
  selected: {
    opacity: 0,
  },
  selectedTab: {
    borderBottom: `3px solid ${palette.primary.main}`,
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      flexWrap: 'noWrap',
      overflowX: 'auto',
      // scrollbar: {
      '&::-webkit-scrollbar': {
        height: '10px',
      },

      '&::-webkit-scrollbar-track': {
        borderRadius: '100vh',
        background: '#f7f4ed',
      },

      '&::-webkit-scrollbar-thumb': {
        background: 'white',
        borderRadius: '100vh',
        border: '3px solid #f6f7ed',
      },

      '&::-webkit-scrollbar-thumb:hover': {
        background: '#1b66ff',
      },
      // },
      // gridTemplateColumns: props =>
      //   `repeat(${props.isDuplicated && props.size > 1 ? props.size + 1 : props.size}, auto)`,
    },
  },
}))
