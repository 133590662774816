import moment from 'moment'
import { Trash } from '@refera/ui-icons'
import { Tooltip } from '@material-ui/core'
import IconButton from '_components/svg/icon-button'
import React from 'react'

export const ProceduresColumns = (styles, handleRemoveProcedure, lastProcedure) => [
  {
    field: 'createdAt',
    headerName: 'Criado em',
    width: 130,
    editable: false,
    headerClassName: styles.grayColumn,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'userName',
    headerName: 'Usuário',
    width: 150,
    editable: false,
    headerClassName: styles.grayColumn,
  },
  {
    field: 'message',
    headerName: 'Mensagem',
    flex: 1,
    editable: false,
    headerClassName: styles.grayColumn,
  },
  {
    field: 'actions',
    headerName: 'Ações',
    width: 150,
    editable: false,
    headerClassName: styles.grayColumn,
    renderCell: ({ row }) =>
      row?.id === lastProcedure?.id && (
        <Tooltip title="Excluir trâmite">
          <IconButton variant="ghost" onClick={event => handleRemoveProcedure(event, row)}>
            <Trash color="gray" />
          </IconButton>
        </Tooltip>
      ),
  },
]
