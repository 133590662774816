import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  media: {
    height: spacing(80),
    width: spacing(80),
    borderRadius: spacing(4),
    padding: spacing(4),
    display: 'flex',

    '&:hover': {
      filter: 'brightness(80%)',
      cursor: 'pointer',
    },
  },
  mediaSVG: {
    height: spacing(80),
    width: spacing(80),
    borderRadius: spacing(4),
    padding: spacing(4),
    display: 'flex',
    backgroundColor: 'lightgray',
    transition: '0.2s all',
    position: 'relative',

    '&:hover': {
      backgroundColor: 'gray',
      cursor: 'pointer',
    },
  },
  closeIcon: {
    height: spacing(20),
    width: spacing(20),
  },
  svgIcon: {
    top: '50%',
    right: '50%',
    transform: 'translate(50%,-50%)',
    width: '70%',
    height: '70%',
    position: 'absolute',
  },
  svgFileType: {
    bottom: '2%',
    right: '5%',
    fontSize: '10px',
    fontWeight: '700',
    fontFamily: 'Roboto',
    color: 'white',
    position: 'absolute',
  },
  svgPDF: {
    top: '50%',
    right: '50%',
    transform: 'translate(45%,-50%)',
    width: '80%',
    height: '80%',
    position: 'absolute',
  },
  iconButton: {
    backgroundColor: 'white',
    height: spacing(16),
    width: spacing(16),
    padding: 0,
    marginLeft: 'auto',
    color: palette.gray[80],

    '&:hover': {
      backgroundColor: 'white',
    },
  },
}))
