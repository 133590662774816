import { useCallback, useEffect, useState } from 'react'

const INITIAL_STATE = {
  open: false,
  windowState: 'minimized',
}

const useMovideskChat = (initialState = INITIAL_STATE) => {
  const [movideskChat, setMovideskChat] = useState(initialState)

  const toggleMovideskChat = useCallback(() => {
    setMovideskChat(prevState => ({
      open: !prevState.open,
      windowState: !prevState.open ? 'maximized' : 'minimized',
    }))
  }, [initialState])

  useEffect(() => {
    window?.movideskChatWidgetChangeWindowState?.(movideskChat.windowState)
  }, [movideskChat.windowState])

  // Resets movideskChat's state when the page is refreshed
  useEffect(() => {
    const handleBeforeUnload = () => {
      setMovideskChat(initialState)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [initialState])

  return [toggleMovideskChat]
}

export default useMovideskChat
