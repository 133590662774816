/* eslint-disable react/jsx-no-bind */
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { ArrowRight3 } from '@refera/ui-icons'
import Theme from '@refera/ui-core'

import { formatDate } from '_utils/helpers'
import { Preview, Button } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'
import useStyles from './styles'
import { fetchPaidInstallments } from '_/modules/budget/actions'
import { useDispatch } from 'react-redux'

import InstallmentsModal from './components/installments-modal'

const ExecutionBudget = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const executionSchedule = serviceOrder?.datetimeExecutionScheduled
  const defaultPreviewState = {
    visible: false,
    selectedIndex: undefined,
  }
  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const [installments, setInstallments] = useState([])
  const [paidInstallments, setPaidInstallments] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const executionScheduleFormatted = executionSchedule
    ? formatDate(new Date(executionSchedule))
    : ''

  const executedAt = serviceOrder?.executedAt ? formatDate(new Date(serviceOrder?.executedAt)) : ''
  const budgetId = serviceOrder?.activeBudget

  const handleFetchPaidInstallments = async () => {
    await Promise.resolve(dispatch(fetchPaidInstallments(budgetId))).then(res => {
      setInstallments(res.installments)
      setPaidInstallments(res.paidInstallments)
    })
  }

  useEffect(() => {
    if (budgetId) {
      handleFetchPaidInstallments()
    }
  }, [budgetId])

  const [attachments, setAttachments] = useState(
    serviceOrder?.attachments
      ?.toArray()
      .filter(attachment => attachment.get('fileType') === 'execution_proof')
      .map(file => ({ file: file?.get('file'), id: file?.get('id') }))
      ?.reverse()
  )
  const [invoices] = useState(
    serviceOrder?.attachments
      ?.toArray()
      .filter(attachment => attachment.get('fileType') === 'invoice')
      .map(file => ({ file: file?.get('file'), id: file?.get('id') }))
      ?.reverse()
  )

  const handleDownloadInvoice = index => {
    window.open(invoices[index]?.file)
  }

  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(() => defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={
          attachments &&
          attachments.map((item, index) =>
            item.id
              ? {
                  id: item.id,
                  url: item.file,
                }
              : {
                  id: index,
                  url: URL.createObjectURL(item),
                }
          )
        }
      />
    ),
    [previewState, attachments]
  )

  const handleInstallmentsModal = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  return (
    <>
      <Grid className={styles.container}>
        {PreviewComponent}
        <Typography className={styles.containerTitle}>Execução do Serviço</Typography>
        <Grid className={styles.payerSection}>
          <Grid>
            <Typography className={styles.label}>Agendamento da execução</Typography>
            <Typography className={styles.value}>{executionScheduleFormatted || '-'}</Typography>
          </Grid>
          <Grid>
            <Typography className={styles.label}>Data do serviço executado</Typography>
            <Typography className={styles.value}>{executedAt || '-'}</Typography>
          </Grid>
          <Grid>
            <Typography className={styles.label}>Pagamentos do chamado</Typography>
            <Button
              variant="ghost"
              className={styles.link}
              onClick={() => handleInstallmentsModal()}
              endIcon={<ArrowRight3 color={Theme.Colors.Primary.Base} />}
            >
              Ver todas parcelas
            </Button>
          </Grid>
        </Grid>
        <Grid className={styles.picturesSection}>
          <Attachments
            label="Fotos do pós-obra"
            readOnly
            downloadable
            files={attachments}
            onDrop={files => setAttachments(old => [...old, ...files])}
            onItemClick={index => {
              setPreviewState(() => ({
                visible: true,
                selectedIndex: index,
              }))
            }}
          />
        </Grid>
        {invoices.length > 0 && (
          <Grid className={styles.invoice}>
            {invoices.map((invoice, index) => {
              return (
                <>
                  <Attachments
                    label="Nota fiscal"
                    downloadable
                    content="files"
                    readOnly
                    files={invoices}
                    onItemClick={() => handleDownloadInvoice(index)}
                  />
                </>
              )
            })}
          </Grid>
        )}
      </Grid>
      {modalOpen && (
        <InstallmentsModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          installments={installments}
          paidInstallments={paidInstallments}
        />
      )}
    </>
  )
}

export default React.memo(ExecutionBudget)
