import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  alertInfo: {
    padding: spacing(16, 72, 0),
  },
  alertTitle: {
    color: Theme.Colors.Grayscale.OneThousand,
    fontSize: Theme.Typography.FontSize.XSmall,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
  },
  reasonText: {
    color: Theme.Colors.Grayscale.SixtyFour,
    fontSize: Theme.Typography.FontSize.XSmall,
  },
  moreDetails: {
    padding: spacing(0),
    marginTop: spacing(7),
    background: 'none',
    border: 'none',
    color: Theme.Colors.Primary.Base,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: Theme.Typography.FontSize.XSmall,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    textDecoration: 'none',
  },
  groud: {
    backgroundColor: Theme.Colors.Yellow.Lightest,
  },
}))

export const alertSxStyle = {
  borderBottom: `4px solid ${Theme.Colors.Red.Base}`,
  borderEndEndRadius: 0,
  borderEndStartRadius: 0,
}
