/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Box,
} from '@material-ui/core'
import { Danger } from 'iconsax-react'
import { Check } from '@material-ui/icons'

import useStyles from './styles'

const DANGER = 'Danger'
const CHECK = 'Check'

export const WARNING_MODAL = { icon: 'Danger', title: 'Atenção!' }
export const SUCCESS_MODAL = { icon: 'Check', title: 'Sucesso!' }

const ModalDialog = ({ modalDialog, setModalDialog }) => {
  const classes = useStyles()

  const handleModal = () => {
    setModalDialog({ ...modalDialog, isOpen: false })
    if (modalDialog?.onClick) modalDialog?.onClick()
    if (modalDialog?.isClose) modalDialog?.isClose()
  }

  function GetIconButton() {
    if (modalDialog.type?.icon === DANGER) {
      return (
        <IconButton disableRipple className={classes.titleIconWarning}>
          <Danger className={classes.iconeSVG} />
        </IconButton>
      )
    }
    if (modalDialog.type?.icon === CHECK)
      return (
        <IconButton disableRipple className={classes.titleIconSuccess}>
          <Check className={classes.iconeSVG} />
        </IconButton>
      )
  }

  return (
    <Dialog open={modalDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Box mt={10} />
        <GetIconButton />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{modalDialog.type?.title}</Typography>
        <Box mt={20} />
        <Typography className={classes.subTitle} variant="">
          {modalDialog.subTitle}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleModal}
        >
          {modalDialog.textOkButton ? modalDialog.textOkButton : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalDialog
