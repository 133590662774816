import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

// TODO: Remove !important once refera lib support override this class
export default makeStyles(({ spacing }) => ({
  form: {
    maxWidth: spacing(728),
    maxHeight: spacing(410),
    width: '100%',
    height: '100%',
  },
  textarea: {
    fontSize: `${Theme.Typography.FontSize.XXSmall}px !important`,
  },
  textareaLabel: {
    fontSize: `${Theme.Typography.FontSize.XXSmall}px !important`,
    zIndex: `0 !important`,
  },
}))
