import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  dialog: {
    position: 'absolute',
    width: '504px',
    height: '330px',
  },
  button: {
    minWidth: '100px',
    borderRadius: '24px',
    height: '40px',
    padding: '8px 16px',
    textTransform: 'none',
    marginBottom: '15px',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    overflow: 'hidden',
  },
  subTitle: {
    fontSize: '16px',
  },
  dialogAction: {
    justifyContent: 'center',
  },
  titleIconWarning: {
    marginBottom: '-16px',
    backgroundColor: '#FEFAEB',
    color: '#F2BD00',
    fontSize: '6rem',
    '& .MuiSvgIcon-root': {
      fontSize: '6rem',
    },
  },
  titleIconSuccess: {
    marginBottom: '-16px',
    backgroundColor: '#EBF6EB',
    color: '#008908',
    fontSize: '6rem',
    '& .MuiSvgIcon-root': {
      fontSize: '6rem',
    },
  },
  iconeSVG: {
    width: '50px',
    height: '50px',
  },
}))
