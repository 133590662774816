import React, { useMemo, useReducer, useCallback, useEffect } from 'react'
import { Grid, Link } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useParams } from '@reach/router'

import Snackbar from '_components/snackbar'
import Button from '_components/button'
import AddUserModal from '_/components/accordion/add-users/add-user-modal'
import useModal from '_hooks/use-toggle'
import {
  addAgencyStaffUser,
  updateAgencyStaffUser,
  deleteAgencyStaffUser,
  ADD_AGENCY_STAFF_USER,
  UPDATE_AGENCY_STAFF_USER,
} from '_modules/agency/actions'
import {
  addProviderStaffUser,
  updateProviderStaffUser,
  deleteProviderStaffUser,
  ADD_PROVIDER_STAFF_USER,
  UPDATE_PROVIDER_STAFF_USER,
} from '_modules/provider/actions'
import useFetchCall from '_hooks/use-fetch-call'
import StaffUser from '_models/staff-user'

import Accordion from '../index'

import useStyles from './styles'
import UserInfo from './user-info'
import {
  reducer,
  INITIAL_STATE,
  ADD_USER,
  HANDLE_CURRENT_USER,
  HANDLE_CURRENT_USER_BLUR,
  SET_CURRENT_USER,
  UPDATE_USER,
  DELETE_USER,
} from './reducer'
import { AGENCY_FIELDS } from '_/views/agency/constants'

const AddUsers = ({ isProvider, staffUsers, isCreating, setFieldValue, ...otherProps }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [modalOpen, handleModal] = useModal()
  const [values, dispatchLocal] = useReducer(reducer, INITIAL_STATE)
  const [isEditingProvider, handleIsEditing] = useModal()
  const addUser = isProvider ? addProviderStaffUser : addAgencyStaffUser
  const updateUser = isProvider ? updateProviderStaffUser : updateAgencyStaffUser
  const deleteUser = isProvider ? deleteProviderStaffUser : deleteAgencyStaffUser
  const addUserAction = isProvider ? ADD_PROVIDER_STAFF_USER.ACTION : ADD_AGENCY_STAFF_USER.ACTION
  const updateUserAction = isProvider
    ? UPDATE_PROVIDER_STAFF_USER.ACTION
    : UPDATE_AGENCY_STAFF_USER.ACTION
  const url = useParams()

  const handleInputChange = useCallback(event => {
    const { value, name } = event.target
    dispatchLocal({
      type: HANDLE_CURRENT_USER,
      payload: { values: { value, name } },
    })
  }, [])

  const handleBlur = useCallback(event => {
    const { value, name } = event.target
    dispatchLocal({
      type: HANDLE_CURRENT_USER_BLUR,
      payload: { value, name },
    })
  }, [])

  const formHandleValues = useMemo(
    () => ({
      onChange: handleInputChange,
      onBlur: handleBlur,
      errors: values.error,
      touched: values.touched,
      values: values.currentUser,
    }),
    [handleBlur, handleInputChange, values]
  )

  const handleSuccessModals = useCallback(() => {
    handleIsEditing()
    handleModal()
  }, [handleIsEditing, handleModal])

  const handleSubmit = useCallback(
    value => {
      if (isCreating) {
        dispatchLocal({
          type: !isEditingProvider ? ADD_USER : UPDATE_USER,
          payload: values,
        })
        handleSuccessModals()
        return
      }
      if (!value?.id) {
        dispatch(addUser(value))
        return
      }
      // eslint-disable-next-line no-unused-vars
      const { getServiceProviderId, registrationConfirmed, termsAccepted, ...payload } = value
      dispatch(updateUser(payload))
    },
    [addUser, dispatch, handleSuccessModals, isCreating, isEditingProvider, updateUser, values]
  )

  const [isAddingUser] = useFetchCall(addUserAction, handleSuccessModals)
  const [isUpdatingUser] = useFetchCall(updateUserAction, handleSuccessModals)
  const isLoading = isAddingUser || isUpdatingUser

  const handleSelectedUser = useCallback(
    (user, index) => {
      dispatchLocal({
        type: SET_CURRENT_USER,
        payload: { user: user.toJS(), index },
      })
      handleIsEditing()
      handleModal()
    },
    [handleIsEditing, handleModal]
  )

  // const handleAddUser = useCallback(() => {
  //   handleModal()
  //   dispatchLocal({
  //     type: SET_INITIAL_STATE,
  //   })
  // }, [handleModal])

  const handleDeleteUser = useCallback(
    user => {
      if (isCreating) {
        dispatchLocal({
          type: DELETE_USER,
          payload: { user },
        })
        setFieldValue(AGENCY_FIELDS.STAFF_USERS, values.users)
        return
      }
      dispatch(deleteUser(user.id))
    },
    [deleteUser, dispatch, isCreating, setFieldValue, values.users]
  )

  useEffect(() => {
    if (isCreating && values.users) {
      setFieldValue(AGENCY_FIELDS.STAFF_USERS, values.users)
    }
  }, [isCreating, setFieldValue, values.users])

  return (
    <Accordion
      id="usuarios-cadastrados"
      title="Usuários cadastrados"
      className={styles.container}
      {...otherProps}
    >
      <Grid className={styles.userList}>
        {isCreating &&
          values.users?.map((user, index) => (
            <UserInfo
              key={user.fullName}
              user={user}
              index={index}
              handleDeleteUser={handleDeleteUser}
              handleSelectedUser={handleSelectedUser}
              isProvider={isProvider}
            />
          ))}
        {staffUsers?.map(user => (
          <UserInfo
            key={user.id}
            user={user}
            index={user.id}
            handleDeleteUser={handleDeleteUser}
            handleSelectedUser={handleSelectedUser}
            isProvider={isProvider}
          />
        ))}
      </Grid>
      <Link
        key="create-user-button"
        to={`/imobiliaria/${url.agencyId}/criar-usuario`}
        component={RouterLink}
      >
        <Button className={styles.button} variant="contained" color="primary">
          Criar usuário
        </Button>
      </Link>
      {modalOpen && (
        <AddUserModal
          modalOpen={modalOpen}
          handleModal={handleModal}
          handleSubmit={handleSubmit}
          disabledSubmit={Object.values(values.error).length > 0}
          isProvider={isProvider}
          isLoading={isLoading}
          {...formHandleValues}
        />
      )}
      <Snackbar action={[addUserAction, updateUserAction]} />
    </Accordion>
  )
}

AddUsers.propTypes = {
  isProvider: PropTypes.bool,
  isCreating: PropTypes.bool,
  staffUsers: PropTypes.arrayOf(PropTypes.instanceOf(StaffUser)),
  setFieldValue: PropTypes.func,
}

AddUsers.defaultProps = {
  isProvider: false,
  isCreating: false,
  staffUsers: [],
  setFieldValue: () => {},
}

export default React.memo(AddUsers)
