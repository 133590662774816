import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  mainTable: {
    width: 'auto',
    height: '616px',
  },
}))

export const datagridStyle = {
  fontSize: Theme.Typography.FontSize.XXSmall,

  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
}
