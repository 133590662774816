import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core'

import useStyles from './styles'

const SaveChangesDialog = ({ isOpen, onClose }) => {
  const styles = useStyles()

  return (
    <Dialog open={isOpen} onClose={onClose} aria-describedby="saveChangesDialog">
      <DialogContent>
        <DialogContentText id="saveChangesDialog" className={styles.description}>
          Salve antes as suas alterações no registro.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

SaveChangesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(SaveChangesDialog)
