/* eslint-disable no-param-reassign */
import React, { useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeItem, TreeView } from '@material-ui/lab'
import { Input } from '@mui/material'

import useStyles from './styles'

const CustomTreeItem = ({ data, handleClick = () => {} }) => {
  return (
    <TreeItem
      key={data.id}
      nodeId={data.id.toString()}
      onClick={() => handleClick(data)}
      label={data.name}
    >
      {data.children &&
        data.children.map(child => (
          <CustomTreeItem key={child.id} data={child} handleClick={handleClick} />
        ))}
    </TreeItem>
  )
}

const DynamicTreeView = ({ data, handleClick = () => {} }) => {
  const styles = useStyles()
  const [inputValue, setInputValue] = useState('')
  const timerRef = useRef(null) // debounce timer

  const buildTree = (items, parentId = null) => {
    const tree = []
    items.forEach(item => {
      if (item.parent === parentId) {
        const children = buildTree(items, item.id)
        if (children.length) {
          item.children = children
        }
        tree.push(item)
      }
    })
    return tree
  }

  const treeData = useMemo(() => {
    return buildTree(data)
  }, [data])

  const handleSearch = async e => {
    const { value } = e.target

    // Clear the previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    // Set a new timer
    timerRef.current = setTimeout(() => {
      setInputValue(value)
    }, 200)
  }

  const filteredValues = useMemo(() => {
    return treeData.filter(item => {
      if (inputValue === '') return true
      if (item.name.toLowerCase().includes(inputValue.toLowerCase())) return true
      return false
    })
  }, [inputValue, treeData])

  return (
    <Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300 }}>
      <Input
        placeholder="Pesquisar"
        className={styles.searchInput}
        inputProps={{ className: styles.inputStyles }}
        onChange={handleSearch}
        fullWidth
      />

      <TreeView
        aria-label="dynamic tree view"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {filteredValues.map(item => (
          <CustomTreeItem key={item.id} data={item} handleClick={handleClick} />
        ))}
      </TreeView>
    </Box>
  )
}

export default DynamicTreeView
