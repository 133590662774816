import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'

import Button from '_components/button'

import useStyles from './styles'

const InfoModal = ({ handleModal, children }) => {
  const styles = useStyles()

  return (
    <Dialog open onClose={handleModal} className={classnames(styles.modalContainer)}>
      <DialogContent className={classnames(styles.content)}>{children}</DialogContent>
      <DialogActions className={classnames(styles.actions)}>
        <Button
          className={classnames(styles.textButton, styles.textButtonActive)}
          onClick={handleModal}
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

InfoModal.propTypes = {
  children: PropTypes.node.isRequired,
  handleModal: PropTypes.func.isRequired,
}

export default React.memo(InfoModal)
