import React, { useCallback, useState, useMemo } from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import { Button, DatePicker, Modal } from '@refera/ui-web'

import Select from '_components/common/select'

import {
  ANTICIPATIONS_PAYMENT_METHOD_OPTIONS,
  METHOD_PAYMENT_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
} from '../../utils/constants'

import useStyles from './styles'
import { useLocation } from '@reach/router'
import { FINANCE_ROUTES } from '_/utils/constants'

const DEFAULT_SELECT_OPTION = [
  {
    label: 'Selecione',
    value: '',
  },
]

const DEFAULT_LABELS = {
  title: 'Dar baixa no pagamento',
  paymentMethodLabel: 'Forma de pagamento',
  paymentDateLabel: 'Data de pagamento',
}

const ConfirmDateAndPaymentModal = ({ open, onConfirm, onCancel, defaultDate, labelsProps }) => {
  const styles = useStyles()
  const [datetimePayment, setDatetimePayment] = useState(
    defaultDate ? moment(defaultDate) : moment()
  )
  const [paymentMethod, setPaymentMethod] = useState('')
  const { pathname } = useLocation()

  const handleConfirm = useCallback(() => {
    onConfirm({ datetimePayment, paymentMethod })
  }, [onConfirm, datetimePayment, paymentMethod])

  const labels = useMemo(() => ({ ...DEFAULT_LABELS, ...labelsProps }), [labelsProps])

  const screen = useMemo(() => {
    if (pathname.includes(FINANCE_ROUTES.PAYMENT_ADVANCE)) return 'anticipations'
    if (pathname.includes(FINANCE_ROUTES.MANAGE_INSTALLMENTS)) return 'installments'
    if (pathname.includes(FINANCE_ROUTES.RECEIPTS)) return 'receipts'
    return ''
  }, [pathname])

  const paymentOptions = useMemo(() => {
    let options = PAYMENT_METHOD_OPTIONS

    if (screen === 'anticipations') options = ANTICIPATIONS_PAYMENT_METHOD_OPTIONS
    if (screen === 'receipts') options = METHOD_PAYMENT_OPTIONS

    return [...DEFAULT_SELECT_OPTION, ...options]
  }, [labelsProps, screen])

  const isSubmitDisabled = useMemo(() => {
    return paymentMethod === '' || !moment(datetimePayment).isValid()
  }, [datetimePayment, paymentMethod])

  const handleChange = useCallback(event => setPaymentMethod(event.target.value), [])

  return (
    <Modal
      className={styles.modal}
      title={labels.title}
      onBackdropClick={onCancel}
      onClose={onCancel}
      open={open}
      actionsButtons={
        <Grid className={styles.modalButtons}>
          <Button color="red" variant="secondary" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            disabled={isSubmitDisabled}
            color="primary"
            variant="primary"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </Grid>
      }
    >
      <Grid className={styles.wrapper}>
        <Select
          label={labels.paymentMethodLabel}
          required
          defaultValue=""
          value={paymentMethod}
          name="paymentMethod"
          onChange={handleChange}
          options={paymentOptions}
          selectStyles={styles.select}
        />
        <DatePicker
          required
          label={labels.paymentDateLabel}
          variant="inline"
          value={datetimePayment}
          defaultValue={datetimePayment}
          onChange={setDatetimePayment}
          format="dd/MM/yyyy"
          placeholder="DD/MM/AAAA"
          invalidDateMessage="Insira uma data válida"
          className={styles.datePicker}
          refuse={/[^\d\\.]+/gi}
          disabled={!paymentMethod}
        />
      </Grid>
    </Modal>
  )
}

export default ConfirmDateAndPaymentModal
