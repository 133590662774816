import { makeStyles } from '@material-ui/core/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  dialog: {
    position: 'absolute',
    width: '504px',
    height: '372px',
  },
  button: {
    width: '100px',
    borderRadius: '24px',
    height: '40px',
    textTransform: 'none',
    marginBottom: '15px',
  },
  dialogTitle: {
    textAlign: 'center',
    gridArea: 'title',
    justifySelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '15px',
  },
  dialogContent: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    overflow: 'hidden',
  },
  dialogAction: {
    justifyContent: 'center',
  },
  icon: {
    width: '60px',
    height: '60px',
  },
  iconBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  referaButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: Theme.Colors.Grayscale.Zero,
  },
  alertTitle: {
    fontSize: '1.6rem',
    fontFamily: 'Roboto',
  },
  titleIconWarning: {
    height: '80px',
    width: '80px',
    backgroundColor: Theme.Colors.Primary.Lightest,
    color: Theme.Colors.Primary.Lightest,
    borderRadius: '50%',
  },
  iconSVG: {
    height: '60px',
    width: '60px',
  },
}))
