import React, { useMemo } from 'react'
import Switch from '.'
import { Controller, useFormContext } from 'react-hook-form'

/**
 * Renders a controlled Switch component.
 * @param {Object} props - The props object.
 * @param {string} props.name - The name of the switch. (Hook-Form related)
 * @param {string} props.label - The label for the switch.
 * @param {boolean} [props.defaultValue=false] - The default value of the switch.
 * @param {Object} [props.control] - The control object from hook-form.
 * @param {Function} [props.customOnChange] - The custom function to be called when the switch changes.
 * @param {string} [props.className] - The classes for the switch container.
 * @returns {React.ReactElement} - The Switch component.
 *
 * @example
 * // Example usage:
 * import { ControlledSwitch } from './components/inputs/Switch'
 *
 * function MyComponent() {
 *  const methods = useForm()
 *  const cannotChangeSwitchValue = true
 *  const customOnChange = (event, callback) => {
 *    if (cannotChangeSwitchValue)
 *      return
 *
 *   // this is necessary to set the value in the form
 *   // for hook-form to work properly
 *   callback()
 *  }
 *  return (
 *   <FormProvider {...methods}>
 *     <ControlledSwitch
 *       name="switchInput"
 *       label="Switch Input"
 *       defaultValue={false}
 *       customOnChange={customOnChange}
 *     />
 *   </FormProvider>
 *  )
 * }
 *
 * export default MyComponent
 *
 * // For styling, please refer to src/components/inputs/Switch/styles.js
 */
export function ControlledSwitch({
  name,
  label,
  defaultValue,
  customOnChange,
  className = undefined,
  control: propsControl = undefined,
  ...rest
}) {
  const contextData = useFormContext()
  const inputId = useMemo(() => `Refera__Switch-${name}-${crypto.randomUUID()}`, [name])

  const memoizedOnChange = useMemo(() => {
    return (event, hookformOnchange) => {
      const { checked } = event.target
      if (customOnChange) {
        customOnChange(event, () => hookformOnchange(checked))
      } else {
        hookformOnchange(checked)
      }
    }
  }, [customOnChange])

  return (
    <Switch.Root className={className}>
      <Switch.Label htmlFor={inputId} content={label} />
      <Controller
        control={contextData?.control ?? propsControl}
        name={name}
        defaultValue={defaultValue || false}
        render={props => {
          return (
            <Switch.Control
              id={inputId}
              name={name}
              checked={props.value}
              onChange={e => memoizedOnChange(e, props.onChange)}
              {...rest}
            />
          )
        }}
      />
    </Switch.Root>
  )
}
