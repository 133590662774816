import { schema } from 'normalizr'

import Model from './model'

class State extends Model({
  id: undefined,
  name: undefined,
  uf: undefined,
}) {}

const stateNewSchema = new schema.Entity(
  'state',
  {},
  {
    processStrategy: entity => new State(entity),
  }
)

const stateSchema = [stateNewSchema]

export { stateSchema, State as default }
