/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { Button } from '@refera/ui-web'

import useStyles from './styles'

const TourButtons = ({ handleNext, handleBack, handleFinish, info, infoIndex }) => {
  const styles = useStyles()

  const buttons = useMemo(() => {
    if (infoIndex === info?.length - 1) {
      return (
        <Grid className={styles.btnRow}>
          <Grid className={[styles.btnWrapper, styles.center]}>
            <Grid className={styles.btnRow}>
              <Button className={[styles.button]} onClick={handleFinish}>
                Ok, entendi
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    if (infoIndex === 0) {
      return (
        <Grid className={styles.btnRow}>
          <Grid className={[styles.btnWrapper, styles.center]}>
            <Grid className={styles.btnRow}>
              <Button className={[styles.button]} onClick={handleNext}>
                Avançar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid className={styles.btnRow}>
        <Grid className={[styles.btnWrapper, styles.center]}>
          <Button className={styles.button} color="red" onClick={handleBack}>
            Voltar
          </Button>
          <Button className={styles.button} onClick={handleNext}>
            Avançar
          </Button>
        </Grid>
      </Grid>
    )
  }, [handleBack, handleFinish, handleNext, info, infoIndex])

  return <Grid className={[styles.container, styles.center]}>{buttons}</Grid>
}

export default TourButtons
