import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, TextField, Divider } from '@material-ui/core'

import * as Modal from '_components/modal/generic-modal'
import useStyles from './styles'

const ShowMoreDetailRefuseModal = ({ handleModal, refusalMoreDetails }) => {
  const styles = useStyles()

  return (
    <Modal.Root open onClose={handleModal}>
      <Modal.TitleModal>
        <Typography className={styles.title}>Mais Detalhes</Typography>
      </Modal.TitleModal>
      <Divider style={{ marginBottom: 20 }} />
      <Modal.Content className={styles.content}>
        <Grid className={styles.container}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontFamily: 'Dm Sans',
              fontStyle: 'normal',
              fontSize: '12px',
              color: '#141414',
            }}
          >
            Descrição
          </Typography>
          <TextField
            variant="outlined"
            multiline
            minRows={4}
            maxRows={32}
            value={refusalMoreDetails}
            disabled
          />
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Modal.ButtonRed onClick={handleModal}>Fechar</Modal.ButtonRed>
      </Modal.Actions>
    </Modal.Root>
  )
}

ShowMoreDetailRefuseModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  refusalMoreDetails: PropTypes.string,
}

ShowMoreDetailRefuseModal.defaultProps = {
  refusalMoreDetails: '',
}

export default React.memo(ShowMoreDetailRefuseModal)
