import humps from 'humps'

import { get, post, patch, del } from './requests'

export const getBudgetTemplates = key => params =>
  get(['budget-templates'], { key, params: humps.decamelizeKeys(params) })

export const getBudgetTemplateById = key => budgetTemplateId =>
  get(['budget-templates', budgetTemplateId], { key })

export const createBudgetTemplate = key => payload =>
  post(['budget-templates'], { key, transformPayload: true }, payload)

export const updateBudgetTemplate = key => (budgetTemplateId, payload) =>
  patch(['budget-templates', budgetTemplateId], { key, transformPayload: true }, payload)

export const deleteBudgetTemplate = key => budgetTemplateId =>
  del(['budget-templates', budgetTemplateId], { key })
