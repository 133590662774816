import React from 'react'
import { Typography, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'

import useStyles from './styles'

const CategoryIndicator = ({ label, tooltipText, color }) => {
  const styles = useStyles()
  return (
    <Tooltip title={tooltipText}>
      <Typography className={styles.text} color={color}>
        {label}
      </Typography>
    </Tooltip>
  )
}

CategoryIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  color: PropTypes.string,
}

CategoryIndicator.defaultProps = {
  color: 'primary',
}
export default React.memo(CategoryIndicator)
