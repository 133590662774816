import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  categoryForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '22px',
    marginBottom: '5px',
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  label: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '18px',
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  serviceLabel: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '18px',
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
    marginTop: '20px',
  },
  textfield: {
    marginRight: '10px',
    marginBottom: '10px',
    width: '350px',
  },
  containerAdd: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '3rem',
  },
  selectWrapper: {
    width: '100%',
    height: '30px',
  },
  autocomplete: {
    marginBottom: '20px',
    width: '500px',

    '& .MuiAutocomplete-popper': {
      maxHeight: '200px',
    },
  },
  modalContent: {
    width: '100%',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: 0,
  },
}))
