import { Map, fromJS } from 'immutable'
import humps from 'humps'

import { getActionName } from '_utils/redux'
import { removeToken } from '_/utils/token'

export const INITIAL_STATE = Map()

export default (state = INITIAL_STATE, action) => {
  if (action.error) {
    if (action?.payload?.detail?.toLowerCase().includes('invalid token')) {
      removeToken()
    }
    return state.set(
      getActionName(action.type),
      fromJS(
        typeof action.payload === 'string'
          ? { error: action.payload }
          : humps.camelizeKeys(action.payload)
      )
    )
  }
  return state.delete(getActionName(action.type))
}
