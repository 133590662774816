import { schema } from 'normalizr'

import Model from './model'
import State from './state'

class ReasonToReprove extends Model({
  id: undefined,
  reasons: undefined,
}) {}

const reasonToReproveNewSchema = new schema.Entity(
  'reasonToReprove',
  {},
  {
    processStrategy: entity => {
      return new ReasonToReprove(entity).set('state', new State(entity.state))
    },
  }
)

const reasonToReproveSchema = [reasonToReproveNewSchema]

export { reasonToReproveSchema, ReasonToReprove as default }
