import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Typography } from '@refera/ui-web'
import Draggable from 'react-draggable'

import Theme from '@refera/ui-core'

import DotsGroup from '_assets/svgs/dots-group.svg'
import { BoxAdd } from '@refera/ui-icons'
import Svg from '_/components/svg'

import useStyles from './styles'
import { useSelector } from 'react-redux'
import { userPermissionsSelector } from '_/modules/authentication/selectors'
import { useToast } from '_/hooks/use-toast'

export const ListLeadProvidersFloatingMenu = ({ selectedItems, handleButtonsActions }) => {
  const styles = useStyles()
  const userPermissions = useSelector(userPermissionsSelector)
  const { showToast } = useToast()

  const handleImportProvidersButtonClick = useCallback(() => {
    if (userPermissions?.importTradesman?.includes('create') || userPermissions?.isAdmin)
      handleButtonsActions('import')
    else
      showToast({
        type: 'error',
        message: 'Você não tem permissão para esta ação.',
      })
  }, [userPermissions])

  return (
    <Draggable handle="#draggable-floating-menu" cancel={'[class*="MuiDialogContent-root"]'}>
      <Grid className={styles.floatingContainer}>
        <Grid className={styles.floatingWrapper}>
          <Grid className={styles.draggable} id="draggable-floating-menu">
            <Svg className={styles.dotsIcon} icon={DotsGroup} />
          </Grid>
          <Grid className={styles.floatingContent}>
            <Grid className={styles.floatingLabels}>
              <Typography fontSize={14}>
                Selecionado:{' '}
                <b>
                  {selectedItems.length}
                  {selectedItems.length > 1 ? ' itens' : ' item'}
                </b>
              </Typography>
              <Button
                variant="ghost"
                startIcon={
                  <BoxAdd color={Theme.Colors.Primary.Base} style={{ fontSize: '20px' }} />
                }
                onClick={handleImportProvidersButtonClick}
              >
                Importar prestadores
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Draggable>
  )
}
