import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import UrgentIcon from '_assets/icons/ic-urgent-priority.svg'
import HighIcon from '_assets/icons/ic-high-priority.svg'
import MediumIcon from '_assets/icons/ic-medium-priority.svg'
import Svg from '_components/svg'

import useRolePermission from '_hooks/use-role-permission'

import useStyles from './styles'

export const PRIORITY_VALUES = {
  URGENT: 3,
  HIGH: 2,
  MEDIUM: 1,
}

const ICON_OPTIONS = {
  [PRIORITY_VALUES.URGENT]: UrgentIcon,
  [PRIORITY_VALUES.HIGH]: HighIcon,
  [PRIORITY_VALUES.MEDIUM]: MediumIcon,
}

const PRIORITY_LABEL = {
  [PRIORITY_VALUES.URGENT]: 'Urgente',
  [PRIORITY_VALUES.HIGH]: 'Alta',
  [PRIORITY_VALUES.MEDIUM]: 'Média',
}

export const PRIORITY_OPTIONS = Object.values(PRIORITY_VALUES).map(value => ({
  value,
  label: PRIORITY_LABEL[value],
}))

const Priority = ({ value, iconClassName }) => {
  const styles = useStyles()
  const isUrgentPriority = value === 3
  const { isTradesman } = useRolePermission()

  return (
    <Grid container alignItems="center">
      <Svg
        icon={ICON_OPTIONS[value]}
        className={classnames(styles.icon, styles[value], iconClassName)}
      />
      <Typography variant="body2" className={isUrgentPriority && isTradesman && styles.boldText}>
        {PRIORITY_LABEL[value]}
      </Typography>
    </Grid>
  )
}
Priority.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconClassName: PropTypes.string,
}
Priority.defaultProps = {
  value: '',
  iconClassName: '',
}
export default React.memo(Priority)
