import React from 'react'

import Svg from '_components/svg'
import CloseIcon from '_assets/icons/ic-close-circle.svg'
import SaveIcon from '_assets/icons/ic-tick-circle.svg'

import useStyles from '../../classification-management/styles'

const ActiveCell = ({ active }) => {
  const styles = useStyles()

  if (active) {
    return <Svg icon={SaveIcon} className={styles.icon} />
  }

  return <Svg icon={CloseIcon} className={styles.icon} />
}

export const COLUMNS = [
  {
    field: 'cpfCnpj',
    headerName: 'CPF/CNPJ',
    flex: 1,
  },
  {
    field: 'isVip',
    headerName: 'Vip',
    width: 100,
    renderCell: ({ value }) => <ActiveCell active={value} />,
  },
  {
    field: 'forbiddenContact',
    headerName: 'Contato proibido',
    width: 100,
    renderCell: ({ value }) => <ActiveCell active={value} />,
  },
  {
    field: 'isActive',
    headerName: 'Ativo',
    width: 100,
    renderCell: ({ value }) => <ActiveCell active={value} />,
  },
]
