import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))
