import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: '90px',
    height: '40px',
  },
  editIcon: {
    marginLeft: '4px',
    fontSize: '16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px 50px 0',
  },
  datesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '800px',
    marginBottom: '24px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#141414',
  },
  dateField: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '260px',
  },
  datePicker: {
    width: '200px',
    zIndex: '1',
  },
  dateIcon: {
    margin: '0px 0px 10px 176px',
    position: 'absolute',
    zIndex: '0',
  },
  attachmentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
    width: '100%',
  },
}))
