const palette = {
  primary: {
    darkest: '#0B3672',
    dark: '#1051AB',
    main: '#1671ED',
    light: '#6AA4F3',
    lightest: '#ECF4FE',
  },
  secondary: {
    main: '#707070',
  },
  custom: {
    red: '#C90000',
    yellow: '#FFCC00',
    green: '#34C759',
    orange: '#E27121',
    rhino: '#294155',
  },
  text: {
    primary: '#717171',
    secondary: '#9F9F9F',
  },
  divider: '#C4C4C4',
  background: {
    default: '#fff',
  },
  orange: {
    main: '#E27121',
    light: '#DC7C00',
  },
  error: {
    darkest: '#600000',
    dark: '#910000',
    main: '#C90000',
    light: '#DC5C5C',
    lightest: '#FBEBEB',
  },
  gray: {
    4: '#F6F6F6',
    12: '#E3E3E3',
    32: '#B4B4B4',
    64: '#696969',
    80: '#434343',
    100: '#141414',
  },
  green: {
    darkest: '#004204',
    dark: '#006306',
    main: '#008908',
    light: '#5CB461',
    lightest: '#EBF6EB',
  },
  yellow: {
    darkest: '#745B00',
    dark: '#AE8800',
    main: '#F2BD00',
    light: '#F7D55C',
    lightest: '#FEFAEB',
  },
}

export default palette
