import React, { useCallback } from 'react'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import PropTypes from 'prop-types'

import Button from '_components/button'
import useFetchCall from '_hooks/use-fetch-call'

import useStyles from './styles'

const ConfirmationModal = ({
  isOpen,
  handleClose,
  description,
  cancelButtonText,
  confirmButtonText,
  handleConfirmClick,
  handleDeniedClick,
  action,
  disabled,
}) => {
  const styles = useStyles()

  const onConfirmClick = useCallback(() => {
    handleConfirmClick()
  }, [handleConfirmClick])

  const onDeniedClick = useCallback(() => {
    if (handleDeniedClick) {
      handleDeniedClick()
      return
    }

    handleClose()
  }, [handleClose, handleDeniedClick])

  const [isLoading] = useFetchCall(action, handleClose)

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-describedby={`modal-${description}`}
      className={styles.dialog}
    >
      <DialogContent>
        <DialogContentText id={`modal-${description}`} className={styles.description}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDeniedClick}>{cancelButtonText}</Button>
        <Button onClick={onConfirmClick} color="primary" isLoading={isLoading || disabled}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  description: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  handleConfirmClick: PropTypes.func,
  isOpen: PropTypes.bool,
  action: PropTypes.string,
  handleDeniedClick: PropTypes.func,
  disabled: PropTypes.bool,
}

ConfirmationModal.defaultProps = {
  description: '',
  cancelButtonText: 'Não',
  confirmButtonText: 'Sim',
  handleConfirmClick: () => {},
  handleDeniedClick: undefined,
  isOpen: false,
  action: '',
  disabled: false,
}
export default React.memo(ConfirmationModal)
