import React, { useCallback, useState, useEffect, useMemo } from 'react'
import HeaderTitle from '_/components/header-title'
import Button, { BUTTON_COLOR } from '_components/button'
import { FormProvider, useForm } from 'react-hook-form'
import useRolePermission from '_hooks/use-role-permission'
import { useParams, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { getBudgetById, updateBudgetCoverage } from '_/modules/budget/actions'
import { getBudgetByIdSelector } from '_modules/budget/selectors'
import { activeBudgetSelector } from '_/modules/service-orders/selectors'
import { BUTTONS_ACTION } from '_/utils/constants/service-order'
import InfoIcon from '_assets/icons/ic-info-circle.svg'
import useCanDoButtonAction from '_/hooks/use-can-do-button-action'
import {
  getButtonsAction,
  getServiceOrder,
  getActiveBudget,
} from '_/modules/service-orders/actions'

import { Typography, FormHelperText, Link, Tooltip } from '@material-ui/core'
import { PercentInput } from '@refera/ui-web'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import Svg from '_components/svg'
import { useToast } from '_/hooks/use-toast'
import { useDialog } from '_/hooks/use-dialog'
import useStyles from './styles'
import { BudgetCoverageModal } from '../budget-coverage-modal'
import useToggle from '_hooks/use-toggle'
import { formatDate } from '_/utils/helpers'
import CircleBlue from 'src/assets/icons/ic-info-circle-blue.svg'
import { REFERA_COVERAGE_LINK, REFERA_COVERAGE_MANAGEMENT_LINK } from '_/utils/constants'

const ReferaCoverage = () => {
  const { isAdmin, isIntermediary } = useRolePermission()
  const { showToast } = useToast()
  const { showDialog, closeDialog } = useDialog()
  const styles = useStyles()

  const dispatch = useDispatch()
  const { budgetId } = useParams()
  const activeBudget = useSelector(activeBudgetSelector)
  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const coverageValue = useMemo(() => budget?.coverageValue?.toJS(), [budget])
  const selectedVersion = coverageValue?.version
  const [helperText, setHelpText] = useState('')

  const canEditCoverage = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.EDIT_COVERAGE,
    isBudgetRelated: true,
  })

  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showVersionDetails, toggleShowVersionDetails] = useToggle()

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      coverage: null,
    },
  })

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods

  const showAlert =
    coverageValue &&
    coverageValue?.user &&
    String(coverageValue?.updatedAt).substring(0, 19) !==
      String(coverageValue?.createdAt).substring(0, 19)

  const validateCoverage = useCallback(
    coverage => {
      if (coverage > 0) {
        const coverageAmount = budget?.totalPrice * (coverage / 100)
        setHelpText({
          messageInfo: `Com a cobertura em ${coverage}% esse orçamento terá um limite de R$ ${coverageAmount.toLocaleString(
            'pt-BR',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )} de cobertura da Refera.`,
          typeInfo: 'info',
        })
      } else if (coverage === 0) {
        setHelpText({
          messageInfo: 'Sem cobertura da refera',
          typeInfo: 'info',
        })
      }
    },
    [budget?.totalPrice]
  )

  useEffect(() => {
    validateCoverage(coverageValue?.coveragePercentage)
  }, [coverageValue?.coveragePercentage])

  const handlerCheckError = currentValue => {
    if (!currentValue && currentValue !== 0) {
      setHelpText({
        messageInfo: 'Campo obrigatório',
        typeInfo: 'warning',
      })
      return
    }

    const watchedCoverage = currentValue ?? 0

    validateCoverage(watchedCoverage)
  }

  const handleCancel = useCallback(() => {
    if (isDirty) {
      showDialog({
        type: 'warning',
        subject: 'Aviso',
        description: 'Você tem certeza que deseja cancelar estas alterações?',
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onApprove: () => {
          closeDialog()
          navigate(-1)
        },
        onCancel: closeDialog,
      })
    } else {
      navigate(-1)
    }
  }, [isDirty, navigate, showDialog, closeDialog])

  const onSubmit = useCallback(
    data => {
      setIsLoading(true)
      if (!canEditCoverage) {
        setIsLoading(false)
        showToast({
          type: 'error',
          message:
            'Não é possível editar a regra de cobertura para esse orçamento pois o chamado se encontra em uma etapa onde isso não é mais permitido ou o orçamento não é o que está ativo no chamado.',
        })
        return
      }

      dispatch(updateBudgetCoverage(budgetId, data))
        .then(() => {
          showToast({ type: 'success' })
          dispatch(getBudgetById(budgetId))
        })
        .catch(() => {
          showToast({ type: 'error' })
        })
      setIsLoading(false)
    },
    [canEditCoverage, budget, activeBudget, showToast, dispatch]
  )

  const displayPercentage = value => {
    return value
      ? (value / 100).toLocaleString('pt-br', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '0,00%'
  }

  useEffect(() => {
    if (!budget) {
      Promise.resolve(dispatch(getBudgetById(budgetId))).then(res => {
        Promise.all([
          dispatch(getServiceOrder(res?.serviceOrder)),
          dispatch(getActiveBudget(res?.serviceOrder)),
          dispatch(getButtonsAction()),
        ])
      })
      dispatch(getButtonsAction())
    }
  }, [budget, budgetId, dispatch])

  useEffect(() => {
    if (isAdmin) {
      setIsEditing(true)
    }
  }, [isAdmin])

  const handleLink = useMemo(() => {
    if (isAdmin || isIntermediary) {
      return REFERA_COVERAGE_MANAGEMENT_LINK
    }
    return REFERA_COVERAGE_LINK
  }, [isAdmin, isIntermediary])

  return (
    <main className={styles.container}>
      {!coverageValue?.coveragePercentage ? (
        <div className={styles.specialRule}>
          <div className={styles.iconWrapper}>
            <Svg icon={InfoIcon} className={styles.icon} />
          </div>
          <Typography variant="body2">
            Esse orçamento possui a regra especial referente a cobertura da Refera. <br /> Para
            saber mais sobre as diretrizes dessa regra
            <Link href={handleLink} target="_blank" rel="noopener" className={styles.link}>
              clique aqui
            </Link>
            .
          </Typography>
        </div>
      ) : (
        <>
          <HeaderTitle
            title="Limite de cobertura do orçamento"
            backButtonAction={() => navigate(-1)}
          >
            {isEditing && (
              <div style={{ display: 'flex', gap: '8px' }}>
                <Button
                  style={{ width: '100px' }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoading}
                >
                  Salvar
                </Button>
                <Button
                  style={{ width: '100px' }}
                  color={BUTTON_COLOR.RED}
                  variant="outlined"
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </HeaderTitle>

          <div className={styles.content}>
            {showAlert && (
              <div className={styles.warning}>
                <Svg className={styles.circleIcon} icon={CircleBlue} />
                <Typography className={styles.typography}>
                  {`Essa regra de cobertura foi personalizada por ${
                    coverageValue?.user
                  } no dia ${formatDate(coverageValue?.updatedAt)}.`}
                </Typography>
              </div>
            )}
            <div className={styles.coverage}>
              <Typography variant="body2" className={styles.typography}>
                Regra original:{' '}
                <button type="button" className={styles.link} onClick={toggleShowVersionDetails}>
                  Versão {selectedVersion?.version}{' '}
                </button>
              </Typography>
              <Tooltip
                arrow
                disableFocusListener
                disableTouchListener
                title={
                  <Typography className={styles.tooltipText}>
                    A Regra original de cobertura é a que estava vigente quando o orçamento foi
                    criado.
                  </Typography>
                }
                placement="bottom-end"
              >
                <HelpOutlineOutlinedIcon className={styles.tooltipIcon} />
              </Tooltip>
            </div>

            <FormProvider {...methods}>
              <form id="referaCoverageForm">
                <div className={styles.coverage}>
                  <PercentInput
                    {...methods.register('coverage')}
                    required
                    label="Cobertura"
                    value={displayPercentage(coverageValue?.coveragePercentage)}
                    mask=" "
                    onChange={({ floatValue }) => {
                      methods.setValue('coverage', floatValue)
                      handlerCheckError(floatValue)
                    }}
                    onBlur={handlerCheckError}
                    format="####"
                    placeholder="%"
                    allowNegative={false}
                    allowLeadingZeros={false}
                    style={{ fontSize: '14px', width: '193px' }}
                    disabled={!isEditing}
                  />
                </div>
                <FormHelperText
                  style={{
                    marginTop: '4px',
                    color: helperText.typeInfo === 'warning' ? 'red' : 'black',
                  }}
                >
                  {helperText?.messageInfo}
                </FormHelperText>
              </form>
            </FormProvider>
          </div>
        </>
      )}
      {showVersionDetails && (
        <BudgetCoverageModal handleModal={toggleShowVersionDetails} version={selectedVersion} />
      )}
    </main>
  )
}

export default ReferaCoverage
