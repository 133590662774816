import React, { useState } from 'react'

import { FormProvider, useForm } from 'react-hook-form'

import { Loader } from '@refera/ui-web'
import { PageHeader } from './PageHeader'
import { Form } from './Form'

import useStyles from './styles'

import { yupResolver } from '@hookform/resolvers/yup'

import { createAndEditReferaUsersFormSchema } from './constants'

export default function CreateAndEditReferaUsersForm() {
  const styles = useStyles()

  const [permissionsGroup, setPermissionsGroup] = useState([])

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(createAndEditReferaUsersFormSchema),
  })

  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <main className={styles.container}>
        <FormProvider {...methods}>
          <PageHeader
            permissionsGroup={permissionsGroup}
            setPermissionsGroup={setPermissionsGroup}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />

          <div className={styles.content}>
            <Form
              permissionsGroup={permissionsGroup}
              setPermissionsGroup={setPermissionsGroup}
              setIsLoading={setIsLoading}
            />
          </div>
        </FormProvider>
      </main>

      <Loader hasBackdrop open={isLoading} label="Aguarde..." />
    </>
  )
}
