const ADD_ITEM = 'ADD_ITEM'
const HANDLE_CHANGE = 'HANDLE_CHANGE'
const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
const HANDLE_DESCRIPTION_CHANGE = 'HANDLE_DESCRIPTION_CHANGE'

export const ORDER_DETAILS_FIELDS = {
  ACCEPTS_INSTALLMENT: 'acceptsInstallment',
  INSTALLMENTS: 'installments',
  ACCEPTS_WARRANTY: 'acceptsWarranty',
  WARRANTY_MONTHS: 'warrantyMonths',
  VISIT_HAPPENED: 'visitHappened',
  PAYMENT: 'payment',
  ITEM_DESCRIPTION: 'description',
  VALUE_DESCRIPTION: 'price',
  DESCRIPTIONS: 'descriptions',
  TOTAL: 'total',
  PERSON_PAYING: 'personPaying',
  PERSON_PAYING_EMAIL: 'personPayingEmail',
  PERSON_PAYING_WHATSAPP: 'personPayingWhatsapp',
  PERSON_PAYING_AUTH: 'personPayingAuth',
}

export const ORDER_DETAILS_LABEL = {
  [ORDER_DETAILS_FIELDS.ACCEPTS_INSTALLMENT]: 'Você aceita parcelamento?',
  [ORDER_DETAILS_FIELDS.INSTALLMENTS]: 'Em quantas vezes?',
  [ORDER_DETAILS_FIELDS.ACCEPTS_WARRANTY]: 'Seu serviço tem garantia?',
  [ORDER_DETAILS_FIELDS.WARRANTY_MONTHS]: 'Quanto tempo de garantia?',
  [ORDER_DETAILS_FIELDS.VISIT_HAPPENED]: 'Houve visita presencial?',
  [ORDER_DETAILS_FIELDS.PAYMENT]: 'Definir quem irá pagar',
  [ORDER_DETAILS_FIELDS.TOTAL]: 'Total',
  [ORDER_DETAILS_FIELDS.PERSON_PAYING]: 'Selecione o responsável pelo pagamento',
  [ORDER_DETAILS_FIELDS.PERSON_PAYING_EMAIL]: 'Digite o e-mail do responsável pelo pagamento',
  [ORDER_DETAILS_FIELDS.PERSON_PAYING_WHATSAPP]: 'Digite o WhatsApp',
}

export const INITIAL_STATE = {
  [ORDER_DETAILS_FIELDS.DESCRIPTIONS]: [],
  [ORDER_DETAILS_FIELDS.ACCEPTS_INSTALLMENT]: undefined,
  [ORDER_DETAILS_FIELDS.INSTALLMENTS]: undefined,
  [ORDER_DETAILS_FIELDS.ACCEPTS_WARRANTY]: undefined,
  [ORDER_DETAILS_FIELDS.VISIT_HAPPENED]: undefined,
  [ORDER_DETAILS_FIELDS.PAYMENT]: undefined,
  [ORDER_DETAILS_FIELDS.WARRANTY_MONTHS]: undefined,
  [ORDER_DETAILS_FIELDS.PERSON_PAYING]: 'tenant',
  [ORDER_DETAILS_FIELDS.PERSON_PAYING_EMAIL]: undefined,
  [ORDER_DETAILS_FIELDS.PERSON_PAYING_WHATSAPP]: undefined,
}

const removeNonDigits = string => {
  return string.replace(/\D/g, '')
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ADD_ITEM: {
      const newItem = {
        [ORDER_DETAILS_FIELDS.ITEM_DESCRIPTION]: '',
        [ORDER_DETAILS_FIELDS.VALUE_DESCRIPTION]: '',
      }
      return {
        ...state,
        descriptions: [...state.descriptions, newItem],
      }
    }
    case HANDLE_CHANGE: {
      const { name, value } = action.payload

      if (ORDER_DETAILS_FIELDS.INSTALLMENTS === name) {
        return { ...state, [name]: `${removeNonDigits(value)}x` }
      }
      if (ORDER_DETAILS_FIELDS.WARRANTY_MONTHS === name) {
        return {
          ...state,
          [name]: `${removeNonDigits(value)} meses`,
        }
      }
      return { ...state, [name]: value }
    }
    case HANDLE_DESCRIPTION_CHANGE: {
      const { name, value, index } = action.payload
      const item = state.descriptions[index]
      if (ORDER_DETAILS_FIELDS.VALUE_DESCRIPTION === name) {
        state.descriptions.splice(index, 1, { ...item, [name]: removeNonDigits(value) })
        return { ...state, descriptions: [...state.descriptions] }
      }
      state.descriptions.splice(index, 1, { ...item, [name]: value })
      return { ...state, descriptions: [...state.descriptions] }
    }
    case SET_INITIAL_STATE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
