import {
  GET_CLASSIFICATION,
  GET_CLASSIFICATION_BY_ID,
  GET_CLASSIFICATION_COLOR,
  GET_CLASSIFICATION_TYPE,
} from './actions'

export const classificationCountSelector = state => state.classification?.get('count')

export const classificationPageSelector = state => state.classification?.get('page')

export const classificationPageSizeSelector = state => state.classification?.get('pageSize')

export const classificationsSelector = state => {
  const results = state.classification?.get('resultsClassificationsAgency')
  const order = state.classification?.get('orderAgency')

  return order?.map(id => results.get(id?.toString()))
}

export const classificationsServiceOrderSelector = state => {
  const results = state.classification?.get('resultsClassificationsServiceOrder')
  const order = state.classification?.get('orderServiceOrder')

  return order?.map(id => results.get(id?.toString()))
}

export const classificationsLoadingSelector = state =>
  state?.loading?.get(GET_CLASSIFICATION.ACTION)

export const getClassificationByIdSelector = id => state =>
  state.classification?.getIn(['resultsClassificationsAgency', id])

export const optionsClassificationColorSelector = state =>
  state.classification?.get('optionsClassificationColor')?.toJS()

export const optionsClassificationTypeSelector = state => [
  ...state.classification?.get('optionsClassificationType')?.toJS(),
  {
    id: null,
    name: '',
    description: '',
  },
]

export const getClassificationLoadingSelector = state =>
  !!state.loading.get(GET_CLASSIFICATION_BY_ID.ACTION) ||
  !!state.loading.get(GET_CLASSIFICATION_COLOR.ACTION) ||
  !!state.loading.get(GET_CLASSIFICATION_TYPE.ACTION)
