/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { InputLabel, FormControl } from '@material-ui/core'
import { FormHelperText, TextField } from '@mui/material'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import generalInputStyles from '../styles'
import { defaultInputRules } from '../constants'

/**
 * Basic input component that uses Material-UI TextField and React Hook Form Controller.
 * @param {Object} props - The props object.
 * @param {string} props.label - The label of the input.
 * @param {string} props.name - The name of the input. (Hook-Form related)
 * @param {boolean} [props.required=false] - Whether the input is required or not.
 * @param {string} [props.defaultValue=''] - The default value of the input.
 * @param {Object} [props.rules={}] - The validation rules for the input. Follows the same rules as React Hook Form.
 * @param {Object} [props.style={}] - The style object to be applied to the component.
 * @param {string} [props.containerClasses] - The classes to be applied to the container element.
 * @param {string} [props.labelClasses] - The classes to be applied to the label element.
 * @returns {JSX.Element} - The BasicInput component.
 */
function BasicInput({
  label,
  name,
  required = false,
  showLabelSpan = true,
  defaultValue = '',
  rules = {},
  style = {},
  containerClasses,
  labelClasses = null,
  showErrorMessage = true,
  customErrorMessage = '',
  textArea = false,
  ...rest
}) {
  const generalStyles = generalInputStyles()

  const inputRules = useMemo(() => {
    const formattedRules = Object.entries(rules).reduce((acc, [rule, value]) => {
      if (typeof value === 'object') {
        // No value is invalid
        if (!Object.values(value)?.length) return acc

        if (value?.message) return { ...acc, [rule]: value }
      }

      return { ...acc, [rule]: defaultInputRules[rule]({ value }) }
    }, {})

    if (required && !formattedRules.required) {
      return { ...formattedRules, required: defaultInputRules.required() }
    }

    return formattedRules
  }, [])

  const { errors, control, setValue } = useFormContext()

  const errorMessage = useMemo(
    () => errors?.[`${name}`]?.message || customErrorMessage,
    [errors?.[name], customErrorMessage]
  )

  useEffect(() => {
    setValue(name, defaultValue)
  }, [defaultValue, name, setValue])

  return (
    <FormControl
      style={{ ...style }}
      className={classNames(generalStyles.formControl, containerClasses ?? null)}
    >
      <InputLabel
        variant="standard"
        id={`${name}-label`}
        style={{ color: errorMessage ? '#C90000' : null, top: '-39px' }}
        className={classNames(
          textArea ? generalStyles.inputLabelTextArea : generalStyles.inputLabel,
          labelClasses ?? null
        )}
      >
        {label}
        {required && showLabelSpan && <span className={generalStyles.required}>*</span>}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={inputRules}
        as={
          <TextField
            id={name}
            variant="standard"
            defaultValue={defaultValue}
            error={!!errorMessage}
            required={required}
            InputProps={{
              style: {
                fontSize: 16,
                borderRadius: '8px',
                border: `${textArea ? '1px' : 0} solid ${errorMessage ? '#C90000' : '#717171'}`,
              },
              maxLength: rules?.maxLength?.value || rules?.maxLength || 255,
            }}
            className={[textArea && generalStyles.textArea]}
            {...rest}
          />
        }
      />
      {showErrorMessage && (
        <FormHelperText className={generalStyles.errorMessage} error={!!errorMessage}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

BasicInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  defaultValue: PropTypes.string,
  rules: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
  containerClasses: PropTypes.string,
  labelClasses: PropTypes.string,
}

BasicInput.defaultProps = {
  required: false,
  showErrorMessage: true,
  defaultValue: '',
  rules: {},
  style: {},
  containerClasses: null,
  labelClasses: null,
}

export default BasicInput
