import { schema } from 'normalizr'

import Model from './model'
import State from './state'

class City extends Model({
  name: undefined,
  slug: undefined,
  state_id: undefined,
  state: undefined,
}) {}

const cityNewSchema = new schema.Entity(
  'city',
  {},
  {
    processStrategy: entity => {
      return new City(entity).set('state', new State(entity.state))
    },
  }
)

const citySchema = [cityNewSchema]

export { citySchema, City as default }
