/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core'
import { useParams } from '@reach/router'
import { useDispatch } from 'react-redux'
import { DeleteOutlined } from '@material-ui/icons'

import Button from '_components/button'
import { resendEmailOnCancelServiceOrderRejected } from '_modules/service-orders/actions'

import useStyles from './styles'

export default function ResendEmailModal(props) {
  const { resendEmailModal, setResendEmailModal } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()

  const handleConfirm = async () => {
    dispatch(resendEmailOnCancelServiceOrderRejected(serviceOrderId))
    setResendEmailModal({ ...resendEmailModal, isOpen: false })
  }

  function GetIconButton() {
    return (
      <IconButton disableRipple className={classes.titleIconTrash}>
        <DeleteOutlined className={classes.iconeSVG} />
      </IconButton>
    )
  }

  return (
    <Dialog open={resendEmailModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Box mt={10} />
        <GetIconButton />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="">{resendEmailModal.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          variant="outlined"
          color="red"
          className={classes.button}
          onClick={() => setResendEmailModal({ ...resendEmailModal, isOpen: false })}
        >
          {resendEmailModal.textNoButton ? resendEmailModal.textNoButton : 'Não'}
        </Button>
        {resendEmailModal ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleConfirm}
          >
            {resendEmailModal.textYesButton ? resendEmailModal.textYesButton : 'Sim'}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setResendEmailModal({ ...resendEmailModal, isOpen: false })}
          >
            {resendEmailModal.textYesButton ? resendEmailModal.textYesButton : 'Sim'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
