import { GlobalDialog } from '_/components/GlobalDialog'
import React, { createContext, useContext, useState, useCallback, useMemo } from 'react'

const DialogContext = createContext()

export const DEFAULT_DIALOG_INFO = {
  isOpen: false,
  type: 'info',
  subject: '',
}

export const DialogProvider = ({ children }) => {
  const [dialogInfo, setDialogInfo] = useState(DEFAULT_DIALOG_INFO)

  const showDialog = useCallback(dialogProps => {
    setDialogInfo({ isOpen: true, ...dialogProps })
  }, [])

  const closeDialog = useCallback(() => {
    setDialogInfo(DEFAULT_DIALOG_INFO)
  }, [])

  const renderDialog = useMemo(
    () => dialogInfo?.isOpen && <GlobalDialog {...dialogInfo} />,
    [dialogInfo]
  )

  return (
    <DialogContext.Provider value={{ showDialog, closeDialog }}>
      {children}
      {renderDialog}
    </DialogContext.Provider>
  )
}

/**
 * @typedef {Object} DialogProps
 * @property {'success' | 'error' | 'info' | 'warning'} type - The type of the dialog.
 * @property {React.ElementType} [customIcon] - The custom icon component for the dialog.
 * @property {string} subject - The subject of the dialog.
 * @property {string} [description] - The description of the dialog.
 * @property {string} labelApprove - The label for the approve button.
 * @property {string} [labelCancel] - The label for the cancel button.
 * @property {function} onApprove - The function to call when the approve button is clicked.
 * @property {function} [onCancel] - The function to call when the cancel button is clicked.
 */

/**
 * @typedef {Object} DialogContextValue
 * @property {function(DialogProps): void} showDialog - Shows the dialog with the specified properties.
 * @property {function(): void} closeDialog - Closes the dialog.
 */

/**
 * Use the dialog context.
 *
 * @returns {DialogContextValue} The dialog context value.
 */
export const useDialog = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider')
  }
  return context
}
