import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Grid } from '@mui/material'
import { Typography } from '@material-ui/core'

import Svg, { ICON } from '_components/svg'

import useStyles from './styles'

const DragAndDrop = ({ id, list, renderItem, updateList, listProps, itemProps }) => {
  const styles = useStyles()

  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      updateList(result.source.index, result.destination.index)
    },
    [updateList]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`droppable-${id}`}>
        {providedDroppable => (
          <Grid
            {...providedDroppable.droppableProps}
            ref={providedDroppable.innerRef}
            {...listProps}
          >
            {list.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {providedDraggable => (
                  <div
                    ref={providedDraggable.innerRef}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                  >
                    <Grid container {...itemProps}>
                      <Grid item container rowSpacing="4" classes={{ root: styles.dragIcon }}>
                        <Grid item>
                          <Typography>{index + 1}</Typography>
                        </Grid>
                        <Grid item>
                          <Svg type={ICON.DRAG_AND_DROP} />
                        </Grid>
                      </Grid>

                      {renderItem(item, index)}
                    </Grid>
                  </div>
                )}
              </Draggable>
            ))}
            {providedDroppable.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DragAndDrop.propTypes = {
  id: PropTypes.string.isRequired,
  itemProps: PropTypes.shape({}),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  listProps: PropTypes.shape({}),
  renderItem: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
}

DragAndDrop.defaultProps = {
  listProps: {},
  itemProps: {},
}

export default React.memo(DragAndDrop)
