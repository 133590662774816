import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Grid } from '@mui/material'
import Theme from '@refera/ui-core'
import { Button, Datagrid } from '@refera/ui-web'
import {
  DocumentText,
  FilterSearch as FilterIcon,
  SearchNormal1 as SearchIcon,
} from '@refera/ui-icons'
import { useDispatch, useSelector } from 'react-redux'

import HeaderTitle from '_/components/header-title'

import { ReceiptsCsReferaColumns } from '../manage-installments/utils/constants'

import {
  getFinanceGenericParameters,
  getListBudgetReceivables,
  updateReceiptsInstallmentQuantity,
} from '_/modules/finance/actions'

import {
  getBudgetReceivablesFilterSelector,
  getListBudgetsReceivablesSelector,
} from '_/modules/finance/selectors'

import { useToast } from '_/hooks/use-toast'
import useRolePermission from '_/hooks/use-role-permission'

import { getVerificationToken } from '_/modules/authentication/actions'

import Filters from './components/Filters'
import FloatingMenu from './components/floating-menu'
import { ConfirmVoucher } from './components/confirm-voucher'
import { DownloadSpreadsheet } from './components/download-spreadsheet'

import ReceiptsConfirmation from './receipts-confirmation'

import { FINANCE_ROUTES, ROUTES } from '_/utils/constants'
import { TABLE_SX } from '../utils/constants'

import useStyles from './styles'
import { EmptyScreen } from '_components/empty-screen'
import ChangeInstallmentModal from './components/change-installment-modal'
import { getPayerOptions } from '_/modules/budget/actions'
import { formatErrorMessage } from '../utils/FormatErrorMessage'
import { navigate } from '@reach/router'
import humps from 'humps'

export const columnsToRemove = ['paymentDate', 'amountPaid', 'number']

const Receipts = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { isAdmin, isApprover, isManager } = useRolePermission()

  const receipts = useSelector(getListBudgetsReceivablesSelector)

  const [selectedItems, setSelectedItems] = useState([])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [confirmationScreenPayments, setConfirmationScreenPayments] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [floatingMenuOpen, setFloatingMenuOpen] = useState(false)
  const filters = useSelector(getBudgetReceivablesFilterSelector)
  const [orderBy, setOrderBy] = useState('')
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(true)
  const [downloadSpreadsheetModalOpen, setDownloadSpreadsheetModalOpen] = useState(false)
  const [changeInstallmentsModalOpen, setChangeInstallmentsModalOpen] = useState(false)

  const [menuType, setMenuType] = useState('default')

  const { showToast } = useToast()

  const isFilterDirty = useMemo(() => {
    return Object.values(filters).some(
      value => value && (Array.isArray(value) ? value.length > 0 : true)
    )
  }, [filters])

  const buttonColor = useMemo(() => {
    return isLoading ? Theme.Colors.Grayscale.ThirtyTwo : Theme.Colors.Primary.Base
  }, [isLoading])

  const title = useMemo(() => {
    if (isApprover || isManager) {
      return 'Pagamentos'
    }

    return 'Recebimentos'
  }, [isApprover, isManager])

  const receiptsColumns = useMemo(() => {
    return ReceiptsCsReferaColumns({ styles }).filter(
      column => !columnsToRemove.includes(column.field)
    )
  }, [ReceiptsCsReferaColumns])

  const handleGetReceivables = useCallback(() => {
    if (!isFilterDirty) return

    setIsLoading(true)
    const budgetReceivablesParams = { ...filters, pageSize, page: page + 1, orderBy }

    dispatch(getListBudgetReceivables(budgetReceivablesParams))
      .then(() => setIsLoading(false))
      .catch(() => {
        setIsLoading(false)
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
      })
  }, [receipts, filters, isFilterDirty, page, pageSize, orderBy])

  const handleFloatingMenuActions = useCallback(
    async type => {
      switch (type) {
        case 'insert': {
          const hasItemWithoutVoucher = selectedPayments?.some(item => !item?.voucher)

          if (hasItemWithoutVoucher) {
            setMenuType('confirm-voucher')
          } else {
            showToast({ message: 'Todos os registros já têm números de boletos.', type: 'error' })
          }
          break
        }
        case 'remove': {
          const hasItemWithVoucher = selectedPayments?.some(item => item?.voucher)

          if (hasItemWithVoucher) {
            setMenuType('remove-voucher')
          } else {
            showToast({
              message: 'Nenhum dos registros têm números de boletos para serem excluídos.',
              type: 'error',
            })
          }
          break
        }
        case 'download': {
          setDownloadSpreadsheetModalOpen(true)
          break
        }
        case 'installments': {
          if (selectedPayments.length !== 1) {
            showToast({
              message: 'Para realizar esta ação, você deve selecionar apenas um registro.',
              type: 'error',
            })
            return
          }
          const receivable = selectedPayments[0]
          if (!receivable?.canChangeInstallmentsQuantity) {
            showToast({
              message:
                'Esta ação só é permitida quando não tem parcelas de recebimento pagas ou em pagamento.',
              type: 'error',
            })
            return
          }
          setIsLoading(true)

          await dispatch(getPayerOptions(receivable?.serviceOrderId, receivable?.budget))
            .then(() => {
              setIsLoading(false)
              setChangeInstallmentsModalOpen(true)
            })
            .catch(() => {
              setIsLoading(false)
              showToast({
                type: 'error',
                message: 'Ocorreu um erro ao buscar opções de parcelamento.',
              })
            })

          break
        }
        default: {
          break
        }
      }
    },
    [selectedPayments]
  )

  const handleGetVerificationToken = useCallback(() => {
    dispatch(getVerificationToken())
  }, [])

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('dueDate')
        return
      }
      const { field, sort } = order
      const decamelizedField = humps.decamelize(field)
      sort === 'desc' ? setOrderBy(`-${decamelizedField}`) : setOrderBy(decamelizedField)
    },
    [setOrderBy]
  )

  const handleFloatingModalNavigation = useCallback(
    type => {
      // default options for type = confirm
      let status = { value: 'pending', label: 'pendente' }
      let menu = 'confirm'

      if (type === 'cancel') {
        status = { value: 'paid', label: 'pago' }
        menu = 'cancel'
      }

      const hasCorrectStatusItem = selectedPayments?.some(item => item.status === status.value)
      if (!hasCorrectStatusItem)
        return showToast({
          message: `Nenhum registro selecionado está ${status.label}`,
          type: 'error',
        })

      const newSelectedPayments = selectedPayments?.filter(item => item.status === status.value)
      setConfirmationScreenPayments(newSelectedPayments)

      setMenuType(menu)
      return null
    },
    [selectedPayments, showToast]
  )

  const handleSelectedPayments = useCallback(() => {
    if (selectedItems?.length > 0) {
      const newArray = [...selectedPayments?.filter(item => selectedItems.includes(item.id))]

      receipts?.results?.map(
        item =>
          selectedItems.includes(item.id) &&
          !selectedPayments.filter(payment => item.id === payment.id)[0] &&
          newArray.push(item)
      )

      setSelectedPayments(newArray)
      setFloatingMenuOpen(true)
    } else {
      if (floatingMenuOpen) {
        setFloatingMenuOpen(false)
      }
      setSelectedPayments([])
    }
  }, [selectedItems, selectedPayments, receipts])

  const handleUpdateInstallmentQuantity = useCallback(
    payload => {
      const receivableId = selectedPayments[0]?.id

      setIsLoading(true)
      dispatch(updateReceiptsInstallmentQuantity(receivableId, payload))
        .then(() => {
          setIsLoading(false)
          setChangeInstallmentsModalOpen(false)

          showToast({ type: 'success' })
          setSelectedPayments([])
          setSelectedItems([])
          handleGetReceivables()
        })
        .catch(err => {
          setIsLoading(false)
          const error = formatErrorMessage(err)
          showToast({
            type: 'error',
            message: error,
          })
        })
    },
    [dispatch, selectedPayments, handleGetReceivables]
  )

  const handleGoToAsaasExport = () => navigate(FINANCE_ROUTES.RECEIPTS_ASAAS)

  useEffect(() => {
    handleSelectedPayments()
  }, [selectedItems])

  const handleGetFinanceGenericParameters = useCallback(() => {
    dispatch(getFinanceGenericParameters())
  }, [])

  const handleFilters = () => setFilterDrawerOpen(prevState => !prevState)

  const BudgetInstallmentsFilter = useMemo(
    () => (
      <Filters
        handleFilterDrawer={handleFilters}
        params={filters}
        open={filterDrawerOpen}
        setPage={setPage}
        handleGetReceivables={handleGetReceivables}
      />
    ),
    [filterDrawerOpen, filters]
  )

  const handleCellClick = useCallback(
    params => {
      if (params.field === '__check__') return
      if (params.field === 'serviceOrderId') {
        window.open(
          `${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}`,
          '_blank',
          'noopener,noreferrer'
        )
        return
      }
      const url = isAdmin
        ? `${FINANCE_ROUTES.RECEIPT_DETAILS}/${params.row.id}`
        : `${ROUTES.FINANCE_MANAGER}/detalhes-recebimento/${params.row.id}`

      window.open(url, '_blank', 'noopener,noreferrer')
    },
    [isAdmin]
  )

  const handleGoBackAndUpdate = useCallback(
    ({ update = false }) => {
      setMenuType('default')

      if (update) {
        handleGetReceivables()
        setSelectedItems([])
      }
    },
    [menuType, handleGetReceivables]
  )

  const renderConfirmationScreen = useMemo(() => {
    if (menuType === 'confirm' || menuType === 'cancel') {
      return (
        <ReceiptsConfirmation
          goBack={handleGoBackAndUpdate}
          items={confirmationScreenPayments}
          menuType={menuType}
        />
      )
    }
    return null
  }, [menuType, confirmationScreenPayments])

  const renderVoucherScreen = useMemo(() => {
    if (menuType === 'confirm-voucher' || menuType === 'remove-voucher') {
      const filteredBasedOnVoucher =
        menuType === 'confirm-voucher'
          ? selectedPayments?.filter(item => !item?.voucher)
          : selectedPayments?.filter(item => item?.voucher)

      return (
        <ConfirmVoucher
          menuType={menuType}
          handleGetReceivables={handleGetReceivables}
          handleGoBackAndUpdate={handleGoBackAndUpdate}
          items={filteredBasedOnVoucher}
        />
      )
    }
    return null
  }, [menuType, confirmationScreenPayments, selectedPayments])

  useEffect(() => {
    handleGetFinanceGenericParameters()
    handleGetVerificationToken()
  }, [])

  useEffect(() => {
    handleGetReceivables()
  }, [page, pageSize, orderBy])

  return (
    <Grid className={styles.page}>
      {BudgetInstallmentsFilter}
      <>
        {menuType === 'default' && (
          <>
            <HeaderTitle title={title}>
              <Grid className={styles.buttonsContainer}>
                {isAdmin && (
                  <Button
                    variant="secondary"
                    startIcon={<DocumentText color={Theme.Colors.Primary.Base} />}
                    onClick={handleGoToAsaasExport}
                  >
                    Fechamento
                  </Button>
                )}
                <Button
                  variant="ghost"
                  startIcon={<FilterIcon color={buttonColor} />}
                  onClick={handleFilters}
                  disabled={isLoading}
                >
                  Filtros
                </Button>
              </Grid>
            </HeaderTitle>
            {/* Only shows table when filter is applied */}
            {isFilterDirty ? (
              <Grid className={styles.tableContainer}>
                <Grid className={styles.mainTable}>
                  <Datagrid
                    loading={isLoading}
                    rows={receipts?.results?.length ? receipts?.results : []}
                    columns={receiptsColumns}
                    paginationMode="server"
                    onPageChange={setPage}
                    page={page}
                    onPageSizeChange={setPageSize}
                    pageSize={pageSize}
                    rowCount={receipts?.count}
                    onSortModelChange={handleOrderBy}
                    checkboxSelection
                    disableSelectionOnClick
                    onCellClick={handleCellClick}
                    onSelectionModelChange={setSelectedItems}
                    keepNonExistentRowsSelected
                    selectionModel={selectedItems}
                    sx={TABLE_SX}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid className={styles.emptyScreenWrapper}>
                <EmptyScreen
                  Icon={SearchIcon}
                  title="Favor aplicar um filtro"
                  subtitle="Para ver registros nesta tela, abra a área de filtros e aplique critérios para sua pesquisa"
                />
              </Grid>
            )}
            {selectedItems?.length > 0 && (
              <FloatingMenu
                selectedItems={selectedItems}
                items={selectedPayments}
                handleButtonsActions={handleFloatingMenuActions}
                handleFloatingModalNavigation={handleFloatingModalNavigation}
              />
            )}
          </>
        )}
        {downloadSpreadsheetModalOpen && (
          <DownloadSpreadsheet
            open={downloadSpreadsheetModalOpen}
            close={() => setDownloadSpreadsheetModalOpen(false)}
            filters={filters}
          />
        )}
        {changeInstallmentsModalOpen && (
          <ChangeInstallmentModal
            open={changeInstallmentsModalOpen}
            close={() => setChangeInstallmentsModalOpen(false)}
            onConfirm={handleUpdateInstallmentQuantity}
            isLoading={isLoading}
          />
        )}
        {renderVoucherScreen}
        {renderConfirmationScreen}
      </>
    </Grid>
  )
}

export default Receipts
