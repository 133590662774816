import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core'

import useStyles from './styles'

export default function SuccessDialog({ closeModal }) {
  const styles = useStyles()

  return (
    <Dialog open onClose={closeModal}>
      <DialogContent>
        <DialogContentText className={styles.description}>
          Ação executada com sucesso.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

SuccessDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
}
