import { schema } from 'normalizr'

import Model from './model'

class AgencyVip extends Model({
  isActive: undefined,
  agency: undefined,
  cpfCnpj: undefined,
  id: undefined,
  isVip: undefined,
  forbiddenContact: undefined,
}) {}

const agencyVipNewSchema = new schema.Entity(
  'agencyVip',
  {},
  {
    processStrategy: entity => new AgencyVip(entity),
  }
)

const agencyVipSchema = [agencyVipNewSchema]

export { agencyVipSchema, AgencyVip as default }
