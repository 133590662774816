import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Alert, AlertTitle, Collapse } from '@mui/material'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Warning as WarningIcon, WarningRounded as WarningRoundedIcon } from '@mui/icons-material'
import Theme from '@refera/ui-core'
import classnames from 'classnames'

import Button from '_components/button'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'

import useStyles, { alertSxStyle } from './styles'

const AlertWrap = ({ isCollapsable, open, children }) => {
  if (isCollapsable) {
    return <Collapse in={open}>{children}</Collapse>
  }

  return children
}

const ServiceOrderAlert = ({
  title,
  description,
  onClick,
  onClose,
  color,
  isOpen,
  isCollapsable,
}) => {
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const styles = useStyles({ step: serviceOrder?.orderStep })

  const icon = useMemo(() => {
    if (color === 'warning') {
      return {
        component: WarningRoundedIcon,
        color: Theme.Colors.Yellow.Base,
      }
    }

    return {
      component: WarningIcon,
      color: 'error',
    }
  }, [color])

  return (
    <Grid className={styles.alertInfo}>
      <AlertWrap isCollapsable={isCollapsable} open={isOpen}>
        <Alert
          severity="warning"
          color={color}
          icon={<icon.component fontSize="large" color={icon.color} />}
          sx={alertSxStyle}
          onClose={onClose}
          className={classnames({
            [styles.groud]: color === 'warning',
          })}
        >
          <AlertTitle>
            <Typography variant="h6" component="h6" className={styles.alertTitle}>
              {title}
            </Typography>
          </AlertTitle>
          <Typography variant="body1" component="span" className={styles.reasonText}>
            {description}
          </Typography>
          <br />
          <Button className={styles.moreDetails} onClick={onClick}>
            Ver mais detalhes
          </Button>
        </Alert>
      </AlertWrap>
    </Grid>
  )
}

ServiceOrderAlert.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  color: PropTypes.oneOf(['warning', 'error']),
  isCollapsable: PropTypes.bool,
  isOpen: PropTypes.bool,
}

ServiceOrderAlert.defaultProps = {
  color: 'warning',
  onClose: undefined,
  isCollapsable: false,
  isOpen: false,
}

AlertWrap.propTypes = {
  isCollapsable: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default ServiceOrderAlert
