import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Select,
  MenuItem as SelectOption,
  InputLabel,
} from '@material-ui/core'
import { Divider, Grid } from '@mui/material'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
// BYPASS FOR ADJUSTS IN ALPHA
// import { Select, SelectOption } from '@refera/ui-web'
import { useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'

import Button from '_components/button'
import Snackbar from '_components/snackbar'
import { updateBudget, UPDATE_BUDGET } from '_modules/budget/actions'

import useStyles from './styles'

import { CLASSIFICATED_SERVICE_TYPE, INITIAL_STATE, NEGOCIATION_TYPE } from './constants'
import { getHistoryLogs } from '_/modules/history-logs/actions'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { getServiceOrder } from '_/modules/service-orders/actions'

const ReviseBudgetDialog = ({
  isOpen,
  setIsReviseBudgetDialogOpen,
  serviceOrderId,
  budgetId,
  handleIsRevised,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { showToast } = useToast()
  const { handleSubmit, control } = useForm({ INITIAL_STATE })

  const onSuccess = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))
    dispatch(getServiceOrder(serviceOrderId))
    showToast({ type: 'success' })
    handleIsRevised()
    setIsReviseBudgetDialogOpen(false)
  }, [handleIsRevised, isOpen, showToast])

  const onFailed = useCallback(
    error => {
      if (isOpen) {
        showToast({ type: 'error', message: formatErrorMessage(error) })
      }
    },
    [isOpen]
  )

  const onSubmit = useCallback(
    handleSubmit(async formData => {
      setIsLoading(true)
      await dispatch(updateBudget(serviceOrderId, budgetId, { isRevised: true, ...formData }))
        .then(() => {
          onSuccess()
          setIsLoading(false)
        })
        .catch(error => {
          onFailed(error)
          setIsLoading(false)
        })
    }),
    [budgetId, dispatch, serviceOrderId, onSuccess, onFailed]
  )

  return (
    <>
      <Dialog
        className={styles.overflow}
        open={isOpen}
        onClose={() => setIsReviseBudgetDialogOpen(false)}
        aria-describedby="reviseBudgetDialog"
      >
        <Button onClick={() => setIsReviseBudgetDialogOpen(false)} className={styles.closeButton}>
          <HighlightOffSharpIcon />
        </Button>
        <DialogTitle id="reviseBudgetDialog" className={styles.title}>
          Mais informações sobre a revisão
        </DialogTitle>
        <Divider />
        <form onSubmit={onSubmit}>
          <DialogContent className={styles.dialog}>
            <Grid className={styles.contentContainer} container direction="column">
              <Grid className={styles.selectInput}>
                <InputLabel className={styles.selectInputLabel}>
                  Negociação do orçamento *
                </InputLabel>
                <Controller
                  name="wasBudgetNegotiated"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={null}
                  variant="standard"
                  as={
                    <Select
                      label="Negociação do orçamento *"
                      control={control}
                      className={styles.selectContainer}
                      fullWidth
                    >
                      {NEGOCIATION_TYPE.map(option => (
                        <SelectOption
                          key={option.label}
                          value={option.value}
                          className={styles.optionContainer}
                        >
                          <Typography className={styles.optionLabel}>{option.label}</Typography>
                          <Typography className={styles.optionHelperText}>
                            {option.helperText}
                          </Typography>
                        </SelectOption>
                      ))}
                    </Select>
                  }
                />
              </Grid>

              <Grid className={styles.selectInput}>
                <InputLabel className={styles.selectInputLabel}>Serviço classificado *</InputLabel>
                <Controller
                  name="isServiceCataloged"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={null}
                  as={
                    <Select
                      label="Serviço classificado *"
                      control={control}
                      variant="standard"
                      fullWidth
                    >
                      {CLASSIFICATED_SERVICE_TYPE.map(option => (
                        <SelectOption
                          key={option.label}
                          value={option.value}
                          className={styles.optionContainer}
                        >
                          <Typography className={styles.optionLabel}>{option.label}</Typography>
                          <Typography className={styles.optionHelperText}>
                            {option.helperText}
                          </Typography>
                        </SelectOption>
                      ))}
                    </Select>
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={styles.buttonContainer}>
            <Button
              onClick={() => setIsReviseBudgetDialogOpen(false)}
              variant="outlined"
              className={styles.button}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              isLoading={isLoading}
              color="primary"
              variant="contained"
              className={styles.button}
              disabled={isLoading}
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
        <Snackbar action={[UPDATE_BUDGET.ACTION]} />
      </Dialog>
    </>
  )
}

ReviseBudgetDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  budgetId: PropTypes.number.isRequired,
}

export default React.memo(ReviseBudgetDialog)
