/* eslint-disable spaced-comment */
import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: spacing(64),
    height: spacing(64),
    backgroundColor: Theme.Colors.Primary.Lightest,
    borderRadius: '50%',
    marginBottom: spacing(16),
  },
  icon: {
    fill: Theme.Colors.Primary.Base,
    width: spacing(32),
    height: spacing(32),
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing(12),
    maxWidth: spacing(360),
    textAlign: 'center',
    color: Theme.Colors.Grayscale.SixtyFour,
  },
  title: {
    color: Theme.Colors.Primary.Base,
    fontWeight: '500',
    fontSize: spacing(18),
  },
}))
