export const vipClientCountSelector = state => state.agencyVip?.get('count')

export const vipClientPageSelector = state => state.agencyVip?.get('page')

export const vipClientPageSizeSelector = state => state.agencyVip?.get('pageSize')

export const vipClientSelector = state => {
  const results = state.agencyVip?.get('results')
  const order = state.agencyVip?.get('orderAgency')

  return order?.map(id => results.get(id?.toString()))
}

export const getVipClientByIdSelector = id => state => state.agencyVip?.getIn(['results', id])
