import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  toolbarClassName: {
    boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.1)',
    marginBottom: '0 !important',
    backgroundColor: '#fbfbfb !important',

    '& .rdw-dropdown-optionwrapper': {
      overflowY: 'scroll',
      maxHeight: '125px',
    },
  },
  wrapperClassName: {
    border: '1px solid rgb(231, 221, 221)',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperOnError: {
    borderColor: '#C90000 !important',
  },
  editorClassName: {
    padding: '1rem',
    fontSize: '1rem',
    minHeight: '150px',

    '& .public-DraftEditorPlaceholder-inner': {
      fontSize: spacing(16),
      fontStyle: 'italic',
    },

    '& .public-DraftStyleDefault-unorderedListItem::marker': {
      fontSize: spacing(16),
      top: '50%',
      transform: 'translateY(-50%)',
    },

    '& .public-DraftStyleDefault-orderedListItem::before': {
      fontSize: spacing(16),
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
}))
