import * as utilsService from '_services/utils'
import { defineAction } from '_utils/redux'

export const GET_CITIES = defineAction('GET_CITIES')
export const GET_STATES = defineAction('GET_STATES')
export const GET_ARTICLES = defineAction('GET_ARTICLES')
export const GET_CSREFERA = defineAction('GET_CSREFERA')
export const GET_SECTORS_OPTIONS = defineAction('GET_SECTORS_OPTIONS')
export const GET_BANK_LIST = defineAction('GET_BANK_LIST')
export const TRIGGER_SNACKBAR = defineAction('TRIGGER_SNACKBAR')
export const GET_REASONS_TO_CANCEL = defineAction('GET_REASONS_TO_CANCEL')
export const GET_REASONS_TO_CANCEL_SERVICE = defineAction('GET_REASONS_TO_CANCEL_SERVICE')
export const GET_CITIES_FILTERED = 'GET_CITIES_FILTERED'
export const TOGGLE_SHOW_CONTRACT_MODAL_MANAGER = 'TOGGLE_SHOW_CONTRACT_MODAL_MANAGER'
export const GET_ALL_ROLES = defineAction('GET_ALL_ROLES')
export const GET_ALL_ROLE_GROUPS = defineAction('GET_ALL_ROLE_GROUPS')

export const getCities = () => async (dispatch, getState) => {
  const queryParams = {
    pageSize: 1000,
  }
  const data = await utilsService.getCities(getState().user.authToken)(queryParams)

  dispatch({
    type: GET_CITIES.ACTION,
    payload: async () => data,
  })

  return data
}

export const getStates = () => async (dispatch, getState) => {
  const queryParams = {
    pageSize: 1000,
  }
  const data = await utilsService.getStates(getState().user.authToken)(queryParams)

  dispatch({
    type: GET_STATES.ACTION,
    payload: async () => data,
  })

  return data
}

export const getArticles = () => async (dispatch, getState) => {
  const data = await utilsService.getArticles(getState().user.authToken)
  dispatch({
    type: GET_ARTICLES.ACTION,
    payload: async () => data,
  })

  return data
}

export const getCsrefera = () => (dispatch, getState) => {
  const queryParams = {
    pageSize: 1000,
  }
  const data = utilsService.getCsrefera(getState().user.authToken)(queryParams)
  dispatch({
    type: GET_CSREFERA.ACTION,
    payload: data,
    meta: queryParams,
  })
  return data
}

export const getSectorsOptions = () => (dispatch, getState) => {
  const data = utilsService.getSectorsOptions(getState().user.authToken)
  dispatch({
    type: GET_SECTORS_OPTIONS.ACTION,
    payload: data,
  })

  return data
}

export const getCitiesFiltered = search => dispatch =>
  dispatch({
    type: GET_CITIES_FILTERED,
    payload: search,
  })

export const getBankList = () => async (dispatch, getState) => {
  const data = await utilsService.getBankList(getState().user.authToken)
  dispatch({
    type: GET_BANK_LIST.ACTION,
    payload: async () => data,
  })

  return data
}

export const getReasonsToCancel = params => (dispatch, getState) =>
  dispatch({
    type: GET_REASONS_TO_CANCEL.ACTION,
    payload: utilsService.getReasonsToCancel(getState().user.authToken)(params),
  })

export const getReasonsToCancelService = (dispatch, getState) =>
  dispatch({
    type: GET_REASONS_TO_CANCEL_SERVICE.ACTION,
    payload: utilsService.getReasonsToCancelService(getState().user.authToken),
  })

export const triggerSnackbar = error => dispatch =>
  dispatch({
    type: TRIGGER_SNACKBAR.ACTION,
    payload: new Promise(res => {
      setTimeout(res, 10)
    }),
    meta: { error },
  })

export const toggleShowContractModalManager = () => dispatch =>
  dispatch({
    type: TOGGLE_SHOW_CONTRACT_MODAL_MANAGER,
  })

export const getAllRoles = () => async (dispatch, getState) =>
  dispatch({
    type: GET_ALL_ROLES.ACTION,
    payload: utilsService.getAllRoles(getState().user.authToken),
  })

export const getAllRoleGroups = () => async (dispatch, getState) => {
  const data = await utilsService.getAllRoleGroups(getState().user.authToken)

  dispatch({
    type: GET_ALL_ROLE_GROUPS.ACTION,
    payload: async () => data,
  })

  return data
}
