import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Modal from '_components/modal'

import useStyles from './styles'

const BudgetActionModal = ({ title, text, handleModal, handleConfirm, style }) => {
  const styles = useStyles()

  const handleConfirmAction = useCallback(() => {
    handleConfirm()
  }, [handleConfirm])

  return (
    <Modal
      hideRightButton
      title={title}
      handleModal={handleModal}
      handleSubmit={handleConfirmAction}
      className={`${styles.modal} ${style}`}
      leftButtonText="Entendi"
    >
      <p className={styles.modalText}>{text}</p>
    </Modal>
  )
}

BudgetActionModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  style: PropTypes.string,
  handleModal: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
}

BudgetActionModal.defaultProps = {
  style: '',
}

export default React.memo(BudgetActionModal)
