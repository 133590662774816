import { defineAction } from '_utils/redux'
import * as AgencyVipServices from '_services/agencyVip'

export const GET_VIP_CLIENTS = defineAction('GET_VIP_CLIENTS')
export const GET_VIP_CLIENT_BY_ID = defineAction('GET_VIP_CLIENT_BY_ID')
export const CREATE_VIP_CLIENT = defineAction('CREATE_VIP_CLIENT')
export const UPDATE_VIP_CLIENT = defineAction('UPDATE_VIP_CLIENT')
export const DELETE_VIP_CLIENT = defineAction('DELETE_VIP_CLIENT')

export const getVipClients = params => async (dispatch, getState) => {
  const queryParams = {
    pageSize: params?.pageSize || getState().clients.size,
    order: params?.order || getState().clients.params?.order,
    ...params,
  }

  const data = await AgencyVipServices.getVipClients(getState().user.authToken)(queryParams)

  dispatch({
    type: GET_VIP_CLIENTS.ACTION,
    payload: async () => data,
    meta: queryParams,
  })

  return data
}

export const getVipClientById = clientId => async (dispatch, getState) => {
  const data = await AgencyVipServices.getVipClientById(getState().user.authToken)(clientId)

  dispatch({
    type: GET_VIP_CLIENT_BY_ID.ACTION,
    payload: async () => data,
  })

  return data
}

export const createVipClient = payload => async (dispatch, getState) => {
  const data = await AgencyVipServices.createVipClient(getState().user.authToken)(payload)

  dispatch({
    type: CREATE_VIP_CLIENT.ACTION,
    payload: async () => data,
  })

  return data
}

export const updateVipClient = (clientId, payload) => async (dispatch, getState) => {
  const data = await AgencyVipServices.updateVipClient(getState().user.authToken)(clientId, payload)

  dispatch({
    type: UPDATE_VIP_CLIENT.ACTION,
    payload: async () => data,
  })

  return data
}

export const deleteVipClient = clientId => async (dispatch, getState) => {
  const data = await AgencyVipServices.deleteVipClient(getState().user.authToken)(clientId)

  dispatch({
    type: DELETE_VIP_CLIENT.ACTION,
    payload: async () => data,
  })

  return data
}
