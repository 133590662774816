/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useMemo, useState } from 'react'
import { Button as MUIButton, Grid, Typography } from '@mui/material'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { STEP_STATUS } from '_/utils/constants/service-order'

import * as Modal from '_components/modal/generic-modal'
import HelpForm from '_/views/service-order/help/help-form'
import { getAllNotes } from '_/modules/messages/actions'
import { INITIAL_STATE } from '_/views/service-order/help/help-form/reducer'

export function ReprovedServiceDetails() {
  const styles = useStyles()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)

  const relatedReasonText = useMemo(() => {
    if (serviceOrder?.stepStatus === STEP_STATUS.SERVICE_FINALIZATION_REPROVED_REQUESTER) {
      return 'Serviços executados'
    }

    return serviceOrder?.reprovedReason?.get('relatedTo') || 'Não informado'
  })

  const relatedReasonDescText = useMemo(() => {
    if (serviceOrder?.stepStatus === STEP_STATUS.SERVICE_FINALIZATION_REPROVED_REQUESTER) {
      return serviceOrder?.negativeFeedback || 'Não informado'
    }

    return serviceOrder?.reprovedReason?.get('description') || 'Não informado'
  })

  const [openHelpForm, setOpenHelpForm] = useState(false)
  const [helpObject, setHelpObject] = useState(null)

  function handleHelpRequest() {
    setShowModal(false)
    setOpenHelpForm(true)
  }

  const handleClearSelectedMessage = useCallback(() => {
    setOpenHelpForm(false)
    dispatch(getAllNotes({ serviceOrderId: serviceOrder?.id }))
  }, [dispatch, serviceOrder])

  return (
    <>
      {openHelpForm && (
        <HelpForm
          closeModal={handleClearSelectedMessage}
          onCancel={() => setOpenHelpForm(false)}
          message={helpObject}
          setMessage={setHelpObject}
          initialState={{
            ...INITIAL_STATE,
            fromTo: 'tradesman_to_refera',
            subject: 'service_disapproval_dispute',
          }}
          disabledFields={{ fromTo: true, subject: false }}
        />
      )}

      <Modal.Root open={showModal} onClose={() => setShowModal(false)}>
        <Modal.TitleModal className={styles.titleModal} title="Mais detalhes" />
        <Modal.Content className={styles.contentModal}>
          <Grid className={styles.content}>
            <Typography className={styles.subTitleModal}>
              Leia atentamente o motivo da reprovação e se necessário fale com o cliente para
              combinar um novo momento para realizar os ajustes no serviço.
            </Typography>
            <Typography className={styles.subTitleModal}>
              <strong>Atenção:</strong> Caso não concorde com a reprovação, clique em{' '}
              <strong>Quero discordar</strong> e nos explique a situação.
            </Typography>
          </Grid>
          <Typography className={styles.text}>
            <strong>Relacionado à:</strong>
          </Typography>
          <Grid className={styles.card}>
            <Typography className={styles.text}>{relatedReasonText}.</Typography>
          </Grid>
          <Typography className={styles.text}>
            <strong>Descrição:</strong>
          </Typography>
          <Grid className={styles.card}>
            <Typography className={styles.text}>{relatedReasonDescText}.</Typography>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Modal.ButtonRed onClick={() => setShowModal(false)}>Fechar</Modal.ButtonRed>
          <Modal.ButtonFullBlue onClick={handleHelpRequest}>Quero discordar</Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>

      <Grid className={styles.container}>
        <ReportProblemIcon className={styles.icon} />
        <Grid className={styles.content}>
          <Typography className={styles.title}>Serviço reprovado</Typography>
          <Typography className={styles.subTitle}>
            Esse serviço foi recusado pelo seguinte motivo: {relatedReasonText}.
          </Typography>
          <MUIButton className={styles.button} onClick={() => setShowModal(true)}>
            Ver mais detalhes
          </MUIButton>
        </Grid>
      </Grid>
    </>
  )
}
