import { makeStyles } from '@material-ui/styles'

import { theme } from '../utils/theme'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  label: {
    marginBottom: spacing(12),
  },
  labelDoc: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    color: '#141414',
  },
  paper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: 'auto auto auto',
    gridTemplateAreas: props =>
      props.hasFiles ? "'. title'" : "'icon title' 'icon description' '. button'",
    backgroundColor: theme.palette.gray[4],
    padding: props => (props.hasFiles ? spacing(14, 30) : spacing(24, 30)),
    columnGap: spacing(24),
  },
  title: {
    gridArea: 'title',
    color: theme.palette.gray[80],
  },
  description: {
    gridArea: 'description',
  },
  icon: {
    gridArea: 'icon',
    height: spacing(34),
    width: spacing(34),
  },
  button: {
    gridArea: props => (props.hasFiles ? 'title' : 'button'),
    marginTop: props => (props.hasFiles ? 0 : spacing(24)),
    textTransform: 'none',
    textDecoration: 'underline',
    width: props => (props.hasFiles || props.document ? '16rem' : '11rem'),
    padding: 0,

    '& .MuiButton-label': {
      marginRight: 'auto',
    },
  },
  fileSelector: {
    display: 'none',
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, ${spacing(80)})`,
    gridTemplateRows: 'auto',
    gap: spacing(16),
    marginBottom: spacing(16),
  },
  cardsDoc: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, ${spacing(80)})`,
    gridTemplateRows: 'auto',
    gap: spacing(16),
    marginBottom: spacing(8),
  },
  loader: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, ${spacing(80)})`,
    gridTemplateRows: `repeat(4, ${spacing(80)})`,
  },
}))
