import moment from 'moment'

export const FORM_FIELD_NAME = {
  ANTICIPATION: 'anticipation',
  PAYMENT_DATE_START: 'paymentDateStart',
  PAYMENT_DATE_END: 'paymentDateEnd',
  REQUESTER: 'requester',
  SERVICE_ORDER: 'serviceOrder',
  STATUS: 'status',
  DUE_DATE_END: 'dueDateEnd',
  DUE_DATE_START: 'dueDateStart',
  PAYMENT_VALUE_START: 'paymentValueStart',
  PAYMENT_VALUE_END: 'paymentValueEnd',
}

export const INITIAL_STATE = {
  [FORM_FIELD_NAME.ANTICIPATION]: '',
  [FORM_FIELD_NAME.PAYMENT_DATE_START]: null,
  [FORM_FIELD_NAME.PAYMENT_DATE_END]: null,
  [FORM_FIELD_NAME.REQUESTER]: '',
  [FORM_FIELD_NAME.SERVICE_ORDER]: '',
  [FORM_FIELD_NAME.STATUS]: '',
  [FORM_FIELD_NAME.DUE_DATE_END]: null,
  [FORM_FIELD_NAME.DUE_DATE_START]: null,
  [FORM_FIELD_NAME.PAYMENT_VALUE_START]: null,
  [FORM_FIELD_NAME.PAYMENT_VALUE_END]: null,
  isValid: true,
  paymentDate: '',
  dueDate: '',
  paymentValue: '',
}

const PAYMENT_DATE_FIELDS = [FORM_FIELD_NAME.PAYMENT_DATE_START, FORM_FIELD_NAME.PAYMENT_DATE_END]
const DUE_DATE_FIELDS = [FORM_FIELD_NAME.DUE_DATE_START, FORM_FIELD_NAME.DUE_DATE_END]

export const UPDATE_STATE = 'updateState'
export const RESET_STATE = 'resetState'

const handleValidDates = (startDate, endDate) => {
  if (startDate || endDate) {
    return moment(startDate).isValid() || moment(endDate).isValid()
  }

  return false
}

export const reducer = (state, { type, payload }) => {
  const dateValidation = () => {
    const newState = {
      ...state,
      ...payload,
    }

    return Object.keys(payload).reduce((acc, payloadKey) => {
      if (!acc) {
        return acc
      }

      if (DUE_DATE_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.DUE_DATE_START],
          newState[FORM_FIELD_NAME.DUE_DATE_END]
        )
      }

      if (PAYMENT_DATE_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.PAYMENT_DATE_START],
          newState[FORM_FIELD_NAME.PAYMENT_DATE_END]
        )
      }

      return acc
    }, true)
  }

  switch (type) {
    case UPDATE_STATE:
      return {
        ...state,
        ...payload,
        isValid: dateValidation(),
      }
    case RESET_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
