import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, OutlinedInput } from '@material-ui/core'
import { List } from 'immutable'
import { useDispatch } from 'react-redux'

import Modal from '_components/modal'
import Select from '_components/select'
import Button from '_components/button'
import ModalDialog, { WARNING_MODAL } from '_components/modal/modal-dialog'
import { cancelServiceOrder } from '_modules/service-orders/actions'
import { getHistoryLogs } from '_modules/history-logs/actions'

import useStyles from './styles'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'

const CancelModal = ({
  serviceOrderId,
  handleModal,
  cancelReasons,
  handleGetServiceOrderActive,
}) => {
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const styles = useStyles()
  const [reason, setReason] = useState('')
  const [serviceOrderCancelDetails, setServiceOrderCancelDetails] = useState('')
  const [enabledConfirmButton, setEnableConfirmButton] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const onSelect = useCallback(event => {
    const { value } = event.target
    setReason(value)
    setEnableConfirmButton(true)
  }, [])

  const handleInputChange = useCallback(event => {
    const { value } = event.target
    setServiceOrderCancelDetails(value)
  })

  const onCancelServiceRejected = useCallback(response => {
    setModalDialog({
      isOpen: true,
      subTitle: response,
      type: WARNING_MODAL,
    })
  }, [])

  const handleCancelServiceOrder = useCallback(() => {
    setIsLoading(true)
    dispatch(cancelServiceOrder(reason, serviceOrderCancelDetails))
      .then(() => {
        dispatch(getHistoryLogs(serviceOrderId))
        handleGetServiceOrderActive()
        handleModal()
      })
      .catch(err => {
        onCancelServiceRejected(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [dispatch, reason, serviceOrderCancelDetails])

  return (
    <Modal
      title={`Cancelar chamado ID #${serviceOrderId}`}
      handleModal={handleModal}
      hideActionButtons
    >
      <Grid className={styles.container}>
        <Select
          label="Selecione o motivo do cancelamento"
          options={cancelReasons?.toArray()}
          value={reason}
          onChange={onSelect}
        />
        <Typography className={styles.textTitle}>Mais detalhes do cancelamento</Typography>
        <OutlinedInput
          multiline
          className={styles.textInput}
          inputProps={{ maxLength: 1000 }}
          placeholder="Mais detalhes"
          name="serviceOrderCancelDetails"
          value={serviceOrderCancelDetails}
          onChange={handleInputChange}
        />
        <ConfidentialDataWarning style={{ marginBottom: -16 }} />
        <Button className={styles.button} fullWidth onClick={handleModal} disabled={isLoading}>
          Desfazer e manter chamado
        </Button>
        <Button
          isLoading={isLoading}
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleCancelServiceOrder}
          disabled={!enabledConfirmButton}
        >
          Confirmar e cancelar chamado
        </Button>
      </Grid>
      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
    </Modal>
  )
}

CancelModal.propTypes = {
  serviceOrderId: PropTypes.string.isRequired,
  handleModal: PropTypes.func.isRequired,
  cancelReasons: PropTypes.instanceOf(List).isRequired,
}

export default React.memo(CancelModal)
