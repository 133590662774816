import React, { useCallback } from 'react'
import { Grid, Typography, Paper, Button, RootRef } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import Svg from '_components/svg'
import FileIcon from '_assets/icons/ic-file.svg'
import CardMedia from '_components/card-media'
import Loading from '_/components/loading'

import useStyles from './styles'

const PictureInput = ({ name, value, onChange, isDocument, isLoading, disabled = false }) => {
  const onDrop = useCallback(
    file => {
      const newValue = isDocument ? [...value, file[0]] : [...value, ...file]
      onChange({ target: { name, value: newValue } })
    },
    [name, onChange, value]
  )

  const { getRootProps, getInputProps } = useDropzone(
    isDocument
      ? {
          accept: 'image/png, image/jpeg, image/jpg, .pdf',
          onDrop,
        }
      : {
          accept:
            'image/png, image/jpeg, image/jpg, image/bmp, video/mp4, video/mkv, video/mov, video/flv, .pdf',
          onDrop,
        }
  )
  const { ref, ...rootProps } = getRootProps()
  const styles = useStyles({ hasFiles: !!value?.length, document: isDocument })

  const onRemoveClick = useCallback(
    fileName => {
      const newValue = Number(fileName)
        ? value.filter(file => file.lastModified !== Number(fileName))
        : value.filter(file => file?.path !== fileName)

      onChange({ target: { name, value: newValue } })
    },
    [name, onChange, value]
  )

  return (
    <Grid className={styles.container}>
      <Grid container justify="space-between">
        {isDocument ? (
          <>
            <Typography className={styles.labelDoc} component="label">
              Comprovante
            </Typography>
          </>
        ) : (
          <>
            <Typography
              className={styles.label}
              color="textSecondary"
              component="label"
              htmlFor="picture-input"
              variant="h3"
            >
              Anexos
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Opcional
            </Typography>
          </>
        )}
      </Grid>
      {isLoading ? (
        <Loading className={styles.loader} />
      ) : (
        <>
          <Grid className={isDocument ? styles.cardsDoc : styles.cards}>
            {value?.map(file => (
              <CardMedia
                key={file?.lastModified}
                onRemoveClick={onRemoveClick}
                file={file?.path && !file?.lastModified ? file?.path : file}
                showOnly={disabled}
              />
            ))}
          </Grid>
          {(value?.length && isDocument) || disabled ? null : (
            <RootRef rootRef={ref}>
              <Paper {...rootProps} className={styles.paper} id="picture-input" variant="outlined">
                {!value?.length && (
                  <>
                    <Svg className={styles.icon} icon={FileIcon} />
                    <Typography className={styles.title} component="p" variant="body1">
                      {isDocument
                        ? 'Arraste aqui ou selecione o anexo'
                        : 'Arraste aqui ou selecione os anexos'}
                    </Typography>
                    <Typography
                      className={styles.description}
                      color="textSecondary"
                      component="p"
                      variant="body2"
                    >
                      {isDocument
                        ? '.JPG, .JPEG, .PNG ou .PDF'
                        : '.JPG, .JPEG, .PNG, .BMP, .MP4, .MKV, .MOV, .FLV ou .PDF'}
                    </Typography>
                  </>
                )}
                <input {...getInputProps()} />
                <Button color="primary" className={styles.button}>
                  {value?.length
                    ? 'Adicionar mais arquivos'
                    : isDocument
                    ? 'Selecionar arquivo'
                    : 'Selecionar fotos'}
                </Button>
              </Paper>
            </RootRef>
          )}
        </>
      )}
    </Grid>
  )
}

PictureInput.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      lastModified: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isDocument: PropTypes.bool,
  isLoading: PropTypes.bool,
}
PictureInput.defaultProps = {
  isDocument: false,
  isLoading: false,
}

export default React.memo(PictureInput)
