import React, { useCallback, useEffect } from 'react'

import useStyles from './styles'
import { useParams, navigate } from '@reach/router'

import { BasicInput, Checkbox, MaskBasicInput } from '_/components/inputs'
import { useDispatch, useSelector } from 'react-redux'
import { getReferaUserDetails } from '_/modules/user/actions'
import { useDialog } from '_/hooks/use-dialog'
import { useFormContext } from 'react-hook-form'
import { applyCPFMask } from './constants'
import { ROUTES } from '_/utils/constants'
import { AddGroupPermissions } from './add-group-permission'
import { getPermissionsGroupSelector } from '_/modules/profile/selectors'
import { getProfilePermissionGroup } from '_/modules/profile/actions'
import { useToast } from '_/hooks/use-toast'

export function Form({ setIsLoading, permissionsGroup, setPermissionsGroup }) {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { showDialog, closeDialog } = useDialog()
  const { setValue } = useFormContext()
  const groupsPermission = useSelector(getPermissionsGroupSelector)
  const { showToast } = useToast()

  const getPermissionsGroup = useCallback(() => {
    if (!groupsPermission?.results || groupsPermission?.results?.length === 0) {
      setIsLoading(true)

      return dispatch(
        getProfilePermissionGroup({
          profile: 'refera',
          pageSize: 1000,
        })
      )
        .catch(() => {
          showToast({
            type: 'error',
            message: 'Ocorreu um erro ao buscar os registros dos grupos de permissões.',
          })
          return setIsLoading(false)
        })
        .finally(() => setIsLoading(false))
    }

    return null
  }, [dispatch])

  const getUserRefera = useCallback(() => {
    setIsLoading(true)
    dispatch(getReferaUserDetails(userId))
      .then(data => {
        setValue('name', data.name)
        setValue('cpf', applyCPFMask(data.cpfOrCnpj))
        setValue('email', data.email)
        setValue('admin', data.admin)
        setValue('digisacUserId', data.digisacUserId)
        setPermissionsGroup(data.permissionsGroup)
      })
      .catch(() => {
        showDialog({
          labelApprove: 'Ok',
          subject: 'Ocorreu um erro ao buscar dados do usuário. Tente novamente mais tarde.',
          type: 'error',
          onApprove: () => {
            closeDialog()
            navigate(ROUTES.REFERA_USERS)
          },
        })
      })
      .finally(() => setIsLoading(false))
  }, [userId, dispatch])

  useEffect(() => {
    getPermissionsGroup()
    if (userId) {
      getUserRefera()
    }
  }, [userId])

  return (
    <form className={styles.form}>
      <div className={styles.row}>
        <BasicInput label="Nome" name="name" autocomplete="name" required />
        <MaskBasicInput
          label="CPF"
          name="cpf"
          mask="999.999.999-99"
          required
          autocomplete="cpf"
          labelClasses={styles.label}
          containerClasses={styles.maskInput}
        />
        <BasicInput label="E-mail" name="email" required autocomplete="email" disabled={userId} />
      </div>
      <div className={styles.row}>
        <BasicInput label="ID na Plataforma Whatsapp" name="digisacUserId" />
      </div>
      <div className={styles.row}>
        <Checkbox label="Admin" name="admin" />
      </div>
      <div className={styles.box}>
        <AddGroupPermissions
          permissionsGroup={permissionsGroup}
          setPermissionsGroup={setPermissionsGroup}
        />
      </div>
    </form>
  )
}
