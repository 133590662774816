import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  titleBlue: {
    color: '#1671ED',
    cursor: 'move',
  },
  wrapper: {
    overflowY: 'auto',
    overflowX: 'auto',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  container: {
    display: 'flex',
    width: '584px',
    borderTop: '1px solid #E5E5E5',
    height: '100%',
    padding: '16px 24px',
    flexDirection: 'column',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '26px',
  },
  subcontainerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '260px',
  },
  subcontainerPaymentWrapper: {
    gap: '10px',
  },
  title: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: 'black',
  },
  dialogAction: {
    padding: '12px 12px 16px',
  },
  paidButtonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    textDecorationLine: 'underline',
    color: '#1671ED',
  },
  button: {
    height: '40px',
    padding: '10px 16px',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '16px',
  },
  cancelButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  switch: {
    margin: '0',
    width: '44px',
  },
  subcontainerBorderTop: {
    borderTop: '1px solid #E5E5E5',
    paddingTop: '24px',
  },
  required: {
    color: 'red',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3px',
  },
  date: {
    fontSize: '14px',
    marginTop: '4px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  dateField: {
    display: 'flex',
    flexDirection: 'row',
  },
  datePicker: {
    width: '100%',
    zIndex: '1',
  },
  dateIcon: {
    margin: '0px 0px 10px 238px',
    position: 'absolute',
    zIndex: '0',
  },
}))
