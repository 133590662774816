import humps from 'humps'
import { get } from './requests'

export const getCategories = key => get(['categories'], { key, transformPayload: true })

export const getCategoriesParams = key => params =>
  get(['form-pages', 'categories'], {
    key,
    params: humps.decamelizeKeys(params),
    transformPayload: true,
  })

export const getBudgetTemplate = key => params =>
  get(['budget-template'], { key, params: humps.decamelizeKeys(params), transformPayload: true })
