import React from 'react'
import classnames from 'classnames'

import useStyles from './styles'

const StatusCell = ({ isResolved, wasntViewed = null }) => {
  const styles = useStyles()

  return (
    <div
      className={classnames(styles.statusContainer, {
        [styles.statusResolved]: isResolved,
      })}
    >
      <div
        className={classnames(styles.statusDot, {
          [styles.statusDotResolved]: isResolved,
        })}
      />
      <span className={classnames({ [styles.wasntViewed]: wasntViewed === true })}>
        {isResolved ? 'Resolvido' : 'Em andamento'}
      </span>
    </div>
  )
}

export default React.memo(StatusCell)
