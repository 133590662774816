import { Record, Map, List } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { reasonSchema } from '_/models/reason'

import { GET_REASONS, GET_REASONS_TO_REFUSE_SERVICE_ORDER } from './actions'

const INITIAL_STATE = new Record({
  results: Map(),
  order: Map(),
  reasonToRefuseServiceOrderList: List(),
})()

export default createReducer(INITIAL_STATE, {
  [GET_REASONS.FULFILLED]: (state, { payload }) => {
    const normalizeReasons = normalize(payload.results, reasonSchema)
    return state.set('results', Map(normalizeReasons.entities.reason))
  },
  [GET_REASONS_TO_REFUSE_SERVICE_ORDER.FULFILLED]: (state, { payload }) => {
    return state.set('reasonToRefuseServiceOrderList', List(payload))
  },
})
