import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  title: {
    fontSize: spacing(20),
    lineHeight: spacing(20),
    fontWeight: '500',
    fontFamily: 'DM Sans',
    color: palette.primary.main,
    padding: 0,
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: spacing(480),
    },
  },
  p: {
    fontSize: `${spacing(14)} !important`,
    lineHeight: `${spacing(20)} !important`,
    color: '#696969',
    textAlign: 'left',
    letterSpacing: '0 !important',
  },
  content: {
    padding: spacing(0, 24, 16, 24),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(16),
  },
  button: {
    width: spacing(90),
    padding: `${spacing(10)} auto`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(16, 24),
  },
  actions: {
    padding: spacing(12, 24, 16),
  },
  label: {
    color: palette.gray[100],
    fontWeight: '500',
    fontSize: spacing(12),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(12),
    width: '100%',
  },
  textArea: {
    color: palette.gray[500],
    fontSize: spacing(14),
    width: '100%',
    backgroundColor: palette.white,
    resize: 'none',
    textAlign: 'justify',
    display: 'flex',
    border: 'none',

    '&::-webkit-scrollbar': {
      width: spacing(0.75),
      height: 0,
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '100vh',
      backgroundColor: palette.grey[100],
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.grey[300],
      borderRadius: '100vh',
      border: `3px solid ${palette.grey[300]}`,
    },
  },
  errorMessage: {
    margin: 0,
    marginTop: '-10px',
    color: `${palette.error.main} !important`,
    fontSize: spacing(10.5),
    fontFamily: 'Roboto',
    height: spacing(10),
  },
}))
