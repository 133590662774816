/* eslint-disable no-param-reassign */
import React, { createContext, forwardRef, useContext, useMemo } from 'react'

import { TextField } from '@mui/material'
import { InputLabel, Typography } from '@material-ui/core'

import useStyles from './styles'

const MaskInputContext = createContext({})
const useMaskInput = () => useContext(MaskInputContext)

function Root({ children, className, ...rest }) {
  const inputId = useMemo(() => `Refera__MaskInput-${crypto.randomUUID()}`, [])

  const styles = useStyles()

  return (
    <MaskInputContext.Provider value={{ id: inputId }}>
      <div className={`${styles.root} Refera__MaskInput-Root ${className}`} {...rest}>
        {children}
      </div>
    </MaskInputContext.Provider>
  )
}

// eslint-disable-next-line no-unused-vars
function Label({ children, htmlFor: _, required, hasError, className = '', ...rest }) {
  const { id } = useMaskInput()

  const classes = useMemo(
    () =>
      `Refera__MaskInput-Label ${required ? 'Refera__MaskInput-LabelRequired' : ''} ${
        hasError ? 'Refera__MaskInput-LabelError' : ''
      } ${className}`,
    [required, hasError, className]
  )

  return (
    <InputLabel htmlFor={id} {...rest} className={classes}>
      {children}
    </InputLabel>
  )
}

const Control = forwardRef(({ inputProps, ...rest }, ref) => {
  const { id } = useMaskInput()

  return (
    <TextField
      id={id}
      variant="standard"
      className="Refera__MaskInput-Control"
      inputProps={{
        ref,
        ...inputProps,
      }}
      {...rest}
    />
  )
})

function ErrorMessage({ children, ...rest }) {
  return (
    <Typography className="Refera__MaskInput-ErrorMessage" {...rest}>
      {children}
    </Typography>
  )
}

export default { Root, Label, Control, ErrorMessage }
