import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react'
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Grid,
  Button as MuiButton,
  ButtonGroup,
  Popper,
  MenuItem,
  MenuList,
  Grow,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ModalDialog, { WARNING_MODAL } from '_components/modal/modal-dialog'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import Button from '_components/button'
import IconButton from '_components/svg/icon-button'
import Svg from '_components/svg'
import TickIcon from '_assets/icons/ic-tick-circle.svg'
import InfoIcon from '_assets/icons/ic-info-circle.svg'
import CloseCircleIcon from '_assets/icons/ic-close-circle.svg'
import DollarCircleIcon from '_assets/icons/ic-dollar-circle.svg'
import HelpIcon from '_assets/icons/ic-help.svg'
import useRolePermission from '_hooks/use-role-permission'
import { userSelector } from '_modules/authentication/selectors'
import { getCurrentMessagesSelector } from '_modules/messages/selector'
import { paymentAdvanceSelector } from '_modules/paymentAdvance/selectors'
import { getCurrentHelpRequestListSelector } from '_modules/helpRequests/selectors'

import useStyles from './styles'
import { translateToFromValue } from './constants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const HelpList = ({
  closeModal,
  openHelpForm,
  justifiedDelayForm,
  paymentAnticipationForm,
  subjectOption,
  setPaymentAdvanceItem,
  handleOpenAdvancePaymentModal,
}) => {
  const styles = useStyles()

  const { isAdmin } = useRolePermission()
  const [listItems, setListItems] = useState([])
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const user = useSelector(userSelector)
  const messages = useSelector(getCurrentMessagesSelector)
  const antiPayments = useSelector(paymentAdvanceSelector)
  const helpRequests = useSelector(getCurrentHelpRequestListSelector)

  const [modalSelectProviderDialog, setModalSelectProviderDialog] = useState({
    isOpen: false,
    subTitle: '',
  })

  const isIntermediaryUser = useMemo(
    () => user?.getRoles?.includes('cs_manager') || user?.getRoles?.includes('cs_approver'),
    [user]
  )

  const OPTIONS = isAdmin
    ? ['+ Antecipação de pagamento', '+ Atraso justificado', '+ Item de ajuda']
    : ['+ Atraso justificado', '+ Item de ajuda']

  const handleOpenSelectProviderModal = () => {
    setModalSelectProviderDialog({
      isOpen: true,
      subTitle: 'Para abrir um item de ajuda neste chamado, antes aloque um prestador a ele.',
      type: WARNING_MODAL,
    })
  }

  const handleOpenProviderAllocatedModal = () => {
    setModalSelectProviderDialog({
      isOpen: true,
      subTitle:
        'Para abrir uma justificativa de atraso neste chamado, antes aloque um prestador a ele.',
      type: WARNING_MODAL,
    })
  }

  const formatDateTime = data => {
    const dateTime = new Date(data)
    const date = dateTime.toLocaleDateString('pt-BR')
    const hours = dateTime.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    })
    return `${date}, às ${hours}`
  }

  useEffect(() => {
    const allItems = []

    messages?.map(msg => {
      const {
        id,
        createdAt,
        updatedAt,
        type,
        toFrom,
        recipientId,
        senderId,
        senderName,
        recipientName,
        subject,
        message,
        resolved,
        expectedDate,
        answer,
      } = msg

      const newItem = {
        id,
        createdAt,
        updatedAt,
        type,
        toFrom: translateToFromValue(toFrom),
        subject,
        resolved,
        senderId,
        senderName,
        recipientName,
        message,
        expectedDate,
        answer,
        recipientId,
      }

      allItems.push(newItem)

      return allItems
    })

    helpRequests?.map(msg => {
      const { id, createdAt, updatedAt, fromTo, senderId, subject, datetimeResolved } = msg

      const newItem = {
        id,
        createdAt,
        updatedAt,
        type: 'request',
        fromTo: translateToFromValue(fromTo),
        senderId,
        subject,
        resolved: !!datetimeResolved,
      }

      allItems.push(newItem)

      return allItems
    })

    antiPayments?.map(msg => {
      const { id, createdAt, updatedAt, tradesmanName, status, datetimePayed, toFrom } = msg

      const newItem = {
        id,
        createdAt,
        updatedAt,
        type: 'payment_anticipation',
        toFrom: translateToFromValue(toFrom),
        subject: 'payment_anticipation_subject',
        resolved: status,
        senderName: tradesmanName,
        datetimePayed,
      }

      allItems.push(newItem)
      return allItems
    })

    setListItems(allItems.sort((x, y) => (x.resolved < y.resolved ? -1 : 1)))
  }, [messages, antiPayments, setListItems])

  const translateSubject = message => {
    if (message === 'payment_anticipation_subject') return 'Antecipação de pagamento'
    const statusIndex = subjectOption?.status?.indexOf(message)
    return subjectOption?.option[statusIndex]
  }

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  // const [paymentAdvanceModalOpen, setPaymentAdvanceModalOpen] = useState(false)
  // const [paymentAdvanceItem, setPaymentAdvanceItem] = useState(null)

  const handleOpenAdvanceModal = row => {
    if (row) {
      setPaymentAdvanceItem(row)
    }
    handleOpenAdvancePaymentModal(true)
  }

  const handleClick = useCallback(() => {
    if (OPTIONS[selectedIndex] === '+ Atraso justificado') {
      serviceOrder?.tradesman ? justifiedDelayForm() : handleOpenProviderAllocatedModal()
    } else if (OPTIONS[selectedIndex] === '+ Item de ajuda') {
      serviceOrder?.tradesman || user?.getRoles?.includes('cs_refera') || isIntermediaryUser
        ? openHelpForm()
        : handleOpenSelectProviderModal()
    } else if (OPTIONS[selectedIndex] === '+ Antecipação de pagamento') {
      paymentAnticipationForm()
    }
  }, [user, isIntermediaryUser, selectedIndex])

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <Dialog
      open
      maxWidth="xl"
      disableEscapeKeyDown
      onClose={closeModal}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      className={styles.dialog}
    >
      <DialogTitle className={styles.titleBlue} id="draggable-dialog-title">
        Suporte
      </DialogTitle>
      <DialogContent className={styles.container}>
        {listItems.length === 0 && (
          <>
            <Grid className={styles.empty}>
              <IconButton icon={HelpIcon} className={styles.iconButton} />
              <Typography className={styles.textHeader}>Nenhum dado foi encontrado</Typography>
              <Typography className={styles.textSubheader}>
                Para abrir um item de ajuda ou Justificativa de atraso, clique nos botões abaixo.
              </Typography>
            </Grid>
          </>
        )}
        {listItems.length > 0 && (
          <>
            <TableContainer className={styles.scroll}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" colSpan={1} style={{ paddingTop: 0 }}>
                      <Typography>Data e Hora</Typography>
                    </TableCell>
                    <TableCell align="left" colSpan={1} style={{ paddingTop: 0 }}>
                      <Typography>Tipo</Typography>
                    </TableCell>
                    <TableCell align="left" colSpan={1} style={{ paddingTop: 0 }}>
                      <Typography>De / Para</Typography>
                    </TableCell>
                    <TableCell align="left" colSpan={1} style={{ paddingTop: 0 }}>
                      <Typography>Assunto</Typography>
                    </TableCell>
                    <TableCell align="left" colSpan={1} style={{ paddingTop: 0 }}>
                      <Typography>Status</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    // eslint-disable-next-line array-callback-return, consistent-return
                    listItems.map(row => {
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          className={styles.pointer}
                          // onClick={() => openHelpForm(row)}
                          onClick={
                            row.type === 'request'
                              ? () => openHelpForm(row)
                              : row.type === 'payment_anticipation'
                              ? () => handleOpenAdvanceModal(row)
                              : () => justifiedDelayForm(row)
                          }
                        >
                          {Object.keys(row).map(column => {
                            if (
                              column === 'id' ||
                              column === 'senderId' ||
                              column === 'message' ||
                              column === 'answer' ||
                              column === 'recipientId' ||
                              column === 'recipientName' ||
                              column === 'updatedAt' ||
                              column === 'expectedDate' ||
                              column === 'datetimePayed' ||
                              column === 'senderName'
                            ) {
                              return null
                            }

                            if (column === 'createdAt') {
                              return (
                                <TableCell key={row[column]} align="left" colSpan={1}>
                                  <Typography>{formatDateTime(row[column])}</Typography>
                                </TableCell>
                              )
                            }

                            if (column === 'toFrom') {
                              return (
                                <TableCell key={row[column]} align="left" colSpan={1}>
                                  {row[column]?.length > 25 ? (
                                    <Typography>{`${row[column].slice(0, 25)}...`}</Typography>
                                  ) : (
                                    <Typography>{row[column]}</Typography>
                                  )}
                                </TableCell>
                              )
                            }

                            if (column === 'type') {
                              return (
                                <TableCell key={row[column]} align="left" colSpan={1}>
                                  <Chip
                                    variant="outlined"
                                    color="primary"
                                    label={
                                      row[column] === 'request'
                                        ? 'Item de Ajuda'
                                        : row[column] === 'budget_justified'
                                        ? 'Atraso justificado'
                                        : row[column] === 'execution_justified'
                                        ? 'Atraso justificado'
                                        : row[column] === 'payment_anticipation'
                                        ? 'Pagamento antecipado'
                                        : 'Não descrito'
                                    }
                                  />
                                </TableCell>
                              )
                            }

                            if (column === 'subject') {
                              return (
                                <TableCell key={row[column]} align="left" colSpan={1}>
                                  <Typography>{translateSubject(row[column])}</Typography>
                                </TableCell>
                              )
                            }

                            if (column === 'resolved') {
                              return (
                                <TableCell
                                  key={row[column]}
                                  className={styles.statusTable}
                                  align="left"
                                  colSpan={1}
                                >
                                  <div className={styles.statusField}>
                                    {(row[column] === true ||
                                      (row[column] === 'approved' && !row?.datetimePayed)) && (
                                      <Svg icon={TickIcon} className={styles.icon} />
                                    )}
                                    {(!row[column] || row[column] === 'pending') && (
                                      <>
                                        <Svg icon={InfoIcon} className={styles.icon} />
                                        <Typography>Pendente</Typography>
                                      </>
                                    )}
                                    {row[column] === true && row[column] !== 'approved' && (
                                      <Typography>Resolvido</Typography>
                                    )}
                                    {row[column] === 'approved' &&
                                      (row?.datetimePayed ? (
                                        <>
                                          <Svg icon={DollarCircleIcon} className={styles.icon} />
                                          <Typography>Pago</Typography>
                                        </>
                                      ) : (
                                        <Typography>Aprovado</Typography>
                                      ))}
                                    {row[column] === 'reproved' && (
                                      <>
                                        <Svg icon={CloseCircleIcon} className={styles.icon} />
                                        <Typography>Reprovado</Typography>
                                      </>
                                    )}
                                  </div>
                                </TableCell>
                              )
                            }

                            return (
                              <TableCell key={row[column]} align="left" colSpan={1}>
                                <Typography>{row[column]}</Typography>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>

      <DialogActions className={styles.dialogAction}>
        <Button className={styles.button} onClick={closeModal} color="red" variant="outlined">
          Fechar
        </Button>
        <ButtonGroup variant="outlined" color="primary" ref={anchorRef} aria-label="split button">
          <MuiButton className={styles.muiButton} onClick={handleClick}>
            {OPTIONS[selectedIndex]}
          </MuiButton>
          <MuiButton
            className={styles.muiArrow}
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </MuiButton>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {OPTIONS.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </DialogActions>
      <ModalDialog
        modalDialog={modalSelectProviderDialog}
        setModalDialog={setModalSelectProviderDialog}
      />
    </Dialog>
  )
}

HelpList.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openHelpForm: PropTypes.func.isRequired,
  justifiedDelayForm: PropTypes.func.isRequired,
  paymentAnticipationForm: PropTypes.func.isRequired,
}

export default HelpList
