/* eslint-disable react/prop-types */
import React from 'react'
import { TextField } from '@material-ui/core'

import useStyles from './styles'

export const InputField = ({
  id,
  name,
  type,
  label,
  placeholder,
  defaultValue,
  onChange,
  register,
  required = false,
  min = 0,
}) => {
  const styles = useStyles({ required })

  return (
    <>
      <TextField
        id={id}
        name={name}
        type={type}
        label={label}
        required={required}
        placeholder={placeholder}
        inputRef={register}
        onChange={onChange}
        margin="dense"
        variant="standard"
        className={styles.input}
        defaultValue={defaultValue}
        InputLabelProps={{
          shrink: true,
          className: styles.inputLabel,
        }}
        InputProps={{ style: { fontSize: 16, height: 35, marginTop: 8 }, minlength: { min } }}
      />
    </>
  )
}

export default InputField
