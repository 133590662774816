import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core'
import { Button, Modal, useConfirm, Preview, Dialog } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'
import { useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import useToggle from '_hooks/use-toggle'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import { useToast } from '_/hooks/use-toast'
import { ConfidentialDataWarning } from '_components/ConfidentialDataWarning'
import { JUSTIFIED_STATUS_OPTIONS } from '_/views/service-order/help/help-form/constants'
import {
  postHelpRequestPendings,
  deletePendingAttachment,
  editHelpRequestPendings,
  verifyStepLog,
  UPDATE_AVAILABILITY,
} from '_modules/helpRequests/actions'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'
import { userSelector } from '_modules/authentication/selectors'
import { getGenericParameters } from '_modules/service-orders/actions'
import {
  getHelpRequestPendingListSelector,
  getHelpRequestsByIDSelector,
} from '_modules/helpRequests/selectors'
import ModalDialog, { WARNING_MODAL } from '_components/modal/modal-dialog'
import { CloseCircle as CloseCircleIcon } from '@refera/ui-icons'
import useRolePermission from '_/hooks/use-role-permission'
import useStyles from './styles'

const defaultPreviewState = {
  visible: false,
  selectedIndex: undefined,
}

const ProcedureDetailsDialog = ({ open, onCancel, procedure, editable = true, title = 'Novo' }) => {
  // TODO: Remove useState hell adding useReducer
  const styles = useStyles()
  const { id: helpRequestId } = useParams()
  const dispatch = useDispatch()

  const userData = useSelector(userSelector)?.toJS()
  const helpRequest = useSelector(getHelpRequestsByIDSelector(helpRequestId))?.toJS()
  const procedureList = useSelector(getHelpRequestPendingListSelector)
  const { isIntermediary } = useRolePermission()

  const [message, setMessage] = useState('')
  const [resolved, setResolved] = useState(false)
  const [pendingAttachment, setPendingAttachment] = useState([])
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [justifiedStatus, setJustifiedStatus] = useState(null)
  const [isSameStepLog, setIsSameStepLog] = useState(true)

  // TODO: Check if this toggle is needed
  const [editAttachments] = useToggle()
  const { isConfirmed } = useConfirm()
  const [dialogMessage, setDialogMessage] = useState('')
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [toDelete, setToDelete] = useState([])
  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const [dialogInfo, setDialogInfo] = useState({ subject: '', description: '' })
  const { showToast } = useToast()

  const wordCount = useMemo(() => message?.length ?? 0, [message])
  const isJustifiedDelay = helpRequest?.typeSubject === 'justified_delayed'

  const MESSAGES = {
    JUSTIFIED_DELAYED:
      'Você não pode salvar este trâmite porque ele reabre um Atraso Justificado que já não pode mais ser reaberto.',
    HELP_REQUEST:
      'Você não pode salvar este trâmite porque ele reabre um Pedido de Ajuda que já não pode mais ser reaberto.',
  }

  const QUESTIONS = {
    PAUSING_SERVICE:
      'Essa alteração vai fazer o item de ajuda pausar o prazo do chamado ao qual está vinculado. Você tem certeza que deseja continuar?',
    SOLVING_PROCEDURE:
      'Lembre-se que resolvendo você dará continuidade aos prazos do prestador. Portanto, verifique se a resposta satisfaz o que foi solicitado. Além disso, caso deseje fazer um pedido para a Refera, por gentileza, abra um novo pedido de ajuda.',
  }

  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  const lastProcedure = useMemo(() => procedureList?.[0], [procedureList])

  useEffect(() => {
    if (procedure?.pendingAttachment) {
      setPendingAttachment(
        procedure?.pendingAttachment?.map(file => ({ file: file?.file, id: file?.id }))
      )
    }
    setMessage(procedure?.message ?? '')
    setJustifiedStatus(
      procedure?.justifiedDelayedStatus ?? lastProcedure?.justifiedDelayedStatus ?? null
    )
    setResolved(procedure?.resolved ?? Boolean(helpRequest?.datetimeResolved))
    if (open && !isSameStepLog) {
      showToast({
        message:
          "'Status da justificativa' desabilitado porque a etapa quando ele foi criado não é mais a atual.",
        type: 'info',
      })
    }
  }, [
    procedure,
    helpRequest?.datetimeResolved,
    lastProcedure?.justifiedDelayedStatus,
    isSameStepLog,
    open,
  ])

  const handleBlur = useCallback(() => setResolved(!!justifiedStatus), [justifiedStatus])

  const isHelpRequestActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.ALLOW_HELPREQUEST_PAUSE_SERVICE,
  })

  useEffect(() => {
    dispatch(
      getGenericParameters({
        name: GENERIC_PARAMETERS.ALLOW_HELPREQUEST_PAUSE_SERVICE,
      })
    )
    if (isJustifiedDelay) {
      dispatch(verifyStepLog(helpRequestId)).then(res => setIsSameStepLog(res?.isSameStepLog))
    }
  }, [dispatch, helpRequestId, isJustifiedDelay])

  const attachmentsImages = useMemo(
    () =>
      pendingAttachment?.filter(file =>
        file instanceof File ? file.type !== 'application/pdf' : file.file.indexOf('.pdf') === -1
      ) || [],
    [pendingAttachment]
  )

  const payload = useMemo(
    () => ({
      message,
      helpRequest: helpRequestId,
      user: userData?.id,
      resolved,
      force: false,
      justifiedDelayedStatus: justifiedStatus,
      pendingAttachment: pendingAttachment.filter(item => item?.id === undefined),
    }),
    [helpRequestId, message, pendingAttachment, resolved, userData?.id, justifiedStatus]
  )

  const handleOnDrop = useCallback(files => {
    setPendingAttachment(old => [...old, ...files])
  }, [])

  const handleRemoveAttachments = useCallback(
    async index => {
      setDialogMessage('Você deseja deletar este item?')
      const confirmed = await isConfirmed()

      if (confirmed) {
        const currentFile = attachmentsImages[index]

        if (currentFile?.id) {
          setToDelete(old => [...old, currentFile.id])
          setPendingAttachment(old => old.filter(current => current.id !== currentFile.id))
        } else {
          setPendingAttachment(old =>
            old.filter(current => current.lastModified !== currentFile?.lastModified)
          )
        }
      }

      setDialogMessage('')
    },
    [pendingAttachment, setDialogMessage]
  )

  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={attachmentsImages?.map((item, index) =>
          item.id
            ? {
                id: item.id,
                url: item.file,
              }
            : {
                id: index,
                url: URL.createObjectURL(item),
              }
        )}
      />
    ),
    [previewState, attachmentsImages]
  )

  const justifiedDelayedStatus = useMemo(() => {
    if (isJustifiedDelay) {
      return (
        <Grid className={styles.statusWrapper}>
          <Typography className={styles.labelMessage}>Status da justificativa</Typography>
          <Select
            name="status"
            className={styles.selectPlaceholder2}
            onChange={e => setJustifiedStatus(e.target.value)}
            value={justifiedStatus}
            displayEmpty
            MenuProps={menuProps}
            onBlur={handleBlur}
            disabled={!isSameStepLog}
          >
            <MenuItem disabled value={null}>
              Selecione um status
            </MenuItem>
            {JUSTIFIED_STATUS_OPTIONS?.map(option => {
              return (
                <MenuItem key={option} value={option?.value}>
                  {option?.label}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>
      )
    }
    return null
  }, [isJustifiedDelay, justifiedStatus, isSameStepLog])

  const handleItemClick = useCallback(index => {
    setPreviewState(() => ({
      visible: true,
      selectedIndex: index,
    }))
  }, [])

  const handleMessageChange = useCallback(event => {
    setMessage(event.target.value)
  }, [])

  const onUpdateSuccessful = useCallback(() => {
    if (open) {
      onCancel()
    }
  }, [onCancel, open])

  const handleCreateProcedureRejected = useCallback(async () => {
    if (!helpRequest.locked) {
      showToast({
        message: 'Houve um erro desconhecido. Por favor, repita a operação.',
        type: 'error',
      })
    }
    const confirmed = await isConfirmed()

    if (confirmed) {
      dispatch(
        postHelpRequestPendings(
          {
            ...payload,
            force: true,
          },
          helpRequestId
        )
      )
    }
  }, [dispatch, helpRequestId, payload])

  const handleEditProcedureRejected = useCallback(async () => {
    setDialogMessage('Você tem certeza que deseja executar esta ação?')
    const confirmed = await isConfirmed()

    if (confirmed) {
      dispatch(
        editHelpRequestPendings(
          {
            ...payload,
            helpRequestPendingId: procedure?.id,
            force: true,
          },
          helpRequestId,
          procedure?.id
        )
      )
    }

    setDialogMessage('')
  }, [dispatch, helpRequestId, payload, procedure?.id])

  const handleSave = () => {
    const isTradesmanToReferra =
      helpRequest?.fromTo === 'tradesman_to_refera' && helpRequest?.datetimeResolved === null

    if (!isHelpRequestActive) {
      if (isTradesmanToReferra) {
        setDialogInfo({ subject: 'Atenção!', description: QUESTIONS.PAUSING_SERVICE })
        setShowConfirmation(true)
      }
    }
    if (isIntermediary && helpRequest.typeSubject === 'help_request' && resolved) {
      setDialogInfo({
        subject: 'Deseja resolver o pedido de ajuda?',
        description: QUESTIONS.SOLVING_PROCEDURE,
      })
      setShowConfirmation(true)
    } else {
      handleConfirm()
    }
    if (helpRequest.locked) {
      let subTitle

      if (helpRequest.typeSubject === 'justified_delayed') {
        subTitle = MESSAGES.JUSTIFIED_DELAYED
      } else {
        subTitle = MESSAGES.HELP_REQUEST
      }
      setModalDialog({
        isOpen: true,
        subTitle,
        type: WARNING_MODAL,
      })
      onCancel()
    }
  }

  const handleConfirm = useCallback(() => {
    if (!procedure) {
      dispatch(postHelpRequestPendings(payload, helpRequestId))
        .then(() => onUpdateSuccessful())
        .catch(() => {
          handleCreateProcedureRejected()
        })

      dispatch({
        type: UPDATE_AVAILABILITY.ACTION,
        payload: {
          helpRequestId: helpRequest.id,
          datetimeResolved: null,
        },
      })
    } else if (procedure.user === userData.id) {
      const cantEdit = lastProcedure?.id !== procedure.id && payload.resolved === procedure.resolved

      if (cantEdit) {
        setModalDialog({
          isOpen: true,
          subTitle:
            'Você não pode realizar essa ação em um item de ajuda já resolvido. Favor prosseguir criando um novo item de ajuda para o chamado.',
          type: WARNING_MODAL,
        })
        onCancel()
        return
      }

      if (helpRequest.locked) {
        let subTitle

        if (helpRequest.typeSubject === 'justified_delayed') {
          subTitle = MESSAGES.JUSTIFIED_DELAYED
        } else {
          subTitle = MESSAGES.HELP_REQUEST
        }
        setModalDialog({
          isOpen: true,
          subTitle,
          type: WARNING_MODAL,
        })
        onCancel()
        return
      }

      if (toDelete.length > 0) {
        // TODO: Remove this loop once BE is ready to receive an array of IDs to delete attachments
        toDelete.forEach(fileID =>
          dispatch(deletePendingAttachment(procedure.helpRequest, procedure.id, fileID))
        )
      }

      dispatch(
        editHelpRequestPendings(
          {
            ...payload,
            helpRequestPendingId: procedure?.id,
          },
          helpRequestId,
          procedure?.id
        )
      )
        .then(() => {
          onUpdateSuccessful()
        })
        .catch(() => handleEditProcedureRejected())
    } else {
      setModalDialog({
        isOpen: true,
        subTitle: 'Só o usuário que o incluiu pode realizar essa ação .',
        type: WARNING_MODAL,
      })
    }
    setShowConfirmation(false)
  }, [helpRequest, lastProcedure, userData, payload, procedure])

  return (
    <Modal
      className={styles.modal}
      title={procedure ? 'Mensagem' : title}
      onBackdropClick={onCancel}
      onClose={onCancel}
      open={open}
      actionsButtons={
        <Grid className={styles.modalButtons}>
          {(editable || isSameStepLog) && (
            <>
              <Button color="red" variant="secondary" onClick={onCancel}>
                Cancelar
              </Button>
              <Button
                disabled={message?.length === 0 || !editable || !isSameStepLog}
                color="primary"
                variant="primary"
                onClick={handleSave}
              >
                Salvar
              </Button>
            </>
          )}
        </Grid>
      }
    >
      <Typography className={styles.labelMessage}>
        Mensagem<b className={styles.required}> *</b>
      </Typography>
      <TextField
        value={message}
        onChange={handleMessageChange}
        multiline
        fullWidth
        minRows={5}
        inputProps={{
          maxLength: 500,
        }}
        variant="outlined"
        InputProps={{ style: { fontSize: 16, maxLength: 500 } }}
        required
        disabled={!editable || !isSameStepLog}
        defaultValue=""
      />
      <Grid item sm={12}>
        <Typography className={styles.countArea}>{wordCount}/500</Typography>
      </Grid>
      <ConfidentialDataWarning style={{ marginTop: 5 }} />
      {justifiedDelayedStatus}
      <Grid>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              onChange={e => setResolved(e.target.checked)}
              checked={resolved}
              disabled={!editable || isJustifiedDelay}
            />
          }
          label="Marcar como resolvido"
        />
      </Grid>
      <Grid container className={styles.attachment}>
        <Grid item sm={12}>
          <Typography className={styles.labelMessage}>Anexos</Typography>
        </Grid>
        <Attachments
          label="Imagens e vídeos"
          downloadable
          readOnly={editAttachments || !isSameStepLog}
          files={attachmentsImages}
          accept={{
            'image/jpeg': ['.jpeg', '.png', '.jpg', '.bmp', '.flv', '.mkv', '.mp4', '.mov'],
          }}
          onDrop={handleOnDrop}
          onRemoveItem={handleRemoveAttachments}
          onItemClick={handleItemClick}
        />
        {dialogMessage && <ConfirmRemoveDialog message={dialogMessage} />}
        {PreviewComponent}
      </Grid>
      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />{' '}
      {showConfirmation && (
        <Dialog
          open={showConfirmation}
          icon={CloseCircleIcon}
          type="info"
          subject={dialogInfo?.subject}
          description={dialogInfo?.description}
          labelApprove="Sim"
          labelCancel="Não"
          onApprove={handleConfirm}
          onCancel={() => setShowConfirmation(false)}
          closeButton
        />
      )}
    </Modal>
  )
}

export default React.memo(ProcedureDetailsDialog)
