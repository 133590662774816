import React, { useEffect, useState, useMemo } from 'react'
import { Grid } from '@mui/material'
import HeaderTitle from '_/components/header-title'
import { Button, Loader, Typography } from '@refera/ui-web'
import { Edit2, Export3 } from '@refera/ui-icons'
import { FINANCE_ROUTES, ROUTES } from '_/utils/constants'
import Theme from '@refera/ui-core'
import Accordion from '../../components/Accordion'

import { navigate, useParams, Redirect } from '@reach/router'
import { useDispatch } from 'react-redux'
import {
  UPDATE_BUDGET_RECEIVABLE,
  UPDATE_PAYMENT_RECEIVABLE,
  getBudgetReceivableDetails,
} from '_/modules/finance/actions'
import useRolePermission from '_/hooks/use-role-permission'

import { useToast } from '_hooks/use-toast'

import { formatErrorMessage } from '../../utils/FormatErrorMessage'

import {
  handleFormatInstallmentDetailsItemValue,
  handleStatusField,
  handleStringField,
} from '../../utils/functions'

import useStyles from '../../installment-details/styles'
import useToggle from '_/hooks/use-toggle'
import { EditInstallmentModal } from '../../installment-details/modals/EditInstallmentModal'
import { EditPaymentModal } from '../../installment-details/modals/EditPaymentModal'
import useFetchCall from '_/hooks/use-fetch-call'

const ACCORDION_TITLES = ['Dados da parcela', 'Recebimento']

const formatPageInfo = receiptInfo => {
  const { budgetReceivable, paymentReceivable } = receiptInfo

  const detailsInfo = [
    { label: 'Status', value: handleStatusField(budgetReceivable?.status) },
    { label: 'ID do chamado', value: handleStringField(budgetReceivable?.serviceOrderId) },
    { label: 'Vencimento', value: budgetReceivable?.dueDate, type: 'date', name: 'dueDate' },
    {
      label: 'Valor da parcela',
      value: budgetReceivable?.value,
      type: 'money',
      name: 'value',
    },
  ]

  const paymentInfo = [
    {
      label: 'Forma de recebimento',
      value: paymentReceivable?.formOfPayment || '',
      type: 'formOfPayment',
      name: 'formOfPayment',
    },
    {
      label: 'Pagamento',
      value: paymentReceivable?.paymentDate || null,
      type: 'date',
      name: 'paymentDate',
    },
    {
      label: '- Desconto',
      value: paymentReceivable?.discount || 0,
      type: 'money',
      name: 'discount',
    },
    {
      label: '+ Acréscimo',
      value: paymentReceivable?.additionValue || 0,
      type: 'money',
      name: 'additionValue',
    },
    {
      label: '+ Juros',
      value: paymentReceivable?.interest || 0,
      type: 'money',
      name: 'interest',
    },
    {
      label: '+ Multa',
      value: paymentReceivable?.fine || 0,
      type: 'money',
      name: 'fine',
    },
    {
      label: 'Valor pago',
      value: paymentReceivable?.amountPaid,
      type: 'money-readonly',
      name: 'amountPaid',
    },
  ]
  return { detailsInfo, paymentInfo }
}

const ReceiptDetails = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { budgetReceivableId } = useParams()

  const { isAdmin } = useRolePermission()

  const [receiptInfo, setReceiptInfo] = useState({})
  const [redirectTo, setRedirectTo] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const [isEditReceiptDialogOpen, toggleEditReceiptDialogOpen] = useToggle()
  const [isEditInstallmentDialogOpen, toggleEditInstallmentDialogOpen] = useToggle()

  useFetchCall(UPDATE_PAYMENT_RECEIVABLE.ACTION, fetchReceiptInfo)
  useFetchCall(UPDATE_BUDGET_RECEIVABLE.ACTION, fetchReceiptInfo)

  const { showToast } = useToast()

  const goBack = () =>
    isAdmin ? navigate(FINANCE_ROUTES.RECEIPTS) : navigate(ROUTES.FINANCE_MANAGER)

  const pageInfo = useMemo(() => {
    return formatPageInfo(receiptInfo)
  }, [receiptInfo])

  const receiptValue = useMemo(() => receiptInfo?.budgetReceivable?.value, [receiptInfo])

  async function fetchReceiptInfo() {
    setIsLoading(true)
    await dispatch(getBudgetReceivableDetails(budgetReceivableId))
      .then(res => setReceiptInfo(res))
      .catch(error =>
        error?.detail?.toLowerCase()?.includes('not found')
          ? setRedirectTo('/nao-encontrado')
          : showToast({ message: formatErrorMessage(error), type: 'error' })
      )
    setIsLoading(false)
  }

  const handleGoToServiceOrder = () =>
    window.open(
      `${ROUTES.SERVICE_ORDER}/${receiptInfo?.budgetReceivable?.serviceOrderId}`,
      '_blank',
      'noopener,noreferrer'
    )

  useEffect(() => {
    fetchReceiptInfo()
  }, [])

  if (redirectTo) return <Redirect to={redirectTo} noThrow />

  return (
    <Grid>
      <Loader hasBackdrop open={isLoading} label="Aguarde..." />
      <HeaderTitle title="Recebimento" backButtonAction={goBack}>
        <Grid className={styles.buttonsContainer}>
          {isAdmin && (
            <>
              <Button
                className={styles.edit}
                variant="secondary"
                startIcon={<Edit2 color={Theme.Colors.Primary.Base} />}
                onClick={toggleEditInstallmentDialogOpen}
              >
                Editar parcela
              </Button>
              <Button
                className={styles.edit}
                variant="secondary"
                startIcon={<Edit2 color={Theme.Colors.Primary.Base} />}
                onClick={toggleEditReceiptDialogOpen}
              >
                Editar recebimento
              </Button>
            </>
          )}

          <Button
            variant="secondary"
            startIcon={<Export3 color={Theme.Colors.Primary.Base} />}
            onClick={handleGoToServiceOrder}
            disabled={isLoading}
          >
            Abrir chamado
          </Button>
        </Grid>
      </HeaderTitle>
      <Grid className={styles.container}>
        {Object.values(pageInfo)?.map((accordion, index) => {
          return (
            <Accordion title={ACCORDION_TITLES[index]} key={ACCORDION_TITLES[index]}>
              <Grid className={styles.accordionWrapper}>
                {accordion?.map(item => (
                  <Grid className={styles.accordionItem} key={item.label}>
                    <Typography className={styles.accordionLabel}>{item.label}</Typography>
                    <Typography className={styles.accordionValue}>
                      {handleFormatInstallmentDetailsItemValue(item)}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Accordion>
          )
        })}
      </Grid>
      {isEditInstallmentDialogOpen && (
        <EditInstallmentModal
          open={isEditInstallmentDialogOpen}
          onClose={toggleEditInstallmentDialogOpen}
          installmentInfo={pageInfo?.detailsInfo}
        />
      )}
      {isEditReceiptDialogOpen && (
        <EditPaymentModal
          open={toggleEditReceiptDialogOpen}
          onClose={toggleEditReceiptDialogOpen}
          installmentInfo={pageInfo?.paymentInfo}
          installmentValue={receiptValue}
        />
      )}
    </Grid>
  )
}

export default ReceiptDetails
