import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    background: palette.primary.main,
    height: '100vh',
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  content: {
    background: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  form: {
    width: spacing(368),
    [breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  title: {
    letterSpacing: spacing(-0.3),
    color: palette.gray[64],
    marginBottom: spacing(32),
    [breakpoints.down('xs')]: {
      fontSize: spacing(24),
    },
  },
  icon: {
    width: spacing(176),
    height: spacing(75.43),
    marginBottom: spacing(80),
    [breakpoints.down('sm')]: {
      maxWidth: spacing(100),
      maxHeight: spacing(50),
      inset: `${spacing(-20)} auto auto 0`,
    },
  },
  textfield: {
    marginBottom: spacing(40),
  },
  actions: {
    marginTop: spacing(7),
  },
  loginButton: {
    textTransform: 'none',
    background: palette.orange.light,
    borderRadius: spacing(75),
    color: 'white',
    width: spacing(176),
    height: spacing(48),
    fontWeight: '500',
    fontSize: spacing(16),
    lineHeight: spacing(24),
    letterSpacing: spacing(-0.1),
    [breakpoints.down('sm')]: {
      width: '80%',
      marginLeft: '10px',
    },

    '&:hover': {
      background: palette.orange.light,
      opacity: 0.8,
    },
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: '500',
    fontSize: spacing(14),
    lineHeight: spacing(20),
  },
  error: {
    color: palette.error.main,
    marginTop: spacing(-30),
    marginBottom: spacing(20),
  },
  alert: {
    width: 'auto !important',
  },
  root: {
    padding: 0,

    '& .MuiPaper-root': {
      maxWidth: '800px',
    },
  },
  contentModal: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: spacing(40),
    paddingRight: spacing(40),
    paddingBottom: spacing(10),
    alignItems: 'flex-start',
    gap: spacing(20),

    [breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  cardBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing(20),
    flexWrap: 'wrap',
    width: '100%',
  },
  card: {
    width: spacing(180),
    height: spacing(200),
    display: 'flex',
    gap: spacing(20),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: spacing(8),
    cursor: 'pointer',
    border: `2px solid ${palette.primary.main}`,
    background: 'transparent',
    fontWeight: '500',
    fontSize: spacing(14),
    padding: spacing(15),
    color: palette.primary.main,
    '& svg': {
      fill: palette.primary.main,
    },

    '&:hover': {
      background: palette.primary.lightest,
    },

    [breakpoints.down('sm')]: {
      width: spacing(120),
      height: spacing(150),
    },
  },
  titleModal: {
    color: palette.primary.main,
    fontSize: spacing(24),
    textAlign: 'center',
  },
  textModal: {
    color: palette.gray[100],
    fontSize: spacing(18),
    textAlign: 'center',
  },
  iconProfile: {
    width: spacing(50),
    height: spacing(50),
  },
  iconUser: {
    width: spacing(45),
    height: spacing(45),
  },
  actionConfirm: {
    padding: spacing(20),
  },
}))
