import { camelize } from '_/utils/token'
import { createFormDataDocuments } from '_utils/helpers'
import { formDataInstance } from '_/services/requests'

export const updateServiceOrderForm = async (uuid, serviceID, payload) => {
  if (uuid) {
    const response = await formDataInstance.patch(
      `/serviceorder-form/${serviceID}/?uuid=${uuid}`,
      createFormDataDocuments(payload)
    )
    return camelize(response.data)
  }
  const response = await formDataInstance.patch(
    `/serviceorder-form/${serviceID}/`,
    createFormDataDocuments(payload)
  )
  return camelize(response.data)
}
