import PropTypes from 'prop-types'

export const generalGridAreaTemplate = `
"generalSectionTitle generalSectionTitle generalSectionTitle generalSectionTitle generalSectionTitle generalSectionTitle"
"businessFront businessFront type type type type" 
"destination destination stepStatus stepStatus ... ..."
"agencies agencies  definedItem definedItem ... ..."
"defaultMessage defaultMessage  ... ... ... ..."
`

const emailGridAreaTemplate = `
"emailSectionTitle emailSectionTitle emailSectionTitle emailSectionTitle emailSectionTitle emailSectionTitle"
"emailSubject emailSubject emailSubject emailBcc emailBcc emailBcc"
"emailBody emailBody emailBody emailBody emailBody emailBody"
`

const whatsappGridAreaTemplate = `
"whatsappSectionTitle whatsappSectionTitle whatsappSectionTitle whatsappSectionTitle whatsappSectionTitle whatsappSectionTitle"
"whatsappTemplateName whatsappTemplateName whatsappTemplateName . . ."
"whatsappBody whatsappBody whatsappBody message_2 message_2 message_2"
"message_1_subsequent_budget message_1_subsequent_budget message_1_subsequent_budget message_2_subsequent_budget message_2_subsequent_budget message_2_subsequent_budget "
`

export const complementGridAreaTemplates = [
  emailGridAreaTemplate,
  whatsappGridAreaTemplate,
  emailGridAreaTemplate.concat(whatsappGridAreaTemplate),
]

export const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}

/**
 * Returns a value from a reference object or array based on a given target and value.
 * @param {Object} options - The options object.
 * @param {Array|Object} options.reference - The reference object or array to search in.
 * @param {string} [options.target] - The property name to search for in the reference object or array.
 * @param {*} options.value - The value to search for in the reference object or array.
 * @param {string} options.method - The search method to use. Possible values are 'findInObjectList', 'findInList', 'findInObject' and 'findKeyfromValue'.
 * @returns {*} The value found in the reference object or array, or undefined if not found.
 */
export function getValueByReference({ reference, target = '', value = undefined, method }) {
  switch (method) {
    case 'findInObjectList':
      return reference.find(item => item[target] === value)

    case 'findInList':
      return reference.find(item => item === value)

    case 'findInObject':
      return reference[target] === value

    case 'findKeyfromValue':
      return Object.keys(reference).find(key => reference[key] === value)
    default:
      return undefined
  }
}

getValueByReference.propTypes = {
  reference: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  target: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  method: PropTypes.oneOf(['findInObjectList', 'findInList', 'findInObject', 'findKeyfromValue'])
    .isRequired,
}

getValueByReference.defaultProps = {
  target: '',
  value: undefined,
}
