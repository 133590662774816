import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  aside: {
    backgroundColor: palette.gray[4],
    padding: spacing(32),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: spacing(300),
  },
  navigate: {
    height: spacing(24),
    width: spacing(24),
  },
  editButton: {
    height: spacing(24),
    width: spacing(24),
    margin: `${spacing(16)} 0 0 auto`,
  },
  image: {
    height: spacing(225),
    width: spacing(226),
    borderRadius: spacing(5),
  },
  fileSelector: {
    display: 'none',
  },
  addNewPictureContainer: {
    height: spacing(225),
    width: spacing(226),
    cursor: 'pointer',
    position: 'relative',
    marginBottom: spacing(24),
  },
  addNewPicture: {
    height: spacing(225),
    width: spacing(226),
    backgroundColor: 'rgba(0,0,0,.5)',
    borderRadius: spacing(5),
  },
  addIcon: {
    height: spacing(64),
    width: spacing(64),
    color: 'white',
  },
  information: {
    marginBottom: spacing(16),
  },
  buttonService: {
    padding: 0,
    width: spacing(180),
    fontWeight: 'normal',
  },
  label: {
    color: palette.gray[32],
    marginBottom: spacing(6),
  },
  buttonEdit: {
    height: spacing(40),
  },
  header: {
    marginBottom: spacing(16),
  },
  error: {
    color: palette.custom.red,
    fontSize: spacing(10),
  },
  title: {
    color: palette.gray[32],
    marginBottom: spacing(6),
  },
  switch: {
    margin: '0',
    width: '44px',
  },
}))
