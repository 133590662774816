import { Grid } from '@mui/material'
import { Typography } from '@refera/ui-web'
import React from 'react'
import useStyles from './styles'
import { formatDate } from '_/utils/helpers'

const LogDate = ({ data }) => {
  const styles = useStyles()

  const handleFormatDate = date => {
    const weekday = [
      'DOMINGO',
      'SEGUNDA-FEIRA',
      'TERÇA-FEIRA',
      'QUARTA-FEIRA',
      'QUINTA-FEIRA',
      'SEXTA-FEIRA',
      'SÁBADO',
    ]

    const time = new Date(date)

    const formattedDate = ` ${weekday[time.getDay()]}, ${formatDate(date)}`

    return formattedDate
  }

  return (
    <Grid className={styles.container}>
      <Typography>{handleFormatDate(data)}</Typography>
    </Grid>
  )
}

export default LogDate
