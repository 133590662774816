import React from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Typography } from '@mui/material'

import { pageAccessControl } from '_modules/authentication/actions'

import ReferaLogo from '_assets/svgs/refera-logo-negative.svg'
import ReferaLogoWhite from '_assets/svgs/refera-logo.svg'
import CheckIcon from '_assets/svgs/check-icon-dark.svg'
import ShakingHandsImg from '_assets/images/landing-page-agency/shaking-hands.webp'
import PlumberOnKitchenImg from '_assets/images/landing-page-agency/plumber-on-kitchen.webp'
import { ArrowUp3 } from '@refera/ui-icons'
import WhatsappIcon from '_assets/icons/ic-whatsapp.svg'
import FacebookIcon from '_assets/icons/ic-facebook.svg'
import InstagramIcon from '_assets/icons/ic-instagram.svg'
import YoutubeIcon from '_assets/icons/ic-youtube.svg'
import LinkedinIcon from '_assets/icons/ic-linkedin.svg'

import useStyles, { BackgroundImage } from './styles'
import Svg from '_/components/svg'
import { navigate, useParams } from '@reach/router'
import { BENEFITS_LIST } from './constants'
import classNames from 'classnames'
import { ROUTES } from '_/utils/constants'

export const LandingPageAgency = () => {
  const styles = useStyles()

  const { agencySlug } = useParams()

  const dispatch = useDispatch()

  const handleGoToServiceForm = () => {
    if (agencySlug && agencySlug === 'lello-condominos') {
      dispatch(pageAccessControl('service_order_form', agencySlug))
      navigate(`${ROUTES.NEW_SERVICE}/${agencySlug}`)
    }
  }

  return (
    <Grid className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerWrapper}>
          <div className={styles.logoContainer}>
            <Svg icon={ReferaLogo} ariaLabel="Logo da Refera" className={styles.referaLogo} />
            <Typography className={styles.logoText}>
              A Plataforma
              <br />
              de manutenções
            </Typography>
          </div>
          <div />
        </div>
      </header>
      <Grid className={styles.intro}>
        <Grid className={styles.introWrapper}>
          <Grid className={styles.introContent}>
            <Typography className={styles.introTitle}>
              Refera: a maior plataforma de manutenções do Brasil
            </Typography>
            <Typography className={styles.introDescription}>
              Serviços de manutenção para imóveis com qualidade, garantia e preço justo
            </Typography>
            <button
              type="button"
              onClick={handleGoToServiceForm}
              className={classNames(styles.button, styles.whiteButton)}
            >
              SOLICITE UM ORÇAMENTO SEM COMPROMISSO
            </button>
          </Grid>
          <Grid className={styles.introImgContainer}>
            <BackgroundImage
              image={ShakingHandsImg}
              alt="Prestador de serviços na cozinha apertando a mão de uma mulher"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={styles.benefits}>
        <Grid className={styles.benefitsWrapper}>
          <Grid className={styles.benefitsImgContainer}>
            <BackgroundImage
              image={PlumberOnKitchenImg}
              alt="Encanador de uniforme falando com a proprietária na cozinha"
            />
          </Grid>
          <Grid className={styles.benefitsContent}>
            <Typography className={styles.benefitsTitle}>Vantagens de usar a Refera</Typography>
            <Grid className={styles.benefitsList}>
              {BENEFITS_LIST.map(({ text, description }) => (
                <Grid key={text} className={styles.benefitItem}>
                  <Svg icon={CheckIcon} ariaLabel="Ícone de check" className={styles.checkIcon} />
                  <Grid className={styles.benefitInfo}>
                    <span className={styles.benefitItemText}>
                      <span style={{ fontWeight: 700 }}>{text}</span>
                      {description}
                    </span>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <button
              type="button"
              onClick={handleGoToServiceForm}
              className={classNames(styles.button, styles.blueButton)}
            >
              SOLICITE UM ORÇAMENTO SEM COMPROMISSO
              <ArrowUp3 className={styles.arrowIcon} color="white" />
            </button>
          </Grid>
        </Grid>
      </Grid>
      <footer className={styles.footer}>
        <Grid className={styles.footerWrapper}>
          <div className={styles.logoContainer}>
            <Svg icon={ReferaLogoWhite} ariaLabel="Logo da Refera" className={styles.referaLogo} />
            <Typography className={styles.logoText} style={{ color: 'white' }}>
              A Plataforma
              <br />
              de manutenções
            </Typography>
          </div>
          <div className={styles.footerGroup}>
            <span className={styles.footerGroupTitle}>Contato</span>
            <div className={styles.contactButtonsContainer}>
              <a
                href="https://wa.me/5548991813893"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Whatsapp"
              >
                <Svg
                  icon={WhatsappIcon}
                  ariaLabel="Logo do Whatsapp"
                  className={styles.contactIcons}
                />
              </a>
              <a
                href="https://www.facebook.com/AppRefera/"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Facebook"
              >
                <Svg
                  icon={FacebookIcon}
                  ariaLabel="Logo do Facebook"
                  className={styles.contactIcons}
                />
              </a>
              <a
                href="https://www.instagram.com/apprefera/"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Instagram"
              >
                <Svg
                  icon={InstagramIcon}
                  ariaLabel="Logo do Instagram"
                  className={styles.contactIcons}
                />
              </a>
              <a
                href="https://www.youtube.com/@refera9876/featured"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Youtube"
              >
                <Svg
                  icon={YoutubeIcon}
                  ariaLabel="Logo do Youtube"
                  className={styles.contactIcons}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/refera/"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Linkedin"
              >
                <Svg
                  icon={LinkedinIcon}
                  ariaLabel="Logo do Linkedin"
                  className={styles.contactIcons}
                />
              </a>
            </div>
          </div>
        </Grid>
      </footer>
    </Grid>
  )
}
