import React from 'react'
import { Typography, Tooltip } from '@material-ui/core'

import Svg from '_components/svg'
import CloseIcon from '_assets/icons/ic-close-circle.svg'
import SaveIcon from '_assets/icons/ic-tick-circle.svg'

import useStyles, { useIndicatorStyles } from './styles'

const Indicator = ({ color, agency }) => {
  const borderPixels = color?.get('colorInHex') === '#FFFFFF' ? 1 : 0
  const rounded = agency ? 0 : '50%'

  const styles = useIndicatorStyles({
    color: color?.get('colorInHex'),
    border: borderPixels,
    radius: rounded,
  })

  return (
    <div className={styles.indicatorContainer}>
      <span className={styles.indicator} />
    </div>
  )
}

const ClassificationTypeCell = ({ value }) => {
  const styles = useStyles()

  return (
    <Tooltip title={value?.get('description')}>
      <Typography className={styles.cellText}>{value?.get('description')}</Typography>
    </Tooltip>
  )
}

const ActiveCell = ({ active }) => {
  const styles = useStyles()

  if (active) {
    return <Svg icon={SaveIcon} className={styles.icon} />
  }

  return <Svg icon={CloseIcon} className={styles.icon} />
}

export const COLUMNS = [
  {
    field: 'color',
    headerName: 'Cor',
    width: 75,
    renderCell: ({ value, row }) => <Indicator color={value} agency={row.agency} />,
  },
  {
    field: 'classificationName',
    headerName: 'Classificação',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Descrição',
    flex: 1,
  },
  {
    field: 'classificationType',
    headerName: 'Tipo de classificação',
    flex: 1,
    renderCell: ({ value }) => <ClassificationTypeCell value={value} />,
  },
  {
    field: 'active',
    headerName: 'Ativo',
    width: 100,
    renderCell: ({ value }) => <ActiveCell active={value} />,
  },
]
