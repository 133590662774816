import React from 'react'
import { Grid } from '@material-ui/core'
import { Button, Typography } from '@refera/ui-web'
import Draggable from 'react-draggable'

import Theme from '@refera/ui-core'

import DotsGroup from '_assets/svgs/dots-group.svg'
import { DollarCircle as DollarCircleIcon } from '@refera/ui-icons'
import Svg from '_/components/svg'

import useStyles from './styles'
import useRolePermission from '_/hooks/use-role-permission'

const FloatingMenu = ({
  selected,
  items,
  handleGoToConfirm,
  removePaymentAction = false,
  isCsRefera = false,
}) => {
  const styles = useStyles()
  const { isTradesman } = useRolePermission()

  return (
    <Draggable handle="#draggable-floating-menu" cancel={'[class*="MuiDialogContent-root"]'}>
      <Grid className={styles.floatingContainer}>
        <Grid className={styles.floatingWrapper}>
          <Grid className={styles.draggable} id="draggable-floating-menu">
            <Svg className={styles.dotsIcon} icon={DotsGroup} />
          </Grid>
          <Grid className={styles.floatingContent}>
            <Grid className={styles.floatingLabels}>
              <Typography fontSize={14}>
                Selecionado:{' '}
                <b>
                  {selected}
                  {selected > 1 ? ' itens' : ' item'}
                </b>
              </Typography>
              <Typography fontSize={14}>|</Typography>
              <Typography fontSize={14}>
                Valor total: <b>{items.totalPriceServiceProvider}</b>
              </Typography>
              {!isTradesman && (
                <>
                  <Typography fontSize={14}>•</Typography>
                  <Typography fontSize={14}>
                    Valor dos juros de antecipação ({items.interestPercentage}%):{' '}
                    <b style={{ color: Theme.Colors.Red.Base }} fontSize={14}>
                      - {items.interestValue}
                    </b>
                  </Typography>
                  <Typography fontSize={14}>=</Typography>
                  <Typography fontSize={14}>
                    Valor final a receber:{' '}
                    <b style={{ color: Theme.Colors.Primary.Base }} fontSize={14}>
                      {items.totalValue}
                    </b>
                  </Typography>
                </>
              )}
            </Grid>
            <Grid>
              {Boolean(handleGoToConfirm) && (
                <Button
                  className={styles.button}
                  variant="ghost"
                  startIcon={
                    <DollarCircleIcon
                      color={Theme.Colors.Primary.Base}
                      style={{ fontSize: '24px' }}
                    />
                  }
                  onClick={handleGoToConfirm}
                >
                  {isCsRefera ? 'Dar baixa nos pagamentos' : 'Solicitar antecipação'}
                </Button>
              )}
              {removePaymentAction && (
                <Button
                  className={styles.redButton}
                  variant="ghost"
                  startIcon={
                    <DollarCircleIcon color={Theme.Colors.Red.Base} style={{ fontSize: '24px' }} />
                  }
                  onClick={removePaymentAction}
                >
                  Cancelar pagamento de antecipações
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Draggable>
  )
}

export default FloatingMenu
