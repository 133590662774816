import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  dealine: {
    '& .ReferaTimer--label': {
      fontFamily: `${Theme.Typography.FontFamily.DMSans} !important`,
    },
  },
}))
