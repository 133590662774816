import React, { useCallback, useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Modal, Button, Dialog } from '@refera/ui-web'
import { CloseCircle as CloseCircleIcon } from '@refera/ui-icons'
import { useParams } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'

import { GENERIC_PARAMETERS } from '_utils/constants/service-order'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'
import { getHelpRequestsByIDSelector } from '_modules/helpRequests/selectors'
import { getGenericParameters } from '_modules/service-orders/actions'
import Select from '_components/common/select'

import useStyles from './styles'

const ShowToIntermediaryDialog = ({ open, value, onCancel, onConfirm = () => {} }) => {
  const styles = useStyles()
  const { id: helpRequestId } = useParams()
  const [selectedOption, setSelectedOption] = useState(value ?? '')
  const helpRequest = useSelector(getHelpRequestsByIDSelector(helpRequestId))
  const [showConfirmation, setShowConfirmation] = useState(false)

  const dispatch = useDispatch()

  const handleConfirm = useCallback(() => {
    onConfirm(selectedOption)
    setShowConfirmation(false)
  }, [dispatch, helpRequest?.id, selectedOption])

  const options = [
    {
      value: '',
      label: 'Selecione',
    },
    {
      value: true,
      label: 'Sim',
    },
    {
      value: false,
      label: 'Não',
    },
  ]

  const handleOptionChange = event => {
    const selectedOptions = event.target?.value
    setSelectedOption(selectedOptions)
  }

  const isHelpRequestActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.ALLOW_HELPREQUEST_PAUSE_SERVICE,
  })

  useEffect(() => {
    dispatch(
      getGenericParameters({
        name: GENERIC_PARAMETERS.ALLOW_HELPREQUEST_PAUSE_SERVICE,
      })
    )
  }, [dispatch])

  const handleSave = () => {
    const checkCanPause =
      selectedOption === true &&
      helpRequest?.fromTo === 'tradesman_to_refera' &&
      helpRequest?.datetimeResolved === null

    if (!isHelpRequestActive) {
      if (checkCanPause) {
        setShowConfirmation(true)
      }
    } else {
      onConfirm(selectedOption)
    }
  }

  return (
    <Modal
      className={styles.modal}
      title="Exibir item de ajuda para a intermediária"
      onBackdropClick={onCancel}
      onClose={onCancel}
      open={open}
      actionsButtons={
        <Grid className={styles.modalButtons}>
          <Button color="red" variant="secondary" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            disabled={selectedOption === ''}
            color="primary"
            variant="primary"
            onClick={handleSave}
          >
            Salvar
          </Button>
        </Grid>
      }
    >
      <Select
        name="intermediaryAvailable"
        label="Disponibilizar pedido para intermediária?"
        value={selectedOption}
        onChange={handleOptionChange}
        options={options}
      />

      {showConfirmation && (
        <Dialog
          open={showConfirmation}
          icon={CloseCircleIcon}
          type="info"
          subject="Atenção!"
          description="Essa alteração vai fazer o item de ajuda pausar o prazo do chamado ao qual está vinculado. Você tem certeza que deseja continuar?"
          labelApprove="Sim"
          labelCancel="Não"
          onApprove={handleConfirm}
          onCancel={() => setShowConfirmation(false)}
          closeButton
        />
      )}
    </Modal>
  )
}

export default ShowToIntermediaryDialog
