import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  modal: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  modalContent: {
    width: '100%',
  },
}))
