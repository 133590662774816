import React from 'react'
import { BaseContractModal } from '_components/modal/contracts/base'
import { Box } from '@mui/material'

function ContractSignatureStageModal({
  generateNewContract = () => {},
  resendContract = () => {},
  onCancel = () => {},
}) {
  return (
    <BaseContractModal.Root sx={{ maxWidth: '591px' }}>
      <BaseContractModal.Icon iconType="contract" sx={{ marginTop: '24px' }} />
      <Box display="flex" flexDirection="column" width="504px" marginTop="24px">
        <BaseContractModal.Header variant="variant2" sx={{ margin: '0 22px' }}>
          Atenção! Seu contrato com a Refera já foi gerado, mas ainda não foi assinado.
        </BaseContractModal.Header>
        <BaseContractModal.Paragraph sx={{ margin: '16px 32px 0' }}>
          Verificamos que seu contrato já foi gerado.
          <br />
          Deseja reenvia-lo para os emails indicados para assinatura, ou gerar um novo contrato?
          <br />
          <br />
          Atenção: caso um novo contrato seja gerado, as assinaturas já feitas serão perdidas.
        </BaseContractModal.Paragraph>
      </Box>
      <BaseContractModal.ActionButtonContainer sx={{ margin: '40px 0 16px' }}>
        <BaseContractModal.ActionButton
          variant="secondary"
          sx={{ width: '165px' }}
          color="red"
          onClick={onCancel}
        >
          Cancelar
        </BaseContractModal.ActionButton>
        <BaseContractModal.ActionButton
          variant="secondary"
          onClick={generateNewContract}
          sx={{ width: '165px' }}
        >
          Gerar novo contrato
        </BaseContractModal.ActionButton>
        <BaseContractModal.ActionButton
          variant="primary"
          onClick={resendContract}
          sx={{ width: '165px' }}
        >
          Reenviar contrato
        </BaseContractModal.ActionButton>
      </BaseContractModal.ActionButtonContainer>
    </BaseContractModal.Root>
  )
}

export default ContractSignatureStageModal
