import React, { useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

import { BasicInput, MaskBasicInput } from '_/components/inputs'
import { useLocation } from '@reach/router'
import { ROUTES } from '_/utils/constants'

export function GenerateContractCompanyDataPage({ props }) {
  const styles = useStyles()
  const { pathname } = useLocation()

  const isCondolivreContract = useMemo(
    () => pathname.includes(ROUTES.CONDOLIVRE_CONTRACT),
    [pathname]
  )

  const previousCompanyData = useMemo(() => {
    return props?.[0]?.company || {}
  }, [props])

  const description = useMemo(() => {
    return isCondolivreContract
      ? 'Confira os dados abaixo e selecione a opção Enviar. O cadastro de sua empresa será analisado pela parceria e as pessoas cadastradas na etapa anterior irão receber as atualizações de tal processo.'
      : 'Confira os dados abaixo e selecione a opção de enviar contrato. As pessoas cadastradas na etapa anterior irão receber uma cópia do contrato para análise e assinatura digital.'
  })

  return (
    <Grid className={[styles.responsibleInputContainer, styles.step3Container]}>
      <Typography variant="p" className={styles.paragraph}>
        {description}
      </Typography>

      <BasicInput
        label="Nome Completo"
        name="name"
        defaultValue={previousCompanyData?.name ?? ''}
        required
      />

      <MaskBasicInput
        label="CNPJ"
        name="cnpj"
        defaultValue={previousCompanyData?.cnpj ?? ''}
        mask="99.999.999/9999-99"
        required
      />

      <BasicInput
        label="Endereço"
        name="address"
        defaultValue={previousCompanyData?.address ?? ''}
        required
      />

      <BasicInput
        label="Número"
        name="streetNumber"
        defaultValue={previousCompanyData?.streetNumber ?? ''}
        required
        rules={{
          pattern: {
            value: /^\d{1,80}$/,
            message: 'Informe um número válido',
          },
        }}
      />

      <MaskBasicInput
        label="CEP"
        name="cep"
        defaultValue={previousCompanyData?.cep ?? ''}
        mask="99999-999"
        rules={{
          pattern: {
            value: /^\d{5}-\d{3}$/,
            message: 'Informe um CEP válido',
          },
        }}
        required
      />

      <BasicInput
        label="Complemento"
        name="extraAddressInfo"
        defaultValue={previousCompanyData?.extraAddressInfo ?? ''}
      />

      <BasicInput
        label="Bairro"
        name="neighborhood"
        defaultValue={previousCompanyData?.neighborhood ?? ''}
        required
      />

      <BasicInput
        label="Cidade"
        name="city"
        defaultValue={previousCompanyData?.city ?? ''}
        required
      />

      <BasicInput
        label="Estado"
        name="state"
        defaultValue={previousCompanyData?.state ?? ''}
        required
      />
    </Grid>
  )
}
