import { useCallback, useEffect, useMemo, useState } from 'react'
/* 
 Use/extend the config object to change the hook behavior as needed BUT 
 be aware to avoid changes that break other devs implementations :)

 keepArrays: avoid transform array props from initial state into strings 
 skipInitialValidation: avoid initial validation  
*/
export default (initialValues, validate, configs = {}) => {
  const { keepArrays, skipInitialValidation } = configs

  const [values, setValues] = useState(
    Object.entries(initialValues).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: !Array.isArray(value) || keepArrays === false ? value?.toString() || '' : value,
      }),
      {}
    )
  )
  const [errors, setErrors] = useState({})
  const [skipValidate, setSkipValidate] = useState(skipInitialValidation)

  const [touched, setTouched] = useState(
    Object.keys(values).reduce((obj, field) => ({ ...obj, [field]: false }), {})
  )

  const validateForm = useCallback(() => {
    const validationResult = validate(values) || {}

    setErrors(validationResult)
    return validationResult
  }, [validate, values])

  const setFieldTouched = useCallback((field, value) => {
    setTouched(prevValues => ({ ...prevValues, [field]: value }))
  }, [])

  const setFieldValue = useCallback((field, value) => {
    setValues(prevValues => ({ ...prevValues, [field]: value }))
  }, [])

  const setFieldValues = useCallback(newValues => {
    setValues(prevValues => ({ ...prevValues, ...newValues }))
  }, [])

  const setTouchedAll = useCallback(
    (isTouched = true) => {
      setTouched(prevValues =>
        Object.keys(prevValues).reduce((obj, field) => ({ ...obj, [field]: isTouched }), {})
      )
      validateForm()
    },
    [validateForm]
  )

  const handleBlur = useCallback(
    event => {
      const { name } = event.target
      setTouched(prevTouched => ({ ...prevTouched, [name]: true }))
      if (validate) {
        validateForm()
      }
    },
    [validate, validateForm]
  )

  const isValid = useMemo(() => !Object.keys(errors).length, [errors])

  const resetValues = useCallback(() => {
    setValues(initialValues)
  }, [initialValues])

  const handleInputChange = useCallback(event => {
    const { name, value } = event.target
    setValues(prevState => ({ ...prevState, [name]: value }))
  }, [])

  useEffect(() => {
    if (validate && touched && !skipValidate) {
      validateForm()
    }
    setSkipValidate(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched, validate, validateForm])

  return {
    errors,
    handleBlur,
    isValid,
    setErrors,
    setFieldTouched,
    setTouched,
    setTouchedAll,
    setValues,
    touched,
    values,
    resetValues,
    handleInputChange,
    setFieldValue,
    setFieldValues,
    validateForm,
  }
}
