import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { companySelector } from '_/modules/company/selector'
import { Toast, Alert } from '@refera/ui-web'
import { navigate, useLocation } from '@reach/router'

import humps from 'humps'

import ContractSignatureStageModal from './ContractSignatureStageModal'
import ConfirmNewContractGenerationModal from './ConfirmNewContractGenerationModal'
import ContractDeadlineOverModal from './ContractDeadlineOverModal'
import ContractNotSignedYetModal from './ContractNotSignedYetModal'
import CondolivreAlertsModal from './CondolivreAlertsModal'

import moment from 'moment'
import { postGenerateContract, requestSignatureEmail } from '_/modules/company/actions'
import { ROUTES } from '_/utils/constants'
import { reprovedCondolivreContract } from './utils'

export function ContractModalManager({ onFinish = () => {} }) {
  const company = useSelector(companySelector)
  const today = moment()
  const { pathname } = useLocation()
  const blockedContractDate = moment(company.blockedContractDate)
  const [currentModalOpen, setCurrentModalOpen] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' })

  const isCondolivreContract = useMemo(
    () => pathname.includes(ROUTES.CONDOLIVRE_CONTRACT),
    [pathname]
  )

  /* Action button functions */
  const closeManager = () => {
    setCurrentModalOpen(null)
    onFinish()
  }

  const handleSuccess = () => {
    setToast({
      isOpen: true,
      message: 'Ação realizada com sucesso.',
      type: 'success',
    })
  }

  const handleGenerateNewContract = async () => {
    try {
      setIsLoading(true)
      await dispatch(postGenerateContract()).then(() => navigate(ROUTES.GENERATE_CONTRACT))
    } catch (err) {
      const message = `Ocorreu um erro inesperado ao gerar novo contrato. Por favor, tente novamente mais tarde ou contacte a Refera.`

      setToast({
        isOpen: true,
        message,
        type: 'error',
      })
    } finally {
      setCurrentModalOpen(null)
      setIsLoading(false)
    }
  }

  const handleResendContract = async () => {
    try {
      setIsLoading(true)
      await dispatch(requestSignatureEmail())
      handleSuccess()
      onFinish()
    } catch (err) {
      const message = `Ocorreu um erro inesperado no reenvio do contrato. Por favor, tente novamente mais tarde ou contacte a Refera.`

      setToast({
        isOpen: true,
        message,
        type: 'error',
      })
    } finally {
      setCurrentModalOpen(null)
      setIsLoading(false)
    }
  }

  function generateNewContract() {
    setCurrentModalOpen(MODAL_COMPONENTS.ConfirmNewContractGeneration)
  }

  function closeModalAndRedirect() {
    closeManager()
    navigate(ROUTES.TRADESMAN)
  }

  const MODAL_COMPONENTS = useMemo(
    () => ({
      ContractSignatureStage: {
        component: ContractSignatureStageModal,
        props: {
          generateNewContract,
          resendContract: handleResendContract,
          onCancel: closeManager,
        },
      },
      ConfirmNewContractGeneration: {
        component: ConfirmNewContractGenerationModal,
        props: {
          onConfirm: handleGenerateNewContract,
          onCancel: closeManager,
          isLoading,
        },
      },
      ContractDeadlineOver: {
        component: ContractDeadlineOverModal,
        props: {
          onConfirm: () => navigate(ROUTES.GENERATE_CONTRACT),
          onCancel: closeManager,
          deadlineDate: blockedContractDate?.format('DD/MM/YYYY'),
        },
      },
      ContractNotSignedYet: {
        component: ContractNotSignedYetModal,
        props: {
          onConfirm: () => navigate(ROUTES.GENERATE_CONTRACT),
          onCancel: closeManager,
          deadlineDate: blockedContractDate?.format('DD/MM/YYYY'),
        },
      },
      CondolivreProcessing: {
        component: CondolivreAlertsModal,
        props: {
          icon: 'warning',
          title: 'Cadastro em processamento',
          description: 'Seu cadastro na Condolivre está sendo processado.',
          onConfirm: closeModalAndRedirect,
        },
      },
      CondolivreApproved: {
        component: CondolivreAlertsModal,
        props: {
          icon: 'check',
          title: 'Cadastro aprovado',
          description: 'Seu cadastro na Condolivre já está aprovado.',
          onConfirm: closeModalAndRedirect,
        },
      },
      CondolivreReproved: {
        component: CondolivreAlertsModal,
        props: {
          icon: 'warning',
          title: 'Cadastro reprovado',
          description: reprovedCondolivreContract(company?.condolivreError),
          confirmButtonLabel: 'Editar',
          onConfirm: closeManager,
          onCancel: closeModalAndRedirect,
        },
      },
    }),
    [blockedContractDate, isLoading, closeModalAndRedirect]
  )

  function setModalByStatus() {
    const status = company.contractStatus && humps.camelize(company.contractStatus.toLowerCase())

    if (isCondolivreContract) {
      const condolivreStatus = company?.condolivreStatus

      switch (condolivreStatus) {
        case 'processing':
        case 'started':
          setCurrentModalOpen(MODAL_COMPONENTS.CondolivreProcessing)
          break
        case 'approved':
          setCurrentModalOpen(MODAL_COMPONENTS.CondolivreApproved)
          break
        case 'reproved':
          setCurrentModalOpen(MODAL_COMPONENTS.CondolivreReproved)
          break
        case 'invited':
        default:
          break
      }
    } else {
      switch (status) {
        case 'processing':
          setCurrentModalOpen(MODAL_COMPONENTS.CondolivreProcessing)
          break

        case 'signed':
          break

        case 'inSignature':
          setCurrentModalOpen(MODAL_COMPONENTS.ContractSignatureStage)
          break

        default:
          if (today > blockedContractDate) {
            setCurrentModalOpen(MODAL_COMPONENTS.ContractDeadlineOver)
            break
          }

          setCurrentModalOpen(MODAL_COMPONENTS.ContractNotSignedYet)
      }
    }
  }

  const handleCloseToast = () => {
    setToast(prevState => ({ ...prevState, isOpen: false }))
  }

  /* Set modal based on status when company data loads */
  useEffect(() => {
    if (Object.hasOwn(company, 'contractStatus') || isCondolivreContract) {
      setModalByStatus()
    }
  }, [company.contractStatus, isCondolivreContract])

  const renderModal = () => {
    const Modal = currentModalOpen.component

    return <Modal {...currentModalOpen.props} />
  }

  return (
    <>
      {currentModalOpen && renderModal()}
      <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert severity={toast.type} title={toast.message} onClose={handleCloseToast} />
      </Toast>
    </>
  )
}
