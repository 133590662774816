/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Drawer, Grid, IconButton, Link, Typography } from '@material-ui/core'
import { Link as RouterLink, useLocation , navigate } from '@reach/router'

import MenuIcon from '_assets/svgs/hamburger-menu.svg'
import CloseIcon from '_assets/svgs/close-menu.svg'

import Theme from '@refera/ui-core'

import {
  Element4 as WidgetsNew,
  FolderMinus as ServerOrdersNew,
  MessageQuestion as HelpRequest,
  UserAdd as ProvidersLogoNew,
  House2 as Agency,
  DollarCircle as PaymentAdvance,
  MoneyRecive as DollarReceive,
  MoneySend as DollarSend,
  ReceiptText as ManageInstallments,
  Profile2User as COllaborators,
  Chart21 as ChartIcon,
} from '@refera/ui-icons'

import Svg from '_components/svg'
import { ROUTES, ROUTES_TO_HIDE_MENUS, USER_MODULES } from '_utils/constants'
import useRolePermission from '_hooks/use-role-permission'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '_modules/authentication/selectors'
import { menuItemsSelector } from '_modules/menu-items/selector'
import { getGenericParametersSelector } from '_modules/service-orders/selectors'
import { getMenuItems } from '_modules/menu-items/actions'
import { getGenericParameters } from '_modules/service-orders/actions'
import { useToast } from '_/hooks/use-toast'

const SideMenu = ({ className }) => {
  const styles = useStyles()
  const { unblockedTabs, checkPathPermission } = useRolePermission()
  const dispatch = useDispatch()
  const userMenuItems = useSelector(menuItemsSelector)
  const genericParameters = useSelector(getGenericParametersSelector)

  const user = useSelector(userSelector)
  const { pathname } = useLocation()
  const [isActive, setIsActive] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState()

  const { showToast } = useToast()

  const shouldHideMenu = useMemo(() => {
    return ROUTES_TO_HIDE_MENUS.includes(pathname)
  }, [pathname])

  const getMenu = useMemo(
    () =>
      userMenuItems?.filter(
        item =>
          item.active &&
          item.type === 'main' &&
          item.module.includes(
            user.lastSelectedModule !== undefined
              ? user.lastSelectedModule
              : USER_MODULES.MARKETPLACE
          )
      ),
    [unblockedTabs, userMenuItems, user]
  )

  useEffect(() => {
    const menu = getMenu.find(item => pathname.includes(item.path) && item.path.length > 1)?.path

    if (menu) {
      setSelectedMenu(menu)
      return
    }
    if (!menu && pathname !== ROUTES.MANAGE_ORDERS) {
      pathname.includes(ROUTES.SERVICE_ORDER) && setSelectedMenu(ROUTES.MANAGE_ORDERS)
      pathname.includes(ROUTES.DETAIL_SERVICE_ORDER) && setSelectedMenu(ROUTES.NEW_ORDERS)
      pathname.includes(ROUTES.NF_FORM) && setSelectedMenu(ROUTES.NEW_ORDERS)
      pathname.includes(ROUTES.PROVIDER) && setSelectedMenu(ROUTES.MANAGE_PROVIDERS)
      pathname.includes(ROUTES.AGENCY) && setSelectedMenu(ROUTES.MANAGE_AGENCIES)
      return
    }

    setSelectedMenu(ROUTES.MANAGE_ORDERS)
  }, [pathname, getMenu])

  const handleMenuClick = useCallback(() => {
    setIsActive(!isActive)
  }, [setIsActive, isActive])

  const MapIcon = useMemo(
    () => ({
      Widgets: WidgetsNew,
      ManageCallLogo: ServerOrdersNew,
      DollarCircle: PaymentAdvance,
      DollarReceive,
      DollarSend,
      ReceiptText: ManageInstallments,
      HelpLogo: HelpRequest,
      ProvidersLogo: ProvidersLogoNew,
      RealStateLogo: Agency,
      Collaborators: COllaborators,
      ChartLog: ChartIcon,
    }),
    []
  )

  const renderIcon = useCallback(
    item => {
      const Icon = MapIcon[item.icon]
      return (
        <Icon
          color={
            selectedMenu === item.path
              ? Theme.Colors.Primary.Base
              : Theme.Colors.Grayscale.ThirtyTwo
          }
        />
      )
    },
    [selectedMenu]
  )

  useEffect(() => {
    dispatch(getMenuItems())
    dispatch(
      getGenericParameters({
        name: 'reports',
      })
    )
  }, [dispatch])

  const handleClick = useCallback(path => {
    let newPath = path
    const userCanAccessPath = checkPathPermission(path)
    if (!userCanAccessPath) {
      return showToast({
        type: 'error',
        message: 'Você não tem permissão para esta ação.',
      })
    }

    if (!path.startsWith('/')) {
      newPath = `/${path}`
    }

    return navigate(newPath)
  }, [checkPathPermission, showToast])

  if (shouldHideMenu) return null

  return (
    <Drawer
      variant="permanent"
      className={classnames(
        styles.container,
        isActive ? styles.containerOpened : styles.containerClosed
      )}
    >
      <IconButton onClick={handleMenuClick} className={styles.menuIcon}>
        <Svg className={styles.icon} icon={isActive ? CloseIcon : MenuIcon} />
      </IconButton>

      {getMenu
        .filter(m =>
          genericParameters?.find(g => g.name === 'reports')?.active
            ? true
            : m.path !== '/relatorios'
        )
        .map(item => (
            <IconButton
              className={classnames({
                [styles.iconsExpanded]: isActive,
              })}
              key={item?.id}
              onClick={() => handleClick(item.path)}
            >
              {renderIcon(item)}
              {isActive && (
                <Grid className={styles.menuItem}>
                  <Typography
                    className={classnames(styles.menuLabel, {
                      [styles.filled]: item?.path === selectedMenu,
                    })}
                  >
                    {item.label}
                  </Typography>
                </Grid>
              )}
            </IconButton>
        ))}
    </Drawer>
  )
}

SideMenu.propTypes = {
  className: PropTypes.string,
}

SideMenu.defaultProps = {
  className: '',
}

export default React.memo(SideMenu)
