import { schema } from 'normalizr'

import Model from './model'
import State from './state'

class ReasonToRefuse extends Model({
  id: undefined,
  reason: undefined,
  tradesmanRefuseDetail: undefined,
}) {}

const reasonToRefuseNewSchema = new schema.Entity(
  'reasonToRefuse',
  {},
  {
    processStrategy: entity => {
      return new ReasonToRefuse(entity).set('state', new State(entity.state))
    },
  }
)

const reasonToRefuseSchema = [reasonToRefuseNewSchema]

export { reasonToRefuseSchema, ReasonToRefuse as default }
