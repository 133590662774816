import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  mainTable: {
    marginTop: '16px',
    width: 'auto',
    height: '650px',
  },
  link: {
    color: palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))
