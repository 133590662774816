/* eslint-disable no-param-reassign */
import React, { useMemo, useRef, useState } from 'react'
import { Grid, Input } from '@mui/material'

import useStyles from './styles'

export function ListProblems({ list, handleClick }) {
  const styles = useStyles()
  const [inputValue, setInputValue] = useState('')
  const timerRef = useRef(null) // debounce timer

  const handleSearch = async e => {
    const { value } = e.target

    // Clear the previous timer if it exists
    if (timerRef?.current) {
      clearTimeout(timerRef?.current)
    }

    // Set a new timer
    timerRef.current = setTimeout(() => {
      setInputValue(value)
    }, 200)
  }

  const filteredValues = useMemo(() => {
    return list?.filter(item => {
      if (inputValue === '') return true
      if (item?.name?.toLowerCase()?.includes(inputValue?.toLowerCase())) return true
      return false
    })
  }, [inputValue, list])

  return (
    <Grid className={styles.container}>
      <Input
        placeholder="Pesquisar"
        className={styles.searchInput}
        inputProps={{ className: styles.inputStyles }}
        onChange={handleSearch}
        fullWidth
      />
      <Grid className={styles.treeWrapper}>
        {filteredValues.map(item => (
          <button
            className={styles.button}
            key={item.id}
            type="button"
            onClick={() => handleClick(item.id)}
          >
            {item.name?.toLowerCase()}
          </button>
        ))}
      </Grid>
    </Grid>
  )
}
