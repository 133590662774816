import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  buttons: {
    padding: '7px 15px',
    color: '#1671ED',
    borderColor: '#1671ED',
    borderRadius: '20px',
    textTransform: 'none',
    fontWeight: '500',
  },
  buttonRed: {
    padding: '7px 15px',
    borderRadius: '20px',
    textTransform: 'none',
    color: 'red',
    border: '1px solid red',
    backgroundColor: 'transparent',
    fontWeight: '500',
  },
  buttonSend: {
    borderRadius: '20px',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  buttonSave: {
    color: '#fff',
    backgroundColor: '#1671ED',
    border: '1px solid #1671ED',
    borderRadius: '20px',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '7px 15px',
    fontWeight: '500',

    '&:hover': {
      boxShadow: 'none',
      color: '#fff',
      backgroundColor: '#1671ED',
    },
  },
  dialogContent: {
    padding: `${spacing(40)} !important`,
  },
}))
