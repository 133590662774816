import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ spacing, palette }) => ({
  dialog: {
    position: 'absolute',
    width: '654px',
    height: '504px',
  },
  button: {
    width: '100px',
    borderRadius: '24px',
    height: '40px',
    textTransform: 'none',
    marginBottom: '15px',
  },
  dialogTitle: {
    textAlign: 'justify',
    color: '#4169e1',
  },
  dialogContent: {
    textAlign: 'justify',
    fontSize: '16px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    overflow: 'hidden',
  },
  dialogAction: {
    justifyContent: 'right',
    margin: '20px',
  },
  titleIconSuccess: {
    margin: '15px',
    backgroundColor: '#EBF6EB',
    color: '#008908',
    fontSize: '6rem',
    '& .MuiSvgIcon-root': {
      fontSize: '6rem',
    },
  },
  iconeSVG: {
    width: '50px',
    height: '50px',
  },
  labelText: {
    fontSize: spacing(16),
    lineHeight: spacing(16),
    color: palette.gray[32],
    marginTop: spacing(10),
  },
  text: {
    fontSize: spacing(16),
    lineHeight: spacing(16),
    color: palette.gray[64],
    marginTop: spacing(10),
  },
  select: {
    fontSize: spacing(16),
    lineHeight: spacing(16),
    color: palette.gray[64],
    marginTop: spacing(10),
    width: '544px',
  },
  input: {
    '& .MuiOutlinedInput-multiline': {
      alignItems: 'baseline',
      minHeight: spacing(152),
      marginTop: spacing(10),
    },
  },
  wrapper: {
    '&:not(:first-child)': {
      marginTop: spacing(32),
      paddingTop: spacing(24),
      borderTop: `${spacing(1)} solid ${palette.gray[12]}`,
    },
  },
}))
