import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    marginLeft: spacing(34),
    marginRight: spacing(23),
    marginTop: spacing(20),
    marginBottom: spacing(20),
  },
  boxes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: spacing(30),
  },
  box: {
    marginBottom: spacing(32),
    border: '1px solid #E3E3E3',
    boxSizing: 'borderBox',
    borderRadius: spacing(8),
  },
  boxTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '23px 23px',
  },
  closeIcon: {
    width: spacing(24),
    heigth: spacing(24),
    marginRight: spacing(10),
  },
  title: {
    display: 'flex',
    marginBottom: spacing(24),
  },
  chip: {
    borderRadius: spacing(24),
    textTransform: 'none',
    fontFamily: ['Roboto', 'sans-serif'],
    padding: '8px 12px',
    fontSize: '16px',
    lineHeight: '112.5%',
    cursor: 'pointer',
  },
  chipEnabled: {
    backgroundColor: Theme.Colors.Primary.Base,
    color: Theme.Colors.Primary.Lightest,
  },
  chipDisabled: {
    backgroundColor: Theme.Colors.Primary.Lightest,
    color: Theme.Colors.Primary.Base,
  },
}))
