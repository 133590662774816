import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from '@reach/router'
import { Button } from '@refera/ui-web'

import { getOriginalBudget } from '_modules/budget/selectors'
import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import { BUTTONS_ACTION } from '_utils/constants/service-order'
import { BudgetContext } from '_utils/budget'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { updateBudget } from '_/modules/budget/actions'

const EditBudgetButton = () => {
  const { isEditing, toggleIsEditing, onUpdateBudgetSuccess, onUpdateBudgetError, setIsLoading } =
    useContext(BudgetContext)
  const { budgetId } = useParams()
  const { isTradesman, isSAAS, checkUserPermission } = useRolePermission()
  const budget = useSelector(getOriginalBudget(budgetId))?.toJS()
  const dispatch = useDispatch()

  const tradesmanCanEditBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.EDIT_BUDGET_BY_PROVIDER,
    isBudgetRelated: true,
  })

  const isProposeOpened = useMemo(() => budget?.proposeOpened, [budget?.proposeOpened])

  const isEditButtonVisible = useMemo(() => {
    if (isTradesman || isSAAS) {
      return !isEditing && tradesmanCanEditBudget
    }

    return !isEditing
  }, [isEditing, isTradesman, tradesmanCanEditBudget, isSAAS])

  const handleEditButtonClick = useCallback(() => {
    if (!isProposeOpened) {
      toggleIsEditing()
      return
    }
    setIsLoading(true)
    dispatch(
      updateBudget(budget?.serviceOrder, budgetId, {
        released: true,
      })
    )
      .then(onUpdateBudgetSuccess)
      .catch(onUpdateBudgetError)
      .finally(() => setIsLoading(false))
  }, [isProposeOpened, toggleIsEditing])

  if (!isEditButtonVisible) {
    return null
  }

  return (
    <Button
      onClick={() =>
        checkUserPermission({
          permission: isTradesman || isSAAS ? PERMISSIONS.ORIGINAL_BUDGETS : PERMISSIONS.BUDGETS,
          permissionAction: PERMISSIONS_ACTIONS.ADD,
          functionAccess: handleEditButtonClick,
        })
      }
      color="primary"
      variant="secondary"
    >
      {isProposeOpened && (isTradesman || isSAAS) ? 'Aceitar Orçamento' : 'Editar'}
    </Button>
  )
}

export default EditBudgetButton
