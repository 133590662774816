import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(8, 16),
    maxHeight: spacing(600),
  },
  title: {
    color: 'black',
    fontSize: spacing(16, '!important'),
    fontWeight: '500 !important',
    marginBottom: '24px !important',
  },
  subtitle: {
    color: '#696969',
    fontSize: spacing(14, '!important'),
    fontWeight: '500 !important',
    marginBottom: '16px !important',
  },
  link: {
    fontSize: spacing(14, '!important'),
    fontWeight: '400 !important',
    marginBottom: '12px !important',
  },
  linksWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    maxHeight: '315px',
  },
  sublink: {
    color: '#1671ED',
    marginTop: '5px',
    paddingLeft: '5px',
    fontSize: spacing(14, '!important'),
    fontWeight: '500 !important',
    marginBottom: '24px !important',
  },
  supportIcon: {
    objectFit: 'contain',
  },
  arrowIcon: {
    fontSize: spacing(8, '!important'),
    marginLeft: '7px',
  },
  bottomMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: spacing(10, 0),
    borderRadius: '8px',

    color: '#696969',
    fontSize: spacing(14, '!important'),
    fontWeight: '500 !important',
    textTransform: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
      color: '#1671ED',
    },
  },
}))
