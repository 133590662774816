import React, { useState, useEffect, useCallback } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useParams } from '@reach/router'
import { WhatsApp } from '@material-ui/icons'

import Header, { HEADER_THEME } from '_components/header'
import useToggle from '_hooks/use-toggle'
import Button from '_components/button'
import DocumentForward from '_assets/svgs/document-forward.svg'
import Svg from '_components/svg'
import SendWhatsappModal from '_components/modal/send-whatsapp-dialog'
import { getBudget } from '_modules/budget/actions'

import useStyles from './styles'

const ReasonDevolution = () => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const [isSendWhatsappDialogOpen, onToggleSendWhatsappDialog] = useToggle()
  const budgetId = useParams()?.budgetId
  const serviceOrderId = useParams()?.serviceOrderId
  const [budget, setBudget] = useState([])

  useEffect(() => {
    Promise.resolve(dispatch(getBudget(serviceOrderId, budgetId))).then(res => setBudget(res))
  }, [])

  const onSendWhatsapp = useCallback(() => {
    onToggleSendWhatsappDialog()
  }, [onToggleSendWhatsappDialog])

  return (
    <>
      <Header theme={HEADER_THEME.NO_BUTTONS} />
      <Grid component="main" className={styles.container}>
        <Svg icon={DocumentForward} className={styles.image} />
        <Typography component="h1" className={styles.title}>
          Orçamento devolvido ao Prestador
        </Typography>
      </Grid>
      <>
        <Grid component="main" className={styles.noContainer}>
          <Typography className={styles.textTitle}>Motivo da devolução:</Typography>
          <Typography component="h3">{budget?.reasonDevolution}</Typography>
        </Grid>
      </>
      <Grid>
        <Button variant="contained" className={styles.whatsButton} onClick={onSendWhatsapp}>
          <WhatsApp className={styles.iconWhatsApp} />
          Enviar por WhatsApp
        </Button>
      </Grid>
      <Typography className={styles.textRefera}>
        © 2022 Refera Tecnologia - Desenvolvido por Refera
      </Typography>
      {isSendWhatsappDialogOpen && (
        <SendWhatsappModal
          isOpen
          closeModal={onToggleSendWhatsappDialog}
          serviceOrderId={serviceOrderId}
          budgetId={budgetId}
        />
      )}
    </>
  )
}

export default React.memo(ReasonDevolution)
