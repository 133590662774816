import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'grid',
    gap: spacing(8),
    '.MuiInputBase-root': {
      width: '100%',
    },
  },
  label: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.OneThousand,
  },
  optionList: {
    background: palette.background.default,
    boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.16)`,
    maxHeight: 300,
    boxSizing: 'border-box',
    padding: spacing(5),
    margin: spacing(8, 0),
    minWidth: 320,
    overflow: 'auto',
    outline: 0,
  },
  option: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: Theme.Typography.FontSize.XSmall,
    color: Theme.Colors.Grayscale.Eighty,
  },
  select: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: Theme.Typography.FontSize.XSmall,
    color: Theme.Colors.Grayscale.Eighty,
    background: 'transparent',
    cursor: 'pointer',
    boxSizing: 'border-box',
    minHeight: 'calc(1.5em + 22px)',
    minWidth: 320,
    border: 0,
    borderBottom: `2px solid ${Theme.Colors.Grayscale.Twelve}`,
    padding: spacing(10),
    textAlign: 'left',
    lineHeight: 1.5,
    position: 'relative',
  },
  hint: {
    padding: spacing(4, 0),
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.OneThousand,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(4),
    padding: spacing(4, 0),
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Red.Base,
  },
  loaderContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
}))
