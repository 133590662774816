const TO_FROM_OPTIONS = {
  TRADESMAN_REFERA: { label: 'Prestador para Refera', value: 'tradesman_to_refera' },
  REFERA_TO_INTERMEDIARY: { label: 'Refera para Intermediária', value: 'refera_to_intermediary' },
  INTERMEDIARY_TO_REFERA: { label: 'Intermediária para Refera', value: 'intermediary_to_refera' },
}

export const translateToFromValue = value =>
  TO_FROM_OPTIONS[
    Object.keys(TO_FROM_OPTIONS).find(option => TO_FROM_OPTIONS[option].value === value)
  ]?.label
