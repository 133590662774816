import React, { useState, useCallback, useEffect, useMemo } from 'react'
import HeaderTitle from '_/components/header-title'
import Button, { BUTTON_COLOR } from '_components/button'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'
import { Box, IconButton, Typography } from '@material-ui/core'
import { HTMLEditor } from '_components/inputs'
import { Danger as InfoDangerIcon } from '@refera/ui-icons'
import { navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { createCoverageRule, getCoverageRuleVersion } from '_/modules/budget/actions'
import { getCoverageRuleVersionSelector } from '_/modules/budget/selectors'
import useRolePermission from '_hooks/use-role-permission'
import { useToast } from '_/hooks/use-toast'
import { useConfirm, Dialog } from '@refera/ui-web'
import moment from 'moment'

import CoverageTable from './coverage-table'
import CoverageModal from './coverage-modal'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import useStyles from './styles'

const defaultDialog = {
  isOpen: false,
  subject: '',
  icon: InfoDangerIcon,
  iconType: 'info',
  description: '',
  labelApprove: 'Ok, entendi',
  onApprove: () => {},
}

const CoverageRule = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { isAdmin } = useRolePermission()
  const { isConfirmed } = useConfirm()

  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')
  const [dialogInfo, setDialogInfo] = useState(defaultDialog)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [currentValue, setCurrentValue] = useState({ budgetValue: '', coveragePercentage: '' })

  const [open, setOpen] = useState(false)

  const coverageRuleVersion = useSelector(getCoverageRuleVersionSelector)

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      description: coverageRuleVersion?.description || '',
      description_json: coverageRuleVersion?.descriptionJson || {},
      valueData: coverageRuleVersion?.values || [],
    },
  })

  const {
    handleSubmit,
    watch,
    control,
    reset,
    formState: { dirtyFields },
  } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'valueData',
  })

  const watchedDescription = watch('description')
  const isValueDataDirty = !!dirtyFields.valueData

  const onSubmit = useCallback(
    async data => {
      setConfirmMessage('Se você continuar, uma nova versão da regra de cobertura será criada.')
      setIsLoading(true)
      const payload = {
        title: '',
        versionData: data.description,
        valueData: fields,
      }

      if (!isAdmin) {
        setIsLoading(false)
        showToast({
          type: 'error',
          message:
            'Você não tem permissão para gerenciar os dados desta área. Por favor, fale com seu administrador.',
        })
        return
      }

      const confirm = await isConfirmed()

      if (confirm) {
        dispatch(createCoverageRule(payload))
          .then(() => {
            showToast({ type: 'success' })
            dispatch(getCoverageRuleVersion()).then(res => {
              reset({
                description: res.description,
                valueData: res.values,
              })
            })
          })
          .catch(() => {
            showToast({ type: 'error' })
          })
      }

      setIsLoading(false)
    },
    [isAdmin, fields, dispatch, showToast, reset]
  )

  const isDescriptionEdited = useMemo(() => {
    if (!watchedDescription?.json) return false

    const areEqual =
      JSON.stringify(watchedDescription?.json) ===
      JSON.stringify(coverageRuleVersion?.descriptionJson)

    return !areEqual
  }, [watchedDescription?.json, coverageRuleVersion?.descriptionJson])

  const handleCancel = useCallback(() => {
    if ((isDescriptionEdited || isValueDataDirty) && isEditing) {
      setDialogInfo({
        isOpen: true,
        iconType: 'warning',
        icon: InfoDangerIcon,
        labelApprove: 'Sim',
        labelCancel: 'Não',
        subject: 'Aviso',
        description: 'Você tem certeza que deseja cancelar estas alterações?',
        onApprove: () => {
          setDialogInfo(prev => ({ ...prev, isOpen: false }))
          navigate(-1)
        },
        onCancel: () => {
          setDialogInfo(prev => ({ ...prev, isOpen: false }))
        },
      })
    } else {
      navigate(-1)
    }
  }, [isDescriptionEdited, isEditing, isValueDataDirty])

  const handleModalOpen = (index = null) => {
    setCurrentIndex(index)
    if (index !== null) {
      setCurrentValue(fields[index])
    } else {
      setCurrentValue({ budgetValue: '', coveragePercentage: '' })
    }
    setOpen(true)
  }

  const handleModalClose = () => {
    setOpen(false)
    setCurrentIndex(null)
    setCurrentValue({ budgetValue: '', coveragePercentage: '' })
  }

  const handleVersionChange = useCallback(
    type => {
      let versionToCall = coverageRuleVersion?.version || 1

      if (type === 'previous' && versionToCall > 1) {
        if ((isDescriptionEdited || isValueDataDirty) && isEditing) {
          setDialogInfo({
            isOpen: true,
            iconType: 'warning',
            icon: InfoDangerIcon,
            labelApprove: 'Sim',
            labelCancel: 'Não',
            subject: 'Aviso',
            description: 'Você tem certeza que deseja cancelar estas alterações?',
            onApprove: () => {
              setDialogInfo(prev => ({ ...prev, isOpen: false }))
              versionToCall -= 1
              dispatch(getCoverageRuleVersion({ version: versionToCall })).then(res => {
                reset({
                  description: res.description,
                  valueData: res.values,
                })
              })
            },
            onCancel: () => {
              setDialogInfo(prev => ({ ...prev, isOpen: false }))
              versionToCall = null
            },
          })
        } else {
          versionToCall -= 1
        }
      } else {
        versionToCall += 1
      }
      if (versionToCall) {
        dispatch(getCoverageRuleVersion({ version: versionToCall }))
      }
    },
    [
      isDescriptionEdited,
      coverageRuleVersion?.version,
      dispatch,
      isEditing,
      isValueDataDirty,
      reset,
    ]
  )

  useEffect(() => {
    dispatch(getCoverageRuleVersion()).then(res => {
      if (res) {
        reset({
          description: res.description,
          valueData: res.values,
        })
      }
    })
  }, [])

  useEffect(() => {
    setIsEditing(coverageRuleVersion?.mode === 'edit')
  }, [coverageRuleVersion])

  return (
    <>
      <main className={styles.container}>
        <HeaderTitle title="Regra de cobertura" backButtonAction={() => navigate(-1)}>
          <div style={{ display: 'flex', gap: '8px' }}>
            {isEditing && (
              <>
                <Button
                  style={{ width: '100px' }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoading}
                >
                  Salvar
                </Button>
                <Button
                  style={{ width: '100px' }}
                  color={BUTTON_COLOR.RED}
                  variant="outlined"
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </>
            )}
          </div>
        </HeaderTitle>

        <FormProvider {...methods}>
          <form id="coverageRuleForm" className={styles.content}>
            <div className={styles.info}>
              <Box className={styles.box}>
                <Typography className={styles.typography} variant="body2">
                  <strong>Criada em:</strong>{' '}
                  {moment(coverageRuleVersion?.createdAt).format('DD/MM/YYYY [às] HH:mm')}
                </Typography>
                <Typography className={styles.typography} variant="body2">
                  <strong>Autor:</strong> {coverageRuleVersion?.user}
                </Typography>
              </Box>

              <div className={styles.buttonsContainer}>
                {coverageRuleVersion?.version > 1 && (
                  <IconButton onClick={() => handleVersionChange('previous')}>
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                )}
                <span className={styles.textContainer}>
                  <Typography className={styles.typography}>
                    <strong>Versão</strong>
                  </Typography>
                  <Typography className={styles.typography}>
                    <strong>{coverageRuleVersion?.version}</strong>
                  </Typography>
                </span>
                <div className={styles.placeholder}>
                  {!isEditing && (
                    <IconButton onClick={() => handleVersionChange('next')}>
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>

            <CoverageTable
              fields={fields}
              remove={remove}
              isEditing={isEditing}
              handleModalOpen={handleModalOpen}
            />

            {isEditing ? (
              <HTMLEditor
                required
                disabled={!isEditing}
                name="description"
                label="Descrição da Refera:"
                labelClasses={styles.sectionTitle}
                style={{ gridArea: 'referaDescription' }}
                defaultValue={coverageRuleVersion?.descriptionJson || ''}
              />
            ) : (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Typography className={styles.typography}>
                  <strong>Descrição da Refera:</strong>
                </Typography>
                <span
                  style={{
                    border: '1px solid #E3E3E3',
                    borderRadius: '5px',
                    padding: '10px',
                    minHeight: '150px',
                    width: '100%',
                  }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: coverageRuleVersion?.description || '' }}
                />
              </div>
            )}
          </form>
        </FormProvider>
      </main>

      <CoverageModal
        open={open}
        append={append}
        remove={remove}
        fields={fields}
        currentIndex={currentIndex}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        handleModalClose={handleModalClose}
      />

      {dialogInfo.isOpen && (
        <Dialog
          open={dialogInfo.isOpen}
          type={dialogInfo.iconType}
          icon={dialogInfo.icon}
          subject={dialogInfo.subject}
          description={dialogInfo.description}
          labelApprove={dialogInfo.labelApprove}
          onApprove={dialogInfo.onApprove}
          labelCancel={dialogInfo.labelCancel}
          onCancel={dialogInfo.onCancel}
        />
      )}

      <ConfirmRemoveDialog message={confirmMessage} />
    </>
  )
}

export default CoverageRule
