import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  container: {
    width: '480px',
    height: '390px',
  },
  icon: {
    width: '250px',
    height: '166px',
    marginLeft: '90px',
    alignItems: 'center',
  },
  title: {
    color: '#434343',
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '25px',
  },
  description: {
    color: '#434343',
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '10px',
  },
  subTitle: {
    color: '#434343',
    fontFamily: 'DM Sans',
    fontSize: '14px',
    fontWeight: '500',
    marginTop: '24px',
  },
}))
