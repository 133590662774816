import React, { useMemo } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Slide,
} from '@material-ui/core'
import { Button, Loader } from '@refera/ui-web'
import Draggable from 'react-draggable'

import useStyles from './styles'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export const CommonModal = ({
  open,
  onClose,
  installmentInfo,
  loading,
  onSubmit,
  handleInputType,
  modalType,
  children = null,
}) => {
  const styles = useStyles({ modalType })

  const title = useMemo(
    () => (modalType === 'installment' ? 'Editar parcela' : 'Editar pagamento'),
    [modalType]
  )

  return (
    <Dialog
      open={open}
      maxWidth="auto"
      onClose={onClose}
      className={styles.modal}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} className={styles.dialogTitle} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <form className={styles.contentContainer} onSubmit={() => {}}>
          {installmentInfo?.map(installmentItem => {
            return <Grid key={installmentItem.name}>{handleInputType(installmentItem)}</Grid>
          })}
        </form>
        {children}
      </DialogContent>
      <DialogActions className={styles.dialogAction}>
        <Grid className={styles.modalButtons}>
          <Button color="red" variant="secondary" onClick={onClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={loading}>
            Salvar
          </Button>
        </Grid>
      </DialogActions>
      <Loader hasBackdrop open={loading} />
    </Dialog>
  )
}
