import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  mainTable: {
    marginTop: '16px',
    width: 'auto',
    height: '616px',
  },
  modal: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    minWidth: '360px',
  },

  // Floating Menus
  floatingContainer: {
    display: 'flex',
    position: 'absolute',
    left: '20vw',
    transform: 'translateX(-20vw)',
    bottom: '20px',
  },
  floatingWrapper: {
    display: 'flex',
    width: 'auto',
    backgroundColor: '#fff',
    minWidth: spacing(966),
    height: spacing(101),
    borderRadius: spacing(4),
    border: `1px solid ${Theme.Colors.Primary.Base}`,
    boxShadow: ' 0px 1px 4px rgba(0, 0, 0, 0.16)',
  },
  floatingContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: spacing(16, 28, 16, 8),
    marginLeft: spacing(12),
    gap: spacing(8),
  },
  floatingLabels: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(8),
    marginLeft: spacing(4),
    alignItems: 'center',
  },
  draggable: {
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: spacing(32),
    backgroundColor: Theme.Colors.Primary.Base,

    '&:active': {
      cursor: 'grabbing',
    },
  },
  dotsIcon: {
    fontSize: spacing(20),
  },
  button: {
    padding: spacing(4, '!important'),
    height: 'fit-content',
  },
  redButton: {
    padding: spacing(4, '!important'),
    height: 'fit-content',
    color: `${Theme.Colors.Red.Base} !important`,

    '&:hover': {
      color: `${Theme.Colors.Red.Dark} !important`,
    },
  },

  // Download spreadsheet
  select: {
    color: Theme.Colors.Grayscale.Eighty,
    fontSize: '16px',
  },
}))
