import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Svg from '_components/svg'
import TimeManagement from '_assets/svgs/time-management.svg'

import useStyles from './styles'

const SelfLearningTemplate = () => {
  const styles = useStyles()

  return (
    <Grid className={styles.container}>
      <Svg icon={TimeManagement} className={styles.icon} />
      <Typography className={styles.title} variant="h4">
        O que são as sugestões de horário?
      </Typography>
      <Typography className={styles.description} variant="h4">
        Alguns reparos requerem que o prestador visite a sua residência para realizar um orçamento
        mais preciso. Por isso, pedimos que você deixe sugestões de horário para que essa vista
        aconteça, caso ela seja necessária. Você pode escolher entre duas opções de seleção:
        períodos do dia ou horário personalizado.
      </Typography>
      <Typography className={styles.subTitle}>Períodos do dia</Typography>
      <Typography className={styles.description} variant="h4">
        Temos algumas opções de períodos para você receber o prestador: manhã, tarde, ou dia
        inteiro. O prestador pode realizar sua visita a qualquer momento dentro do horário e dia
        determinados.
      </Typography>
      <Typography className={styles.subTitle}>Horário personalizado</Typography>
      <Typography className={styles.description} variant="h4">
        Ao selecionar essa opção, você pode escolher um intervalo de tempo personalizado para
        receber sua visita. É necessário que o intervalo tenha o mínimo de duas horas de duração,
        por exemplo: das 10h até as 12h. O prestador poderá chegar a qualquer momento, dentro do
        horário escolhido por você.
      </Typography>
    </Grid>
  )
}

export default SelfLearningTemplate
