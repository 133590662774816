import { get } from './requests'

import humps from 'humps'

export const getCities = key => params =>
  get(['cities'], { key, params: humps.decamelizeKeys(params) })

export const getStates = key => params =>
  get(['states'], { key, params: humps.decamelizeKeys(params) })

export const getArticles = key => get(['articles'], { key })

export const getCsrefera = key => params =>
  get(['responsible_refera'], { key, params: humps.decamelizeKeys(params) })

export const getBankList = key => get(['bank'], { key, transformPayload: true })

export const getReasonsToCancel = key => params =>
  get(['reasons-to-cancel'], { key, params: humps.decamelizeKeys(params) })

export const getAllRoles = key => get(['roles'], { key })

export const getAllRoleGroups = key => get(['roles', 'groups'], { key })

export const getReasonsToCancelService = key =>
  get(['reasons-to-reprove'], { key, transformPayload: true })

export const getSectorsOptions = key => get(['sectors'], { key, transformPayload: true })
