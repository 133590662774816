import React, { useEffect } from 'react'

// Icons
import { Danger as DangerIcon, Trash as TrashIcon } from '@refera/ui-icons'

// Components
import { Dialog, useConfirm } from '@refera/ui-web'

// TODO: Remove duplicated component
const ConfirmationDialog = ({ subject, description, type }) => {
  const { isOpen = true, approve, cancel } = useConfirm()

  useEffect(() => {
    const handleKeydown = e => {
      if (approve && isOpen && e.key === 'Enter') {
        approve()
      }
    }
    window.addEventListener('keydown', handleKeydown)
    return () => window.removeEventListener('keydown', handleKeydown)
  }, [approve, isOpen])

  return (
    <Dialog
      open={isOpen}
      icon={type === 'warning' ? DangerIcon : TrashIcon}
      type={type}
      subject={subject}
      description={description}
      labelApprove="Sim"
      labelCancel="Não"
      onApprove={approve}
      onCancel={cancel}
    />
  )
}

export default ConfirmationDialog
