import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridTemplateAreas: `
      "first second"
      "third second"
      `,
    padding: spacing(0, 24),
    maxWidth: spacing(1136),
    marginTop: spacing(68),
    marginBottom: spacing(32),

    [breakpoints.down('md')]: {
      marginTop: spacing(48),
      marginBottom: spacing(24),
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: `
        "first"
        "second"
        "third"
        `,
    },
  },
  gridFirst: {
    gridArea: 'first',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  gridSecond: {
    gridArea: 'second',
    display: 'flex',
    [breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  gridThird: {
    gridArea: 'third',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: spacing(16),
    gap: spacing(40),
    maxWidth: spacing(560),

    [breakpoints.down('600')]: {
      gap: spacing(16),
    },
  },
  section: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(24),
  },
  sectionContent: {
    display: 'flex',
    width: '100%',
    maxWidth: spacing(1366),
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  sectionBlueBackground: {
    backgroundColor: Theme.Colors.Primary.Base,
  },
  mdColumnReverse: {
    [breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  yellowDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Theme.Colors.Yellow.Base,
    padding: spacing(12, 0),
    gap: spacing(8),
    overflow: 'hidden',
  },
  yellowDivText: {
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: spacing(12),
    color: '#000',
    lineHeight: spacing(16),
    whiteSpace: 'nowrap',
  },
  buttonContainer: {
    display: 'flex',
    gap: spacing(16),

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  button: {
    [breakpoints.down('md')]: {
      flex: 1,
    },
  },
  svg: {
    width: spacing(540),
    height: spacing(400),

    [breakpoints.down('md')]: {
      marginBottom: spacing(24),
    },

    [breakpoints.down('600')]: {
      width: spacing(270),
      height: spacing(200),
    },
  },
  sectionTitle: {
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: spacing(32),
    color: Theme.Colors.Primary.Base,
    lineHeight: spacing(42),
    width: spacing(560),

    [breakpoints.down('600')]: {
      width: '100%',
      minWidth: spacing(312),
      marginBottom: spacing(24),
    },
  },
  sectionParagraph: {
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: spacing(16),
    color: Theme.Colors.Grayscale.SixtyFour,
    lineHeight: spacing(24),
  },
  secondSectionParagraph: {
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: spacing(16),
    marginTop: spacing(16),
    lineHeight: spacing(32),
  },
  thirdSectionContentContainer: {
    maxWidth: spacing(560),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(20),
  },
  whiteText: {
    color: '#fff',
  },
  sectionParagraphSmaller: {
    fontSize: spacing(12),
    marginBottom: spacing(8),

    [breakpoints.down('xs')]: {
      lineHeight: spacing(16),
    },

    [breakpoints.down('400')]: {
      maxWidth: spacing(275),
    },
  },
  sixHundredMarginZero: {
    [breakpoints.down('md')]: {
      margin: 0,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(12),
  },
  footerText: {
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: spacing(12),
    color: Theme.Colors.Grayscale.SixtyFour,
    lineHeight: spacing(16),
  },
}))
