import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

export const TotalAnticipationValues = ({ totalMenuValues }) => {
  const styles = useStyles()

  return (
    <Grid className={styles.totalValueContainer}>
      <Typography>
        <b>Total dos itens selecionados: </b>
      </Typography>
      <Typography>
        <b>Valor total </b>
        {totalMenuValues?.totalPriceServiceProvider}
      </Typography>
      <Typography>
        <b>Taxa de antecipação </b>
        {totalMenuValues?.interestValue}
      </Typography>
      <Typography>
        <b>Valor líquido a receber </b>
        {totalMenuValues?.totalValue}
      </Typography>
    </Grid>
  )
}
