import { Map, List } from 'immutable'
import { createReducer } from '_utils/redux'
import {
  GET_COMPANIES,
  GET_COMPANY,
  GET_COMPANY_BY_ID,
  GET_COMPANIES_SIMPLE,
  GET_CONTRACT_OPTIONS,
  POST_GENERATE_CONTRACT,
} from './actions'

const INITIAL_STATE = Map({
  results: Map(),
  company: Map(),
  contractOptions: List(),
  companiesList: Map(),
  companiesSimpleList: List(),
})

export default createReducer(INITIAL_STATE, {
  [GET_COMPANY_BY_ID.FULFILLED]: (state, { payload }) => {
    return state.setIn(['results', `${payload.id}`], Map(payload))
  },
  [GET_COMPANY.FULFILLED]: (state, { payload }) => {
    return state.setIn(['company'], Map(payload.results[0]))
  },
  [GET_CONTRACT_OPTIONS.FULFILLED]: (state, { payload }) => {
    return state.setIn(['contractOptions'], List(payload))
  },
  [GET_COMPANIES.FULFILLED]: (state, { payload }) => {
    return state.update('companiesList', () => Map(payload))
  },
  [GET_COMPANIES_SIMPLE.FULFILLED]: (state, { payload }) => {
    return state.set('companiesSimpleList', List(payload))
  },
  [POST_GENERATE_CONTRACT.FULFILLED]: (state, { payload }) => {
    return state.setIn(['company', 'contractStatus'], payload.status)
  },
})
