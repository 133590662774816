import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Button } from '@refera/ui-web'
import { DateTimePicker } from '_components/inputs'

import useStyles from '../styles'
import { FormProvider, useForm } from 'react-hook-form'

export function CancelServiceFinish({ open, onConfirm, onCancel }) {
  const styles = useStyles()

  const defaultValues = {
    dateAndTimeFinish: null,
  }

  const methods = useForm({
    defaultValues,
    mode: 'all',
  })
  const {
    formState: { isValid },
  } = methods

  return (
    <>
      <Dialog open={open} className={styles.dialog} keepMounted={false}>
        <div key="cancelServiceFinishModalHeader" className={styles.header}>
          <DialogTitle className={styles.title}>Cancelar finalização do serviço</DialogTitle>
        </div>
        <DialogContent className={styles.content}>
          <FormProvider {...methods}>
            <form
              key="cancelServiceFinishModalForm"
              id="cancelServiceFinishModalForm"
              className={styles.form}
              onSubmit={e => e.preventDefault()}
              noValidate
            >
              <DateTimePicker
                name="dateAndTimeFinish"
                label="Expectativa de finalização do serviço"
                referaDatePickerProps={{ disablePast: true }}
                required
              />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={onCancel} variant="secondary" color="red" className={styles.button}>
            Cancelar
          </Button>
          <Button
            onClick={() => onConfirm(methods.getValues())}
            className={styles.button}
            type="button"
            disabled={!isValid}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
