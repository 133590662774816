import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { isAfter } from 'date-fns'
import Theme from '@refera/ui-core'
import { Button } from '@refera/ui-web'
import { ArrowRight3 as ArrowRightIcon } from '@refera/ui-icons'

import useRolePermission from '_hooks/use-role-permission'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'
import { USER_ROLES_LABEL, USER_ROLES } from '_utils/user'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { formatDate } from '_/utils/helpers'

import useStyles from './styles'

const TODAY = new Date()

const ActionDeadline = ({ handleShowHelpRequest }) => {
  const styles = useStyles()
  const { isIntermediary } = useRolePermission()
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const isPauseServiceOrderActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.PAUSE_SERVICE_ORDER,
  })

  const isDelayed = useMemo(
    () => (serviceOrder?.deadline ? isAfter(TODAY, new Date(serviceOrder.deadline)) : false),
    [serviceOrder?.deadline]
  )

  const status = useMemo(() => (isDelayed ? 'Atrasado' : 'Dentro do prazo'), [isDelayed])

  const deadline = useMemo(() => {
    if (!serviceOrder?.deadline) {
      return null
    }

    return formatDate(serviceOrder.deadline)
  }, [serviceOrder?.deadline])

  const isIntermediaryResponsible = useMemo(
    () =>
      serviceOrder?.actionResponsible
        ? [USER_ROLES.CS_APPROVER, USER_ROLES.CS_MANAGER].includes(serviceOrder.actionResponsible)
        : false,
    [serviceOrder?.actionResponsible]
  )

  const showInfo = useMemo(() => {
    const defaultCondition = !isPauseServiceOrderActive || !serviceOrder.isPaused

    return isIntermediary ? defaultCondition && isIntermediaryResponsible : defaultCondition
  }, [isIntermediary, isIntermediaryResponsible, isPauseServiceOrderActive, serviceOrder.isPaused])

  const showPausedServiceOrderInfo = useMemo(() => {
    return isPauseServiceOrderActive && serviceOrder.isPaused
  }, [isPauseServiceOrderActive, serviceOrder.isPaused])

  const renderContentStepStatus = useMemo(() => {
    if (serviceOrder.stepDesc) {
      return (
        <Grid item xs={12}>
          <Typography className={styles.stepStatus}>{serviceOrder.stepDesc}</Typography>
        </Grid>
      )
    }
    return null
  }, [serviceOrder])

  return (
    <Grid container spacing={8} wrap="nowrap">
      <Grid item>
        <span
          className={classnames(styles.dot, {
            [styles.greenDot]: !isDelayed || isIntermediary || !deadline,
            [styles.redDot]: isIntermediary ? isDelayed && isIntermediaryResponsible : isDelayed,
            [styles.grayDot]: showPausedServiceOrderInfo,
          })}
        />
      </Grid>

      <Grid item container spacing={2}>
        {renderContentStepStatus}
        {/* <Grid item xs={12}>
          <Typography className={styles.stepStatus}>{serviceOrder.stepDesc}</Typography>
        </Grid> */}
        {showInfo && (
          <Grid item xs={12}>
            <Typography className={styles.info}>
              {deadline
                ? `${
                    USER_ROLES_LABEL?.[serviceOrder.actionResponsible] ?? '-'
                  } • ${status} • Prazo: ${deadline}`
                : `${
                    USER_ROLES_LABEL?.[serviceOrder.actionResponsible] ?? '-'
                  } • Não há prazo cadastrado`}
            </Typography>
          </Grid>
        )}
        {showPausedServiceOrderInfo && (
          <>
            <Grid item xs={12}>
              <Typography className={styles.info}>Pausado com item de ajuda</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="ghost"
                onClick={handleShowHelpRequest}
                className={classnames(styles.link)}
                endIcon={<ArrowRightIcon color={Theme.Colors.Primary.Base} />}
              >
                Ver itens de ajuda
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default ActionDeadline
