import { GET_BATCHES, GET_BUDGET_INSTALLMENTS } from './actions'

export const budgetInstallmentsSelector = state => state?.finance?.get('results')?.toJS()
export const tradesmanInstallmentsSelector = state => state?.finance?.get('results')?.toJS()

export const batchesSelector = state => state?.finance?.get('batches')?.toJS()
export const batchesLoadingSelector = state => state?.loading?.get(GET_BATCHES.ACTION)

export const financeOptionsSelector = state => state?.finance?.get('options')?.toJS()

export const budgetInstallmentsLoadingSelector = state =>
  state?.loading?.get(GET_BUDGET_INSTALLMENTS.ACTION)

export const getBudgetInstallmentsIsFilterDirtySelector = state =>
  state?.finance?.get('isFilterDirty')

export const getBudgetInstallmentsFilterSelector = state => state?.finance?.get('filter')?.toJS()
export const getBudgetReceivablesFilterSelector = state =>
  state?.finance?.get('filterReceivables')?.toJS()

export const financeGenericParametersSelector = state =>
  state?.finance?.get('financeGenericParameters')?.toJS()

export const anticipationsBannerInfoSelector = state =>
  state?.finance?.get('anticipationsBannerInfo')?.toJS()

export const getListBudgetsReceivablesSelector = state => state?.finance?.get('receivables')?.toJS()

export const getInstallmentRulesSelector = state =>
  state?.finance?.get('installmentRules')?.toJS().results

export const isXmlRequiredToFinishServiceOrderSelector = state =>
  state?.finance?.isXmlRequiredToFinishServiceOrder || null
