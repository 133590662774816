import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  main: {
    padding: '162px 50px 10px 50px',
  },
  required: {
    marginLeft: '5px',
    color: 'red',
    fontSize: '16px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '40px',
  },
  textTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    fontWeight: '500',
    fontSize: '16px',
    color: 'black',
  },
  textInput: {
    display: 'block',
    width: '100%',
    height: '132px',
  },
  textInputSmall: {
    display: 'block',
    height: '102px',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '9%',
    marginBottom: '40px',
  },
  selectInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '66%',
    gap: '10%',
    marginBottom: '40px',
  },
  radioInput: {
    width: '100%',
  },
  numberInput: {
    width: '98%',
    height: '38px',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '40px',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  disableClick: {
    pointerEvents: 'none',
  },
  footer: {
    left: '0',
    bottom: '0',
    width: '100%',
    marginTop: '50px',
  },
}))
