import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  loader: {
    width: 'auto',
  },
  loaderOverlay: {
    top: 0,
    left: 0,
    backgroundColor: `rgba(0, 0, 0, 0.1)`,
    width: '100%',
    position: 'fixed',
    zIndex: 99,
  },
  container: {
    gridColumn: '1',
    position: 'relative',
    width: '100%',
    marginBottom: spacing(32),
  },
  paperWrapper: {
    display: 'grid',
    gridTemplateAreas: "'first button' 'second third'",
    gridTemplate: 'repeat(2, auto) / 1fr 1fr',
    gap: spacing(24),
  },
  descriptionWrapper: {
    marginTop: spacing(24),
  },
  descriptionTitle: {
    color: palette.gray[80],
    marginBottom: spacing(12),
  },
  description: {
    color: palette.gray[64],
    lineHeight: spacing(20),
  },
  divider: {
    color: palette.gray[12],
    margin: spacing(24, 0),
  },
  view: {
    display: 'grid',
    gridTemplateColumns: `minmax(${spacing(955)}, auto) ${spacing(328)}`,
    height: `calc(100vh - ${spacing(72)})`,
  },
  history: {
    backgroundColor: palette.custom.rhino,
  },
  historyHeader: {
    boxShadow: `${spacing(0, 4, 4)} rgba(0, 0, 0, 0.25)`,
    padding: spacing(16, 24),
  },
  paper1: {
    gridArea: 'first',
  },
  paper3: {
    gridArea: 'second',
  },
  paper4: {
    gridArea: 'third',
  },
  rejectButton: {
    backgroundColor: palette.error.main,
    color: 'white',
  },
  cancelRejectButton: {
    borderColor: palette.error.main,
    color: palette.error.main,
  },
  confirmButton: {
    backgroundColor: palette.primary.main,
    color: 'white',
  },
  cancelApproveButton: {
    border: `1px solid ${palette.error.main}`,
    backgroundColor: 'white',
    color: palette.error.main,
  },
  proposeOpened: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'red',
  },
  // Orçamento recusado
  alertTitle: {
    fontFamily: 'DM sans !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    fontSize: '16px !important',
    color: '#141414',
    lineHeight: '112.5%',
  },
  actionDeadlineWrapper: {
    padding: spacing(0, 0, 24),
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(8),
    marginBottom: spacing(16),
    marginLeft: spacing(24),
  },
  card: {
    padding: spacing(16),
    border: `1px solid ${palette.gray[12]}`,
    borderRadius: '10px',
    minHeight: spacing(176),
    width: '100%',
    display: 'flex',
    gap: spacing(16),

    [breakpoints.down('md')]: {
      minHeight: spacing(255),
    },

    [breakpoints.down('xs')]: {
      gap: spacing(8),
      padding: spacing(16, 12),
      height: spacing(250),
      minWidth: '100%',
    },
    cardTitle: {
      fontSize: spacing(16),
    },
    content: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: spacing(16),
      width: '100%',
      flexDirection: 'column',
    },
  },
}))
