import moment from 'moment'

export const FORM_FIELD_NAME = {
  PAYMENT_DATE_START: 'paymentDateStart',
  PAYMENT_DATE_END: 'paymentDateEnd',
  SERVICE_ORDER_ID: 'serviceOrderId',
  AGENCY: 'agency',
  STATUS: 'status',
  PAYMENT_TYPE: 'formOfPayment',
  VOUCHER_END: 'voucherEnd',
  VOUCHER_START: 'voucherStart',
  DUE_DATE_END: 'dueDateEnd',
  DUE_DATE_START: 'dueDateStart',
  INVOICE_START: 'invoiceStart',
  INVOICE_END: 'invoiceEnd',
  SERVICE_ORDER_END_DATE_START: 'serviceOrderEndDateStart',
  SERVICE_ORDER_END_DATE_END: 'serviceOrderEndDateEnd',
  CLIENT_NAME: 'clientName',
  CLIENT_CPF_CNPJ: 'clientCpfCnpj',
  COMPANY: 'company',
  CLASSIFICATION: 'classification',
  COMMENT: 'comment',
  INSTALLMENT_VALUE_START: 'installmentValueStart',
  INSTALLMENT_VALUE_END: 'installmentValueEnd',
  INSTALLMENT_NUMBER_START: 'installmentNumberStart',
  INSTALLMENT_NUMBER_END: 'installmentNumberEnd',
  STEP_STATUS: 'stepStatus',
}

const INITIAL_STATE = {
  [FORM_FIELD_NAME.PAYMENT_DATE_START]: null,
  [FORM_FIELD_NAME.PAYMENT_DATE_END]: null,
  [FORM_FIELD_NAME.SERVICE_ORDER_ID]: '',
  [FORM_FIELD_NAME.AGENCY]: '',
  [FORM_FIELD_NAME.PAYMENT_TYPE]: '',
  [FORM_FIELD_NAME.VOUCHER_START]: '',
  [FORM_FIELD_NAME.VOUCHER_END]: '',
  [FORM_FIELD_NAME.STATUS]: '',
  [FORM_FIELD_NAME.DUE_DATE_END]: null,
  [FORM_FIELD_NAME.DUE_DATE_START]: null,
  [FORM_FIELD_NAME.INVOICE_START]: '',
  [FORM_FIELD_NAME.INVOICE_END]: '',
  [FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_START]: null,
  [FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_END]: null,
  [FORM_FIELD_NAME.INSTALLMENT_VALUE_START]: '',
  [FORM_FIELD_NAME.INSTALLMENT_VALUE_END]: '',
  [FORM_FIELD_NAME.INSTALLMENT_NUMBER_START]: '',
  [FORM_FIELD_NAME.INSTALLMENT_NUMBER_END]: '',
  paymentDate: '',
  dueDate: '',
  voucher: '',
  invoice: '',
  serviceOrderEndDate: '',
  clientName: '',
  clientCpfCnpj: '',
  company: '',
  classification: '',
  comment: '',
  installmentValue: '',
  installmentNumber: '',
  invalidFields: {},
}

const INTERVAL_FIELDS = [
  'dueDate',
  'voucher',
  'invoice',
  'serviceOrderEndDate',
  'paymentDate',
  'installmentValue',
  'installmentNumber',
]

const DATE_INTERVAL_FIELDS = [
  [FORM_FIELD_NAME.PAYMENT_DATE_START, FORM_FIELD_NAME.PAYMENT_DATE_END],
  [FORM_FIELD_NAME.DUE_DATE_START, FORM_FIELD_NAME.DUE_DATE_END],
  [FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_START, FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_END],
]

const NUMERIC_INTERVAL_FIELDS = [
  [FORM_FIELD_NAME.INVOICE_START, FORM_FIELD_NAME.INVOICE_END],
  [FORM_FIELD_NAME.VOUCHER_START, FORM_FIELD_NAME.VOUCHER_END],
  [FORM_FIELD_NAME.INSTALLMENT_VALUE_START, FORM_FIELD_NAME.INSTALLMENT_VALUE_END],
  [FORM_FIELD_NAME.INSTALLMENT_NUMBER_START, FORM_FIELD_NAME.INSTALLMENT_NUMBER_END],
]

const DISABLE_FUTURE_DATES = [
  FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_START,
  FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_END,
]

export const UPDATE_STATE = 'updateState'
export const RESET_STATE = 'resetState'

export const reducer = (state, { type, payload }) => {
  const fieldsValidation = () => {
    const newState = {
      ...state,
      ...payload,
    }

    const handleValidDates = fields => {
      const startDate = newState[fields[0]]
      const endDate = newState[fields[1]]
      const fieldName = INTERVAL_FIELDS.find(field => fields[0].includes(field))

      if ((startDate && !startDate.isValid()) || (endDate && !endDate.isValid())) {
        return { [fieldName]: 'Insira uma data válida' }
      }
      if (startDate && endDate && startDate?.isAfter(endDate))
        return { [fieldName]: 'A data inicial não pode ser maior que a data final' }

      if (
        (DISABLE_FUTURE_DATES.includes(fields[0]) && startDate?.isAfter(moment())) ||
        (DISABLE_FUTURE_DATES.includes(fields[1]) && endDate?.isAfter(moment()))
      )
        return { [fieldName]: 'A data não pode ser futura' }
      return ''
    }

    const handleNumericInterval = fields => {
      const startValue = newState[fields[0]]
      const endValue = newState[fields[1]]
      const fieldName = INTERVAL_FIELDS.find(field => fields[0].includes(field))

      if (startValue && endValue && Number(startValue) > Number(endValue)) {
        return { [fieldName]: 'O valor inicial não pode ser maior que o valor final' }
      }

      return ''
    }

    return Object.keys(payload).reduce((acc, payloadKey) => {
      let invalidField = ''

      const datePair = DATE_INTERVAL_FIELDS.find(pair => pair.includes(payloadKey))
      if (datePair) {
        invalidField = handleValidDates(datePair)
      }
      const numericPair = NUMERIC_INTERVAL_FIELDS.find(pair => pair.includes(payloadKey))
      if (numericPair) {
        invalidField = handleNumericInterval(numericPair)
      }

      if (invalidField) return { ...acc, ...invalidField }

      const keyToRemove = Object.keys(acc).find(field => payloadKey.includes(field))
      if (keyToRemove) {
        const newAcc = { ...acc }
        delete newAcc[keyToRemove]
        return newAcc
      }
      return { ...acc }
    }, newState.invalidFields)
  }

  switch (type) {
    case UPDATE_STATE:
      return {
        ...state,
        ...payload,
        invalidFields: fieldsValidation(),
      }
    case RESET_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}

const checkValidInitialDate = date => {
  return moment(date).isValid() ? moment(date) : null
}

export const initialState = filters => {
  const state = Object.values(FORM_FIELD_NAME).reduce((acc, currentFieldKey) => {
    const intervalField = INTERVAL_FIELDS.find(field => currentFieldKey.includes(field))
    const filterKey = filters?.[intervalField || currentFieldKey]
    const hasValue = filterKey?.length && typeof filterKey === 'string'
    const [start, end] = hasValue ? filterKey.split(',') : []

    switch (currentFieldKey) {
      // date fields start intervals
      case FORM_FIELD_NAME.DUE_DATE_START:
      case FORM_FIELD_NAME.PAYMENT_DATE_START:
      case FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_START:
        return {
          ...acc,
          [currentFieldKey]: hasValue
            ? checkValidInitialDate(start)
            : INITIAL_STATE[currentFieldKey],
        }
      // date fields end intervals
      case FORM_FIELD_NAME.DUE_DATE_END:
      case FORM_FIELD_NAME.PAYMENT_DATE_END:
      case FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_END:
        return {
          ...acc,
          [currentFieldKey]: hasValue ? checkValidInitialDate(end) : INITIAL_STATE[currentFieldKey],
        }

      // numeric fields start intervals
      case FORM_FIELD_NAME.INVOICE_START:
      case FORM_FIELD_NAME.VOUCHER_START:
      case FORM_FIELD_NAME.INSTALLMENT_VALUE_START:
      case FORM_FIELD_NAME.INSTALLMENT_NUMBER_START:
        return {
          ...acc,
          [currentFieldKey]: hasValue ? start : INITIAL_STATE[currentFieldKey],
        }
      // numeric fields END intervals
      case FORM_FIELD_NAME.INVOICE_END:
      case FORM_FIELD_NAME.VOUCHER_END:
      case FORM_FIELD_NAME.INSTALLMENT_VALUE_END:
      case FORM_FIELD_NAME.INSTALLMENT_NUMBER_END:
        return {
          ...acc,
          [currentFieldKey]: hasValue ? end : INITIAL_STATE[currentFieldKey],
        }
      default:
        return {
          ...acc,
          [currentFieldKey]: filterKey || INITIAL_STATE[currentFieldKey],
        }
    }
  }, {})
  return {
    ...INITIAL_STATE,
    ...state,
  }
}
