import React, { useCallback, useMemo, useState, useContext } from 'react'
import { Button } from '@refera/ui-web'
import { useLocation, useParams, navigate } from '@reach/router'
import { useSelector } from 'react-redux'
import { parse } from 'query-string'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { BUTTONS_ACTION } from '_/utils/constants/service-order'
import useCanDoButtonAction from '_/hooks/use-can-do-button-action'
import { BudgetContext } from '_/utils/budget'
import useRolePermission from '_/hooks/use-role-permission'
import ModalDialog, { WARNING_MODAL } from '_/components/modal/modal-dialog'
import { ROUTES } from '_/utils/constants'

const DataNFButton = () => {
  const { budgetId } = useParams()
  const { search } = useLocation()
  const { verification_token: token } = parse(search)
  const { isTradesman, isSAAS } = useRolePermission()

  const [modalDialog, setModalDialog] = useState({
    isOpen: false,
    textOkButton: 'Ok, entendi',
    subTitle: `Salve antes as edições deste registro.`,
    type: WARNING_MODAL,
  })

  const serviceOrder = useSelector(currentServiceOrderSelector)

  const serviceOrderId = useMemo(() => serviceOrder?.get('id'), [serviceOrder?.get('id')])

  const { isEditing } = useContext(BudgetContext)

  const ableToSendNF = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.EDIT_BUDGET_BY_PROVIDER,
    isBudgetRelated: true,
  })

  const dataNFURL = useMemo(
    () => `${ROUTES.NF_FORM}/${serviceOrderId}/orcamento/${budgetId}/?verification_token=${token}`,
    [budgetId, serviceOrderId, token]
  )

  const handleButtonClick = useCallback(() => {
    if ((isTradesman || isSAAS) && isEditing && ableToSendNF) {
      return setModalDialog({
        isOpen: true,
        textOkButton: 'Ok, entendi',
        subTitle: `Salve antes as edições deste registro.`,
        type: WARNING_MODAL,
      })
    }

    return navigate(dataNFURL)
  }, [modalDialog, navigate, dataNFURL, isEditing, ableToSendNF, isTradesman, isSAAS])

  return (
    <>
      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
      <Button onClick={handleButtonClick} color="primary" variant="secondary">
        NFS-e
      </Button>
    </>
  )
}

export default DataNFButton
