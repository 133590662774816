import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  searchInput: {
    marginBottom: '2rem',
  },
  inputStyles: {
    fontSize: '1.5rem',
  },
}))
