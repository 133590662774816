/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import { Collapse, Grid, Typography, Divider } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from '@reach/router'
import { parse } from 'query-string'

import Svg from '_components/svg'
import Snackbar from '_components/snackbar'
import Header, { HEADER_THEME } from '_components/header'
import Footer from '_/components/footer'
import { getBudgetByIdSelector } from '_modules/budget/selectors'

import { getServiceOrder } from '_modules/service-orders/actions'

import { getHistoryLogs } from '_modules/history-logs/actions'
import {
  getBudget,
  CLIENT_APPROVE_BUDGET,
  CLIENT_REFUSE_BUDGET,
  getPayerOptions,
} from '_modules/budget/actions'
import { authenticateToken } from '_modules/authentication/actions'
import { authenticateTokenLoadingSelector } from '_modules/authentication/selectors'
import { usePrevious } from '_hooks/use-previous'

import { Alert, Preview } from '@refera/ui-web'
import Union from 'src/assets/icons/ic-union.svg'
import filePage from 'src/assets/images/filePage.png'

import useStyles from '../styles'

const BudgetVisualization = () => {
  const dispatch = useDispatch()
  const { budgetId, serviceOrderId } = useParams()
  const { search } = useLocation()
  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const { verification_token } = parse(search)
  const token = search.split('verification_token=')[1]

  const isAuthenticatingToken = useSelector(authenticateTokenLoadingSelector)
  const wasAuthenticatingToken = usePrevious(isAuthenticatingToken)

  const styles = useStyles()

  useEffect(() => {
    dispatch(
      authenticateToken({
        verification_token,
      })
    )
  }, [dispatch, search, verification_token])

  useEffect(() => {
    dispatch(authenticateToken({ verificationToken: token }))
  }, [dispatch, token])

  useEffect(() => {
    if (wasAuthenticatingToken) {
      dispatch(getBudget(serviceOrderId, budgetId))
      dispatch(getServiceOrder(serviceOrderId))
      dispatch(getHistoryLogs(serviceOrderId))
      dispatch(getPayerOptions(serviceOrderId, budgetId))
    }
  }, [budgetId, dispatch, serviceOrderId, wasAuthenticatingToken])

  const defaultPreviewState = {
    visible: false,
    selectedIndex: undefined,
  }
  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(() => defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={
          budget?.toJS()?.budgetPictures &&
          budget?.toJS()?.budgetPictures?.map((item, index) =>
            item.id
              ? {
                  id: item.id,
                  url: item.picture,
                }
              : {
                  id: index,
                  url: URL.createObjectURL(item),
                }
          )
        }
      />
    ),
    [previewState, budget?.toJS()?.budgetPictures]
  )
  const formatterNumberOnly = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return (
    <>
      <Header theme={HEADER_THEME.NO_BUTTONS} className={styles.header} />
      <Grid className={styles.container}>
        {PreviewComponent}
        <Grid container className={styles.content}>
          <Grid className={styles.pic}>
            <img src={filePage} alt="file icon" className={styles.fileIcon} />
          </Grid>
          <Typography component="h1" variant="h1" className={styles.title}>
            {`Orçamento Refera - ${budgetId}`}
          </Typography>
          <Collapse in={alert?.isOpen} className={styles.alert}>
            <Alert severity="info" title={alert.title} />
          </Collapse>
          <Grid>
            <Typography component="h4" variant="h4" className={styles.diagnosis}>
              Diagnóstico do problema
            </Typography>
            <Typography className={styles.diagnosisText}>{budget?.diagnosis}</Typography>
            <Typography component="h4" variant="h4" className={styles.solution}>
              Solução para o problema
            </Typography>
            <Typography className={styles.diagnosisText}>{budget?.proposedSolution}</Typography>
          </Grid>
          <Grid>
            <Typography component="h4" variant="h4" className={styles.tableTitle}>
              Orçamento detalhado
            </Typography>
            <Grid className={styles.tableList}>
              <Grid className={styles.leftList}>
                <Typography className={styles.listHeaderDescription}>Descrição</Typography>
                <ul>
                  {budget?.toJS()?.budgetItems?.map(item => {
                    return (
                      <li className={styles.leftListItems} key={item?.id}>
                        {item?.description}
                      </li>
                    )
                  })}
                </ul>
              </Grid>
              <Grid className={styles.rightList}>
                <Typography className={styles.listHeaderPrice}>R$</Typography>
                <ul>
                  {budget?.toJS()?.budgetItems?.map(item => {
                    return (
                      <li className={styles.rightListItems} key={item?.id}>
                        {formatterNumberOnly.format(
                          item?.price *
                            ((parseFloat(budget?.referaCompletionPercentage) +
                              parseFloat(budget?.agencyCompletionPercentage)) /
                              100 +
                              1)
                        )}
                      </li>
                    )
                  })}
                </ul>
              </Grid>
            </Grid>
            <Grid className={styles.totalPrice}>
              <Typography className={styles.totalPriceText}>Total</Typography>
              <Typography className={styles.totalPriceValue}>
                {formatterNumberOnly.format(budget?.totalPrice)}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid className={styles.guarantee}>
            <Svg className={styles.guaranteeText} icon={Union} />
            <Typography className={styles.guaranteeText}>
              {`O serviço tem garantia de ${budget?.warrantyMonths} meses`}
            </Typography>
          </Grid>
          <Divider />
          <Typography component="h4" variant="h4" className={styles.paymentTitle}>
            Pagamento
          </Typography>
          <Grid className={styles.paymentContainer}>
            <Grid className={styles.selectContainer}>
              <Grid className={styles.selectInput}>
                <Typography className={styles.selectLabel}> Tem garantia?</Typography>
              </Grid>

              <Typography className={styles.textInput}>
                {budget?.acceptsWarranty ? 'Sim' : 'Não'}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={styles.paymentContainer}>
            <Grid className={styles.selectContainer}>
              <Grid className={styles.selectInput}>
                <Typography className={styles.selectLabel}> Aceita parcelamento?</Typography>
              </Grid>

              <Typography className={styles.textInput}>
                {budget?.installments ? 'Sim' : 'Não'}
              </Typography>
            </Grid>
            <Grid className={styles.selectContainer}>
              <Grid className={styles.selectInput}>
                <Typography className={styles.selectLabel}>Houve visita presencial?</Typography>
              </Grid>
              <Typography className={styles.textInput}>
                {budget?.visitHappened ? 'Sim' : 'Não'}
              </Typography>
              <Divider />

              <Typography>Serão cobrados juros dependendo do parcelamento escolhido</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar action={[CLIENT_APPROVE_BUDGET.ACTION, CLIENT_REFUSE_BUDGET.ACTION]} />
        <Footer className={styles.footer} />
      </Grid>
    </>
  )
}

export default BudgetVisualization
