import { schema } from 'normalizr'

import Model from './model'

class Messages extends Model({
  id: undefined,
  origin: undefined,
  type: undefined,
  service_order: undefined,
  parent_message: undefined,
  subject: undefined,
  message: undefined,
  answer: undefined,
  sender: undefined,
  sender_name: undefined,
  recipient: undefined,
  recipient_name: undefined,
  resolved: undefined,
  expected_date: undefined,
  justified_delay_approved: undefined,
  intermediary_available: undefined,
  created_at: undefined,
  updated_at: undefined,
  resolvedDate: undefined,
  reason: undefined,
  visibleTo: undefined,
  isImportant: undefined,
  pendingAttachment: undefined,
  messageAttachment: undefined,
  requester: undefined,
  step: undefined,
  taskExpectedSomeone: undefined,
  taskDue: undefined,
  completedIn: undefined,
  workingDays: undefined,
  startedDate: undefined,
  reminder: undefined,
}) {}

const messagesNewSchema = new schema.Entity(
  'messages',
  {},
  {
    processStrategy: entity => new Messages(entity),
  }
)

const messagesSchema = [messagesNewSchema]

export { messagesSchema, Messages as default }
