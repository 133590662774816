import React from 'react'
import { Grid } from '@material-ui/core'
import { Button, Typography } from '@refera/ui-web'
import Draggable from 'react-draggable'

import Theme from '@refera/ui-core'

import DotsGroup from '_assets/svgs/dots-group.svg'
import { CloseCircle } from '@refera/ui-icons'
import Svg from '_/components/svg'

import useStyles from './styles'

export const FloatingMenu = ({ selected, handleAction, isLoading }) => {
  const styles = useStyles()

  return (
    <Draggable handle="#draggable-floating-menu" cancel={'[class*="MuiDialogContent-root"]'}>
      <Grid className={styles.floatingContainer}>
        <Grid className={styles.floatingWrapper}>
          <Grid className={styles.draggable} id="draggable-floating-menu">
            <Svg className={styles.dotsIcon} icon={DotsGroup} />
          </Grid>
          <Grid className={styles.floatingContent}>
            <Grid className={styles.floatingLabels}>
              <Typography fontSize={14}>
                Selecionado:{' '}
                <b>
                  {selected}
                  {selected > 1 ? ' itens' : ' item'}
                </b>
              </Typography>
            </Grid>
            <Grid className={styles.buttonsContainer}>
              <Button
                className={styles.redButton}
                variant="ghost"
                startIcon={
                  <CloseCircle color={Theme.Colors.Red.Base} style={{ fontSize: '24px' }} />
                }
                onClick={() => handleAction('remove')}
                disabled={isLoading}
              >
                Remover do lote
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Draggable>
  )
}
