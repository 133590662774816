import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  modal: {
    position: 'absolute',
    top: '35%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    padding: '14px',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing(16),
  },
  modalContent: {
    width: 600,
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    color: Theme.Colors.Grayscale.SixtyFour,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Small,
    lineHeight: '20px',
  },
  textArea: {
    marginTop: spacing(8),
    fontSize: '14px',
  },
  label: {
    marginTop: spacing(16),
    fontSize: '12px',
    color: Theme.Colors.Grayscale.OneThousand,
    fontWeight: 500,
  },
}))
