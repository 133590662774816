/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { AlertTitle } from '@material-ui/lab'
import { Alert, Button, Datagrid, useConfirm } from '@refera/ui-web'
import { Trash as TrashIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { saveAs } from 'file-saver'

import Svg, { ICON } from '_components/svg'
import HeaderTitle from '_/components/header-title'
import { addLeadingZeros, Columns } from '../../utils/constants'

import useStyles from '../../styles'
import { navigate, useParams } from '@reach/router'

import { FINANCE_ROUTES, ROUTES } from '_/utils/constants'
import { useDispatch } from 'react-redux'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'
import {
  cancelBatchInstallments,
  getInstallmentsByBatchId,
  processCondolivrePayments,
  releaseInstallmentsByBatchId,
  updateBatch,
} from '_/modules/finance/actions'
import moment from 'moment'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { TABLE_SX } from '_/views/finance/utils/constants'

import { FloatingMenu } from '../components/floating-menu'

const COLUMNS_TO_REMOVE = ['lastBatchId']
const INSTALLMENTS_STATUS_ABLE_TO_REMOVE = ['error', 'paying']
const CONDOLIVRE_STATUS_ABLE_TO_REMOVE = ['error', 'canceled']
const CANNOT_REMOVE_INSTALLMENT_FROM_BATCH_MESSAGE =
  'Você só pode remover parcelas do lote que estão no Status Em antecipação ou Erro/Pagando, desde que com erro ou cancelado na Fidc.'

const BatchScreen = props => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { batchId } = useParams()
  const batchIdWithZeroes = addLeadingZeros(batchId)
  const isBatchCreatedNow = props.location.state?.created || false
  const info = props.location.state?.info || ''

  const batch = useRef({})
  const [isLoading, setIsLoading] = useState(true)
  const [dialogInfo, setDialogInfo] = useState({ message: '', description: '' })
  const [batchInstallments, setBatchInstallments] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [orderBy, setOrderBy] = useState('')

  const [selectedIds, setSelectedIds] = useState([])
  const [floatingMenuOpen, setFloatingMenuOpen] = useState(false)

  const { showToast } = useToast()

  const isCanceled = useMemo(() => batch?.current?.status === 'canceled', [batch?.current])
  const batchType = useMemo(() => batch?.current?.batchType, [batch?.current])
  const handleCanceledBatchMessage = useMemo(
    () => (
      <Alert severity="error">
        <AlertTitle>Lote cancelado</AlertTitle>
        Lote cancelado em{' '}
        {moment(batch?.current.canceledAt)
          ? moment(batch?.current.canceledAt).format('DD/MM/yyyy')
          : '--/--/--'}
        , por {batch?.current.canceledByName || '-'}
      </Alert>
    ),
    [isCanceled, batch?.current]
  )

  const batchColumns = useMemo(() => {
    return Columns({ styles, isBatchScreen: true }).filter(
      column => !COLUMNS_TO_REMOVE.includes(column.field)
    )
  }, [])

  const { isConfirmed } = useConfirm()
  const goBack = () => navigate(FINANCE_ROUTES.BATCHES)

  const handleGetInstallments = () => {
    Promise.resolve(
      dispatch(getInstallmentsByBatchId(batchId, { page: page + 1, pageSize, orderBy }))
    ).then(res => {
      batch.current = res
      setBatchInstallments(res?.installments)
      setIsLoading(false)
    })
  }

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('-id')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  useEffect(() => {
    if (isBatchCreatedNow) {
      const message = (
        <span>
          Lote {batchIdWithZeroes} criado com sucesso!
          <br />
          {info}
        </span>
      )
      showToast({
        type: 'success',
        message,
      })
    }
  }, [batchId, isBatchCreatedNow])

  const handleRemoveModal = async () => {
    setDialogInfo({
      message: 'Você tem certeza que deseja cancelar esse lote?',
      description: 'As parcelas voltarão para o status pendente.',
    })

    const confirmed = await isConfirmed()

    if (confirmed) {
      setIsLoading(true)
      const payload = {
        status: 'canceled',
      }
      Promise.resolve(dispatch(updateBatch(batchId, payload)))
        .then(() => {
          handleGetInstallments()
          setIsLoading(false)
          showToast({ message: 'Lote cancelado com sucesso!', type: 'success' })
        })
        .catch(() => {
          setIsLoading(false)
          showToast({ type: 'error' })
        })
    }
    return undefined
  }

  const handleRemittanceDownload = () => {
    const fileName = `lote-${batchIdWithZeroes}.rem`
    const remittanceData = new Blob([batch.current.remittance], { type: 'text/plain' })
    saveAs(remittanceData, fileName)
  }

  const handleBatchActions = async (condolivreAction = null) => {
    setDialogInfo({
      message: 'Você tem certeza que deseja executar esta ação?',
    })

    const payload = { action: condolivreAction }
    const action =
      batchType === 'condolivre'
        ? processCondolivrePayments(batchId, payload)
        : releaseInstallmentsByBatchId(batchId)

    const confirmed = await isConfirmed()

    if (confirmed) {
      setIsLoading(true)
      await dispatch(action)
        .then(() => {
          handleGetInstallments()
          showToast({ type: 'success' })
          setIsLoading(false)
        })
        .catch(error => {
          showToast({ type: 'error', message: formatErrorMessage(error) })
          setIsLoading(false)
        })
    }
    return undefined
  }

  const handleRowClick = params => {
    window.open(
      `${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}/orcamento/${params?.row?.budget}`,
      '_blank',
      'noopener,noreferrer'
    )
  }

  const handleFloatingMenuActions = async action => {
    const selectedItems = batchInstallments.filter(item => selectedIds.includes(item.id))

    switch (action) {
      case 'remove':
        const hasNonPermittedStatus = selectedItems.some(
          item =>
            !INSTALLMENTS_STATUS_ABLE_TO_REMOVE.includes(item.status) ||
            !CONDOLIVRE_STATUS_ABLE_TO_REMOVE.includes(item.condolivreStatus)
        )
        const hasEmptyCondolivreStatus = selectedItems.some(item => !item.condolivreStatus)
        const isAnticipating = selectedItems.some(item => item.status === 'anticipating')

        if (!isAnticipating && hasNonPermittedStatus && hasEmptyCondolivreStatus) {
          showToast({
            type: 'error',
            message: CANNOT_REMOVE_INSTALLMENT_FROM_BATCH_MESSAGE,
          })
        } else {
          setIsLoading(true)
          await dispatch(cancelBatchInstallments(batchId, { installmentIds: selectedIds }))
            .then(() => {
              setSelectedIds([])
              handleGetInstallments()
              showToast({ type: 'success' })
              setIsLoading(false)
            })
            .catch(errorMessage => {
              showToast({ type: 'error', message: formatErrorMessage(errorMessage) })
              setIsLoading(false)
            })
        }

        break
      default:
        break
    }
  }

  useEffect(() => {
    if (batchId) {
      handleGetInstallments()
    }
  }, [batchId])

  useEffect(() => {
    if (selectedIds.length) setFloatingMenuOpen(true)
    else setFloatingMenuOpen(false)
  }, [selectedIds])

  return (
    <Grid>
      <HeaderTitle title={`Lote ${batchIdWithZeroes}`} backButtonAction={goBack}>
        <>
          <Grid style={{ display: 'flex', gap: '8px' }}>
            {batchType === 'santander' && (
              <>
                <Button variant="ghost" onClick={handleBatchActions} disabled={isLoading}>
                  Liberar pgtos. pendentes
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleRemittanceDownload}
                  startIcon={<Svg type={ICON.DOWNLOAD} color={Theme.Colors.Primary.Base} />}
                >
                  Baixar arquivo de remessa
                </Button>
              </>
            )}
            {batchType === 'condolivre' && (
              <>
                <Button
                  variant="ghost"
                  onClick={() => handleBatchActions('process-payments')}
                  disabled={isLoading}
                >
                  Processar pagamentos
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => handleBatchActions('send-link')}
                  disabled={isLoading}
                >
                  Enviar link de assinatura
                </Button>
              </>
            )}
            <Button
              color="red"
              variant="secondary"
              onClick={handleRemoveModal}
              startIcon={<TrashIcon color={Theme.Colors.Red.Base} />}
              disabled={isLoading}
            >
              Cancelar lote
            </Button>
          </Grid>
        </>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        {isCanceled && handleCanceledBatchMessage}
        <Grid className={styles.mainTable}>
          <Datagrid
            loading={isLoading}
            rows={batchInstallments?.length ? batchInstallments : []}
            columns={batchColumns}
            paginationMode="client"
            onRowClick={handleRowClick}
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            rowCount={batchInstallments?.length}
            onSortModelChange={handleOrderBy}
            checkboxSelection={batchType === 'condolivre'}
            disableSelectionOnClick
            onSelectionModelChange={setSelectedIds}
            keepNonExistentRowsSelected
            selectionModel={selectedIds}
            sx={TABLE_SX}
          />
        </Grid>
        {floatingMenuOpen && (
          <FloatingMenu
            selected={selectedIds.length}
            handleAction={handleFloatingMenuActions}
            isLoading={isLoading}
          />
        )}
      </Grid>
      <ConfirmRemoveDialog message={dialogInfo.message} description={dialogInfo.description} />
    </Grid>
  )
}

export default BatchScreen
