import { useDispatch } from 'react-redux'
import React, { useCallback, useMemo, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Datagrid, Loader } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'
import { TABLE_SX } from '_/views/finance/utils/constants'

import useStyles from './styles'
import { useToast } from '_/hooks/use-toast'
import { confirmationMenuTypes, leadProviderListColumns } from './constants'
import { importLeadProviders } from '_/modules/provider/actions'

const tableInitialState = {
  sorting: {
    sortModel: [{ field: 'name', sort: 'desc' }],
  },
}

const titleMapping = {
  import: 'Confirmação de importação de prestadores',
}

const actionsMapping = {
  import: importLeadProviders,
}

export const ImportProvidersConfirmationScreen = ({ goBack, providers, selectedIds, menuType }) => {
  const styles = useStyles()

  const dispatch = useDispatch()

  // List of selected items from previous screen
  const confirmationData = useMemo(
    () => providers?.filter(provider => selectedIds.includes(provider.id)) || [],
    [providers, selectedIds]
  )

  // Selected items to be confirmed from the list of selected items from previous screen
  const [confirmedItems, setConfirmedItems] = useState(selectedIds)

  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const { showToast } = useToast()

  const title = useMemo(() => {
    return titleMapping[menuType] || ''
  }, [menuType, titleMapping])

  const handleConfirmAction = useCallback(async () => {
    const action = actionsMapping[menuType]

    if (!action) return

    setIsLoading(true)

    const payload = {
      companies: confirmedItems,
    }

    dispatch(action(payload))
      .then(() => {
        showToast({ type: 'success' })
        setIsLoading(false)
        goBack({ update: true })
      })
      .catch(() => {
        showToast({ type: 'error' })
        setIsLoading(false)
      })
  }, [menuType, actionsMapping, showToast, goBack, confirmedItems])

  const disableCheckbox = useMemo(() => {
    return !confirmationMenuTypes.includes(menuType)
  }, [menuType])

  return (
    <>
      <Loader hasBackdrop open={isLoading} label="Aguarde..." />
      <HeaderTitle title={title} backButtonAction={goBack}>
        <div className={styles.actionButtons}>
          <Button color="red" variant="secondary" onClick={goBack}>
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="primary"
            onClick={handleConfirmAction}
            disabled={selectedIds.length === 0}
          >
            Confirmar
          </Button>
        </div>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            initialState={tableInitialState}
            rows={confirmationData.length ? confirmationData : []}
            columns={leadProviderListColumns}
            paginationMode="client"
            autoHeight={confirmationData.length < 10}
            columnVisibilityModel={{ actions: false }}
            rowCount={confirmationData?.length}
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            checkboxSelection={!disableCheckbox}
            onSelectionModelChange={setConfirmedItems}
            keepNonExistentRowsSelected
            selectionModel={confirmedItems}
            sx={TABLE_SX}
          />
        </Grid>
      </Grid>
    </>
  )
}
