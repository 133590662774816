import { makeStyles } from '@material-ui/styles'

import { STAGE_TYPES } from '_utils/constants'

export default makeStyles(({ palette, spacing }) => ({
  progress: {
    position: 'relative',
  },
  firstStep: {
    position: 'absolute',
    inset: `${spacing(10)} auto auto 15%`,
    color: 'white',
  },
  secondStep: {
    position: 'absolute',
    inset: `${spacing(10)} auto auto 45%`,
    color: props => (props.step === STAGE_TYPES.ORDER ? palette.gray[32] : 'white'),
  },
  thirdStep: {
    position: 'absolute',
    inset: `${spacing(10)} auto auto 70%`,
    color: props => (props.step === STAGE_TYPES.EXECUTION ? 'white' : palette.gray[32]),
  },
  linearProgress: {
    height: spacing(35),
    width: '100%',
    backgroundColor: palette.gray[4],
    borderRadius: 0,

    '& .MuiLinearProgress-bar': {
      backgroundColor: props => (props.isCancelled ? palette.gray[32] : palette.orange.main),
      borderRadius: spacing(0, 60, 60, 0),
    },
  },
}))
