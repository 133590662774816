import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Datagrid } from '@refera/ui-web'

import { getMessageSelector } from '_modules/mensageria/selector'
import { ROUTES } from '_utils/constants'

import { COLUMNS } from './constants'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

const MessageMainTable = ({
  stateList,
  isLoading,
  page,
  setPage,
  pageSize,
  setPageSize,
  handleOrderBy,
}) => {
  const messageList = useSelector(getMessageSelector)

  const { checkIfUserDoesNotPermission } = useRolePermission()

  const handleRowClick = useCallback(event => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.MESSENGER, PERMISSIONS_ACTIONS.ADD)) {
      return
    }

    window.open(`${ROUTES.MENSAGERIA}/${event.row.id}`, '_blank', 'noopener,noreferrer')
  }, [])

  const dataGridStyle = {
    fontSize: '1.4rem',
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
  }

  return (
    <Datagrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'id', sort: 'asc' }],
        },
      }}
      rows={stateList}
      columns={COLUMNS.DEFAULT}
      paginationMode="server"
      sx={dataGridStyle}
      onRowClick={handleRowClick}
      loading={isLoading}
      rowCount={messageList?.count || 0}
      onPageChange={setPage}
      page={page}
      onPageSizeChange={setPageSize}
      pageSize={pageSize}
      onSortModelChange={handleOrderBy}
      noRowsLabel="Nenhum resultado encontrado"
    />
  )
}

export default React.memo(MessageMainTable)
