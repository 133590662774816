import humps from 'humps'
import { notEmptyPayload, parseNumber } from '_utils/helpers'

export const handleVerifyAgency = (res, setter) => {
  if (res?.detail === 'Not found.') {
    setter(false)
  }
}

export const verifyCEP = cep => {
  if (!cep.includes('-')) {
    const firstFour = cep.slice(0, 5)
    const lastThree = cep.slice(-3)
    const addSlash = `${firstFour}-${lastThree}`
    return addSlash
  }

  return cep
}

export const formatPayload = values => {
  const { address, number, complement, cep, listingId, city, neighborhood, code, ...payload } =
    notEmptyPayload(values)

  if (address) {
    payload.property = {
      ...payload.property,
      address,
      city,
      neighborhood,
      number: parseNumber(number?.toString()),
      complement,
      cep,
      listingId,
    }
  }

  if (code) {
    payload.property = { ...payload.property, code }
  }

  if (payload.contactNumber) {
    payload.contactNumber = `+${parseNumber(payload.contactNumber)}`
  }
  if (payload.firstContactSecondary) {
    payload.firstContactSecondary = `+${parseNumber(payload.firstContactSecondary)}`
  }
  if (payload.secondContactSecondary) {
    payload.secondContactSecondary = `+${parseNumber(payload.secondContactSecondary)}`
  }
  if (payload.contactCpfCnpj) {
    payload.contactCpfCnpj = parseNumber(payload.contactCpfCnpj)
  }
  if (payload.property.cpfOrCnpj) {
    payload.property.cpfOrCnpj = parseNumber(payload.property.cpfOrCnpj)
  }
  if (payload.property.propertyWhatsapp) {
    payload.property.propertyWhatsapp = parseNumber(payload.property.propertyWhatsapp)
  }

  return humps.decamelizeKeys(payload)
}

export const DATE_SUGGESTION_OPTIONS = [
  {
    name: 'firstSuggestionDate',
    label: 'Sugestão 1',
    period: 'firstPeriod',
    startTime: 'firstStartTime',
    endTime: 'firstEndTime',
  },
  {
    name: 'secondSuggestionDate',
    label: 'Sugestão 2',
    period: 'secondPeriod',
    startTime: 'secondStartTime',
    endTime: 'secondEndTime',
  },
  {
    name: 'thirdSuggestionDate',
    label: 'Sugestão 3',
    period: 'thirdPeriod',
    startTime: 'thirdStartTime',
    endTime: 'thirdEndTime',
  },
]

export const FORM_FIELDS = {
  DATE_SUGGESTION: [
    'firstSuggestionDate',
    'firstStartTime',
    'firstEndTime',
    'firstPeriod',
    'secondSuggestionDate',
    'secondStartTime',
    'secondEndTime',
    'secondPeriod',
    'thirdSuggestionDate',
    'thirdStartTime',
    'thirdEndTime',
    'thirdPeriod',
  ],
  PROPERTY_OWNER: ['propertyName', 'propertyEmail', 'propertyWhatsapp', 'cpfOrCnpj'],
  HIDDEN_FIELDS: [
    {
      name: 'url_callback',
      queryName: 'parametroWebhook',
    },
    {
      name: 'responsible_email',
      queryName: 'emailResponsavel',
    },
  ],
}

export const MODAL_MESSAGES = {
  DATE_SUGGESTION: 'Você tem certeza que deseja pular de etapa sem preencher uma sugestão de data?',
  PROPERTY_OWNER:
    'Você tem certeza que deseja pular de etapa sem preencher os dados do proprietário?',
}

export const FORM_STEPS = {
  STEP_ONE: 0,
  STEP_TWO: 1,
  STEP_THREE: 2,
  STEP_FOUR: 3,
  STEP_FIVE: 4,
  STEP_SIX: 5,
}
