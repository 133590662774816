import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { businessFrontListSelector } from '_/modules/businessFront/selectors'
import { roleGroupListSelector } from '_/modules/utils/selectors'
import { agenciesResultSelector } from '_/modules/agency/selectors'
import { getAllStepStatusOptionsSelector } from '_/modules/service-orders/selectors'
import { setItemsAsListSelector } from '_/modules/mensageria/selector'

import { SideFilter } from '_/components/side-filter'
import { Select, Autocomplete } from '_/components/inputs'

import useStyles from './styles'

export const MessageFilterDrawer = ({ open, close, filters, applyFilters }) => {
  const styles = useStyles()

  const agenciesList = useSelector(agenciesResultSelector)
  const roleGroupList = useSelector(roleGroupListSelector)
  const stepStatusList = useSelector(getAllStepStatusOptionsSelector)
  const businessFrontList = useSelector(businessFrontListSelector)
  const setItemsList = useSelector(setItemsAsListSelector)

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      business_front: [],
      type_message: '',
      destination: '',
      step_status: '',
      agencies: [],
      set_item: '',
    },
  })

  const { register, reset, handleSubmit } = methods

  const options = useMemo(() => {
    const businessFrontOptions =
      businessFrontList?.slice()?.sort((a, b) => a.description.localeCompare(b.description)) || []

    const roleOptions =
      roleGroupList
        ?.map(item => ({ label: item.roleGroupDesc, value: item.roleGroupName }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label)) || []

    const typeMessageOptions =
      [
        { value: '', label: '(Todos)' },
        { value: 'step', label: 'Etapa' },
        { value: 'self_checkout', label: 'Self-checkout' },
        { value: 'early_warning', label: 'Aviso prévio' },
      ].sort((a, b) => a.label.localeCompare(b.label)) || []

    const stepStatusOptions = stepStatusList?.slice()?.sort((a, b) => a.desc.localeCompare(b.desc))

    const setItemOptions =
      setItemsList?.slice().sort((a, b) => a.label.localeCompare(b.label)) || []

    const agenciesOptions =
      agenciesList
        .filter(item => item.isActive === true)
        ?.map(agency => ({ id: agency.id, name: agency.name }))
        ?.sort((a, b) => a.name.localeCompare(b.name)) || []

    roleOptions?.unshift({ value: '', label: '(Todos)' })
    stepStatusOptions?.unshift({ name: '', desc: '(Todos)' })
    setItemOptions?.unshift({ value: '', label: '(Todos)' })

    return {
      businessFrontOptions,
      roleOptions,
      typeMessageOptions,
      stepStatusOptions,
      setItemOptions,
      agenciesOptions,
    }
  }, [businessFrontList, roleGroupList, stepStatusList, setItemsList, agenciesList])

  const formatDefaultValues = () => {
    register('business_front')
    register('type_message')
    register('destination')
    register('step_status')
    register('agencies')
    register('set_item')
  }

  const resetFilters = () => {
    formatDefaultValues()
    reset({
      business_front: [],
      type_message: '',
      destination: '',
      step_status: '',
      agencies: [],
      set_item: '',
    })
  }

  useEffect(() => {
    formatDefaultValues()
  }, [])

  const mainFilters = useMemo(() => {
    return (
      <>
        <Autocomplete
          label="Fluxo"
          name="business_front"
          defaultValue={filters?.business_front}
          options={options?.businessFrontOptions || []}
          getOptionLabel={option => option.description}
        />

        <div className={styles.selectContainer}>
          <Select
            label="Tipo"
            name="type_message"
            defaultValue={filters?.type_message}
            options={options?.typeMessageOptions}
            getKey={item => item.value}
            getValue={item => item.value}
            getLabel={item => item.label}
          />
        </div>
        <div className={styles.selectContainer}>
          <Select
            label="Etapa"
            name="step_status"
            defaultValue={filters?.step_status}
            options={options?.stepStatusOptions}
            getKey={item => item.name}
            getValue={item => item.name}
            getLabel={item => item.desc}
          />
        </div>
        <div className={styles.selectContainer}>
          <Select
            label="Destino"
            name="destination"
            defaultValue={filters?.destination}
            options={options?.roleOptions}
            getValue={item => item.value}
            getLabel={item => item.label}
            getKey={item => item.value}
          />
        </div>
        <Autocomplete
          label="Intermediárias"
          name="agencies"
          defaultValue={filters?.agencies}
          options={options?.agenciesOptions}
          getOptionLabel={option => option.name}
          loading={!agenciesList?.length}
        />
        <div className={styles.selectContainer}>
          <Select
            label="Item definido"
            name="set_item"
            defaultValue={filters?.set_item}
            options={options?.setItemOptions}
            getKey={item => item.value}
            getValue={item => item.value}
            getLabel={item => item.label}
          />
        </div>
      </>
    )
  }, [{ ...methods }, options])

  // const moreFilters = useMemo(() => {
  //   return <></>
  // }, [{ ...methods }, options])

  return (
    <FormProvider {...methods}>
      <SideFilter
        open={open}
        close={close}
        mainFilters={mainFilters}
        // moreFilters={moreFilters}
        cleanFilters={resetFilters}
        applyFilters={handleSubmit(applyFilters)}
      />
    </FormProvider>
  )
}
