import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getGenericParametersSelector } from '_modules/service-orders/selectors'

const useIsGenericParamActive = ({ name, agency }) => {
  const genericParameters = useSelector(getGenericParametersSelector)

  const isActive = useMemo(() => {
    if (!genericParameters?.length) {
      return false
    }

    if (agency) {
      return genericParameters.find(item => item.name === name && item.agency === agency)?.active
    }

    return Boolean(genericParameters.find(item => item.name === name)?.active)
  }, [agency, genericParameters, name])

  return isActive
}

export default useIsGenericParamActive
