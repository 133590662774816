import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: spacing(34),
    paddingRight: '2%',
    height: spacing(72),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  menuItems: {
    display: 'flex',
    width: '73%',
    maxWidth: '900px',
    gap: spacing(8),
    justifyContent: 'space-between',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '300px',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    gap: spacing(4),
  },
  menuItemValue: {
    padding: spacing(4, 0),
    color: Theme.Colors.Grayscale.SixtyFour,
  },
  idField: {
    padding: spacing(4, 6),
    borderRadius: '16px',
    backgroundColor: Theme.Colors.Primary.Lightest,
    color: Theme.Colors.Primary.Base,
  },
  select: {
    marginBottom: spacing(-24),
    minWidth: spacing(120),
    maxWidth: spacing(200),
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  menuButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(8),
  },
  subjectField: {
    maxHeight: 200,
    fontSize: '1.6rem',
    color: palette.gray[32],
    overflowY: 'auto',
    marginBottom: 16,

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
}))
