import { schema } from 'normalizr'

import Model from './model'
import State from './state'

class Reason extends Model({
  id: undefined,
  uuid: undefined,
  reason: undefined,
}) {}

const reasonNewSchema = new schema.Entity(
  'reason',
  {},
  {
    processStrategy: entity => {
      return new Reason(entity).set('state', new State(entity.state))
    },
  }
)

const reasonSchema = [reasonNewSchema]

export { reasonSchema, Reason as default }
