import { createReducer } from '_utils/redux'
import { GET_NOTIFICATIONS, READ_NOTIFICATION } from './actions'
import { List, Record } from 'immutable'

const INITIAL_STATE = new Record({
  results: List(),
})()

const notifications = createReducer(INITIAL_STATE, {
  [GET_NOTIFICATIONS.FULFILLED]: (state, { payload }) =>
    state.set('results', new List(payload.results)),

  [READ_NOTIFICATION.FULFILLED]: (state, { meta }) => {
    const newNotificationList = state.results
      .toArray()
      .filter(notification => notification.id !== meta)
    return state.set('results', new List(newNotificationList))
  },
})

export default notifications
