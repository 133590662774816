/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

const DraggableStyle = styled.div`
  overflow-x: auto;
  cursor: grab;
  display: flex;
  margin: -16px;
  padding: 16px;
  user-select: none;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 720px) {
    overflow-x: hidden;
  }
`

export const DraggableList = ({ children }) => {
  const listRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const startDragging = e => {
    setIsDragging(true)
    setStartX(e.pageX - listRef.current.offsetLeft)
    setScrollLeft(listRef.current.scrollLeft)
  }

  const stopDragging = () => {
    setIsDragging(false)
  }

  const onDrag = e => {
    if (!isDragging) return
    e.preventDefault()
    const x = e.pageX - listRef.current.offsetLeft
    const walk = (x - startX) * 2 // Ajuste o multiplicador conforme necessário
    listRef.current.scrollLeft = scrollLeft - walk
  }

  return (
    <DraggableStyle
      id="draggable"
      ref={listRef}
      onMouseDown={startDragging}
      onMouseLeave={stopDragging}
      onMouseUp={stopDragging}
      onMouseMove={onDrag}
      role="list"
    >
      {children}
    </DraggableStyle>
  )
}
