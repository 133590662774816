import React, { useMemo } from 'react'
import { Grid, Typography, Link, Button } from '@material-ui/core'
import { useSelector } from 'react-redux'

import useMovideskChat from '_hooks/use-movidesk-chat'
import { HEADER_TAGS } from '_utils/header-tags'

import { getArticlesSelector } from '_/modules/utils/selectors'
import { userSelector } from '_/modules/authentication/selectors'

import Svg from '_components/svg'
import LandingIcon from '_assets/svgs/landing-help-lady.svg'
import QuestionIconBlue from '_assets/svgs/question-circle-blue.svg'
import MovideskChatIcon from '_assets/svgs/messages.svg'

import useStyles from './styles'
import { filterArticles, filterVideos, filterFaqs } from './utils'

const LandingPage = () => {
  const styles = useStyles()
  const [toggleMovideskChat] = useMovideskChat()

  const articles = useSelector(getArticlesSelector)
  const user = useSelector(userSelector)

  const fetchThumbnail = url => {
    const youtubeUrl = url.match(
      /(?:https?:\/\/)?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
    )

    const image = `https://i3.ytimg.com/vi/${youtubeUrl[1]}/mqdefault.jpg`

    return image || ''
  }

  const links = useMemo(() => {
    const userArticles = articles.filter(item => {
      const hasArticle = filterArticles(item, user)

      return hasArticle && item
    })

    const userVideos = articles.filter(item => {
      const hasVideos = filterVideos(item, user)

      return hasVideos && item
    })

    const userFaqs = articles.filter(item => {
      const hasFaqs = filterFaqs(item, user)

      return hasFaqs && item
    })

    return { videos: userVideos, faqs: userFaqs, articles: userArticles }
  }, [articles])

  const renderVideos = useMemo(() => {
    return (
      <>
        {links?.videos.length > 0 && (
          <Typography className={[styles.text, styles.alternative]}>Vídeos</Typography>
        )}
        <Grid className={[styles.contentSpacing, styles.videoContent]}>
          {links?.videos?.map(item => (
            <Grid className={styles.thumbnailWrapper}>
              <Link
                underline="always"
                className={styles.videoThumbnail}
                target="_blank"
                rel="noopener"
                key={item.id}
                href={item.link}
              >
                <img
                  className={styles.videoImage}
                  key={item.id}
                  src={fetchThumbnail(item.link)}
                  alt=""
                />
              </Link>
              <Typography className={styles.videoText}>{item.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </>
    )
  }, [links, articles])

  const renderFaqs = useMemo(() => {
    return (
      <Grid className={styles.contentSpacing}>
        {links?.faqs.length > 0 && (
          <Typography className={[styles.text, styles.alternative]}>Dúvidas frequentes</Typography>
        )}
        {links?.faqs?.map(item => (
          <Link
            underline="always"
            target="_blank"
            rel="noopener"
            key={item.id}
            className={styles.link}
            href={item.link}
          >
            {item.name}
          </Link>
        ))}
      </Grid>
    )
  }, [links, articles])

  const renderArticles = useMemo(() => {
    return (
      <>
        {links?.articles.length > 0 && (
          <Typography className={[styles.text, styles.alternative]}>Artigos</Typography>
        )}
        {links?.articles?.map(item => (
          <Link
            underline="always"
            target="_blank"
            rel="noopener"
            key={item.id}
            className={styles.link}
            href={item.link}
          >
            {item.name}
          </Link>
        ))}
      </>
    )
  }, [links, articles])

  return (
    <>
      <Grid className={styles.container}>
        <Svg icon={LandingIcon} className={styles.icon} />
        <Grid className={styles.welcomeWrapper}>
          <Typography className={styles.title}>Olá, prestador!</Typography>
          <Typography className={styles.text}>
            Enquanto aguarda a liberação da sua conta, que tal dar uma olhada nos conteúdos que
            separamos para você?
          </Typography>
        </Grid>

        <Grid className={styles.content}>
          <Typography className={[styles.text, styles.alternative]}>
            CONTEÚDOS EXCLUSIVOS PARA VOCÊ
          </Typography>

          {renderVideos}
          {renderFaqs}
          {renderArticles}
        </Grid>
      </Grid>

      <Grid className={styles.footer}>
        <Typography className={styles.footerTitle}>Você tem dúvidas?</Typography>
        <Typography className={styles.text}>
          Acesse nossa central de ajuda para encontrar as informações que você precisa.
        </Typography>
        <Grid className={styles.footerButtonWrapper}>
          <Button
            className={styles.footerButton}
            onClick={() => window.open('https://refera.movidesk.com/kb', '_blank', 'noopener')}
          >
            <Svg icon={QuestionIconBlue} className={styles.supportIcon} />
            <Typography className={styles.footerButtonText}>Central de ajuda</Typography>
          </Button>
          <Button
            onClick={toggleMovideskChat}
            className={styles.footerButton}
            icon={MovideskChatIcon}
          >
            <Svg icon={MovideskChatIcon} className={styles.supportIcon} />
            <Typography className={styles.footerButtonText}>Atendimento via chat</Typography>
          </Button>
        </Grid>
      </Grid>
      {HEADER_TAGS.MOVIDESK_CHAT_TRADESMAN.header}
    </>
  )
}

export default LandingPage
