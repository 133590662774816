import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  divider: {
    color: palette.gray[12],
    margin: spacing(24, 0),
  },
  rejectButton: {
    backgroundColor: palette.error.main,
    color: 'white',
  },
  cancelRejectButton: {
    borderColor: palette.error.main,
    color: palette.error.main,
  },
  cancelApproveButton: {
    border: `1px solid ${palette.error.main}`,
    backgroundColor: 'white',
    color: palette.error.main,
  },
}))
