import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './styles'
import Svg from '_/components/svg'
import { Button, Dialog } from '@refera/ui-web'
import { useParams, navigate } from '@reach/router'
import { useDispatch } from 'react-redux'
import { reproveServiceOrder } from '_modules/service-orders/actions'
import { TickCircle as TickCircleIcon } from '@refera/ui-icons'
import like from '_assets/icons/ic-like.svg'

const ServiceFive = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { serviceOrderId } = useParams()

  const handleConfirmButtonClick = () =>
    navigate(`/solicitar-manutencao/quinto-andar-b2c?idChamado=${serviceOrderId}`)

  useEffect(() => {
    dispatch(reproveServiceOrder(serviceOrderId)).then(result => {
      if (
        result?.stepStatus !== 'waiting_first_comunication' &&
        result?.stepStatus !== 'refera_service_denied' &&
        result?.stepStatus !== 'allocate_provider'
      ) {
        setIsDialogOpen(true)
      }
    })
  }, [])
  return (
    <>
      <Grid className={styles.container}>
        <Grid className={styles.content}>
          <Svg icon={like} className={styles.icon} />
          <Typography className={styles.titulo}>Obrigado!</Typography>
          <Typography className={styles.text}>
            Caso mude de ideia, podemos te enviar um orçamento sem compromisso em até 48 horas. Te
            enviaremos um orçamento completo incluindo material e mão de obra, com as condições de
            pagamento e prazo de execução. <br /> <br />
            Você pode fazer uma solicitação aqui 👇🏻
          </Typography>
          <Button className={styles.button} onClick={handleConfirmButtonClick}>
            Solicite um orçamento agora mesmo!
          </Button>
          <Typography className={styles.text}>
            Tem alguma dúvida? Entre em contato conosco através do e-mail: suporte@refera.com.br. !
          </Typography>
        </Grid>

        <Grid component="footer" className={styles.footer}>
          <Typography className={styles.titulo_footer}>
            © 2023 Refera Tecnologia - Desenvolvido por Refera
          </Typography>
        </Grid>
      </Grid>
      <Dialog
        open={isDialogOpen}
        type="info"
        description="Olá! Esta sua solicitação de orçamento já evoluiu para uma etapa onde infelizmente não é mais possível cancelar de forma automática. Solicitamos encarecidamente que você entre em contato com nosso suporte, clicando aqui, para oficializar tal pedido."
        onApprove={() => {
          setIsDialogOpen(false)
          window.open(`https://refera.movidesk.com/form/6753/`, '_blank')
        }}
        icon={TickCircleIcon}
      />
    </>
  )
}

export default ServiceFive
