export const formatDateTime = data => {
  const dateTime = new Date(data)
  const date = dateTime.toLocaleDateString('pt-BR')
  return `${date}`
}

export const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})
