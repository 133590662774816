import React from 'react'
import * as Modal from '_components/modal/generic-modal'
import { Grid, Typography, Divider, Box } from '@material-ui/core'
import CoverageTable from '../coverage-table'
import moment from 'moment'
import useStyles from './styles'

export function BudgetCoverageModal({ handleModal, version }) {
  const styles = useStyles()

  return (
    <Modal.Root open onClose={handleModal}>
      <Modal.TitleModal>
        <Typography className={styles.title}>Regra de cobertura</Typography>
      </Modal.TitleModal>
      <Divider style={{ marginBottom: 20 }} />
      <Modal.Content className={styles.content}>
        <Grid className={styles.container}>
          <div className={styles.info}>
            <Box className={styles.box}>
              <Typography className={styles.typography} variant="body2">
                <strong>Criada em:</strong>{' '}
                {moment(version?.createdAt).format('DD/MM/YYYY [às] HH:mm')}
              </Typography>
              <Typography className={styles.typography} variant="body2">
                <strong>Autor:</strong> {version?.user}
              </Typography>
            </Box>

            <div className={styles.textContainer}>
              <Typography className={styles.typography}>
                <strong>Versão</strong>
              </Typography>
              <Typography className={styles.typography}>
                <strong>{version?.version}</strong>
              </Typography>
            </div>
          </div>

          <CoverageTable fields={version?.values} isEditing={false} />

          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Typography className={styles.typography}>
              <strong>Descrição da Refera:</strong>
            </Typography>
            <span
              style={{
                border: '1px solid #E3E3E3',
                borderRadius: '5px',
                padding: '10px',
                minHeight: '150px',
                width: '100%',
              }}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: version?.description || '' }}
            />
          </div>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Modal.ButtonRed onClick={handleModal}>Fechar</Modal.ButtonRed>
      </Modal.Actions>
    </Modal.Root>
  )
}
