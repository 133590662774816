// eslint-disable-next-line import/no-extraneous-dependencies
import React, { forwardRef, useMemo, useCallback, useState, useEffect } from 'react'

// Core
import Theme, { generateBoxShadow } from '@refera/ui-core'

// Icons
import {
  CloseCircle as IconCloseCircle,
  DocumentText as IconDocumentText,
  PlayCircle as IconPlayCircle,
} from '@refera/ui-icons'

// Components
import styled, { css } from 'styled-components'
import { CardMedia } from '@mui/material'

// Utils
import { generateVideoThumbnail } from '_/utils/videoTools'

const AttachmentFile = styled(
  forwardRef(({ file, onRemove, onClick, content, readOnly, index, label, ...rest }, ref) => {
    const [thumbAttachment, setThumbAttachment] = useState(undefined)

    const fileName = useCallback(
      name => `${name.substring(0, 8)}...${name.split('.').pop()}`,
      [file]
    )

    const fileExtension = useMemo(
      () => (file?.fileType && file.fileType?.includes('xml') ? 'xml' : 'pdf'),
      [file]
    )

    const isFileObject = useMemo(() => file instanceof File, [file])

    const isVideo = useMemo(
      () =>
        isFileObject
          ? file.type.indexOf('video') !== -1
          : ['.webm', '.mkv', '.mp4', '.mov'].find(item => file.file.indexOf(item) !== -1),
      [file]
    )

    const generateThumbnail = useCallback(
      internalFile =>
        generateVideoThumbnail(
          internalFile instanceof File ? URL.createObjectURL(internalFile) : internalFile.file
        ),
      [file]
    )

    const generateThumb = useCallback(() => {
      if (!isVideo) {
        setThumbAttachment(isFileObject ? URL.createObjectURL(file) : file.file)
      }

      if (isVideo) {
        generateThumbnail(file).then(resp => setThumbAttachment(String(resp)))
      }

      return thumbAttachment
    }, [file])

    const Image = useMemo(
      () => (
        <div style={{ position: 'relative' }}>
          <CardMedia onClick={() => onClick(file)} image={thumbAttachment} ref={ref}>
            {isVideo && (
              <div className="ReferaAttachmentFile__videoPlay">
                <IconPlayCircle color="white" />
              </div>
            )}
          </CardMedia>
          {!readOnly && (
            <button
              onClick={e => {
                e.stopPropagation()
                onRemove()
              }}
              type="button"
              aria-label="Remove"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 10,
              }}
            >
              <IconCloseCircle style={{ background: 'none' }} />
            </button>
          )}
        </div>
      ),
      [thumbAttachment, file, readOnly]
    )

    const Document = useMemo(
      () => (
        <div
          onClick={() => onClick(file)}
          role="button"
          tabIndex={0}
          aria-hidden="true"
          className="ReferaAttachmentFile__fileItem"
          ref={ref}
        >
          <IconDocumentText />
          <div className="ReferaAttachmentFile__fileName">
            {fileName(
              file instanceof File
                ? file.name
                : `${label} ${index + 1}${file?.fileType}.${fileExtension}`
            )}
          </div>
          {!readOnly && (
            <button
              onClick={e => {
                e.stopPropagation()
                onRemove()
              }}
              type="button"
              aria-label="Remove"
            >
              <IconCloseCircle />
            </button>
          )}
        </div>
      ),
      [file, readOnly]
    )

    useEffect(() => {
      generateThumb()
    }, [file])

    return (
      <div className="ReferaAttachmentFile__root" {...rest}>
        {content === 'images' ? Image : Document}
      </div>
    )
  })
).attrs({})`
  && {
    ${() => css`
      .MuiCardMedia-root {
        min-width: 96px;
        min-height: 96px;
        cursor: pointer;
        margin-right: 8px;
        position: relative;
        display: flex !important;

        ::before {
          content: '';
          width: 100%;
          height: 100%;
          display: flex;
          position: absolute;
        }

        .ReferaAttachmentFile__videoPlay {
          display: flex;
          flex: 1 1 0%;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.58);
          font-size: 35px;
        }
      }

      width: fit-content !important;

      .ReferaAttachmentFile__fileItem {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        margin-bottom: 8px;
        padding: 8px;
        padding-right: 0;
        font-size: 24px;
        position: relative;
        align-items: center;
        overflow-y: scroll;
        border: 1px solid ${Theme.Colors.Grayscale.Twelve};
        border-radius: ${Theme.Radius.Small}px;
        box-shadow: ${generateBoxShadow('medium')};

        .ReferaAttachmentFile__fileName {
          flex: 1;
          margin: 0 8px 0 16px;
          color: ${Theme.Colors.Grayscale.Eighty};
          font-family: ${Theme.Typography.FontFamily.Roboto};
          font-weight: ${Theme.Typography.FontWeight.Small};
          font-size: ${Theme.Typography.FontSize.XSmall}px;
        }

        button {
          background-color: transparent;
          position: initial;
          font-size: 20px !important;

          svg,
          path {
            fill: ${Theme.Colors.Red.Base};
          }

          :hover {
            background: ${Theme.Colors.Red.Base};
            svg,
            path {
              fill: white;
            }
          }
        }

        svg,
        path {
          fill: ${Theme.Colors.Grayscale.Eighty};
        }
      }

      button {
        font-size: 25px;
        color: inherit;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        background: ${Theme.Colors.Grayscale.Eighty};
        border-radius: 50%;
        right: 4px;
        top: 4px;
        position: absolute;
        transition: all 0.1s linear;

        :hover {
          background: ${Theme.Colors.Grayscale.OneThousand};
        }

        svg,
        path {
          fill: ${Theme.Colors.Grayscale.Four};
        }
      }
    `}
  }
`

export default AttachmentFile
