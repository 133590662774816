const MESSAGE_USER_TYPE = {
  TRADESMAN: 'tradesman_to_refera',
  REFERA: 'refera_to_intermediary',
  INTERMEDIARY: 'intermediary_to_refera',
}

export const getMessagesSelector = state => state.messages.get('messages')
export const getOriginMessagesSelector = state => state.messages.get('messages').toJS().origin

export const getMessageSubjectSelectorList = state =>
  state.messages.get('messagesSubjectOptions').toArray()

export const getMessageOptionsSubjectSelector = userType => state => {
  const optionList = state.messages.get('messagesSubjectOptions')
  let type = null

  switch (userType) {
    case MESSAGE_USER_TYPE.TRADESMAN: {
      type = 'allowTradesman'
      break
    }
    case MESSAGE_USER_TYPE.REFERA: {
      type = 'allowCsrefera'
      break
    }
    case MESSAGE_USER_TYPE.INTERMEDIARY: {
      type = 'allowCsmanagerCsapprover'
      break
    }
    default:
      break
  }

  // Esse filtro era mais interessante ser feito no back
  if (type) return optionList.filter(option => option[type]).toArray()
  return []
}

export const getCurrentMessagesSelector = state => state.messages.get('currentMessage').toArray()

export const getCurrentMessageByIdSelector = messageId => state =>
  messageId &&
  state.messages
    .get('currentMessage')
    .toArray()
    .find(message => message.id === messageId)

export const getAllNotesSelector = state => state.messages.get('allNotes')
