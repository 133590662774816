import React from 'react'

import Theme from '@refera/ui-core'
import { Tooltip } from '@material-ui/core'

import { ArrowRight3 as ArrowIcon } from '@refera/ui-icons'

import moment from 'moment'
import { statusField } from '../../manage-installments/utils/constants'
import { ROUTES } from '_/utils/constants'

const handleBudgetUrl = ({ params, verificationToken }) => {
  const url = verificationToken
    ? `${ROUTES.BUDGET_VIEW}/${params.row.budgetId}?verification_token=${verificationToken}`
    : `${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}/orcamento/${params.row.budgetId}`
  return window.open(url, '_blank', 'noopener,noreferrer')
}

export const TableColumns = ({ styles, verificationToken = null }) => {
  return [
    {
      field: 'serviceOrderId',
      headerName: 'ID do chamado',
      width: 120,
      editable: false,
      headerClassName: styles.grayColumn,
      cellClassName: styles.idColumn,
      renderCell: params => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            backgroundColor: Theme.Colors.Primary.Lightest,
            padding: '4px 8px',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'dueDate',
      headerName: 'Vencimento',
      width: 120,
      editable: false,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
    },
    {
      field: 'paymentDate',
      headerName: 'Pagamento',
      width: 120,
      editable: false,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
    },
    {
      field: 'number',
      headerName: 'Parcela',
      width: 120,
      editable: false,
      valueFormatter: ({ value }) => (Number(value) > 1 ? value : 'À vista'),
    },
    {
      field: 'providerValue',
      headerName: 'Valor',
      width: 120,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
    },
    {
      field: 'anticipationTax',
      headerName: 'Taxa antecipação',
      width: 150,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
    },
    {
      field: 'amountPaid',
      headerName: 'Valor líquido',
      width: 150,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: false,
      renderCell: params => statusField(params.row.status),
    },
    {
      field: 'agencyName',
      headerName: 'Intermediária',
      width: 180,
      editable: false,
      valueFormatter: ({ value }) => value || '-',
    },
    {
      field: ' ',
      headerName: '',
      minWidth: 80,
      editable: false,
      flex: 1,
      renderCell: params => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Tooltip title="Ir para o orçamento">
            <button
              type="button"
              className={styles.actionButtonWrapper}
              onClick={() => handleBudgetUrl({ params, verificationToken })}
              aria-label="Ir para o orçamento"
            >
              <ArrowIcon color={Theme.Colors.Primary.Base} />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ]
}
