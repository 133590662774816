/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Button from '_components/button'
import useModal from '_hooks/use-toggle'
import { providerSelector } from '_modules/provider/selectors'
import { getCitiesOptions } from '_modules/utils/selectors'
import { getCities } from '_modules/utils/actions'

import Accordion from '../index'
import AccordionInfo from '../accordion-info'

import AddRegionsModal from './add-regions-modal'
import useStyles from './styles'

const AddRegions = ({
  isProvider,
  notSaveState,
  setNotSaveState,
  isEditing,
  setValue,
  errors,
  touched,
  ...otherProps
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const provider = useSelector(providerSelector)
  const cityOptions = useSelector(getCitiesOptions)
  const [modalOpen, handleModal] = useModal()
  const [regions, setRegions] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = formValues => {
    const isDuplicated = regions.find(x => x.city === formValues.city)

    if (formValues.city === '' || formValues.state === '') {
      return
    }

    if (!isDuplicated) {
      setRegions([...regions, formValues])
    }

    setSubmitting(true)
  }

  const [regionStates, setRegionStates] = useState()
  const [regionStatesNames, setRegionStatesNames] = useState()

  const handleAddRegion = () => {
    const newRegionStates = []
    const newRegionStatesNames = []
    cityOptions.map(city => {
      newRegionStates.push({ id: city.state.id, name: city.state.name })
      newRegionStatesNames.push(city.state.name)
    })
    setRegionStates([...new Set(newRegionStates)])
    setRegionStatesNames([...new Set(newRegionStatesNames)])
    handleModal()
  }

  useEffect(() => {
    handleInitialState()
    if (notSaveState) {
      setNotSaveState(false)
    }
  }, [provider, notSaveState])

  const handleInitialState = () => {
    const initialRegionCities = []
    const cities = []

    provider?.cities.map(city => {
      initialRegionCities.push(city)
    })

    cityOptions
      .filter(city => {
        return initialRegionCities.includes(city.value)
      })
      .map(city => {
        const data = {
          state: city.state.name,
          city: city.label,
        }
        cities.push(data)
      })
    setRegions(cities)
  }

  useEffect(() => {
    const cityNames = []
    const finalCitySlugs = []

    if (regions.length === 0) {
      setValue('cities', '')
    }

    regions.map(x => cityNames.push(x.city))

    cityOptions
      .filter(city => {
        return cityNames.includes(city.label)
      })
      .map(city => finalCitySlugs.push(city.value))

    setValue('cities', finalCitySlugs)
    setSubmitting(false)
  }, [isSubmitting])

  const handleDeleteRegion = cityObj => {
    const updatedRegions = regions.filter(currentCity => currentCity.city !== cityObj.city)
    setRegions(updatedRegions)
    if (updatedRegions.length === 0) {
      setRegions([])
    }
    setSubmitting(true)
  }

  useEffect(() => {
    if (!cityOptions.length) {
      dispatch(getCities())
    }
  }, [cityOptions.length, dispatch])

  return (
    <Accordion
      id="regioes-da-empresa-1"
      title="Região de atendimento"
      {...otherProps}
      className={styles.container}
    >
      <Grid className={styles.userList}>
        {regions
          .sort((a, b) => a.state.localeCompare(b.state))
          .map((region, index) => (
            <AccordionInfo
              key={index}
              isFirst={index === 0}
              type="region"
              values={region}
              isProvider={isProvider}
              isEditing={isEditing}
              handleDeleteValue={handleDeleteRegion}
            />
          ))}
      </Grid>
      {isEditing && (
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={handleAddRegion}
          required
        >
          +Adicionar registro
        </Button>
      )}
      {errors?.cities && touched?.cities && (
        <Typography variant="body2" className={styles.error}>
          {errors.cities}
        </Typography>
      )}
      {modalOpen && (
        <AddRegionsModal
          modalOpen={modalOpen}
          regionsList={regions}
          handleModal={handleModal}
          handleSubmit={handleSubmit}
          cityOptions={cityOptions}
          stateOptions={regionStates}
          stateNames={regionStatesNames.sort()}
          isProvider={isProvider}
        />
      )}
    </Accordion>
  )
}

AddRegions.propTypes = {
  isProvider: PropTypes.bool,
  isCreating: PropTypes.bool,
  setFieldValue: PropTypes.func,
}

AddRegions.defaultProps = {
  isProvider: false,
  isCreating: false,
  setFieldValue: () => {},
}

export default AddRegions
