export const FORM_FIELD_NAME = {
  FROM_TO: 'fromTo',
  SUBJECT: 'subject',
  MESSAGE: 'message',
  BUDGET: 'budget',
  SENDER: 'requester',
  INTERMEDIARY_AVAILABLE: 'intermediaryAvailable',
  ANSWER: 'answer',
  RESOLVED: 'resolved',
}

export const INITIAL_STATE = {
  [FORM_FIELD_NAME.FROM_TO]: null,
  [FORM_FIELD_NAME.SUBJECT]: null,
  [FORM_FIELD_NAME.MESSAGE]: null,
  [FORM_FIELD_NAME.BUDGET]: null,
  [FORM_FIELD_NAME.SENDER]: null,
  [FORM_FIELD_NAME.INTERMEDIARY_AVAILABLE]: null,
  [FORM_FIELD_NAME.ANSWER]: null,
  [FORM_FIELD_NAME.RESOLVED]: null,
}

export const UPDATE_STATE = 'updateState'
export const FILL_STATE = 'fillState'
export const CLEAR_PROVIDER_FIELDS = 'clearProviderFields'
export const CLEAR_STATE = 'clearState'
export const UPDATE_CHECKBOX = 'updateCheckbox'

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_STATE:
      return { ...state, [payload?.name]: payload?.value }

    case UPDATE_CHECKBOX:
      return { ...state, [payload?.name]: !state[payload.name] }

    case FILL_STATE: {
      let result = { ...state }
      Object.keys(payload).forEach(field => {
        result = { ...result, [field]: payload[field] }
      })
      return result
    }

    case CLEAR_PROVIDER_FIELDS:
      return {
        ...state,
        [FORM_FIELD_NAME.BUDGET]: null,
        [FORM_FIELD_NAME.SENDER]: null,
        [FORM_FIELD_NAME.SUBJECT]: null,
      }

    case CLEAR_STATE:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}
