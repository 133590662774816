import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  accordion: {
    borderRadius: spacing(5),
    width: '100%',
    maxWidth: '1050px',
    boxShadow: `${spacing(0, 2, 4)} rgba(0, 0, 0, 0.25)`,
    padding: spacing(24, 20),

    '& .MuiAccordion-root.Mui-expanded': {
      margin: spacing(16, 0, 0),
    },
  },
  summary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '0 !important',

    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  icon: {
    height: spacing(22),
    width: spacing(22),
    marginRight: spacing(10),
    transform: props => (!props.isExpanded ? 'rotate(180deg)' : 'rotate(0)'),
  },
  iconButton: {
    height: spacing(16),
    width: spacing(16),
  },
  openActions: {
    marginLeft: 'auto',
  },
  details: {
    paddingTop: spacing(14),
    display: 'grid',
    // gridTemplateColumns: props =>`repeat(${props.hasMoreFields ? 3 : 2},minmax(auto, ${spacing(317)}))`,
    gridTemplateColumns: `repeat( 3,minmax(auto, ${spacing(317)}))`,
    rowGap: spacing(16),
    columnGap: spacing(16),
  },
  title: {
    color: palette.gray[32],
    marginBottom: spacing(6),
  },
  value: {
    color: palette.gray[64],
  },

  containerAddModal: {
    display: 'grid',
    gridRowGap: spacing(24),
  },
  radioGroupAddModal: {
    '&.MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  labelAddModal: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: palette.gray[64],
  },
  labelsAddModal: {
    fontWeight: '500',
    fontSize: spacing(12),
    lineHeight: spacing(20),
    marginBottom: spacing(10),
    color: '#141414',
  },
  inlineLabelAddModal: {
    display: 'flex',
    gap: spacing(6),
  },
  modalAddModal: {
    width: '100vw',
  },
  wrapperAddModal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
  },
  contentStateAddModal: {
    width: '100%',
    marginBottom: spacing(15),
  },
  contentCityAddModal: {
    width: '100%',
  },

  containerAdd: {
    fontSize: '3rem',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperAdd: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
  chipAdd: {
    marginRight: spacing(10),
    marginBottom: spacing(10),
  },
  titleAdd: {
    color: palette.gray[32],
    marginBottom: spacing(6),
  },
  buttonAdd: {
    width: spacing(154),
    height: spacing(40),
    fontWeight: 'normal',
    fontSize: spacing(15),
  },
  errorAdd: {
    color: palette.custom.red,
    fontSize: spacing(10),
  },
  categorySelectAdd: {
    width: spacing(250),
    height: spacing(42),
    fontSize: spacing(15),
  },
  categoryWrapperAdd: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: spacing(15),
    borderTop: '1px solid #E3E3E3',
    paddingTop: spacing(10),
  },
  blueButtonAdd: {
    minWidth: 0,
    width: spacing(154),
    height: spacing(40),
    fontWeight: 'normal',
    fontSize: spacing(15),
  },
  subjectFieldAdd: {
    width: spacing(200),
    maxHeight: spacing(200),
    fontSize: spacing(20),
    color: '#B4B4B4',

    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: spacing(6),
      height: spacing(6),
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: spacing(20),
      margin: spacing(30),
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: spacing(20),
      border: '3px solid gray',
    },
  },
}))
