/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, FormControl, Typography, TextField } from '@material-ui/core'
import Modal from '_components/modal'
import RatingModal, { WARNING_MODAL } from '_components/modal/rating-modal'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useStyles from '../../styles'
import { getAgencies, getAgenciesFiltered } from '_/modules/agency/actions'
import { useDispatch, useSelector } from 'react-redux'
import { agenciesResultSelector } from '_/modules/agency/selectors'
import useHandleInput from '_/hooks/use-handle-input'
import { useDebounce } from 'use-debounce'

const AddAgencyModal = ({ handleModal, handleSubmit, disabledSubmit, available, title, name }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [input, handleInput] = useHandleInput()
  const [searchValue] = useDebounce(input, 200)

  const servicesAgency = useSelector(agenciesResultSelector)
  const [services, setServices] = useState([])

  useEffect(() => {
    const newResult = servicesAgency.reduce((result, agency) => {
      if (!available.some(agencySelected => agencySelected.id === agency.id)) {
        result.push({ value: agency.id, label: agency.name })
      }
      return result
    }, [])
    setServices(newResult)
  }, [servicesAgency])

  const [select, setSelect] = useState('')
  const [ratingModal, setRatingModal] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  })

  const onConfirmCategory = useCallback(() => {
    setRatingModal({
      isOpen: true,
      title: 'Atenção!',
      subTitle: 'Por favor, selecione uma categoria valida antes de salvar ou clica em cancelar!',
      type: WARNING_MODAL,
    })
  }, [setRatingModal])

  const handleSubmitCategory = () => {
    if (!select) return onConfirmCategory()
    const data = {
      id: select?.value,
      name: select?.label,
    }
    handleSubmit(data)
    dispatch(getAgencies({ pageSize: 1000 }))
    return handleModal()
  }

  useEffect(() => {
    dispatch(getAgenciesFiltered({ name: searchValue, pageSize: 1000 }))
  }, [dispatch, searchValue])

  const getOptionLabel = useCallback(
    option => {
      if (typeof option === 'string' || typeof option === 'number') {
        return services?.find(opt => opt.value === option)?.label || ''
      }

      return option?.label || option || ''
    },
    [services]
  )

  const getOptionSelected = useCallback((option, currentValue) => {
    return (
      String(option.value) === String(currentValue.value) || String(option.value) === currentValue
    )
  }, [])

  return (
    <Modal
      handleModal={handleModal}
      title={title}
      className={styles.modalAddModal}
      handleSubmit={handleSubmitCategory}
      disabledSubmit={disabledSubmit}
    >
      <Grid container className={styles.wrapperAddModal}>
        <FormControl>
          <Grid className={styles.inlineLabelAddModal}>
            <Typography className={styles.labelsAddModal}>Intermediárias</Typography>
          </Grid>
          <Autocomplete
            id={name}
            options={services}
            getOptionSelected={getOptionSelected}
            multiple={false}
            getOptionLabel={getOptionLabel}
            noOptionsText="Nenhuma agência encontrada com esse nome"
            renderInput={params => <TextField {...params} />}
            onInputChange={handleInput}
            onChange={(event, newValue) => {
              setSelect(newValue)
            }}
          />
        </FormControl>
        <RatingModal ratingModal={ratingModal} setRatingModal={setRatingModal} />
      </Grid>
    </Modal>
  )
}

AddAgencyModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default AddAgencyModal
