import React, { useState, useCallback, useEffect } from 'react'
import { Grid } from '@material-ui/core'

import { Button, Menu, MenuItem, Typography } from '@refera/ui-web'
import { ArrowDown1 as ArrowDown, DollarSquare, Shop } from '@refera/ui-icons'
import Theme from '@refera/ui-core'

import useStyles from './styles'
import useRolePermission from '_/hooks/use-role-permission'
import { USER_ROLES } from '_utils/user'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '_/modules/authentication/actions'
import { userSelector } from '_/modules/authentication/selectors'
import { USER_MODULES } from '_/utils/constants'

const ModuleSelector = () => {
  const styles = useStyles()

  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const { role } = useRolePermission()

  const [anchorEl, setAnchorEl] = useState(null)

  const iconProps = { color: Theme.Colors.Primary.Base, className: styles.moduleIcon }
  const modules = [
    {
      label: 'Marketplace',
      icon: <Shop {...iconProps} />,
      role: [USER_ROLES.CS_REFERA],
      value: USER_MODULES.MARKETPLACE,
    },
    {
      label: 'Financeiro',
      icon: <DollarSquare {...iconProps} />,
      role: [USER_ROLES.CS_FINANCE],
      value: USER_MODULES.FINANCE,
    },
  ]

  const [selectedModule, setSelectedModule] = useState(
    modules?.filter(moduleItem => user.lastSelectedModule === moduleItem.value)[0]?.label
  )

  async function updateModule(moduleToUpdate) {
    await dispatch(
      updateUser({
        lastSelectedModule: moduleToUpdate?.value,
      })
    ).then(() => setSelectedModule(moduleToUpdate?.label))
  }

  useEffect(() => {
    if (!user.lastSelectedModule) {
      const defaultModule = role === USER_ROLES.CS_FINANCE ? modules[1] : modules[0]
      updateModule(defaultModule)
    }
  }, [user.lastSelectedModule])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleModuleClick = useCallback(
    async moduleItem => {
      setAnchorEl(null)

      if (user.lastSelectedModule !== moduleItem.value) updateModule(moduleItem)
    },
    [user, updateModule]
  )

  return (
    <Grid className={styles.container}>
      <Button onClick={handleClick} className={styles.button}>
        <Typography className={styles.moduleName}>{selectedModule}</Typography>
        <ArrowDown color="white" fontSize={16} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Grid className={styles.modulesMenuContainer}>
          {modules.map(
            moduleItem =>
              moduleItem.role.some(item => role?.indexOf(item) >= 0) && (
                <MenuItem
                  key={moduleItem.label}
                  onClick={() => handleModuleClick(moduleItem)}
                  className={styles.moduleItem}
                >
                  {moduleItem.icon}
                  <Typography className={styles.moduleLabel}>{moduleItem.label}</Typography>
                </MenuItem>
              )
          )}
        </Grid>
      </Menu>
    </Grid>
  )
}

export default ModuleSelector
