import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: spacing(75),
    width: '100%',
    paddingRight: '2%',
    paddingLeft: spacing(34),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: `${Theme.Typography.FontSize.Large}px`,
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  mainTable: {
    width: 'auto',
    height: 630,
  },
  headerButton: {
    display: 'flex',
    gap: spacing(10),
    alignContent: 'center',
    justifyContent: 'center',
  },
  modalText: {
    fontSize: Theme.Typography.FontSize.XXSmall,
    lineHeight: spacing(20),
    color: palette.gray[64],
    marginTop: spacing(10),
  },
  modalTextTitle: {
    fontSize: Theme.Typography.FontSize.XSmall,
    lineHeight: spacing(20),
    color: palette.gray[64],
  },
  chip: {
    borderRadius: spacing(24),
    textTransform: 'none',
    fontFamily: ['Roboto', 'sans-serif'],
    padding: '4px 8px',
    fontSize: '14px',
    lineHeight: '112.5%',
    cursor: 'pointer',
  },
  chipEnabled: {
    backgroundColor: Theme.Colors.Primary.Base,
    color: Theme.Colors.Primary.Lightest,
  },
  chipDisabled: {
    backgroundColor: Theme.Colors.Primary.Lightest,
    color: Theme.Colors.Primary.Base,
  },
}))
