import React, { useCallback, useMemo, useContext } from 'react'
import { Button } from '@refera/ui-web'

import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import { BUTTONS_ACTION } from '_utils/constants/service-order'
import { BudgetContext, UPDATE_BUDGET_TYPE } from '_utils/budget'
import { useDialog } from '_/hooks/use-dialog'

const TemporarySaveBudgetButton = () => {
  const { isAdmin, isTradesman, isSAAS } = useRolePermission()
  const { showDialog, closeDialog } = useDialog()

  const { handleUpdateBudgetItems, isEditing, setUpdateBudgetType, isLoading, handleUpdateBudget } =
    useContext(BudgetContext)

  const canTemporarySave = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.TEMPORARY_SAVE,
    isBudgetRelated: true,
  })

  const label = useMemo(() => (isAdmin ? 'Salvar' : 'Salvar temporário'), [isAdmin])

  const isTemporaryButtonVisible = useMemo(
    () => ((canTemporarySave && (isTradesman || isSAAS)) || isAdmin) && isEditing,
    [canTemporarySave, isAdmin, isTradesman, isSAAS, isEditing]
  )

  const handleTemporarySave = useCallback(() => {
    setUpdateBudgetType(UPDATE_BUDGET_TYPE.TEMPORARY)
    handleUpdateBudgetItems(UPDATE_BUDGET_TYPE.TEMPORARY)
  }, [handleUpdateBudgetItems, setUpdateBudgetType])

  const handleSave = useCallback(() => {
    handleUpdateBudget()
  }, [handleUpdateBudget])

  const sendBudget = useCallback(() => {
    showDialog({
      type: 'warning',
      subject: 'Atenção!',
      description: 'Você tem certeza que deseja executar esta ação?',
      onApprove: () => {
        closeDialog()
        handleSave()
      },
      labelApprove: 'Ok',
      labelCancel: 'Cancelar',
      onCancel: closeDialog,
    })
  }, [handleUpdateBudgetItems])

  if (!isTemporaryButtonVisible) {
    return null
  }

  return (
    <Button
      onClick={isAdmin ? sendBudget : handleTemporarySave}
      color="primary"
      variant="secondary"
      disabled={isLoading}
    >
      {label}
    </Button>
  )
}

export default TemporarySaveBudgetButton
