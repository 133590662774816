import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints }) => ({
  container: {
    position: 'absolute',
    bottom: 30,
    right: 20,

    [breakpoints.down('sm')]: {
      display: 'None',
    },
  },
  icon: {
    width: '280px',
    height: '250px',
    marginLeft: '27px',

    [breakpoints.down('sm')]: {
      display: 'None',
    },
  },
  messageWrapper: {
    width: '184px',
    height: '72px',
    position: 'absolute',
    marginTop: '-52px',
    marginLeft: '19px',
    color: '#000000',

    [breakpoints.down('sm')]: {
      display: 'None',
    },
  },
  speech: {
    width: '216px',
    height: '125px',
    position: 'absolute',
    marginTop: '-70px',

    [breakpoints.down('sm')]: {
      display: 'None',
    },
  },
  button: {
    position: 'absolute',
    marginTop: '-12px',
    marginLeft: '-7px',
    color: '#1671ED',

    [breakpoints.down('sm')]: {
      display: 'None',
    },
  },

  // MOBILE ---------------
  containerMobile: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 30,
    right: 20,

    [breakpoints.up('md')]: {
      display: 'None',
    },
  },
  iconMobile: {
    width: '113px',
    height: '113px',

    [breakpoints.up('md')]: {
      display: 'None',
    },
  },
  closeBtnWrapper: {
    position: 'absolute',
    top: 12,
    right: -2,
    padding: 0,
    minWidth: 0,
    minHeight: 0,
    border: '0px solid rgb(0 0 0 / 0%)',
    borderRadius: '50%',
    backgroundColor: 'white',

    [breakpoints.up('md')]: {
      display: 'None',
    },
  },
  closeBtn: {
    width: '26px',
    height: '26px',

    [breakpoints.up('md')]: {
      display: 'None',
    },
  },
  buttonMobile: {
    color: 'white',
    width: '113px',
    maxHeight: '30px',
    marginTop: '5px',
    backgroundColor: '#1671ED',
    borderRadius: '4px',

    [breakpoints.up('md')]: {
      display: 'None',
    },
  },
}))
