import React, { useMemo, useCallback } from 'react'
import classnames from 'classnames'
import { Grid, Typography, Link, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/Add'
import { useSelector } from 'react-redux'
import DefaultImage from '_assets/images/default.png'
import CardMedia from '_components/card-media'
import { updateServiceOrderLoadingSelector } from '_modules/service-orders/selectors'

import useStyles from './styles'

const PICTURES = [DefaultImage]

const AttachedPictures = ({
  isEditing,
  pictures,
  setFieldValue,
  name,
  classNameButton,
  classNameIcon,
  isIntegrated,
  handleAddPictures,
  handleRemovePictures,
  hasPreview,
  picturesContainerClassName,
  acceptFormat,
}) => {
  const styles = useStyles()
  const isLoading = useSelector(updateServiceOrderLoadingSelector)

  const onChooseFile = useCallback(
    event => {
      const newFile = event.target.files[0]
      if (isIntegrated) {
        handleAddPictures(newFile)
        return
      }
      if (newFile && name) {
        setFieldValue(name, [...pictures, newFile])
        return
      }

      setFieldValue([...pictures, newFile])
    },
    [handleAddPictures, isIntegrated, name, pictures, setFieldValue]
  )

  const onRemoveClick = useCallback(
    (pictureId, id) => {
      if (isIntegrated) {
        handleRemovePictures(pictureId)
        setFieldValue(
          name,
          pictures.filter(picture => picture?.id !== pictureId)
        )
        return
      }
      if (!isNaN(id)) {
        handleRemovePictures(id)
        return
      }
      setFieldValue(
        name,
        pictures.filter(picture => picture?.lastModified !== pictureId)
      )
    },
    [handleRemovePictures, isIntegrated, name, pictures, setFieldValue]
  )

  const renderContentEditing = useMemo(
    () => (
      <Grid className={classnames(styles.images, picturesContainerClassName)}>
        <label
          htmlFor={`${name}-upload-image`}
          className={classnames(styles.button, classNameButton)}
        >
          <AddIcon className={classnames(styles.icon, classNameIcon)} />
          <input
            onChange={onChooseFile}
            id={`${name}-upload-image`}
            type="file"
            accept={acceptFormat || 'image/*, .mp4, .mkv, .mov, .flv, .pdf'}
            className={styles.fileSelector}
          />
        </label>
        {isLoading ? (
          <Grid container className={styles.loading}>
            <CircularProgress size={40} />
          </Grid>
        ) : (
          pictures &&
          pictures?.map((picture, index) => (
            <CardMedia
              key={picture.lastModified || picture.id}
              className={styles.media}
              isEditing={isEditing}
              file={picture.file || picture}
              alt="Imagem do chamado"
              name={isIntegrated ? picture.id : picture}
              onRemoveClick={onRemoveClick}
              id={index}
            />
          ))
        )}
      </Grid>
    ),
    [
      classNameButton,
      classNameIcon,
      isIntegrated,
      isLoading,
      name,
      isEditing,
      onChooseFile,
      onRemoveClick,
      pictures,
      picturesContainerClassName,
      styles.button,
      styles.fileSelector,
      styles.icon,
      styles.images,
      styles.loading,
      styles.media,
    ]
  )

  const renderContent = useMemo(() => {
    if (Array.isArray(pictures) && pictures?.length) {
      return (
        <Grid className={classnames(styles.images, picturesContainerClassName)}>
          {pictures?.map(picture =>
            hasPreview ? (
              <Link href={picture} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  key={picture?.id || picture}
                  showOnly
                  className={styles.media}
                  file={picture.file || picture}
                  alt="Imagem do chamado"
                />
              </Link>
            ) : (
              <CardMedia
                key={picture?.id || picture}
                showOnly
                className={styles.media}
                file={picture.file || picture}
                alt="Imagem do chamado"
              />
            )
          )}
        </Grid>
      )
    }
    return (
      <Typography component="span" variant="body1">
        Sem anexos
      </Typography>
    )
  }, [picturesContainerClassName, hasPreview, pictures, styles.images, styles.media])

  return (
    <Grid className={styles.container}>{isEditing ? renderContentEditing : renderContent}</Grid>
  )
}

AttachedPictures.propTypes = {
  isEditing: PropTypes.bool,
  pictures: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
  setFieldValue: PropTypes.func,
  name: PropTypes.string.isRequired,
  classNameButton: PropTypes.string,
  classNameIcon: PropTypes.string,
  isIntegrated: PropTypes.bool,
  handleAddPictures: PropTypes.func,
  handleRemovePictures: PropTypes.func,
  hasPreview: PropTypes.bool,
  picturesContainerClassName: PropTypes.string,
}
AttachedPictures.defaultProps = {
  isEditing: false,
  pictures: PICTURES,
  setFieldValue: () => {},
  classNameButton: '',
  classNameIcon: '',
  isIntegrated: false,
  handleAddPictures: () => {},
  handleRemovePictures: () => {},
  hasPreview: false,
  picturesContainerClassName: '',
}

export default AttachedPictures
