import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  dialog: {
    position: 'absolute',
    maxWidth: spacing(654),
    height: 'auto',

    [breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  button: {
    width: '100px',
    borderRadius: '24px',
    height: '40px',
    textTransform: 'none',
  },
  dialogContent: {
    textAlign: 'justify',
    fontSize: '16px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0 8px 8px 0',
  },
  dialogAction: {
    flexDirection: 'column',
    justifyContent: 'right',
    margin: '20px',
  },
  btnWrapper: {
    display: 'flex',
    width: '100%',
    gap: '10px',
    justifyContent: 'end',
    marginTop: '10px',
    alignItems: 'center',
    paddingRight: '4px',
  },
  text: {
    fontSize: spacing(16),
    lineHeight: spacing(16),
    color: palette.gray[64],
    marginTop: spacing(10),
  },
}))
