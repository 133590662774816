import React, { useEffect } from 'react'

// Icons
import { Trash as TrashIcon } from '@refera/ui-icons'

// Components
import { Dialog, useConfirm } from '@refera/ui-web'

const CancelRejectedModal = () => {
  const { isOpen = true, approve, cancel } = useConfirm()

  useEffect(() => {
    const handleKeydown = e => {
      if (approve && isOpen && e.key === 'Enter') {
        approve()
      }
    }
    window.addEventListener('keydown', handleKeydown)
    return () => window.removeEventListener('keydown', handleKeydown)
  }, [approve, isOpen])

  return (
    <Dialog
      open={isOpen}
      icon={TrashIcon}
      type="error"
      subject="Você tem certeza que deseja cancelar a reprovação de finalização dos serviços deste chamado?"
      labelApprove="Sim"
      labelCancel="Não"
      onApprove={approve}
      onCancel={cancel}
    />
  )
}

export default CancelRejectedModal
