import { formatCurrency } from '_/utils/helpers'
import moment from 'moment'

export const CalculateTradesmanAnticipationValues = ({ items, financeGenericParameters }) => {
  const calculatePresentValue = (installment, providerValue) => {
    const daysToAnticipationDate = moment(installment.dueDate).diff(
      moment(financeGenericParameters.anticipationDay),
      'days'
    )
    const presentValue =
      providerValue / (1 + financeGenericParameters.dailyAnticipationTax) ** daysToAnticipationDate

    return presentValue || 0
  }

  const getItemsWithTaxes = () => {
    let interestValue = 0
    let totalValue = 0
    let totalPriceServiceProvider = 0

    const itemsWithTax = items?.map(item => {
      const providerValue =
        item.priceServiceProvider || item.providerValueTotal || item.providerValue
      totalPriceServiceProvider += providerValue
      const actualInterestTax = calculatePresentValue(item, providerValue)
      totalValue += actualInterestTax
      interestValue += providerValue - actualInterestTax

      return {
        ...item,
        providerValue: item.priceServiceProvider,
        anticipationTax: providerValue - actualInterestTax,
        value: actualInterestTax,
      }
    })

    const interestPercentage = (
      parseFloat(interestValue / totalPriceServiceProvider) * 100
    ).toFixed(2)

    return {
      itemsWithTax,
      totalPriceServiceProvider: formatCurrency(totalPriceServiceProvider || 0),
      interestValue: formatCurrency(interestValue || 0),
      totalValue: formatCurrency(totalValue || 0),
      interestPercentage: isNaN(interestPercentage) ? 0 : interestPercentage,
    }
  }
  return getItemsWithTaxes()
}
