import { cpf, cnpj } from 'cpf-cnpj-validator'

import { validationEmail } from '_utils/helpers'

const validateCpfCnpj = numbers => {
  let output = false
  const numbersOnly = numbers.replace(/\D+/g, '')

  if (numbersOnly.length === 11) {
    output = cpf.isValid(numbersOnly)
  }

  if (numbersOnly.length === 14) {
    output = cnpj.isValid(numbersOnly)
  }

  return output
}

const APPROVE_BUDGET_FORM_FIELDS = { NAME: 'name', EMAIL: 'email', CPF: 'cpf', PHOTOS: 'photos' }

const NOT_REQUIRED_FIELDS = [APPROVE_BUDGET_FORM_FIELDS.PHOTOS]

const REQUIRED_FIELDS = Object.values(APPROVE_BUDGET_FORM_FIELDS).filter(
  field => !NOT_REQUIRED_FIELDS.includes(field)
)

const REQUIRED_FIELD_MESSAGE = 'Campo Obrigatório'

export const validate = values => {
  const errors = {}

  REQUIRED_FIELDS.forEach(field => {
    if (!values[field]?.length) {
      errors[field] = REQUIRED_FIELD_MESSAGE
    }
  })

  if (!validationEmail(values[APPROVE_BUDGET_FORM_FIELDS.EMAIL])) {
    errors[APPROVE_BUDGET_FORM_FIELDS.EMAIL] = 'Email inválido'
  }

  if (!validateCpfCnpj(values[APPROVE_BUDGET_FORM_FIELDS.CPF])) {
    errors[APPROVE_BUDGET_FORM_FIELDS.CPF] = 'CPF/CNPJ inválido ou não existe'
  }
  return errors
}

export default validate
