import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  buttonsContainer: {
    display: 'flex',
    gap: spacing(16),
  },
  tableContainer: {
    flex: 1,
    margin: '20px 2% 0 34px',
  },
  mainTable: {
    width: 'auto',
    height: '616px',
  },
  grayColumn: {
    backgroundColor: Theme.Colors.Grayscale.Four,
  },
  idColumn: {
    backgroundColor: Theme.Colors.Grayscale.Four,
    color: Theme.Colors.Primary.Base,
  },
}))
