/* eslint-disable spaced-comment */
import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  modal: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  modalContent: {
    width: '100%',
  },
  //Accordion
  accordion: {
    borderRadius: spacing(5),
    width: '100%',
    boxShadow: `${spacing(0, 2, 4)} rgba(0, 0, 0, 0.25)`,
    padding: spacing(24, 20),

    '& .MuiAccordion-root.Mui-expanded': {
      margin: spacing(16, 0, 0),
    },
  },
  summary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '0 !important',

    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  icon: {
    height: spacing(22),
    width: spacing(22),
    marginRight: spacing(10),
    transform: props => (!props.isExpanded ? 'rotate(180deg)' : 'rotate(0)'),
  },
  details: {
    paddingTop: spacing(14),
  },
  totalValueContainer: {
    backgroundColor: Theme.Colors.Grayscale.Four,
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
    padding: '12px 16px',
    borderRadius: '8px',
    marginTop: '8px',
  },
  chip: {
    '& .MuiChip-deleteIcon': {
      color: 'white !important',
    },
  },
  floatingContainer: {
    display: 'flex',
    position: 'absolute',
    left: '20vw',
    transform: 'translateX(-20vw)',
    bottom: '20px',
  },
  floatingWrapper: {
    display: 'flex',
    width: 'auto',
    backgroundColor: '#fff',
    minWidth: spacing(966),
    height: spacing(101),
    borderRadius: spacing(4),
    border: `1px solid ${Theme.Colors.Primary.Base}`,
    boxShadow: ' 0px 1px 4px rgba(0, 0, 0, 0.16)',
  },
  floatingContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: spacing(16, 28, 16, 8),
    marginLeft: spacing(12),
    gap: spacing(8),
  },
  floatingLabels: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(8),
    marginLeft: spacing(4),
  },
  draggable: {
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: spacing(32),
    backgroundColor: Theme.Colors.Primary.Base,

    '&:active': {
      cursor: 'grabbing',
    },
  },
  dotsIcon: {
    fontSize: spacing(20),
  },
  button: {
    padding: spacing(4, '!important'),
    height: 'fit-content',
  },
  redButton: {
    padding: spacing(4, '!important'),
    height: 'fit-content',
    color: `${Theme.Colors.Red.Base} !important`,

    '&:hover': {
      color: `${Theme.Colors.Red.Dark} !important`,
    },
  },
  select: {
    width: '100%',
    padding: '6px 0px',
    fontSize: '16px',
  },
  selectDefault: {
    width: '30%',
  },
  selectEmpty: {
    color: '#ccc',
  },
  inputLabel: {
    color: 'black',
    fontSize: '16px',
    fontWeight: '500',
    display: 'flex',
    gap: '4px',
    marginBottom: '1px',

    '& :after': {
      content: '" *"',
      color: 'red',
      fontSize: '19px',
    },
  },
}))
