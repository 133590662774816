import { createSelector } from 'reselect'
import { CREATE_TRADESMAN_SEQUENCE } from './actions'

const getTradesmanSequencesState = state => state.tradesmanSequences

export const getTradesmanSequenceResults = createSelector(
  getTradesmanSequencesState,
  tradesmanSequences => tradesmanSequences.tradesmanSequenceResults
)

export const getTradesmanSequenceByID =
  sequenceId =>
  ({ tradesmanSequences: { tradesmanSequenceResults } }) =>
    tradesmanSequenceResults.find(sequence => Number(sequence?.id) === Number(sequenceId))

export const createTradesmanSequencesErrorSelector = ({ error }) =>
  error.get(CREATE_TRADESMAN_SEQUENCE.ACTION)?.toJS() || []
