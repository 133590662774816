import React, { useMemo, useCallback, useState, useEffect } from 'react'
import Accordion from '_/components/accordion'
import { useConfirm } from '@refera/ui-web'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import useStyles from './styles'
import { Attachments } from '_/components/refera-ui'

const AddAttachments = ({ agency, isEditing, onChange }) => {
  const styles = useStyles()

  const [dialogMessage, setDialogMessage] = useState('')
  const [toDelete, setToDelete] = useState([])
  const { isConfirmed } = useConfirm()

  const [contracts, setContracts] = useState([])

  useEffect(() => {
    if (agency?.agencyAttachments) {
      setContracts(
        agency?.agencyAttachments.toArray().map(file => ({
          file: file?.get('file'),
          id: file?.get('id'),
        }))
      )
    }
  }, [agency?.agencyAttachments])

  const attachmentsPdf = useMemo(
    () =>
      contracts.filter(file =>
        file instanceof File ? file.type === 'application/pdf' : file.file.indexOf('.pdf') !== -1
      ),
    [contracts]
  )

  const handleRemoveContracts = useCallback(
    async index => {
      setDialogMessage('Você deseja deletar este item?')
      const confirmed = await isConfirmed()
      if (confirmed) {
        const currentFile = attachmentsPdf[index]
        if (currentFile?.id) {
          setToDelete([...toDelete, currentFile.id])
          setContracts(old => old.filter(current => current.id !== currentFile.id))
        } else {
          setContracts(old =>
            old.filter(current => current.lastModified !== currentFile?.lastModified)
          )
        }
      }
    },
    [contracts, setDialogMessage]
  )

  useEffect(() => {
    if (contracts) {
      onChange({ contracts, toDelete })
    }
  }, [contracts, toDelete])

  return (
    <Accordion title="Contratos" className={styles.container}>
      <ConfirmRemoveDialog message={dialogMessage} />
      <Attachments
        label="Contratos"
        content="files"
        downloadable
        readOnly={!isEditing}
        files={attachmentsPdf || []}
        accept={{
          'application/pdf': ['.pdf'],
        }}
        onDrop={files => {
          setContracts(old => [...old, ...files])
        }}
        onRemoveItem={index => handleRemoveContracts(index, true)}
        onItemClick={index => window.open(attachmentsPdf[index].file)}
      />
    </Accordion>
  )
}

export default AddAttachments
