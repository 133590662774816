import { Record, Map } from 'immutable'

import { createReducer } from '_utils/redux'

import {
  GET_BATCHES,
  GET_BUDGET_INSTALLMENTS,
  GET_TRADESMAN_INSTALLMENTS,
  GET_FINANCE_OPTIONS,
  SET_DEFAULT_BUDGET_INSTALLMENTS_FILTER,
  UPDATE_BUDGET_INSTALLMENTS_FILTER,
  GET_FINANCE_GENERIC_PARAMETERS,
  GET_ANTICIPATIONS_BANNER_INFO,
  GET_LIST_BUDGET_RECEIVABLES,
  SET_DEFAULT_BUDGET_RECEIVABLES_FILTER,
  UPDATE_BUDGET_RECEIVABLES_FILTER,
  GET_INSTALLMENT_RULES,
  GET_IS_XML_REQUIRED_TO_FINISH_SERVICE_ORDER,
} from './actions'

const INITIAL_STATE = new Record({
  results: Map(),
  filter: Map({
    agency: [],
    company: [],
    anticipationApproved: '',
    transferDate: '',
    serviceOrderEndDate: '',
    serviceOrderLastEndDate: '',
    paymentDate: '',
    solicitationDate: '',
    totalValue: '',
    serviceOrder: '',
    status: '',
  }),
  filterReceivables: Map({
    agency: '',
    company: [],
    anticipationApproved: '',
    transferDate: '',
    serviceOrderEndDate: '',
    serviceOrderLastEndDate: '',
    paymentDate: '',
    solicitationDate: '',
    totalValue: '',
    serviceOrder: '',
    status: '',
    paymentType: [],
    formOfPayment: '',
    voucherIntervalEnd: '',
    voucherIntervalStart: '',
    dueDateEnd: '',
    dueDateStart: '',
  }),
  isFilterDirty: false,
  options: Map({
    taxRegimes: [],
  }),
  installmentRules: Map(),
  batches: Map(),
  financeGenericParameters: Map(),
  anticipationsBannerInfo: Map(),
  receivables: Map(),
})()

const finance = createReducer(INITIAL_STATE, {
  [GET_BUDGET_INSTALLMENTS.FULFILLED]: (state, { payload }) => {
    return state.update('results', () => Map(payload))
  },
  [GET_INSTALLMENT_RULES.FULFILLED]: (state, { payload }) => {
    return state.update('installmentRules', () => Map(payload))
  },
  [GET_TRADESMAN_INSTALLMENTS.FULFILLED]: (state, { payload }) => {
    return state.update('results', () => Map(payload))
  },
  [SET_DEFAULT_BUDGET_INSTALLMENTS_FILTER]: (state, { payload }) => {
    return state
      .update('filter', prevFilter =>
        Map({
          ...prevFilter.toJS(),
          ...payload,
        })
      )
      .set('isFilterDirty', true)
  },
  [GET_BATCHES.FULFILLED]: (state, { payload }) => {
    return state.update('batches', () => Map(payload))
  },
  [UPDATE_BUDGET_INSTALLMENTS_FILTER]: (state, { payload }) => {
    return state.update('filter', prevFilter => Map({ ...prevFilter.toJS(), ...payload }))
  },
  [GET_FINANCE_OPTIONS.FULFILLED]: (state, { payload }) => {
    return state.update('options', () => Map(payload))
  },

  [GET_FINANCE_GENERIC_PARAMETERS.FULFILLED]: (state, { payload }) => {
    return state.update('financeGenericParameters', () => Map(payload))
  },
  [GET_ANTICIPATIONS_BANNER_INFO.FULFILLED]: (state, { payload }) => {
    return state.update('anticipationsBannerInfo', () => Map(payload))
  },
  [GET_LIST_BUDGET_RECEIVABLES.FULFILLED]: (state, { payload }) => {
    return state.update('receivables', () => Map(payload))
  },
  [SET_DEFAULT_BUDGET_RECEIVABLES_FILTER]: (state, { payload }) => {
    return state
      .update('filterReceivables', prevFilter =>
        Map({
          ...prevFilter.toJS(),
          ...payload,
        })
      )
      .set('isFilterDirty', true)
  },
  [UPDATE_BUDGET_RECEIVABLES_FILTER]: (state, { payload }) => {
    return state.update('filterReceivables', prevFilter =>
      Map({ ...prevFilter.toJS(), ...payload })
    )
  },
  [GET_IS_XML_REQUIRED_TO_FINISH_SERVICE_ORDER.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      isXmlRequiredToFinishServiceOrder: payload,
    }
  },
})

export default finance
