import React, { useEffect, useMemo } from 'react'
import { Controller as ControllerHookForm, useFormContext } from 'react-hook-form'

import { InputLabel, FormControl } from '@material-ui/core'
import { FormHelperText, Input, TextField } from '@mui/material'

import classNames from 'classnames'

import generalInputStyles from './styles'
import { NumericFormat as NumberFormat } from 'react-number-format'

export function Root({ children, name, defaultValue = '', style = {}, containerClasses, ...rest }) {
  const generalStyles = generalInputStyles()

  const { setValue } = useFormContext()

  useEffect(() => {
    setValue(name, defaultValue)
  }, [defaultValue, name, setValue])

  return (
    <FormControl
      style={{ ...style }}
      className={classNames(generalStyles.rootInput, containerClasses ?? null)}
      {...rest}
    >
      {children}
    </FormControl>
  )
}

export function Label({ children, name, textArea = false, labelClasses, required, ...rest }) {
  const generalStyles = generalInputStyles()
  const { errors } = useFormContext()

  return (
    <InputLabel
      variant="standard"
      id={`${name}-label`}
      style={{ color: errors?.[name] ? '#C90000' : null }}
      className={classNames(
        textArea ? generalStyles.inputLabelTextArea : generalStyles.inputLabel,
        labelClasses ?? null
      )}
      {...rest}
    >
      {children}
      {required && <span className={generalStyles.required}>*</span>}
    </InputLabel>
  )
}

export function LabelTextarea({ children, name, labelClasses, required, ...rest }) {
  const generalStyles = generalInputStyles()
  const { errors } = useFormContext()

  return (
    <InputLabel
      variant="standard"
      id={`${name}-label`}
      style={{ color: errors?.[name] ? '#C90000' : null, marginTop: '-39px' }}
      className={classNames(generalStyles.inputLabelTextArea, labelClasses ?? null)}
      {...rest}
    >
      {children}
      {required && <span className={generalStyles.required}>*</span>}
    </InputLabel>
  )
}

export function ControllerText({
  rules,
  textArea = false,
  required = false,
  name,
  defaultValue,
  ...rest
}) {
  const generalStyles = generalInputStyles()

  const { control, errors } = useFormContext()

  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      as={
        <TextField
          id={name}
          variant="standard"
          defaultValue={defaultValue}
          error={!!errors?.[name]}
          required={required}
          InputProps={{
            style: {
              fontSize: 16,
              borderRadius: '8px',
              border: `${textArea ? '1px' : 0} solid ${errors?.[name] ? '#C90000' : '#717171'}`,
            },
            maxLength: rules?.maxLength?.value || rules?.maxLength || 255,
          }}
          className={textArea ? generalStyles.textArea : generalStyles.textFieldInput}
          {...rest}
        />
      }
    />
  )
}

export function ControllerTextarea({ rules, required = false, name, defaultValue, ...rest }) {
  const generalStyles = generalInputStyles()

  const { control, errors } = useFormContext()

  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      as={
        <TextField
          id={name}
          variant="standard"
          defaultValue={defaultValue}
          error={!!errors?.[name]}
          required={required}
          InputProps={{
            style: {
              fontSize: 16,
              borderRadius: '8px',
              border: `1px solid ${errors?.[name] ? '#C90000' : '#717171'}`,
            },
            maxLength: rules?.maxLength?.value || rules?.maxLength || 255,
          }}
          className={generalStyles.textArea}
          {...rest}
        />
      }
    />
  )
}

export function ControllerNumber({ rules, name, defaultValue, classes, ...rest }) {
  const generalStyles = generalInputStyles()

  const { control, errors } = useFormContext()

  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      as={
        <NumberFormat
          error={errors[name]}
          errorMessage={errors[name]?.message}
          customInput={Input}
          thousandSeparator="."
          decimalSeparator=","
          fixedDecimalScale
          allowNegative={false}
          allowLeadingZeros={false}
          fullWidth
          decimalScale={2}
          className={classNames(generalStyles.inputValue, classes ?? null)}
          {...rest}
        />
      }
    />
  )
}

export function ErrorMessage({ customErrorMessage, classes, name, ...rest }) {
  const generalStyles = generalInputStyles()

  const { errors } = useFormContext()

  const errorMessage = useMemo(
    () => errors?.[`${name}`]?.message || customErrorMessage,
    [errors?.[name], customErrorMessage]
  )

  return (
    <FormHelperText
      className={classNames(generalStyles.errorMessage, classes ?? null)}
      error={!!errorMessage}
      {...rest}
    >
      {errorMessage}
    </FormHelperText>
  )
}
