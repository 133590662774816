/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core'
import { Danger } from 'iconsax-react'

import Button from '_components/button'

import useStyles from './styles'

const DANGER = 'Danger'

export const WARNING_MODAL = { icon: 'Danger', title: 'Atenção!' }

export default function RatingModal(props) {
  const { ratingModal, setRatingModal } = props
  const classes = useStyles()

  function GetIconButton() {
    if (ratingModal.type?.icon === DANGER)
      return (
        <IconButton disableRipple className={classes.titleIconWarning}>
          <Danger className={classes.iconeSVG} />
        </IconButton>
      )
  }

  return (
    <Dialog open={ratingModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Box mt={10} />
        <GetIconButton />
        <Typography variant="h6">{ratingModal.title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="">{ratingModal.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setRatingModal({ ...ratingModal, isOpen: false })}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
