/* eslint-disable react/jsx-boolean-value */
import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { DateTimePicker } from '@refera/ui-web'
import { Attachments } from '_components/refera-ui'
import { Grid } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'

import useStyles from './styles'
import PreviewComponent from './preview-component'

const DEFAULT_PREVIEW_STATE = {
  visible: false,
  selectedIndex: undefined,
}

const ExecutionForm = ({
  executionProofs,
  invoices,
  handleDropExecutionProofs,
  handleRemoveExecutionProof,
  isEditing,
}) => {
  const styles = useStyles()
  const [previewState, setPreviewState] = useState(DEFAULT_PREVIEW_STATE)

  const { control, watch, setValue } = useFormContext()
  const datetimeExecutionScheduledWatch = watch('datetimeExecutionScheduled')

  const handlePreviewClose = useCallback(() => {
    setPreviewState(DEFAULT_PREVIEW_STATE)
  }, [])

  const handleClickExecutionProof = useCallback(index => {
    setPreviewState(() => ({
      visible: true,
      selectedIndex: index,
    }))
  }, [])

  const fillDates = useCallback(date => {
    if (date === 'executedAt') {
      if (!watch('datetimeExecutionScheduled')) {
        setValue('datetimeExecutionScheduled', watch('executedAt'))
      }
      if (!watch('dateAndTimeFinish')) {
        setValue('dateAndTimeFinish', watch('executedAt'))
      }
    } else if (date === 'datetimeFinished') {
      if (!watch('datetimeExecutionScheduled')) {
        setValue('datetimeExecutionScheduled', watch('datetimeFinished'))
      }
      if (!watch('dateAndTimeFinish')) {
        setValue('dateAndTimeFinish', watch('datetimeFinished'))
      }
      if (!watch('executedAt')) {
        setValue('executedAt', watch('datetimeFinished'))
      }
    }
  }, [])

  const DATES_LABELS = useMemo(
    () => [
      {
        label: 'Agendamento da execução',
        value: 'datetimeExecutionScheduled',
        minDate: null,
      },
      {
        label: 'Expectativa de finalização do serviço',
        value: 'dateAndTimeFinish',
        minDate: moment(datetimeExecutionScheduledWatch),
      },
      {
        label: 'Data do serviço finalizado',
        value: 'executedAt',
      },
      {
        label: 'Data do chamado finalizado',
        value: 'datetimeFinished',
        minDate: moment().startOf('day'),
      },
    ],
    [datetimeExecutionScheduledWatch]
  )

  const handleDownloadInvoice = index => {
    window.open(invoices[index]?.file)
  }

  return (
    <>
      <PreviewComponent
        executionProofs={executionProofs}
        handleClose={handlePreviewClose}
        open={previewState.visible}
        selectedIndex={previewState.selectedIndex}
      />
      <form className={styles.content} id="execution-form">
        <Grid className={styles.datesContainer}>
          {DATES_LABELS.map(date => {
            return (
              watch(date.value) && (
                <Grid key={date.label}>
                  <Grid className={styles.dateField}>
                    <Controller
                      control={control}
                      name={date.value}
                      as={
                        <DateTimePicker
                          label={date.label}
                          readOnly
                          variant="inline"
                          format="dd/MM/yyyy  HH:mm"
                          placeholder="DD/MM/AAAA HH:MM"
                          disabled
                          invalidDateMessage="Insira uma data válida"
                          refuse={/[^\d\\.]+/gi}
                          minDate={date.minDate}
                          onAccept={() => fillDates(date.value)}
                          inputProps={{
                            onBlur: () => fillDates(date.value),
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              )
            )
          })}
        </Grid>
        <Grid className={styles.attachmentsContainer}>
          <Grid>
            {executionProofs && (
              <Attachments
                label="Fotos do pós-obra"
                readOnly={!isEditing}
                downloadable
                files={executionProofs}
                accept={{
                  'image/jpeg': [
                    '.jpeg',
                    '.png',
                    '.jpg',
                    '.bmp',
                    '.webp',
                    '.mkv',
                    '.mp4',
                    '.mov',
                    '.avi',
                    '.m4v',
                    '.mpg',
                    '.mpeg',
                    '.wmv',
                    '.webm',
                  ],
                }}
                onDrop={handleDropExecutionProofs}
                onRemoveItem={handleRemoveExecutionProof}
                onItemClick={handleClickExecutionProof}
              />
            )}
          </Grid>
          <Grid className={styles.attachmentsContainer}>
            <Attachments
              label="Nota fiscal"
              content="files"
              readOnly={true}
              files={invoices}
              multiple
              downloadable
              onItemClick={handleDownloadInvoice}
              accept={{
                'application/pdf': ['.pdf'],
                'application/xml': ['.xml'],
              }}
            />
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default ExecutionForm
