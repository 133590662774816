import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  deadlineIcon: {
    verticalAlign: spacing(-3),
  },
  dot: {
    display: 'block',
    width: spacing(8),
    height: spacing(8),
    background: Theme.Colors.Grayscale.ThirtyTwo,
    borderRadius: '50%',
  },
  greenDot: {
    background: Theme.Colors.Green.Base,
  },
  yellowDot: {
    background: Theme.Colors.Yellow.Base,
  },
}))
