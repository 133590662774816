/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import { Dialog } from '@material-ui/core'
import PropTypes from 'prop-types'

import useStyles from './styles'

const FullPictureModal = ({ imageURL, open, closeModal }) => {
  const styles = useStyles()

  // closeModal must be a function that switches state
  // from false to true and vice-versa.

  const checkImg = file => {
    // <img> tag supports URL only. If imageURL arrives as object, this function will
    // convert the file into usable URL so it can be displayed before being uploaded.
    if (typeof file === 'object') {
      return URL.createObjectURL(file)
    }
    return file
  }

  const checkFormat = file => {
    if (typeof file === 'string') {
      if (
        file.includes('.mp4') ||
        file.includes('.flv') ||
        file.includes('.mkv') ||
        file.includes('.mov')
      ) {
        return true
      }
    }

    return false
  }

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      closeModal()
    }
    return null
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        {checkFormat(imageURL) ? (
          <video controls className={styles.videoPlayer}>
            <source src={checkImg(imageURL)} type="video/mp4" />
          </video>
        ) : (
          <img className={styles.contentImg} src={checkImg(imageURL)} alt="Attachment" />
        )}
      </Dialog>
    </>
  )
}

FullPictureModal.propTypes = {
  imageURL: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default FullPictureModal
