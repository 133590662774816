import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  content: {
    padding: spacing(32, 40),
  },
  header: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(0, 32),
    height: spacing(62),
    width: '100%',
  },

  hline: {
    position: 'absolute',
    width: '100%',
    height: 'opx',
    left: '0px',
    top: '56px',
    border: '1px solid #E3E3E3',
  },

  iconButton: { width: spacing(20), height: spacing(20) },
  buttons: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    width: spacing(20),
    height: spacing(20),
    '& .MuiSvgIcon-root': {
      width: spacing(20),
      height: spacing(20),
    },
    fill: '#696969',
  },
  title: {
    fontSize: spacing(16),
    lineHeight: spacing(24),
    letterSpacing: spacing(-0.1),
    color: '#1671ED',
    fontWeight: 'bold',
  },

  actions: {
    '&.MuiDialogActions-root': {
      padding: spacing(0, 40, 40, 40),
      justifyContent: 'space-around',
    },
  },
  button: {
    width: spacing(88),
  },
  textButton: {
    width: spacing(88),
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    textTransform: 'uppercase',
    color: palette.gray[64],
  },
  textButtonActive: {
    marginLeft: 'auto',
    color: palette.primary.main,
    '&:hover': {
      background: 'white',
    },
  },
  editButton: {
    color: 'white',
    borderColor: 'white',
    marginRight: spacing(32),
  },
}))
