import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Datagrid } from '@refera/ui-web'
import { Grid } from '@mui/material'
import Theme from '@refera/ui-core'

import { useDispatch } from 'react-redux'

import { FINANCE_ROUTES } from '_/utils/constants'
import { navigate } from '@reach/router'

import HeaderTitle from '_/components/header-title'
import Svg, { ICON } from '_/components/svg'

import { getLogChargeFilesList, uploadChargeFile } from '_/modules/finance/actions'
import { useToast } from '_/hooks/use-toast'

import useStyles from '../styles'
import { TABLE_SX } from '../../utils/constants'
import { fileErrorUploadFileType, ReturnFilesColumns } from '../utils/constants'
import { formatErrorMessage } from '../../utils/FormatErrorMessage'

export function ChargeFiles() {
  const styles = useStyles()
  const dispatch = useDispatch()

  const fileInputRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState('')
  const [pageSize, setPageSize] = useState(10)
  const [chargeFiles, setChargeFiles] = useState([])

  const { showToast } = useToast()

  const handleGetChargeFilesList = async () => {
    setIsLoading(true)
    await dispatch(
      getLogChargeFilesList({
        page: page + 1,
        pageSize,
        orderBy,
      })
    )
      .then(res => {
        setChargeFiles(res)
      })
      .catch(() => showToast({ message: 'Ocorreu um erro ao buscar registros', type: 'error' }))
    setIsLoading(false)
  }

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async event => {
    setIsLoading(true)
    const file = event.target.files[0]

    if (file && file?.name?.toLowerCase().endsWith('.xlsx')) {
      const payload = { file }
      await dispatch(uploadChargeFile(payload))
        .then(() => {
          showToast({ type: 'success' })
          setIsLoading(false)
          handleGetChargeFilesList()
        })
        .catch(error => {
          showToast({ type: 'error', message: formatErrorMessage(error) })
          setIsLoading(false)
        })
    } else {
      showToast({ message: fileErrorUploadFileType, type: 'error' })
    }
    fileInputRef.current.value = ''
    setIsLoading(false)
  }

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('processingDate')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const handleRowClick = processingDate => {
    if (processingDate) {
      const formattedDate = processingDate.replace('.', '@')
      navigate(`${FINANCE_ROUTES.CHARGE_FILES}/${formattedDate}`)
    }
  }

  useEffect(() => {
    handleGetChargeFilesList()
  }, [page, pageSize, orderBy])

  return (
    <>
      <HeaderTitle
        title="Arquivos de Cobrança"
        backButtonAction={() => navigate(FINANCE_ROUTES.MANAGE_INSTALLMENTS)}
      >
        <Button
          variant="ghost"
          onClick={handleFileClick}
          startIcon={
            <Svg
              type={ICON.DOWNLOAD}
              color={Theme.Colors.Primary.Base}
              style={{ rotate: '180deg' }}
            />
          }
        >
          <input
            type="file"
            ref={fileInputRef}
            accept=".xlsx"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          Fazer upload de novo arquivo
        </Button>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            loading={isLoading}
            rows={chargeFiles?.results?.length ? chargeFiles?.results : []}
            columns={ReturnFilesColumns}
            paginationMode="server"
            rowCount={chargeFiles?.count}
            page={page}
            pageSize={pageSize}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            onSortModelChange={handleOrderBy}
            onRowClick={params => handleRowClick(params.row.processingDate)}
            sx={TABLE_SX}
          />
        </Grid>
      </Grid>
    </>
  )
}
