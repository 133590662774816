import React from 'react'
import { Button, Loader } from '@refera/ui-web'
import { Typography } from '@material-ui/core'
import Svg from '_components/svg'
import Tick from '_assets/icons/ic-tick.svg'
import Cross from '_assets/icons/ic-close-red-sharp.svg'
import { navigate } from '@reach/router'

import useStyles from './styles'
import { companySelector } from '_/modules/company/selector'
import { useSelector } from 'react-redux'

const resultProps = (props = {}) => {
  return {
    success: {
      icon: Tick,
      title: 'Contrato gerado com sucesso',
      message: (
        <>
          O contrato será enviado para os emails indicados na etapa anterior para avaliação e
          assinatura.
          <br />
          <br />
          Se seu email não é um dos informados na etapa anterior, ainda assim você receberá uma
          cópia do contrato para análise.
        </>
      ),
    },
    successCondolivre: {
      icon: Tick,
      title: 'Documentos enviados',
      message: `Seus dados e documentos foram enviados para análise da empresa, e você receberá as atualizações de tal análise pelo seu email ${props?.contactEmail}.`,
    },
    error: {
      icon: Cross,
      title: 'Erro na emissão do contrato',
      message: (
        <>
          Erro na emissão do contrato
          <br />
          <br />
          Ocorreu um erro inesperado. Por favor, tente novamente mais tarde ou contacte a Refera.
        </>
      ),
    },
  }
}

export function GenerateContractResultPage({ returnToMobile = false, result }) {
  const styles = useStyles()
  const company = useSelector(companySelector)

  const handleButtonClick = () => {
    if (returnToMobile) {
      window.location.href = 'referaproapp://home'
    }

    navigate('/')
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '16px',
        padding: '0 24px',
      }}
    >
      {result ? (
        <>
          <div
            style={{
              borderRadius: '50%',
              padding: '16px',
              backgroundColor: result === 'error' ? '#FBEBEB' : '#ECF4FE',
            }}
          >
            <Svg icon={resultProps()[result]?.icon} className={styles.svg} />
          </div>
          <Typography
            component="h2"
            className={styles.resultTitle}
            style={result === 'error' ? { color: '#C90000' } : {}}
          >
            {resultProps()[result]?.title}
          </Typography>
          <Typography component="p" className={styles.resultMessage}>
            {resultProps({ contactEmail: company.contactEmail })[result]?.message}
          </Typography>
          <Button style={{ padding: '10px 31px', marginTop: '8px' }} onClick={handleButtonClick}>
            Ok, entendi
          </Button>
        </>
      ) : (
        <Loader open />
      )}
    </div>
  )
}
