import humps from 'humps'
import { defineAction } from '_utils/redux'
import * as groups from '_/services/groups'

export const GET_GROUP = defineAction('GET_GROUP')
export const GET_GROUP_DATA_PERMISSION = defineAction('GET_GROUP_DATA_PERMISSION')
export const GET_GROUP_ACTION_PERMISSION = defineAction('GET_GROUP_ACTION_PERMISSION')
export const PATCH_GROUP_PERMISSION = defineAction('PATCH_GROUP_PERMISSION')

export const getGroupById = id => async (dispatch, getState) => {
  const data = await groups.getGroupById(getState().user.authToken)(id)

  dispatch({
    type: GET_GROUP.ACTION,
    payload: async () => data,
  })

  return data
}

export const getGroupDataPermissionList = (id, params) => async (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = await groups.getGroupPermission(getState().user.authToken)(id, {
    type: 'data',
    ...formattedParams,
  })

  dispatch({
    type: GET_GROUP_DATA_PERMISSION.ACTION,
    payload: async () => data,
    meta: params,
  })

  return data
}

export const getGroupActionPermissionList = (id, params) => async (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = await groups.getGroupPermission(getState().user.authToken)(id, {
    type: 'action',
    ...formattedParams,
  })

  dispatch({
    type: GET_GROUP_ACTION_PERMISSION.ACTION,
    payload: async () => data,
    meta: params,
  })

  return data
}

export const updateGroupPermission = payload => async (dispatch, getState) => {
  const data = await groups.patchGroupPermission(getState().user.authToken)(payload)

  dispatch({
    type: PATCH_GROUP_PERMISSION.ACTION,
    payload: async () => data,
  })

  return data
}
