import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import classnames from 'classnames'

import Textfield from '_components/textfield'
import Select from '_components/select'
import { bankOptionSelector } from '_modules/utils/selectors'
import { BANK_ACCOUNT_TYPE_OPTIONS, KEY_PIX_OPTIONS } from '_utils/constants'
import {
  phoneNumberMask,
  cpfCnpjMask,
  phoneNumberMaskServiceOrder,
  formatPhoneValueMask,
} from '_utils/helpers'
import useToggle from '_hooks/use-toggle'
import { agencySelector } from '_modules/agency/selectors'
import Button from '_components/button'
import Svg, { ICON } from '_components/svg'

import ConfirmValueChangeModal from './confirm-value-change-modal'
import useStyles from './styles'

const FIELDS_WITH_OPTIONS = {
  BANK: 'bank',
  PIX_KEY_CHOICE: 'pixKeyChoice',
  BANK_ACCOUNT_TYPE: 'bankAccountType',
  CNPJ: 'cnpj',
  BANK_ACCOUNT_CPF_CNPJ: 'bankAccountCpfCnpj',
  PHONE: 'phone',
  CPF_CNPJ: 'cpfCnpj',
  CPF: 'cpf',
  CONTACT_CPF_CNPJ: 'contactCpfCnpj',
  CONTACT_NUMBER: 'contactNumber',
  FIRST_CONTACT_SECONDARY: 'firstContactSecondary',
  SECOND_CONTACT_SECONDARY: 'secondContactSecondary',
  PHONE_NUMBER: 'phoneNumber',
  SUPPORT_PHONE: 'supportPhone',
  SUPPORT_WHATSAPP: 'supportWhatsapp',
}

const InformationWrapper = ({
  isEditing,
  value,
  label,
  placeholder,
  options,
  className,
  id,
  isModalInfo,
  setFieldValue,
  numberPhoneQuantity,
  addPhoneQuantity,
  phoneList,
  removePhone,
  ...props
}) => {
  const bankOptions = useSelector(bankOptionSelector)
  const currentAgency = useSelector(agencySelector)
  const styles = useStyles({ hasPlaceholder: !value && placeholder.length })
  const [isConfirmationOpen, toggleConfirmation] = useToggle()

  const setOptions = useMemo(
    () =>
      id === FIELDS_WITH_OPTIONS.BANK
        ? bankOptions.sort((a, b) => a.label.localeCompare(b.label))
        : options,
    [bankOptions, id, options]
  )

  const renderLabel = useMemo(() => {
    if (options?.length) {
      return options.find(option => option.value === value)?.label
    }
    switch (id) {
      case FIELDS_WITH_OPTIONS.BANK:
        return bankOptions?.find(option => option.value === Number(value))?.label
      case FIELDS_WITH_OPTIONS.PIX_KEY_CHOICE:
        return KEY_PIX_OPTIONS.find(item => item.value === value)?.label
      case FIELDS_WITH_OPTIONS.BANK_ACCOUNT_TYPE:
        return BANK_ACCOUNT_TYPE_OPTIONS.find(item => item.value === value)?.label
      case FIELDS_WITH_OPTIONS.CNPJ:
      case FIELDS_WITH_OPTIONS.CPF_CNPJ:
      case FIELDS_WITH_OPTIONS.BANK_ACCOUNT_CPF_CNPJ:
      case FIELDS_WITH_OPTIONS.CPF:
      case FIELDS_WITH_OPTIONS.CONTACT_CPF_CNPJ:
        return cpfCnpjMask(value)
      case FIELDS_WITH_OPTIONS.PHONE:
      case FIELDS_WITH_OPTIONS.CONTACT_NUMBER:
      case FIELDS_WITH_OPTIONS.FIRST_CONTACT_SECONDARY:
      case FIELDS_WITH_OPTIONS.SECOND_CONTACT_SECONDARY:
      case FIELDS_WITH_OPTIONS.PHONE_NUMBER:
        if (numberPhoneQuantity) {
          return phoneNumberMaskServiceOrder(value)
        }
        return phoneNumberMask(value)
      default:
        return value
    }
  }, [bankOptions, id, options, value])

  const handleConfirmationClose = useCallback(() => {
    toggleConfirmation()
  }, [toggleConfirmation])

  const onAddNewPhone = useCallback(() => {
    addPhoneQuantity()
  }, [addPhoneQuantity])

  const onRemovePhone = useCallback(() => {
    removePhone(props.name)
  }, [removePhone])

  const renderTypeInput = useMemo(() => {
    if (isEditing) {
      if (setOptions?.length) {
        return (
          <Select
            IconComponent={KeyboardArrowDownIcon}
            placeholder={placeholder}
            value={value}
            options={setOptions}
            className={styles.select}
            {...props}
          />
        )
      }
      if (phoneList?.includes(props.name)) {
        return (
          <Grid container xs={12} direction="column">
            <Textfield
              placeholder={placeholder}
              value={value}
              numberPhoneQuantity={numberPhoneQuantity}
              onClick={onRemovePhone}
              {...props}
            />
            {numberPhoneQuantity < 3 && (
              <Button className={styles.iconBtn} onClick={onAddNewPhone}>
                <Svg className={styles.icon} type={ICON.ADD_CIRCLE} />
                Adicionar outro número
              </Button>
            )}
          </Grid>
        )
      }
      if (['firstContactSecondary', 'secondContactSecondary'].includes(props.name)) {
        return (
          <Grid container xs={12} direction="column">
            <Textfield
              placeholder={placeholder}
              value={value}
              numberPhoneQuantity={numberPhoneQuantity}
              onClick={onRemovePhone}
              {...props}
            />
          </Grid>
        )
      }

      if (
        [
          FIELDS_WITH_OPTIONS.SUPPORT_PHONE,
          FIELDS_WITH_OPTIONS.SUPPORT_WHATSAPP,
          FIELDS_WITH_OPTIONS.PHONE_NUMBER,
        ].includes(props.name)
      ) {
        return (
          <Textfield
            placeholder={placeholder}
            value={value}
            {...props}
            onChange={e => setFieldValue(props.name, formatPhoneValueMask(e.target.value))}
          />
        )
      }

      return <Textfield placeholder={placeholder} value={value} {...props} />
    }
    if (['contactNumber', 'firstContactSecondary', 'secondContactSecondary'].includes(props.name)) {
      return props.name === 'secondContactSecondary' ? (
        <Typography className={styles.value} component="p" variant={isModalInfo ? 'h5' : 'body1'}>
          <div>{phoneNumberMaskServiceOrder(props.valuesPhones[0])}</div>
          <div>{phoneNumberMaskServiceOrder(props.valuesPhones[1])}</div>
          <div>{phoneNumberMaskServiceOrder(props.valuesPhones[2])}</div>
        </Typography>
      ) : props.name === 'firstContactSecondary' ? (
        <Typography className={styles.value} component="p" variant={isModalInfo ? 'h5' : 'body1'}>
          <div>{phoneNumberMaskServiceOrder(props.valuesPhones[0])}</div>
          <div>{phoneNumberMaskServiceOrder(props.valuesPhones[1])}</div>
        </Typography>
      ) : (
        props.name === 'contactNumber' && (
          <Typography className={styles.value} component="p" variant={isModalInfo ? 'h5' : 'body1'}>
            {phoneNumberMaskServiceOrder(props.valuesPhones[0])}
          </Typography>
        )
      )
    }

    if (
      [
        FIELDS_WITH_OPTIONS.SUPPORT_PHONE,
        FIELDS_WITH_OPTIONS.SUPPORT_WHATSAPP,
        FIELDS_WITH_OPTIONS.PHONE_NUMBER,
      ].includes(props.name)
    ) {
      return (
        <Typography className={styles.value} component="p" variant={isModalInfo ? 'h5' : 'body1'}>
          {formatPhoneValueMask(value)}
        </Typography>
      )
    }
    return (
      <Typography className={styles.value} component="p" variant={isModalInfo ? 'h5' : 'body1'}>
        {renderLabel || placeholder}
      </Typography>
    )
  }, [
    currentAgency,
    id,
    isEditing,
    isModalInfo,
    placeholder,
    props,
    renderLabel,
    setOptions,
    styles.select,
    styles.value,
    value,
    numberPhoneQuantity,
  ])

  return (
    <Grid className={classnames(styles.wrapper, className)}>
      <Typography
        className={classnames(styles.title, { [styles.modalLabel]: isModalInfo })}
        component="h2"
        variant={isModalInfo ? 'body1' : 'body2'}
      >
        {label}
      </Typography>
      {renderTypeInput}

      {isConfirmationOpen && (
        <ConfirmValueChangeModal
          isOpen={isConfirmationOpen}
          handleClose={handleConfirmationClose}
          description={`Você tem certeza que deseja alterar a ${label}?`}
          fieldId={id}
          setValue={setFieldValue}
          value={currentAgency[id]}
        />
      )}
    </Grid>
  )
}

InformationWrapper.propTypes = {
  isEditing: PropTypes.bool,
  isModalInfo: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  id: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  error: PropTypes.bool,
}
InformationWrapper.defaultProps = {
  isEditing: false,
  placeholder: '',
  className: '',
  options: [],
  isModalInfo: false,
  error: false,
}

export default React.memo(InformationWrapper)
