import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'grid',
    gridRowGap: spacing(24),
  },
  label: {
    fontSize: '1.4rem',
    paddingRight: spacing(10),
    lineHeight: spacing(20),
    color: palette.gray[64],
  },
  modal: {
    width: '100vw',
  },
  modalText: {
    fontSize: '1.6rem',
    textAlign: 'center',
  },
}))
