import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: spacing(75),
    width: '100%',
    paddingLeft: spacing(71),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: `${Theme.Typography.FontSize.Large}px`,
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    paddingLeft: spacing(34),
    paddingRight: '2%',
    height: spacing(72),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
    gap: '8px',
  },
  tableContainer: {
    flex: 1,
    margin: '20px 2% 20px 34px',
  },
  mainTable: {
    width: 'auto',
    height: '616px',
  },
  idColumn: {
    color: Theme.Colors.Primary.Base,
  },
  serviceOrderCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(4),
    borderRadius: 16,
    backgroundColor: Theme.Colors.Primary.Lightest,
  },
  greenColor: {
    color: Theme.Colors.Green.Base,
  },
  redColor: {
    color: Theme.Colors.Red.Base,
  },
  statusCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(4, 8),
    borderRadius: 16,
    backgroundColor: Theme.Colors.Grayscale.Four,
    color: Theme.Colors.Grayscale.SixtyFour,
    fontSize: Theme.Typography.FontSize.XXSmall,
  },
  statusCellDot: {
    marginRight: 8,
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: Theme.Colors.Yellow.Base,
  },
  greenBackground: {
    backgroundColor: Theme.Colors.Green.Base,
  },
  wasntViewed: {
    fontWeight: 500,
  },
}))

export const datagridStyle = {
  fontSize: Theme.Typography.FontSize.XXSmall,

  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
}
