import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  button: {
    textDecoration: 'underline',
    fontWeight: '500',
    marginTop: spacing(16),
  },
  select: {
    minWidth: '500px',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: '500px',
  },
  filter: {
    marginBottom: '25px',
  },
  error: {
    marginTop: '5px',
    fontSize: '12px',
    color: 'red',
  },
}))
