import humps from 'humps'
import { defineAction } from '_utils/redux'
import * as helpRequestsServices from '_services/help-requests'

export const GET_HELP_REQUESTS = defineAction('GET_HELP_REQUESTS')
export const GET_HELP_REQUESTS_BY_SERVICE_ORDER_ID = defineAction(
  'GET_HELP_REQUESTS_BY_SERVICE_ORDER_ID'
)
export const GET_HELP_REQUEST_BY_ID = defineAction('GET_HELP_REQUEST_BY_ID')
export const EDIT_HELP_REQUEST = defineAction('EDIT_HELP_REQUEST')
export const GET_HELP_REQUEST_PROCEDURES = defineAction('GET_HELP_REQUEST_PROCEDURES')
export const CREATE_HELP_REQUEST_PROCEDURE = defineAction('CREATE_HELP_REQUEST_PROCEDURE')
export const DELETE_PROCEDURE_REQUEST = defineAction('DELETE_PROCEDURE_REQUEST')
export const EDIT_HELP_REQUEST_PROCEDURE = defineAction('EDIT_HELP_REQUEST_PROCEDURE')
export const UPDATE_HELP_REQUEST_FILTER = 'UPDATE_HELP_REQUEST_FILTER'
export const SET_DEFAULT_HELP_REQUEST_FILTER = 'SET_DEFAULT_HELP_REQUEST_FILTER'
export const DELETE_PROCEDURE_ATTACHMENT = defineAction('DELETE_PROCEDURE_ATTACHMENT')
export const CLEAR_FEEDBACK_MESSAGE = 'CLEAR_FEEDBACK_MESSAGE'
export const UPDATE_AVAILABILITY = defineAction('UPDATE_AVAILABILITY')
export const VERIFY_STEP_LOG = defineAction('VERIFY_STEP_LOG')

export const getHelpRequests = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = helpRequestsServices.getHelpRequests(getState().user.authToken)({
    ...formattedParams,
  })

  dispatch({
    type: GET_HELP_REQUESTS.ACTION,
    payload: data,
  })
  return data
}

export const getHelpRequestsByServiceOrderId = serviceOrderId => (dispatch, getState) => {
  const data = helpRequestsServices.getHelpRequestsByServiceOrderId(getState().user.authToken)(
    serviceOrderId
  )

  dispatch({
    type: GET_HELP_REQUESTS_BY_SERVICE_ORDER_ID.ACTION,
    payload: data,
  })
}

export const getHelpRequestById = requestId => (dispatch, getState) => {
  const data = helpRequestsServices.getHelpRequestById(getState().user.authToken)(requestId)
  dispatch({
    type: GET_HELP_REQUEST_BY_ID.ACTION,
    payload: data,
  })
  return data
}
export const editHelpRequest = (helpRequestId, payload) => (dispatch, getState) => {
  const data = helpRequestsServices.editHelpRequest(getState().user.authToken)(
    helpRequestId,
    payload
  )
  dispatch({
    type: EDIT_HELP_REQUEST.ACTION,
    payload: data,
  })
}

export const getHelpRequestPendings = helpRequestId => (dispatch, getState) => {
  const data = helpRequestsServices.getHelpRequestPendings(getState().user.authToken)(helpRequestId)
  dispatch({
    type: GET_HELP_REQUEST_PROCEDURES.ACTION,
    payload: data,
  })
}

export const postHelpRequestPendings = (payload, helpRequestId) => (dispatch, getState) => {
  const data = helpRequestsServices.createHelpRequestPendings(
    getState().user.authToken,
    payload,
    helpRequestId
  )

  dispatch({
    type: CREATE_HELP_REQUEST_PROCEDURE.ACTION,
    payload: data,
  })

  return data
}

export const editHelpRequestPendings =
  (payload, helpRequestId, procedureId) => (dispatch, getState) => {
    const data = helpRequestsServices.editHelpRequestPendings(getState().user.authToken)(
      payload,
      helpRequestId,
      procedureId
    )

    dispatch({
      type: EDIT_HELP_REQUEST_PROCEDURE.ACTION,
      payload: data,
    })

    return data
  }

export const deletePendingRequest = (helpRequestId, procedureId) => (dispatch, getState) => {
  const data = helpRequestsServices.deletePendingRequest(getState().user.authToken)(
    helpRequestId,
    procedureId
  )

  dispatch({
    type: DELETE_PROCEDURE_REQUEST.ACTION,
    payload: data,
    meta: {
      helpRequestId,
      procedureId,
    },
  })

  return data
}

export const deletePendingAttachment =
  (helpRequest, procedureId, attachmentId) => (dispatch, getState) => {
    dispatch({
      type: DELETE_PROCEDURE_ATTACHMENT.ACTION,
      payload: helpRequestsServices.deletePendingAttachment(getState().user.authToken)(
        helpRequest,
        procedureId,
        attachmentId
      ),
      meta: { attachmentId },
    })
  }

export const setDefaultHelpRequestFilter = payload => dispatch => {
  dispatch({
    type: SET_DEFAULT_HELP_REQUEST_FILTER,
    payload,
  })
}

export const updateHelpRequestFilter = payload => dispatch => {
  dispatch({
    type: UPDATE_HELP_REQUEST_FILTER,
    payload,
  })
}

export const updateHelpRequestAvailability = (helpRequestId, payload) => (dispatch, getState) => {
  const data = helpRequestsServices.updateHelpRequestAvailability(getState().user.authToken)(
    helpRequestId,
    humps.decamelizeKeys(payload)
  )

  dispatch({
    type: UPDATE_AVAILABILITY.ACTION,
    payload: data,
  })

  return data
}

export const clearFeedbackMessage = () => dispatch => {
  dispatch({
    type: CLEAR_FEEDBACK_MESSAGE,
  })
}

export const verifyStepLog = helpRequestId => (dispatch, getState) => {
  const data = helpRequestsServices.verifyStepLog(getState().user.authToken)(helpRequestId)
  dispatch({
    type: VERIFY_STEP_LOG.ACTION,
    payload: data,
  })
  return data
}
