import React from 'react'

import { Typography } from '@material-ui/core'
import useStyles from '../styles'

export function SectionTitle({ title, style }) {
  const styles = useStyles()

  return (
    <Typography style={{ ...style }} className={styles.sectionTitle}>
      {title}
    </Typography>
  )
}
