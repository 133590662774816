import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  input: {
    width: '30%',
  },
  inputLabel: {
    color: 'black',
    fontSize: '16px',
    fontWeight: '500',
    position: 'relative',

    '& :after': {
      content: props => (props.required ? '" *"' : ''),
      color: props => (props.required ? 'red' : ''),
      bottom: 0,
      position: 'absolute',
      transform: 'translate(-100%, 0)',
    },
  },
}))
