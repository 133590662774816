import { formatCurrency } from '_/utils/helpers'
import moment from 'moment'
import { handlePaymentMethod, statusField } from '../manage-installments/utils/constants'

// function to round numbers according to decimal places
export function round(num, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces)
  const intermediary = num * factor
  const temp = Math.floor(intermediary)

  return intermediary - temp < 0.5 ? temp / factor : Math.ceil(intermediary) / factor
}

// functions for installments and receipts details screens
export const handleStringField = value => (value ? String(value) : '-')
const handleDateField = value => (value ? moment(value).format('DD/MM/yyyy') : '--/--/----')
const handleMoneyField = value => (value ? formatCurrency(value) : 'R$ 0,00')
export const handleStatusField = value => (value ? statusField(value) : '-')
export const handleBankField = (bank, digit) => (bank ? `${bank}${digit && `-${digit}`}` : '-')

// functions for installments and receipts details screens
export const handleFormatInstallmentDetailsItemValue = item => {
  switch (item?.type) {
    case 'date':
      return handleDateField(item.value)
    case 'money':
      return handleMoneyField(item.value)
    case 'money-readonly':
      return handleMoneyField(item.value)
    case 'formOfPayment':
      return handlePaymentMethod(item.value)
    default:
      return item.value
  }
}

// function to format interval of numbers or dates of filters
export const formatInterval = (type, startValue, endValue) => {
  const isDate = type === 'date'

  if (!startValue && !endValue) {
    return isDate ? undefined : null
  }

  if (startValue && !endValue) {
    return `${isDate ? startValue.format('YYYY-MM-DD') : startValue},`
  }

  if (!startValue && endValue) {
    return `,${isDate ? endValue.format('YYYY-MM-DD') : endValue}`
  }

  return `${isDate ? startValue.format('YYYY-MM-DD') : startValue},${
    isDate ? endValue.format('YYYY-MM-DD') : endValue
  }`
}

export function cpfCnpjMaskSwitch(value) {
  if (value?.length <= 14) {
    return '999.999.999-999'
  }

  return '99.999.999/9999-99'
}
