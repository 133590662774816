import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { TextField } from '@material-ui/core'

import useStyles from './styles'

const SearchBar = ({ className, placeholder, ...props }) => {
  const styles = useStyles()

  return (
    <TextField
      fullWidth
      placeholder={placeholder}
      variant="outlined"
      className={classnames(styles.searchBar, className)}
      {...props}
    />
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
}

SearchBar.defaultProps = {
  className: '',
  placeholder: '',
}

export default React.memo(SearchBar)
