import React, { useEffect, useMemo, useState } from 'react'

import useStyles from './styles'
import { SideFilter } from '_/components/side-filter'
import { Loader } from '@refera/ui-web'

import { FormProvider, useForm } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { BasicInput, Autocomplete } from '_/components/inputs'
import { useDispatch, useSelector } from 'react-redux'
import { categoriesSelector } from '_/modules/categories/selectors'
import { getCategories } from '_/modules/categories/actions'

const DEFAULT_VALUES = {
  name: '',
  mainServices: [],
  city: '',
}

const schema = yup.object().shape({
  name: yup.string('Conteúdo inválido'),
  city: yup.string('Conteúdo inválido'),
})

function MainFilters({ filters, categoriesFieldOptions }) {
  const styles = useStyles()

  return (
    <>
      <BasicInput
        name="name"
        label="Nome do prestador contenha"
        defaultValue={filters?.name || DEFAULT_VALUES.name}
        containerClasses={styles.inputLabel}
        style={{ margin: '16px 0 -16px' }}
      />

      <Autocomplete
        label="Categoria de atendimento"
        name="mainServices"
        defaultValue={filters?.mainServices || DEFAULT_VALUES.mainServices}
        autocompleteClasses={`${styles.inputLabel} ${styles.selectLabel}`}
        options={categoriesFieldOptions ?? []}
        getOptionLabel={item => item.name}
        style={{ marginBottom: '-16px' }}
      />

      <BasicInput
        name="city"
        label="Cidade de atendimento"
        defaultValue={filters?.city || DEFAULT_VALUES.city}
        containerClasses={styles.inputLabel}
        style={{ margin: '16px 0 -16px' }}
      />
    </>
  )
}

export const ListLeadProvidersFilters = ({ open, close, filters, setFilters, applyFilters }) => {
  const categories = useSelector(categoriesSelector)
  const dispatch = useDispatch()
  const [categoriesLoading, setCategoriesLoading] = useState(true)

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  })

  const { reset, handleSubmit } = methods

  const handleFilters = data => {
    applyFilters(data)
    close()
  }

  const resetFilters = () => {
    reset(DEFAULT_VALUES)
    setFilters(DEFAULT_VALUES)
  }

  const categoriesFieldOptions = useMemo(() => {
    return categories?.sort((a, b) => a.name.localeCompare(b.name)) || []
  }, [categories])

  useEffect(() => {
    if (!categories?.length)
      dispatch(getCategories())
        .then(() => setCategoriesLoading(false))
        .catch(() => setCategoriesLoading(false))
    else setCategoriesLoading(false)
  }, [])

  if (categoriesLoading) return <Loader hasBackdrop open />

  return (
    <FormProvider {...methods}>
      <SideFilter
        open={open}
        close={close}
        mainFilters={
          <MainFilters filters={filters} categoriesFieldOptions={categoriesFieldOptions} />
        }
        cleanFilters={resetFilters}
        applyFilters={handleSubmit(handleFilters)}
      />
    </FormProvider>
  )
}
