import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  app: {
    display: 'grid',
    gridTemplateAreas: props =>
      props.showSideBar ? '"header header" "sidebar children"' : '"header " "children"',
    minHeight: '100vh',
    gridTemplateColumns: props => (props.showSideBar ? `${spacing(80)} 1fr` : '1fr'),
    gridTemplateRows: `${spacing(48)} auto`,
  },

  header: {
    gridArea: 'header',
  },

  children: {
    gridArea: 'children',
    overflowX: 'hidden',
  },

  sidebar: {
    gridArea: 'sidebar',
  },
}))
