// import * as yup from 'yup'

export const AGENCY_REFERA_DETAILS_FIELDS = {
  REVIEW_BUDGET: { name: 'reviewBudget', type: 'boolean' },
  ALLOWS_RENT_DISCOUNT: { name: 'allowsRentDiscount', type: 'boolean' },
  CONTRACT_STATUS: { name: 'contractStatus', type: 'select' },
  CONTRACT_TYPE: { name: 'contractType', type: 'select' },
  CONTRACT_GENERATION_DATE: { name: 'contractGenerationDate', type: 'datetime' },
  CONTRACT_BLOCK_DATE: { name: 'contractBlockDate', type: 'datetime' },
  CONTRACT_SIGNING_DATE: { name: 'contractSigningDate', type: 'date' },
  DEFINE_RESPONSIBLE: { name: 'defineResponsible', type: 'boolean' },
  VIP_LIMIT: { name: 'vipLimit', type: 'integer' },
  IS_VIP_AGENCY: { name: 'isVipAgency', type: 'boolean' },
  SEND_NOTIFICATION: { name: 'sendNotification', type: 'boolean' },
  MINIMUM_SALE_VALUE: { name: 'minimumSaleValue', type: 'number' },
  AGENCY_COMPLETION_PERCENTAGE: { name: 'agencyCompletionPercentage', type: 'number' },
  REFERA_COMPLETION_PERCENTAGE: { name: 'referaCompletionPercentage', type: 'number' },
  SELLER_CONNECTION: { name: 'sellerConnection', type: 'select' },
}

export const CONTRACT_TYPE_OPTIONS = [
  { value: 'i_got_rid', label: 'Me livrei' },
  { value: 'i_answer', label: 'Eu atendo' },
  { value: 'SAAS', label: 'SAAS' },
  { value: 'intern_refera', label: 'Refera interno' },
]

export const CONTRACT_STATUS_OPTIONS = [
  { value: 'pending', label: 'Pendente' },
  { value: 'generated', label: 'Gerado' },
  { value: 'signed', label: 'Assinado' },
  { value: 'cancelled', label: 'Cancelado' },
]

export function transformValue(value, key) {
  if (AGENCY_REFERA_DETAILS_FIELDS[key].type === 'integer') {
    if (!value) return null

    return typeof value === 'number' ? value.toString() : value
  }

  if (AGENCY_REFERA_DETAILS_FIELDS[key].type === 'number') {
    if (!value) return null

    const returnValue = typeof value === 'number' ? value : parseFloat(value)

    return isNaN(returnValue)
      ? null
      : returnValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  if (AGENCY_REFERA_DETAILS_FIELDS[key].type === 'boolean') return value === true

  if (AGENCY_REFERA_DETAILS_FIELDS[key].type === 'date') return value ?? null

  return value
}

export function castToStringifiedNumber(value) {
  let transformedValue = value
  if (typeof transformedValue === 'number') {
    transformedValue = transformedValue.toString()
  }
  const returnValue = transformedValue.replace(/[^\d,]/g, '').replace(',', '.')

  return returnValue
}

// export const referaDetailsPayloadSchema = yup.object().shape({
//   [REFERA_DETAILS_FIELDS.CONTRACT_TYPE]: yup.string(),
//   [REFERA_DETAILS_FIELDS.CONTRACT_STATUS]: yup.string().nullable(),
//   [REFERA_DETAILS_FIELDS.VIP_LIMIT]: yup.string(),
//   [REFERA_DETAILS_FIELDS.IS_VIP_AGENCY]: yup.boolean().transform(value => value === true),
//   [REFERA_DETAILS_FIELDS.CONTRACT_GENERATION_DATE]: yup.string().nullable(),
//   [REFERA_DETAILS_FIELDS.CONTRACT_BLOCK_DATE]: yup.string().nullable(),
//   [REFERA_DETAILS_FIELDS.CONTRACT_SIGNING_DATE]: yup.string().nullable(),
//   [REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE]: yup
//     .string()
//     .nullable()
//     .transform(castToStringifiedNumber),
//   [REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE]: yup
//     .string()
//     .nullable()
//     .transform(value => castToStringifiedNumber(value)),
//   [REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE]: yup.string().transform(castToStringifiedNumber),
//   [REFERA_DETAILS_FIELDS.SEND_NOTIFICATION]: yup.boolean().transform(value => value === true),
//   [REFERA_DETAILS_FIELDS.DEFINE_RESPONSIBLE]: yup.boolean().transform(value => value === true),
//   [REFERA_DETAILS_FIELDS.REVIEW_BUDGET]: yup.boolean().transform(value => value === true),
//   [REFERA_DETAILS_FIELDS.ALLOWS_RENT_DISCOUNT]: yup.boolean().transform(value => value === true),
// })
