import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      darkest: '#0B3672',
      dark: '#1051AB',
      main: '#1671ED',
      light: '#6AA4F3',
      lightest: '#ECF4FE',
    },
    secondary: {
      darkest: '#004C6C',
      dark: '#0072A3',
      main: '#009EE2',
      light: '#5CC1EC',
      lightest: '#5CC1EC',
    },
    red: {
      darkest: '#600000',
      dark: '#910000',
      main: '#C90000',
      light: '#DC5C5C',
      lightest: '#FBEBEB',
    },
    error: {
      darkest: '#600000',
      dark: '#910000',
      main: '#C90000',
      light: '#DC5C5C',
      lightest: '#FBEBEB',
    },
    green: {
      darkest: '#004204',
      dark: '#006306',
      main: '#008908',
      light: '#5CB461',
      lightest: '#EBF6EB',
    },
    yellow: {
      darkest: '#745B00',
      dark: '#AE8800',
      main: '#F2BD00',
      light: '#F7D55C',
      lightest: '#FEFAEB',
    },
    orange: {
      darkest: '#6C3610',
      dark: '#A35118',
      main: '#DC7C00',
      light: '#ECA471',
      lightest: '#FDF4ED',
    },
    text: {
      primary: '#141414',
      secondary: '#696969',
    },
    divider: '#C4C4C4',
    background: {
      default: 'white',
    },
    gray: {
      4: '#F6F6F6',
      80: '#434343',
      64: '#696969',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: {
      fontSize: '3.2rem',
      fontWeight: '500',
      lineHeight: '4.8rem',
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: '500',
      lineHeight: '3.2rem',
    },
    h3: {
      fontSize: '1.6rem',
      fontWeight: '500',
      lineHeight: '2.4rem',
    },
    h4: {
      fontSize: '1.4rem',
      fontWeight: '500',
      lineHeight: '2rem',
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: '500',
      lineHeight: '1.6rem',
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: '500',
      lineHeight: '2rem',
    },
    body1: {
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '2rem',
    },
    body2: {
      fontSize: '1.2rem',
      fontWeight: 'normal',
      lineHeight: '1.5rem',
    },
  },
  overrides: {
    MuiSelect: {
      icon: {
        color: '#696969',
        height: '2rem',
        width: '2rem',
      },
    },
    MuiInputBase: {
      input: {
        lineHeight: '2.4rem',
        fontSize: '1.6rem',
        color: '#141414',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 10,
      },
    },
    MuiLinearProgress: {
      bar1Determinate: {
        borderRadius: '0.8rem',
      },
    },
    MuiOutlinedInput: {
      multiline: {
        height: '14.4rem',
        padding: '1rem 1.6rem',
        display: 'flow-root',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 450,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: factor => `${0.1 * factor}rem`,
})
