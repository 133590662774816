import React, { useCallback, useEffect, useMemo, useState, useReducer } from 'react'
import { Grid } from '@material-ui/core'
import { Datagrid, Button } from '@refera/ui-web'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { usePrevious } from '_hooks/use-previous'

import { getBudgetTemplates } from '_/modules/budgetTemplate/actions'
import {
  budgetTemplateCountSelector,
  budgetTemplatePageSelector,
  budgetTemplatePageSizeSelector,
  budgetTemplateSelector,
} from '_/modules/budgetTemplate/selector'
import HeaderTitle from '_/components/header-title'

import { ROUTES } from '_utils/constants'

import useStyles, { dataGridStyle } from '../../classification-management/styles'
import { COLUMNS } from './constants'
import { reducer, INITIAL_STATE, ACTIONS } from '../reducer'

const BudgetTemplatesView = () => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const wasLoading = usePrevious(isLoading)

  const [localState, localDispatch] = useReducer(reducer, INITIAL_STATE)
  const budgetTemplates = useSelector(budgetTemplateSelector)
  const budgetTemplateCount = useSelector(budgetTemplateCountSelector)
  const budgetTemplatePage = useSelector(budgetTemplatePageSelector)
  const budgetTemplatePageSize = useSelector(budgetTemplatePageSizeSelector)
  const rows = useMemo(() => budgetTemplates ?? [], [budgetTemplates])

  const handleGoToTemplate = useCallback(event => {
    navigate(`${ROUTES.BUDGET_TEMPLATE}/template/${event.row.id}`)
  }, [])

  const handleGoToNew = useCallback(() => {
    navigate(`${ROUTES.BUDGET_TEMPLATE}/template`)
  }, [])

  const handlePage = useCallback(page => {
    localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { page } })
  }, [])

  const handlePageSize = useCallback(pageSize => {
    localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { pageSize } })
  }, [])

  const handleSortModel = useCallback(orderObj => {
    if (orderObj[0]?.field && orderObj[0]?.sort) {
      const { field, sort } = orderObj[0]
      const order = `${sort === 'desc' ? '-' : ''}${field}`

      localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { order } })
    }
  }, [])

  const getBudgetTemplateToList = useCallback(() => {
    dispatch(
      getBudgetTemplates({
        ...localState,
        page: localState.page + 1,
      })
    )

    return setIsLoading(false)
  }, [localState])

  useEffect(() => {
    getBudgetTemplateToList()
  }, [wasLoading])

  const handleBackNavigation = useCallback(() => {
    navigate(ROUTES.TRADESMAN)
  }, [])

  return (
    <>
      <Grid className={styles.container}>
        <HeaderTitle title="Modelos de orçamentos" backButtonAction={handleBackNavigation}>
          <Grid className={styles.header}>
            <Button onClick={handleGoToNew}>Novo</Button>
          </Grid>
        </HeaderTitle>

        <Grid className={styles.tableContainer}>
          <Grid className={styles.mainTable}>
            <Datagrid
              paginationMode="server"
              sx={dataGridStyle}
              rowCount={budgetTemplateCount}
              onPageChange={handlePage}
              onRowClick={handleGoToTemplate}
              onPageSizeChange={handlePageSize}
              onSortModelChange={handleSortModel}
              page={budgetTemplatePage}
              pageSize={budgetTemplatePageSize > 100 ? 100 : budgetTemplatePageSize}
              rows={rows}
              columns={COLUMNS}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default BudgetTemplatesView
