import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  icon: {
    height: spacing(14),
    width: spacing(14),
  },
  checkIcon: {
    height: spacing(16),
    width: spacing(16),
  },
  edit: {
    marginBottom: spacing(8),
    fontWeight: 'normal',
  },
  saveButton: {
    marginBottom: spacing(8),
    fontWeight: 'normal',

    '& .MuiButton-endIcon': {
      margin: 0,
    },
  },
  cancelButton: {
    margin: spacing(0, 8, 8),
  },
}))
