import { defineAction } from '_utils/redux'
import * as propertyServices from '_services/property'

export const GET_PROPERTY = defineAction('GET_PROPERTY')
export const UPDATE_PROPERTY = defineAction('UPDATE_PROPERTY')
export const GET_FORMATTED_ADDRESS = defineAction('GET_FORMATTED_ADDRESS')

const formatPayload = payload => {
  const newPayload = payload
  if (newPayload.cep) {
    newPayload.cep = payload.cep.replace(/(\d{5})(\d{3})/, '$1-$2')
  }

  return newPayload
}

export const getProperty = propertyId => (dispatch, getState) => {
  dispatch({
    type: GET_PROPERTY.ACTION,
    payload: propertyServices.getProperty(getState().user.authToken)(propertyId),
  })
}

export const updateProperty = (propertyId, payload) => (dispatch, getState) => {
  const data = propertyServices.updateProperty(getState().user.authToken)(
    propertyId,
    formatPayload(payload)
  )

  dispatch({
    type: UPDATE_PROPERTY.ACTION,
    payload: data,
  })
  return data
}

export const getFormattedAddress = propertyId => (dispatch, getState) => {
  const data = propertyServices.getFormattedAddress(getState().user.authToken)(propertyId)
  dispatch({
    type: GET_FORMATTED_ADDRESS.ACTION,
    payload: data,
  })
  return data
}
