import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  currentServiceOrderSelector,
  getGenericParametersSelector,
  getStepStatusCanceledListSelector,
} from '_modules/service-orders/selectors'

const useIsCurrentStepStatusCanceled = () => {
  const stepStatusList = useSelector(getStepStatusCanceledListSelector)
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const genericParameters = useSelector(getGenericParametersSelector)

  const currentAgencyId = useMemo(
    () => serviceOrder?.get('agency')?.get('id'),
    [serviceOrder?.get('agency')?.get('id')]
  )

  const isStepStatusStructureActive = useMemo(() => {
    if (!genericParameters?.length) {
      return false
    }

    return genericParameters.find(
      item => item.name === 'step_status_structure' && item.agency === currentAgencyId
    )?.active
  }, [currentAgencyId, genericParameters])

  const currentStepStatus = useMemo(
    () => serviceOrder?.get('stepStatus'),
    [serviceOrder?.get('stepStatus')]
  )

  const isCurrentStepStatusCanceled = useMemo(() => {
    if (!isStepStatusStructureActive) {
      return !serviceOrder?.get('isActive')
    }

    if (!currentStepStatus) {
      return false
    }

    return stepStatusList?.some(item => item.name === currentStepStatus)
  }, [currentStepStatus, serviceOrder?.get('isActive'), stepStatusList])

  return isCurrentStepStatusCanceled
}

export default useIsCurrentStepStatusCanceled
