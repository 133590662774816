/* eslint-disable no-param-reassign */

import { createReducer } from '_utils/redux'

import {
  GET_PROFILE,
  GET_PROFILE_ACTION_PERMISSION,
  GET_PROFILE_DATA_PERMISSION,
  GET_PROFILE_GROUP,
  GET_PROFILE_LIST,
} from './actions'
import { Record } from 'immutable'

const INITIAL_STATE = new Record({
  profileDataPermissions: {},
  profileActionPermissions: {},
  profiles: {},
  permissionsGroup: {},
  profileGroup: {},
  profile: {},
})()

export const profiles = createReducer(INITIAL_STATE, {
  [GET_PROFILE_DATA_PERMISSION.FULFILLED]: (state, { payload }) => {
    return state.update('profileDataPermissions', () => payload)
  },
  [GET_PROFILE_ACTION_PERMISSION.FULFILLED]: (state, { payload }) => {
    return state.update('profileActionPermissions', () => payload)
  },
  [GET_PROFILE_LIST.FULFILLED]: (state, { payload }) => {
    return state.update('profiles', () => payload)
  },
  [GET_PROFILE_GROUP.FULFILLED]: (state, { payload }) => {
    return state.update('permissionsGroup', () => payload)
  },
  [GET_PROFILE.FULFILLED]: (state, { payload }) => {
    return state.update('profile', () => payload)
  },
})
