import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'

import Modal from '_components/modal'
import Textfield from '_components/textfield'
import { USER_FIELDS } from '_utils/user'

import { INPUTS } from '../reducer'

import useStyles from './styles'

const AddUserModal = ({
  handleModal,
  handleSubmit,
  values,
  onChange,
  onBlur,
  errors,
  touched,
  disabledSubmit,
  isProvider,
  isLoading,
}) => {
  const styles = useStyles()
  const renderInputs = useCallback(
    input => {
      const textFieldProps = {
        value: values[input.name],
        onChange,
        onBlur,
        error: touched[input.name] && !!errors[input.name],
        helperText: (touched[input.name] && errors[input.name]) || '',
        ...input,
      }

      if (input.options) {
        return (
          <FormControl component="fieldset" key={`add-user-modal-${input.name}`}>
            <FormLabel component="legend" className={styles.label}>
              {input.label}
            </FormLabel>
            <RadioGroup
              name={input.name}
              className={styles.radioGroup}
              onChange={textFieldProps.onChange}
            >
              {input.options.map(item => (
                <FormControlLabel
                  value={item.value}
                  control={<Radio color="primary" />}
                  label={item.label}
                  checked={values[input.name] === item.value}
                  key={`radio-${input.name}-${item.value}`}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )
      }
      return <Textfield {...textFieldProps} key={`add-user-modal-${input.name}`} />
    },
    [errors, onBlur, onChange, styles.label, styles.radioGroup, touched, values]
  )

  const renderView = useMemo(() => {
    const inputs = values?.id ? INPUTS.filter(item => item.name !== USER_FIELDS.PASSWORD) : INPUTS
    if (isProvider) {
      return inputs.filter(item => item.name !== USER_FIELDS.ROLE).map(input => renderInputs(input))
    }
    return inputs?.map(input => renderInputs(input))
  }, [isProvider, renderInputs, values])

  const renderTitle = useMemo(
    () => (isProvider ? 'Editar usuário' : 'Criar novo usuário'),
    [isProvider]
  )

  const handleSubmitUser = useCallback(() => {
    handleSubmit(values)
  }, [handleSubmit, values])

  return (
    <Modal
      handleModal={handleModal}
      handleSubmit={handleSubmitUser}
      title={renderTitle}
      disabledSubmit={disabledSubmit}
      className={styles.modal}
      isLoading={isLoading}
    >
      <Grid className={styles.container}>{renderView}</Grid>
    </Modal>
  )
}

AddUserModal.propTypes = {
  disabledSubmit: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  touched: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  isProvider: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
}

AddUserModal.defaultProps = {
  isLoading: false,
}

export default React.memo(AddUserModal)
