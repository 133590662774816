import { Map, fromJS } from 'immutable'
import { normalize } from 'normalizr'

import AgencyVip, { agencyVipSchema } from '_/models/agency-vip'
import { createReducer } from '_utils/redux'
import { getPage } from '_utils/helpers'
import { ORDER_BY_VALUES } from '_utils/constants'

import { GET_VIP_CLIENTS, GET_VIP_CLIENT_BY_ID, UPDATE_VIP_CLIENT } from './actions'

const INITIAL_STATE = new Map({
  results: new Map(),
  next: '',
  count: '',
  pageSize: 10,
  orderAgency: new Map(),
  params: undefined,
  page: 0,
  isAsc: undefined,
})

export default createReducer(INITIAL_STATE, {
  [GET_VIP_CLIENTS.FULFILLED]: (state, { payload, meta }) => {
    const { order } = meta
    const normalizeAgencyVip = normalize(payload.results, agencyVipSchema)

    return state
      .set('results', fromJS(normalizeAgencyVip.entities.agencyVip))
      .set('next', getPage(payload.next))
      .set('count', payload.count)
      .set('pageSize', meta.pageSize)
      .set('params', { order: meta.order })
      .set('page', meta.page)
      .set('orderAgency', normalizeAgencyVip.result)
      .set('isAsc', order === ORDER_BY_VALUES.ASC)
  },

  [GET_VIP_CLIENT_BY_ID.FULFILLED]: (state, { payload }) => {
    return state.setIn(['results', payload?.id?.toString()], new AgencyVip(payload))
  },

  [UPDATE_VIP_CLIENT.FULFILLED]: (state, { payload }) => {
    const data = {
      ...payload,
    }

    return state.setIn(['results', payload?.id?.toString()], new AgencyVip(data))
  },
})
