import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, breakpoints }) => ({
  history: {
    display: 'grid',
    gridTemplateColumns: `${spacing(35)} 1fr`,
    gridColumnGap: spacing(16),
    marginBottom: spacing(16),
    position: 'relative',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: spacing(35),
    height: spacing(35),
    borderRadius: '100%',
  },
  content: {
    marginTop: spacing(10),
  },
  title: {
    fontSize: spacing(13),
    lineHeight: spacing(15),
    color: 'white',
    marginBottom: spacing(4),
  },
  description: {
    fontSize: spacing(12),
    lineHeight: spacing(16),
    color: 'white',
    width: spacing(205),
  },
  divider: {
    position: 'absolute',
    left: spacing(17),
    top: spacing(35),
    color: 'white',
  },
  button: {
    fontSize: spacing(12),
    lineHeight: spacing(15),
    color: 'white',
    textDecoration: 'underline',
    margin: 0,
    marginTop: spacing(4),
    padding: 0,
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
  modal: {
    '& .ReferaModal__content': {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(24),
      padding: spacing(24, 16, 'important'),
    },

    '& .ReferaModal-root': {
      minWidth: spacing(550),
    },

    [breakpoints.down('sm')]: {
      '& .ReferaModal-root': {
        minWidth: spacing(250),
        width: '80%',
      },
    },
  },
  modalButtom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  titleModal: {
    fontSize: spacing(Theme.Typography.FontSize.XXSmall),
    fontWeight: Theme.Typography.FontWeight.Big,
    fontFamily: Theme.Typography.FontFamily.DMSans,
  },
  textModal: {
    fontSize: spacing(Theme.Typography.FontSize.XSmall),
    fontWeight: Theme.Typography.FontWeight.Small,
    fontFamily: Theme.Typography.FontFamily.DMSans,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing(10),
  },
  descriptionModal: {
    fontSize: spacing(Theme.Typography.FontSize.XSmall),
    fontWeight: Theme.Typography.FontWeight.Small,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    borderSizing: 'border-box',
    border: '1px solid #E3E3E3',
    padding: '16px',
    borderRadius: '4px',
    overflowWrap: 'break-Word',
    width: '100%',
    minHeight: spacing(150),
    [breakpoints.up('sm')]: {
      minHeight: spacing(100),
    },
  },
}))
