import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  tableContainer: {
    flex: 1,
    margin: '20px 2% 0 34px',
  },
  mainTable: {
    width: 'auto',
    height: spacing(616),
  },
}))
