import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  container: {
    margin: '10px',
    height: '320px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  noContainer: {
    display: 'flex',
    alignItems: 'justify',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '-100px',
    marginLeft: '32%',
    marginRight: '25%',
  },
  image: {
    width: '60px',
    height: '60px',
    margin: '20px',
  },
  title: {
    color: '#141414',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    left: '444px',
    lineHeight: '40px',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    size: '32px',
    top: '216px',
  },
  subTitle: {
    fontSize: '13px',
    lineHeight: '24px',
    color: '#696969',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    textAlign: 'center',
    width: '400px',
    height: '47px',
    left: '440px',
    fontFamily: 'Roboto',
  },
  textTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '30px',
    fontWeight: '500',
    fontSize: '13px',
    color: '#141414',
    fontFamily: 'Roboto',
  },
  textMessage: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '10px',
    fontSize: '13px',
    color: '#141414',
    fontFamily: 'Roboto',
  },
  aster: {
    color: 'red',
  },
  radioButton: {
    color: '#1671ED',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  button: {
    height: '40px',
    width: '272px',
    padding: '10px 16.5px',
    borderRadius: '24px',
    margin: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textTransform: 'none',
  },
  optional: {
    fontSize: '10px',
    color: '#696969',
    fontFamily: 'Roboto',
    paddingLeft: '2px',
  },
  optionalRight: {
    alignItems: 'self-end',
    justifyContent: 'end',
    fontSize: '10px',
    color: '#696969',
    fontFamily: 'Roboto',
    paddingRight: '2px',
  },
  stars: {},
}))
