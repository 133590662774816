import React from 'react'
// Components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core'
import { Button } from '@refera/ui-web'
import useStyles from './styles'
import Draggable from 'react-draggable'
import moment from 'moment'
import { formatCurrency } from '_/utils/helpers'
import { statusField } from '_/views/finance/manage-installments/utils/constants'

const InstallmentsModal = ({ open, onClose, installments, paidInstallments }) => {
  const styles = useStyles()

  const formatDate = date => (date ? moment(date).format('DD/MM/YYYY') : '__ / __ / ____')

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const PaperComponent = props => {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    )
  }

  return (
    <Dialog
      className={styles.modal}
      onBackdropClick={onClose}
      onClose={onClose}
      open={open}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      maxWidth="auto"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} className={styles.titleBlue} id="draggable-dialog-title">
        Data de pagamento de chamado
      </DialogTitle>
      <DialogContent className={styles.contentContainer}>
        <Grid className={styles.listHeader}>
          <Typography className={styles.headerText}>Vencimento</Typography>
          <Typography className={styles.headerText}>Valor da parcela</Typography>
          <Typography className={styles.headerText}>Pagamento</Typography>
          <Typography className={styles.headerText}>Valor pago</Typography>
          <Typography className={styles.headerText}>Status</Typography>
        </Grid>
        {installments.map(installment => {
          const paidInstallment = paidInstallments.find(
            currentInstallment => currentInstallment.installmentId === installment.id
          )

          return (
            <Grid key={installment.id} className={styles.listContainer}>
              <Typography className={styles.listText}>
                {formatDate(installment?.dueDate)}
              </Typography>
              <Typography className={styles.listText}>
                {formatCurrency(installment?.providerValue)}
              </Typography>
              <Typography className={styles.listText}>
                {formatDate(installment?.status === 'paid' && paidInstallment?.paymentDate)}
              </Typography>
              <Typography className={styles.listText}>
                {formatCurrency(
                  (installment?.status === 'paid' && paidInstallment?.amountPaid) || 0
                )}
              </Typography>
              <Typography className={styles.listText}>
                {statusField(installment?.status)}
              </Typography>
            </Grid>
          )
        })}
      </DialogContent>
      <DialogActions className={styles.dialogAction}>
        <Grid className={styles.modalButtons}>
          <Button color="red" variant="secondary" onClick={onClose}>
            Fechar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default InstallmentsModal
