import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  sectionTitle: {
    fontSize: spacing(16),
    fontWeight: 500,
    lineHeight: spacing(20),
    color: Theme.Colors.Grayscale.OneThousand,
  },
  content: {
    display: 'flex',
    gap: '25px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    padding: spacing(32, 50),
  },
  info: {
    display: 'flex',
    width: '100%',
  },
  form: {
    width: '100%',
    maxWidth: 1360,
  },
  box: {
    gap: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid #E3E3E3`,
    borderRadius: '5px',
    padding: '30px 20px',
  },
  typography: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: Theme.Typography.FontSize.XSmall,
    color: Theme.Colors.Grayscale.Eighty,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(20, 50),
    gap: '30px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
  },
  placeholder: {
    width: '48px',
  },
}))
