import React, { useMemo, useState } from 'react'
import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // TableSortLabel,
  IconButton,
  Typography,
} from '@material-ui/core'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'

import { navigate } from '@reach/router'

import { defaultStyles } from './styles'

function formatFirstLetterToUpperCase(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const Table = ({ items }) => {
  const [columnsWidths, setColumnsWidths] = useState({})
  const styles = defaultStyles()
  // const [orderBy, setOrderBy] = useState('asc')

  const formattedItems = useMemo(() => {
    const newItems = []

    items.forEach((item, index) => {
      const newObj = {}

      Object.keys(item).forEach(key => {
        const [newKey, width] = key.split('#')
        newObj[newKey] = item[key]
        if (index === 0) setColumnsWidths(prev => ({ ...prev, [newKey]: width }))
      })

      newItems.push(newObj)
    })
    return newItems
  }, [items])

  const renderTableCell = (key, value) => {
    switch (key) {
      case 'link':
        return (
          <IconButton
            className={styles.iconButton}
            onClick={() => navigate(value)}
            id={value.serviceOrderId}
          >
            <ArrowForwardIos className={styles.closeArrrowIcon} />
          </IconButton>
        )
      default:
        return <Typography className={styles.cellText}>{value}</Typography>
    }
  }

  const cellStyles = key => ({
    padding: '0',
    textWrap: 'nowrap',
    width: columnsWidths[key] ? `${columnsWidths[key]}%` : '100px',
  })

  const renderTableBody = useMemo(() => {
    return formattedItems?.map(row => {
      return (
        <TableRow
          className={styles.tableRow}
          hover
          key={`table-row-${row?.['código']}`}
          id={row?.['código']}
          name={row?.['código']}
          // className={cellStyle.cell}
        >
          {Object.keys(row).map(key => (
            <TableCell key={`cell-${row?.['código']}-${key}`} style={cellStyles(key)}>
              {renderTableCell(key, row[key])}
            </TableCell>
          ))}
        </TableRow>
      )
    })
  }, [renderTableCell, formattedItems, columnsWidths])

  // const changeOrder = () => setOrderBy(prev => (prev === 'asc' ? 'desc' : 'asc'))

  const renderTableHeader = useMemo(() => {
    return (
      <TableRow className={styles.tableRow}>
        {Object.keys(formattedItems?.[0])?.map(column => {
          return (
            <TableCell key={column?.['código']} style={{ ...cellStyles(column), padding: '8px 0' }}>
              {/* <TableSortLabel active={orderBy} direction={orderBy} onClick={changeOrder}> */}
              <Typography className={styles.cellText}>
                {formatFirstLetterToUpperCase(column)}
              </Typography>
              {/* </TableSortLabel> */}
            </TableCell>
          )
        })}
      </TableRow>
    )
  }, [formattedItems, columnsWidths, cellStyles])

  return (
    <TableContainer component="div" className={styles.container}>
      <MuiTable aria-label="Tabela de gestão de chamados" className={styles.tableContainer}>
        <TableHead>{renderTableHeader}</TableHead>
        <TableBody className={styles.tableContainer}>{renderTableBody}</TableBody>
      </MuiTable>
    </TableContainer>
  )
}

export default React.memo(Table)
