import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from '@reach/router'
import { Dialog } from '@refera/ui-web'
import { TickSquare as TickSquareIcon } from '@refera/ui-icons'

import { completeCanceledServiceOrder } from '_modules/service-orders/actions'
import { getHistoryLogs } from '_modules/history-logs/actions'

const CompleteCanceledServiceOrderModal = ({ isOpen, handleClose }) => {
  const { serviceOrderId } = useParams()
  const dispatch = useDispatch()

  const refetchHistoryLogs = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))
  }, [dispatch, serviceOrderId])

  const handleApprove = useCallback(() => {
    dispatch(completeCanceledServiceOrder(serviceOrderId)).then(() => {
      refetchHistoryLogs()
      handleClose()
    })
  }, [dispatch, handleClose, serviceOrderId])

  const handleCancel = useCallback(() => {
    handleClose()
  }, [handleClose])

  useEffect(() => {
    const handleKeydown = event => {
      if (isOpen && event.key === 'Enter') {
        handleApprove()
      }
    }

    window.addEventListener('keydown', handleKeydown)
    return () => window.removeEventListener('keydown', handleKeydown)
  }, [handleApprove, isOpen])

  return (
    <Dialog
      open={isOpen}
      icon={TickSquareIcon}
      type="info"
      subject="Você tem certeza que deseja executar esta ação?"
      labelApprove="Sim"
      labelCancel="Não"
      onApprove={handleApprove}
      onCancel={handleCancel}
    />
  )
}

export default CompleteCanceledServiceOrderModal
