import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getBudgetByIdSelector } from '_modules/budget/selectors'

const useBudgetPdfFileName = ({ budgetId }) => {
  const budget = useSelector(getBudgetByIdSelector(budgetId))

  const dateTimeNow = new Date().toLocaleString()

  const pdfFileName = useMemo(
    () => `orcamento_${budget?.serviceOrder}_${budget?.id}_${dateTimeNow}.pdf`,
    [budget?.id, budget?.serviceOrder, dateTimeNow]
  )

  return pdfFileName
}

export default useBudgetPdfFileName
