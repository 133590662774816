import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ typography, palette: { gray, secondary }, spacing }) => ({
  checkbox: {
    color: props => (props.selected ? secondary.main : gray[32]),
    marginRight: 0,
    padding: 0,
    height: spacing(24),
    width: spacing(24),
    '& .MuiSvgIcon-root': {
      height: spacing(24),
      width: spacing(24),
    },
    '& .MuiTypography-body1': {
      ...typography.body2,
    },
  },
}))
