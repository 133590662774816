import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  pagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

const TablePaginationActions = ({ count, page, rowsPerPage, onChangePage }) => {
  const styles = useStyles()
  const totalPage = Math.max(0, Math.ceil(count / rowsPerPage))

  return (
    <Pagination
      className={styles.pagination}
      count={totalPage}
      onChange={onChangePage}
      color="primary"
      page={page + 1}
    />
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

TablePaginationActions.defaultProps = {
  count: 0,
}

export default React.memo(TablePaginationActions)
