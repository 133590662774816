import React, { useEffect } from 'react'
import {
  Accordion as MUIAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import Svg, { ICON } from '_components/svg'
import useStyles from './styles'
import useBoolean from '_hooks/use-toggle'

const Accordion = ({
  title,
  children,
  titleStyles = {},
  defaultExpanded = true,
  close = false,
}) => {
  const [isExpanded, handleExpanded] = useBoolean(defaultExpanded)
  const styles = useStyles({ isExpanded })

  useEffect(() => {
    if (isExpanded && close) handleExpanded()
  }, [close])

  return (
    <MUIAccordion
      elevation={0}
      className={styles.accordion}
      expanded={isExpanded}
      onChange={handleExpanded}
      component="section"
    >
      <AccordionSummary className={styles.summary} aria-controls={title} id={title}>
        <Svg type={ICON.ARROW} className={styles.icon} />
        <Typography variant="h5" component="h1" color="primary" className={titleStyles}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>{children}</AccordionDetails>
    </MUIAccordion>
  )
}

export default Accordion
