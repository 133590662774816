import React, { useEffect } from 'react'

// Icons
import {
  InfoCircle as InfoCircleIcon,
  Trash as TrashIcon,
  Danger as DangerIcon,
} from '@refera/ui-icons'

// Components
import { Dialog, useConfirm } from '@refera/ui-web'

const ConfirmRemoveDialog = ({ message, type = 'info', description = '' }) => {
  const { isOpen = true, approve, cancel } = useConfirm()

  useEffect(() => {
    const handleKeydown = e => {
      if (approve && isOpen && e.key === 'Enter') {
        approve()
      }
    }
    window.addEventListener('keydown', handleKeydown)
    return () => window.removeEventListener('keydown', handleKeydown)
  }, [approve, isOpen])

  const handleIcon = () => {
    if (type === 'remove') return TrashIcon
    if (type === 'warning') return DangerIcon
    return InfoCircleIcon
  }

  const handleType = () => {
    if (type === 'remove') return 'error'
    if (type === 'warning') return 'warning'
    return 'info'
  }

  return (
    <Dialog
      open={isOpen}
      icon={handleIcon()}
      type={handleType()}
      subject={message}
      description={description}
      labelApprove="Sim"
      labelCancel="Não"
      onApprove={approve}
      onCancel={cancel}
    />
  )
}

export default ConfirmRemoveDialog
