import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  modalConteiner: {
    width: 860,
    '& .MuiTableCell-root': {
      padding: '22px 0',
      color: palette.gray[64],
    },
  },
  columnHeader: {
    fontWeight: 500,
  },
  chipReasons: {
    borderRadius: '16px',
    backgroundColor: palette.gray[4],
    padding: '4px 8px',
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
  },
}))
