import { Record, OrderedMap } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { historySchema } from '_models/history-log'

import { GET_HISTORY_LOGS, SET_EVENTS_FILTER } from './actions'

const INITIAL_STATE = new Record({
  results: undefined,
  filter: OrderedMap({
    mainEvents: true,
  }),
})()

const historyLogs = createReducer(INITIAL_STATE, {
  [SET_EVENTS_FILTER]: (state, { payload }) => {
    const { name, value } = payload
    return state.updateIn(['filter', name], () => value)
  },
  [GET_HISTORY_LOGS.FULFILLED]: (state, { payload }) => {
    const normalizedHistoryLogs = normalize(payload.results, historySchema)
    const reverseNormalizedHistoryLogs = OrderedMap(
      normalizedHistoryLogs.entities.historyLog
    ).reverse()
    return state.set('results', reverseNormalizedHistoryLogs)
  },
})

export default historyLogs
