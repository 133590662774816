import { createReducer } from '_utils/redux'
import { GET_HOLIDAYS } from './actions'
import { List, Record } from 'immutable'

const INITIAL_STATE = new Record({
  results: List(),
})()

const holidays = createReducer(INITIAL_STATE, {
  [GET_HOLIDAYS.FULFILLED]: (state, { payload }) => {
    return state.set('results', List(payload))
  },
})

export default holidays
