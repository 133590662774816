export const ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
  RESET_STATE: 'RESET_STATE',
}

export const INITIAL_STATE = {
  page: 0,
  pageSize: 10,
  order: '-active,classification_name',
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...payload,
      }
    case ACTIONS.RESET_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
