import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import CheckIcon from '@material-ui/icons/Check'

import Button from '_components/button'
import Svg, { ICON } from '_components/svg'

import useStyles from './styles'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const EditMode = ({ editMode, setEditMode, handleSave, isLoading, handleCancel, onlyCancel }) => {
  const styles = useStyles()
  const { checkUserPermission } = useRolePermission()

  useEffect(() => {
    const confirmExit = () => {
      if (editMode) {
        return 'Salve antes as suas alterações no registro.'
      }

      return null
    }

    window.onbeforeunload = confirmExit
  }, [editMode])

  if (editMode) {
    return (
      <Grid className={styles.buttons}>
        <Button
          className={styles.cancelButton}
          variant="outlined"
          color="secondary"
          onClick={handleCancel}
          disabled={isLoading}
        >
          Fechar
        </Button>
        {!onlyCancel && (
          <Button
            onClick={handleSave}
            className={styles.saveButton}
            color="primary"
            endIcon={<CheckIcon className={styles.checkIcon} />}
            variant="contained"
            isLoading={isLoading}
          >
            Salvar
          </Button>
        )}
      </Grid>
    )
  }

  return (
    <Button
      className={styles.edit}
      color="primary"
      endIcon={<Svg className={styles.icon} type={ICON.EDIT} />}
      onClick={() =>
        checkUserPermission({
          permission: PERMISSIONS.SERVICE_ORDER,
          permissionAction: [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT],
          functionAccess: setEditMode,
        })
      }
    >
      Editar
    </Button>
  )
}

EditMode.propTypes = {
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onlyCancel: PropTypes.bool,
}

EditMode.defaultProps = {
  isLoading: false,
  onlyCancel: false,
}

export default React.memo(EditMode)
