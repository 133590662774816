import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

// TODO: Remove !important once refera lib support override this class
export default makeStyles(() => ({
  label: {
    color: Theme.Colors.Grayscale.OneThousand,
    fontFamily: `${Theme.Typography.FontFamily.DMSans} !important`,
    fontSize: `${Theme.Typography.FontSize.Tiny}px !important`,
    fontWeight: `${Theme.Typography.FontWeight.Medium} !important`,
  },
  radioGroup: {
    flexFlow: 'row wrap !important',
    justifyContent: 'space-between',
  },
}))
