import React from 'react'
import { Chip, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Theme from '@refera/ui-core'

import useStyles from './styles'

const AutoComplete = ({
  options,
  value,
  name,
  onChange,
  placeholder,
  multiple = true,
  chips = false,
}) => {
  const styles = useStyles()

  return (
    <Autocomplete
      multiple={multiple}
      freeSolo
      id={name}
      options={options || []}
      value={value || ``}
      onChange={(event, newValue) => onChange(newValue, name)}
      getOptionLabel={option => option?.name || option?.label}
      className={styles.chip}
      renderTags={(tagValue, getTagProps) =>
        chips
          ? value?.map((option, index) => (
              <Chip
                classes={styles.chip}
                style={{
                  backgroundColor: Theme.Colors.Primary.Base,
                  color: '#fff',
                }}
                {...getTagProps({ index })}
                label={option?.name || option?.label}
              />
            ))
          : value?.map((option, index) => (
              <Typography style={{ marginRight: '4px' }}>
                {option?.name || option?.label}
                {index + 1 !== value?.length && ','}
              </Typography>
            ))
      }
      renderInput={params => (
        <TextField {...params} placeholder={value?.length ? '' : placeholder} />
      )}
    />
  )
}

export default AutoComplete
