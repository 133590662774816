import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    maxHeight: 110,
    marginTop: '16px',
  },
  btnWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gap: '16px',
  },
  btnRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    width: '70px',
    height: '40px',
  },
  multiple: {
    width: '155px',
  },
  label: {
    margin: '0px 3px',
  },
  center: {
    alignItems: 'center',
  },
}))
