import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    fontSize: '3rem',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: palette.gray[32],
    marginBottom: spacing(6),
  },
  value: {
    color: palette.gray[64],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  userList: {
    '& :last-of-type': {
      borderBottom: 'none',
    },
  },
  wrapper: {
    display: 'grid',
    marginTop: spacing(13),
    paddingBottom: spacing(15),
    borderBottom: `${spacing(1)} solid #E3E3E3`,
    gridTemplateColumns: '1fr auto',
  },
  details: {
    display: 'grid',
    gridTemplateColumns: `minmax(${spacing(130)}, ${spacing(250)}) minmax(${spacing(
      130
    )}, ${spacing(275)}) minmax(auto, ${spacing(200)})`,
    columnGap: spacing(20),
    textAlign: 'left',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    color: palette.gray[32],
  },
  button: {
    width: spacing(154),
    height: spacing(40),
  },
}))
