import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { STEP_STATUS } from '_utils/constants/service-order'
import useToggle from '_hooks/use-toggle'
import TradesmanReasonRefuseDetail from '_components/modal/tradesman-reason-refuse-detail'

import ServiceOrderAlert from '..'

const TradesmanAlertInfo = ({ open, onClose }) => {
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const [showTradesmanCancelDetail, toggleShowTradesmanCancelDetail] = useToggle()

  const showAlert = useMemo(() => {
    return (
      serviceOrder.stepStatus === STEP_STATUS.PROVIDER_REFUSED_SERVICE_ORDER &&
      serviceOrder?.reasonToRefuse
    )
  }, [serviceOrder])

  return (
    <>
      {showAlert && (
        <ServiceOrderAlert
          title="O prestador recusou o chamado."
          description={`Motivo da recusa:
          ${' '}${serviceOrder?.reasonToRefuse?.get('reason')}. Por favor, selecione um novo
          prestador para este chamado.`}
          onClick={toggleShowTradesmanCancelDetail}
          color="warning"
          isOpen={open}
          isCollapsable
          onClose={onClose}
        />
      )}
      {showTradesmanCancelDetail && (
        <TradesmanReasonRefuseDetail
          handleModal={toggleShowTradesmanCancelDetail}
          tradesmanRefuseDetail={serviceOrder?.otherReasonToRefuse}
        />
      )}
    </>
  )
}

export default TradesmanAlertInfo
