export const FORM_FIELD_NAME = {
  CPF_CNPJ: 'cpfCnpj',
  ISACTIVE: 'isActive',
  ISVIP: 'isVip',
  FORBIDDENCONTACT: 'forbiddenContact',
}

export const ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
  RESET_STATE: 'RESET_STATE',
  UPDATE_CHECKBOX: 'updateCheckbox',
  UPDATE_VIP_CHECKBOX: 'updateVipCheckbox',
  FILL_STATE: 'fillState',
  UPDATE_FORBIDDEN_CONTACT_CHECKBOX: 'updateVipCheckbox',
}

export const INITIAL_STATE = {
  [FORM_FIELD_NAME.CPF_CNPJ]: '',
  [FORM_FIELD_NAME.ISACTIVE]: true,
  [FORM_FIELD_NAME.ISVIP]: true,
  [FORM_FIELD_NAME.FORBIDDENCONTACT]: true,

  page: 0,
  pageSize: 10,
  order: '-is_active,cpf_cnpj',
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...payload,
      }
    case ACTIONS.UPDATE_CHECKBOX:
      return { ...state, [payload?.name]: !state[payload.name] }

    case ACTIONS.UPDATE_VIP_CHECKBOX:
      return { ...state, [payload?.name]: !state[payload.name] }

    case ACTIONS.UPDATE_FORBIDDEN_CONTACT_CHECKBOX:
      return { ...state, [payload?.name]: !state[payload.name] }

    case ACTIONS.FILL_STATE: {
      let result = { ...state }
      Object.keys(payload).forEach(field => {
        result = { ...result, [field]: payload[field] }
      })
      return result
    }
    case ACTIONS.RESET_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
