/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react'
import { Typography } from '@material-ui/core'

const useOnScreen = ref => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
      threshold: 0.5,
    })
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export const AnimatedNumber = ({ start, end, numberToAdd = 1, timeout = 0.95 }) => {
  const [count, setCount] = useState(start)
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    if (isVisible && count < end) {
      const id = window.setTimeout(() => {
        setCount(prevCount => (prevCount + numberToAdd <= end ? prevCount + numberToAdd : end))
      }, timeout)
      return () => window.clearTimeout(id)
    }
  }, [isVisible, count, end])

  return (
    <Typography
      ref={ref}
      style={{
        fontWeight: '700',
        color: '#0943A6',
        fontSize: '48px',
        fontFamily: 'Roboto',
        lineHeight: '40px',
      }}
    >
      {count}+
    </Typography>
  )
}
