import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  modal: {
    '& .MuiDialog-paperWidthSm': { minWidth: 580, maxWidth: 'calc(100vw - 20rem)' },
  },
  headerConteiner: {
    padding: '16px 16px 16px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '2rem',
    color: palette.primary.main,
    fontWeight: 500,
  },
  content: {
    padding: '16px 36px 24px',
  },
  labelInput: {
    marginTop: 24,
    color: palette.gray[100],
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  input: {
    fontSize: '1.6rem',
    '& .MuiInput-input': {
      padding: '16px 8px',
    },
  },
  buttonContainer: {
    padding: '0px 36px 24px 36px',
    justifyContent: 'right',
  },
  button: {
    marginLeft: 16,
    minWidth: 90,
  },
}))
