import React, { useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  DialogContent,
  FormControlLabel,
  Typography,
  Checkbox,
  IconButton,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'

import useStyles from './styles'
import Button from '_/components/button'
import Draggable from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { getSectorsOptionsSelector } from '_/modules/utils/selectors'
import { getSectorsOptions } from '_/modules/utils/actions'
import { getSectorsAgencyByUserId } from '_/modules/agency/actions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const AddCsreferaModal = ({
  initialValues,
  modalOpen,
  handleModal,
  handleSubmit,
  csreferaUsers,
  csreferaData,
  isLoading,
}) => {
  const styles = useStyles()
  const [sectorsData, setSectorsData] = useState([])
  const [acceptHelpRequestData, setAcceptHelpRequestData] = useState(false)
  const [selectedState, setSelectedState] = useState('')
  const dispatch = useDispatch()
  const sectorsOptions = useSelector(getSectorsOptionsSelector)

  useEffect(() => {
    if (initialValues) {
      setSelectedState(csreferaData?.find(user => user.name === initialValues.name))
      setSectorsData(initialValues?.sector)
    }
  }, [csreferaData])

  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  useEffect(() => {
    if (!sectorsOptions.length) {
      dispatch(getSectorsOptions())
    }
  }, [sectorsOptions.length, dispatch])

  const onSelectState = e => {
    setSelectedState(e)
  }
  const handleSubmitCsrefera = () => {
    const updatedSectorsData = sectorsData.map(sector => sector?.id)

    handleSubmit({
      ...selectedState,
      sector: updatedSectorsData.length > 0 ? updatedSectorsData[0] : null,
      acceptHelpRequestData,
    })

    handleModal()
  }

  const handleSelectNewUser = useCallback(async userSelected => {
    onSelectState(userSelected)
    await dispatch(getSectorsAgencyByUserId(userSelected.id)).then(response => {
      if (response?.sector) {
        setSectorsData([response.sector])
      }
    })
  }, [])

  return (
    <Dialog
      open={modalOpen}
      maxWidth="xl"
      // disableEscapeKeyDown
      onClose={handleModal}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      root={styles.modal}
      disabledSubmit={isLoading}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} className={styles.titleBlue} id="draggable-dialog-title">
        <Typography className={styles.title}>
          Usuário vinculado a intermediária
          <IconButton
            aria-label="close"
            onClick={handleModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent container className={styles.wrapper}>
        <FormControl fullWidth>
          <Grid className={styles.line}>
            <Grid className={styles.select}>
              <InputLabel id="select-states">Usuário</InputLabel>
              <Select
                MenuProps={menuProps}
                labelId="select-states"
                label="Usuário"
                value={selectedState || ''}
                onChange={e => handleSelectNewUser(e.target.value)}
                className={styles.contentState}
                renderValue={selected => {
                  return selected ? selected.name : ''
                }}
              >
                {csreferaUsers
                  ?.sort((x, y) => (x.name < y.name ? -1 : 1))
                  .map(user => (
                    <MenuItem value={user} key={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid className={styles.select}>
              <Autocomplete
                id="sectors"
                options={sectorsOptions.filter(x => !sectorsData.find(y => y.sector === x.sector))}
                className={[styles.contentState]}
                onChange={(e, value) => setSectorsData(value ? [value] : [])}
                value={sectorsData[0] || null}
                classes={{
                  tag: styles.customTag,
                }}
                getOptionLabel={option => option.sector}
                renderInput={params => <TextField {...params} variant="standard" label="Setores" />}
              />
            </Grid>
          </Grid>
          <Grid className={styles.line}>
            <FormControlLabel
              control={
                <Checkbox
                  value={acceptHelpRequestData}
                  onChange={e => setAcceptHelpRequestData(e.target.checked)}
                  className={styles.formControl}
                  color="primary"
                  name="accept_pa"
                />
              }
              label={
                <Typography
                  checked={acceptHelpRequestData}
                  disabled={isLoading}
                  className={styles.formControl}
                >
                  Atende item de ajuda
                </Typography>
              }
            />
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions className={styles.dialogAction}>
        <Button
          className={styles.button}
          onClick={handleModal}
          disabled={isLoading}
          color="red"
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          className={styles.button}
          onClick={handleSubmitCsrefera}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddCsreferaModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  csreferaUsers: PropTypes.shape({}).isRequired,
  csreferaData: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
}

AddCsreferaModal.defaultProps = {
  isLoading: false,
}

export default AddCsreferaModal
