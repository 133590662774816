import { defineAction } from '_utils/redux'
import * as dayPeriodServices from '_services/day-period'

export const GET_DAY_PERIODS = defineAction('GET_DAY_PERIODS')

export const getDayPeriods = () => (dispatch, getState) => {
  dispatch({
    type: GET_DAY_PERIODS.ACTION,
    payload: dayPeriodServices.getDayPeriods(getState().user.authToken),
  })
}
