import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(16),
    marginBottom: spacing(8),
  },
  button: {
    maxWidth: spacing(195),
    height: spacing(50),
    border: `1px solid ${palette.gray[12]}`,
    borderRadius: spacing(4),
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  content: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: spacing(16),
    width: '100%',
    flexDirection: 'column',
  },
  icon: {
    height: spacing(20),
    width: spacing(20),
  },
  iconContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    height: spacing(36),
    width: spacing(36),
    minWidth: spacing(36),
    background: palette.primary.lightest,
  },
  circle: {
    height: spacing(18),
    width: spacing(18),
    borderRadius: '50%',
    background: palette.yellow.light,
  },
  yellow: {
    background: palette.yellow.lightest,
  },
  orderDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(16),
    width: '100%',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  orderDetailStepStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(16),
    width: '100%',
  },
  card: {
    padding: spacing(16),
    border: `1px solid ${palette.gray[12]}`,
    borderRadius: '10px',
    minHeight: spacing(176),
    width: '100%',
    display: 'flex',
    gap: spacing(16),

    [breakpoints.down('md')]: {
      minHeight: spacing(255),
    },

    [breakpoints.down('xs')]: {
      gap: spacing(8),
      padding: spacing(16, 12),
      height: spacing(250),
      minWidth: '100%',
    },
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(10),
  },
  cardTitle: {
    fontSize: spacing(16),
    fontWeight: 'bold',
  },
  cardSubTitle: {
    fontSize: spacing(14),
    color: palette.gray[64],
    display: 'flex',
    alignItems: 'center',
    gap: spacing(8),
  },
  textBold: {
    fontWeight: 'bold',
  },
  cardText: {
    color: palette.gray[64],
    fontSize: spacing(12),
  },
  infoCardDate: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: spacing(30),
    flex: 1,
    flexWrap: 'wrap',

    [breakpoints.down('md')]: {
      gap: spacing(10),
      flexDirection: 'column',
    },
  },
  serviceType: {
    color: palette.primary.main,
    fontSize: spacing(20),
    fontWeight: 'bold',
  },
  orderAttachmentsContainer: {
    gridArea: 'attachments',
    width: '100%',
  },
  textField: {
    color: `${palette.gray[64]} !important`,
    lineHeight: '2rem',
  },
  WhatsAppIcon: {
    color: 'green',
    height: spacing(20),
    width: spacing(20),
  },
  iconButton: {
    justifyContent: 'flex-start',
  },
  dealine: {
    '& .ReferaTimer--label': {
      fontFamily: `${Theme.Typography.FontFamily.DMSans} !important`,
    },
  },
}))
