import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

const Footer = ({ className }) => {
  const styles = useStyles()

  return (
    <Grid component="footer" className={classnames(styles.footer, className)}>
      <Typography variant="body2" component="p" className={styles.footerContent}>
        Todos os direitos reservados - Refera Tecnologia S.A.
      </Typography>
    </Grid>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

Footer.defaultProps = {
  className: '',
}

export default Footer
