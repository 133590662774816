import { schema } from 'normalizr'
import { List } from 'immutable'

import Model from './model'

class BudgetTemplate extends Model({
  installments: undefined,
  name: undefined,
  proposedSolution: undefined,
  budgetTemplateServices: List(),
  createdAt: undefined,
  diagnosis: undefined,
  tradesman: undefined,
  warrantyMonths: undefined,
  id: undefined,
}) {}

const budgetTemplateNewSchema = new schema.Entity(
  'budgetTemplate',
  {},
  {
    processStrategy: entity => new BudgetTemplate(entity),
  }
)

const budgetTemplateSchema = [budgetTemplateNewSchema]

export { budgetTemplateSchema, BudgetTemplate as default }
