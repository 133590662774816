import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  iconBtn: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  inputField: {
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
  errorMessage: {
    color: '#C90000',
    marginBottom: 10,
    fontSize: 12,
    fontFamily: 'DM Sans',
  },
}))
