import React, { useState } from 'react'
import Button from '_components/button'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormHelperText,
} from '@material-ui/core'
import { MoneyInput } from '@refera/ui-web'

import useStyles from './styles'

const CoverageModal = ({
  open,
  fields,
  append,
  remove,
  currentIndex,
  currentValue,
  setCurrentValue,
  handleModalClose,
}) => {
  const styles = useStyles()

  const [modalError, setModalError] = useState({ budgetValue: null, coveragePercentage: null })

  const handleCheckError = () => {
    const hasBudgetValue = currentValue.budgetValue.length > 0 || currentValue.budgetValue > 0
    const hasCoverageValue = currentValue.coveragePercentage.length > 0
    const hasBoth = hasBudgetValue && hasCoverageValue

    const isEditing = fields.some(item => item.id === currentValue.id)
    const valueExists = fields.some(
      item => item.budgetValue === currentValue.budgetValue && item.id !== currentValue.id
    )

    if (valueExists) {
      setModalError({
        budgetValue: 'Valor já existe na regra existente.',
        coveragePercentage: null,
      })
      return true
    }

    if (!hasBoth && !isEditing) {
      setModalError({ budgetValue: 'Campo obrigatóro.', coveragePercentage: 'Campo obrigatóro.' })
      return true
    }

    if (!hasBudgetValue && !isEditing) {
      setModalError({ budgetValue: 'Campo obrigatóro.', coveragePercentage: null })
      return true
    }

    if (!hasCoverageValue && !isEditing) {
      setModalError({ budgetValue: null, coveragePercentage: 'Campo obrigatóro.' })
      return true
    }

    setModalError({ budgetValue: null, coveragePercentage: null })

    return false
  }

  const handleModalSubmit = () => {
    const hasError = handleCheckError()

    if (hasError) {
      return
    }

    if (currentIndex !== null) {
      append(currentValue)
      remove(currentIndex)
    } else {
      append(currentValue)
    }
    handleModalClose()
  }

  const handleChange = (field, value) => {
    if (field === 'coveragePercentage' && value.match(/[^0-9]/)) {
      return
    }

    setCurrentValue(prev => ({ ...prev, [field]: value }))
  }

  return (
    <Dialog style={{ padding: '40px' }} open={open} onClose={handleModalClose}>
      <DialogTitle style={{ color: '#1671ED' }}>Configuração de Cobertura</DialogTitle>
      <DialogContent>
        <MoneyInput
          decimalScale={2}
          name={`valueData[${currentIndex}].budgetValue`}
          style={{ fontSize: '14px' }}
          label="Valor do Orçamento (R$)"
          value={parseFloat(currentValue.budgetValue) || '0,00'}
          onBlur={handleCheckError}
          onChange={e => handleChange('budgetValue', e.floatValue)}
        />
        {modalError.budgetValue && (
          <FormHelperText style={{ marginTop: '10px', color: 'red' }}>
            {modalError.budgetValue}
          </FormHelperText>
        )}
        <TextField
          fullWidth
          margin="normal"
          onBlur={handleCheckError}
          name={`valueData[${currentIndex}].coveragePercentage`}
          label="Percentual de Cobertura (%)"
          value={currentValue.coveragePercentage}
          onChange={e => handleChange('coveragePercentage', e.target.value)}
          inputProps={{ inputMode: 'numeric' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {modalError.coveragePercentage && (
          <FormHelperText style={{ marginTop: '2px', color: 'red' }}>
            {modalError.coveragePercentage}
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '20px 10px' }}>
        <Button
          className={styles.buttonRed}
          form="coverageModalForm"
          onClick={handleModalClose}
          color="secondary"
        >
          Cancelar
        </Button>
        <Button className={styles.buttonBlue} onClick={() => handleModalSubmit()} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CoverageModal
