import PropTypes from 'prop-types'

const dataAttributesValidator = (props, propName, componentName) => {
  const dataAttributes = props[propName]
  const invalidKeys = Object.keys(dataAttributes).filter(key => !key.startsWith('data-'))

  if (invalidKeys.length > 0) {
    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}. Keys must start with "data-".`
    )
  }

  return null
}

dataAttributesValidator.isRequired = PropTypes.object.isRequired

export { dataAttributesValidator }
