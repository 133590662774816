import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Loader, Modal } from '@refera/ui-web'

import Select from '_components/common/select'

import useStyles from '../styles'

import { useDispatch } from 'react-redux'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { getBudgetReceivablesSpreadsheets } from '_/modules/finance/actions'

import moment from 'moment'
import { notEmptyPayload } from '_/utils/helpers'
import humps from 'humps'
import { downloadABudgetReceivablesSpreadsheet } from '_/services/finance'

export const DownloadSpreadsheet = ({ open, close, filters }) => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [downloading, setDownloading] = useState(false)
  const [spreadsheet, setSpreadsheet] = useState('')
  const [spreadsheetOptionsList, setSpreadsheetOptionsList] = useState([])

  const { showToast } = useToast()

  const spreadsheetOptions = useMemo(() => {
    const selectOptions = [{ label: 'Selecione', value: '' }]
    spreadsheetOptionsList?.forEach(item => {
      selectOptions.push({ label: item?.spreadsheet, value: item?.id })
    })
    return selectOptions
  }, [spreadsheetOptionsList])

  const fetchSpreadsheets = async () => {
    setLoading(true)
    await dispatch(getBudgetReceivablesSpreadsheets())
      .then(res => {
        setSpreadsheetOptionsList(res?.results)
        setLoading(false)
      })
      .catch(error => {
        showToast({ message: formatErrorMessage(error), type: 'error' })
        setLoading(false)
      })
  }

  const handleDownload = useCallback(async () => {
    try {
      setDownloading(() => true)

      const params = humps.decamelizeKeys(notEmptyPayload({ ...filters }))
      const templateId = spreadsheet
      const paramString = new URLSearchParams(params).toString()

      const response = await downloadABudgetReceivablesSpreadsheet(templateId, paramString)

      const now = moment().format('YYYYMMDDHHmmss')
      const name = spreadsheetOptions.filter(option => option.value === spreadsheet)?.[0]?.label
      const fileName = `${name}_${now}.xlsx`

      const url = window.URL.createObjectURL(response.data)
      const downloadLink = document.createElement('a')

      downloadLink.href = url
      downloadLink.setAttribute('download', fileName)
      document.body.appendChild(downloadLink)
      downloadLink.click()

      setDownloading(() => false)
      close()
    } catch (error) {
      setDownloading(() => false)
      showToast({
        type: 'error',
        message: formatErrorMessage(error),
      })
    }
  }, [filters, spreadsheet, spreadsheetOptions, close])

  useEffect(() => {
    fetchSpreadsheets()
  }, [])

  const renderActionButtons = useMemo(() => {
    return (
      <Grid className={styles.modalButtons}>
        <Button color="red" variant="secondary" onClick={close}>
          Fechar
        </Button>
        <Button disabled={!spreadsheet} color="primary" variant="primary" onClick={handleDownload}>
          OK
        </Button>
      </Grid>
    )
  }, [handleDownload, spreadsheet, close])

  return (
    <>
      <Loader hasBackdrop open={downloading} />
      <Modal
        open={open}
        className={styles.modal}
        title="Modelo de planilha"
        onClose={close}
        actionsButtons={renderActionButtons}
        onBackdropClick={close}
      >
        <Grid className={styles.wrapper}>
          <Select
            label="Planilha"
            required
            defaultValue=""
            value={spreadsheet}
            name="spreadsheet"
            onChange={e => setSpreadsheet(e.target.value)}
            options={spreadsheetOptions}
            isLoading={loading}
          />
        </Grid>
      </Modal>
    </>
  )
}
