import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  moduleName: {
    fontSize: spacing(14, '!important'),
    lineHeight: spacing(20),
    fontWeight: 'normal',
  },
  button: {
    padding: spacing(4, 6, '!important'),
    borderRadius: spacing(8, '!important'),
    gap: spacing(8),
  },
  modulesMenuContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto',
    padding: spacing(0, 8),
    gridGap: spacing(8),
  },
  moduleItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: spacing(8, '!important'),
    padding: spacing(16, '!important'),
    gap: spacing(8),

    '&:hover': {
      backgroundColor: `${Theme.Colors.Primary.Lightest} !important`,
    },
  },
  moduleIcon: {
    width: spacing(32),
    height: spacing(32),
    margin: spacing(0, '!important'),
  },
  moduleLabel: {
    fontSize: spacing(12, '!important'),
    lineHeight: spacing(20),
  },
}))
