/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import useStyles from './styles'
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core'
import * as Modal from '_components/modal/generic-modal'

export const SaveModelsModal = ({
  open,
  handleConfirm = () => {},
  handleModal = () => {},
  isEditing = false,
  model = null,
}) => {
  const styles = useStyles()
  const [name, setName] = useState('')
  const [saveValue, setSaveValue] = useState(false)

  useEffect(() => {
    if (isEditing && model) {
      setName(model?.name)
      setSaveValue(model?.saveValue)
    }
  }, [isEditing, model])

  return (
    <Modal.Root open={open} onClose={() => handleModal()} className={styles.modalMain} keepMounted>
      <Modal.TitleModal title="Modelo" />

      <Modal.Content className={styles.modalContent}>
        <Grid className={styles.wrapper}>
          <TextField
            id="name"
            key="name"
            label="Nome"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <FormControlLabel
            id="saveValue"
            key="saveValue"
            label="Armazenar os valores"
            onChange={e => setSaveValue(e.target.checked)}
            control={<Checkbox checked={saveValue} />}
          />
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Modal.ButtonRed onClick={() => handleModal()}>Cancelar</Modal.ButtonRed>
        <Modal.ButtonFullBlue
          disabled={!name?.length > 0}
          onClick={() =>
            handleConfirm({
              name,
              saveValue,
            })
          }
        >
          Salvar
        </Modal.ButtonFullBlue>
      </Modal.Actions>
    </Modal.Root>
  )
}
