import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ palette, spacing }) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  mainTable: {
    marginTop: '16px',
    width: 'auto',
    height: '650px',
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: `${Theme.Typography.FontSize.Large}px`,
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  button: {
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,
    padding: spacing(8, 16),
    margin: '0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',

    backgroundColor: 'transparent',
    fontSize: spacing(14),
    borderRadius: '75px',

    '&:not(:disabled):hover': {
      opacity: '0.8',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'visible',
      color: palette.gray[32],
      backgroundColor: palette.gray[4],
    },
  },
  buttonRed: {
    background: 'transparent',
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,

    '&:not(:disabled):hover': {
      background: palette.error.main,
      color: palette.gray[4],
    },

    '&:disabled': {
      border: `1px solid ${palette.error.main}`,
    },
  },
  buttonFullBlue: {
    background: palette.primary.main,
    color: palette.gray[4],
    border: 'none',

    '&:not(:disabled):hover': {
      background: palette.primary.main,
      opacity: '0.8',
    },

    '&:disabled': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
}))
