import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  switch: {
    margin: '5px 0 0 0',
  },
  error: {
    color: Theme.Colors.Red.Base,
    fontSize: '1rem',
    marginTop: spacing(4),
  },
  label: {
    fontSize: '1.2rem',
    color: '#B4B4B4',
  },
}))
