import moment from 'moment'

const CONTACT_FIELDS = ['contactNumber', 'firstContactSecondary', 'secondContactSecondary']
const DATE_FIELDS = ['firstSuggestionDate', 'secondSuggestionDate', 'thirdSuggestionDate']
const START_TIME_FIELDS = ['firstStartTime', 'secondStartTime', 'thirdStartTime']
const END_TIME_FIELDS = ['firstEndTime', 'secondEndTime', 'thirdEndTime']

export const dataFormatter = user => {
  const sortValues = (sortedKeys, options) => {
    const complementKeys = options?.complementKeys ?? []
    const resultKeyA = options?.resultKeyA ?? 'data'
    const resultKeyB = options?.resultKeyB ?? ''

    const hasComplementKey = complementKeys?.length && resultKeyB?.length

    return sortedKeys.reduce((acc, currentKey, currentIndex) => {
      const complementData = hasComplementKey
        ? {
            [resultKeyB]: user[complementKeys[currentIndex]]?.length
              ? user[complementKeys[currentIndex]]
              : null,
          }
        : {}

      if (user?.[currentKey]?.length) {
        return [
          ...acc,
          {
            [resultKeyA]: user[currentKey],
            ...complementData,
          },
        ]
      }

      if (options?.keepEmpty) {
        return [
          ...acc,
          {
            [resultKeyA]: null,
            ...complementData,
          },
        ]
      }

      return acc
    }, [])
  }

  const phones = sortValues(CONTACT_FIELDS)
  const date = sortValues(DATE_FIELDS, {
    keepEmpty: true,
  })
  const time = sortValues(START_TIME_FIELDS, {
    complementKeys: END_TIME_FIELDS,
    resultKeyA: 'startTime',
    resultKeyB: 'endTime',
    keepEmpty: true,
  })

  return {
    phones,
    date,
    time,
  }
}

export const PAYLOAD_KEY = {
  DATE: 'date',
  PHONES: 'phones',
  TIME: 'time',
}

export const payloadFormatter = (values, type) => {
  if (type === PAYLOAD_KEY.DATE) {
    return values.reduce((acc, currentDate, currentIndex) => {
      return {
        ...acc,
        [DATE_FIELDS[currentIndex]]: currentDate.data
          ? moment(currentDate.data).format('yyyy-MM-DD')
          : null,
      }
    }, {})
  }

  if (type === PAYLOAD_KEY.PHONES) {
    return values
      .filter(value => value.data?.length)
      .reduce((acc, currentPhone, currentIndex) => {
        return {
          ...acc,
          [CONTACT_FIELDS[currentIndex]]: currentPhone.data,
        }
      }, {})
  }

  if (type === PAYLOAD_KEY.TIME) {
    return values.reduce((acc, currentDate, currentIndex) => {
      return {
        ...acc,
        [START_TIME_FIELDS[currentIndex]]: currentDate.startTime
          ? moment(currentDate.startTime).format('HH:mm:ss')
          : null,
        [END_TIME_FIELDS[currentIndex]]: currentDate.endTime
          ? moment(currentDate.endTime).format('HH:mm:ss')
          : null,
      }
    }, {})
  }

  return {}
}
