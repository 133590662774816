import { Record, Map, fromJS, List } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import HelpRequest, { helpRequestSchema } from '_models/help-request'

import {
  GET_HELP_REQUESTS,
  GET_HELP_REQUEST_BY_ID,
  GET_HELP_REQUESTS_BY_SERVICE_ORDER_ID,
  GET_HELP_REQUEST_PROCEDURES,
  DELETE_PROCEDURE_REQUEST,
  SET_DEFAULT_HELP_REQUEST_FILTER,
  UPDATE_HELP_REQUEST_FILTER,
  CLEAR_FEEDBACK_MESSAGE,
  UPDATE_AVAILABILITY,
  CREATE_HELP_REQUEST_PROCEDURE,
  EDIT_HELP_REQUEST_PROCEDURE,
} from './actions'

const INITIAL_STATE = new Record({
  results: Map(),
  procedures: List(),
  count: '',
  order: Map(),
  feedbackMessage: '',
  currentHelpRequests: List(),
  helpRequestPendings: [],
  filter: Map({
    agency: '',
    responsible: '',
    openedAt: '',
    resolvedAt: '',
    serviceOrder: '',
    fromTo: '',
    subject: '',
    message: '',
    status: '',
    page: 0,
    pageSize: 10,
    orderBy: '',
    serviceOrderStatus: '',
    requester: '',
  }),
  isFilterDirty: false,
})()

const getHelpRequestsById = (state, { payload }) => {
  return state.setIn(['results', payload.id.toString()], new HelpRequest(payload))
}

const helpRequests = createReducer(INITIAL_STATE, {
  [GET_HELP_REQUEST_BY_ID.FULFILLED]: getHelpRequestsById,
  [GET_HELP_REQUESTS.FULFILLED]: (state, { payload }) => {
    const normalizeHelpRequests = normalize(payload.results, helpRequestSchema)
    return state
      .set('results', fromJS(normalizeHelpRequests.entities.helpRequest))
      .set('count', payload.count)
      .set('order', normalizeHelpRequests.result)
  },

  [GET_HELP_REQUESTS_BY_SERVICE_ORDER_ID.FULFILLED]: (state, { payload }) => {
    return state.set('currentHelpRequests', List(payload))
  },

  [GET_HELP_REQUEST_PROCEDURES.FULFILLED]: (state, { payload }) => {
    return state.set('procedures', List(payload?.results))
  },

  [DELETE_PROCEDURE_REQUEST.FULFILLED]: (state, { payload }) => {
    return state.set('procedures', List(payload))
  },

  [CREATE_HELP_REQUEST_PROCEDURE.FULFILLED]: (state, { payload }) => {
    return state
      .update('procedures', prevProcedures => List([payload.details, ...prevProcedures.toJS()]))
      .updateIn(['results', String(payload.details.helpRequest)], prevState => {
        const updatedHelpRequest = {
          ...prevState?.toJS(),
          datetimeResolved: payload.details.datetimeResolved,
        }

        return new HelpRequest(updatedHelpRequest)
      })
      .set('feedbackMessage', payload?.message ?? '')
  },

  [EDIT_HELP_REQUEST_PROCEDURE.FULFILLED]: (state, { payload }) => {
    return state
      .update('procedures', prevProcedures => {
        const updatedProcedures = prevProcedures.toJS()?.map(procedure => {
          if (procedure.id === payload.details.id) {
            return payload.details
          }

          return procedure
        })

        return List(updatedProcedures)
      })
      .updateIn(['results', String(payload.details.helpRequest)], prevState => {
        const updatedHelpRequest = {
          ...prevState?.toJS(),
          datetimeResolved: payload.details.datetimeResolved,
        }

        return new HelpRequest(updatedHelpRequest)
      })
      .set('feedbackMessage', payload?.message ?? '')
  },

  [UPDATE_AVAILABILITY.FULFILLED]: (state, { payload }) => {
    // TODO: Update the help request itself as well once PREF-4404 is merged into the dev
    return state.set('feedbackMessage', payload.message)
  },

  [CLEAR_FEEDBACK_MESSAGE]: state => {
    return state.set('feedbackMessage', '')
  },

  [SET_DEFAULT_HELP_REQUEST_FILTER]: (
    state,
    { payload: { isTradesman, isIntermediary, isAdmin, userId } }
  ) => {
    return state
      .update('filter', prevFilter =>
        Map({
          ...prevFilter.toJS(),
          responsible: isIntermediary ? userId : '',
          agency: isAdmin ? 'myAgencies' : '',
          status: isTradesman || isIntermediary || isAdmin ? 'pending' : '',
        })
      )
      .set('isFilterDirty', true)
  },

  [UPDATE_HELP_REQUEST_FILTER]: (state, { payload }) => {
    return state.update('filter', prevFilter => Map({ ...prevFilter.toJS(), ...payload }))
  },
})

export default helpRequests
