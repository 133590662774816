/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core'
import { useParams } from '@reach/router'
import { Check } from '@material-ui/icons'
import { useDispatch } from 'react-redux'

import Button, { BUTTON_COLOR } from '_components/button'
import { getServiceApproved } from '_modules/service-orders/actions'

import useStyles from './styles'

const CHECK = 'Check'

export const SUCCESS_MODAL = { icon: 'Check', title: '' }

export default function ConfirmCancelModal(props) {
  const { confirmCancelModal, setConfirmCancelModal } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()

  function GetIconButton() {
    if (confirmCancelModal.type?.icon === CHECK)
      return (
        <IconButton disableRipple className={classes.titleIconSuccess}>
          <Check className={classes.iconeSVG} />
        </IconButton>
      )
  }

  const handleConfirm = async () => {
    await Promise.resolve(dispatch(getServiceApproved(serviceOrderId))).then(
      setConfirmCancelModal({ ...confirmCancelModal, isOpen: false })
    )
    window.location.reload()
  }

  return (
    <Dialog open={confirmCancelModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Box mt={10} />
        <GetIconButton />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="">{confirmCancelModal.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          color={BUTTON_COLOR.RED}
          variant="outlined"
          className={classes.button}
          onClick={() => setConfirmCancelModal({ ...confirmCancelModal, isOpen: false })}
        >
          {confirmCancelModal.textNoButton ? confirmCancelModal.textNoButton : 'Não'}
        </Button>

        {confirmCancelModal ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleConfirm}
          >
            {confirmCancelModal.textYesButton ? confirmCancelModal.textYesButton : 'Sim'}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setConfirmCancelModal({ ...confirmCancelModal, isOpen: false })}
          >
            {confirmCancelModal.textYesButton ? confirmCancelModal.textYesButton : 'Sim'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
