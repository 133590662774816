import * as reasonsServices from '_services/reasons'
import { defineAction } from '_utils/redux'

export const GET_REASONS = defineAction('GET_REASONS')
export const GET_REASONS_TO_REFUSE_SERVICE_ORDER = defineAction(
  'GET_REASONS_TO_REFUSE_SERVICE_ORDER'
)

export const getReasons = () => (dispatch, getState) =>
  dispatch({
    type: GET_REASONS.ACTION,
    payload: reasonsServices.getReasons(getState().user.authToken),
  })

export const getReasonsToRefuseServiceOrder = () => (dispatch, getState) =>
  dispatch({
    type: GET_REASONS_TO_REFUSE_SERVICE_ORDER.ACTION,
    payload: reasonsServices.getReasonsToRefuseServiceOrder(getState().user.authToken),
  })
