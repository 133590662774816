import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    padding: '60px 25% 20px 25%',

    '@media (max-width: 450px)': {
      padding: '60px 10% 20px 10%',
    },
  },
  icon: {
    width: '220px',
    height: '186px',
    marginBottom: '50px',
  },
  welcomeWrapper: {
    width: '100%',
    marginBottom: '40px',
  },
  title: {
    fontFamily: 'DM Sans',
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '-0.02em',
    textAlign: 'left',
    marginBottom: '16px',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  alternative: {
    fontFamily: 'DM Sans',
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '40px',
  },
  link: {
    fontSize: spacing(14, '!important'),
    fontWeight: '400 !important',
    marginBottom: '12px !important',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 25%',
    backgroundColor: '#ECF4FE',

    '@media (max-width: 450px)': {
      padding: '20px 10%',
    },
  },
  footerTitle: {
    fontFamily: 'DM Sans',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '-0.02em',
    textAlign: 'left',
    marginBottom: '8px',
  },
  footerText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  footerButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '18px',
    gap: '18px',
  },
  footerButton: {
    padding: '0px',
  },
  footerButtonText: {
    color: '#1671ED',
    paddingLeft: '5px',
    fontSize: spacing(16, '!important'),
    textTransform: 'none',
  },
  videoThumbnail: {
    display: 'flex',
    width: '242px',
    height: '130px',
  },
  videoImage: {
    width: '100%',
    height: '100%',
  },
  videoText: {
    fontFamily: 'DM Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    letterSpacing: '-0.32px',
    marginTop: '6px',
  },
  contentSpacing: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
  },
  videoContent: {
    flexDirection: 'row',
    gap: '17px',
    flexWrap: 'wrap',
  },
  thumbnailWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
