import { Box, Checkbox, Container, Divider, Modal, Typography } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@refera/ui-web'

import useStyles from './styles'
import { getPrivacyPolicyMessage } from './privacyPolicyContent'
import { updateUser } from '_/modules/authentication/actions'
import { userSelector } from '_/modules/authentication/selectors'

export function AcceptPrivacyPolicyModal() {
  const styles = useStyles()
  const [open, setOpen] = useState(true)
  const [checkboxDisabled, setCheckboxDisabled] = useState(true)
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const scrollDivRef = useRef(null)

  const handleAccept = () => {
    Promise.resolve(dispatch(updateUser({ termsAccepted: true }))).then(() => {
      setOpen(false)
    })
  }

  const handleScroll = e => {
    const reachedBottom =
      Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1

    if (reachedBottom) {
      setCheckboxDisabled(false)
    }
  }

  useEffect(() => {
    const container = scrollDivRef.current
    if (container?.scrollHeight <= container?.clientHeight) {
      setCheckboxDisabled(false)
    }
  }, [scrollDivRef.current])

  return (
    <Modal open={open} onClose={() => {}} disableEscapeKeyDown>
      <Box className={styles.container}>
        <Typography className={styles.header}>
          Termos de Uso e Política de Privacidade da Refera
        </Typography>
        <Divider />
        <Container className={styles.contentContainer} onScroll={handleScroll} ref={scrollDivRef}>
          <Typography className={styles.content}>
            {user?.lgpdLinks && getPrivacyPolicyMessage(user?.lgpdLinks[0], user?.lgpdLinks[1])}
          </Typography>
        </Container>
        <Divider />
        <Box className={styles.footer}>
          <Box className={styles.checkboxContainer}>
            <Checkbox
              sx={{
                color: '#B4B4B4 !important',
                '&.Mui-checked': {
                  color: '#1671ED !important',
                },
              }}
              checked={checked}
              disabled={checkboxDisabled}
              onChange={e => setChecked(e.target.checked)}
            />
            <Typography className={styles.checkboxLabel}>
              Concordo que li e aceito os Termos de Uso e Politicas de privacidade da Refera.
            </Typography>
          </Box>
          <Box className={styles.actionButtonsContainer}>
            <Button color="primary" variant="primary" onClick={handleAccept} disabled={!checked}>
              Concordar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
