import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Preview } from '@refera/ui-web'

const DEFAULT_STATE = {
  open: false,
  selectedIndex: undefined,
}

const PreviewComponent = ({ images, open, selectedIndex, setPreviewState }) => {
  const handleClose = useCallback(() => {
    setPreviewState(DEFAULT_STATE)
  }, [])

  const previewImages = useMemo(
    () =>
      images?.map((item, index) =>
        item.id
          ? {
              id: item.id,
              url: item.file,
            }
          : {
              id: index,
              url: URL.createObjectURL(item),
            }
      ),
    [images]
  )

  return (
    <Preview
      open={open}
      onClose={handleClose}
      selectedIndex={selectedIndex}
      images={previewImages}
    />
  )
}

PreviewComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, file: PropTypes.string }))
    .isRequired,
  open: PropTypes.bool,
  selectedIndex: PropTypes.number,
}

PreviewComponent.defaultProps = DEFAULT_STATE

export default PreviewComponent
