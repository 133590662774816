import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import {
  Button as MuiButton,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Slide,
  SwipeableDrawer,
  Typography,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useParams } from '@reach/router'
import Theme from '@refera/ui-core'
import { CloseCircle } from '@refera/ui-icons'
import { Alert, Button, Toast } from '@refera/ui-web'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { getPaymentAnticipationInfo, getTradesmanById } from '_modules/service-orders/actions'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { getVerificationToken } from '_modules/authentication/actions'
import { getAllNotes } from '_modules/messages/actions'
import { getAllNotesSelector } from '_modules/messages/selector'
import useRolePermission from '_hooks/use-role-permission'
import useFetchCall from '_hooks/use-fetch-call'
import useToggle from '_hooks/use-toggle'
import IconButton, { ICON } from '_components/svg/icon-button'
import DialogModal, { WARNING_MODAL } from '_components/modal/modal-dialog'
import GeneralAnnotation from '_components/annotations/general-annotation'
import NewCommentModal from '_components/modal/new-structure-comment-modal'

import HelpForm from '../help/help-form'
import PaymentAnticipationForm from '../payment-anticipation/payment-anticipation-form'
import JustifiedDelayForm from '../justified-delay/justified-delay-form'

import { ACTION, reducer } from './reducer'
import { BUTTON_OPTIONS, BUTTON_VALUES, INITIAL_STATE, TAB_OPTIONS } from './constants'
import useStyles from './styles'
import {
  DELETE_TRADESMAN_ANTICIPATION,
  UPDATE_PAYMENT_ANTICIPATIONS,
  GET_VERIFY_UPDATE_ANTICIPATIONS,
} from '_/modules/finance/actions'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

const defaultToast = { isOpen: false, title: '', severity: '' }
const defaultErrorMessage = 'Não foi possível executar esta ação, tente novamente.'

const Annotations = ({ handleAnnotationDrawer, open, initialState = {} }) => {
  /**
   * TODO : Diminuir a complexidade do componente
   *
   *
   */
  const dispatch = useDispatch()
  const [localState, localDispatch] = useReducer(reducer, INITIAL_STATE)

  const annotations = useSelector(getAllNotesSelector)
  const styles = useStyles()
  const { serviceOrderId } = useParams()
  const { isAdmin, isIntermediary: isIntermediaryUser, isSAAS } = useRolePermission()

  const [newCommentModal, setNewCommentModal] = useState({ isOpen: false, title: '' })
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const anchorRef = useRef(null)
  const [helpObj, setHelpObj] = useState(null)
  const [tradesmanList, setTradesmanList] = useState([])
  const [toastInfo, setToastInfo] = useState(defaultToast)
  const [toggleHelpView] = useToggle()

  const serviceOrder = useSelector(currentServiceOrderSelector)

  const [openJustifiedDelayForm, toggleJustifiedDelayForm] = useToggle()

  const [modalSelectProviderDialog, setModalSelectProviderDialog] = useState({
    isOpen: false,
    subTitle: '',
  })
  const [openHelpForm, toggleHelpForm] = useToggle()
  const [helpObject, setHelpObject] = useState(null)
  const [setSubject] = useState()

  const [paymentAnticipationForm, togglePaymentAnticipationForm] = useToggle()
  const [openProposal, setOpenProposal] = useState(false)

  const buttons = useMemo(() => {
    if (!isAdmin) {
      return BUTTON_OPTIONS.filter(
        button => !button.adminOnly && !(isSAAS && button.value === BUTTON_VALUES.HELP_REQUEST)
      )
    }

    return BUTTON_OPTIONS
  }, [isAdmin])

  const handleOpenSelectProviderModal = () => {
    setModalSelectProviderDialog({
      isOpen: true,
      subTitle: 'Para abrir um item de ajuda neste chamado, antes aloque um prestador a ele.',
      type: WARNING_MODAL,
    })
  }

  const onCommentModal = useCallback(() => {
    setNewCommentModal({
      isOpen: true,
      title: 'Comentário',
    })
  }, [setNewCommentModal, serviceOrderId])

  const handleOpenProviderAllocatedModal = () => {
    setModalSelectProviderDialog({
      isOpen: true,
      subTitle:
        'Para abrir uma justificativa de atraso neste chamado, antes aloque um prestador a ele.',
      type: WARNING_MODAL,
    })
  }

  const handleToggle = useCallback(() => {
    localDispatch({ type: ACTION.TOGGLE_MENU_BUTTON })
  }, [])

  const handleClose = useCallback(
    event => {
      if (anchorRef?.current?.contains(event.target)) {
        return
      }

      localDispatch({ type: ACTION.CLOSE_MENU_BUTTON })
    },
    [anchorRef.current]
  )

  const handleClearMessage = () => {
    if (helpObj?.answer?.length > 0 && helpObj?.resolved) {
      toggleHelpView()
    } else {
      toggleJustifiedDelayForm()
    }
    setHelpObj(undefined)
  }

  const getAllTradesman = async () => {
    const IdList = []
    serviceOrder?.budget
      ?.map(x => {
        return x?.tradesman
      })
      .map(tradesman => {
        if (!IdList.includes(tradesman?.id)) {
          IdList.push(tradesman?.id)
          setTradesmanList([...tradesmanList, tradesman])
        }
        return null
      })

    if (serviceOrder?.tradesman > 0) {
      await Promise.resolve(dispatch(getTradesmanById(serviceOrder?.tradesman))).then(res => {
        const tradesman = {
          id: serviceOrder?.tradesman,
          name: res?.user?.name,
          phone: res?.user?.phone,
        }
        setTradesmanList([...tradesmanList, tradesman])
      })
    }
  }

  const successToast = () =>
    setToastInfo({ isOpen: true, title: 'Ação realizada com sucesso', severity: 'success' })

  const errorToast = error =>
    setToastInfo({ isOpen: true, title: formatErrorMessage(error), severity: 'error' })

  const handleUpdate = useCallback(() => {
    successToast()
    dispatch(getAllNotes({ serviceOrderId }))
  }, [dispatch, getAllNotes, serviceOrderId, successToast])

  const handleGetPaymentInfoRejected = useCallback(error => {
    setModalDialog({
      isOpen: true,
      subTitle: error || defaultErrorMessage,
      type: WARNING_MODAL,
    })
    setOpenProposal(true)
  }, [])

  const handleSuccessPaymentAnticipation = useCallback(
    ({ success = false }) => {
      if (success) handleUpdate()
      togglePaymentAnticipationForm()
    },
    [handleUpdate, togglePaymentAnticipationForm]
  )

  const handleErrorDeleteAnticipations = useCallback(error => {
    const message = error?.first() || defaultErrorMessage
    setToastInfo({
      isOpen: true,
      severity: 'error',
      title: message,
    })
  }, [])

  useEffect(() => {
    if (Object.keys(initialState).length) {
      const currentTab = initialState?.currentTab?.value ?? localState.currentTab.value

      localDispatch({
        type: ACTION.UPDATE_STATE,
        payload: {
          notes: annotations?.get(currentTab),
          ...initialState,
        },
      })
      return
    }

    localDispatch({
      type: ACTION.UPDATE_STATE,
      payload: {
        notes: annotations?.get(localState.currentTab.value),
      },
    })
  }, [annotations, localState?.currentTab, open])

  useFetchCall(UPDATE_PAYMENT_ANTICIPATIONS.ACTION, handleUpdate, errorToast)
  useFetchCall(GET_VERIFY_UPDATE_ANTICIPATIONS.ACTION, () => {}, errorToast)
  useFetchCall(DELETE_TRADESMAN_ANTICIPATION.ACTION, handleUpdate, handleErrorDeleteAnticipations)

  const handlePaymentAnticipationForm = useCallback(() => {
    dispatch(getPaymentAnticipationInfo(serviceOrderId))
      .then(togglePaymentAnticipationForm)
      .catch(handleGetPaymentInfoRejected)
  }, [dispatch, serviceOrderId, togglePaymentAnticipationForm, handleGetPaymentInfoRejected])

  useEffect(() => {
    getAllTradesman()
    dispatch(getVerificationToken())
    dispatch(getAllNotes({ serviceOrderId }))
  }, [serviceOrderId])

  const buttonHandlers = useMemo(
    () => ({
      [BUTTON_VALUES.COMMENT]: onCommentModal,
      [BUTTON_VALUES.ADVANCE_ORDER]: handlePaymentAnticipationForm,
      [BUTTON_VALUES.JUSTIFIED_DELAY]: serviceOrder?.tradesman
        ? toggleJustifiedDelayForm
        : handleOpenProviderAllocatedModal,
      [BUTTON_VALUES.HELP_REQUEST]:
        serviceOrder?.tradesman || isAdmin || isIntermediaryUser
          ? toggleHelpForm
          : handleOpenSelectProviderModal,
    }),
    [
      onCommentModal,
      handlePaymentAnticipationForm,
      serviceOrder?.tradesman,
      toggleJustifiedDelayForm,
      handleOpenProviderAllocatedModal,
      isAdmin,
      isIntermediaryUser,
      toggleHelpForm,
      handleOpenSelectProviderModal,
    ]
  )

  const handleFilters = useCallback(
    event => {
      const { id } = event.currentTarget.dataset

      const currentTab = Object.values(TAB_OPTIONS)
        .filter(
          option =>
            !((isIntermediaryUser || isSAAS) && option.value === 'advanceOrders') &&
            !(isSAAS && option.value === 'helpRequest')
        )
        .find(option => option.value === id)
      const isButtonUnavailable =
        (currentTab.defaultButton.adminOnly && !isAdmin) ||
        (isSAAS && currentTab.defaultButton.value === BUTTON_VALUES.HELP_REQUEST)

      localDispatch({
        type: ACTION.UPDATE_CURRENT_TAB,
        payload: {
          currentTab: {
            ...currentTab,
            defaultButton: isButtonUnavailable ? buttons[0] : currentTab.defaultButton,
          },
          notes: annotations?.get(id),
        },
      })
    },
    [annotations, buttons, isAdmin, isIntermediaryUser, isSAAS]
  )

  const renderChips = useMemo(() => {
    const tabOptions = Object.values(TAB_OPTIONS).filter(
      option =>
        !((isIntermediaryUser || isSAAS) && option.value === 'advanceOrders') &&
        !(isSAAS && option.value === 'helpRequest')
    )

    return tabOptions.map(({ label, value }) => (
      <button
        key={value}
        type="button"
        data-id={value}
        className={classnames(styles.chip, {
          [styles.chipEnabled]: localState.currentTab.value === value,
        })}
        onClick={handleFilters}
      >
        {label}
      </button>
    ))
  }, [localState.currentTab, isIntermediaryUser, isSAAS])

  const renderOptions = useMemo(() => {
    return localState?.notes?.map((item, index) => {
      if (isIntermediaryUser && item.noteType === 'payment_anticipation') {
        return null
      }
      return (
        <GeneralAnnotation
          key={item.id}
          data={item}
          noteType={item.noteType.toUpperCase()}
          previousDate={index ? localState.notes[index - 1].createdAt : 0}
          tradesmanList={tradesmanList}
        />
      )
    })
  }, [localState?.notes, tradesmanList, isIntermediaryUser])

  const handleButtonChange = useCallback(event => {
    const { id } = event.currentTarget.dataset

    localDispatch({
      type: ACTION.UPDATE_CURRENT_BUTTON,
      payload: buttons?.find(button => button.value === id),
    })
  }, [])

  const handleClearSelectedMessage = useCallback(() => {
    toggleHelpForm()
    dispatch(getAllNotes({ serviceOrderId }))
  }, [dispatch, serviceOrderId])

  return (
    <>
      <SwipeableDrawer
        component="section"
        anchor="right"
        open={open}
        onClose={handleAnnotationDrawer}
        className={styles.container}
        TransitionComponent={Transition}
      >
        <Grid className={styles.header}>
          <Grid className={styles.headerTitle}>
            <Grid className={styles.iconWrapper}>
              <IconButton type={ICON.NOTES} color={Theme.Colors.Primary.Base} />
            </Grid>
            <Typography component="h1" variant="h5" className={styles.title}>
              Anotações
            </Typography>
          </Grid>
          <Button onClick={handleAnnotationDrawer} variant="ghost">
            <CloseCircle color={Theme.Colors.Grayscale.SixtyFour} className={styles.closeIcon} />
          </Button>
        </Grid>
        <Divider className={styles.divider} />
        <Grid className={styles.chipsContainer}>{renderChips}</Grid>
        <Grid className={styles.content}>
          <Grid className={styles.wrapper}>{renderOptions}</Grid>
        </Grid>
        <Grid className={styles.footer}>
          <ButtonGroup variant="outlined" color="primary" aria-label="split button">
            <MuiButton
              className={styles.muiButton}
              onClick={buttonHandlers[localState.currentButton.value]}
            >
              {localState.currentButton?.label}
            </MuiButton>
            <MuiButton
              className={styles.muiArrow}
              color="primary"
              size="small"
              ref={anchorRef}
              aria-controls={localState.isMenuButtonOpen ? 'split-button-menu' : undefined}
              aria-expanded={localState.isMenuButtonOpen ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </MuiButton>
          </ButtonGroup>
          <Popper
            open={localState.isMenuButtonOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {buttons.map(button => (
                        <MenuItem
                          key={button.value}
                          data-id={button.value}
                          onClick={handleButtonChange}
                        >
                          {button.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </SwipeableDrawer>
      {openJustifiedDelayForm && (
        <JustifiedDelayForm
          closeModal={handleClearMessage}
          message={setHelpObj}
          setMessage={setHelpObj}
          tradesmanInfo={tradesmanList}
          handleUpdate={handleUpdate}
        />
      )}
      <NewCommentModal
        newCommentModal={newCommentModal}
        setNewCommentModal={setNewCommentModal}
        handleUpdate={handleUpdate}
      />
      {paymentAnticipationForm && (
        <PaymentAnticipationForm closeModal={handleSuccessPaymentAnticipation} />
      )}
      {openProposal && <DialogModal modalDialog={modalDialog} setModalDialog={setModalDialog} />}
      <DialogModal
        modalDialog={modalSelectProviderDialog}
        setModalDialog={setModalSelectProviderDialog}
      />
      {openHelpForm && (
        <HelpForm
          closeModal={handleClearSelectedMessage}
          onCancel={toggleHelpForm}
          setSubjectOption={setSubject}
          message={helpObject}
          setMessage={setHelpObject}
          handleUpdate={handleUpdate}
        />
      )}
      {toastInfo.isOpen && (
        <Toast
          draggable
          open={toastInfo.isOpen}
          autoHideDuration={6000}
          onClose={() => setToastInfo(defaultToast)}
        >
          <Alert
            severity={toastInfo.severity}
            title={toastInfo.title}
            onClose={() => setToastInfo(defaultToast)}
          />
        </Toast>
      )}
    </>
  )
}

export default Annotations
