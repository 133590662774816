import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import Button from '_/components/button'

import useStyles from './styles'
import { FORM_STEPS } from '../utils/general'

const ButtonBox = ({
  data,
  progress,
  setProgress,
  buttonLoading,
  hasPropertyPage,
  setIsModalOpen,
  setSkipDateSuggestion,
}) => {
  const styles = useStyles()

  const { STEP_FOUR, STEP_FIVE, STEP_SIX } = FORM_STEPS

  const hasSkipButton = hasPropertyPage && (progress === STEP_FOUR || progress === STEP_FIVE)

  const handleSkipButton = useCallback(() => {
    if (progress === STEP_FOUR) {
      setSkipDateSuggestion(true)
      setIsModalOpen(true)
    } else if (progress === STEP_FIVE) {
      setIsModalOpen(true)
    } else {
      setProgress(progress + 1)
    }
  }, [progress, data])

  return (
    <Grid className={styles.buttons}>
      {progress !== 0 && (
        <Button
          fullWidth
          className={styles.backButton}
          variant="outlined"
          disableElevation
          onClick={() => setProgress(progress - 1)}
          disabled={buttonLoading}
        >
          Voltar
        </Button>
      )}
      {hasSkipButton && (
        <Button
          fullWidth
          className={styles.button}
          variant="outlined"
          disableElevation
          onClick={handleSkipButton}
          disabled={buttonLoading}
        >
          Pular
        </Button>
      )}
      {(!hasPropertyPage && progress < STEP_FIVE) || (hasPropertyPage && progress < STEP_SIX) ? (
        <Button
          fullWidth
          className={styles.button}
          color="primary"
          variant="outlined"
          endIcon={<ArrowForwardIosIcon className={styles.icon} />}
          type="submit"
          form="serviceOrderForm"
          disabled={
            progress === 0 &&
            (!data?.clientType || (data?.managedBy === 'my_company' && !data?.serviceOrderType))
          }
          disableElevation
        >
          Continuar
        </Button>
      ) : (
        <Button
          fullWidth
          className={styles.button}
          color="primary"
          variant="contained"
          type="submit"
          form="serviceOrderForm"
          disableElevation
          disabled={buttonLoading}
          isLoading={buttonLoading}
        >
          Enviar
        </Button>
      )}
    </Grid>
  )
}

export default ButtonBox
