import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export const useIndicatorStyles = makeStyles({
  indicator: {
    display: 'inline-block',
    width: 8,
    height: 8,
    marginRight: 4,
    backgroundColor: props => props.color,
    border: props => `${props.border}px solid`,
    borderRadius: props => `${props.radius}`,
    borderColor: 'rgba(0, 0, 0, 0.8)',
  },
  indicatorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '12px',
  },
})

export default makeStyles(({ spacing }) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: spacing(75),
    width: '100%',
    padding: spacing(0, 71),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: Theme.Typography.FontSize.Large,
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  tableContainer: {
    flex: 1,
    margin: spacing(20, 71),
  },
  mainTable: {
    width: 'auto',
    height: 630,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

export const dataGridStyle = {
  fontSize: '1.4rem',

  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
}
