import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  content: {
    padding: `${spacing(36, 20, 16, 20)} !important`,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(24),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(24),
  },
  inputLabel: {
    fontSize: spacing(14),
    fontWeight: 500,
    fontFamily: 'DM Sans',
  },
  attachmentLabel: {
    marginBottom: spacing(-36),
    lineHeight: spacing(16),
    height: spacing(26),
  },

  required: {
    '&::after': {
      content: '"*"',
      color: palette.error.main,
      marginLeft: spacing(4),
      fontSize: spacing(14),
    },
  },

  attachmentsContainer: {
    '& .ReferaAttachment__label': {
      visibility: 'hidden',
    },
  },
}))
