import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  content: {
    padding: spacing(0, 20, 16, 20),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(24),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(24),
  },
  inputLabel: {
    fontSize: spacing(15),
    color: palette.grey[900],
    fontWeight: 500,
    fontFamily: 'DM Sans',
    marginBottom: spacing(8),
  },
  labelRequired: {
    '&::after': {
      content: '"*"',
      color: palette.error.main,
      marginLeft: spacing(4),
      fontSize: spacing(14),
    },
  },
  radio: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(32),
  },
  radioFormGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
  },
  formControlLabel: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    gap: spacing(8),
  },
  errorMessage: {
    color: '#C90000 !important',
    margin: 0,
    fontSize: spacing(10.5),
    fontFamily: 'Roboto',
    height: spacing(10),
  },
  overrideHelperText: {
    '& .MuiFormHelperText-root': {
      margin: 0,
      fontSize: spacing(12),
      fontFamily: 'Roboto',
      fontWeight: 500,
      marginTop: spacing(4),
      letterSpacing: '0.4px',
    },
  },
  overrideInputLabelOnError: {
    color: `${palette.grey[900]} !important`,
  },
}))
