import React, { useCallback, useState, useEffect, useReducer, useMemo } from 'react'
import { SwipeableDrawer, Divider, Grid, Slide, Typography, Tooltip } from '@material-ui/core'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'

import { FilterSearch as FilterIcon, CloseCircle } from '@refera/ui-icons'
import { useDispatch, useSelector } from 'react-redux'
import Theme from '@refera/ui-core'
import { Button, DatePicker, Input, MoneyInput } from '@refera/ui-web'

import { updateBudgetInstallmentsFilter } from '_/modules/finance/actions'
import { getBudgetInstallmentsFilterSelector } from '_/modules/finance/selectors'
import Select from '_components/common/select'

import { FORM_FIELD_NAME, INITIAL_STATE, reducer, RESET_STATE, UPDATE_STATE } from './reducer'

import { STATUS_FIELDS, ANTICIPATION_OPTIONS } from '../../../utils/constants'

import useStyles from './styles'
import moment from 'moment'
import { useLocation } from '@reach/router'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

// TODO: Implement react-hook-form
const Filters = ({ handleFilterDrawer, params, open, setPage, handleGetInstallments }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const filters = useSelector(getBudgetInstallmentsFilterSelector)
  const { search: urlParams } = useLocation()

  const [apply, setApply] = useState(false)
  const [initialStateFilter, setInitialStateFilter] = useState(0)

  const checkValidInitialDate = date => {
    return moment(date).isValid() ? moment(date) : null
  }

  const initialState = useMemo(() => {
    const hasDueDateValue = filters?.dueDate?.length && typeof filters?.dueDate === 'string'
    const hasPaymentDateValue =
      filters?.paymentDate?.length && typeof filters?.paymentDate === 'string'
    const hasPaymentValue =
      filters?.paymentValue?.length && typeof filters?.paymentValue === 'string'

    const [dueDateStart, dueDateEnd] = hasDueDateValue ? filters.dueDate.split(',') : []
    const [paymentDateStart, paymentDateEnd] = hasPaymentDateValue
      ? filters.paymentDate.split(',')
      : []
    const [paymentValueStart, paymentValueEnd] = hasPaymentValue
      ? filters.paymentValue.split(',')
      : []

    const state = Object.values(FORM_FIELD_NAME).reduce((acc, currentFieldKey) => {
      switch (currentFieldKey) {
        case FORM_FIELD_NAME.DUE_DATE_END:
          return {
            ...acc,
            [currentFieldKey]: hasDueDateValue
              ? checkValidInitialDate(dueDateEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.DUE_DATE_START:
          return {
            ...acc,
            [currentFieldKey]: hasDueDateValue
              ? checkValidInitialDate(dueDateStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.PAYMENT_DATE_START:
          return {
            ...acc,
            [currentFieldKey]: hasPaymentDateValue
              ? checkValidInitialDate(paymentDateStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.PAYMENT_DATE_END:
          return {
            ...acc,
            [currentFieldKey]: hasPaymentDateValue
              ? checkValidInitialDate(paymentDateEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.PAYMENT_VALUE_START:
          return {
            ...acc,
            [currentFieldKey]: hasPaymentValue
              ? parseFloat(paymentValueStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.PAYMENT_VALUE_END:
          return {
            ...acc,
            [currentFieldKey]: hasPaymentValue
              ? parseFloat(paymentValueEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        default:
          return {
            ...acc,
            [currentFieldKey]: filters?.[currentFieldKey] || INITIAL_STATE[currentFieldKey],
          }
      }
    }, {})

    return {
      ...INITIAL_STATE,
      ...state,
    }
  }, [filters, INITIAL_STATE])

  const [localState, localDispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (urlParams && initialStateFilter < 2) {
      setInitialStateFilter(prev => prev + 1)
      localDispatch({
        type: UPDATE_STATE,
        payload: initialState,
      })
    }
  }, [urlParams, initialStateFilter])

  const handleChange = (value, field) => {
    localDispatch({
      type: UPDATE_STATE,
      payload: {
        [field]: value,
      },
    })
  }

  const handleMultiSelection = useCallback((newValue, field) => {
    localDispatch({ type: UPDATE_STATE, payload: { [field]: newValue } })
  }, [])

  const handleInputChange = event => {
    const { name: field, value } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: {
        [field]: value,
      },
    })
  }

  const cleanFilters = () => {
    const url = window.location.href.split('?')[0]
    window.history.replaceState({}, '', url)
    localDispatch({ type: RESET_STATE })
  }

  const getDateInterval = (startDate, endDate) => {
    if (!startDate && !endDate) {
      return undefined
    }

    if (startDate && !endDate) {
      return `${startDate.startOf('day').toISOString()},`
    }
    if (!startDate && endDate) {
      return `,${endDate.endOf('day').toISOString()}`
    }

    return `${startDate.startOf('day').toISOString()},${endDate.endOf('day').toISOString()}`
  }

  const getMoneyInterval = (startValue, endValue) => {
    if (!startValue && !endValue) {
      return null
    }
    if (startValue && !endValue) {
      return `${localState?.paymentValueStart},`
    }
    if (!startValue && endValue) {
      return `,${localState?.paymentValueEnd}`
    }
    return `${localState?.paymentValueStart},${localState?.paymentValueEnd}`
  }

  const handleApplyFilters = useCallback(() => {
    const paymentDate = localState?.isValid
      ? getDateInterval(localState?.paymentDateStart, localState?.paymentDateEnd)
      : null

    const dueDate = localState?.isValid
      ? getDateInterval(localState?.dueDateStart, localState?.dueDateEnd)
      : null

    const paymentValue = localState?.isValid
      ? getMoneyInterval(localState?.paymentValueStart, localState?.paymentValueEnd)
      : null

    const { requester, serviceOrder, status, anticipation } = localState

    const updatedFilters = {
      serviceOrder,
      requester,
      status,
      dueDate,
      anticipation,
      paymentDate,
      paymentValue,
    }

    const url = window.location.href.split('?')[0]
    window.history.replaceState({}, '', url)

    dispatch(updateBudgetInstallmentsFilter(updatedFilters))
    setApply(true)
  }, [dispatch, filters, localState])

  useEffect(() => {
    if (!filters) {
      return
    }

    if ((Object.values(filters)?.some(value => value) && apply) || apply) {
      setPage(0)
      handleGetInstallments()
      setApply(false)
    }
  }, [filters, apply, params])

  const renderInputTooltip = useCallback(text => {
    return (
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={<Typography className={styles.tooltipText}>{text}</Typography>}
        placement="bottom-end"
      >
        <HelpOutlineOutlinedIcon className={styles.tooltipIcon} />
      </Tooltip>
    )
  }, [])

  const MainFilters = useMemo(() => {
    return (
      <>
        <>
          <Select
            label="Status"
            defaultValue={localState?.status}
            value={localState?.status}
            onChange={handleInputChange}
            name="status"
            options={STATUS_FIELDS}
          />

          <Input
            key="serviceOrderId"
            label="ID do chamado"
            value={localState?.serviceOrder}
            placeholder="12345"
            style={{ fontSize: '16px' }}
            onChange={handleInputChange}
            fullWidth
            name="serviceOrder"
            inputProps={{ type: 'number' }}
          />
          <Input
            key="requester"
            label="Solicitante"
            value={localState?.requester}
            placeholder="Solicitante"
            style={{ fontSize: '16px' }}
            onChange={handleInputChange}
            fullWidth
            name="requester"
            endAdornment={renderInputTooltip('Busque por nome, email, telefone ou CPF/CNPJ')}
          />
        </>

        <Grid className={styles.inputsWrapper}>
          <DatePicker
            key="dueDateStart"
            label="Vencimento entre"
            value={localState?.dueDateStart}
            onChange={value => handleChange(value, 'dueDateStart')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            maxDate={localState?.dueDateEnd || undefined}
            mask="__/__/____"
          />
          <Typography className={styles.dotDivision}>•</Typography>
          <DatePicker
            key="dueDateEnd"
            label=" "
            value={localState?.dueDateEnd}
            onChange={value => handleChange(value, 'dueDateEnd')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            minDate={localState?.dueDateStart || undefined}
            mask="__/__/____"
          />
        </Grid>
        <Grid className={styles.inputsWrapper}>
          <DatePicker
            key="paymentDateStart"
            label="Pagamento entre"
            value={localState?.paymentDateStart}
            onChange={value => handleChange(value, 'paymentDateStart')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            maxDate={localState?.paymentDateEnd || undefined}
            mask="__/__/____"
          />
          <Typography className={styles.dotDivision}>•</Typography>
          <DatePicker
            key="paymentDateEnd"
            label=" "
            value={localState?.paymentDateEnd}
            onChange={value => handleChange(value, 'paymentDateEnd')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            minDate={localState?.paymentDateStart || undefined}
            mask="__/__/____"
          />
        </Grid>
        <Grid className={styles.inputsWrapper}>
          <MoneyInput
            key="valor-min"
            label="Valor entre"
            value={localState?.paymentValueStart || ''}
            style={{ fontSize: '16px' }}
            placeholder="R$ "
            onChange={e => handleChange(e.floatValue, 'paymentValueStart')}
            className={styles.doubleInput}
            fullWidth
          />
          <Typography className={styles.dotDivision}>•</Typography>
          <MoneyInput
            key="valor-max"
            label=" "
            value={localState?.paymentValueEnd || ''}
            style={{ fontSize: '16px', marginTop: '12px' }}
            className={styles.doubleInput}
            placeholder="R$ "
            onChange={e => handleChange(e.floatValue, 'paymentValueEnd')}
            fullWidth
          />
        </Grid>
        <Select
          label="Antecipação"
          defaultValue={localState?.anticipation}
          value={localState?.anticipation}
          onChange={handleInputChange}
          name="anticipation"
          options={ANTICIPATION_OPTIONS}
        />
      </>
    )
  }, [localState, handleMultiSelection, handleChange])

  return (
    <>
      <SwipeableDrawer
        component="section"
        anchor="right"
        open={open}
        onClose={handleFilterDrawer}
        className={styles.container}
        TransitionComponent={Transition}
      >
        <Grid className={styles.header}>
          <Grid className={styles.headerTitle}>
            <Grid className={styles.iconWrapper}>
              <FilterIcon color={Theme.Colors.Primary.Base} />
            </Grid>
            <Typography component="h1" variant="h5" className={styles.title}>
              Filtros
            </Typography>
          </Grid>
          <Button onClick={handleFilterDrawer} variant="ghost">
            <CloseCircle color={Theme.Colors.Primary.Base} className={styles.closeIcon} />
          </Button>
        </Grid>
        <Divider className={styles.divider} />
        <Grid className={styles.content}>{MainFilters}</Grid>

        <Grid className={styles.buttonsContainer}>
          <Button variant="ghost" color="primary" onClick={cleanFilters}>
            Limpar filtros
          </Button>
          <Button
            variant="primary"
            color="orange"
            disabled={!localState?.isValid}
            onClick={handleApplyFilters}
          >
            Aplicar filtro
          </Button>
        </Grid>
      </SwipeableDrawer>
    </>
  )
}

export default Filters
