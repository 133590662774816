import React, { useCallback, useMemo, useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const PasswordField = props => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = useCallback(() => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }, [])

  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <IconButton aria-label="Mostrar a senha" edge="end" onClick={toggleShowPassword}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }),
    [showPassword, toggleShowPassword]
  )

  return <TextField {...props} InputProps={inputProps} type={showPassword ? 'text' : 'password'} />
}

export default React.memo(PasswordField)
