import React from 'react'
import Button from '_components/button'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useToast } from '_/hooks/use-toast'
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons'
import { useDialog } from '_/hooks/use-dialog'
import useStyles from './styles'

const CoverageTable = ({ fields, remove, isEditing, handleModalOpen }) => {
  const styles = useStyles()
  const { showDialog, closeDialog } = useDialog()

  const { showToast } = useToast()

  const handleDelete = async index => {
    showDialog({
      type: 'warning',
      subject: 'Aviso',
      description: 'Você tem certeza que deseja excluir este registro?',
      labelApprove: 'Sim',
      labelCancel: 'Não',
      onApprove: () => {
        if (fields.length === 1) {
          showToast({
            type: 'error',
            message: 'Não é possível remover o último registro dos limites de cobertura.',
          })
          closeDialog()
          return
        }
        remove(index)
        closeDialog()
      },
      onCancel: closeDialog,
    })
  }

  return (
    <div className={styles.valuesContent}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.tableCell}>
              <Typography variant="body2" className={styles.tableHeader}>
                <strong>Valor de Orçamento</strong>
              </Typography>
            </TableCell>
            <TableCell className={styles.tableCell}>
              <Typography variant="body2" className={styles.tableHeader}>
                <strong>Percentual de Cobertura</strong>
              </Typography>
            </TableCell>
            <TableCell className={styles.tableCell}>
              <></>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell className={styles.tableCell}>
                {`R$ 
                ${
                  item.budgetValue.toString().includes('.')
                    ? item.budgetValue.toString().replace('.', ',')
                    : `${item.budgetValue},00`
                }`}
              </TableCell>
              <TableCell className={styles.tableCell}>
                {item.coveragePercentage.toString().replace('.', ',')}%
              </TableCell>
              {isEditing ? (
                <TableCell className={styles.tableCell}>
                  <IconButton style={{ padding: '0' }} onClick={() => handleModalOpen(index)}>
                    <EditIcon style={{ color: '#1671ED' }} />
                  </IconButton>
                  <IconButton style={{ padding: '0' }} onClick={() => handleDelete(index)}>
                    <DeleteIcon style={{ color: '#C90000' }} />
                  </IconButton>
                </TableCell>
              ) : (
                <TableCell className={styles.tableCell}>
                  <></>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isEditing && (
        <Button
          className={styles.valuesButton}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleModalOpen()}
        >
          Adicionar Registro
        </Button>
      )}
    </div>
  )
}

export default CoverageTable
