/* eslint-disable no-param-reassign */
import { notEmptyPayload } from '_/utils/helpers'
import { get, patch, post } from './requests'
import humps from 'humps'

const decamelizeParams = params => {
  Object.values(params)?.forEach(param => {
    if (typeof param === 'string') {
      const replaceKey = Object.keys(params).find(paramKey => params[paramKey] === param)
      params[replaceKey] = humps.decamelize(param)
    }
  })
  return params
}

// ------------------ SERVICE REQUESTS
export const getProfileList = key => params =>
  get(['profiles'], { key, transformPayload: true, params })

export const getProfileById = key => id => get(['profiles', id], { key, transformPayload: true })

export const updatedUserProfile = (key, payload) =>
  post(['update-active-profile'], { key }, payload)

// Permissions

export const getProfilePermission = key => (id, params) => {
  const payloadParams = decamelizeParams(params)

  return get(['profiles', id, 'permissions'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(payloadParams)),
  })
}

export const patchProfilePermission = key => payload => {
  return patch(
    ['permissions', 'update-crud-permissions'],
    {
      key,
    },
    payload
  )
}

export const postProfilePermissionGroup = key => payload => {
  return post(
    ['permissions-group'],
    {
      key,
    },
    payload
  )
}

export const patchProfilePermissionGroup = key => (id, payload) => {
  return patch(
    ['permissions-group', id],
    {
      key,
    },
    payload
  )
}

// Group permissions
export const getProfilePermissionGroup = key => params => {
  return get(['permissions-group'], { key, transformPayload: true, params })
}
