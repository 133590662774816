import { useCallback, useState } from 'react'

export default (initialValues = '') => {
  const [value, setValue] = useState(initialValues)

  const handleInputChange = useCallback(event => {
    const { value: eventValue } = event.target
    setValue(eventValue)
  }, [])

  return [value, handleInputChange, setValue]
}
