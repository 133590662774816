import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  buttonRed: {
    background: 'transparent',
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,

    '&:not(:disabled):hover': {
      background: palette.error.main,
      color: palette.gray[4],
    },

    '&:disabled': {
      border: `1px solid ${palette.error.main}`,
    },
  },
  buttonBlue: {
    background: palette.primary.main,
    color: palette.gray[4],
    border: 'none',

    '&:not(:disabled):hover': {
      background: palette.primary.main,
      opacity: '0.8',
    },

    '&:disabled': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
}))
