import { WARNING_MODAL } from '_components/modal/modal-dialog'

export const INITIAL_STATE = {
  annotationsInitialState: undefined,
  isAddProviderModalOpen: false,
  isAnnotationDrawerOpen: false,
  isConfirmationModalOpen: false,
  isCommentsOpen: false,
  isNewBudgetRejectedModalOpen: false,
  isTradesmanAlertInfoOpen: true,
  newBudgetRejectedModal: {
    isOpen: false,
    subTitle: '',
    type: WARNING_MODAL,
  },
}

export const ACTIONS = {
  TOGGLE_ADD_PROVIDER_MODAL: 'TOGGLE_ADD_PROVIDER_MODAL',
  TOGGLE_ANNOTATION_DRAWER: 'TOGGLE_ANNOTATION_DRAWER',
  TOGGLE_ANNOTATION_DRAWER_ON_COMMENTS: 'TOGGLE_ANNOTATION_DRAWER_ON_COMMENTS',
  TOGGLE_CONFIRMATION_MODAL: 'TOGGLE_CONFIRMATION_MODAL',
  UPDATE_NEW_BUDGET_REJECTED_MODAL: 'UPDATE_NEW_BUDGET_REJECTED_MODAL',
  TOGGLE_TRADESMAN_ALERT_INFO: 'TOGGLE_TRADESMAN_ALERT_INFO',
  UPDATE_STATE: 'UPDATE_STATE',
  RESET: 'RESET',
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.TOGGLE_ADD_PROVIDER_MODAL:
      return { ...state, isAddProviderModalOpen: payload ?? !state.isAddProviderModalOpen }

    case ACTIONS.TOGGLE_ANNOTATION_DRAWER:
      return { ...state, isAnnotationDrawerOpen: !state.isAnnotationDrawerOpen, ...payload }

    case ACTIONS.TOGGLE_ANNOTATION_DRAWER_ON_COMMENTS:
      return { ...state, isAnnotationDrawerOpen: !state.isAnnotationDrawerOpen, ...payload }

    case ACTIONS.TOGGLE_CONFIRMATION_MODAL:
      return { ...state, isConfirmationModalOpen: !state.isConfirmationModalOpen }

    case ACTIONS.UPDATE_NEW_BUDGET_REJECTED_MODAL:
      return {
        ...state,
        isNewBudgetRejectedModalOpen: {
          ...state.isNewBudgetRejectedModalOpen,
          ...payload,
        },
      }

    case ACTIONS.TOGGLE_TRADESMAN_ALERT_INFO:
      return { ...state, isTradesmanAlertInfoOpen: !state.isTradesmanAlertInfoOpen }

    case ACTIONS.UPDATE_STATE:
      return { ...state, ...payload }

    case ACTIONS.RESET:
      return INITIAL_STATE

    default:
      return state
  }
}
