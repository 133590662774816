/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { Preview } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'

import useStyles from './styles'

const PictureInput = ({ name, value, setValue, isDocument }) => {
  const onDrop = useCallback(
    file => {
      const newValue = [...value, ...file]
      setValue('attachments', newValue)
    },
    [name, setValue, value]
  )

  const defaultPreviewState = {
    visible: false,
    selectedIndex: undefined,
  }
  const styles = useStyles({ hasFiles: !!value?.length, document: isDocument })
  const [previewState, setPreviewState] = useState(defaultPreviewState)

  const onRemoveClick = useCallback(
    (index, pdf) => {
      setValue(
        'attachments',
        pdf
          ? value?.filter(file => file.type === 'application/pdf').filter((_, i) => index !== i)
          : value.filter((_, i) => index !== i)
      )
    },
    [value]
  )

  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(() => defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={
          value &&
          value.map((item, index) =>
            item.id
              ? {
                  id: item.id,
                  url: item.file,
                }
              : {
                  id: index,
                  url: URL.createObjectURL(item),
                }
          )
        }
      />
    ),
    [previewState, value]
  )

  return (
    <Grid className={styles.container}>
      {PreviewComponent}
      <Grid container justify="space-between">
        {isDocument ? (
          <>
            <Typography className={styles.labelDoc} component="label">
              Comprovante
            </Typography>
          </>
        ) : (
          <>
            <Typography
              className={styles.label}
              color="textSecondary"
              component="label"
              htmlFor="picture-input"
              variant="h3"
            >
              Anexos
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Opcional
            </Typography>
          </>
        )}
      </Grid>
      <Grid>
        <Attachments
          className={styles.attachments}
          label="Imagens e vídeos"
          readOnly={false}
          files={value?.filter(file => file.type !== 'application/pdf') || []}
          accept={{
            'image/jpeg': [
              '.jpeg',
              '.png',
              '.jpg',
              '.bmp',
              '.webp',
              '.mkv',
              '.mp4',
              '.mov',
              '.avi',
              '.m4v',
              '.mpg',
              '.mpeg',
              '.wmv',
              '.webm',
            ],
          }}
          onDrop={onDrop}
          onRemoveItem={index => onRemoveClick(index, false)}
          onItemClick={index => {
            setPreviewState(() => ({
              visible: true,
              selectedIndex: index,
            }))
          }}
        />
      </Grid>
      <Grid>
        <Attachments
          className={styles.attachments}
          label="Arquivos"
          content="files"
          readOnly={false}
          files={value?.filter(file => file.type === 'application/pdf') || []}
          multiple={false}
          accept={{
            'application/pdf': ['.pdf'],
          }}
          onDrop={onDrop}
          onRemoveItem={index => onRemoveClick(index, true)}
          onItemClick={index => {
            setPreviewState(() => ({
              visible: true,
              selectedIndex: index,
            }))
          }}
        />
      </Grid>
      {/* {isLoading ? (
        <Loading className={styles.loader} />
      ) : (
        <>
          <Grid className={isDocument ? styles.cardsDoc : styles.cards}>
            {value?.map(file => (
              <CardMedia
                key={file?.lastModified}
                onRemoveClick={onRemoveClick}
                file={file?.path && !file?.lastModified ? file?.path : file}
                lockView
              />
            ))}
          </Grid>
          {value?.length && isDocument ? null : (
            <RootRef rootRef={ref}>
              <Paper {...rootProps} className={styles.paper} id="picture-input" variant="outlined">
                {!value?.length && (
                  <>
                    <Svg className={styles.icon} icon={FileIcon} />
                    <Typography className={styles.title} component="p" variant="body1">
                      {isDocument
                        ? 'Arraste aqui ou selecione o anexo'
                        : 'Arraste aqui ou selecione os anexos'}
                    </Typography>
                    <Typography
                      className={styles.description}
                      color="textSecondary"
                      component="p"
                      variant="body2"
                    >
                      {isDocument
                        ? '.JPG, .JPEG, .PNG ou .PDF'
                        : '.JPG, .JPEG, .PNG, .BMP, .MP4, .MKV, .MOV, .FLV ou .PDF'}
                    </Typography>
                  </>
                )}
                <input {...getInputProps()} />
                <Button color="primary" className={styles.button}>
                  {value?.length
                    ? 'Adicionar mais arquivos'
                    : isDocument
                    ? 'Selecionar arquivo'
                    : 'Selecionar fotos'}
                </Button>
              </Paper>
            </RootRef>
          )}
        </>
      )} */}
    </Grid>
  )
}

PictureInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isDocument: PropTypes.bool,
}
PictureInput.defaultProps = {
  isDocument: false,
}

export default React.memo(PictureInput)
