/* eslint-disable guard-for-in */
import { GenerateContractResponsibleDataPage } from './pages/Step1'
import { GenerateContractCompanyDocumentsPage } from './pages/Step2'
import { GenerateContractCompanyDataPage } from './pages/Step3'
import { GenerateContractResultPage } from './pages/Step4'

export const NUMBER_OF_STEPS = 4
export const STEP_LENGTH = 100 / NUMBER_OF_STEPS
export const STEPS = [
  {
    title: 'Insira os dados dos responsáveis pelo contrato',
    component: GenerateContractResponsibleDataPage,
    buttonLabels: { next: 'Próximo', back: 'Fechar' },
    svgInNextButton: true,
  },
  {
    title: 'Insira os documentos necessários da empresa',
    component: GenerateContractCompanyDocumentsPage,
    buttonLabels: { next: 'Próximo', back: 'Voltar' },
    svgInNextButton: true,
  },
  {
    title: 'Confira os dados abaixo',
    component: GenerateContractCompanyDataPage,
    buttonLabels: { next: 'Enviar contrato', back: 'Voltar' },
    svgInNextButton: false,
  },
  {
    component: GenerateContractResultPage,
  },
]

export const formatFormDataPayload = data => {
  const formattedPayload = {}
  data.forEach((item, index) => {
    for (const chave in item) {
      formattedPayload[`${chave}-${index}`] = item[chave]
    }
  })
  return formattedPayload
}

export const formatAttachmentsPayload = data => {
  const formattedPayload = {}

  data.forEach((item, index) => {
    formattedPayload[`attachment-${index}`] = item
  })
  return formattedPayload
}
