import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    width: '100%',
    display: 'flex',
    gap: spacing(16),
    padding: spacing(16),
    borderRadius: '4px',
    borderBottom: `5px solid ${palette.error.main}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
    width: '100%',
  },
  title: {
    fontSize: spacing(16),
    fontWeight: Theme.typography.fontWeightBold,
    color: palette.gray[100],
  },
  subTitle: {
    fontSize: spacing(14),
    fontWeight: Theme.typography.fontWeightRegular,
    color: palette.gray[80],
  },
  subTitleModal: {
    fontSize: spacing(14),
    fontWeight: Theme.typography.fontWeightRegular,
    color: palette.gray[64],
  },
  text: {
    fontSize: spacing(16),
    fontWeight: Theme.typography.fontWeightRegular,
    color: palette.gray[80],
  },
  button: {
    fontSize: spacing(14),
    fontWeight: Theme.typography.fontWeightBold,
    color: palette.primary.main,
    backgroundColor: 'transparent',
    padding: 0,
    width: 'fit-content',
    textTransform: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    fill: palette.error.main,
    width: spacing(30),
    height: spacing(30),
  },
  titleModal: {
    color: palette.gray[100],
    flexWrap: 'wrap',
    display: 'flex',
    fontWeight: 600,
    fontSize: spacing(24),
    textAlign: 'center',
    lineHeight: '2',
  },
  contentModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: spacing(20),
    padding: spacing(32, 20),
    alignItems: 'flex-start',
  },
  card: {
    padding: spacing(16),
    border: `1px solid ${palette.gray[32]}`,
    borderRadius: '10px',
    minHeight: spacing(100),
    maxHeight: spacing(300),
    width: '100%',
    display: 'flex',
    gap: spacing(16),
    marginTop: spacing(-8),

    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: spacing(6),
      height: spacing(6),
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: spacing(20),
      margin: spacing(15),
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: spacing(20),
      border: '3px solid gray',
    },
  },
}))
