import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Dialog, Grid, Typography, DialogContent, DialogActions } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import Button from '_components/button'
import IconButton from '_components/svg/icon-button'

import useStyles from './styles'

const Modal = ({
  handleModal,
  title,
  children,
  handleSubmit,
  disabledSubmit,
  className,
  contentClassName,
  hideActionButtons,
  hasEditButton,
  toggleEdit,
  isLoading,
  leftButtonText,
  rightButtonText,
  hideLeftButton,
  hideRightButton,
  showHeader,
  textButton,
}) => {
  const styles = useStyles()

  return (
    <Dialog open onClose={handleModal} className={className}>
      {showHeader && (
        <Grid className={styles.header}>
          {title && (
            <Typography component="span" className={styles.title}>
              {title}
            </Typography>
          )}
          <Grid className={styles.buttons}>
            {hasEditButton && (
              <Button onClick={toggleEdit} className={styles.editButton} variant="outlined">
                Editar
              </Button>
            )}
            <IconButton
              aria-label="Close modal"
              onClick={handleModal}
              buttonClassName={styles.iconButton}
            >
              <CloseIcon className={styles.icon} />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <DialogContent className={classnames(styles.content, contentClassName)}>
        {children}
      </DialogContent>
      {!hideActionButtons && (
        <DialogActions className={styles.actions}>
          {!hideRightButton && (
            <Button
              className={textButton ? styles.textButton : styles.button}
              onClick={handleModal}
              color="red"
              variant="outlined"
            >
              {rightButtonText}
            </Button>
          )}
          {!hideLeftButton && (
            <Button
              disabled={disabledSubmit}
              className={
                textButton ? `${styles.textButton} ${styles.textButtonActive}` : styles.button
              }
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              isLoading={isLoading}
            >
              {leftButtonText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  disabledSubmit: PropTypes.bool,
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  title: PropTypes.string,
  hideActionButtons: PropTypes.bool,
  hasEditButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  toggleEdit: PropTypes.func,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string,
  hideLeftButton: PropTypes.bool,
  hideRightButton: PropTypes.bool,
  showHeader: PropTypes.bool,
  textButton: PropTypes.bool,
}

Modal.defaultProps = {
  handleSubmit: () => {},
  className: '',
  contentClassName: '',
  disabledSubmit: false,
  title: '',
  hideActionButtons: false,
  hasEditButton: false,
  isLoading: false,
  toggleEdit: () => {},
  leftButtonText: 'Salvar',
  rightButtonText: 'Cancelar',
  hideLeftButton: false,
  hideRightButton: false,
  showHeader: true,
  textButton: false,
}
export default React.memo(Modal)
