import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: spacing(34),
    paddingRight: '2%',
    height: spacing(72),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
    gap: '8px',
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
    borderRadius: spacing(8),
    height: spacing(48),
    padding: spacing(0, 12),
    gap: spacing(16),
    color: Theme.Colors.Grayscale.SixtyFour,
  },
  menuItem: {
    fontSize: '1.4rem !important',
  },
  menuActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    width: '100%',
  },
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  mainTable: {
    width: 'auto',
    height: '616px',
  },
  grayColumn: {
    backgroundColor: Theme.Colors.Grayscale.Four,
  },
  idColumn: {
    backgroundColor: Theme.Colors.Grayscale.Four,
    color: Theme.Colors.Primary.Base,
  },
  actionButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    fontSize: '2rem',
  },
}))
