import React from 'react'
import { Dialog, DialogContent, DialogActions, Typography, Box, Grid } from '@material-ui/core'

import Button, { BUTTON_COLOR } from '_components/button'

import useStyles from './styles'

export default function SkipStepModal({
  isModalOpen,
  setIsModalOpen,
  handleSkipStep,
  modalMessage,
}) {
  const classes = useStyles()

  return (
    <Dialog open={isModalOpen} classes={{ paper: classes.dialog }}>
      <DialogContent className={classes.dialogContent}>
        <Box mt={5} />
        <Typography className={classes.text}>{modalMessage}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Grid className={classes.btnWrapper}>
          <Button
            color={BUTTON_COLOR.RED}
            variant="outlined"
            className={classes.button}
            onClick={() => setIsModalOpen(false)}
          >
            Não
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSkipStep}
          >
            Sim
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
