import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    paddingTop: spacing(20),
  },
  containerTitle: {
    fontSize: spacing(16),
    lineHeight: spacing(24),
    color: palette.primary.main,
    paddingBottom: spacing(13),
  },
  payerSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: spacing(24),
  },
  approvedItensSection: {
    paddingTop: spacing(24),
  },
  label: {
    fontSize: spacing(12),
    lineHeight: spacing(16),
    color: palette.gray[32],
  },
  value: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: palette.gray[64],
    paddingTop: spacing(8),
  },
  picturesSection: {
    paddingTop: spacing(24),
  },
  posExecutionImage: {
    width: spacing(94),
    height: spacing(76),
    marginRight: spacing(10),
    borderRadius: spacing(4),
    paddingTop: spacing(8),
  },
  invoice: {
    paddingTop: spacing(14),
  },
  invoiceTitle: {
    fontWeight: '400',
    color: palette.gray[32],
  },
  button: {
    marginTop: spacing(10),
    marginRight: spacing(10),
    borderRadius: spacing(14),
  },
  pdfIcon: {
    marginRight: spacing(5),
  },
  link: {
    display: 'flex',
    textAlign: 'left',
    fontSize: spacing(14, '!important'),
    textDecoration: 'none !important',
    padding: spacing(0, '!important'),
    marginTop: spacing(8),
    lineHeight: spacing(20),
  },
}))
