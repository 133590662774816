import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '24px 0 24px 72px',
    borderBottom: '1px solid #E3E3E3',
  },
  title: {
    fontSize: '24px',
    color: '#141414',
  },
  main: {
    gridArea: 'main',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '80.0rem',
    padding: '2% 5%',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gap: spacing(40),
    justifyContent: 'center',
    justifyItems: 'center',
    width: '100%',
    marginBottom: '4.8rem',
  },
  icon: {
    height: spacing(14),
    width: spacing(14),
  },
  edit: {
    marginBottom: spacing(8),
    fontWeight: 'normal',
    border: `1px solid ${palette.primary.main}`,
  },
}))
