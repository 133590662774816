import { schema } from 'normalizr'

import Model from './model'
import State from './state'

class Responsible extends Model({
  id: undefined,
  name: undefined,
  username: undefined,
}) {}

const responsibleNewSchema = new schema.Entity(
  'responsible',
  {},
  {
    processStrategy: entity => {
      return new Responsible(entity).set('state', new State(entity.state))
    },
  }
)

const responsibleSchema = [responsibleNewSchema]

export { responsibleSchema, Responsible as default }
