export function includeDDI(number) {
  let newNumber = null

  if (!number) return number

  if (typeof number === 'number') {
    newNumber = String(number)
  } else {
    newNumber = number
  }

  if (newNumber?.includes('+55') || (newNumber?.[0] === '5' && newNumber?.[1] === '5')) {
    newNumber = `+${newNumber.replace(/[^\d]/g, '')}`
  } else {
    newNumber = `+55${newNumber}`
  }

  return newNumber.replace(/(\+55)(\d{2})(\d{1})(\d{4})(\d{4})/, '$1 ($2) $3 $4-$5')
}
