import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  titleBlue: {
    color: '#1671ED',
    cursor: 'move',
  },
  dialog: {
    maxheight: '900px',
    minHeight: '68%',
  },
  container: {
    display: 'flex',
    width: '800px',
    maxWidth: '800px',
    height: '100%',
    padding: '35px',
    flexDirection: 'column',
    borderTop: '2px solid #E3E3E3',
    overflowX: 'hidden',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  dialogAction: {
    padding: '20px 30px',
  },
  dialogAction2: {
    marginTop: '-70px',
    padding: '20px 30px',
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0 8px 8px 0',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  button: {
    height: '40px',
    width: '140px',
    padding: '10px 16.5px',
    marginLeft: '40px',
    marginTop: '10px',
  },
  labels: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    color: '#141414',
  },
  label: {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '10px',
    color: '#696969',
  },
  inlineLabel: {
    display: 'flex',
    gap: '6px',
    margin: '5px',
  },
  required: {
    color: '#C90000',
  },
  subjectWrapper: {
    display: 'flex',
    marginBottom: '10px',
  },
  select: {
    marginBottom: '10px',
  },
}))
