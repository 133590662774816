import React from 'react'
import { Tooltip } from '@material-ui/core'
import useStyles from './styles'

const LinkCell = ({ name }) => {
  const styles = useStyles()

  return <span className={styles.link}>{name}</span>
}

export const profileColumns = () => [
  {
    field: 'desc',
    headerName: 'Nome',
    minWidth: '100%',
    maxWidth: '100%',
    editable: false,
  },
  {
    field: 'dados',
    headerName: '',
    minWidth: '100%',
    maxWidth: '100%',
    editable: false,
    renderCell: () => (
      <Tooltip title="Ir para permissões de dados">
        {LinkCell({ name: 'Permissões de dados' })}
      </Tooltip>
    ),
  },
  {
    field: 'acoes',
    headerName: '',
    minWidth: '100%',
    maxWidth: '100%',
    editable: false,
    renderCell: () => (
      <Tooltip title="Ir para permissões de ações">
        {LinkCell({ name: 'Permissões de ações' })}
      </Tooltip>
    ),
  },
]

export const TABLE_SX = {
  fontSize: '1.4rem',
  width: '100%',

  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%',
  },

  '& .MuiDataGrid-row': {
    width: '100%',
  },

  '& .MuiDataGrid-cell': {
    width: '100%',
  },

  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
}
