import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(15),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: spacing(30),

    borderLeft: '1px solid lightgrey',
  },
  problemForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingTop: spacing(15),
    paddingBottom: spacing(15),
    gap: spacing(10),
  },
  categoryForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
  },
  title: {
    fontSize: '22px',
    color: Theme.Colors.Grayscale.OneThousand,
    weight: 'bold',
  },
  label: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '16px',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  serviceLabel: {
    fontSize: '18px',
    color: Theme.Colors.Grayscale.OneThousand,
    marginTop: spacing(15),
  },
  textfield: {
    marginRight: spacing(15),
    marginTop: spacing(15),
    marginBottom: spacing(15),
    width: spacing(500),
  },
  containerAdd: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '3rem',
  },
  autocomplete: {
    marginBottom: spacing(15),
    width: spacing(320),

    '& .MuiAutocomplete-popper': {
      maxHeight: spacing(125),
    },
  },
  accordion: {
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
  modalContent: {
    width: '100%',
    height: spacing(300),
    display: 'flex',
    justifyContent: 'center',
    paddingTop: spacing(15),
    paddingBottom: 0,
  },
}))
