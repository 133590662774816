import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    fontSize: '3rem',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: palette.gray[32],
    marginTop: spacing(6),
  },
  titleContainer: {
    display: 'flex',
    padding: spacing(0, 0, 0, 20),
  },
  value: {
    color: palette.gray[64],
  },
  userList: {
    '& :last-of-type': {
      borderBottom: 'none',
    },
  },
  wrapper: {
    borderBottom: `${spacing(1)} solid #E3E3E3`,
    width: '100%',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(14, 20),
    width: '100%',

    '&:hover': {
      backgroundColor: '#F5F5F5',
      // cursor: 'pointer',
    },
  },
  regionDetails: {
    display: 'flex',
    gap: spacing(30),

    '& :first-child': {
      minWidth: spacing(130),
    },
  },
  icon: {
    color: palette.gray[32],
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    height: spacing(18),
    padding: spacing(0),
  },

  buttonEdit: {
    color: palette.primary.main,
    borderColor: palette.primary.main,
  },
}))
