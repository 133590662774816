import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    paddingBottom: spacing(20),
  },
  containerTitle: {
    fontSize: spacing(16),
    lineHeight: spacing(24),
    color: palette.primary.main,
    paddingBottom: spacing(13),
  },
  payerSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: spacing(24),
  },
  approvedItensSection: {
    paddingTop: spacing(24),
  },
  label: {
    fontSize: spacing(12),
    lineHeight: spacing(16),
    color: palette.gray[32],
  },
  value: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: palette.gray[64],
    paddingTop: spacing(8),
  },
  picturesSection: {
    paddingTop: spacing(24),
  },
  budgetImage: {
    width: spacing(76),
    height: spacing(76),
    marginRight: spacing(10),
    borderRadius: spacing(4),
    paddingTop: spacing(8),
  },
}))
