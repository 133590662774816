import { get, post, patch, del } from './requests'

export const getMessage = key => params =>
  get(['comunication-messenger'], {
    key,
    transformPayload: true,
    params,
  })

export const getSetItems = key => get(['comunication-messenger', 'get-items'], { key })

export const getMessagingDetails = (key, messageId) =>
  get(['comunication-messenger', messageId], { key })

export const getMessagingByServiceOrderId = key => params =>
  get(['comunication-messenger', 'get-register'], {
    key,
    transformPayload: true,
    params,
  })

export const postMessaging = (key, payload) => post(['comunication-messenger'], { key }, payload)

export const patchMessaging = (key, messageId, payload) =>
  patch(['comunication-messenger', messageId], { key }, payload)

export const deleteMessaging = (key, messageId) =>
  del(['comunication-messenger', messageId], { key })

export const postSendWhatsAppLog = (key, payload) =>
  post(['comunication-messenger', 'create-whatsapp-log'], { key }, payload)
