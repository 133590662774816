import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Typography } from '@refera/ui-web'
import Draggable from 'react-draggable'

import Theme from '@refera/ui-core'

import DotsGroup from '_assets/svgs/dots-group.svg'
import { CardAdd, MoneyRecive as DollarReceive, CardSlash, GridEdit, Cards } from '@refera/ui-icons'
import Svg from '_/components/svg'

import useStyles from '../styles'
import { formatCurrency } from '_/utils/helpers'
import useRolePermission from '_/hooks/use-role-permission'

const FloatingMenu = ({
  selectedItems,
  items,
  handleButtonsActions,
  handleFloatingModalNavigation,
}) => {
  const styles = useStyles()
  const { isAdmin } = useRolePermission()

  const priceToReceive = useMemo(() => {
    return items.reduce(
      ({ totalPrice, totalValue }, item) => {
        const itemValue = item?.value || 0
        const itemTotal = item?.total || 0

        return {
          totalPrice: totalPrice + itemTotal,
          totalValue: totalValue + itemValue,
        }
      },
      {
        totalPrice: 0,
        totalValue: 0,
      }
    )
  }, [items])

  return (
    <Draggable handle="#draggable-floating-menu" cancel={'[class*="MuiDialogContent-root"]'}>
      <Grid className={styles.floatingContainer}>
        <Grid className={styles.floatingWrapper}>
          <Grid className={styles.draggable} id="draggable-floating-menu">
            <Svg className={styles.dotsIcon} icon={DotsGroup} />
          </Grid>
          <Grid className={styles.floatingContent}>
            <Grid className={styles.floatingLabels}>
              <Typography fontSize={14}>
                Selecionado:{' '}
                <b>
                  {selectedItems.length}
                  {selectedItems.length > 1 ? ' itens' : ' item'}
                </b>
              </Typography>
              <Typography fontSize={14}>|</Typography>
              <Typography fontSize={14}>
                Valor final a receber:{' '}
                <b style={{ color: Theme.Colors.Primary.Base }} fontSize={14}>
                  {formatCurrency(priceToReceive.totalValue)}
                </b>
              </Typography>
            </Grid>
            <Grid className={styles.floatingLabels}>
              {isAdmin && (
                <>
                  <Button
                    className={styles.button}
                    variant="ghost"
                    startIcon={
                      <Cards color={Theme.Colors.Primary.Base} style={{ fontSize: '20px' }} />
                    }
                    onClick={() => handleButtonsActions('installments')}
                  >
                    Alterar parcelamento
                  </Button>
                  <Typography fontSize={14}>|</Typography>
                  <Button
                    className={styles.button}
                    variant="ghost"
                    startIcon={
                      <CardAdd color={Theme.Colors.Primary.Base} style={{ fontSize: '20px' }} />
                    }
                    onClick={() => handleButtonsActions('insert')}
                  >
                    Inserir boleto
                  </Button>
                  <Button
                    className={styles.redButton}
                    variant="ghost"
                    startIcon={
                      <CardSlash color={Theme.Colors.Red.Base} style={{ fontSize: '20px' }} />
                    }
                    onClick={() => handleButtonsActions('remove')}
                  >
                    Excluir boleto
                  </Button>
                  <Typography fontSize={14}>|</Typography>
                  <Button
                    className={styles.button}
                    variant="ghost"
                    startIcon={
                      <DollarReceive
                        color={Theme.Colors.Primary.Base}
                        style={{ fontSize: '20px' }}
                      />
                    }
                    onClick={() => handleFloatingModalNavigation('confirm')}
                  >
                    Receber
                  </Button>
                  <Button
                    className={styles.redButton}
                    variant="ghost"
                    startIcon={
                      <DollarReceive color={Theme.Colors.Red.Base} style={{ fontSize: '20px' }} />
                    }
                    onClick={() => handleFloatingModalNavigation('cancel')}
                  >
                    Cancelar recebimento
                  </Button>
                  <Typography fontSize={14}>|</Typography>
                </>
              )}
              <Button
                className={styles.button}
                variant="ghost"
                startIcon={
                  <GridEdit color={Theme.Colors.Primary.Base} style={{ fontSize: '20px' }} />
                }
                onClick={() => handleButtonsActions('download')}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Draggable>
  )
}

export default FloatingMenu
