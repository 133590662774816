import React from 'react'
import { Grid } from '@material-ui/core'
import { Button, Typography } from '@refera/ui-web'
import Draggable from 'react-draggable'

import Theme from '@refera/ui-core'

import DotsGroup from '_assets/svgs/dots-group.svg'
import { DollarCircle as DollarCircleIcon } from '@refera/ui-icons'
import Svg, { ICON } from '_/components/svg'

import useStyles from './styles'

const FloatingMenu = ({
  selected,
  price,
  handleGoToConfirm,
  handleDownloadSpreadsheet = () => {},
}) => {
  const styles = useStyles()

  return (
    <Draggable handle="#draggable-floating-menu" cancel={'[class*="MuiDialogContent-root"]'}>
      <Grid className={styles.floatingContainer}>
        <Grid className={styles.floatingWrapper}>
          <Grid className={styles.draggable} id="draggable-floating-menu">
            <Svg className={styles.dotsIcon} icon={DotsGroup} />
          </Grid>
          <Grid className={styles.floatingContent}>
            <Grid className={styles.floatingLabels}>
              <Typography fontSize={14}>
                Selecionado:{' '}
                <b>
                  {selected}
                  {selected > 1 ? ' itens' : ' item'}
                </b>
              </Typography>
              <Typography fontSize={14}>|</Typography>
              <Typography fontSize={14}>
                Valor total:{' '}
                <b>{price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
              </Typography>
            </Grid>
            <Grid className={styles.buttonsContainer}>
              <Button
                className={styles.button}
                variant="ghost"
                startIcon={
                  <DollarCircleIcon
                    color={Theme.Colors.Primary.Base}
                    style={{ fontSize: '24px' }}
                  />
                }
                onClick={() => handleGoToConfirm('pay')}
              >
                Pagar
              </Button>

              <Button
                className={styles.redButton}
                variant="ghost"
                startIcon={
                  <DollarCircleIcon color={Theme.Colors.Red.Base} style={{ fontSize: '24px' }} />
                }
                onClick={() => handleGoToConfirm('remove')}
              >
                Remover pagamento
              </Button>

              {handleDownloadSpreadsheet && (
                <Button
                  className={styles.button}
                  variant="ghost"
                  startIcon={<Svg type={ICON.DOWNLOAD} color={Theme.Colors.Primary.Base} />}
                  onClick={handleDownloadSpreadsheet}
                >
                  Download de planilha
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Draggable>
  )
}

export default FloatingMenu
