import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  headerButtons: {
    display: 'flex',
    gap: '8px',
  },
  alert: {
    margin: '-16px 0 20px',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '32px',
    flexDirection: 'column',
  },
  subcontainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 21px',
    marginBottom: '30px',
    flexDirection: 'column',
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '85px',
    alignContent: 'center',
    alignItems: 'center',
    gap: '44px',
  },
  input: {
    width: '30%',
  },
  inputSize: {
    width: '100%',
  },
  inputLabel: {
    color: 'black',
    fontSize: '12px',
    fontWeight: '500',
    display: 'flex',
    gap: '4px',
  },
  asterisk: {
    color: 'red',
  },
  button: {
    height: '43px',
    width: '100px',
    padding: '10px 16px',
  },
  label: {
    top: '-8px',
    left: '15px',
    padding: '0px 6px',
    position: 'absolute',
    backgroundColor: 'white',
  },
}))
