import React from 'react'

import useStyles from './styles'
import CloseIcon from '_assets/icons/ic-close-circle.svg'
import SaveIcon from '_assets/icons/ic-tick-circle.svg'
import { Tooltip } from '@material-ui/core'
import IconButton from '_components/svg/icon-button'
import Svg, { ICON } from '_components/svg'

const Default = ({ value }) => {
  const styles = useStyles()
  return (
    <div className={styles.default}>
      {value ? (
        <Svg icon={SaveIcon} className={styles.icon} />
      ) : (
        <Svg icon={CloseIcon} className={styles.icon} />
      )}
    </div>
  )
}

export const ReferaUsersColumns = sendEmailCallback => [
  {
    field: 'name',
    headerName: 'Nome',
    flex: 1,
    editable: false,
  },
  {
    field: 'email',
    headerName: 'E-mail',
    flex: 1,
    editable: false,
  },
  {
    field: 'isTemporaryPassword',
    headerName: 'Pendente',
    width: 100,
    editable: false,
    renderCell: ({ value }) => <Default value={value} />,
  },
  {
    field: 'actions',
    headerName: 'Ações',
    width: 150,
    editable: false,
    renderCell: ({ row }) =>
      row.isTemporaryPassword && (
        <Tooltip title="Ative seu usuário da Refera!">
          <IconButton variant="ghost" onClick={event => sendEmailCallback(event, row)}>
            <Svg type={ICON.SEND_EMAIL} />
          </IconButton>
        </Tooltip>
      ),
  },
]
