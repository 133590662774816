import { makeStyles } from '@material-ui/core/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  modal: {
    position: 'absolute',
    zIndex: 4,
    left: '33%',
    top: '3%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-height: 900px)': {
      position: 'absolute',
      left: '0%',
      bottom: '0%',
      top: '100%',
      width: '100%',
      padding: '2px',
    },
    title: {
      color: '#434343',
      fontSize: '16px',
      fontWeight: '500',
      marginTop: '25px',
    },
    description: {
      color: '#434343',
      fontSize: '14px',
      fontWeight: '400',
      marginTop: '10px',
    },
    link: {
      color: Theme.Colors.Primary.Base,
      fontSize: Theme.Typography.FontSize.XXSmall,
      fontWeight: Theme.Typography.FontWeight.Medium,
    },
  },
}))
