import React from 'react'
import useStyles from './styles'

const { Grid, Typography } = require('@mui/material')

/**
 * @typedef {Object} EmptyScreenProps
 * @property {React.ElementType} Icon - The icon component of refera/ui-icons.
 * @property {string} [title] - The title of the screen.
 * @property {string} [subtitle] - The subtitle of the screen.
 *
 * @returns {EmptyScreenProps} The dialog context value.
 */

export function EmptyScreen({ Icon, title = '', subtitle = '' }) {
  const styles = useStyles()

  return (
    <Grid className={styles.container}>
      <Grid className={styles.iconWrapper}>
        <Icon className={styles.icon} />
      </Grid>
      <Grid className={styles.textWrapper}>
        {title && <Typography className={styles.title}>{title}</Typography>}
        {subtitle && <Typography>{subtitle}</Typography>}
      </Grid>
    </Grid>
  )
}
