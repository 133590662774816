export const BANK_OPERATION = [
  { value: '0', label: '000 – Conta pagamento' },
  { value: '1', label: '001 – Conta Corrente de Pessoa Física' },
  { value: '2', label: '002 – Conta Simples de Pessoa Física' },
  { value: '3', label: '003 – Conta Corrente de Pessoa Jurídica' },
  { value: '6', label: '006 – Entidades Públicas' },
  { value: '7', label: '007 – Depósitos Instituições Financeiras' },
  { value: '13', label: '013 – Poupança de Pessoa Física' },
  { value: '22', label: '022 – Poupança de Pessoa Jurídica' },
  { value: '28', label: '028 – Poupança de Crédito Imobiliário' },
  { value: '43', label: '043 – Depósitos Lotéricos' },
]

export const PIX_KEY = [
  { value: 'random', label: 'Chave aleatória' },
  { value: 'cpf', label: 'CPF/CNPJ' },
  { value: 'email', label: 'E-mail' },
  { value: 'phone_number', label: 'Telefone' },
]

export const PROVIDER_FIELDS = {
  ADDRESS: 'address',
  BANK: 'bank',
  BANK_ACCOUNT_CPF_CNPJ: 'bankAccountCpfCnpj',
  BANK_ACCOUNT_OWNER_NAME: 'bankAccountOwnerName',
  BANK_ACCOUNT_TYPE: 'bankAccountType',
  BANK_ACCOUNT_WITH_DIGIT: 'bankAccountWithDigit',
  BANK_AGENCY_NUMBER: 'bankAgencyNumber',
  BRAND_LOGO_PNG_URL: 'brandLogoPngUrl',
  CEP: 'cep',
  CITY: 'city',
  CNPJ: 'cnpj',
  COMPANY_CREATION_DATE: 'companyCreationDate',
  CONTACT_EMAIL: 'contactEmail',
  EXTRA_ADDRESS_INFO: 'extraAddressInfo',
  ID: 'id',
  MAIN_SERVICES: 'mainServices',
  NAME: 'name',
  NEARBY_LANDMARK: 'nearbyLandmark',
  NEIGHBORHOOD: 'neighborhood',
  NUMBER_OF_EMPLOYEES: 'numberOfEmployees',
  PERCENTAGE: 'agencyCompletionPercentage',
  PERSON_RESPONSIBLE_NAME: 'personResponsibleName',
  PHONE: 'phone',
  COMPANY_ATTACHMENTS: 'companyAttachments',
  PIX_KEY: 'pixKey',
  PIX_KEY_CHOICE: 'pixKeyChoice',
  PROFILE_PICTURE: 'profilePicture',
  CITIES: 'cities',
  REFERA_PERCENTAGE: 'referaCompletionPercentage',
  REGISTRATION_STATUS: 'registrationStatus',
  STATE: 'state',
  STREET_NUMBER: 'streetNumber',
  MAIN_SERVICE_NAMES: 'mainServiceNames',
  ONBOARDING_STAGE: 'onboardingStage',
}
