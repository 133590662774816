/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core'

import Modal from '_components/modal'
import { REGIONS_FIELDS } from '_utils/regions'

import useStyles from './styles'

const AddRegionModal = ({
  handleModal,
  handleSubmit,
  cityOptions,
  stateOptions,
  stateNames,
  disabledSubmit,
  regionsList,
  isLoading,
}) => {
  const styles = useStyles()

  const [selectedCity, setSelectedCity] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [isCitySelected, setIsCitySelected] = useState(true)
  const [availableCities, setAvailableCities] = useState([])

  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  const onSelectCity = e => {
    setSelectedCity(e)
  }

  const onSelectState = e => {
    setIsCitySelected(false)
    setSelectedState(e)
    handleGetStateCities(e)
  }

  const handleGetStateCities = e => {
    const cityOptions2 = stateOptions.filter(state => state.name === e)
    const cities = cityOptions.filter(city => city.state.id === cityOptions2[0].id)
    setAvailableCities(cities)
  }

  const handleSubmitRegion = () => {
    const data = {
      state: selectedState,
      city: selectedCity,
    }
    handleSubmit(data)
    handleModal()
  }

  return (
    <Modal
      handleModal={handleModal}
      handleSubmit={handleSubmitRegion}
      title={REGIONS_FIELDS.TITLE}
      disabledSubmit={disabledSubmit}
      className={styles.modal}
      isLoading={isLoading}
    >
      <Grid container className={styles.wrapper}>
        <FormControl fullWidth>
          <InputLabel id="select-states">Estado</InputLabel>
          <Select
            MenuProps={menuProps}
            labelId="select-states"
            label="Estado"
            value={selectedState}
            onChange={e => onSelectState(e.target.value)}
            className={styles.contentState}
          >
            {stateNames.map((state, index) => (
              <MenuItem value={state} key={index}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="select-cities">Cidade</InputLabel>
          <Select
            disabled={isCitySelected}
            MenuProps={menuProps}
            labelId="select-cities"
            label="Cidade"
            value={selectedCity}
            onChange={e => onSelectCity(e.target.value)}
            className={styles.contentCity}
          >
            {availableCities?.map((city, index) =>
              regionsList?.find(x => x.city === city.label) ? (
                <MenuItem value={city.label} key={index} disabled>
                  {city.label}
                </MenuItem>
              ) : (
                <MenuItem value={city.label} key={index}>
                  {city.label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
    </Modal>
  )
}

AddRegionModal.propTypes = {
  disabledSubmit: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  touched: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  isProvider: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
}

AddRegionModal.defaultProps = {
  isLoading: false,
}

export default AddRegionModal
