import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ palette, spacing }) => ({
  history: {
    backgroundColor: palette.custom.rhino,
  },
  historyHeader: {
    boxShadow: `${spacing(0, 4, 4)} rgba(0, 0, 0, 0.25)`,
    padding: spacing(16, 24),
    height: spacing(95),
  },
  timeLine: {
    color: 'white',
  },
  icon: {
    height: spacing(32),
    width: spacing(32),
    marginRight: spacing(20),
  },
  content: {
    padding: spacing(24),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  chip: {
    borderRadius: spacing(24),
    textTransform: 'none',
    fontFamily: ['Roboto', 'sans-serif'],
    padding: spacing(6, 12),
    fontSize: '14px',
    lineHeight: '112.5%',
    cursor: 'pointer',
    margin: spacing(10, 0, 10),
  },
  chipEnabled: {
    backgroundColor: Theme.Colors.Primary.Base,
    color: 'white',
  },
  chipDisabled: {
    backgroundColor: 'transparente',
    border: '1px solid white',
    color: 'white',
  },
}))
