import React from 'react'
import { Typography, Grid } from '@material-ui/core'

import Trash from '_assets/icons/ic-trash.svg'
import IconButton from '_components/svg/icon-button'

import useStyles from './styles'

export function AccordionInfo({ values, handleDeleteValue }) {
  const styles = useStyles()

  return (
    <Grid className={styles.wrapper}>
      <Grid className={styles.details}>
        <Grid className={[styles.regionDetails, styles.value]}>
          <Typography component="p" variant="body1">
            {values?.desc}
          </Typography>
        </Grid>
        <Grid className={styles.buttonsContainer}>
          <IconButton icon={Trash} onClick={() => handleDeleteValue(values)} />
        </Grid>
      </Grid>
    </Grid>
  )
}
