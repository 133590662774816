import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  valuesContent: {
    width: '100%',
    maxWidth: '1000px',
    marginBottom: '30px',
  },
  valuesButton: {
    marginTop: '20px',
  },
  label: {
    fontSize: spacing(12),
  },
  tableCell: {
    fontSize: '14px',
    padding: '13px',
    height: '50px',

    '&.MuiTableCell-root': {
      borderBottom: '1px solid #E3E3E3',
    },
  },
  tableHeader: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: Theme.Typography.FontSize.XSmall,
    color: Theme.Colors.Grayscale.Eighty,
  },
}))
