import { RESEND_PASSWORD } from './actions'

export const getProfileDataPermissionsSelector = state =>
  state?.profiles?.get('profileDataPermissions')

export const getProfileActionPermissionsSelector = state =>
  state?.profiles?.get('profileActionPermissions')

export const getProfilesSelector = state => state?.profiles?.get('profiles')

export const getPermissionsGroupSelector = state => state?.profiles?.get('permissionsGroup')

export const getProfileSelector = state => state?.profiles?.get('profile')

export const getResendPasswordLoadingSelector = state => !!state.loading.get(RESEND_PASSWORD.ACTION)
