import React from 'react'
import { BaseContractModal } from '_components/modal/contracts/base'
import { Box } from '@mui/material'

function ContractDeadlineOverModal({
  onConfirm = () => {},
  onCancel = () => {},
  deadlineDate = '',
}) {
  return (
    <BaseContractModal.Root>
      <BaseContractModal.Icon iconType="contract" sx={{ marginTop: '24px' }} />
      <Box display="flex" flexDirection="column" width="100%" marginTop="24px">
        <BaseContractModal.Header variant="variant2" sx={{ margin: '0 22px' }}>
          Atenção! Seu prazo para assinar o contrato com a Refera venceu.
        </BaseContractModal.Header>
        <BaseContractModal.Paragraph sx={{ margin: '16px 32px 0' }}>
          O prazo para sua empresa ter um contrato assinado com a Refera venceu em{' '}
          <strong>{deadlineDate}.</strong> Embora o sistema continue liberado para que você possa
          finalizar serviços em andamento, a Refera não poderá mais enviar novos chamados para sua
          empresa.
          <br />
          <br />
          Contacte a Refera para eventuais dúvidas ou selecione a opção abaixo para proceder com a
          assinatura do documento.
        </BaseContractModal.Paragraph>
      </Box>
      <BaseContractModal.ActionButtonContainer sx={{ margin: '24px 0 16px' }}>
        <BaseContractModal.ActionButton
          variant="secondary"
          sx={{ width: '165px' }}
          color="red"
          onClick={onCancel}
        >
          Cancelar
        </BaseContractModal.ActionButton>
        <BaseContractModal.ActionButton
          variant="primary"
          sx={{ width: '165px' }}
          onClick={onConfirm}
        >
          Assinar Contrato
        </BaseContractModal.ActionButton>
      </BaseContractModal.ActionButtonContainer>
    </BaseContractModal.Root>
  )
}

export default ContractDeadlineOverModal
