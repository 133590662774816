/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'

import Autocomplete from '@mui/material/Autocomplete'
import { TextField, Typography } from '@material-ui/core'
import { Loader } from '@refera/ui-web'
import { useToast } from '_/hooks/use-toast'
import { useDialog } from '_/hooks/use-dialog'

import * as Modal from '_components/modal/generic-modal'
import {
  getBudgetLoadTemplates,
  patchBudgetSaveTemplates,
  deleteBudgetSaveTemplates,
} from '_/modules/budget/actions'
import { SaveModelsModal } from '../save-modal'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_hooks/use-role-permission'

import { useParams } from '@reach/router'

import useStyles from './styles'

export const LoadModelsModal = ({ open, handleConfirm = () => {}, handleModal = () => {} }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [templates, setTemplates] = useState([])
  const [selectValue, setSelectValue] = useState()
  const [resetInputField, setResetInputField] = useState(false)
  const [saveModalOpen, handleSaveModal] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const { budgetId } = useParams()
  const { showToast } = useToast()
  const { showDialog, closeDialog } = useDialog()
  const { checkUserPermission } = useRolePermission()

  const getOptionLabel = useCallback(
    option => {
      return option?.name
    },
    [templates]
  )

  const getOptionSelected = useCallback(
    (option, currentValue) => {
      return option.id === currentValue
    },
    [templates]
  )

  useEffect(() => {
    dispatch(getBudgetLoadTemplates()).then(res => {
      setTemplates(res)
    })
  }, [])

  const handleEditModel = useCallback(() => {
    handleModal()
    handleSaveModal(prevState => !prevState)
  }, [])

  const handleDelete = useCallback(() => {
    setPageLoading(true)

    dispatch(deleteBudgetSaveTemplates(budgetId, { templateId: selectValue?.id }))
      .then(() => {
        setSelectValue(null)
        setResetInputField(prev => !prev)
        setPageLoading(false)
        handleSaveModal(false)
        showToast({
          type: 'success',
        })

        dispatch(getBudgetLoadTemplates()).then(res => {
          setTemplates(res)
        })
      })
      .catch(error => {
        setPageLoading(false)
        showToast({
          type: 'error',
          message: error?.error_message || 'Ocorreu um erro desconhecido ao executar a ação.',
        })
      })
  }, [budgetId, selectValue])

  const handleDeleteModel = useCallback(() => {
    if (!selectValue) {
      showToast({
        type: 'error',
        message: 'Escolha antes o modelo a ser excluído no campo acima.',
      })
    } else {
      showDialog({
        type: 'warning',
        subject: 'Você tem certeza que deseja executar esta ação?',
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onApprove: () => {
          checkUserPermission({
            permission: PERMISSIONS.INTERNAL_TEMPLATES,
            permissionAction: PERMISSIONS_ACTIONS.DELETE,
            functionAccess: handleDelete,
          })
          closeDialog()
        },
        onCancel: closeDialog,
      })
    }
  }, [selectValue, handleDelete])

  const handleConfirmEdition = useCallback(
    async payload => {
      await dispatch(patchBudgetSaveTemplates(budgetId, payload, { templateId: selectValue?.id }))
        .then(() => {
          setPageLoading(false)
          handleSaveModal(prevState => !prevState)
          showToast({
            type: 'success',
          })
        })
        .catch(error => {
          setPageLoading(false)
          showToast({
            type: 'error',
            message: error?.error_message || 'Ocorreu um erro desconhecido ao executar a ação.',
          })
        })
    },
    [budgetId, selectValue]
  )

  return (
    <Grid>
      <Modal.Root open={open} onClose={() => handleModal()} maxWidth="sm" keepMounted>
        <Modal.TitleModal title="Modelo para importar" />

        <Modal.Content className={styles.modalContent}>
          <Grid className={styles.wrapper}>
            <Typography className={styles.serviceLabel}>Modelo</Typography>
            <Autocomplete
              disablePortal
              freeSolo
              key={resetInputField}
              multiple={false}
              options={templates}
              className={styles.autocomplete}
              getOptionSelected={getOptionSelected}
              getOptionLabel={getOptionLabel}
              onChange={(event, newValue) => {
                setSelectValue(newValue)
              }}
              ListboxProps={{
                style: {
                  fontSize: '14px',
                  maxHeight: '190px',
                },
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { height: '55px', paddingTop: '13px', maxHeight: '100px' },
                  }}
                />
              )}
            />
          </Grid>
        </Modal.Content>

        <Modal.Actions>
          <Modal.ButtonFullRed onClick={() => handleDeleteModel()}>Excluir</Modal.ButtonFullRed>
          <Modal.ButtonRed onClick={() => handleModal()}>Cancelar</Modal.ButtonRed>
          <Modal.ButtonBlue disabled={!selectValue} onClick={() => handleEditModel()}>
            Editar
          </Modal.ButtonBlue>
          <Modal.ButtonFullBlue
            disabled={!selectValue}
            onClick={() =>
              handleConfirm({
                templateId: selectValue?.id,
                saveValue: selectValue?.saveValue,
              })
            }
          >
            Salvar
          </Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>
      <SaveModelsModal
        open={saveModalOpen}
        handleConfirm={handleConfirmEdition}
        handleModal={() => handleSaveModal()}
        isEditing
        model={selectValue}
      />
      <Loader open={pageLoading} hasBackdrop />
    </Grid>
  )
}
