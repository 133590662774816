import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    width: spacing(400),
    gap: spacing(12),
    paddingBottom: spacing(16),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  wrapper: {
    whiteSpace: 'break-spaces',
  },
  icon: {
    display: 'flex',
    height: spacing(36),
    width: spacing(36),
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    color: Theme.Colors.Grayscale.OneThousand,
    fontSize: spacing(14, '!important'),
    fontWeight: '500 !important',
  },
  description: {
    display: 'flex',
    fontSize: spacing(12, '!important'),
  },
  link: {
    display: 'flex',
    textAlign: 'left',
    fontWeight: '400 !important',
    padding: spacing(0, '!important'),
    fontSize: spacing(12, '!important'),
    color: `${Theme.Colors.Grayscale.Eighty} !important`,
  },
  date: {
    display: 'flex',
    fontSize: spacing(12, '!important'),
    color: `${Theme.Colors.Grayscale.SixtyFour} !important`,
  },
}))
