import React, { useCallback, useEffect, useMemo } from 'react'
import { Tabs, Tab, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'

import {
  getServiceOrderByID,
  currentServiceOrderSelector,
  getServiceOrderByIdLoadingSelector,
} from '_modules/service-orders/selectors'
import { getServiceOrder, setCurrent } from '_modules/service-orders/actions'

import useStyles from './styles'
import { getHistoryLogs } from '_modules/history-logs/actions'

const DuplicatedTabs = ({ serviceOrderId, pathname }) => {
  const dispatch = useDispatch()
  const currentServiceOrder = useSelector(currentServiceOrderSelector)
  const isGetServiceOrderLoading = useSelector(getServiceOrderByIdLoadingSelector)

  const originalServiceOrder = useSelector(state =>
    getServiceOrderByID(state, currentServiceOrder?.id?.toString())
  )

  const serviceOrder = useMemo(() => {
    if (!currentServiceOrder?.duplicatedFrom) {
      return currentServiceOrder
    }
    return originalServiceOrder
  }, [originalServiceOrder, currentServiceOrder])

  const tabLabel = useMemo(
    () => (serviceOrder?.get('id') ? `Chamado #${serviceOrder.get('id')}` : ''),
    [serviceOrder]
  )

  const styles = useStyles({})

  useEffect(() => {
    if (currentServiceOrder.duplicatedFrom && !isGetServiceOrderLoading && !originalServiceOrder) {
      dispatch(getServiceOrder(currentServiceOrder?.duplicatedFrom, false))
    }
  }, [dispatch, originalServiceOrder, currentServiceOrder.duplicatedFrom, isGetServiceOrderLoading])

  const handleTabClick = useCallback(
    id => () => {
      dispatch(setCurrent(id.toString()))
      dispatch(getServiceOrder(id))
      dispatch(getHistoryLogs(id))
      navigate(`/chamado/${id}`)
    },
    [dispatch]
  )

  const renderDuplicatedTab = useCallback(
    item => {
      const duplicatedTabLabel = item?.get('id') ? `Chamado #${item.get('id')}` : ''
      return (
        <Tab
          key={`tab-duplicate-${item?.get('id')}`}
          className={classnames(styles.tab, {
            [styles.selectedTab]: pathname
              ?.split('/')
              .some(value => value === item?.get('id').toString()),
          })}
          value={`tab-${item?.get('id')}`}
          label={duplicatedTabLabel}
          component={Button}
          onClick={handleTabClick(item?.get('id'))}
        />
      )
    },
    [handleTabClick, pathname, styles.selected, styles.tab]
  )

  return (
    <Tabs
      classes={{ indicator: styles.selected }}
      value={`tab-${serviceOrderId}`}
      aria-label="Selecione o chamado"
      indicatorColor="primary"
      className={styles.tabs}
      variant="fullWidth"
    >
      {serviceOrder?.duplicatedFrom && (
        <Tab
          className={classnames(styles.tab, {
            [styles.selectedTab]: pathname
              .split('/')
              .some(value => value === serviceOrder?.get('duplicatedFrom').toString()),
          })}
          value={`tab-${serviceOrder?.get('duplicatedFrom')}`}
          label={`Chamado #${serviceOrder.get('duplicatedFrom')}`}
          component={Button}
          onClick={handleTabClick(serviceOrder?.get('duplicatedFrom'))}
        />
      )}
      <Tab
        className={classnames(styles.tab, {
          [styles.selectedTab]: pathname
            .split('/')
            .some(value => value === serviceOrder?.get('id').toString()),
        })}
        value={`tab-${serviceOrder?.get('id')}`}
        label={tabLabel}
        component={Button}
        onClick={handleTabClick(serviceOrder?.get('id'))}
      />
      {serviceOrder?.serviceOrderDuplications?.map((item, index) =>
        renderDuplicatedTab(item, index)
      )}
    </Tabs>
  )
}

DuplicatedTabs.propTypes = {
  pathname: PropTypes.string,
  serviceOrderId: PropTypes.string,
}

DuplicatedTabs.defaultProps = {
  pathname: '',
  serviceOrderId: '',
}
export default React.memo(DuplicatedTabs)
