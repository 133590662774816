/* eslint-disable no-param-reassign */
import { createFormDataDocuments, notEmptyPayload } from '_/utils/helpers'
import { get, patch, post, blobInstance } from './requests'
import humps from 'humps'
import { getToken } from '_/utils/token'

const decamelizeParams = params => {
  Object.values(params)?.forEach(param => {
    if (typeof param === 'string') {
      const replaceKey = Object.keys(params).find(paramKey => params[paramKey] === param)
      params[replaceKey] = humps.decamelize(param)
    }
  })
  return params
}

export const getBudgetInstallments = key => params => {
  const payloadParams = params

  return get(['finance/budget-installment'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(payloadParams)),
  })
}

export const getTradesmanInstallments = key => params => {
  return get(['finance/tradesman-installments'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(params)),
  })
}

export const getTradesmanInstallmentsDetails = key => params => {
  const payloadParams = decamelizeParams(params)

  return get(['finance/budget-installment/details'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(payloadParams)),
  })
}

export const payBudgetInstallments = key => payload =>
  patch(
    ['finance/budget-installment/pay-installments'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const createAnticipationsTradesman = key => payload =>
  post(
    ['finance/tradesman-anticipations/create'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const getTradesmanAnticipations = key => params => {
  const payloadParams = decamelizeParams(params)
  return get(['finance/tradesman-anticipations'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(payloadParams)),
  })
}

export const deleteTradesmanAnticipation = key => payload =>
  post(
    ['finance/tradesman-anticipations/delete'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const removeAnticipationsPayments = key => payload =>
  patch(
    ['finance/tradesman-anticipations/remove-anticipations-payments'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const removeBudgetInstallments = key => payload =>
  patch(
    ['finance/budget-installment/remove-installments'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const getFinanceOptions = key =>
  get(['finance', 'general'], {
    key,
  })

export const getBatches = key => params => {
  const payloadParams = decamelizeParams(params)
  return get(['finance', 'batch'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(payloadParams)),
  })
}

export const updateBatch = key => (batchId, payload) => {
  return patch(
    ['finance', 'batch', batchId],
    {
      key,
    },
    payload
  )
}

export const cancelBatchInstallments = key => (batchId, payload) =>
  post(
    ['finance', 'batch', batchId, 'remove-installments'],
    {
      key,
    },
    payload
  )

export const releaseInstallmentsByBatchId = key => batchId =>
  patch(['finance', 'batch', batchId, 'release-payments'], { key })

export const getInstallmentsByBatchId = key => (batchId, params) =>
  get(['finance', 'batch', batchId, 'details'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(params)),
  })

export const getReturnFilesList = key => params => {
  const payloadParams = decamelizeParams(params)
  return get(['finance', 'batch', 'return-files'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(payloadParams)),
  })
}
export const uploadReturnFile = key => payload =>
  post(
    ['finance', 'batch', 'upload-return-file'],
    {
      key,
      transformOnlyResponse: true,
      formData: true,
    },
    createFormDataDocuments(payload)
  )

export const downloadReturnFilePdf = key => payload =>
  patch(
    ['finance', 'batch', 'download-return-file-pdf'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const getPaymentData = key => params =>
  get(['finance/log-batch-file'], {
    key,
    transformPayload: true,
    params,
  })

export const getPaymentAnticipationsList = key => params =>
  get(['payment_anticipation/anticipations-list'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(params)),
  })

export const getPaymentAnticipationById = paymentId => {
  const headers = getToken()
  return get(['payment_anticipation', paymentId], { ...headers })
}

export const getPaymentAnticipationDetails = key => anticipationId =>
  get(['payment_anticipation', anticipationId, 'details'], { key })

export const updatePaymentAnticipations = key => payload =>
  patch(['payment_anticipation', 'update-payment-anticipations'], { key }, payload)

export const payPaymentAnticipations = key => payload =>
  patch(['payment_anticipation', 'pay-payment-anticipations'], { key }, payload)

export const getFinanceGenericParameters = key => () =>
  get(['finance/generic-parameters/daily-anticipation-tax/'], { key })

export const getAnticipationsBannerInfo = key => () =>
  get(['/serviceprovider/anticipations-banner-info/'], { key })

export const getVerifyUpdateAnticipations = key => anticipationId =>
  get(['payment_anticipation', anticipationId, 'verify-update-anticipations'], {
    key,
    transformPayload: true,
  })

export const getVerifyInstallmentsStepStatus = key => params =>
  get(['finance/budget-installment/verify-installments-step-status'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(params)),
  })

// verify if tradesman has installments by service order
export const getVerifyTradesmanInstallments = key => serviceOrderId =>
  get(['finance/tradesman-installments', serviceOrderId, 'verify-installments'], {
    key,
    transformPayload: true,
  })

export const updatePaymentInstallment = key => (installmentId, payload) =>
  patch(['finance/payment-installment', installmentId, 'edit'], { key }, payload)

export const updateBudgetInstallment = key => (installmentId, payload) =>
  patch(['finance/budget-installment', installmentId, 'edit'], { key }, payload)

export const getBudgetReceivables = key => params => {
  return get(['finance/budget-receivables'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(params)),
  })
}

export const updateVoucherReceivables = key => payload =>
  patch(['finance/budget-receivables/update-voucher/'], { key }, payload)

export const postConfirmReceipts = key => payload =>
  post(['finance/budget-receivables/confirm-receipts/'], { key }, payload)

export const patchCancelConfirmReceipts = key => payload =>
  patch(['finance/budget-receivables/cancel-receipts/'], { key }, payload)

export const postBlockFinanceTransfersByReceipts = key => payload =>
  post(['finance/budget-receivables/block-transfers/'], { key }, payload)

export const getBudgetReceivableDetails = key => budgetReceivableId =>
  get(['finance/receivable-details', budgetReceivableId], {
    key,
    transformPayload: true,
  })

export const updatePaymentReceivable = key => (receivableId, payload) =>
  patch(['finance/payment-receivable', receivableId, 'edit'], { key }, payload)

export const updateBudgetReceivable = key => (receivableId, payload) =>
  patch(['finance/budget-receivables', receivableId, 'edit'], { key }, payload)

export const getBudgetReceivablesSpreadsheets = key => () =>
  get(['finance/budget-receivables-spreadsheets'], {
    key,
    transformPayload: true,
  })

export const postCondolivreAnticipationsInfo = key => payload =>
  post(['finance/tradesman-anticipations/condolivre-info'], { key }, payload)

export const processCondolivrePayments = key => (batchId, payload) =>
  post(['finance', 'batch', batchId, 'process-condolivre-payments'], { key }, payload)

export const updateReceiptsInstallmentQuantity = key => (receivableId, payload) =>
  patch(['finance/budget-receivables', receivableId, 'edit-installment-quantity'], { key }, payload)

export const postExportASAASReceivables = key => params =>
  post(['finance/budget-receivables/asaas-export'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(params)),
  })

export const getInstallmentRules = key => params => {
  return get(['finance/installment-rules/validate-installment-rule'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(params)),
  })
}

export const getIsXmlRequiredToFinishServiceOrder = key => serviceOrderId =>
  get(['finance/nf-tags-xml/is-xml-required'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys({ serviceOrderId }),
  })

export const getLogChargeFilesList = key => params => {
  const payloadParams = decamelizeParams(params)
  return get(['finance/log-charge-file/list-simple'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(payloadParams)),
  })
}

export const uploadChargeFile = key => payload =>
  post(
    ['finance/log-charge-file/upload'],
    {
      key,
      transformOnlyResponse: true,
      formData: true,
    },
    createFormDataDocuments(payload)
  )

export const getChargeFileData = key => params =>
  get(['finance/log-charge-file/'], {
    key,
    transformPayload: true,
    params: notEmptyPayload(params),
  })

export const downloadInstallmentsSpreadsheet = async params => {
  const headers = getToken()

  return blobInstance.get(`/finance/budget-installment/download-spreadsheet/?${params}`, {
    ...headers,
  })
}

export const downloadASAASSpreadsheet = async params => {
  const headers = getToken()

  return blobInstance.get(`/finance/budget-receivables-spreadsheets/download-asaas/?${params}`, {
    ...headers,
  })
}

export const downloadABudgetReceivablesSpreadsheet = async (templateId, params) => {
  const headers = getToken()

  return blobInstance.get(
    `/finance/budget-receivables-spreadsheets/${templateId}/download/?${params}`,
    {
      ...headers,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
  )
}
