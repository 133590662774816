export const PAGES_NAME = {
  SERVICE_ORDER_DETAILS: 'service_order_details',
  HELP_CLASSIFICATION: 'help_classification',
  DATE_SUGGESTION: 'date_suggestion',
  ADDRESS: 'address',
  IS_OWNER: 'is_owner',
  LEASE_HOLDER_INFO: 'lease_holder_info',
  IS_EMERGENCY: 'is_emergency',
  ATTACHMENTS: 'attachments',
  PROPERTY_DETAILS: 'property_details',
  OWNER_DETAILS: 'owner_details',
  EXPECTED_WORK_TO_EXECUTE: 'expected_work_to_execute',
  INTERNAL_INFORMATION: 'internal_information',
  REQUEST_CONTACT: 'request_contact',
  CONCLUDED: 'concluded',
  MAINTENANCE_FORM: 'maintenance_form',
  INVISIBLE: 'invisible',
  PROPERTY: 'property',
  NEW_SERVICE_WRAPPER: 'new_service_wrapper',
}

export const CONTACT_FIELDS = {
  CONTACT_NAME: 'contactName',
  CONTACT_EMAIL: 'contactEmail',
  CONTACT_CPF_OR_CNPJ: 'contactCpfCnpj',
  CONTACT_NUMBER: 'contactNumber',
  CONTACT_NUMBER_CONFIRMATION: 'contactNumberConfirmation',
}

export const ADDRESS_FIELDS = {
  CEP: 'cep',
  ADDRESS: 'address',
  NUMBER: 'number',
  COMPLEMENT: 'complement',
  NEIGHBORHOOD: 'neighborhood',
  STATE: 'state',
  CITY: 'city',
}

export const PROPERTY_FIELDS = {
  PROPERTY_PROPERTY_CODE: 'code',
  PROPERTY_CODE: 'contract_code',
  PROPERTY_ACTIVE: 'isContractActive',
  PROPERTY_LOCATION: 'propertyKeysLocation',
  PROPERTY_CONTRACT_START_DATE: 'contractStartDate',
  PROPERTY_RENT_VALUE: 'rentValue',
}

export const PROPERTY_USER_INFO_FIELDS = {
  PROPERTY_USER_NAME: 'propertyName',
  PROPERTY_USER_EMAIL: 'propertyEmail',
  PROPERTY_USER_PHONE: 'propertyWhatsapp',
  PROPERTY_USER_CPF_OR_CNPJ: 'cpfOrCnpj',
}

export const LEASE_HOLDER_INFO_FIELDS = {
  CLIENT_CPF_CNPJ: 'clientCpfCnpj',
  LHI_PROPERTY: 'lhiProperty',
  LHI_CONTRACT: 'lhiContract',
  LHI_FORM_RELATED_DATA: 'lhiFormRelatedData',
}

export const FIELD_CATEGORY = 'category'

export const EXTRA_FIELDS = {
  RESPONSIBLE_TEAM: 'responsible_team',
  EXTERNAL_ID: 'external_id',
  PRE_APPROVED_VALUE: 'approved_maximum_value',
  REQUEST_TYPE: 'request_type',
  MANAGED_BY: 'managedBy',
  SERVICE_ORDER_TYPE: 'serviceOrderType',
}

export const INVISIBLE_FIELDS = {
  RESPONSIBLE_EMAIL: 'responsibleEmail',
  URL_CALLBACK: 'urlCallback',
}

export const FIELDS = {
  ...ADDRESS_FIELDS,

  IS_EMERGENCY: 'emergency',

  SERVICE_ORDER_DETAILS: 'description',

  SERVICE_ORDER_DETAILS_PACKAGE: 'serviceOrderDetailsPackage',

  IS_OWNER: 'clientType',

  ...PROPERTY_FIELDS,

  ...INVISIBLE_FIELDS,

  ...PROPERTY_USER_INFO_FIELDS,

  ...CONTACT_FIELDS,
}

// THE OBJECT NAMES NEED BE EXACTLY THE SAME AS THE OTHERS FROM HERE
// AND THE VALUES EXACTLY THE SAME AS THE URL
export const URL_VARIABLES_ABLE_TO_BE_ENCRYPTED = {
  PROPERTY_USER_NAME: 'nomeProprietario',
  PROPERTY_USER_EMAIL: 'emailProprietario',
  PROPERTY_USER_PHONE: 'celularProprietario',
  PROPERTY_USER_CPF_OR_CNPJ: 'cpfProprietario',

  CONTACT_NAME: 'nomeInquilino',
  CONTACT_EMAIL: 'emailInquilino',
  CONTACT_NUMBER: 'celularInquilino',
  CONTACT_CPF_OR_CNPJ: 'cpfInquilino',
}

export const MONEY_URL_VARIABLES = {
  PROPERTY_RENT_VALUE: 'valorAluguel',
}

const PROPERTY_FIELDS_URL = {
  PROPERTY_PROPERTY_CODE: 'codigo',
  PROPERTY_CODE: 'codigoContrato',
  PROPERTY_ACTIVE: 'contratoAtivo',
  PROPERTY_LOCATION: 'localizavaoChave',
  PROPERTY_CONTRACT_START_DATE: 'dataInicioContrato',
  ...MONEY_URL_VARIABLES,
}

export const NUMBERS_URL_VARIABLES = {
  CONTACT_NUMBER: 'celularInquilino',
  PROPERTY_USER_PHONE: 'celularProprietario',
}

export const URL_VARIABLES = {
  CEP: 'cep',
  ADDRESS: 'endereco',
  NUMBER: 'numero',
  COMPLEMENT: 'complemento',
  NEIGHBORHOOD: 'bairro',
  STATE: 'uf',
  CITY: 'cidade',

  IS_OWNER: 'tipo',
  ENCRYPTED: 'criptografado',

  RESPONSIBLE_EMAIL: 'emailResponsavel',
  URL_CALLBACK: 'parametroWebhook',

  ...URL_VARIABLES_ABLE_TO_BE_ENCRYPTED,
  ...PROPERTY_FIELDS_URL,
}

export const RS_FIELDS = {
  LAST_STEP: 'rs_last_step',
  CATEGORY: 'rs_category',
  SERVICE_PACKAGE: 'rs_service_package',
  SERVICE_PACKAGE_FILTER: 'rs_service_package_filter',
}
