import { validationEmail, validationPhoneNumber } from '_utils/helpers'
import { USER_ROLES, USER_FIELDS, USER_FIELDS_LABEL } from '_utils/user'
import { TEXTFIELD_MASK } from '_components/textfield'

export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const HANDLE_CURRENT_USER = 'HANDLE_CURRENT_USER'
export const HANDLE_CURRENT_USER_BLUR = 'HANDLE_CURRENT_USER_BLUR'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const VALIDATE = 'VALIDATE'

export const FORM_INITIAL_STATE = {
  [USER_FIELDS.NAME]: '',
  [USER_FIELDS.EMAIL]: '',
  [USER_FIELDS.PHONE]: '',
  [USER_FIELDS.PASSWORD]: '',
  [USER_FIELDS.ROLE]: '',
}

export const INPUTS = [
  {
    label: USER_FIELDS_LABEL[USER_FIELDS.NAME],
    name: USER_FIELDS.NAME,
  },
  {
    label: USER_FIELDS_LABEL[USER_FIELDS.EMAIL],
    name: USER_FIELDS.EMAIL,
  },
  {
    label: USER_FIELDS_LABEL[USER_FIELDS.PHONE],
    name: USER_FIELDS.PHONE,
    mask: TEXTFIELD_MASK.PHONE_NUMBER,
  },
  {
    label: USER_FIELDS_LABEL[USER_FIELDS.PASSWORD],
    name: USER_FIELDS.PASSWORD,
  },
  {
    label: USER_FIELDS_LABEL[USER_FIELDS.USER_ROLE],
    name: USER_FIELDS.ROLE,
    options: [
      {
        label: 'CS Aprovador',
        value: USER_ROLES.CS_APPROVER,
      },
      {
        label: 'CS Gestor',
        value: USER_ROLES.CS_MANAGER,
      },
    ],
  },
]

export const INITIAL_STATE = {
  users: [],
  currentUser: { ...FORM_INITIAL_STATE },
  index: '',
  error: {},
  touched: Object.keys(FORM_INITIAL_STATE).reduce((obj, field) => ({ ...obj, [field]: false }), {}),
}

const REQUIRED_FIELD = 'Campo Obrigatório'

const validate = values => {
  const errors = {}

  if (!values[USER_FIELDS.NAME]?.length) {
    errors[USER_FIELDS.NAME] = REQUIRED_FIELD
  }

  if (!values[USER_FIELDS.PASSWORD]?.length) {
    errors[USER_FIELDS.PASSWORD] = REQUIRED_FIELD
  }

  if (!values[USER_FIELDS.PHONE]?.length) {
    errors[USER_FIELDS.PHONE] = REQUIRED_FIELD
  }

  if (values[USER_FIELDS.USER_ROLE] && !values[USER_FIELDS.USER_ROLE].length) {
    errors[USER_FIELDS.USER_ROLE] = REQUIRED_FIELD
  }

  if (!values[USER_FIELDS.EMAIL]?.length) {
    errors[USER_FIELDS.EMAIL] = REQUIRED_FIELD
  }

  if (!validationEmail(values[USER_FIELDS.EMAIL])) {
    errors[USER_FIELDS.EMAIL] = 'Email inválido'
  }
  if (validationPhoneNumber(values[USER_FIELDS.PHONE])) {
    errors[USER_FIELDS.PHONE] = 'Insira um número válido'
  }

  return errors
}

export const reducer = (state, action) => {
  switch (action.type) {
    case HANDLE_CURRENT_USER: {
      const { name, value } = action.payload.values
      const user = { ...state.currentUser, [name]: value }
      const error = validate(user)
      return {
        ...state,
        currentUser: user,
        error,
      }
    }
    case HANDLE_CURRENT_USER_BLUR: {
      const { name } = action.payload
      const error = validate(state.currentUser)
      return {
        ...state,
        touched: { ...state.touched, [name]: true },
        error,
      }
    }
    case SET_CURRENT_USER: {
      return { ...state, currentUser: action.payload.user, index: action.payload.index }
    }
    case ADD_USER: {
      return {
        ...state,
        users: [...state.users, state.currentUser],
        currentUser: { ...FORM_INITIAL_STATE },
      }
    }
    case SET_INITIAL_STATE: {
      // TODO: Change this to set INITIAL_STATE when integrate
      const formValues = action.payload?.isProvider
        ? Object.keys(FORM_INITIAL_STATE)
            .filter(key => key !== USER_FIELDS.USER_ROLE)
            .reduce((res, key) => ({ ...res, [key]: FORM_INITIAL_STATE[key] }), {})
        : FORM_INITIAL_STATE

      return {
        ...state,
        currentUser: { ...formValues },
        error: {},
        touched: Object.keys(formValues).reduce((obj, field) => ({ ...obj, [field]: false }), {}),
        index: '',
      }
    }
    case UPDATE_USER: {
      // TODO: Change this when integrate
      state.users.splice(state.index, 1, { ...state.currentUser })
      return {
        ...state,
        users: [...state.users],
        currentUser: { ...FORM_INITIAL_STATE },
        index: '',
      }
    }
    case DELETE_USER: {
      // TODO: Change this when integrate
      return {
        ...state,
        users: [
          ...state.users.filter(item => item.userFullName !== action.payload.user.userFullName),
        ],
      }
    }
    case VALIDATE: {
      return {
        ...state,
        error: validate(state.currentUser),
      }
    }

    default:
      return state
  }
}
