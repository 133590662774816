import { defineAction } from '_utils/redux'
import * as formService from '_services/form'
import * as agencyService from '_services/agency'
import { notEmptyPayload, parseNumber } from '_utils/helpers'
import { SETUP_FORM_FIELDS } from '_views/setup-form/utils/steps'

export const CREATE_SERVICE_ORDER_FORM = defineAction('CREATE_SERVICE_ORDER_FORM')
export const UPDATE_SERVICE_ORDER_FORM = defineAction('UPDATE_SERVICE_ORDER_FORM')
export const GET_AGENCY_BY_SLUG = defineAction('GET_AGENCY_BY_SLUG')
export const GET_SERVICE_ORDER_FORM = defineAction('GET_SERVICE_ORDER_FORM')
export const GET_DECRYPT = defineAction('GET_DECRYPT')
export const FINALIZE_SERVICE_ORDER_FORM = defineAction('FINALIZE_SERVICE_ORDER_FORM')
export const GET_FORM_AGENCY_CUSTOM_FIELDS = defineAction('GET_FORM_AGENCY_CUSTOM_FIELDS')
export const GET_FORM_AGENCY = defineAction('GET_FORM_AGENCY')

const formatPayload = values => {
  const { CONTACT_CPF_CNPJ, CONTACT_NUMBER } = SETUP_FORM_FIELDS
  const { address, number, complement, cep, listingId, city, neighborhood, code, ...payload } =
    notEmptyPayload(values)

  const FORM_KEY = {
    PROPERTY: 'property',
    CPF_OR_CNPJ: 'cpfOrCnpj',
    PROPERTY_WHATSAPP: 'propertyWhatsapp',
    FIRST_CONTACT_SECONDARY: 'firstContactSecondary',
    SECOND_CONTACT_SECONDARY: 'secondContactSecondary',
  }

  const formattedPayload = Object.keys(payload).reduce((acc, currentKey) => {
    switch (currentKey) {
      case CONTACT_NUMBER:
      case FORM_KEY.FIRST_CONTACT_SECONDARY:
      case FORM_KEY.SECOND_CONTACT_SECONDARY:
        return {
          ...acc,
          [currentKey]: `+${parseNumber(payload[currentKey])}`,
        }
      case CONTACT_CPF_CNPJ:
        return {
          ...acc,
          [currentKey]: parseNumber(payload[currentKey]),
        }
      case FORM_KEY.PROPERTY:
        return {
          ...acc,
          [currentKey]: {
            ...payload[currentKey],
            ...(code && { code }),
            ...(payload[currentKey]?.[FORM_KEY.CPF_OR_CNPJ] && {
              [FORM_KEY.CPF_OR_CNPJ]: payload.encrypted
                ? payload[currentKey][FORM_KEY.CPF_OR_CNPJ]
                : parseNumber(payload[currentKey][FORM_KEY.CPF_OR_CNPJ]),
            }),
            ...(payload[currentKey]?.[FORM_KEY.PROPERTY_WHATSAPP] && {
              [FORM_KEY.PROPERTY_WHATSAPP]: payload.encrypted
                ? payload[currentKey][FORM_KEY.CPF_OR_CNPJ]
                : parseNumber(payload[currentKey][FORM_KEY.PROPERTY_WHATSAPP]),
            }),
            address,
            city,
            neighborhood,
            number: parseNumber(number?.toString()),
            complement,
            cep,
            listingId,
          },
        }
      default:
        return {
          ...acc,
          [currentKey]: payload[currentKey],
        }
    }
  }, {})

  return formattedPayload
}

export const createServiceOrderForm = payload => (dispatch, getState) => {
  const data = formService.createServiceOrderForm(getState().user.authToken)(formatPayload(payload))

  dispatch({
    type: CREATE_SERVICE_ORDER_FORM.ACTION,
    payload: data,
  })

  return data
}

export const updateServiceOrderForm = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_SERVICE_ORDER_FORM.ACTION,
    payload: formService.updateServiceOrderForm(getState().form.uuid)(
      getState().form.serviceOrder.id,
      formatPayload(payload)
    ),
  })

export const finalizeServiceOrderForm = (dispatch, getState) =>
  dispatch({
    type: FINALIZE_SERVICE_ORDER_FORM.ACTION,
    payload: formService.finalizeServiceOrderForm(getState().form.uuid)(
      getState().form.serviceOrder.id
    ),
  })

export const getAgencyBySlug = slug => async dispatch => {
  const data = await formService.getAgencyBySlug(slug)
  dispatch({
    type: GET_AGENCY_BY_SLUG.ACTION,
    payload: async () => data,
  })
  return data
}

export const getFormAgencyCustomFields = agencyId => async (dispatch, getState) => {
  const data = await agencyService.getOptionsFieldName(getState().user.authToken)(agencyId)
  dispatch({
    type: GET_FORM_AGENCY_CUSTOM_FIELDS.ACTION,
    payload: async () => data,
  })
  return data
}

export const getServiceOrderForm = (serviceOrderId, uuid) => dispatch =>
  dispatch({
    type: GET_SERVICE_ORDER_FORM.ACTION,
    payload: formService.getServiceOrderForm(serviceOrderId, uuid),
    meta: { uuid },
  })

export const getDecrypt = payload => async dispatch => {
  const data = await formService.getDecrypt(payload)

  dispatch({
    type: GET_DECRYPT.ACTION,
    payload: async () => data,
  })

  return data
}

export const getFormAgency = agencyId => async (dispatch, getState) => {
  const data = await agencyService.getPages(getState().user.authToken)(agencyId)
  dispatch({
    type: GET_FORM_AGENCY.ACTION,
    payload: async () => data,
  })
  return data
}

export const createNewServiceOrderForm = payload => async (dispatch, getState) => {
  const data = await formService.createNewServiceOrderForm(getState().user.authToken)(payload)
  dispatch({
    type: CREATE_SERVICE_ORDER_FORM.ACTION,
    payload: async () => data,
  })
  return data
}

export const updateAttachmentsNewServiceOrderForm = (uuid, payload) => async dispatch => {
  const data = await formService.updateAttachmentsNewServiceOrderForm(uuid)(payload)
  dispatch({
    type: UPDATE_SERVICE_ORDER_FORM.ACTION,
    payload: async () => data,
  })
  return data
}
