import React, { useCallback, useMemo, useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogContent,
  Divider,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { FormHelperText } from '@mui/material'
import { Dialog as ReferaDialog } from '@refera/ui-web'
import { InfoCircle as InfoCircleIcon, Danger as DangerIcon } from '@refera/ui-icons'
import humps from 'humps'

import Button from '_components/button'
import IconButton from '_components/svg/icon-button'
import TextField from '_components/textfield'
import useToggle from '_hooks/use-toggle'
import { providersResultWithoutPaginationSelector } from '_modules/provider/selectors'
import {
  getReasonsToRefuseMetabaseSuggestion,
  updateServiceOrder,
} from '_modules/service-orders/actions'
import { reasonsToRefuseMetabaseSelector } from '_modules/service-orders/selectors'
import { getStepStatusLogSelector } from '_modules/budget/selectors'
import { REGISTER_STATUS } from '_utils/constants'
import { STEP_STATUS } from '_utils/constants/service-order'
import { ServiceOrder } from '_models'

import { ACTIONS, FORM_FIELDS, FORM_INITIAL_STATE, MANDATORY_FIELDS, reducer } from './reducer'
import useStyles from './styles'
import moment from 'moment'

const initialModal = { isOpen: false, message: '', action: () => {} }

const AddProviderModal = ({
  isOpen,
  serviceOrder,
  onCloseModal,
  openPropose,
  updateHistoryLog,
  ...props
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [formState, localDispatch] = useReducer(reducer, FORM_INITIAL_STATE)
  const [modalOpen, setModalOpen] = useState(initialModal)
  const [isConfirmationDialogOpen, toggleIsConfirmationDialogOpen] = useToggle()

  const providersList = useSelector(providersResultWithoutPaginationSelector)
  const reasonsList = useSelector(reasonsToRefuseMetabaseSelector)
  const stepStatusLog = useSelector(getStepStatusLogSelector)

  // TODO: Move it to helpers folder
  const convertToSlug = text => {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replaceAll(' ', '_')
  }

  const hasApprovedInnerBudget = useMemo(
    () =>
      stepStatusLog.some(
        item =>
          item.budgetId === serviceOrder?.activeBudget &&
          item.stepStatus === STEP_STATUS.PAYER_APPROVED_INNER_BUDGET
      ),
    [stepStatusLog]
  )

  const defaultFilters = useMemo(() => {
    return serviceOrder
      ? {
          ...(serviceOrder.getIn(['property', 'city']) && {
            cities: convertToSlug(serviceOrder.getIn(['property', 'city'])),
          }),
          ...(serviceOrder.category?.first?.()?.get('id') && {
            mainServices: serviceOrder.category?.first?.()?.get('id'),
          }),
          registrationStatus: REGISTER_STATUS.COMPLETE,
        }
      : {}
  }, [serviceOrder])

  const handleSelectsOptions = useCallback(() => {
    dispatch(getReasonsToRefuseMetabaseSuggestion())
  }, [dispatch, defaultFilters])

  useEffect(() => {
    handleSelectsOptions()
  }, [])

  const validateFields = useCallback(() => {
    const errorMessage = 'Este campo é obrigatório'

    const mandatoryFieldsErrors = MANDATORY_FIELDS.reduce((acc, currentField) => {
      if (!formState[currentField] && currentField !== FORM_FIELDS.REASON) {
        return { ...acc, [currentField]: errorMessage }
      }

      return acc
    }, {})

    const conditionalMandatoryFieldError =
      !formState[FORM_FIELDS.REASON] && formState[FORM_FIELDS.METABASE] === 'false'
        ? { [FORM_FIELDS.REASON]: errorMessage }
        : {}

    const errorList = { ...mandatoryFieldsErrors, ...conditionalMandatoryFieldError }

    if (Object.keys(errorList)?.length > 0) {
      localDispatch({ type: ACTIONS.SET_ERROR, payload: { error: errorList } })
      return false
    }

    return true
  }, [formState])

  const validate = useCallback(async () => {
    if (!validateFields()) {
      return false
    }

    return true
  }, [
    hasApprovedInnerBudget,
    serviceOrder?.hasProposeOpened,
    stepStatusLog,
    toggleIsConfirmationDialogOpen,
    validateFields,
  ])

  const onSuccess = useCallback(() => {
    if (modalOpen) {
      setModalOpen(initialModal)
      updateHistoryLog()
      onCloseModal()
    }
  }, [onCloseModal, updateHistoryLog, setModalOpen, modalOpen])

  const onUpdateServiceOrderSuccess = useCallback(() => {
    if (serviceOrder?.hasProposeOpened && isOpen) {
      setModalOpen({
        isOpen: true,
        message:
          'O prestador selecionado recebeu o orçamento interno e já pode vê-la em seu App. Ele agora deve abrir tal orçamento, definir garantias e parcelamentos e enviar o orçamento à Refera.',
        action: onSuccess,
      })
    }
  }, [isOpen, onSuccess, serviceOrder?.hasProposeOpened])

  const onUpdateServiceOrderError = useCallback(() => {
    if (serviceOrder?.hasProposeOpened && isOpen) {
      setModalOpen({
        isOpen: true,
        message: 'Ocorreu um erro. Por favor, tente novamente.',
        action: onSuccess,
      })
    }
  }, [isOpen, serviceOrder?.hasProposeOpened])

  const handleSubmit = useCallback(async () => {
    const payload = {
      tradesman: formState[FORM_FIELDS.PROVIDER_NAME],
      followedMetabaseSuggestion: formState[FORM_FIELDS.METABASE],
      refusedSuggestionDetails: formState?.[FORM_FIELDS.DETAILS],
      reasonToRefuseSuggestion:
        formState[FORM_FIELDS.REASON] && humps.decamelize(formState[FORM_FIELDS.REASON]),
      ...(openPropose && {
        stageServiceProvider: 'send_budget',
      }),
      ...(!openPropose && {
        stepStatus: STEP_STATUS.WAITING_SERVICE_ORDER_ACCEPTANCE,
      }),
      ...(serviceOrder?.hasProposeOpened && {
        stepStatus: STEP_STATUS.WAITING_PRE_APPROVED_PROPOSAL_ACCEPTANCE,
      }),
    }

    try {
      const isValid = await validate(payload)

      if (isValid || isConfirmationDialogOpen) {
        /* Check contract status */
        const serviceProviderId = payload.tradesman
        const { contractStatus, blockedContractDate } = providersList.find(
          provider => provider.id === serviceProviderId
        )

        const today = moment()
        const blockedContractDateMoment = moment(blockedContractDate)

        if (!contractStatus || humps.camelize(contractStatus.toLowerCase()) !== 'signed') {
          if (
            !blockedContractDateMoment.isValid() ||
            blockedContractDateMoment.isBefore(today, 'day')
          ) {
            setModalOpen({
              isOpen: true,
              message:
                'Prazo de assinatura do contrato com a Refera vencido. Selecione outro ou postergue tal prazo no cadastro do prestador.',
              action: () => setModalOpen(prev => ({ ...prev, isOpen: false })),
            })
            return
          }
        }

        dispatch(updateServiceOrder(payload))
          .then(() => {
            onUpdateServiceOrderSuccess()
          })
          .catch(() => {
            onUpdateServiceOrderError()
          })
      }

      if (isConfirmationDialogOpen) {
        toggleIsConfirmationDialogOpen()
      }
    } catch (e) {
      console.warn(e)
    }
  }, [
    dispatch,
    formState,
    isConfirmationDialogOpen,
    openPropose,
    serviceOrder?.hasProposeOpened,
    setModalOpen,
    toggleIsConfirmationDialogOpen,
    validate,
  ])

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      localDispatch({
        type: ACTIONS.UPDATE_FIELD,
        payload: { name, value },
      })
    },
    [localDispatch]
  )

  return (
    <Dialog className={styles.modal} onClose={onCloseModal} open={isOpen} {...props}>
      <ReferaDialog
        open={isConfirmationDialogOpen}
        icon={InfoCircleIcon}
        type="info"
        subject="Este chamado possui um orçamento interno que ainda não foi aprovado pelo pagador. Você tem certeza que já deseja enviá-lo ao prestador?"
        labelApprove="Sim"
        labelCancel="Não"
        onApprove={handleSubmit}
        onCancel={toggleIsConfirmationDialogOpen}
      />
      <ReferaDialog
        open={modalOpen.isOpen}
        icon={DangerIcon}
        type="info"
        subject={modalOpen.message}
        onApprove={modalOpen.action}
      />
      <Grid xs={12} item container className={styles.headerConteiner}>
        <Typography className={styles.title}>Selecionar prestador</Typography>
        <IconButton
          aria-label="Close modal"
          onClick={onCloseModal}
          buttonClassName={styles.iconButton}
        >
          <CloseIcon className={styles.icon} />
        </IconButton>
      </Grid>

      <Divider xs={12} />
      <DialogContent className={styles.content}>
        <Typography className={styles.labelInput}>Selecionar prestador *</Typography>
        <FormControl fullWidth error={formState[FORM_FIELDS.ERROR]?.[FORM_FIELDS.PROVIDER_NAME]}>
          <Select
            name={FORM_FIELDS.PROVIDER_NAME}
            className={styles.input}
            variant="standard"
            onChange={onChange}
          >
            {providersList &&
              providersList.map(option => (
                <MenuItem key={option.name} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText error>
            {formState[FORM_FIELDS.ERROR]?.[FORM_FIELDS.PROVIDER_NAME]}
          </FormHelperText>
        </FormControl>
        <FormControl>
          <Typography className={styles.labelInput}>Seguiu a sugestão do Metabase? *</Typography>
          <RadioGroup row onChange={onChange} name={FORM_FIELDS.METABASE}>
            <FormControlLabel value="true" control={<Radio />} label="Sim" />
            <FormControlLabel value="false" control={<Radio />} label="Não" />
          </RadioGroup>
          <FormHelperText error>
            {formState[FORM_FIELDS.ERROR]?.[FORM_FIELDS.METABASE]}
          </FormHelperText>
        </FormControl>
        {formState[FORM_FIELDS.METABASE] === 'false' && (
          <>
            <Typography className={styles.labelInput}>Selecionar motivo *</Typography>
            <FormControl fullWidth error={formState[FORM_FIELDS.ERROR]?.[FORM_FIELDS.REASON]}>
              <Select
                name={FORM_FIELDS.REASON}
                className={styles.input}
                variant="standard"
                onChange={onChange}
              >
                {reasonsList &&
                  reasonsList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText error>
                {formState[FORM_FIELDS.ERROR]?.[FORM_FIELDS.REASON]}
              </FormHelperText>
            </FormControl>
            <Typography className={styles.labelInput}>Mais detalhes</Typography>
            <TextField
              multiline
              variant="outlined"
              maxRows={10}
              className={styles.input}
              onChange={onChange}
              name={FORM_FIELDS.DETAILS}
            />
          </>
        )}
      </DialogContent>

      <Grid container className={styles.buttonContainer}>
        <Button className={styles.button} onClick={onCloseModal} color="red" variant="outlined">
          Cancelar
        </Button>
        <Button
          className={styles.button}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Salvar
        </Button>
      </Grid>
    </Dialog>
  )
}

AddProviderModal.propTypes = {
  isOpen: PropTypes.bool,
  serviceOrder: PropTypes.instanceOf(ServiceOrder),
  onCloseModal: PropTypes.func.isRequired,
  openPropose: PropTypes.bool,
  updateHistoryLog: PropTypes.func,
}

AddProviderModal.defaultProps = {
  isOpen: false,
  serviceOrder: new ServiceOrder(),
  openPropose: false,
  updateHistoryLog: () => {},
}

export default React.memo(AddProviderModal)
