import React, { useCallback } from 'react'
import { FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

const Checkbox = ({ label, onChange, name, formClassName, id, checked, ...otherProps }) => {
  const styles = useStyles({ selected: checked })

  const handleCheckbox = useCallback(
    event => {
      const { checked: checkbox } = event.target
      onChange({ target: { name, value: checkbox, id } })
    },
    [id, name, onChange]
  )

  return (
    <FormControlLabel
      control={
        <MUICheckbox color="primary" onChange={handleCheckbox} checked={checked} {...otherProps} />
      }
      className={classnames(styles.checkbox, formClassName)}
      label={label}
    />
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formClassName: PropTypes.string,
  checked: PropTypes.bool.isRequired,
}

Checkbox.defaultProps = {
  label: '',
  name: '',
  id: '',
  onChange: () => {},
  formClassName: '',
}
export default React.memo(Checkbox)
