import { schema } from 'normalizr'

import Model from './model'
import Bank from './bank'

class BankAccount extends Model({
  id: undefined,
  bank: undefined,
  bankAccountOwnerName: undefined,
  bankAccountCpfCnpj: undefined,
  bankAgencyNumber: undefined,
  bankAccountNumber: undefined,
  bankAccountType: undefined,
  pixKeyChoice: undefined,
  acceptsPix: undefined,
  pixKey: undefined,
}) {}

const bankAccountNewSchema = new schema.Entity(
  'bankAccount',
  {},
  {
    processStrategy: entity => new BankAccount(entity).set('bank', new Bank(entity.bank)),
  }
)

const bankAccountSchema = [bankAccountNewSchema]

export { bankAccountSchema, BankAccount as default }
