import Theme from '@refera/ui-core'

export const defaultToolbarOptions = {
  options: [
    'inline',
    'fontFamily',
    'fontSize',
    'blockType',
    'list',
    'textAlign',
    'colorPicker',
    'link',
  ],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  fontFamily: {
    options: ['Roboto'],
  },
  colorPicker: {
    // get every color from the theme
    colors: Object.values(Theme.Colors).reduce((acc, value) => {
      Object.values(value).forEach(color => {
        acc.push(color)
      })
      return acc
    }, []),
  },
}
