import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Alert, Button, Datagrid, Dialog, Loader, useConfirm } from '@refera/ui-web'

import { Grid } from '@material-ui/core'

import { TradesmanInstallmentsConfirmationColumns } from '../../manage-installments/utils/constants'
import { ANTICIPATIONS_MORE_INFO_URL, ROUTES } from '_/utils/constants'

import HeaderTitle from '_/components/header-title'

import { useDispatch, useSelector } from 'react-redux'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import AnticipationTermsModal from '../components/AnticipationTermsModal'

import useStyles from '../styles'
import { financeGenericParametersSelector } from '_/modules/finance/selectors'
import { TotalAnticipationValues } from '_/views/finance/components/TotalAnticipationValues'
import { CalculateCondolivreAnticipationValues } from '_/views/finance/utils/CalculateCondolivreAnticipationValues'
import useRolePermission from '_/hooks/use-role-permission'
import {
  createAnticipationsTradesman,
  postCondolivreAnticipationsInfo,
} from '_/modules/finance/actions'
import moment from 'moment'
import { formatErrorMessage } from '../../utils/FormatErrorMessage'
import { TABLE_SX } from '../../utils/constants'

const defaultInfoDialog = {
  isOpen: false,
  icon: '',
  type: '',
  subject: '',
  description: '',
}

const AnticipationConfirmation = ({
  menuType,
  toggleMenu,
  selectedIds,
  items,
  handleSuccessPayment,
  loading,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [infoDialog, setInfoDialog] = useState(defaultInfoDialog)
  const [dialogMessage, setDialogMessage] = useState('')
  const [selectedItems, setSelectedItems] = useState(selectedIds || [])
  const [anticipationTermsModalOpen, setAnticipationTermsModalOpen] = useState(false)
  const [condolivreInfo, setCondolivreInfo] = useState([])

  const financeGenericParameters = useSelector(financeGenericParametersSelector)
  const { isTradesman } = useRolePermission()

  const alertMessage = useMemo(() => {
    return menuType === 'pay'
      ? 'Ao confirmar, seu pedido de antecipação passará por um processo de análise. O valor será liberado após a aprovação do pedido.'
      : 'Remover alert'
  }, [menuType])

  const anticipationDay = useMemo(
    () =>
      financeGenericParameters?.anticipationDay
        ? moment(financeGenericParameters.anticipationDay).format('DD/MM/yyyy')
        : '--/--/----',
    [financeGenericParameters?.anticipationDay]
  )

  // New Condolivre integration
  const itemsWithTaxes = useMemo(() => {
    if (!condolivreInfo?.length) return []

    const formattedItems = items.map(item => {
      return { ...condolivreInfo?.filter(info => info.id === item.id)[0], ...item }
    })
    return formattedItems
  }, [items, condolivreInfo])

  // const itemsWithTaxes = useMemo(() => {
  //   const { itemsWithTax } = CalculateTradesmanAnticipationValues({
  //     items,
  //     financeGenericParameters,
  //   })
  //   return itemsWithTax
  // }, [items, financeGenericParameters])

  const totalMenuValues = useMemo(() => {
    const selectedItemsSet = new Set(selectedItems)
    const filteredItems = itemsWithTaxes.filter(item => selectedItemsSet.has(item.id))

    return CalculateCondolivreAnticipationValues({
      items: filteredItems,
    })
  }, [itemsWithTaxes, selectedItems])

  const { isConfirmed } = useConfirm()

  const handleConfirmPayment = useCallback(() => {
    const itemsToPay = itemsWithTaxes.filter(({ id }) => selectedItems.includes(id))
    // const payload = {
    //   ids: idsToPay,
    // }
    // New Condolivre integration
    const payload = {
      installments_with_condolivre_taxes: itemsToPay,
    }

    dispatch(createAnticipationsTradesman(payload))
      .then(() => {
        handleSuccessPayment(true)
      })
      .catch(err => handleSuccessPayment(false, formatErrorMessage(err)))
    // }, [dispatch, selectedItems, selectedIds])
  }, [dispatch, itemsWithTaxes, selectedItems])

  const handleConfirmModal = async () => {
    setDialogMessage('Você confirma a solicitação de antecipação dos itens selecionados?')

    const confirmed = await isConfirmed()

    if (confirmed) {
      setAnticipationTermsModalOpen(false)
      return handleConfirmPayment()
    }
    return undefined
  }

  const anticipationTermsValues = useMemo(() => {
    const { interestPercentage, interestValue } = totalMenuValues

    return {
      interestPercentage,
      interestValue,
      anticipationDay,
    }
  }, [totalMenuValues, anticipationDay])

  const handleRowClick = useCallback(
    event => {
      if (isTradesman) {
        window.open(
          `${ROUTES.DETAIL_SERVICE_ORDER}/${event.row.serviceOrderId}`,
          '_blank',
          'noopener,noreferrer'
        )
      } else {
        window.open(
          `${ROUTES.SERVICE_ORDER}/${event.row.serviceOrderId}/orcamento/${event?.row?.budget}`,
          '_blank',
          'noopener,noreferrer'
        )
      }
    },
    [isTradesman]
  )

  const fetchCondolivreInfo = useCallback(async () => {
    const installmentIds = selectedIds.filter(id => selectedItems.includes(id))

    await dispatch(postCondolivreAnticipationsInfo({ installmentIds }))
      .then(res => setCondolivreInfo(res?.installmentsWithTaxes))
      .catch(err => handleSuccessPayment(false, formatErrorMessage(err)))
  }, [dispatch, selectedItems])

  useEffect(() => {
    fetchCondolivreInfo()
  }, [])

  return (
    <Grid>
      <Loader hasBackdrop open={loading} />
      <HeaderTitle
        backButtonAction={toggleMenu}
        title={menuType === 'pay' ? 'Confirmação de pedido de antecipação' : 'Remover aprovação'}
      >
        <>
          <Grid style={{ display: 'flex', gap: '8px' }}>
            <Button color="red" variant="secondary" onClick={toggleMenu}>
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="primary"
              onClick={() => setAnticipationTermsModalOpen(true)}
              disabled={selectedItems.length === 0}
            >
              Confirmar
            </Button>
          </Grid>
        </>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.alertContainer}>
          <Alert
            className={styles.alert}
            severity={menuType === 'pay' ? 'info' : 'error'}
            title={alertMessage}
          />
          <a
            href={ANTICIPATIONS_MORE_INFO_URL}
            target="_blank"
            rel="noreferrer"
            className={styles.moreInfoLink}
          >
            Saiba mais
          </a>
        </Grid>
        <TotalAnticipationValues totalMenuValues={totalMenuValues} />
        <Grid className={styles.mainTable}>
          <Datagrid
            rows={itemsWithTaxes.length ? itemsWithTaxes : []}
            columns={TradesmanInstallmentsConfirmationColumns({ styles })}
            paginationMode="server"
            autoHeight
            onRowClick={handleRowClick}
            columnVisibilityModel={{ actions: false }}
            rowCount={itemsWithTaxes?.length}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={setSelectedItems}
            keepNonExistentRowsSelected
            selectionModel={selectedItems}
            sx={TABLE_SX}
          />
        </Grid>
      </Grid>
      <ConfirmRemoveDialog message={dialogMessage} type="info" />
      {infoDialog.isOpen && (
        <Dialog
          open={infoDialog.isOpen}
          icon={infoDialog.icon}
          type={infoDialog.type}
          subject={infoDialog.subject}
          description={infoDialog.description}
          onApprove={() => setInfoDialog(defaultInfoDialog)}
        />
      )}
      {anticipationTermsModalOpen && (
        <AnticipationTermsModal
          open={anticipationTermsModalOpen}
          onConfirm={handleConfirmModal}
          onCancel={() => setAnticipationTermsModalOpen(false)}
          anticipationTermsValues={anticipationTermsValues}
        />
      )}
    </Grid>
  )
}

export default AnticipationConfirmation
