import humps from 'humps'

import { get, post, patch, del } from './requests'

export const getClassification = key => params =>
  get(['classification'], { key, params: humps.decamelizeKeys(params) })

export const getClassificationColor = key =>
  get(['option_classification_color'], { key, transformPayload: true })

export const getClassificationType = key =>
  get(['option_classification_type'], { key, transformPayload: true })

export const getClassificationById = key => classificationId =>
  get(['classification', classificationId], { key })

export const postClassification = key => payload =>
  post(['classification'], { key, transformPayload: true }, payload)

export const patchClassification = key => (classificationId, payload) =>
  patch(['classification', classificationId], { key, transformPayload: true }, payload)

export const deleteClassification = key => classificationId =>
  del(['classification', classificationId], { key })
