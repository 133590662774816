import React, { useCallback, useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react'
import { Typography, Tooltip } from '@material-ui/core'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'

import Chip from '_components/chip'
import Table from '_/components/table'
import { REGISTER_STATUS, REGISTER_STATUS_LABEL, ROUTES } from '_utils/constants'
import { phoneNumberMask, cpfCnpjMask } from '_utils/helpers'
import {
  getAgencies,
  getAgenciesNextPage,
  setTablePage,
  setFilter,
  getAgenciesFiltered,
} from '_modules/agency/actions'
import {
  getAgenciesLoadingSelector,
  agenciesSelector,
  agenciesResultSelector,
} from '_/modules/agency/selectors'
import { getCitiesOptions } from '_modules/utils/selectors'
import { getCities, getCitiesFiltered } from '_modules/utils/actions'
import { usePrevious } from '_hooks/use-previous'

import { AGENCY_FIELDS } from '../agency/constants'

import useStyles from './styles'
import HeaderTitle from '_/components/header-title'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

const FILTER_NAME = {
  [AGENCY_FIELDS.CITY]: 'cities',
}

const COLUMNS = [
  { id: AGENCY_FIELDS.NAME, label: 'Nome', minWidth: 150 },
  { id: AGENCY_FIELDS.PERSON_RESPONSIBLE_NAME, label: 'Responsável', minWidth: 150 },
  { id: AGENCY_FIELDS.PHONE_NUMBER, label: 'Telefone', minWidth: 50 },
  { id: AGENCY_FIELDS.CPF_CNPJ, label: 'CNPJ', minWidth: 150 },
  {
    id: AGENCY_FIELDS.CONTACT_EMAIL,
    label: 'Email',
  },
  {
    id: AGENCY_FIELDS.CITY,
    label: 'Cidade',
    filterOptions: [],
    search: true,
    filterName: FILTER_NAME[AGENCY_FIELDS.CITY],
  },
  {
    id: AGENCY_FIELDS.REGISTRATION_STATUS,
    label: 'Status',
    filterOptions: [
      { label: REGISTER_STATUS_LABEL[REGISTER_STATUS.COMPLETE], value: REGISTER_STATUS.COMPLETE },
      {
        label: REGISTER_STATUS_LABEL[REGISTER_STATUS.INCOMPLETE],
        value: REGISTER_STATUS.INCOMPLETE,
      },
    ],
    search: false,
    filterName: AGENCY_FIELDS.REGISTRATION_STATUS,
  },
]

const ManageAgencies = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getAgenciesLoadingSelector)
  const wasLoading = usePrevious(isLoading)
  const { count, page, size, filter, loadedPages } = useSelector(agenciesSelector)
  const results = useSelector(agenciesResultSelector)
  const cityOptions = useSelector(getCitiesOptions)
  const refs = useRef({})
  const [ellipsisCell, setEllipsisCell] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const { checkIfUserDoesNotPermission } = useRolePermission()

  const getColumns = useMemo(
    () =>
      COLUMNS.map(item => {
        if (item.id === AGENCY_FIELDS.CITY) {
          const column = { ...item, placeholder: 'da cidade', filterOptions: [...cityOptions] }
          return column
        }
        return item
      }),
    [cityOptions]
  )

  const handleCity = useCallback(() => {
    if (!cityOptions.length) {
      dispatch(getCities())
    }
  }, [cityOptions.length, dispatch])

  useEffect(() => {
    dispatch(getAgencies({ pageSize })).then(() => {
      handleCity()
    })
  }, [dispatch])

  const handleTableRowClick = useCallback(event => {
    if (
      checkIfUserDoesNotPermission(PERMISSIONS.INTERMEDIARY_CONFIGS, [
        PERMISSIONS_ACTIONS.READ,
        PERMISSIONS_ACTIONS.EDIT,
        PERMISSIONS_ACTIONS.ADD,
      ])
    ) {
      return null
    }

    return navigate(`${ROUTES.AGENCY}/${event.currentTarget.id}`)
  }, [])

  const handleChangePage = useCallback(
    next => {
      if (!loadedPages.includes(next + 1)) {
        dispatch(getAgenciesNextPage({ page: next + 1 }))
      }
      dispatch(setTablePage({ page: next }))
    },
    [dispatch, loadedPages]
  )

  const handleRowsPerPage = useCallback(
    pageSizeEvent => {
      setPageSize(pageSizeEvent)
      dispatch(getAgencies({ pageSize: pageSizeEvent }))
      dispatch(setTablePage({ page: 0 }))
    },
    [dispatch]
  )

  const handleApplyFilter = useCallback(() => {
    dispatch(getAgenciesFiltered())
  }, [dispatch])

  const handleFilterOption = useCallback(
    event => {
      const { name, value } = event.target
      dispatch(setFilter({ name, value }))

      if (!value) {
        dispatch(getAgencies())
      }
    },
    [dispatch]
  )

  const handleSearchFilter = useCallback(
    () => event => {
      const { value } = event.target
      if (value) {
        dispatch(getCitiesFiltered(value))
        return
      }
      dispatch(getCities())
    },
    [dispatch]
  )

  const checkEmailFieldEllipsis = useCallback(() => {
    const ellipsisCellState = Object.keys(refs.current).map(item => ({
      id: item,
      isEllipsis: refs.current[item]?.offsetWidth < refs.current[item]?.scrollWidth,
    }))

    setEllipsisCell(ellipsisCellState)
  }, [refs])

  const handleRefs = useCallback(
    id => node => {
      refs.current = { ...refs.current, [id]: node }
    },
    [refs]
  )

  useLayoutEffect(() => {
    if (Object.values(refs.current).length > 0 && !isLoading && wasLoading) {
      checkEmailFieldEllipsis()
    }
    window.addEventListener('resize', checkEmailFieldEllipsis)

    return () => window.removeEventListener('resize', checkEmailFieldEllipsis)
  }, [checkEmailFieldEllipsis, isLoading, wasLoading])

  const renderTableCell = useCallback(
    (value, key) => {
      switch (key) {
        case AGENCY_FIELDS.NAME:
          return (
            <Typography className={styles.text} color="primary">
              {value[key]}
            </Typography>
          )
        case AGENCY_FIELDS.REGISTRATION_STATUS: {
          const isComplete = value[key] === REGISTER_STATUS.COMPLETE
          return (
            <Chip
              label={REGISTER_STATUS_LABEL[value[key]]}
              className={classnames(styles.chip, { [styles.incomplete]: !isComplete })}
            />
          )
        }
        case AGENCY_FIELDS.PHONE_NUMBER: {
          return <Typography className={styles.text}>{phoneNumberMask(value[key])}</Typography>
        }
        case AGENCY_FIELDS.CPF_CNPJ: {
          return <Typography className={styles.text}>{cpfCnpjMask(value[key])}</Typography>
        }
        case AGENCY_FIELDS.CONTACT_EMAIL:
          return (
            <Tooltip
              title={value[key]}
              disableHoverListener={
                !ellipsisCell.find(item => Number(item.id) === Number(value.id))?.isEllipsis
              }
            >
              <Typography className={styles.text} ref={handleRefs(value.id)}>
                {value[key]}
              </Typography>
            </Tooltip>
          )

        default:
          return <Typography className={styles.text}>{value[key]}</Typography>
      }
    },
    [handleRefs, styles.chip, styles.incomplete, styles.text, ellipsisCell]
  )

  return (
    <>
      <HeaderTitle title="Gestão de Intermediárias" />

      <Table
        rows={results}
        headers={getColumns}
        handleRowClick={handleTableRowClick}
        renderTableCell={renderTableCell}
        ariaLabel="Tabela de gestão de intermediárias"
        isLoading={isLoading}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={size}
        handleChangeRowsPerPage={handleRowsPerPage}
        total={count}
        handleApplyFilter={handleApplyFilter}
        handleFilterOption={handleFilterOption}
        filter={filter}
        handleSearch={handleSearchFilter}
        loadedPages={loadedPages}
      />
    </>
  )
}

export default ManageAgencies
