import React from 'react'
import { Dialog } from '@refera/ui-web'
import {
  CloseCircle as CloseCircleIcon,
  InfoCircle as InfoCircleIcon,
  Danger as DangerIcon,
  TickCircle as TickCircleIcon,
} from '@refera/ui-icons'
// import PropTypes from 'prop-types'

const DIALOG_TYPE_ICONS = {
  success: TickCircleIcon,
  error: CloseCircleIcon,
  info: InfoCircleIcon,
  warning: DangerIcon,
}

/**
 * @typedef {Object} GlobalDialogProps
 * @property {'success' | 'error' | 'info' | 'warning'} type - The type of the dialog.
 * @property {React.ElementType} [customIcon] - The custom icon component for the dialog.
 * @property {boolean} [isOpen=false] - Determines whether the dialog is open or not.
 * @property {string} subject - The subject of the dialog.
 * @property {string} [description] - The description of the dialog.
 * @property {string} labelApprove - The label for the approve button.
 * @property {string} [labelCancel] - The label for the cancel button.
 * @property {function} onApprove - The function to call when the approve button is clicked.
 * @property {function} [onCancel] - The function to call when the cancel button is clicked.
 */

/**
 * Renders a global dialog component.
 *
 * @component
 * @param {GlobalDialogProps} props - The component props.
 * @returns {JSX.Element} The rendered global dialog component.
 */
export function GlobalDialog({ type = 'info', customIcon = undefined, isOpen = false, ...rest }) {
  const Icon = customIcon ?? DIALOG_TYPE_ICONS[type]

  return (
    <Dialog
      open={isOpen}
      icon={Icon}
      type={type}
      subject=""
      labelApprove="Ok"
      onApprove={() => {}}
      {...rest}
    />
  )
}
