import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  loading: {
    minWidth: '80vw',
    minHeight: '80vh',
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
}))
