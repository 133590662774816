export const DATE_SUGGESTION_OPTIONS_NAMES = [
  'firstSuggestionDate',
  'secondSuggestionDate',
  'thirdSuggestionDate',
]

export const DATE_SUGGESTION_OPTIONS_TIMES_START = [
  'firstStartTime',
  'secondStartTime',
  'thirdStartTime',
]

export const DATE_SUGGESTION_OPTIONS_TIMES_END = ['firstEndTime', 'secondEndTime', 'thirdEndTime']

export const DATE_SUGGESTION_OPTIONS = [
  {
    name: DATE_SUGGESTION_OPTIONS_NAMES[0],
    label: 'Sugestão 1',
    period: 'firstPeriod',
    startTime: DATE_SUGGESTION_OPTIONS_TIMES_START[0],
    endTime: DATE_SUGGESTION_OPTIONS_TIMES_END[0],
  },
  {
    name: DATE_SUGGESTION_OPTIONS_NAMES[1],
    label: 'Sugestão 2',
    period: 'secondPeriod',
    startTime: DATE_SUGGESTION_OPTIONS_TIMES_START[1],
    endTime: DATE_SUGGESTION_OPTIONS_TIMES_END[1],
  },
  {
    name: DATE_SUGGESTION_OPTIONS_NAMES[2],
    label: 'Sugestão 3',
    period: 'thirdPeriod',
    startTime: DATE_SUGGESTION_OPTIONS_TIMES_START[2],
    endTime: DATE_SUGGESTION_OPTIONS_TIMES_END[2],
  },
]
