import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  modal: {
    maxWidth: '900px',
  },
  wrapper: {
    gap: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  modalContent: {
    width: '100%',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: 0,
  },
}))
