import React, { useCallback } from 'react'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import PropTypes from 'prop-types'

import Button from '_components/button'

import useStyles from './styles'

const ConfirmValueChangeModal = ({
  isOpen,
  handleClose,
  description,
  cancelButtonText,
  confirmButtonText,
  fieldId,
  setValue,
  value,
}) => {
  const styles = useStyles()

  const onDeniedClick = useCallback(() => {
    if (fieldId === 'agencyCompletionPercentage' || fieldId === 'referaCompletionPercentage') {
      setValue(fieldId, parseFloat(value).toFixed(2).replace('.', ',') || '')
    } else {
      setValue(fieldId, value || '')
    }
    handleClose()
  }, [fieldId, handleClose, setValue, value])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-describedby={`modal-${description}`}
      className={styles.dialog}
    >
      <DialogContent>
        <DialogContentText id={`modal-${description}`} className={styles.description}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDeniedClick}>{cancelButtonText}</Button>
        <Button onClick={handleClose} color="primary">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmValueChangeModal.propTypes = {
  handleClose: PropTypes.func,
  description: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

ConfirmValueChangeModal.defaultProps = {
  handleClose: () => {},
  description: '',
  cancelButtonText: 'Não',
  confirmButtonText: 'Sim',
  isOpen: false,
}
export default React.memo(ConfirmValueChangeModal)
