import { makeStyles } from '@material-ui/styles'

import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  title: {
    fontSize: Theme.Typography.FontSize.XSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
    color: Theme.Colors.Grayscale.Eighty,
    lineHeight: spacing(24),
    margin: 0,
  },
  paragraph: {
    fontSize: Theme.Typography.FontSize.XSmall,
    fontWeight: Theme.Typography.FontWeight.Regular,
    color: Theme.Colors.Grayscale.SixtyFour,
    lineHeight: spacing(24),
    marginBottom: spacing(8),
    textAlign: 'center',

    [breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  responsibleInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: spacing(38),
    marginBottom: spacing(28),
    marginTop: spacing(24),
  },
  required: {
    color: Theme.Colors.Red.Base,
    marginLeft: spacing(4),
  },
  icon: {
    fontSize: Theme.Typography.FontSize.XSmall,
  },
  actionResponsibleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: spacing(4),
    cursor: 'pointer',
  },
  addResponsibleText: {
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
    color: Theme.Colors.Primary.Base,
    lineHeight: spacing(20),
    margin: 0,
  },
  removeResponsibleText: {
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
    color: Theme.Colors.Red.Base,
    lineHeight: spacing(20),
    margin: 0,
  },
  fillWidth: {
    width: '100%',
  },
  resultTitle: {
    fontSize: Theme.Typography.FontSize.Large,
    fontWeight: Theme.Typography.FontWeight.Medium,
    lineHeight: spacing(32),
    textAlign: 'center',
    color: Theme.Colors.Primary.Base,
  },
  resultMessage: {
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Regular,
    lineHeight: spacing(20),
    textAlign: 'center',
    color: Theme.Colors.Grayscale.SixtyFour,
    maxWidth: spacing(349),
  },
  svg: { width: '48px', height: '48px' },
  attachmentsContainer: {
    marginTop: '-16px',
  },
  errorMessage: {
    color: `${palette.error.main} !important`,
    margin: 0,
    fontSize: spacing(10.5),
    fontFamily: 'Roboto',
    height: spacing(10),
    display: 'flex !important',
  },
  step3Container: {
    marginTop: 0,
  },
}))
