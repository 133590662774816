import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Grid } from '@material-ui/core'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Modal, Button, useConfirm } from '@refera/ui-web'
import {
  getServiceList,
  getServiceById,
  updateService,
  deleteService,
  postService,
  getProblemList,
} from '_/modules/service-orders/actions'
import Loading from '_components/loading'
import { useToast } from '_/hooks/use-toast'
import DynamicTreeView from '_/components/treeview'
import * as ModalComponent from '_components/modal/generic-modal'
import Autocomplete from '@mui/material/Autocomplete'

import useStyles from './styles'
import { ServiceForm } from './service-form'
import { TextField } from '@mui/material'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

const ServiceScreen = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { checkIfUserDoesNotPermission } = useRolePermission()

  const [deleteModal, setDeleteModal] = useState(false)

  const [isDeleting, setIsDeleting] = useState(false)
  const [service, setService] = useState()
  const [serviceSelectId, setServiceSelectId] = useState(null)
  const [serviceList, setServiceList] = useState()
  const [problemsList, setProblemsList] = useState([])
  const [problem, setProblem] = useState(null)
  const [dialogInfo, setDialogInfo] = useState()
  const { isConfirmed } = useConfirm()

  const [modalOpen, setModalOpen] = useState(false)

  const methods = useForm({
    defaultValues: {
      ...service,
      problems: [],
    },
    mode: 'onChange',
  })

  const { reset, setValue, watch, control } = methods

  const data = watch()
  const problemsWatch = watch('problems')

  const message = {
    title: 'Você tem certeza que deseja executar esta ação?',
    error:
      'Você não pode excluir esta opção porque ela tem itens filhos ou já foi utilizada. Tente desativá-la.',
    duplicationError: 'Conteúdo já existente.',
    success: 'Ação executada com sucesso.',
  }

  const openDeleteModal = () => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.SERVICES, PERMISSIONS_ACTIONS.DELETE)) {
      return
    }

    setDeleteModal(true)
  }

  const handleSelection = useCallback(
    e => {
      dispatch(getServiceById(e?.id)).then(res => {
        setService(() => (res.count ? {} : res))
        reset(res)
        setServiceSelectId(res.id)
      })
    },
    [dispatch, service, reset]
  )

  const handleCreate = () => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.SERVICES, PERMISSIONS_ACTIONS.ADD)) {
      return
    }

    setService({})

    reset({
      name: '',
      isActive: false,
      allowsNoAttachments: false,
      allowsTheDistance: false,
      additionalLabor: '0.00',
      additionalPrice: '0.00',
      allowsSelection: false,
      diagnosisDesc: null,
      externalId: null,
      idealLabor: '0.00',
      idealMaterial: '0.00',
      idealPrice: '0.00',
      maxLabor: '0.00',
      maxMaterial: '0.00',
      maxPrice: '0.00',
      problems: [],
    })

    setServiceSelectId(null)
  }

  const handleDelete = async () => {
    setIsDeleting(true)
    await Promise.resolve(dispatch(deleteService(serviceSelectId)))
      .catch(() =>
        showToast({
          message: message.error,
          type: 'error',
        })
      )
      .then(() => {
        setIsDeleting(() => false)
        showToast({
          message: message.success,
          type: 'success',
        })
        dispatch(getServiceList()).then(res => {
          setServiceList(res)
          setServiceSelectId(null)
        })
      })
    setDeleteModal(() => false)
  }

  const handleCreateService = useCallback(
    async payload => {
      if (checkIfUserDoesNotPermission(PERMISSIONS.SERVICES, PERMISSIONS_ACTIONS.ADD)) {
        return
      }

      const payloadToSend = {
        ...payload,
        problems: payload?.problems?.map(item => item.id),
      }

      await Promise.resolve(
        dispatch(
          serviceSelectId
            ? updateService(serviceSelectId, payloadToSend)
            : postService({ ...payloadToSend, parent: service?.id })
        )
      )
        .catch(error =>
          showToast({
            message: error?.error_message ?? message.error,
            type: 'error',
          })
        )
        .then(() => {
          showToast({
            message: message.success,
            type: 'success',
          })

          dispatch(getServiceList()).then(res => {
            setServiceList(res)
          })
        })
    },
    [handleCreate, showToast, service]
  )

  const handleProblemSelect = useCallback(
    problemItem => {
      const findIfSelectedProblemAlreadyInList = problemsWatch?.some(
        p => p?.id === problemItem?.id && p?.name === problemItem?.name
      )

      if (problemsList && !findIfSelectedProblemAlreadyInList) {
        const updatedProblems = [...problemsWatch, { id: problemItem?.id, name: problemItem?.name }]
        setValue('problems', updatedProblems)
      }
      setProblem(null)
      setModalOpen(false)
    },
    [problemsWatch, problemsList, setValue, reset]
  )

  const handleDeleteProblem = useCallback(
    async problemData => {
      setDialogInfo({ message: 'Você tem certeza que deseja executar esta ação?' })
      const confirmed = await isConfirmed()

      if (problemsWatch && confirmed) {
        const updatedProblems = problemsWatch?.filter(
          currentProblem => currentProblem.id !== problemData.id
        )
        setValue('problems', updatedProblems)
      }
    },
    [problemsWatch]
  )

  const getOptionProblemsLabel = useCallback(optionProblems => {
    return optionProblems?.name
  }, [])

  const getOptionProblemsSelected = useCallback((optionProblems, currentValue) => {
    return optionProblems.id === currentValue
  }, [])

  const getOptionProblemsDisabled = useCallback(
    optionProblems => {
      return data?.services && data?.services.some(item => item.id === optionProblems.id)
    },
    [data]
  )

  useEffect(() => {
    dispatch(getServiceList()).then(res => setServiceList(res))
    dispatch(
      getProblemList({
        active: true,
      })
    ).then(res => setProblemsList(res))
  }, [])

  const categoryForms = useMemo(() => {
    if (service) {
      return (
        <ServiceForm
          service={service}
          serviceSelectId={serviceSelectId}
          handleCreate={handleCreateService}
          setModalOpen={setModalOpen}
          handleDeleteProblem={handleDeleteProblem}
        />
      )
    }

    return (
      <Grid className={styles.selectWrapper}>
        <Typography className={styles.selectTitle}>Selecione um serviço.</Typography>
      </Grid>
    )
  }, [service, handleCreateService, handleDeleteProblem, serviceSelectId])

  if (!serviceList) {
    return <Loading />
  }

  return (
    <FormProvider {...methods}>
      <Grid className={styles.container}>
        <Grid className={styles.header}>
          <Typography variant="h4" component="h1" className={styles.title}>
            Serviços
          </Typography>
          <Grid className={styles.buttonWrapper}>
            <Button onClick={() => handleCreate()}>Novo</Button>
            <Button color="red" disabled={!serviceSelectId || isDeleting} onClick={openDeleteModal}>
              Excluir
            </Button>
          </Grid>
        </Grid>

        <Grid className={styles.main}>
          <Grid className={styles.treeWrapper}>
            <DynamicTreeView data={serviceList} handleClick={handleSelection} />
          </Grid>
          <Grid className={styles.displayWrapper}>{categoryForms}</Grid>
        </Grid>

        <Modal
          className={styles.modal}
          title="Excluir serviço"
          onBackdropClick={() => setDeleteModal(false)}
          onClose={() => setDeleteModal(false)}
          open={deleteModal}
          actionsButtons={
            <Grid className={styles.modalButtons}>
              <Button color="red" variant="secondary" onClick={() => setDeleteModal(false)}>
                Não
              </Button>
              <Button color="primary" variant="primary" onClick={handleDelete}>
                Sim
              </Button>
            </Grid>
          }
        >
          <Typography>{message.title}</Typography>
        </Modal>
      </Grid>

      <ModalComponent.Root
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="sm"
        keepMounted
      >
        <ModalComponent.TitleModal title="Selecione um problema" />
        <ModalComponent.Content className={styles.modalContent}>
          <Grid className={{ width: '600px' }}>
            <Controller
              name="problems"
              shouldUnregister={false}
              control={control}
              defaultValue=""
              render={() => {
                return (
                  <>
                    <Typography className={styles.serviceLabel}>Problemas</Typography>
                    <Autocomplete
                      disablePortal
                      className={styles.autocomplete}
                      multiple={false}
                      options={problemsList}
                      defaultValue=""
                      getOptionSelected={getOptionProblemsSelected}
                      getOptionLabel={getOptionProblemsLabel}
                      getOptionDisabled={getOptionProblemsDisabled}
                      noOptionsText="Nenhum problema encontrado."
                      value={problem}
                      onChange={(event, newValue) => {
                        setProblem(newValue)
                      }}
                      ListboxProps={{
                        style: {
                          fontSize: '14px',
                        },
                      }}
                      renderInput={params => <TextField {...params} />}
                    />
                  </>
                )
              }}
            />
          </Grid>
        </ModalComponent.Content>
        <ModalComponent.Actions>
          <ModalComponent.ButtonRed onClick={() => setModalOpen(false)}>
            Cancelar
          </ModalComponent.ButtonRed>
          <ModalComponent.ButtonFullBlue
            disabled={!problem}
            onClick={() => handleProblemSelect(problem)}
          >
            Salvar
          </ModalComponent.ButtonFullBlue>
        </ModalComponent.Actions>
      </ModalComponent.Root>

      {dialogInfo?.message && <ConfirmRemoveDialog message={dialogInfo.message} />}
    </FormProvider>
  )
}

export default ServiceScreen
