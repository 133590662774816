import React, { useState } from 'react'
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  Typography,
  Tooltip,
} from '@material-ui/core'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { updateMessage } from '_/modules/messages/actions'
import Button from '_components/button'
import TrashIcon from '_assets/icons/ic-trash.svg'
import IconButton from '_/components/svg/icon-button'

import useStyles from './styles'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const HelpView = ({ closeModal, message, handleBackToHelpList, subjectOption }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [hasAnswer, sethasAnswer] = useState(true)

  const handleDeleteAnswer = () => {
    const payload = {
      answer: '',
      resolved: false,
    }

    dispatch(updateMessage(message.id, payload))
    sethasAnswer(false)
  }

  const formatDateTime = data => {
    const dateTime = new Date(data)
    const date = dateTime.toLocaleDateString('pt-BR')
    const hours = dateTime.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    })
    return `${date}, às ${hours}`
  }

  const translateSubject = () => {
    const statusIndex = subjectOption?.status?.indexOf(message?.subject)
    return subjectOption?.option[statusIndex]
  }

  return (
    <Dialog
      open
      maxWidth="xl"
      disableEscapeKeyDown
      onClose={closeModal}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      className={styles.dialog}
    >
      <DialogTitle className={styles.titleBlue} id="draggable-dialog-title">
        Item de Ajuda
      </DialogTitle>
      <DialogContent className={styles.container}>
        {message?.subject && (
          <Grid className={styles.messageWrapper}>
            <Typography className={styles.messageTitle}>{translateSubject()}</Typography>
            <Typography className={styles.messageBody}>{message?.message}</Typography>
            <Typography className={styles.messageFooter}>{`${
              message?.senderName
            } - ${formatDateTime(message.createdAt)}`}</Typography>
          </Grid>
        )}
        {hasAnswer && (
          <Grid className={styles.answerContainer}>
            <Grid className={styles.answerWrapper}>
              <Typography className={styles.messageTitle}>Resposta</Typography>
              <Typography className={styles.messageBody}>{message?.answer}</Typography>
              <Typography className={styles.messageFooter}>{`${
                message?.recipientName
              } - ${formatDateTime(message?.updatedAt)}`}</Typography>
            </Grid>
            <Tooltip title="Excluir resposta" placement="left" arrow>
              <Grid className={styles.trashContainer}>
                <IconButton
                  className={styles.trashIcon}
                  icon={TrashIcon}
                  onClick={() => handleDeleteAnswer()}
                />
              </Grid>
            </Tooltip>
          </Grid>
        )}
      </DialogContent>

      <DialogActions className={styles.dialogAction}>
        <Button
          className={styles.button}
          onClick={handleBackToHelpList}
          color="primary"
          variant="outlined"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

HelpView.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default HelpView
