import React from 'react'
import { AppBar } from '@material-ui/core'
import useStyles from './styles'

export function HeaderAgency({ children }) {
  const styles = useStyles()

  return (
    <AppBar className={styles.header} key="app-bar-key">
      {children}
    </AppBar>
  )
}
