import { Map } from 'immutable'

import { LOGIN, GET_USER, AUTHENTICATE_TOKEN } from '_modules/authentication/actions'

export const userSelector = state => state.user

export const userPermissionsSelector = state => state.user?.permissions

export const userWidgetsSelector = state => state.user.widgetsOptions

export const userWidgetsSelectorByPosition = position => state =>
  state.user?.widgetsOptions?.[position]

export const userRoleSelector = state => state.user.getRoles

export const getUserLoadingSelector = state => !!state.loading.get(GET_USER.ACTION) ?? true

export const loginLoadingSelector = state => !!state.loading.get(LOGIN.ACTION)

export const loginErrorSelector = state => state.error.get(LOGIN.ACTION, Map())

export const getUserError = state => state.error.get(GET_USER.ACTION, Map())

export const authenticateTokenLoadingSelector = state =>
  state.loading.get(AUTHENTICATE_TOKEN.ACTION)

export const authenticateTokenErrorSelector = state =>
  state.error.get(AUTHENTICATE_TOKEN.ACTION, Map())

export const getVerificationTokenSelector = state => state.user.verificationToken
