import { makeStyles } from '@material-ui/styles'

export const defaultStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    position: 'relative',
    height: spacing(280),
    padding: spacing(0, 16, 24),

    overflowY: 'auto',
    overflowX: 'hidden',

    '&.MuiTableContainer-root': {
      overflowX: 'hidden',
    },

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tableRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  cellText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'black',
    textAlign: 'left',
  },
  closeArrrowIcon: {
    height: spacing(16),
    width: spacing(14),
    color: palette.primary.main,
  },
}))
