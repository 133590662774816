import { schema } from 'normalizr'

import Model from './model'

class Classification extends Model({
  active: undefined,
  agency: undefined,
  classificationName: undefined,
  classificationType: undefined,
  color: undefined,
  description: undefined,
  id: undefined,
}) {}

const classificationNewSchema = new schema.Entity(
  'classification',
  {},
  {
    processStrategy: entity => new Classification(entity),
  }
)

const classificationSchema = [classificationNewSchema]

export { classificationSchema, Classification as default }
