import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100% - 75px)',
    overflowY: 'auto',
  },
  mainTable: {
    marginTop: '16px',
    width: 'auto',
    height: '650px',
  },
  inputLabel: {
    '& label': {
      color: `${Theme.Colors.Grayscale.OneThousand} !important`,
      fontFamily: `${Theme.Typography.FontFamily.DMSans} !important`,
      fontWeight: `${Theme.Typography.FontWeight.Medium} !important`,
      fontSize: `${Theme.Typography.FontSize.Tiny}px !important`,
    },
  },
  selectLabel: {
    '& label': {
      fontSize: '16px !important',
    },
  },
  actionButtons: {
    display: 'flex',
    gap: spacing(8),
  },

  // Floating Menus
  floatingContainer: {
    display: 'flex',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%) !important',
    bottom: '2.6vh',
  },
  floatingWrapper: {
    display: 'flex',
    width: 'auto',
    backgroundColor: '#fff',
    minWidth: spacing(640),
    borderRadius: spacing(4),
    border: `1px solid ${Theme.Colors.Primary.Base}`,
    boxShadow: ' 0px 1px 4px rgba(0, 0, 0, 0.16)',
  },
  floatingContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: spacing(16, 8),
    marginLeft: spacing(12),
    gap: spacing(8),
    flex: 1,
  },
  floatingLabels: {
    display: 'flex',
    gap: spacing(8),
    marginLeft: spacing(4),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  draggable: {
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: spacing(32),
    backgroundColor: Theme.Colors.Primary.Base,

    '&:active': {
      cursor: 'grabbing',
    },
  },
  dotsIcon: {
    fontSize: spacing(20),
  },
  button: {
    padding: spacing(4, '!important'),
    height: 'fit-content',
  },
  redButton: {
    padding: spacing(4, '!important'),
    height: 'fit-content',
    color: `${Theme.Colors.Red.Base} !important`,

    '&:hover': {
      color: `${Theme.Colors.Red.Dark} !important`,
    },
  },
}))
