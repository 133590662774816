export const FROM_TO_OPTIONS = [
  { label: 'Intermediária para Refera', value: 'intermediary_to_refera' },
  { label: 'Prestador para Refera', value: 'tradesman_to_refera' },
  { label: 'Refera para Intermediária', value: 'refera_to_intermediary' },
]

export const OPTIONS_TO_INTERMEDIARY_AVAILABLE = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
]

export const JUSTIFIED_STATUS_OPTIONS = [
  { label: 'Nulo', value: '' },
  { label: 'Aprovado', value: 'approved' },
  { label: 'Reprovado', value: 'reproved' },
]

export const formatDateTime = data => {
  const dateTime = new Date(data)
  const date = dateTime.toLocaleDateString('pt-BR')
  const hours = dateTime.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
  })
  return `${date}, às ${hours}`
}
