import React from 'react'

import useStyles from './styles'
import classNames from 'classnames'
import SwitchComponent from '_/components/switch'

function Root({ children, className = undefined, ...rest }) {
  const styles = useStyles()
  return (
    <div className={classNames([styles.switchStyles, 'ReferaSwitch__Root', className])} {...rest}>
      {children}
    </div>
  )
}

function Label({ htmlFor, content, ...rest }) {
  return (
    <label className="ReferaSwitch__Label" htmlFor={htmlFor} {...rest}>
      {content}
    </label>
  )
}

function Control({ checked, onChange, ...rest }) {
  return (
    <SwitchComponent
      checked={checked}
      onChange={onChange}
      className="ReferaSwitch__Control"
      {...rest}
    />
  )
}

export default {
  Root,
  Label,
  Control,
}
