import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Dialog, Grid, Typography, DialogContent, DialogActions } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import Button from '_components/button'
import IconButton from '_components/svg/icon-button'

import useStyles from './styles'

const Modal = ({
  handleModal,
  title,
  children,
  handleSubmit,
  disabledSubmit,
  className,
  contentClassName,
}) => {
  const styles = useStyles()

  return (
    <Dialog open onClose={handleModal} className={classnames(styles.modal, className)}>
      <Grid className={styles.header}>
        {title && (
          <Typography component="span" className={styles.title}>
            {title}
          </Typography>
        )}
        <Grid className={styles.buttons}>
          <IconButton
            aria-label="Close modal"
            onClick={handleModal}
            buttonClassName={styles.iconButton}
          >
            <CloseIcon className={styles.icon} />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent className={classnames(styles.content, contentClassName)}>
        {children}
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          disabled={disabledSubmit}
          className={styles.button}
          variant="contained"
          onClick={handleSubmit}
          color="primary"
        >
          Excluir
        </Button>
        <Button className={styles.button} onClick={handleModal} color="primary" variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  disabledSubmit: PropTypes.bool,
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
}

Modal.defaultProps = {
  children: PropTypes.node,
  handleSubmit: () => {},
  className: '',
  contentClassName: '',
  disabledSubmit: false,
  title: '',
}
export default React.memo(Modal)
