import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    boxShadow: 'none',
    left: '-15px',
    padding: 0,

    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
  },
  icon: {
    fontSize: '35px',
    color: '#1671ED',
  },
  accordionTitle: {
    gap: '5px',
    height: '40px',
    minHeight: '40px',
    marginBottom: '10px',
    alignItems: 'center',
    flexDirection: 'row-reverse',

    '&.Mui-expanded': {
      minHeight: '30px',
      maxHeight: '30px',
    },
  },
  cityList: {
    width: '100%',
  },
  title: {
    color: '#1671ED',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    marginBottom: spacing(6),
  },
  subTitle: {
    fontSize: '12px',
    paddingLeft: '24px',
  },
  button: {
    height: spacing(40),
    marginTop: '5px',
    marginLeft: '5px',
    fontWeight: 'normal',
    fontSize: '16px',
    border: 0,

    '&:hover': {
      border: 0,
      backgroundColor: 'none',
    },
  },
  error: {
    color: palette.custom.red,
    fontSize: spacing(10),
  },
}))
