import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  mainTable: {
    marginTop: '16px',
    width: 'auto',
    height: '650px',
  },
  emptyScreenWrapper: {
    height: '65vh',
  },
  grayColumn: {
    backgroundColor: Theme.Colors.Grayscale.Four,
  },
  idColumn: {
    backgroundColor: Theme.Colors.Grayscale.Four,
    color: Theme.Colors.Primary.Base,
  },
  buttonsContainer: {
    display: 'flex',
    gap: '16px',
  },
  alertContainer: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  alert: {
    paddingRight: '100px !important',
  },
  moreInfoLink: {
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    marginRight: '16px',
    color: Theme.Colors.Primary.Base,
    position: 'absolute',
    fontSize: '16px',
    right: '0px',
    top: '16px',
  },
  columnFlexEnd: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  // Anticipation Terms
  modal: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  modalButtons: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  checkBox: {
    display: 'flex',
    gap: '16px',
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
  },
  anticipationTermsLink: {
    color: Theme.Colors.Primary.Base,
    textDecoration: 'underline',
  },
}))
