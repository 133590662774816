import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette }) => ({
  dialog: {
    position: 'absolute',
    height: 'auto',
  },
  dialogTitle: {
    textAlign: 'justify',
    color: '#4169e1',
  },
  dialogContent: {
    display: 'flex',
    width: 400,
    justifyContent: 'center',
    marginTop: '16px',
    textAlign: 'justify',
    fontSize: '16px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    overflow: 'hidden',
  },
  text: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '500',
    color: palette.gray[100],
  },
  selectPlaceholder: {
    fontSize: '16px',
    marginTop: '17px',
  },
  dialogAction: {
    justifyContent: 'right',
    margin: '20px',
  },
  button: {
    width: '100px',
    borderRadius: '24px',
    height: '40px',
    textTransform: 'none',
    marginBottom: '15px',
  },
  modalColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    width: '200px',
    lineHeight: '20px',
    rowGap: 24,
  },
  labels: {
    fontWeight: '500',
    fontSize: '1.2rem',
    color: palette.gray[100],
  },
  required: {
    color: '#C90000',
  },
  selectGrid: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    lineHeight: '20px',
    rowGap: 8,
  },
}))
