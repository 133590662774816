import humps from 'humps'

import { get, post, patch, del } from './requests'

export const getVipClients = key => params =>
  get(['vip-clients'], { key, params: humps.decamelizeKeys(params) })

export const getVipClientById = key => vipClientById => get(['vip-clients', vipClientById], { key })

export const createVipClient = key => payload =>
  post(['vip-clients'], { key, transformPayload: true }, payload)

export const updateVipClient = key => (vipClientById, payload) =>
  patch(['vip-clients', vipClientById], { key, transformPayload: true }, payload)

export const deleteVipClient = key => vipClientById => del(['vip-clients', vipClientById], { key })
