/* eslint-disable prettier/prettier */
import React, { useEffect, useCallback, useState } from 'react'
import classnames from 'classnames'
import { Grid, Link } from '@material-ui/core'
import { useParams, useLocation } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import Svg from '_components/svg'
import Header, { HEADER_THEME } from '_components/header'
import Loading from '_components/loading'
import Button from '_components/button'
import Snackbar from '_components/snackbar'
import { formatAmount } from '_utils/helpers'
import FileBudgetIcon from '_assets/svgs/budget-file.svg'
import { getBudgetLoadingSelector, getBudgetByIdSelector } from '_modules/budget/selectors'
import { authenticateTokenLoadingSelector } from '_modules/authentication/selectors'
import { authenticateToken } from '_modules/authentication/actions'
import {
  getBudget,
  approveCustomerBudget,
  refuseCustomerBudget,
  EXTERNAL_APPROVE_CUSTOMER_BUDGET,
  EXTERNAL_REFUSE_CUSTOMER_BUDGET,
} from '_modules/budget/actions'
import { usePrevious } from '_hooks/use-previous'
import useToggle from '_hooks/use-toggle'

import useStyles from '../styles'

import { ActionModal, InfoModal } from './modals'

const CounterProposalProvider = () => {
  const dispatch = useDispatch()
  const { budgetId, serviceOrderId } = useParams()
  const styles = useStyles()
  const { search } = useLocation()
  const token = search.split('verification_token=')[1]

  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const isBudgetLoading = useSelector(getBudgetLoadingSelector)
  const isAuthenticatingToken = useSelector(authenticateTokenLoadingSelector)
  const wasAuthenticatingToken = usePrevious(isAuthenticatingToken)
  const [showActionModalForApprove, onToggleShowActionModalForApprove] = useToggle()
  const [showActionModalForRefuse, onToggleShowActionModalForRefuse] = useToggle()
  const [showInfoModal, onToggleShowInfoModal] = useToggle()
  const [infoModalText, setInfoModalText] = useState('')

  useEffect(() => {
    dispatch(authenticateToken({ verificationToken: token }))
  }, [dispatch, token])

  useEffect(() => {
    if (wasAuthenticatingToken) {
      dispatch(getBudget(serviceOrderId, budgetId))
    }
  }, [budgetId, dispatch, serviceOrderId, wasAuthenticatingToken])

  const onClickApproveBudget = useCallback(() => {
    if (budget?.counterProposalAccepted) {
      setInfoModalText('Esta proposta já foi aprovada.')
      onToggleShowInfoModal()
      return
    }
    if (budget?.refused) {
      setInfoModalText(
        `Esta proposta já está recusada. Favor contatar a Refera para informar esta sua decisão, indicando o código do orçamento ID ${budgetId}.`
      )
      onToggleShowInfoModal()
      return
    }
    onToggleShowActionModalForApprove()
  }, [
    budget?.counterProposalAccepted,
    budget?.refused,
    budgetId,
    onToggleShowActionModalForApprove,
    onToggleShowInfoModal,
  ])

  const onClickRefusebudget = useCallback(() => {
    if (budget?.refused) {
      setInfoModalText('Esta proposta já está recusada.')
      onToggleShowInfoModal()
      return
    }
    if (budget?.counterProposalAccepted) {
      setInfoModalText(
        `Esta proposta já está aprovada. Favor contatar a Refera para informar esta sua decisão, indicando o código do orçamento ID ${budgetId}.`
      )
      onToggleShowInfoModal()
      return
    }
    onToggleShowActionModalForRefuse()
  }, [
    budget?.counterProposalAccepted,
    budget?.refused,
    budgetId,
    onToggleShowActionModalForRefuse,
    onToggleShowInfoModal,
  ])

  const onConfirmApprove = useCallback(() => {
    dispatch(approveCustomerBudget(serviceOrderId, budgetId)).then(() => {
      onToggleShowActionModalForApprove()
      dispatch(getBudget(serviceOrderId, budgetId))
    })
  }, [dispatch, serviceOrderId, budgetId])

  const onConfirmRefuse = useCallback(() => {
    dispatch(refuseCustomerBudget(serviceOrderId, budgetId)).then(() => {
      onToggleShowActionModalForRefuse()
      dispatch(getBudget(serviceOrderId, budgetId))
    })
  }, [dispatch, serviceOrderId, budgetId])

  if (isBudgetLoading) {
    return <Loading />
  }

  return (
    <>
      <Header theme={HEADER_THEME.NO_BUTTONS} />
      <Grid className={classnames(styles.mainContainer)}>
        <p className={`${styles.topIconContainer} ${styles.sharedBlockTextCentered}`}>
          <span className={classnames(styles.topIconCircle)}>
            <Svg className={styles.topIcon} icon={FileBudgetIcon} />{' '}
          </span>
          <p>
            ID {budgetId} - Que massa! Você recebeu uma proposta de desconto, sugerida pelo cliente.
          </p>
        </p>
        <Grid className={styles.contentMainContainer}>
          {budget?.budgetItems ? (
            <Grid className={`${styles.budgetListContainer} noBottomSpace`}>
              <p className={styles.budgetDetailsTitle}>Proposta detalhada</p>
              <ul className={`${styles.budgetListBlock} details`}>
                <p className={styles.budgetListBlockTitle}>Descrição</p>
                {budget?.budgetItems.toJS().map(item => (
                  <li className={styles.budgetBlockListItem}>
                    <span className={styles.budgetBlockListText}>{item?.description}</span>
                  </li>
                ))}
              </ul>
              <ul className={`${styles.budgetListBlock} priceWithLeftSpace`}>
                <p className={styles.budgetListBlockTitle}>R$</p>
                {budget?.budgetItems.toJS().map(item => (
                  <li className={styles.budgetBlockListItem}>
                    <span className={styles.budgetBlockListText}>{formatAmount(item?.price)}</span>
                  </li>
                ))}
              </ul>
            </Grid>
          ) : null}
          <Grid className={classnames(styles.priceDetailsContainer)}>
            <p className={classnames(styles.priceDetailsTextBlock)}>
              <span
                className={classnames(
                  styles.sharedNoPadding,
                  styles.sharedBudgetPageMainTitle,
                  styles.sharedBlockTextLeft
                )}
              >
                {' '}
                Total
              </span>
              <span className={`${styles.totalText} blackFont`}>
                R${formatAmount(budget?.oldTradesmanPrice)}
              </span>
            </p>
            <p className={classnames(styles.priceDetailsTextBlock)}>
              <span
                className={classnames(
                  styles.sharedNoPadding,
                  styles.sharedBudgetPageMainTitle,
                  styles.sharedBlockTextLeft
                )}
              >
                Desconto
              </span>
              <span className={`${styles.totalText} redFont`}>
                -R${formatAmount(budget?.discount)}
              </span>{' '}
            </p>
            <p className={classnames(styles.priceDetailsTextBlock)}>
              <span
                className={classnames(
                  styles.sharedNoPadding,
                  styles.sharedBudgetPageMainTitle,
                  styles.sharedBlockTextLeft
                )}
              >
                Total estimado
              </span>
              <span className={styles.totalText}>
                R${formatAmount(budget?.estimatedTradesmanTotal)}
              </span>{' '}
            </p>
          </Grid>
          <Grid className={classnames(styles.buttonsGroupContainer)}>
            <Button
              className={classnames(styles.actionButtons)}
              color="primary"
              variant="contained"
              onClick={onClickApproveBudget}
            >
              Aprovar proposta
            </Button>
            <Button
              className={classnames(styles.actionButtons)}
              color="red"
              variant="outlined"
              onClick={onClickRefusebudget}
            >
              Recusar proposta
            </Button>
            <Link
              className={`${styles.actionButtons} ${styles.linkButton} ${
                !budget?.budgetParent ? styles.disabledLinkButton : ''
              }`}
              key="counter-proposal-original-button"
              to={`/orcamento-prestador/${serviceOrderId}/${budgetId}/?${token}`}
              component={Button}
              disabled={!budget?.budgetParent}
            >
              Ver orçamento original
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <p
        className={classnames(styles.fixedFooter, styles.subFooter, styles.sharedBlockTextCentered)}
      >
        Todos os direitos reservados - Refera Tecnologia S.A.
      </p>{' '}
      {showActionModalForApprove && (
        <ActionModal
          handleModal={onToggleShowActionModalForApprove}
          handleSubmit={onConfirmApprove}
        >
          Você tem certeza que deseja aprovar este orçamento?
        </ActionModal>
      )}
      {showActionModalForRefuse && (
        <ActionModal handleModal={onToggleShowActionModalForRefuse} handleSubmit={onConfirmRefuse}>
          Você tem certeza que deseja reprovar este orçamento?
        </ActionModal>
      )}
      {showInfoModal && <InfoModal handleModal={onToggleShowInfoModal}>{infoModalText}</InfoModal>}
      <Snackbar
        action={[EXTERNAL_APPROVE_CUSTOMER_BUDGET.ACTION, EXTERNAL_REFUSE_CUSTOMER_BUDGET.ACTION]}
      />
    </>
  )
}

export default CounterProposalProvider
