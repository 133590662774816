import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    fontSize: '3rem',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    boxShadow: 'none',
    padding: 0,
    paddingTop: spacing(10),
    margin: 0,

    '& .MuiTypography-root': {
      fontWeight: '500',
    },
  },
  title: {
    color: palette.gray[32],
    marginBottom: spacing(6),
  },
  button: {
    height: spacing(40),
    fontWeight: 'normal',
    fontSize: '16px',

    '&:hover': {
      border: 0,
      backgroundColor: 'none',
    },
  },
  subTitle: {
    fontSize: '12px',
    paddingLeft: '24px',
  },
}))
