import React from 'react'

import Theme from '@refera/ui-core'

import moment from 'moment'
import { statusField } from '../../manage-installments/utils/constants'

const handleAnticipationsStatus = row => {
  if (row.status === 'approved' && row.activeBatchId) return statusField('inPayment') // different from paying to use different color
  return statusField(row.status)
}

export const TableColumns = ({ styles }) => {
  return [
    {
      field: 'id',
      headerName: 'ID de antecipação',
      width: 140,
      editable: false,
      headerClassName: styles.grayColumn,
      cellClassName: styles.idColumn,
      renderCell: params => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            backgroundColor: Theme.Colors.Primary.Lightest,
            padding: '4px 8px',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Data da Solicitação',
      width: 150,
      editable: false,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
    },
    {
      field: 'company',
      headerName: 'Prestador',
      width: 180,
      editable: false,
    },
    {
      field: 'providerValueTotal',
      headerName: 'Valor',
      width: 150,
      editable: false,
      valueFormatter: ({ value }) =>
        value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
    },
    {
      field: 'amountPaidTotal',
      headerName: 'Valor líquido',
      width: 150,
      editable: false,
      valueFormatter: ({ value }) =>
        value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: false,
      renderCell: params => handleAnticipationsStatus(params.row),
    },
    {
      field: 'paymentDate',
      headerName: 'Data do pagamento',
      width: 150,
      editable: false,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
    },
    // {
    //   field: 'datetimeFinished',
    //   headerName: 'Finalizado em',
    //   width: 150,
    //   editable: false,
    //   valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
    // },
    // {
    //   field: 'datetimeAproved',
    //   headerName: 'Aprovado em',
    //   width: 150,
    //   editable: false,
    //   valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
    // },
    // {
    //   field: 'batchId',
    //   headerName: 'Lote',
    //   width: 150,
    //   editable: false,
    //   renderCell: params =>
    //     params.value ? (
    //       <Tooltip title="Ir para página do lote">
    //         <div
    //           style={{
    //             display: 'flex',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //           }}
    //           // key={Math.random(0, params.id)}
    //         >
    //           <span
    //             style={{
    //               color: Theme.Colors.Primary.Base,
    //               textDecoration: 'underline',
    //               cursor: 'pointer',
    //             }}
    //           >
    //             {addLeadingZeros(params.value)}
    //           </span>
    //         </div>
    //       </Tooltip>
    //     ) : (
    //       '-'
    //     ),
    // },
    // {
    //   field: 'actions',
    //   headerName: 'Ações',
    //   minWidth: 80,
    //   flex: 1,
    //   editable: false,
    //   headerClassName: styles.grayColumn,
    //   cellClassName: styles.grayColumn,
    //   renderCell: params => (
    //     <div
    //       style={{
    //         display: 'flex',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //       }}
    //       // key={Math.random(0, params.id)}
    //     >
    //       <Tooltip title="Dar baixa no pagamento">
    //         <button
    //           type="button"
    //           className={styles.actionButtonWrapper}
    //           onClick={
    //             params.row.datetimePayed === null ? () => handleOpenModal(params.row.id) : null
    //           }
    //         >
    //           <DollarCircleIcon
    //             color={
    //               params.row.datetimePayed === null
    //                 ? Theme.Colors.Primary.Base
    //                 : Theme.Colors.Grayscale.SixtyFour
    //             }
    //           />
    //         </button>
    //       </Tooltip>
    //       <Tooltip title="Ir para o chamado">
    //         <button
    //           type="button"
    //           className={styles.actionButtonWrapper}
    //           onClick={() => navigate(`${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}`)}
    //         >
    //           <ArrowIcon color={Theme.Colors.Primary.Base} />
    //         </button>
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ]
}
