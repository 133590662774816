import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ palette, spacing }) => ({
  idColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(4),
    borderRadius: spacing(16),
    backgroundColor: Theme.Colors.Primary.Lightest,
    color: Theme.Colors.Primary.Base,
  },
  text: {
    fontWeight: 'normal',
    fontSize: spacing(14),
    lineHeight: spacing(20),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: spacing(14),
    lineHeight: spacing(20),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  stepStatusContainer: {
    overflow: 'hidden',
  },
  icon: {
    fill: palette.custom.red,
  },
  singleRowChip: {
    flexWrap: 'unset',
    flexDirection: 'row',
    gap: spacing(13),
  },
}))
