import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  content: {
    padding: spacing(32, 40),
  },
  header: {
    background: palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(0, 32),
    height: spacing(62),
    width: '100%',
  },
  iconButton: { width: spacing(20), height: spacing(20) },
  buttons: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    width: spacing(20),
    height: spacing(20),
    '& .MuiSvgIcon-root': {
      width: spacing(20),
      height: spacing(20),
    },
    fill: 'white',
  },
  title: {
    fontSize: spacing(16),
    lineHeight: spacing(24),
    letterSpacing: spacing(-0.1),
    color: 'white',
  },

  actions: {
    '&.MuiDialogActions-root': {
      padding: spacing(0, 40, 40, 40),
      justifyContent: 'flex-start',
    },
  },
  button: {
    width: spacing(150),
  },
  editButton: {
    color: 'white',
    borderColor: 'white',
    marginRight: spacing(32),
  },
}))
