import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  selectHeightCompensation: {
    marginBottom: spacing(-2),
  },
  select: {
    width: '100%',
    margin: 0,
    height: '100% !important',
    marginTop: spacing(-15),

    '& .MuiFormLabel-root': {
      fontSize: `${spacing(16)} !important`,
      color: palette.gray[64],
      marginBottom: spacing(2),
    },

    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
  errorMessage: {
    color: `${palette.error.main} !important`,
    margin: 0,
    fontSize: spacing(10.5),
    fontFamily: 'Roboto',
    height: spacing(10),
    display: 'flex !important',
    marginTop: spacing(10),
  },
}))
