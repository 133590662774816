import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'

import Accordion from '_/components/accordion'
import { InputField, InputFieldMask } from '../../textfield'
import { BANK_OPERATION, PIX_KEY } from '../../constants'

import useStyles from './styles'

const CompanyBankData = ({ register, isEditing, control, bank, provider }) => {
  const styles = useStyles()

  const { watch } = useFormContext()
  const data = watch()

  const maskType = useMemo(() => {
    if (data?.bank_account_cpf_cnpj?.replace(/[^A-Z0-9]/gi, '').length < 12) {
      return '999.999.999-999'
    }

    return '99.999.999/9999-99'
  }, [data?.bank_account_cpf_cnpj])

  const menuProps = useMemo(
    () => ({
      classes: { paper: styles.subjectField },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  return (
    <Accordion title="Dados bancários" className={styles.accordion}>
      <InputField
        label="Nome completo do titular"
        name="bank_account_owner_name"
        inputRef={register}
        disabled={!isEditing}
        placeholder="Digite o nome completo"
      />
      <FormControl>
        <InputLabel variant="standard" id="operation" shrink>
          Operação
        </InputLabel>
        <Controller
          control={control}
          name="bank_account_type"
          defaultValue={provider?.bankAccountType || ''}
          as={
            <Select MenuProps={menuProps} labelId="operation" disabled={!isEditing}>
              {BANK_OPERATION.map(object => {
                return (
                  <MenuItem key={object.label} value={object.value}>
                    {object.label}
                  </MenuItem>
                )
              })}
            </Select>
          }
        />
      </FormControl>
      <FormControl>
        <InputLabel variant="standard" id="bank" shrink>
          Banco
        </InputLabel>
        <Controller
          control={control}
          name="bank"
          defaultValue={provider?.bank?.id || ''}
          as={
            <Select MenuProps={menuProps} labelId="bank" disabled={!isEditing}>
              {bank?.map(object => {
                return (
                  <MenuItem key={object.name} value={object.id}>
                    {object.bankNumber} - {object.name}
                  </MenuItem>
                )
              })}
            </Select>
          }
        />
      </FormControl>
      <InputFieldMask
        label="Número da agência"
        name="bank_agency_number"
        control={control}
        mask="999999"
        placeholder="Digite o número da agência"
        isEditing={isEditing}
      />
      <InputFieldMask
        label="Número da conta"
        name="bank_account_number"
        control={control}
        mask="9999999999999999999"
        placeholder="Digite o número da conta"
        isEditing={isEditing}
      />
      <InputFieldMask
        label="DV da conta"
        name="bank_account_digit"
        control={control}
        mask="9999"
        placeholder="Digite o dígito verificador"
        isEditing={isEditing}
      />
      <InputFieldMask
        mask={maskType}
        name="bank_account_cpf_cnpj"
        maskChar=""
        fullWidth
        label="CPF/CNPJ"
        isEditing={isEditing}
      />
      <FormControl>
        <InputLabel variant="standard" id="pixKey" shrink>
          Chave Pix
        </InputLabel>
        <Controller
          control={control}
          name="pix_key_choice"
          defaultValue={provider?.pixKeyChoice || ''}
          as={
            <Select MenuProps={menuProps} labelId="pixKey" disabled={!isEditing}>
              {PIX_KEY.map(object => {
                return (
                  <MenuItem key={object.label} value={object.value}>
                    {object.label}
                  </MenuItem>
                )
              })}
            </Select>
          }
        />
      </FormControl>
      <InputField
        label="Chave"
        name="pix_key"
        inputRef={register}
        placeholder="Digite a chave pix"
        disabled={!isEditing}
      />
    </Accordion>
  )
}

export default CompanyBankData
