import React from 'react'
import { TextField, Typography, List, ListItem } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'
import useStyles from '../styles'

export const InputFieldMask = ({
  control,
  name,
  mask,
  label,
  required = false,
  isEditing = false,
  defaultValue,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      as={
        isEditing ? (
          <InputMask
            mask={mask}
            maskChar=""
            required={required}
            fullWidth
            label={<Typography variant="subtitle1">{label}</Typography>}
          >
            {inputProps => {
              return (
                <TextField
                  {...inputProps}
                  InputLabelProps={{ shrink: true, required: false }}
                  inputProps={{ style: { height: 25, fontSize: 18 } }}
                />
              )
            }}
          </InputMask>
        ) : (
          <TextField
            label={<Typography variant="subtitle1">{label}</Typography>}
            value={defaultValue}
            InputLabelProps={{ shrink: true, required: false }}
            InputProps={{
              inputProps: { style: { height: 25, fontSize: 18, color: 'grey' } },
              disableUnderline: true,
            }}
            fullWidth
            disabled
          />
        )
      }
    />
  )
}

export const InputField = ({
  label,
  name,
  register,
  type = '',
  required = false,
  placeholder = '',
  defaultValue,
  isEditing = false,
}) => {
  return (
    <>
      {isEditing ? (
        <TextField
          label={<Typography variant="subtitle1">{label}</Typography>}
          name={name}
          inputRef={register}
          type={type}
          InputLabelProps={{ shrink: true, required: false }}
          inputProps={{ style: { height: 25, fontSize: 18 } }}
          required={required}
          placeholder={placeholder}
          defaultValue={defaultValue}
          fullWidth
        />
      ) : (
        <TextField
          label={<Typography variant="subtitle1">{label}</Typography>}
          value={defaultValue}
          type={type}
          InputLabelProps={{ shrink: true, required: false }}
          InputProps={{
            inputProps: { style: { height: 25, fontSize: 18, color: 'grey' } },
            disableUnderline: true,
          }}
          fullWidth
          disabled
        />
      )}
    </>
  )
}

export const PasswordRules = ({ error = false }) => {
  const styles = useStyles()
  const rules = [
    'Mínimo de 8 caracteres',
    'Pelo menos uma letra minúscula',
    'Pelo menos um caracter especial (ex: !, @, #, $)',
    'Pelo menos um número',
  ]
  return (
    <List className={styles.rulesField}>
      {rules.map(rule => (
        <ListItem key={rule} className={[styles.noGutters, error && styles.error]}>
          {rule}
        </ListItem>
      ))}
    </List>
  )
}
