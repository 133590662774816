import { makeStyles } from '@material-ui/styles'

import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100vh',
    width: '100%',
    position: 'absolute',
    left: 0,
    overflow: 'auto',
  },
  linearProgress: {
    height: '1.6rem',
    width: '100%',
    backgroundColor: Theme.Colors.Primary.Lightest,
    borderRadius: spacing(8),
    marginBottom: spacing(32),

    '& .MuiLinearProgress-bar': {
      borderRadius: spacing(8),
    },

    [breakpoints.down('xs')]: {
      marginBottom: spacing(24),
    },
  },
  header: {
    width: '100%',
  },
  headerContent: {
    marginBottom: spacing(64),

    [breakpoints.down('xs')]: {
      marginBottom: spacing(24),
      padding: spacing(0, 24),
    },
  },
  pageTitle: {
    color: Theme.Colors.Grayscale.SixtyFour,
    fontSize: Theme.Typography.FontSize.XSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
    marginBottom: spacing(16),

    [breakpoints.down('xs')]: {
      marginBottom: spacing(12),
    },
  },
  stepTitle: {
    maxWidth: spacing(725),
    color: Theme.Colors.Grayscale.OneThousand,
    fontWeight: Theme.Typography.FontWeight.Medium,
    lineHeight: spacing(40),
    marginBottom: 0,

    [breakpoints.down('xs')]: {
      fontSize: Theme.Typography.FontSize.Small,
      lineHeight: spacing(24),
    },
  },
  button: {
    width: '100%',
    maxWidth: spacing(176),
    minWidth: spacing(155),

    [breakpoints.down('xs')]: {
      maxWidth: spacing(155),
    },
  },
  buttonFillWidth: {
    [breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  footer: {
    marginBottom: spacing(24),

    [breakpoints.down('xs')]: {
      marginBottom: spacing(16),
    },
  },
  footerText: {
    color: Theme.Colors.Grayscale.SixtyFour,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
    lineHeight: spacing(20),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: spacing(500),
    width: '100%',
    flex: 1,

    [breakpoints.down('xs')]: {
      maxWidth: spacing(368),
      padding: spacing(0, 24),
    },
  },
  icon: {
    fontSize: spacing(16),
    fill: Theme.Colors.Grayscale.Zero,
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    marginBottom: spacing(38),
    marginTop: spacing(82),
    gap: spacing(16),
    padding: spacing(0, 24),

    [breakpoints.down('xs')]: {
      marginBottom: spacing(32),
      marginTop: spacing(50),
    },
  },
  buttonAreaColumn: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  resultContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
  },
}))
