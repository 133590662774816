import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { Dialog, Timer } from '@refera/ui-web'
import { Timer1 as TickCircleIcon } from '@refera/ui-icons'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { DEADLINE } from '_utils/constants/service-order'
import useToggle from '_hooks/use-toggle'

import useStyles from './styles'

const TODAY = new Date().toISOString()

const DeadlineTimer = () => {
  const styles = useStyles()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const [isDeadlineInfoOpen, toggleIsDeadlineInfoOpen] = useToggle()

  const deadline = useMemo(() => serviceOrder?.deadline, [serviceOrder?.deadline])

  const deadlineInfo = useMemo(() => DEADLINE[serviceOrder?.stepStatus], [serviceOrder?.stepStatus])

  if (!deadlineInfo) {
    return null
  }

  return (
    <>
      <Grid>
        <Timer
          initial={TODAY}
          deadline={deadline}
          onClick={toggleIsDeadlineInfoOpen}
          className={styles.dealine}
          label={deadlineInfo.label}
          isPaused={serviceOrder?.isPaused}
        />
      </Grid>

      <Dialog
        open={isDeadlineInfoOpen}
        icon={TickCircleIcon}
        type="info"
        subject={deadlineInfo.info.title}
        description={deadlineInfo.info.text}
        labelApprove={deadlineInfo.info.button}
        onApprove={toggleIsDeadlineInfoOpen}
      />
    </>
  )
}

export default DeadlineTimer
