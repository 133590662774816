/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import { Alert, Datagrid, Toast, useConfirm } from '@refera/ui-web'
import { Grid } from '@material-ui/core'

import { ROUTES } from '_/utils/constants'

import HeaderTitle from '_/components/header-title'
import { deleteTradesmanAnticipation, getTradesmanAnticipations } from '_/modules/finance/actions'

import useStyles from '../styles'
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  antiticipationMessage,
  defaultToast,
} from '../utils/constants'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'
import { TradesmanAnticipationsColumns } from '../../manage-installments/utils/constants'

const TradesmanAnticipationRequests = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [anticipations, setAnticipations] = useState({})
  const [toastInfo, setToastInfo] = useState(defaultToast)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState()
  const [orderBy, setOrderBy] = useState('')
  const [pageSize, setPageSize] = useState(10)

  const [dialogMessage, setDialogMessage] = useState('')
  const { isConfirmed } = useConfirm()

  const handleGoBack = () => navigate(ROUTES.INSTALLMENTS_RECEIPTS)

  const fetchTradesmanAnticipations = useCallback(() => {
    setLoading(true)
    Promise.resolve(
      dispatch(
        getTradesmanAnticipations({
          page: page + 1,
          pageSize,
          orderBy,
        })
      )
    )
      .then(res => {
        setAnticipations(res)
        setLoading(false)
      })
      .catch(() => {
        setToastInfo({
          isOpen: true,
          type: 'error',
          message: antiticipationMessage('get'),
        })
        setLoading(false)
      })
  }, [dispatch, anticipations, page, pageSize, orderBy])

  useEffect(() => {
    fetchTradesmanAnticipations()
  }, [page, pageSize, orderBy])

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('-createdAt')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const handleRemoveAnticipation = useCallback(
    async anticipation => {
      if (anticipation?.status !== 'pending') {
        return setToastInfo({
          isOpen: true,
          type: 'warning',
          message: antiticipationMessage(anticipation?.status),
        })
      }
      setDialogMessage('Você tem certeza que deseja executar esta ação?')
      const confirmed = await isConfirmed()

      if (confirmed) {
        dispatch(deleteTradesmanAnticipation({ anticipationId: anticipation?.id }))
          .then(() => {
            setToastInfo({
              isOpen: true,
              type: 'success',
              message: DEFAULT_SUCCESS_MESSAGE,
            })
            fetchTradesmanAnticipations()
          })
          .catch(err =>
            setToastInfo({
              isOpen: true,
              type: err?.first() ? 'warning' : 'error',
              message: err?.first() || DEFAULT_ERROR_MESSAGE,
            })
          )
      }
    },
    [isConfirmed]
  )

  const handleCellClick = useCallback(params => {
    if (params.field === '-') return // actions column

    window.open(
      `${ROUTES.INSTALLMENTS_RECEIPTS}/antecipacoes/${params.row.id}`,
      '_blank',
      'noopener,noreferrer'
    )
  }, [])

  return (
    <Grid>
      <HeaderTitle title="Pedidos de antecipação" backButtonAction={handleGoBack} />
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            loading={loading}
            rows={anticipations?.results?.length ? anticipations?.results : []}
            columns={TradesmanAnticipationsColumns({ styles, handleRemoveAnticipation })}
            paginationMode="server"
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            columnVisibilityModel={{ actions: false }}
            rowCount={anticipations?.count}
            disableSelectionOnClick
            onCellClick={handleCellClick}
            onSortModelChange={handleOrderBy}
            keepNonExistentRowsSelected
            sx={{
              fontSize: '1.4rem',
              '& .MuiDataGrid-cell:hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Grid>
      </Grid>
      {toastInfo.isOpen && (
        <Toast
          draggable
          open={toastInfo.isOpen}
          autoHideDuration={6000}
          onClose={() => setToastInfo(defaultToast)}
        >
          <Alert
            severity={toastInfo.type}
            title={toastInfo.message}
            onClose={() => setToastInfo(defaultToast)}
          />
        </Toast>
      )}
      <ConfirmRemoveDialog message={dialogMessage} type="remove" />
    </Grid>
  )
}

export default TradesmanAnticipationRequests
