import React, { useCallback, useMemo } from 'react'

import Button from '_/components/button'

import { Button as MuiButton } from '@material-ui/core'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { resendPasswordAction } from '_/modules/profile/actions'
import { useToast } from '_/hooks/use-toast'
import { getResendPasswordLoadingSelector } from '_/modules/profile/selectors'
import { useParams } from '@reach/router'
import { useDialog } from '_/hooks/use-dialog'

const NavbarButtons = ({ isEditing, setIsEditing, isLoading, user }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { userId } = useParams()
  const isLoadingResendPassword = useSelector(getResendPasswordLoadingSelector)

  const { showDialog, closeDialog } = useDialog()

  const handleResendPassword = useCallback(async () => {
    if (user?.id) {
      await dispatch(resendPasswordAction(userId))
        .then(() => {
          showToast({
            message: 'Email reenviado com sucesso',
            type: 'success',
          })
        })
        .catch(err => {
          console.error(err)
          showToast({
            message: 'Erro ao reenviar email, tente novamente mais tarde.',
            type: 'error',
          })
        })
      closeDialog()
    }
  }, [userId, user?.id])

  const handleClickResendPassword = useCallback(() => {
    if (user?.isTemporaryPassword) {
      handleResendPassword()
    } else {
      showDialog({
        type: 'warning',
        subject:
          'Esta ação vai anular a senha atual do usuário em questão. Você confirma esta ação?',
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onApprove: handleResendPassword,
        onCancel: closeDialog,
      })
    }
  }, [user?.isTemporaryPassword])

  const buttons = useMemo(() => {
    if (isEditing) {
      return (
        <>
          <MuiButton
            disabled={isLoading}
            className={styles.buttonRed}
            onClick={() => setIsEditing(false)}
          >
            Cancelar
          </MuiButton>
          <MuiButton
            disabled={isLoading}
            className={styles.buttonSave}
            form="user-form"
            type="submit"
          >
            Salvar
          </MuiButton>
        </>
      )
    }

    return (
      <>
        <MuiButton
          className={styles.buttonSave}
          type="button"
          onClick={handleClickResendPassword}
          isLoading={isLoadingResendPassword}
        >
          Reenviar email
        </MuiButton>
        <Button
          id="edit-button"
          onClick={() => setIsEditing(true)}
          className={styles.buttons}
          variant="outlined"
          type="button"
        >
          Editar
        </Button>
      </>
    )
  }, [isEditing])

  return <div className={styles.container}>{buttons}</div>
}

export default NavbarButtons
