import React from 'react'
import { Chip as MUIChip } from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const Chip = ({ label, className }) => {
  const styles = useStyles()
  return (
    <MUIChip
      className={classnames(styles.chip, className)}
      label={label}
      variant="outlined"
      color="primary"
    />
  )
}

Chip.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}

Chip.defaultProps = {
  label: '',
  className: '',
}
export default React.memo(Chip)
