/* eslint-disable no-case-declarations */
import React, { useCallback, useState, useEffect, useReducer, useMemo, useRef } from 'react'
import { SwipeableDrawer, Typography, Divider, Grid, Slide, AppBar, Tab } from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab'
import { FilterSearch as FilterIcon, CloseCircle } from '@refera/ui-icons'
import { useDispatch, useSelector } from 'react-redux'
import Theme from '@refera/ui-core'
import { Input, Button, DatePicker } from '@refera/ui-web'
import { agenciesResultSelector } from '_modules/agency/selectors'
import { getUserAgents } from '_modules/responsible/actions'
import {
  getMessageOptionsSubjectSelector,
  getMessageSubjectSelectorList,
} from '_modules/messages/selector'
import { USER_ROLES } from '_utils/user'
import { getHelpRequests, updateHelpRequestFilter } from '_modules/helpRequests/actions'
import { getHelpRequestFilterSelector } from '_modules/helpRequests/selectors'
import useRolePermission from '_hooks/use-role-permission'
import Select from '_components/common/select'

import {
  FROM_TO_OPTIONS,
  TYPE_SUBJECT_OPTIONS,
  REQUESTER_OPTIONS,
  SERVICE_ORDER_STATUS,
} from '../utils/constants'

import { FORM_FIELD_NAME, INITIAL_STATE, reducer, RESET_STATE, UPDATE_STATE } from './reducer'
import classNames from 'classnames'
import useStyles from './styles'
import moment from 'moment'
import AutoComplete from '_/views/finance/components/AutoComplete'

const TABS = {
  PRIMARY_FILTERS: '0',
  MORE_FILTERS: '1',
}

const DEFAULT_SELECT_OPTION = {
  value: '',
  label: '(Todos)',
}

const DEFAULT_AGENCY_OPTION = { label: '*Minhas intermediárias', value: 'myAgencies' }

const STATUS_OPTIONS = [
  {
    value: 'pending',
    label: 'Em aberto',
  },
  {
    value: 'resolved',
    label: 'Resolvidos',
  },
]

const AVAILABLE_INTERMEDIARY_OPTIONS = [
  { value: 'available', label: 'Sim' },
  { value: 'notAvailable', label: 'Não' },
  { value: 'notDefined', label: 'Não definido' },
]

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

// TODO: Implement react-hook-form
// TODO: Refactor to improve readability
const Filters = ({ handleFilterDrawer, params, open, setPage }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const expectedDeadlineAtEndRef = useRef(null)
  const expectedDeadlineAtStartRef = useRef(null)
  const agencies = useSelector(agenciesResultSelector)
  const filters = useSelector(getHelpRequestFilterSelector)
  const filteredSubjectOptionList = useSelector(getMessageOptionsSubjectSelector(filters?.fromTo))
  const subjectOptionList = useSelector(getMessageSubjectSelectorList)

  const [agenciesSelect, setAgenciesSelect] = useState([])
  const [apply, setApply] = useState(false)
  const [responsiblesSelect, setResponsiblesSelect] = useState([])

  const { isAdmin, isIntermediary, isTradesman } = useRolePermission()
  const [currentTab, setCurrentTab] = useState(TABS.PRIMARY_FILTERS)

  const initialState = useMemo(() => {
    const hasOpenedAtValue = filters?.openedAt?.length && typeof filters?.openedAt === 'string'
    const hasResolvedAtValue =
      filters?.resolvedAt?.length && typeof filters?.resolvedAt === 'string'
    const hasExpectedDeadlineAtValue =
      filters?.expectedDeadlineAt?.length && typeof filters?.expectedDeadlineAt === 'string'
    const [openedAtStart, openedAtEnd] = hasOpenedAtValue
      ? filters.openedAt.replace(/(\[|\])/g, '').split(',')
      : []
    const [resolvedAtStart, resolvedAtEnd] = hasResolvedAtValue
      ? filters.resolvedAt.replace(/(\[|\])/g, '').split(',')
      : []
    const [expectedDeadlineAtStart, expectedDeadlineAtEnd] = hasExpectedDeadlineAtValue
      ? filters.expectedDeadlineAt.replace(/(\[|\])/g, '').split(',')
      : []

    const state = Object.values(FORM_FIELD_NAME).reduce((acc, currentFieldKey) => {
      switch (currentFieldKey) {
        case FORM_FIELD_NAME.FROM_TO:
          return {
            ...acc,
            [currentFieldKey]: filters?.[currentFieldKey]
              ? FROM_TO_OPTIONS[filters[currentFieldKey]]
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.TYPE_SUBJECT:
          return {
            ...acc,
            [currentFieldKey]: filters?.[currentFieldKey]
              ? TYPE_SUBJECT_OPTIONS[filters[currentFieldKey]]
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.OPENED_AT_END:
          return {
            ...acc,
            [currentFieldKey]: hasOpenedAtValue
              ? moment(openedAtEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.OPENED_AT_START:
          return {
            ...acc,
            [currentFieldKey]: hasOpenedAtValue
              ? moment(openedAtStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.EXPECTED_DEADLINE_START:
          return {
            ...acc,
            [currentFieldKey]: hasExpectedDeadlineAtValue
              ? moment(expectedDeadlineAtStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.EXPECTED_DEADLINE_END:
          return {
            ...acc,
            [currentFieldKey]: hasExpectedDeadlineAtValue
              ? moment(expectedDeadlineAtEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.RESOLVED_AT_END:
          return {
            ...acc,
            [currentFieldKey]: hasResolvedAtValue
              ? moment(resolvedAtEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.RESOLVED_AT_START:
          return {
            ...acc,
            [currentFieldKey]: hasResolvedAtValue
              ? moment(resolvedAtStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.SERVICE_ORDER_STATUS:
          return {
            ...acc,
            [currentFieldKey]: filters?.[currentFieldKey]
              ? SERVICE_ORDER_STATUS[filters[currentFieldKey]]
              : INITIAL_STATE[currentFieldKey],
          }

        case FORM_FIELD_NAME.AGENCY:
          const selectedAgency =
            filters?.agency === 'myAgencies' ? DEFAULT_AGENCY_OPTION : filters?.agency

          return {
            ...acc,
            [currentFieldKey]: selectedAgency || INITIAL_STATE[currentFieldKey],
          }
        default:
          return {
            ...acc,
            [currentFieldKey]: filters?.[currentFieldKey] || INITIAL_STATE[currentFieldKey],
          }
      }
    }, {})

    return {
      ...INITIAL_STATE,
      ...state,
    }
  }, [filters])

  const [localState, localDispatch] = useReducer(reducer, initialState)

  const onChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue)
  }, [])

  const tabs = [
    { label: 'Filtros Principais', value: TABS.PRIMARY_FILTERS },
    { label: 'Filtros Secundários', value: TABS.MORE_FILTERS },
  ]

  const handleChange = useCallback((value, field) => {
    localDispatch({
      type: UPDATE_STATE,
      payload: {
        [field]: value,
      },
    })
  }, [])

  const handleInputChange = useCallback(event => {
    const { name: field, value } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: {
        [field]: value,
      },
    })
  }, [])

  const cleanFilters = useCallback(() => {
    localDispatch({ type: RESET_STATE })
  }, [])

  const getDateInterval = useCallback((startDate, endDate) => {
    if (!startDate && !endDate) {
      return undefined
    }

    return `[${startDate.set('hour', 0).set('minute', 0).toISOString()},${endDate
      .set('hour', 23)
      .set('minute', 59)
      .toISOString()}]`
  }, [])

  const handleApplyFilters = useCallback(() => {
    const openedAt = localState?.isValid
      ? getDateInterval(localState?.openedAtStart, localState?.openedAtEnd)
      : null
    const resolvedAt = localState?.isValid
      ? getDateInterval(localState?.resolvedAtStart, localState?.resolvedAtEnd)
      : null
    const expectedDeadlineAt = localState?.isValid
      ? getDateInterval(localState?.expectedDeadlineAtStart, localState?.expectedDeadlineAtEnd)
      : null
    const {
      agency,
      message,
      responsible,
      requester,
      serviceOrder,
      status,
      subject,
      availableIntermediary,
    } = localState

    const updatedFilters = {
      fromTo: filters?.fromTo ?? '',
      typeSubject: filters?.typeSubject ?? '',
      requester,
      serviceOrder,
      agency: agency?.value || '',
      responsible,
      subject: subject?.value?.id || '',
      status,
      availableIntermediary,
      message,
      openedAt,
      resolvedAt,
      expectedDeadlineAt,
      serviceOrderStatus: filters?.serviceOrderStatus,
    }

    dispatch(updateHelpRequestFilter(updatedFilters))
    handleFilterDrawer()
    setApply(true)
  }, [dispatch, filters, localState])

  const getUserResponsibles = () => {
    Promise.resolve(dispatch(getUserAgents())).then(res => {
      const requestResponsibles = res
        .filter(responsibleItem => responsibleItem.getRoles !== USER_ROLES.CS_REFERA)
        .map(responsibleItem => ({
          value: responsibleItem.id,
          label: responsibleItem.name,
        }))
      return setResponsiblesSelect([DEFAULT_SELECT_OPTION, ...requestResponsibles])
    })
  }

  const subjectOptions = useMemo(() => {
    if (!subjectOptionList?.length) {
      return []
    }

    if (isIntermediary) {
      if (localState?.requester === REQUESTER_OPTIONS.intermediary) {
        const filteredOptions = subjectOptionList.filter(
          subjectOption => subjectOption?.allowCsmanagerCsapprover
        )

        return [DEFAULT_SELECT_OPTION, ...filteredOptions]
      }

      if (localState?.requester === REQUESTER_OPTIONS.others) {
        const filteredOptions = subjectOptionList.filter(
          subjectOption => !subjectOption?.allowCsmanagerCsapprover
        )

        return [DEFAULT_SELECT_OPTION, ...filteredOptions]
      }

      return [DEFAULT_SELECT_OPTION, ...subjectOptionList]
    }

    if (isTradesman) {
      const filteredOptions = subjectOptionList.filter(
        subjectOption => subjectOption?.allowTradesman
      )
      return [DEFAULT_SELECT_OPTION, ...filteredOptions]
    }

    if (isAdmin) {
      if (['', '(Todos)'].includes(localState?.fromTo)) {
        const filteredOptions = subjectOptionList.filter(
          subjectOption =>
            (subjectOption.allowCsrefera ||
              subjectOption.allowCsmanagerCsapprover ||
              subjectOption.allowTradesman) &&
            subjectOption.isActive
        )

        return [DEFAULT_SELECT_OPTION, ...filteredOptions]
      }

      if (localState?.fromTo) {
        return [DEFAULT_SELECT_OPTION, ...filteredSubjectOptionList]
      }
    }

    return [DEFAULT_SELECT_OPTION]
  }, [
    filteredSubjectOptionList,
    isAdmin,
    isIntermediary,
    isTradesman,
    localState?.fromTo,
    subjectOptionList,
  ])

  const serviceOrderStatusValue = useMemo(
    () => (localState?.serviceOrderStatus === '' ? '(Todos)' : localState?.serviceOrderStatus),
    [localState?.serviceOrderStatus]
  )

  const serviceOrderStatusOptions = useMemo(
    () => Object.values(SERVICE_ORDER_STATUS).map(item => ({ value: item, label: item })),
    []
  )

  const requesterOptions = useMemo(
    () =>
      Object.keys(REQUESTER_OPTIONS).map(optionKey => ({
        value: optionKey,
        label: REQUESTER_OPTIONS[optionKey],
      })),
    []
  )

  const fromToValue = useMemo(
    () => (localState?.fromTo === '' ? '(Todos)' : localState?.fromTo),
    [localState?.fromTo]
  )

  const fromToOptions = useMemo(
    () => Object.values(FROM_TO_OPTIONS).map(item => ({ value: item, label: item })),
    []
  )

  const typeSubjectValue = useMemo(
    () =>
      localState?.typeSubject === ''
        ? TYPE_SUBJECT_OPTIONS[localState?.typeSubject]
        : localState?.typeSubject,
    [localState?.typeSubject]
  )

  const typeSubjectOptions = useMemo(
    () => Object.values(TYPE_SUBJECT_OPTIONS).map(item => ({ value: item, label: item })),
    []
  )

  const formattedSubjectOptions = useMemo(
    () => subjectOptions?.map(option => ({ value: option, label: option.option })),
    [subjectOptions]
  )

  useEffect(() => {
    if (isIntermediary && responsiblesSelect.length === 0) {
      getUserResponsibles()
    }
  }, [isIntermediary])

  useEffect(() => {
    if (!isIntermediary && agencies?.length > 0 && agenciesSelect?.length === 0) {
      const newOptions = [DEFAULT_SELECT_OPTION, DEFAULT_AGENCY_OPTION]
      const formattedAgencies = agencies
        .sort((first, second) => (first.name.toLowerCase() > second.name.toLowerCase() ? 1 : -1))
        .map(agencyItem => ({
          value: agencyItem.id,
          label: agencyItem.name,
        }))

      setAgenciesSelect(newOptions.concat(formattedAgencies))
    }
  }, [agencies])

  useEffect(() => {
    if (localState?.serviceOrderStatus) {
      const serviceOrderStatusRequest = Object.keys(SERVICE_ORDER_STATUS).find(
        key => SERVICE_ORDER_STATUS[key] === localState?.serviceOrderStatus
      )
      dispatch(
        updateHelpRequestFilter({
          serviceOrderStatus: serviceOrderStatusRequest,
        })
      )
    }

    if (localState?.fromTo) {
      const fromToRequest = Object.keys(FROM_TO_OPTIONS).find(
        key => FROM_TO_OPTIONS[key] === localState?.fromTo
      )
      dispatch(
        updateHelpRequestFilter({
          fromTo: fromToRequest,
        })
      )
    }

    if (localState?.typeSubject) {
      const typeSubjectRequest = Object.keys(TYPE_SUBJECT_OPTIONS).find(
        key => TYPE_SUBJECT_OPTIONS[key] === localState?.typeSubject
      )
      dispatch(
        updateHelpRequestFilter({
          typeSubject: typeSubjectRequest,
        })
      )
    }
  }, [localState?.fromTo, dispatch, localState?.serviceOrderStatus, localState?.typeSubject])

  useEffect(() => {
    if (!filters) {
      return
    }

    if ((Object.values(filters).some(value => value) && apply) || apply) {
      const payloadParams = { ...params, ...filters }
      setPage(0)
      dispatch(getHelpRequests(payloadParams))
      setApply(false)
    }
  }, [filters, apply, params])

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        expectedDeadlineAtStartRef.current &&
        !expectedDeadlineAtStartRef.current.contains(event.target)
      ) {
        if (localState?.expectedDeadlineAtEnd && !localState?.expectedDeadlineAtStart) {
          localDispatch({
            type: UPDATE_STATE,
            payload: {
              expectedDeadlineAtStart: localState.expectedDeadlineAtEnd,
            },
          })
        }
      }

      if (
        expectedDeadlineAtEndRef.current &&
        !expectedDeadlineAtEndRef.current.contains(event.target)
      ) {
        if (localState?.expectedDeadlineAtStart && !localState?.expectedDeadlineAtEnd) {
          localDispatch({
            type: UPDATE_STATE,
            payload: {
              expectedDeadlineAtEnd: localState.expectedDeadlineAtStart,
            },
          })
        }
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [localState])

  const TABSCONTENT = useMemo(
    () => ({
      0: (
        <>
          {!isIntermediary && !isTradesman && (
            <Grid className={styles.autocompleteWrapper}>
              <Typography className={styles.label}>Nome da empresa</Typography>
              <AutoComplete
                name="agency"
                multiple={false}
                placeholder="Intermediária"
                options={agenciesSelect}
                value={localState?.agency}
                onChange={handleChange}
              />
            </Grid>
          )}
          <Input
            key="serviceOrderId"
            label="ID do chamado"
            value={localState?.serviceOrder}
            placeholder="12345"
            style={{ fontSize: '16px' }}
            onChange={handleInputChange}
            fullWidth
            name="serviceOrder"
          />
          <Grid className={styles.autocompleteWrapper}>
            <Typography className={styles.label}>Assunto</Typography>
            <AutoComplete
              name="subject"
              multiple={false}
              placeholder="Assunto"
              options={formattedSubjectOptions.filter(option => option.label !== undefined)}
              value={localState?.subject}
              onChange={handleChange}
            />
          </Grid>
          <Input
            key="message"
            label="Mensagem Contenha"
            value={localState?.message}
            placeholder="Mensagem"
            style={{ fontSize: '16px' }}
            onChange={handleInputChange}
            name="message"
            fullWidth
          />
          {(isIntermediary || isAdmin) && (
            <Select
              label="Status do chamado"
              defaultValue={serviceOrderStatusValue}
              value={serviceOrderStatusValue}
              onChange={handleInputChange}
              name="serviceOrderStatus"
              options={serviceOrderStatusOptions}
            />
          )}
          <Select
            label="Status do pedido"
            defaultValue={localState?.status}
            value={localState?.status}
            onChange={handleInputChange}
            name="status"
            options={[DEFAULT_SELECT_OPTION, ...STATUS_OPTIONS]}
          />
        </>
      ),
      1: (
        <>
          {(isAdmin || isIntermediary) && (
            <Select
              label="Tipo de registro"
              defaultValue={typeSubjectValue}
              value={typeSubjectValue}
              onChange={handleInputChange}
              name="typeSubject"
              options={typeSubjectOptions}
            />
          )}
          {!isIntermediary && !isTradesman && (
            <Select
              label="De/Para"
              defaultValue={fromToValue}
              value={fromToValue}
              onChange={handleInputChange}
              name="fromTo"
              options={fromToOptions}
            />
          )}
          <Grid className={styles.inputsWrapper}>
            <DatePicker
              key="openedAtStart"
              label="Aberto em"
              value={localState?.openedAtStart}
              onChange={value => handleChange(value, 'openedAtStart')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              invalidDateMessage="Insira uma data válida"
              disableFuture
              mask="__/__/____"
            />
            <Typography className={styles.dotDivision}>•</Typography>
            <DatePicker
              key="openedAtEnd"
              label=" "
              value={localState?.openedAtEnd}
              onChange={value => handleChange(value, 'openedAtEnd')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              invalidDateMessage="Insira uma data válida"
              disableFuture
              minDate={localState?.openedAtStart || undefined}
              mask="__/__/____"
            />
          </Grid>
          <Grid className={styles.inputsWrapper}>
            <DatePicker
              key="expectedDeadlineAtStart"
              label="Prazo de solução"
              value={localState?.expectedDeadlineAtStart}
              onChange={value => handleChange(value, 'expectedDeadlineAtStart')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              ref={expectedDeadlineAtStartRef}
              invalidDateMessage="Insira uma data válida"
              mask="__/__/____"
            />
            <Typography className={styles.dotDivision}>•</Typography>
            <DatePicker
              key="expectedDeadlineAtEnd"
              label=" "
              value={localState?.expectedDeadlineAtEnd}
              onChange={value => handleChange(value, 'expectedDeadlineAtEnd')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              invalidDateMessage="Insira uma data válida"
              ref={expectedDeadlineAtEndRef}
              minDate={localState?.expectedDeadlineAtStart || undefined}
              mask="__/__/____"
            />
          </Grid>
          <Grid className={styles.inputsWrapper}>
            <DatePicker
              key="resolvedAtStart"
              label="Resolvido em"
              value={localState?.resolvedAtStart}
              onChange={value => handleChange(value, 'resolvedAtStart')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              invalidDateMessage="Insira uma data válida"
              disableFuture
              mask="__/__/____"
            />
            <Typography className={styles.dotDivision}>•</Typography>
            <DatePicker
              key="resolvedAtEnd"
              label=" "
              value={localState?.resolvedAtEnd}
              onChange={value => handleChange(value, 'resolvedAtEnd')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              invalidDateMessage="Insira uma data válida"
              disableFuture
              minDate={localState?.resolvedAtStart || undefined}
              mask="__/__/____"
            />
          </Grid>
          {isIntermediary && (
            <Select
              label="Responsável"
              defaultValue={localState?.responsible}
              value={localState?.responsible}
              onChange={handleInputChange}
              name="responsible"
              options={responsiblesSelect}
            />
          )}
          {isIntermediary && (
            <Select
              label="Solicitante do pedido de ajuda"
              defaultValue={localState?.requester}
              value={localState?.requester}
              onChange={handleInputChange}
              name="requester"
              options={requesterOptions}
            />
          )}
          {!isIntermediary && !isTradesman && (
            <Select
              label="Disponibilizado para a intermediária"
              defaultValue={localState?.availableIntermediary}
              value={localState?.availableIntermediary}
              onChange={handleInputChange}
              name="availableIntermediary"
              options={[DEFAULT_SELECT_OPTION, ...AVAILABLE_INTERMEDIARY_OPTIONS]}
            />
          )}
        </>
      ),
    }),
    [localState, handleInputChange, agenciesSelect, isIntermediary, isAdmin, isTradesman]
  )

  return (
    <>
      <SwipeableDrawer
        component="section"
        anchor="right"
        open={open}
        onClose={handleFilterDrawer}
        className={styles.container}
        TransitionComponent={Transition}
      >
        <Grid className={styles.header}>
          <Grid className={styles.headerTitle}>
            <Grid className={styles.iconWrapper}>
              <FilterIcon color={Theme.Colors.Primary.Base} />
            </Grid>
            <Typography component="h1" variant="h5" className={styles.title}>
              Filtros
            </Typography>
          </Grid>
          <Button onClick={handleFilterDrawer} variant="ghost">
            <CloseCircle color={Theme.Colors.Primary.Base} className={styles.closeIcon} />
          </Button>
        </Grid>
        <TabContext value={currentTab}>
          <AppBar color="transparent" position="static" className={styles.filtersTabs} />
          <TabList onChange={onChangeTab} aria-label="Filtros">
            {tabs.map(tab => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                className={classNames(styles.tab, currentTab === tab.value && styles.selectedTab)}
              />
            ))}
          </TabList>
        </TabContext>
        <Divider className={styles.divider} />
        <Grid className={styles.content}>
          <Grid className={styles.inputWrapper}>{TABSCONTENT[currentTab]}</Grid>
        </Grid>
        <Grid className={styles.buttonsContainer}>
          <Button variant="ghost" color="primary" onClick={cleanFilters}>
            Limpar filtros
          </Button>
          <Button
            variant="primary"
            color="orange"
            disabled={!localState?.isValid}
            onClick={handleApplyFilters}
          >
            Aplicar filtro
          </Button>
        </Grid>
      </SwipeableDrawer>
    </>
  )
}

export default Filters
