/* eslint-disable camelcase */
import { Map } from 'immutable'

const DEFAULT_ERROR = 'Não foi possível executar esta ação, tente novamente.'

export const formatErrorMessage = (error, size = 500) => {
  if (typeof error === 'string' && error.length < size) return error
  if (Map.isMap(error)) return error.get('errorMessage')

  return error?.error_message || DEFAULT_ERROR
}
