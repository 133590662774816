import React from 'react'
import { Grid } from '@material-ui/core'
import { Typography } from '@refera/ui-web'
import { ArrowLeft as ArrowLeftIcon } from '@refera/ui-icons'
import IconButton from '_/components/svg/icon-button'

import useStyles from './styles'

const HeaderTitle = ({ title, children = null, backButtonAction }) => {
  const styles = useStyles()

  return (
    <Grid className={styles.header}>
      <Typography variant="h4" font="dmsans" className={styles.title}>
        {backButtonAction && (
          <IconButton aria-label="Voltar página" onClick={backButtonAction}>
            <ArrowLeftIcon color="black" />
          </IconButton>
        )}
        {title}
      </Typography>
      <Grid className={styles.children}>{children}</Grid>
    </Grid>
  )
}

export default HeaderTitle
