/* eslint-disable react/no-danger */
import React, { useState, useCallback, useMemo } from 'react'
import { Grid, Typography, Modal, IconButton } from '@material-ui/core'

import Svg from '_components/svg'
import { useDispatch } from 'react-redux'
import CloseCircle from '_assets/svgs/close-circle.svg'
import { updateUser } from '_/modules/authentication/actions'

import TourButtons from './tour-buttons'
import useStyles from './styles'

const Tour = ({ user }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const userTips = useMemo(
    () =>
      user?.tour?.filter(
        tip =>
          (tip.getRoles.includes(user?.getRoles) || tip.getRoles.includes(user?.getRoles?.[0])) &&
          !tip.mobile
      ),
    [user]
  )

  const [open, setOpen] = useState(true)
  const [tipIndex, setTipIndex] = useState(0)

  const handleNext = useCallback(() => {
    setTipIndex(prevState => prevState + 1)
  }, [tipIndex])

  const handleBack = useCallback(() => {
    setTipIndex(prevState => prevState - 1)
  }, [open])

  const handleFinish = useCallback(() => {
    dispatch(updateUser({ id: user?.id, welcomeTourWeb: true }))
    setOpen(() => false)
  }, [dispatch])

  const handleClose = useCallback(() => {
    setOpen(() => false)
  }, [open])

  if (!userTips?.length || user?.welcomeTourWeb) {
    return null
  }

  return (
    <Modal
      open={open}
      title="Tour"
      disableEscapeKeyDown
      onClose={handleClose}
      onDismiss={handleClose}
      className={styles.modal}
    >
      <Grid className={styles.container}>
        <Grid className={styles.exitButton}>
          <IconButton onClick={handleClose}>
            <Svg icon={CloseCircle} className={styles.exitIcon} />
          </IconButton>
        </Grid>
        {userTips?.[tipIndex]?.picture && (
          <img className={styles.image} alt="" src={userTips?.[tipIndex]?.picture} />
        )}
        <Grid className={styles.templateWrapper}>
          <Grid className={styles.dotWrapper}>
            {userTips?.map((_tour, index) => {
              if (tipIndex === index) {
                return <Grid className={[styles.dot, styles.selectedDot]} />
              }

              return <Grid className={styles.dot} />
            })}
          </Grid>
          <Typography className={styles.title}>{userTips?.[tipIndex]?.title}</Typography>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: userTips?.[tipIndex]?.content }}
          />
        </Grid>
        <TourButtons
          info={userTips}
          infoIndex={tipIndex}
          infoLastIndex={tipIndex?.length}
          handleBack={handleBack}
          handleNext={handleNext}
          handleFinish={handleFinish}
        />
      </Grid>
    </Modal>
  )
}

export default Tour
