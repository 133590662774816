/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useRef } from 'react'
import { MaskInput } from '_components/inputs'
import { Controller, useFormContext } from 'react-hook-form'
import { cpfCnpjMask } from '_/views/finance/payer-data/utils'

function BaseInput({
  name,
  label,
  required,
  labelProps,
  showErrorMessage,
  errorMessage,
  fieldProps,
  onChange,
  defaultValue,
  errorProps,
  ref,
  ...rest
}) {
  const inputRef = useRef(ref ?? null)

  useEffect(() => {
    if (defaultValue) {
      const maskedValue = cpfCnpjMask(defaultValue)
      inputRef.current.value = maskedValue
    }
  }, [defaultValue])

  const onChangeCallback = useCallback(
    event => {
      const maskedValue = cpfCnpjMask(event.target.value)
      event.target.value = maskedValue

      if (onChange) {
        onChange(event)
      }
    },
    [onChange]
  )

  return (
    <MaskInput.Root {...rest}>
      <MaskInput.Label required={required} hasError={errorMessage} {...labelProps}>
        {label}
      </MaskInput.Label>
      <MaskInput.Control
        error={!!errorMessage}
        name={name}
        onChange={onChangeCallback}
        inputProps={{
          maxLength: '18',
          ref: inputRef,
        }}
        {...fieldProps}
      />
      {showErrorMessage && (
        <MaskInput.ErrorMessage {...errorProps}>{errorMessage}</MaskInput.ErrorMessage>
      )}
    </MaskInput.Root>
  )
}

function ControlledInput({
  name,
  label,
  required,
  labelProps,
  showErrorMessage,
  errorMessage,
  controllerProps,
  fieldProps,
  defaultValue,
  ...rest
}) {
  const { control, setValue } = useFormContext()

  useEffect(() => {
    if (defaultValue) {
      const maskedValue = cpfCnpjMask(defaultValue)
      setValue(name, maskedValue)
    }
  }, [defaultValue])

  const onChangeCallback = useCallback((event, hookFormOnchange) => {
    const maskedValue = cpfCnpjMask(event.target.value)
    event.target.value = maskedValue

    hookFormOnchange(event)
  }, [])

  return (
    <MaskInput.Root {...rest}>
      <MaskInput.Label required={required} hasError={errorMessage} {...labelProps}>
        {label}
      </MaskInput.Label>
      <Controller
        name={name}
        control={control}
        {...controllerProps}
        render={({ ref, onChange, ...field }) => (
          <MaskInput.Control
            error={!!errorMessage}
            inputProps={{
              maxLength: '18',
              ref,
            }}
            onChange={event => onChangeCallback(event, onChange)}
            {...field}
            {...fieldProps}
          />
        )}
      />
      {showErrorMessage && <MaskInput.ErrorMessage>{errorMessage}</MaskInput.ErrorMessage>}
    </MaskInput.Root>
  )
}

export const CpfCnpjInput = React.memo(BaseInput)
export const ControlledCpfCnpjInput = React.memo(ControlledInput)
