import React, { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'
import { useParams, navigate } from '@reach/router'
import { Datagrid } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'
import { FINANCE_ROUTES, ROUTES } from '_/utils/constants'
import { useDispatch } from 'react-redux'
import { getChargeFileData } from '_/modules/finance/actions'
import { useToast } from '_/hooks/use-toast'
import { ChargeFileDetailsColumns } from '../../utils/constants'
import { TABLE_SX } from '_/views/finance/utils/constants'
import humps from 'humps'

import useStyles from '../../../receipts/styles'

const ChargeFileDetails = () => {
  const styles = useStyles()
  const { processingDate } = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [chargeDataList, setChargeDataList] = useState([])

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [orderBy, setOrderBy] = useState('')

  const { showToast } = useToast()

  const goBack = () => navigate(FINANCE_ROUTES.CHARGE_FILES)

  const handleGetPaymentData = async () => {
    setIsLoading(true)
    const isoDate = processingDate.replace('@', '.')
    const params = {
      processingDate: isoDate,
      page: page + 1,
      pageSize,
      orderBy,
    }

    await dispatch(getChargeFileData(params))
      .then(res => setChargeDataList(res))
      .catch(err => {
        showToast({ message: 'Ocorreu um erro ao buscar os registros', type: 'error' })
        setIsLoading(false)
        console.error(err)
      })
    setIsLoading(false)
  }

  const handleCellClick = params => {
    if (params.row.serviceOrderId) {
      window.open(
        `${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        setOrderBy('')
        return
      }
      const { field, sort } = order
      const decamelizedField = humps.decamelize(field)
      sort === 'desc' ? setOrderBy(`-${decamelizedField}`) : setOrderBy(decamelizedField)
    },
    [setOrderBy]
  )

  useEffect(() => {
    if (processingDate) handleGetPaymentData()
  }, [page, pageSize, orderBy])

  return (
    <Grid>
      <HeaderTitle title="Detalhes do arquivo de cobrança" backButtonAction={goBack} />
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            loading={isLoading}
            rows={chargeDataList?.results || []}
            columns={ChargeFileDetailsColumns({ styles })}
            paginationMode="server"
            rowCount={chargeDataList?.count}
            onPageChange={setPage}
            page={page}
            onCellClick={handleCellClick}
            onPageSizeChange={setPageSize}
            onSortModelChange={handleOrderBy}
            pageSize={pageSize}
            sx={TABLE_SX}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ChargeFileDetails
