import React from 'react'

export const getPrivacyPolicyMessage = (termsOfUseLink, privacyPolicyLink) => (
  <>
    Seja bem-vindo à <strong>PLATAFORMA REFERA</strong>!
    <div style={{ height: '32px' }} />
    Somos uma empresa que busca unir pessoas, que precisam de serviços de manutenção/reforma de
    imóveis, a prestadores de tais serviços; criando facilitadores neste processo e lidando com
    Dados Pessoais das partes envolvidas.
    <div style={{ height: '24px' }} />
    Por entendermos que a privacidade de suas informações é importante e estarmos comprometidos em
    cumprir as diretrizes da Lei Geral de Proteção de Dados nº 13.709/2018 (LGPD) quanto ao uso dos
    dados pessoais e sensíveis, possuímos um{' '}
    <a href={termsOfUseLink} target="_blank" rel="noreferrer">
      <strong>Termo de Uso</strong>
    </a>{' '}
    e uma{' '}
    <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
      <strong>Política de Privacidade</strong>
    </a>
    , que têm o propósito de informar a você, usuário dos produtos e serviços da REFERA, como
    tratamos seus dados pessoais, quais são os seus direitos, seus deveres e como você pode
    exercê-los.
    <div style={{ height: '24px' }} />
    Para usar nossa plataforma, é essencial que você concorde com ambos os conteúdos acima. Se este
    for o caso, assinale por favor o check box abaixo e clique em “<strong>Concordar</strong>”.
    <div style={{ height: '32px' }} />
    Qualquer dúvida, dificuldade, solicitação, sugestão ou reclamação pode ser feita pelo e-mail
    suporte@refera.com.br.
  </>
)
