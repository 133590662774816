export const getHelpRequestListSelector = state => {
  const { results, order } = state.helpRequests
  return order.map(id => results.get(id.toString()))
}

export const getHelpRequestPendingListSelector = state =>
  state.helpRequests.get('procedures')?.toJS()

export const getHelpRequestIsFilterDirtySelector = state =>
  state?.helpRequests?.get('isFilterDirty')

export const getHelpRequestFilterSelector = state => state?.helpRequests?.get('filter')?.toJS()

export const getHelpRequestCountSelector = state => state?.helpRequests?.get('count')

export const getHelpRequestsByIDSelector = id => state => {
  return state?.helpRequests.getIn(['results', id])
}
export const getCurrentHelpRequestListSelector = state =>
  state?.helpRequests?.get('currentHelpRequests')?.toJS()

export const getFeedbackMessageSelector = state => state?.helpRequests?.get('feedbackMessage')
