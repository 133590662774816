import { makeStyles } from '@material-ui/styles'

import { theme } from './theme'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100vh',
    justifyItems: 'center',
    width: '100%',

    [breakpoints.down('sm')]: {
      padding: spacing(0, 20),
    },
  },
  header: {
    gridArea: 'header',
    width: '100%',
  },
  footer: {
    gridArea: 'footer',
    padding: spacing(40, 0),
  },
  main: {
    gridArea: 'main',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50rem',
    flex: 1,

    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  linearProgress: {
    height: '1.6rem',
    width: '100%',
    backgroundColor: theme.palette.primary.lightest,
    borderRadius: spacing(8),

    '& .MuiLinearProgress-bar': {
      borderRadius: spacing(8),
    },
  },
  headerContent: {
    margin: '3.2rem 0',
    position: 'relative',

    [breakpoints.down('sm')]: {
      paddingTop: '4rem',
    },
  },
  logo: {
    maxWidth: spacing(175),
    maxHeight: spacing(75),
    position: 'absolute',
    inset: '0 auto auto 0',

    [breakpoints.down('sm')]: {
      maxWidth: spacing(100),
      maxHeight: spacing(50),
      inset: `${spacing(-20)} auto auto 0`,
    },
  },
  stepTitle: {
    width: spacing(630),

    [breakpoints.down('xs')]: {
      fontSize: spacing(24),
    },
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  LGPDWarning: {
    color: '#000',
    textAlign: 'center',
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
    maxWidth: spacing(450),
    margin: '30px 0',
  },
  warningPlusButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  type: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20p',
    display: 'flex',
    alignItems: 'center',
    color: Theme.Colors.Grayscale.OneThousand,
    flexWrap: 'wrap',

    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  link: {
    color: Theme.Colors.Primary.Base,
    paddingLeft: '5px',
    paddingRight: '5px',
  },
}))
