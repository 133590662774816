/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import React, { useCallback, useRef, useMemo, useEffect, useState } from 'react'
import {
  Modal,
  Button,
  Input,
  Typography,
  Dialog,
  Loader,
  MaskedInput,
  DatePicker,
  MoneyInput,
} from '@refera/ui-web'
import { Danger as DangerIcon, TickCircle as TickCircleIcon } from '@refera/ui-icons'
import { Grid } from '@material-ui/core'
import { updateProperty } from '_modules/property/actions'

import useStyles from './styles'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getUpdatePropertyError, getUpdatePropertyLoading } from '_/modules/property/selectors'
import { usePrevious } from '_/hooks/use-previous'
import { updateServiceOrder } from '_/modules/service-orders/actions'
import { updateServiceOrderLoadingSelector } from '_/modules/service-orders/selectors'

const defaultDialogInfo = {
  open: false,
  icon: DangerIcon,
  type: null,
  subject: '',
  description: '',
  onApprove: () => {},
}

const PropertyDataModal = ({ open, onCloseModal, data, serviceOrder, agency }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const formRef = useRef()
  const updatePropertyError = useSelector(getUpdatePropertyError)
  const loadingUpdateProperty = useSelector(getUpdatePropertyLoading)
  const loadingUpdateServiceOrder = useSelector(updateServiceOrderLoadingSelector)
  const [dialogInfo, setDialogInfo] = useState(defaultDialogInfo)
  const customFields = agency?.customFields

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        ...data,
        ...{
          rentValue: serviceOrder?.rentValue,
          contractStartDate: serviceOrder?.contractStartDate,
          approvedMaximumValue: serviceOrder?.approvedMaximumValue,
          contractCode: serviceOrder?.contractCode,
        },
      }
    }, [data, serviceOrder]),
  })
  const formValues = watch()
  const isLoading = useMemo(
    () => loadingUpdateProperty || loadingUpdateServiceOrder,
    [loadingUpdateProperty, loadingUpdateServiceOrder]
  )

  const onSubmit = useCallback(
    handleSubmit(async formData => {
      const serviceOrderData = (({
        rentValue,
        contractStartDate,
        approvedMaximumValue,
        contractCode,
      }) => ({
        rentValue,
        contractStartDate,
        approvedMaximumValue,
        contractCode,
      }))(formData)
      Object.keys(serviceOrderData).forEach(key => {
        if (serviceOrderData[key] === undefined) serviceOrderData[key] = null
      })
      if (serviceOrderData.contractStartDate !== null) {
        serviceOrderData.contractStartDate = new Date(serviceOrderData.contractStartDate)
          .toISOString()
          .split('T')[0]
      }
      const { rentValue, contractStartDate, approvedMaximumValue, id, ...payload } = formData
      payload.service_order_id = serviceOrder.id
      await dispatch(updateProperty(serviceOrder.property.get('id'), payload))
        .then(() =>
          setDialogInfo({
            open: true,
            icon: TickCircleIcon,
            type: 'success',
            subject: 'Sucesso!',
            description: 'Ação executada com sucesso.',
            onApprove: onCloseModal,
          })
        )
        .catch(() =>
          setDialogInfo({
            open: true,
            icon: DangerIcon,
            type: 'error',
            subject: 'Erro',
            description:
              updatePropertyError?.toJS()?.[0] ||
              'Ocorreu um erro ao tentar atualizar os dados do imóvel.',
            onApprove: () => setDialogInfo(defaultDialogInfo),
          })
        )
      dispatch(updateServiceOrder(serviceOrderData))
    }),
    []
  )

  return (
    <>
      <Loader hasBackdrop open={isLoading} />
      {dialogInfo?.open && !isLoading && <Dialog {...dialogInfo} />}
      <Modal
        className={styles.modal}
        title="Dados do imóvel"
        onClose={onCloseModal}
        onBackdropClick={onCloseModal}
        open={open}
        actionsButtons={
          <Grid className={styles.modalButtons}>
            <Button color="red" variant="secondary" onClick={onCloseModal}>
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="primary"
              disabled={loadingUpdateProperty}
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </Grid>
        }
      >
        <form onSubmit={onSubmit} ref={formRef} className={styles.modalContent}>
          <div className={styles.modalColumn}>
            <Input
              label="Endereço"
              required
              name="address"
              inputRef={register({ required: true })}
              style={{ fontSize: '14px' }}
              fullWidth
            />
            {errors.address && <Typography className={styles.error}>Campo requerido</Typography>}
            <Controller
              name="cep"
              control={control}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <MaskedInput
                  format="#####-###"
                  mask=" "
                  label="CEP"
                  value={value}
                  required
                  style={{ fontSize: '14px' }}
                  onChange={e => onChange(e.formattedValue)}
                  fullWidth
                />
              )}
            />
            {errors.cep && <Typography className={styles.error}>Campo requerido</Typography>}
            <Input
              label="Cidade"
              required
              name="city"
              inputRef={register({ required: true })}
              style={{ fontSize: '14px' }}
              fullWidth
            />
            {errors.city && <Typography className={styles.error}>Campo requerido</Typography>}
            <Input
              label="Código do imóvel"
              name="code"
              inputRef={register}
              style={{ fontSize: '14px' }}
              fullWidth
            />
            <MoneyInput
              value={formValues?.rentValue || ''}
              name="rentValue"
              onChange={e => setValue('rentValue', e.floatValue, { shouldDirty: true })}
              label="Valor do aluguel"
              decimalScale={2}
              style={{ fontSize: '14px' }}
              placeholder="R$ "
              ref={register('rentValue')}
            />
            <Controller
              control={control}
              name="contractStartDate"
              defaultValue=""
              as={
                <DatePicker
                  required={false}
                  label="Início do contrato"
                  variant="inline"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/AAAA"
                  invalidDateMessage="Insira uma data válida"
                  refuse={/[^\d\\.]+/gi}
                  defaultValue=""
                  className={styles.dateContent}
                  mask="__/__/____"
                />
              }
            />
            {errors.city && <Typography className={styles.error}>Campo requerido</Typography>}
          </div>
          <div className={styles.modalColumn}>
            <Input
              label="Complemento"
              name="complement"
              inputRef={register}
              style={{ fontSize: '14px' }}
              fullWidth
            />
            <Input
              label="Bairro"
              required
              name="neighborhood"
              inputRef={register({ required: true })}
              style={{ fontSize: '14px' }}
              fullWidth
            />
            {errors.neighborhood && (
              <Typography className={styles.error}>Campo requerido</Typography>
            )}
            <Input
              label="Número"
              required
              name="number"
              inputRef={register({ required: true })}
              style={{ fontSize: '14px' }}
              fullWidth
            />
            {errors.number && <Typography className={styles.error}>Campo requerido</Typography>}
            {customFields?.includes('contract_code') && (
              <Input
                label="Código do contrato"
                name="contractCode"
                inputRef={register}
                style={{ fontSize: '14px' }}
                fullWidth
              />
            )}
            <MoneyInput
              value={formValues?.approvedMaximumValue || ''}
              name="approvedMaximumValue"
              onChange={e => setValue('approvedMaximumValue', e.floatValue, { shouldDirty: true })}
              label="Valor máximo pré aprovado"
              decimalScale={2}
              style={{ fontSize: '14px' }}
              placeholder="R$ "
              ref={register('approvedMaximumValue')}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default React.memo(PropertyDataModal)
