import humps from 'humps'
import { Map } from 'immutable'
import { createReducer } from '_utils/redux'

import { GET_SERVICE_ORDERS_COUNT_WIDGET, SET_WIDGETS_FILTER, GET_WIDGETS_OPTIONS } from './actions'

const INITIAL_STATE = new Map({
  results: Map(),
  order: Map(),
  filter: Map({
    onlyMe: true,
  }),
  options: undefined,
})

export default createReducer(INITIAL_STATE, {
  [SET_WIDGETS_FILTER]: (state, { payload }) => {
    const { name, value } = payload
    return state.updateIn(['filter', name], () => value)
  },
  [GET_SERVICE_ORDERS_COUNT_WIDGET.FULFILLED]: (state, { payload }) => {
    const formattedPayload = humps.decamelizeKeys(payload)
    return state.setIn(['results', 'serviceOrderCount'], formattedPayload)
  },
  [GET_WIDGETS_OPTIONS.FULFILLED]: (state, { payload }) => {
    return state.set('options', payload)
  },
})
