import React, { useState } from 'react'
import { TextField, Typography } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'

export const InputFieldMask = ({
  label,
  name,
  mask,
  control,
  isEditing,
  required = false,
  placeholder,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      as={
        <InputMask
          mask={mask}
          maskChar=""
          disabled={!isEditing}
          required={required}
          label={<Typography variant="subtitle1">{label}</Typography>}
          placeholder={placeholder}
        >
          {inputProps => {
            return (
              <TextField
                {...inputProps}
                disabled={!isEditing}
                InputLabelProps={{ shrink: true, required: false }}
              />
            )
          }}
        </InputMask>
      }
    />
  )
}

export const InputField = ({
  label,
  name,
  inputRef,
  disabled,
  required = false,
  placeholder = '',
}) => {
  return (
    <TextField
      label={<Typography variant="subtitle1">{label}</Typography>}
      name={name}
      inputRef={inputRef}
      InputLabelProps={{ shrink: true, required: false }}
      required={required}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

export const PhoneInputField = ({
  label,
  name,
  control,
  isEditing,
  required = false,
  placeholder,
}) => {
  const [value, setValue] = useState('')

  return (
    <Controller
      name={name}
      control={control}
      as={
        <InputMask
          mask={value.replaceAll(' ', '').length < 14 ? '(99) 9999-99999' : '(99) 9 9999-9999'}
          maskChar=""
          disabled={!isEditing}
          required={required}
          label={<Typography variant="subtitle1">{label}</Typography>}
          placeholder={placeholder}
          value={value}
          onKeyUpCapture={e => setValue(e.target.value)}
        >
          {inputProps => {
            return (
              <TextField
                {...inputProps}
                disabled={!isEditing}
                InputLabelProps={{ shrink: true, required: false }}
              />
            )
          }}
        </InputMask>
      }
    />
  )
}
