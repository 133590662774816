import { Map, List, fromJS } from 'immutable'
import { normalize } from 'normalizr'

import Classification, { classificationSchema } from '_models/classification'
import { createReducer } from '_utils/redux'
import { getPage } from '_utils/helpers'
import { ORDER_BY_VALUES } from '_utils/constants'

import {
  GET_CLASSIFICATION,
  GET_CLASSIFICATIONS_FOR_SERVICE_ORDER,
  GET_CLASSIFICATION_BY_ID,
  GET_CLASSIFICATION_COLOR,
  GET_CLASSIFICATION_TYPE,
  PATCH_CLASSIFICATION,
} from './actions'

const INITIAL_STATE = new Map({
  resultsClassificationsAgency: new Map(),
  resultsClassificationsServiceOrder: new Map(),
  orderAgency: new Map(),
  orderServiceOrder: new Map(),
  next: '',
  count: '',
  pageSize: 10,
  params: undefined,
  page: 0,
  isAsc: undefined,
  optionsClassificationColor: new List(),
  optionsClassificationType: new List(),
})

export default createReducer(INITIAL_STATE, {
  [GET_CLASSIFICATION.FULFILLED]: (state, { payload, meta }) => {
    const { order } = meta
    const normalizeClassifications = normalize(payload.results, classificationSchema)

    return state
      .set('resultsClassificationsAgency', fromJS(normalizeClassifications.entities.classification))
      .set('orderAgency', normalizeClassifications.result)
      .set('next', getPage(payload.next))
      .set('count', payload.count)
      .set('pageSize', meta.pageSize)
      .set('params', { order: meta.order })
      .set('page', meta.page)
      .set('isAsc', order === ORDER_BY_VALUES.ASC)
  },

  [GET_CLASSIFICATIONS_FOR_SERVICE_ORDER.FULFILLED]: (state, { payload, meta }) => {
    const { order } = meta
    const normalizeClassifications = normalize(payload.results, classificationSchema)

    return state
      .set(
        'resultsClassificationsServiceOrder',
        fromJS(normalizeClassifications.entities.classification)
      )
      .set('orderServiceOrder', normalizeClassifications.result)
      .set('next', getPage(payload.next))
      .set('count', payload.count)
      .set('pageSize', meta.pageSize)
      .set('params', { order: meta.order })
      .set('page', meta.page)
      .set('isAsc', order === ORDER_BY_VALUES.ASC)
  },

  [GET_CLASSIFICATION_BY_ID.FULFILLED]: (state, { payload }) => {
    return state.setIn(
      ['resultsClassificationsAgency', payload?.id?.toString()],
      new Classification(payload)
    )
  },

  [GET_CLASSIFICATION_COLOR.FULFILLED]: (state, { payload }) => {
    return state.set('optionsClassificationColor', new List(payload))
  },

  [GET_CLASSIFICATION_TYPE.FULFILLED]: (state, { payload }) => {
    return state.set('optionsClassificationType', new List(payload))
  },

  [PATCH_CLASSIFICATION.FULFILLED]: (state, { payload }) => {
    const colorOptions = state.get('optionsClassificationColor')?.toJS()
    const typeOptions = state.get('optionsClassificationType')?.toJS()

    const classificationType = typeOptions.find(option => option?.id === payload.classificationType)
    const color = colorOptions.find(option => option?.id === payload.color)

    const data = {
      ...payload,
      classificationType,
      color,
    }

    return state.setIn(
      ['resultsClassificationsAgency', payload?.id?.toString()],
      new Classification(data)
    )
  },
})
