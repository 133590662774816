import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  mainTable: {
    marginTop: '16px',
    width: 'auto',
    height: '650px',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '16px',
  },
  totalValueContainer: {
    backgroundColor: Theme.Colors.Grayscale.Four,
    padding: '12px 16px',
    borderRadius: '8px',
    marginTop: '8px',
  },
  flexDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
