import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

import Modal from '_components/modal'

import useStyles from './styles'

const ViewOriginalDescriptionModal = ({ handleModal, originalDescription }) => {
  const styles = useStyles()

  return (
    <Modal title="Descrição original" handleModal={handleModal} hideActionButtons>
      <Grid className={styles.container}>
        <Typography>Descrição Original:</Typography>
        <Typography>{originalDescription}</Typography>
      </Grid>
    </Modal>
  )
}

ViewOriginalDescriptionModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  originalDescription: PropTypes.string,
}

ViewOriginalDescriptionModal.defaultProps = {
  originalDescription: '',
}

export default React.memo(ViewOriginalDescriptionModal)
