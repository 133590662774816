import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  icon: {
    marginTop: spacing(2),
    width: spacing(16),
    height: spacing(16),
  },
  text: {
    color: Theme.Colors.Grayscale.OneThousand,
    fontSize: spacing(12),
    fontWeight: Theme.typography.fontWeightMedium,
    fontFamily: 'Dm Sans',
    marginLeft: spacing(5),
    padding: 0,
    flex: 1,
  },
  link: {
    color: Theme.Colors.Primary.Base,
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    '&:hover': {
      color: Theme.Colors.Primary.Dark,
    },
  },
}))
