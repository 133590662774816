/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useEffect, useState, useCallback, Suspense } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { redirectTo, useLocation, useParams } from '@reach/router'

import { Grid, Typography, Button } from '@material-ui/core'
import { useMediaQuery } from '@mui/material'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { HeaderAgency } from '_/components/header-agency'
import { userSelector } from '_/modules/authentication/selectors'
import {
  createNewServiceOrderForm,
  getAgencyBySlug,
  getDecrypt,
  getFormAgency,
  updateAttachmentsNewServiceOrderForm,
} from '_/modules/form/actions'
import { handleVerifyAgency } from '../service-form/utils/general'
import { ROUTES } from '_/utils/constants'
import { getCities, getStates } from '_/modules/utils/actions'
import { getDayPeriods } from '_/modules/day-period/actions'
import { getHolidays } from '_/modules/holiday/actions'
import { getBusinessTimes } from '_/modules/businessTime/actions'
import { agencySelector, formPagesSelector } from '_/modules/form/selectors'

import useRolePermission from '_/hooks/use-role-permission'

import {
  DATE_SUGGESTION_OPTIONS,
  DATE_SUGGESTION_OPTIONS_NAMES,
  DATE_SUGGESTION_OPTIONS_TIMES_END,
  DATE_SUGGESTION_OPTIONS_TIMES_START,
} from './pages/date-suggestion/constants'
import {
  ADDRESS_FIELDS,
  CONTACT_FIELDS,
  EXTRA_FIELDS,
  FIELDS,
  FIELD_CATEGORY,
  INVISIBLE_FIELDS,
  LEASE_HOLDER_INFO_FIELDS,
  MONEY_URL_VARIABLES,
  NUMBERS_URL_VARIABLES,
  PAGES_NAME,
  PROPERTY_FIELDS,
  PROPERTY_USER_INFO_FIELDS,
  RS_FIELDS,
  URL_VARIABLES,
  URL_VARIABLES_ABLE_TO_BE_ENCRYPTED,
} from './constants'
import { SkipModal } from './components/date-suggestion-modal'

import { useToast } from '_/hooks/use-toast'
import { useDialog } from '_/hooks/use-dialog'

import { HelpButton } from '_/components/help-requests/HelpButton'
import {
  getRequesterGenericParameters,
  getGenericParametersAgencies,
  getStepStatusOptions,
} from '_/modules/service-orders/actions'
import { GENERIC_PARAMETERS } from '_/utils/constants/service-order'
import MaintenanceIcon from '_/assets/svgs/maintenance.svg'
import { ErrorScreen } from '../error-screens'
import { theme } from '_/utils/material-ui'

import useStyles from './styles'
import moment from 'moment/moment'
import { dayPeriodsSelector } from '_/modules/day-period/selector'
import { MANAGED_BY_OPTIONS } from './options'
import { getCategoriesParams } from '_/modules/categories/actions'
import {
  categoriesParamsSelector,
  getCategoriesLoadingSelector,
} from '_/modules/categories/selectors'
import { includeDDI } from './utils/includeDDI'
import { fieldsToChangeByPage } from './pages/lease-holder-info/constants'
import { getUser } from '_/modules/authentication/actions'
import { Loader } from '@refera/ui-web'

const NewServiceWrapper = React.lazy(() =>
  import('./pages/new-service-wrapper').then(module => ({ default: module.NewServiceWrapper }))
)
const AttachmentField = React.lazy(() =>
  import('./pages/attatchment').then(module => ({ default: module.AttachmentField }))
)

const DetailField = React.lazy(() =>
  import('./pages/detail').then(module => ({ default: module.DetailField }))
)
const CustomFields = React.lazy(() =>
  import('./pages/custom-fields').then(module => ({ default: module.CustomFields }))
)
const Emergency = React.lazy(() =>
  import('./pages/emegency').then(module => ({ default: module.Emergency }))
)

const OwnerProperty = React.lazy(() =>
  import('./pages/owner-property').then(module => ({ default: module.OwnerProperty }))
)
const Address = React.lazy(() =>
  import('./pages/address').then(module => ({ default: module.Address }))
)
const DateSuggestion = React.lazy(() =>
  import('./pages/date-suggestion').then(module => ({ default: module.DateSuggestion }))
)

const InternalInformation = React.lazy(() =>
  import('./pages/internal-information').then(module => ({
    default: module.InternalInformation,
  }))
)
const LeaseHolderInfo = React.lazy(() =>
  import('./pages/lease-holder-info').then(module => ({ default: module.LeaseHolderInfo }))
)

const PropertyDetailField = React.lazy(() =>
  import('./pages/property-details').then(module => ({ default: module.PropertyDetailField }))
)
const PropertyUserInfo = React.lazy(() =>
  import('./pages/property-user-info').then(module => ({ default: module.PropertyUserInfo }))
)
const UserDetails = React.lazy(() =>
  import('./pages/user-details').then(module => ({ default: module.UserDetails }))
)
const Finished = React.lazy(() =>
  import('./pages/finished').then(module => ({ default: module.Finished }))
)

const { IS_EMERGENCY, SERVICE_ORDER_DETAILS, SERVICE_ORDER_DETAILS_PACKAGE, IS_OWNER } = FIELDS

const errorMessageToDecrypted = 'Não foi possível descriptografar o valor'

const Aside = React.lazy(() =>
  import('./components/aside-form-page').then(module => ({ default: module.Aside }))
)
const EmergencyModal = React.lazy(() =>
  import('./pages/emegency/components/emergency-modal').then(module => ({
    default: module.EmergencyModal,
  }))
)

export function NewService() {
  // constants
  const { agencySlug } = useParams()
  const styles = useStyles()
  const { isIntermediary } = useRolePermission()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { search, pathname } = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const formPagesList = useSelector(formPagesSelector)
  const agency = useSelector(agencySelector)
  const categories = useSelector(categoriesParamsSelector)
  const loadingCategory = useSelector(getCategoriesLoadingSelector)
  const personalizedTimeSuggestion = useSelector(dayPeriodsSelector)
  const [validAgency, setValidAgency] = useState()
  const [dataToSend, setDataToSend] = useState({})
  const [objectDecrypted, setObjectDecrypted] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [dateError, setDateError] = useState('')
  const [showInternRefera, setShowInternRefera] = useState(false)

  // FILTER PAGES WITCH NEEDS CUSTOM FIELDS AND THE AGENCY DON'T HAVE CUSTOM FIELDS REGISTERED
  const formPages = useMemo(() => {
    return formPagesList?.filter(
      page =>
        (page?.pageName !== PAGES_NAME.HELP_CLASSIFICATION &&
          page?.pageName !== PAGES_NAME.INTERNAL_INFORMATION) ||
        (page?.pageName === PAGES_NAME.INTERNAL_INFORMATION && Boolean(agency?.pilotAutoTicket)) ||
        page?.customFields?.length > 0
    )
  }, [formPagesList, agency])

  const areValuesEncrypted =
    new URLSearchParams(search).get(URL_VARIABLES.ENCRYPTED)?.toLowerCase() === 'true'

  const [openEmergencyModal, setOpenEmergencyModal] = useState(false)
  const [openSkipModal, setOpenSkipModal] = useState(false)

  const [agencyId, setAgencyId] = useState(null)

  const { showToast } = useToast()
  const { showDialog, closeDialog } = useDialog()

  // Form constants
  const methods = useForm({
    mode: 'all',
    defaultValues: {},
  })

  const {
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = methods

  const [attachments, setAttachments] = useState([])

  const hasMenu = useMemo(() => pathname?.includes(ROUTES.NEW_SERVICE_LOGGED), [pathname, ROUTES])
  const [progress, setProgress] = useState(0)

  // useEffects
  useEffect(() => {
    const fetchData = async () => {
      if (!agencySlug) return

      try {
        const { id } = await dispatch(getAgencyBySlug(agencySlug))
        setAgencyId(id)

        await dispatch(getFormAgency(id))
        dispatch(getStepStatusOptions({ intermediary: true }))

        setIsLoading(false)
      } catch (error) {
        handleVerifyAgency(error, setValidAgency)
        // setIsLoading(false)
        console.error(error)
      }
    }

    fetchData()
  }, [agencySlug, dispatch])

  useEffect(() => {
    if (validAgency === false) {
      redirectTo(ROUTES.LINK_EXPIRED)
    }
  }, [validAgency])

  // Start information to the pages
  useEffect(() => {
    dispatch(
      getRequesterGenericParameters({
        name: GENERIC_PARAMETERS.WHATSAPP_BUTTON,
      })
    )

    dispatch(getCities())
    dispatch(getStates())
    dispatch(getDayPeriods())
    dispatch(getHolidays())
    dispatch(getBusinessTimes())
  }, [dispatch])

  useEffect(() => {
    if (agencyId) {
      dispatch(
        getGenericParametersAgencies({
          name: GENERIC_PARAMETERS.RS_MANTEINANCE_SCREEN,
          agencies: agencyId,
        })
      )
    }
  }, [dispatch, agencyId])

  // GET CATEGORIES WHEN HAS IN CUSTOM FIELDS IN HELP_CLASSIFICATION AND CATEGORIES IS EMPTY
  const getClassificationWhenHasInCustomFields = useCallback(() => {
    if (agency?.id && (categories?.length === 0 || !categories?.length) && !loadingCategory) {
      const checkIfNeedsGetCategories = formPages?.some(
        page =>
          (page?.pageName === PAGES_NAME.HELP_CLASSIFICATION &&
            page?.customFields?.some(field => field?.name === FIELD_CATEGORY)) ||
          (page?.pageName === PAGES_NAME.NEW_SERVICE_WRAPPER && agency?.state === 'RS')
      )

      const params = agency?.state === 'RS' ? { type: 'RS' } : {}

      if (checkIfNeedsGetCategories) {
        dispatch(getCategoriesParams(params))
      }
    }
  }, [dispatch, agency, formPages, categories, loadingCategory])

  useEffect(() => {
    getClassificationWhenHasInCustomFields()
  }, [agency?.id, formPagesList])

  useEffect(() => {
    if (!user?.id) {
      dispatch(getUser())
    }
  }, [dispatch])

  // GET INFO FROM URL
  const convertUrlNameToFieldName = useMemo(() => {
    return Object.keys(FIELDS).reduce((acc, key) => {
      if (URL_VARIABLES[key]) {
        acc[URL_VARIABLES[key]] = FIELDS[key]
      }
      return { ...acc }
    }, {})
  }, [])

  const findNamePageFromUrlKey = useMemo(() => {
    return {
      [PAGES_NAME.SERVICE_ORDER_DETAILS]: { SERVICE_ORDER_DETAILS },
      [PAGES_NAME.IS_EMERGENCY]: { IS_EMERGENCY },
      [PAGES_NAME.IS_OWNER]: { IS_OWNER },
      [PAGES_NAME.PROPERTY_DETAILS]: PROPERTY_FIELDS,
      [PAGES_NAME.OWNER_DETAILS]: PROPERTY_USER_INFO_FIELDS,
      [PAGES_NAME.PROPERTY]: ADDRESS_FIELDS,
      [PAGES_NAME.REQUEST_CONTACT]: CONTACT_FIELDS,
      [PAGES_NAME.INVISIBLE]: INVISIBLE_FIELDS,
    }
  }, [])

  const searchParams = search.split('&').map(param => param.split('='))
  // CONVERT ENCRYPTED VALUES TO DECRYPTED
  const convertEncryptedValuesToDecrypted = useCallback(() => {
    const objectToConvert = Object.values(URL_VARIABLES_ABLE_TO_BE_ENCRYPTED).reduce(
      (acc, valueObjectToConvert) => {
        const getValueFromParams = searchParams.find(
          param => param[0] === valueObjectToConvert
        )?.[1]

        if (getValueFromParams) {
          acc[valueObjectToConvert] = getValueFromParams
        }

        return { ...acc }
      },
      {}
    )

    dispatch(
      getDecrypt({
        ...objectToConvert,
        agencySlug,
      })
    ).then(decryptedValues => {
      const objectDecryptedWithoutErrors = Object.keys(decryptedValues).reduce((acc, key) => {
        if (decryptedValues[key] !== errorMessageToDecrypted) {
          acc[key] = decryptedValues[key]
        }
        return { ...acc }
      }, {})

      setObjectDecrypted(objectDecryptedWithoutErrors)
    })
  }, [dispatch, search])

  // FUNCTION TO UPDATE FIELDS
  const updateValueFromUrl = useCallback(() => {
    const listOfKeys = Object.values(URL_VARIABLES)

    const urlSearchParams = new URLSearchParams(search)

    // get all params from url
    return urlSearchParams.forEach((value, key) => {
      // check if the param is in the URL_VARIABLES
      if (listOfKeys?.includes(key)) {
        // get the field name from the URL_VARIABLES
        const newValueKey = convertUrlNameToFieldName?.[key]
        let newValue = areValuesEncrypted && objectDecrypted?.[key] ? objectDecrypted?.[key] : value

        // check witch screen the parameter value belong
        const indexOfThePage = Object.values(findNamePageFromUrlKey)?.findIndex(pageProperties =>
          Object.values(pageProperties).includes(newValueKey)
        )

        if (Object.values(NUMBERS_URL_VARIABLES)?.includes(key)) {
          newValue = includeDDI(newValue)
        }

        if (Object.values(MONEY_URL_VARIABLES)?.includes(key)) {
          newValue = parseFloat(newValue?.replace(',', '.'))
        }

        const namePage = Object.keys(findNamePageFromUrlKey)[indexOfThePage]

        // set the param on the screen name
        setDataToSend(state => ({
          ...state,
          [namePage]: {
            ...state?.[namePage],
            [newValueKey]: newValue,
          },
        }))
      }
    })
  }, [
    search,
    dataToSend,
    convertUrlNameToFieldName,
    findNamePageFromUrlKey,
    formPages,
    agencySlug,
    objectDecrypted,
  ])

  useEffect(() => {
    if (areValuesEncrypted) {
      convertEncryptedValuesToDecrypted()
    }
  }, [search])

  useEffect(() => {
    if (Boolean(search) && formPages?.length !== 0 && Object.keys(dataToSend).length === 0) {
      updateValueFromUrl()
    }
  }, [search, formPages, objectDecrypted])

  // COMPONENTS TO RENDER
  const stepContent = useMemo(() => {
    return {
      [PAGES_NAME.NEW_SERVICE_WRAPPER]: (
        <NewServiceWrapper
          handleNext={handleGoToNextStep}
          dataToSend={dataToSend?.[PAGES_NAME.NEW_SERVICE_WRAPPER]}
          showInternRefera={showInternRefera}
          setShowInternRefera={setShowInternRefera}
        />
      ),
      [PAGES_NAME.ATTACHMENTS]: (
        <AttachmentField
          attachments={attachments}
          setAttachments={setAttachments}
          alert={formPages?.[progress]?.alert}
        />
      ),
      [PAGES_NAME.SERVICE_ORDER_DETAILS]: (
        <DetailField dataToSend={dataToSend} alert={formPages?.[progress]?.alert} />
      ),
      [PAGES_NAME.HELP_CLASSIFICATION]: (
        <CustomFields
          alert={formPages?.[progress]?.alert}
          customFields={formPages?.[progress]?.customFields}
          dataToSend={dataToSend?.[PAGES_NAME.HELP_CLASSIFICATION]}
        />
      ),
      [PAGES_NAME.IS_EMERGENCY]: (
        <Emergency
          alert={formPages?.[progress]?.alert}
          isEmergencyValue={dataToSend?.[PAGES_NAME.IS_EMERGENCY]?.[IS_EMERGENCY]}
        />
      ),
      [PAGES_NAME.IS_OWNER]: (
        <OwnerProperty
          isOwnerValue={dataToSend?.[PAGES_NAME.IS_OWNER]?.[IS_OWNER]}
          alert={formPages?.[progress]?.alert}
        />
      ),
      [PAGES_NAME.LEASE_HOLDER_INFO]: (
        <LeaseHolderInfo
          agencyId={agencyId}
          dataToSend={dataToSend?.[PAGES_NAME.LEASE_HOLDER_INFO]}
          setDataToSend={setDataToSend}
          clientType={dataToSend?.[PAGES_NAME.IS_OWNER]?.clientType}
          alert={formPages?.[progress]?.alert}
        />
      ),
      [PAGES_NAME.PROPERTY_DETAILS]: (
        <PropertyDetailField
          dataToSend={dataToSend?.[PAGES_NAME.PROPERTY_DETAILS]}
          alert={formPages?.[progress]?.alert}
          customFields={formPages?.[progress]?.customFields}
        />
      ),
      [PAGES_NAME.OWNER_DETAILS]: (
        <PropertyUserInfo
          dataToSend={dataToSend?.[PAGES_NAME.OWNER_DETAILS]}
          alert={formPages?.[progress]?.alert}
        />
      ),
      [PAGES_NAME.INTERNAL_INFORMATION]: (
        <InternalInformation
          alert={formPages?.[progress]?.alert}
          customFields={formPages?.[progress]?.customFields}
          dataToSend={dataToSend?.[PAGES_NAME.INTERNAL_INFORMATION]}
          pilotAutoTicket={agency?.pilotAutoTicket}
        />
      ),
      [PAGES_NAME.PROPERTY]: (
        <Address
          dataToSend={dataToSend?.[PAGES_NAME.PROPERTY]}
          alert={formPages?.[progress]?.alert}
        />
      ),
      [PAGES_NAME.DATE_SUGGESTION]: (
        <DateSuggestion
          dataToSend={dataToSend?.[PAGES_NAME.DATE_SUGGESTION]}
          alert={formPages?.[progress]?.alert}
          isEmergencyValue={dataToSend?.[PAGES_NAME.IS_EMERGENCY]?.[IS_EMERGENCY]}
          error={dateError}
        />
      ),
      [PAGES_NAME.REQUEST_CONTACT]: (
        <UserDetails
          dataToSend={dataToSend?.[PAGES_NAME.REQUEST_CONTACT]}
          alert={formPages?.[progress]?.alert}
          isOwner={dataToSend?.is_owner}
        />
      ),
      [PAGES_NAME.CONCLUDED]: <Finished />,
    }
  }, [attachments, dataToSend, formPages, progress, agency, handleGoToNextStep, dateError])

  // Transform options data in custom data
  const formatDateToSend = useCallback(
    data => {
      if (data) {
        return Object.keys(data)?.reduce((acc, date) => {
          if (data?.[date] !== 'customized' && typeof data?.[date] === 'number') {
            const newPeriod = personalizedTimeSuggestion?.find(
              period => period?.id === data?.[date]
            )

            const findIndexOfField = DATE_SUGGESTION_OPTIONS.findIndex(
              teste => teste?.period === date
            )

            acc[DATE_SUGGESTION_OPTIONS_TIMES_START[findIndexOfField]] = newPeriod?.startTime
            acc[DATE_SUGGESTION_OPTIONS_TIMES_END[findIndexOfField]] = newPeriod?.endTime
          }

          if (DATE_SUGGESTION_OPTIONS_NAMES?.includes(date)) {
            const dateFormatted = moment(data?.[date]).format('yyyy-MM-DD')
            acc[date] = dateFormatted
          }

          if (
            [
              ...DATE_SUGGESTION_OPTIONS_TIMES_START,
              ...DATE_SUGGESTION_OPTIONS_TIMES_END,
            ]?.includes(date)
          ) {
            const timeFormatted = moment(data?.[date]).format('hh:mm:ss')
            acc[date] = timeFormatted
          }

          return { ...acc }
        }, {})
      }

      return null
    },
    [dataToSend]
  )

  function formatDetails(data) {
    // concat message if the user is from RS state and has previously selected a service package
    const detailsConcat = data?.[PAGES_NAME.SERVICE_ORDER_DETAILS]?.[SERVICE_ORDER_DETAILS_PACKAGE]
      ? `${data?.[PAGES_NAME.SERVICE_ORDER_DETAILS]?.[SERVICE_ORDER_DETAILS_PACKAGE]}\n\n${
          data?.[PAGES_NAME.SERVICE_ORDER_DETAILS]?.[SERVICE_ORDER_DETAILS]
        }`
      : data?.[PAGES_NAME.SERVICE_ORDER_DETAILS]?.[SERVICE_ORDER_DETAILS]
    return { [SERVICE_ORDER_DETAILS]: detailsConcat }
  }

  function handleCategoriesToSend(data) {
    // if the user is from RS state, the category will be in the new_service_wrapper page
    return data?.[PAGES_NAME.NEW_SERVICE_WRAPPER]?.[RS_FIELDS.CATEGORY]
      ? {
          ...data?.[PAGES_NAME.HELP_CLASSIFICATION],
          [FIELD_CATEGORY]:
            data?.[PAGES_NAME.NEW_SERVICE_WRAPPER]?.[RS_FIELDS.CATEGORY]?.name ===
            'Outra categoria / problema'
              ? []
              : [data?.[PAGES_NAME.NEW_SERVICE_WRAPPER]?.[RS_FIELDS.CATEGORY]?.id],
        }
      : {
          ...data?.[PAGES_NAME.HELP_CLASSIFICATION],
          [FIELD_CATEGORY]: data?.[PAGES_NAME.HELP_CLASSIFICATION]?.[FIELD_CATEGORY]
            ? [data?.[PAGES_NAME.HELP_CLASSIFICATION]?.[FIELD_CATEGORY]]
            : [],
        }
  }

  // CREATE NEW SERVICE ORDER
  const handleCreateServiceOrder = useCallback(
    async data => {
      setIsSubmitting(true)
      const dateFormatted = formatDateToSend(data?.[PAGES_NAME.DATE_SUGGESTION])
      const detailsFormatted = formatDetails(data)
      const categoriesFormatted = handleCategoriesToSend(data)

      dispatch(
        createNewServiceOrderForm({
          agency: agencyId,
          [PAGES_NAME.PROPERTY]: {
            ...data?.[PAGES_NAME.PROPERTY],
            ...data?.[PAGES_NAME.OWNER_DETAILS],
            ...data?.[PAGES_NAME.PROPERTY_DETAILS],
          },
          ...detailsFormatted,
          ...categoriesFormatted,
          ...dateFormatted,
          ...data?.[PAGES_NAME.IS_OWNER],
          ...data?.[PAGES_NAME.IS_EMERGENCY],
          ...data?.[PAGES_NAME.INTERNAL_INFORMATION],
          ...data?.[PAGES_NAME.REQUEST_CONTACT],
          ...data?.[PAGES_NAME.INVISIBLE],
          ...data?.[PAGES_NAME.PROPERTY_DETAILS],
        })
      )
        .then(res => {
          if (attachments.length > 0) {
            return dispatch(updateAttachmentsNewServiceOrderForm(res?.uuid, { attachments }))
              .then(() => {
                setIsSubmitting(false)
                return setProgress(PAGES_NAME.CONCLUDED)
              })
              .catch(() => {
                setIsSubmitting(false)
                showToast({
                  type: 'error',
                  message:
                    'Ops! Seu chamado foi criado, mas ocorreu um erro ao salvar os anexos, entre em contato com o suporte!',
                })
                return setProgress(PAGES_NAME.CONCLUDED)
              })
          }
          setIsSubmitting(false)
          return setProgress(PAGES_NAME.CONCLUDED)
        })
        .catch(() => {
          setIsSubmitting(false)
          return showToast({
            type: 'error',
            message: 'Ops! Erro ao enviar o seu chamado, tente novamente!',
          })
        })
    },
    [attachments, dataToSend, dispatch, agencyId, formatDateToSend, handleCategoriesToSend]
  )

  const fillLHIRelatedFormData = useCallback(
    submitData => {
      const clientPageKeys = {
        lessee_client: PAGES_NAME.REQUEST_CONTACT,
        property_owner_client: PAGES_NAME.OWNER_DETAILS,
      }

      let clientPageKey = null

      if (
        Object.keys(clientPageKeys).includes(
          submitData?.[LEASE_HOLDER_INFO_FIELDS.LHI_FORM_RELATED_DATA]?.lastUsedClientType
        )
      ) {
        // if the user is not an intermediary user, the client page to be filled with the client data
        // will always be the lessee_client page because the OWNER_DETAILS page is not shown to the user
        // so no matter what the lastUsedClientType is, the client data page will be the REQUEST_CONTACT page
        clientPageKey = isIntermediary
          ? clientPageKeys[
              submitData?.[LEASE_HOLDER_INFO_FIELDS.LHI_FORM_RELATED_DATA]?.lastUsedClientType
            ]
          : clientPageKeys.lessee_client
      }

      if (!clientPageKey) return

      const clientData = {
        ...submitData?.[LEASE_HOLDER_INFO_FIELDS.LHI_FORM_RELATED_DATA]?.integrationData
          ?.clientData,
      }
      const propertyData = { ...submitData?.[LEASE_HOLDER_INFO_FIELDS.LHI_PROPERTY] }
      const contractData = { ...submitData?.[LEASE_HOLDER_INFO_FIELDS.LHI_CONTRACT] }
      const ownerData = {
        ...submitData?.[LEASE_HOLDER_INFO_FIELDS.LHI_PROPERTY]?.ownerDetails,
      }

      if (contractData?.[PROPERTY_FIELDS.PROPERTY_CONTRACT_START_DATE]) {
        const parsedContractStartDate = moment(
          contractData[PROPERTY_FIELDS.PROPERTY_CONTRACT_START_DATE],
          'DD/MM/YYYY'
        )?.format('YYYY-MM-DD')

        if (parsedContractStartDate)
          contractData[PROPERTY_FIELDS.PROPERTY_CONTRACT_START_DATE] = parsedContractStartDate
        else delete contractData[PROPERTY_FIELDS.PROPERTY_CONTRACT_START_DATE]
      }

      setDataToSend(prev => {
        const payload = {
          ...prev,
          [PAGES_NAME.LEASE_HOLDER_INFO]: submitData,
          [clientPageKey]: {
            ...prev?.[clientPageKey],
            ...fieldsToChangeByPage[clientPageKey].reduce((acc, field) => {
              acc[field] = clientData?.[field]
              return acc
            }, {}),
          },
          [PAGES_NAME.PROPERTY]: {
            ...prev?.[PAGES_NAME.PROPERTY],
            ...fieldsToChangeByPage[PAGES_NAME.PROPERTY].reduce((acc, field) => {
              acc[field] = propertyData?.[field]
              return acc
            }, {}),
          },
          [PAGES_NAME.PROPERTY_DETAILS]: {
            ...prev?.[PAGES_NAME.PROPERTY_DETAILS],
            ...fieldsToChangeByPage[PAGES_NAME.PROPERTY_DETAILS].reduce((acc, field) => {
              if (field === PROPERTY_FIELDS.PROPERTY_CODE) {
                const { contractCode, ctrSeq, ctrDV } = contractData
                if (contractCode && ctrSeq && ctrDV)
                  acc[
                    PROPERTY_FIELDS.PROPERTY_CODE
                  ] = `${contractData.contractCode}-${contractData.ctrSeq}-${contractData.ctrDV}`
                else acc[field] = undefined
              } else acc[field] = contractData?.[field]
              return acc
            }, {}),
          },
        }

        if (Object.keys(ownerData).length > 0)
          payload[PAGES_NAME.OWNER_DETAILS] = {
            ...payload?.[PAGES_NAME.OWNER_DETAILS],
            ...fieldsToChangeByPage[PAGES_NAME.OWNER_DETAILS].reduce((acc, field) => {
              acc[field] = ownerData?.[field]
              return acc
            }, {}),
          }

        return payload
      })

      setProgress(state => state + 1)
    },
    [isIntermediary]
  )

  const handleSubmitLeaseHolderInfoStep = useCallback(
    submitData => {
      const isPropertyDataEmpty = !Object.keys(submitData[LEASE_HOLDER_INFO_FIELDS.LHI_PROPERTY])
        .length
      const isContractDataEmpty = !Object.keys(submitData[LEASE_HOLDER_INFO_FIELDS.LHI_CONTRACT])
        .length

      if (isPropertyDataEmpty || isContractDataEmpty) {
        const targetMessage = isPropertyDataEmpty ? ' IMÓVEL e' : ''
        showDialog({
          type: 'warning',
          subject: `Você tem certeza que não quer definir o${targetMessage} CONTRATO junto à intermediária?`,
          description:
            'Se preencheres, o seu atendimento será mais rápido, já que teremos todos os dados necessários para as análises e contatos relacionados.',
          onApprove: () => {
            fillLHIRelatedFormData(submitData)
            closeDialog()
          },
          labelApprove: 'Sim',
          labelCancel: 'Não',
          onCancel: () => {
            closeDialog()
          },
        })
        return
      }

      fillLHIRelatedFormData(submitData)
    },
    [fillLHIRelatedFormData]
  )

  const handleDatesValidation = useCallback(data => {
    let isValid = true

    const firstDate = moment(data.firstSuggestionDate)
    const secondDate = moment(data.secondSuggestionDate)
    const dateSelected = moment(data.thirdSuggestionDate)

    if (dateSelected.isSame(firstDate, 'day') && dateSelected.isSame(secondDate, 'day')) {
      showToast({
        type: 'error',
        message: 'Você não pode escolher três dias de sugestão iguais.',
      })
      setDateError('equalDates')
      isValid = false
    }

    const isSamePeriod = (date1, period1, date2, period2) =>
      date1.isSame(date2, 'day') && period1 === period2

    if (
      isSamePeriod(dateSelected, data.thirdPeriod, firstDate, data.firstPeriod) ||
      isSamePeriod(dateSelected, data.thirdPeriod, secondDate, data.secondPeriod) ||
      isSamePeriod(firstDate, data.firstPeriod, secondDate, data.secondPeriod)
    ) {
      showToast({
        type: 'error',
        message:
          'Você está inserindo sugestões idênticas. Revise suas sugestões (datas e horários) e tente novamente.',
      })
      isValid = false
    }
    return isValid
  }, [])

  // FUNCTIONS TO CHANGE THE COMPONENT TO SHOW ON THE SCREEN
  function handleGoToNextStep() {
    const formValues = getValues()

    if (formPages?.[progress].pageName === PAGES_NAME.ATTACHMENTS && showInternRefera) {
      setShowInternRefera(false)
    }

    if (formPages?.[progress].pageName === PAGES_NAME.DATE_SUGGESTION) {
      const isValid = handleDatesValidation(formValues)
      if (!isValid) return
    }

    if (formPages?.[progress].pageName === PAGES_NAME.LEASE_HOLDER_INFO) {
      handleSubmitLeaseHolderInfoStep(formValues)
      return
    }

    const newData = {
      ...dataToSend,
      [formPages?.[progress]?.pageName]: formValues,
    }
    setDataToSend(newData)

    const isEmergency = watch(IS_EMERGENCY)

    if (formPages?.[progress].pageName && isEmergency === 'true') {
      setOpenEmergencyModal(true)
      return
    }

    if (progress === formPages?.length - 1) {
      handleSubmit(handleCreateServiceOrder(newData))
      return
    }

    setProgress(state => state + 1)
  }

  const handleGoBackStep = useCallback(() => {
    if (formPages?.[progress - 1].pageName === PAGES_NAME.DATE_SUGGESTION) {
      setDateError('')
    }
    if (progress === 0) {
      return
    }

    const formValues = getValues()
    setDataToSend(prev => ({
      ...prev,
      [formPages?.[progress]?.pageName]: formValues,
    }))

    setProgress(state => state - 1)
  }, [progress, getValues])

  const handleClickIsEmergencyModal = useCallback(() => {
    setOpenEmergencyModal(false)

    if (progress === formPages?.length - 1) {
      return handleSubmit(handleCreateServiceOrder(dataToSend))
    }

    return setProgress(state => state + 1)
  }, [progress, getValues, dataToSend, formPages])

  const handleSkipModal = useCallback(() => {
    setOpenSkipModal(false)

    if (progress === formPages?.length - 1) {
      return handleSubmit(handleCreateServiceOrder(dataToSend))
    }

    return setProgress(state => state + 1)
  }, [progress, getValues, dataToSend, formPages])

  // RULES TO NOT PERMIT CHANGE PAGE
  const rulesToDisableNextPageFromObject = useCallback(
    objectName => {
      return Object.keys(objectName)
        .map(key => {
          const value = objectName[key]
          if (value === ADDRESS_FIELDS.COMPLEMENT) {
            return false
          }
          return Boolean(errors?.[value]?.message) || !watch(value)
        })
        .some(value => value === true)
    },
    [watch]
  )

  // RULES TO NOT PERMIT CHANGE SUGGEST DATE PAGE
  const rulesToDisableSuggestDateNextPage = useMemo(() => {
    return DATE_SUGGESTION_OPTIONS.map(date => {
      return (
        (watch(date.period) === 'customized' && (!watch(date.startTime) || !watch(date.endTime))) ||
        !watch(date.name) ||
        !watch(date.period) ||
        Boolean(errors?.[date.name]?.message) ||
        Boolean(errors?.[date.period]?.message) ||
        Boolean(errors?.[date.startTime]?.message) ||
        Boolean(errors?.[date.endTime]?.message)
      )
    }).some(value => value === true)
  }, [watch, errors])

  // RULES TO NOT PERMIT CHANGE PROPERTY DETAILS PAGE
  const rulesToDisablePropertyDetailsNextPage = useMemo(() => {
    return (
      (watch(PROPERTY_FIELDS.PROPERTY_ACTIVE) === 'false' &&
        !watch(PROPERTY_FIELDS.PROPERTY_LOCATION)) ||
      !watch(PROPERTY_FIELDS.PROPERTY_PROPERTY_CODE) ||
      !watch(PROPERTY_FIELDS.PROPERTY_ACTIVE) ||
      Boolean(errors?.[PROPERTY_FIELDS.PROPERTY_ACTIVE]?.message) ||
      Boolean(errors?.[PROPERTY_FIELDS.PROPERTY_PROPERTY_CODE]?.message) ||
      Boolean(errors?.[PROPERTY_FIELDS.PROPERTY_ACTIVE]?.message)
    )
  }, [watch, errors])

  // RULES TO NOT PERMIT CHANGE ADDRESS PAGE
  const rulesToDisableExtraFieldsNextPage = useCallback(
    objectName => {
      const customFields = formPages?.find(page => page?.pageName === objectName)

      if (customFields) {
        return customFields?.customFields?.some(field => {
          return !watch(field.name) || Boolean(errors?.[field.name]?.message)
        })
      }

      return false
    },
    [watch, formPages]
  )

  // RULES TO NOT PERMIT CHANGE ADDRESS PAGE
  const rulesToDisableInternalFieldsNextPage = useCallback(() => {
    const customFields = formPages?.find(page => page?.pageName === PAGES_NAME.INTERNAL_INFORMATION)

    let checkCustomFields = false
    let checkOthersFields = false

    if (customFields) {
      checkCustomFields = customFields?.customFields?.some(field => {
        return (
          (!watch(field.name) || Boolean(errors?.[field.name]?.message)) &&
          field.name !== EXTRA_FIELDS.PRE_APPROVED_VALUE
        )
      })
    }

    if (agency?.pilotAutoTicket) {
      checkOthersFields =
        !watch(EXTRA_FIELDS.MANAGED_BY) ||
        Boolean(errors?.[EXTRA_FIELDS.MANAGED_BY]?.message) ||
        (watch(EXTRA_FIELDS.MANAGED_BY) === MANAGED_BY_OPTIONS[1].value &&
          (!watch(EXTRA_FIELDS.SERVICE_ORDER_TYPE) ||
            Boolean(errors?.[EXTRA_FIELDS.SERVICE_ORDER_TYPE]?.message)))
    }

    return checkCustomFields || checkOthersFields
  }, [watch, formPages, agency])

  const rulesToDisableDetailsPage = () => {
    const servicePackage = dataToSend?.[PAGES_NAME.NEW_SERVICE_WRAPPER]?.[RS_FIELDS.SERVICE_PACKAGE]

    if (servicePackage?.description && servicePackage?.type !== 'other') {
      return !watch(SERVICE_ORDER_DETAILS_PACKAGE)
    }
    return !watch(SERVICE_ORDER_DETAILS)
  }

  // RULES TO NOT PERMIT CHANGE LEASE HOLDER PAGE
  const rulesToDisableKSINextPage = useMemo(() => {
    const clientCpfCnpjError = errors?.[LEASE_HOLDER_INFO_FIELDS.CLIENT_CPF_CNPJ]

    if (clientCpfCnpjError?.type === 'manual') {
      return false
    }

    const lhiFormRelatedData = watch(LEASE_HOLDER_INFO_FIELDS.LHI_FORM_RELATED_DATA)
    const hasIntegrationData =
      lhiFormRelatedData?.integrationData &&
      Object.keys(lhiFormRelatedData.integrationData).length > 0

    return (
      !watch(LEASE_HOLDER_INFO_FIELDS.CLIENT_CPF_CNPJ) ||
      !hasIntegrationData ||
      (hasIntegrationData &&
        (!watch(LEASE_HOLDER_INFO_FIELDS.LHI_PROPERTY) ||
          !watch(LEASE_HOLDER_INFO_FIELDS.LHI_CONTRACT))) ||
      Boolean(clientCpfCnpjError?.message) ||
      Boolean(errors?.[LEASE_HOLDER_INFO_FIELDS.LHI_PROPERTY]?.message) ||
      Boolean(errors?.[LEASE_HOLDER_INFO_FIELDS.LHI_CONTRACT]?.message) ||
      Boolean(errors?.[LEASE_HOLDER_INFO_FIELDS.LHI_FORM_RELATED_DATA]?.message)
    )
  }, [watch, errors])

  // RULES TO NOT PERMIT CHANGE PAGE
  const rulesToDisableNextPage = useMemo(() => {
    return {
      [PAGES_NAME.ATTACHMENTS]: false,
      [PAGES_NAME.SERVICE_ORDER_DETAILS]: rulesToDisableDetailsPage(),
      [PAGES_NAME.HELP_CLASSIFICATION]: rulesToDisableExtraFieldsNextPage(
        PAGES_NAME.HELP_CLASSIFICATION
      ),
      [PAGES_NAME.IS_EMERGENCY]: !watch(IS_EMERGENCY),
      [PAGES_NAME.IS_OWNER]: !watch(IS_OWNER),
      [PAGES_NAME.PROPERTY_DETAILS]: rulesToDisablePropertyDetailsNextPage,
      [PAGES_NAME.OWNER_DETAILS]: rulesToDisableNextPageFromObject(PROPERTY_USER_INFO_FIELDS),
      [PAGES_NAME.INTERNAL_INFORMATION]: rulesToDisableInternalFieldsNextPage(),
      [PAGES_NAME.PROPERTY]: rulesToDisableNextPageFromObject(ADDRESS_FIELDS),
      [PAGES_NAME.DATE_SUGGESTION]: rulesToDisableSuggestDateNextPage,
      [PAGES_NAME.REQUEST_CONTACT]: rulesToDisableNextPageFromObject(CONTACT_FIELDS),
      [PAGES_NAME.LEASE_HOLDER_INFO]: rulesToDisableKSINextPage,
    }
  }, [
    watch,
    errors,
    rulesToDisableSuggestDateNextPage,
    rulesToDisableNextPageFromObject,
    rulesToDisableExtraFieldsNextPage,
    rulesToDisableInternalFieldsNextPage,
    rulesToDisableDetailsPage,
    rulesToDisableKSINextPage,
  ])

  // Texts to skip modal
  const textSkipModal = useMemo(() => {
    return {
      [PAGES_NAME.ATTACHMENTS]: null,
      [PAGES_NAME.SERVICE_ORDER_DETAILS]: null,
      [PAGES_NAME.HELP_CLASSIFICATION]: null,
      [PAGES_NAME.IS_EMERGENCY]: null,
      [PAGES_NAME.IS_OWNER]: null,
      [PAGES_NAME.PROPERTY_DETAILS]: null,
      [PAGES_NAME.OWNER_DETAILS]:
        'Você tem certeza que deseja pular de etapa sem preencher os dados do proprietário?',
      [PAGES_NAME.INTERNAL_INFORMATION]: null,
      [PAGES_NAME.PROPERTY]: null,
      [PAGES_NAME.DATE_SUGGESTION]:
        'Você tem certeza que deseja pular de etapa sem preencher as sugestões de data?',
      [PAGES_NAME.REQUEST_CONTACT]: null,
    }
  }, [watch, errors, rulesToDisableSuggestDateNextPage, rulesToDisableNextPageFromObject])

  // Titles Page
  const titlePage = useMemo(() => {
    return {
      [PAGES_NAME.ATTACHMENTS]: 'Adicione vídeos e fotos do problema',
      [PAGES_NAME.SERVICE_ORDER_DETAILS]: 'Vamos detalhar o seu pedido?',
      [PAGES_NAME.HELP_CLASSIFICATION]: 'Nos ajude a classificar a sua solicitação!',
      [PAGES_NAME.IS_EMERGENCY]: 'O problema é uma EMERGÊNCIA?',
      [PAGES_NAME.IS_OWNER]: 'Você é inquilino(a) do imóvel?',
      [PAGES_NAME.LEASE_HOLDER_INFO]:
        'Por favor, insira os dados de quem tem o contrato de locação assinado com a empresa.',
      [PAGES_NAME.PROPERTY_DETAILS]: 'Informações sobre o imóvel.',
      [PAGES_NAME.OWNER_DETAILS]: 'Informe alguns dados do proprietário do imóvel.',
      [PAGES_NAME.INTERNAL_INFORMATION]: 'Informações internas sobre a solicitação',
      [PAGES_NAME.PROPERTY]: 'Em qual endereço você precisa de atendimento?',
      [PAGES_NAME.DATE_SUGGESTION]:
        'Caso seja necessário, qual o melhor dia e horário para o prestador de serviço realizar uma visita para montar o orçamento?',
      [PAGES_NAME.REQUEST_CONTACT]: isIntermediary
        ? 'Informe os dados do cliente'
        : 'Informe seus dados para contato',
    }
  }, [isIntermediary])

  if (
    formPages?.length === 0 ||
    formPages?.find(page => page?.pageName === PAGES_NAME.MAINTENANCE_FORM)
  ) {
    const maintenancePage = formPages?.find(page => page?.pageName === PAGES_NAME.MAINTENANCE_FORM)

    return (
      <ErrorScreen
        title={maintenancePage?.pageDesc ?? 'O sistema está temporariamente em manutenção.'}
        date={maintenancePage?.date}
        logo={MaintenanceIcon}
        fullscreen={!user?.id}
        size={!isMobileScreen && '690px'}
      />
    )
  }

  if (isLoading) {
    return <Loader hasBackdrop open={isLoading} label="Aguarde..." />
  }

  if (formPages?.[progress]?.pageName === PAGES_NAME.NEW_SERVICE_WRAPPER) {
    return (
      <Suspense fallback={<Loader hasBackdrop label="Aguarde..." />}>
        <FormProvider {...methods}>
          {stepContent[PAGES_NAME.NEW_SERVICE_WRAPPER]}
          <HelpButton />
        </FormProvider>
      </Suspense>
    )
  }

  // render page
  return (
    <Suspense fallback={<Loader hasBackdrop label="Aguarde..." />}>
      <Grid className={styles.app}>
        {!hasMenu && agency?.brandLogoPngUrl && (
          <HeaderAgency>
            <img
              src={agency?.brandLogoPngUrl}
              alt="Logo da intermediária"
              className={styles.logo}
            />
          </HeaderAgency>
        )}

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(handleCreateServiceOrder)}
            className={
              progress === PAGES_NAME.CONCLUDED ? styles.containerConcluded : styles.container
            }
          >
            <Grid className={styles.box}>
              {titlePage?.[formPages?.[progress]?.pageName] && progress !== formPages.length && (
                <Grid className={styles.boxTitle}>
                  {formPages?.[progress]?.pageName === PAGES_NAME.DATE_SUGGESTION && (
                    <Typography className={styles.title}>Estamos quase lá!</Typography>
                  )}
                  <Typography className={styles.title}>
                    {titlePage?.[formPages?.[progress]?.pageName]}
                  </Typography>
                </Grid>
              )}
              {progress === PAGES_NAME.CONCLUDED
                ? stepContent[PAGES_NAME.CONCLUDED]
                : stepContent[formPages?.[progress]?.pageName]}

              <Grid className={styles.buttonsField}>
                <Grid className={styles.buttons}>
                  {formPages?.[progress]?.skipButton && (
                    <Button
                      fullWidth
                      className={styles.dropButton}
                      color="primary"
                      variant="outlined"
                      endIcon={<ArrowForwardIosIcon className={styles.nextIcon} />}
                      onClick={() => setOpenSkipModal(true)}
                      disableElevation
                    >
                      Pular
                    </Button>
                  )}
                  {progress !== 0 && progress < formPages.length && (
                    <Button
                      fullWidth
                      className={styles.backButton}
                      variant="outlined"
                      disableElevation
                      onClick={handleGoBackStep}
                      disabled={isSubmitting}
                    >
                      Voltar
                    </Button>
                  )}
                  {progress < formPages?.length - 1 && (
                    <Button
                      fullWidth
                      className={styles.button}
                      color="primary"
                      variant="outlined"
                      endIcon={<ArrowForwardIosIcon className={styles.nextIcon} />}
                      onClick={handleGoToNextStep}
                      disabled={
                        rulesToDisableNextPage[formPages?.[progress]?.pageName] || isSubmitting
                      }
                      disableElevation
                      // type="submit"
                    >
                      Continuar
                    </Button>
                  )}

                  {progress === formPages?.length - 1 && (
                    <Button
                      fullWidth
                      className={styles.button}
                      color="primary"
                      variant="contained"
                      onClick={handleGoToNextStep}
                      disableElevation
                      disabled={
                        rulesToDisableNextPage[formPages?.[progress]?.pageName] || isSubmitting
                      }
                      isLoading={isSubmitting}
                    >
                      Enviar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {progress !== PAGES_NAME.CONCLUDED && <Aside />}

            <EmergencyModal
              onClick={handleClickIsEmergencyModal}
              openEmergencyModal={openEmergencyModal}
              setOpenEmergencyModal={setOpenEmergencyModal}
            />

            <SkipModal
              text={textSkipModal[formPages?.[progress]?.pageName]}
              onClick={handleSkipModal}
              openModal={openSkipModal}
              setOpenModal={setOpenSkipModal}
            />
          </form>
        </FormProvider>
      </Grid>

      <Loader hasBackdrop open={isSubmitting} label="Aguarde..." />
      <HelpButton />
    </Suspense>
  )
}
