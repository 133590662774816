/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import generalInputStyles from '../styles'
import checkboxStyles from './styles'

/**
 * Checkbox component that uses Material-UI Checkbox and React Hook Form Controller.
 * @param {Object} props - The props object.
 * @param {string} props.name - The name of the input. (Hook-Form related)
 * @param {string} props.label - The label of the input.
 * @param {boolean} [props.defaultValue=false] - The default value of the input.
 * @param {Object} [props.style={}] - The style object to be applied to the component.
 * @param {string} [props.containerClasses] - The classes to be applied to the container element.
 * @returns {JSX.Element} - The Checkbox component.
 */
function Checkbox({
  name,
  label,
  defaultValue = false,
  style = {},
  containerClasses,
  required = false,
}) {
  const generalStyles = generalInputStyles()
  const { control, setValue } = useFormContext()
  const localStyles = checkboxStyles()

  useEffect(() => {
    setValue(name, defaultValue)
  }, [defaultValue, name, setValue])

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', ...style }}
      className={classNames(generalStyles.formControl, containerClasses ?? null)}
    >
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={name}
            defaultValue={!!defaultValue}
            render={props => (
              <MUICheckbox
                {...props}
                color="primary"
                checked={props.value}
                onChange={e => props.onChange(e.target.checked)}
              />
            )}
          />
        }
        label={label}
        className={required ? localStyles.required : null}
      />
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  style: PropTypes.object,
  containerClasses: PropTypes.string,
}

Checkbox.defaultProps = {
  defaultValue: false,
  style: {},
  containerClasses: null,
}

export default Checkbox
