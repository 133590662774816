import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { DatePicker, MoneyInput } from '@refera/ui-web'

import useStyles from './styles'

import { useForm } from 'react-hook-form'
import { formatCurrency } from '_/utils/helpers'
import moment from 'moment'
import { useToast } from '_/hooks/use-toast'
import { useDispatch } from 'react-redux'
import { updatePaymentInstallment, updatePaymentReceivable } from '_/modules/finance/actions'
import { useParams } from '@reach/router'
import { formatErrorMessage } from '../../utils/FormatErrorMessage'
import { round } from '../../utils/functions'
import { CommonModal } from './CommonModal'
import Select from '_components/common/select'
import { METHOD_PAYMENT_OPTIONS_SELECT } from '../../manage-installments/utils/constants'
import { InconsistentInstallmentModal } from './InconsistentInstallmentModal'

export const EditPaymentModal = props => {
  const dispatch = useDispatch()
  const [amountPaid, setAmountPaid] = useState(props?.installmentInfo?.amountPaid || 0)
  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { installmentId, budgetReceivableId } = useParams()

  const screen = useMemo(
    () => (budgetReceivableId ? 'receipt' : 'installment'),
    [budgetReceivableId]
  )

  const defaultValues = useMemo(() => {
    const values = {}
    props?.installmentInfo?.forEach(payment => {
      const { name, value } = payment
      values[name] = value
    })
    return values
  }, [props?.installmentInfo])

  const methods = useForm({
    defaultValues,
    mode: 'all',
  })

  const { register, errors, setError, clearErrors, handleSubmit, setValue, watch } = methods
  const formData = watch()

  const { showToast } = useToast()
  const styles = useStyles({ errors })

  const calculateAmountPaid = () => {
    // Anticipation has a default when opening from receipts screen
    const { anticipationTax = 0, discount, additionValue, interest, fine } = formData
    const total =
      props?.installmentValue + additionValue + fine + interest - discount - anticipationTax

    setAmountPaid(round(total, 2))
  }

  const renderRequiredErrorMessage = useMemo(
    () => fieldName => {
      if (errors?.[fieldName]) {
        return (
          <Typography role="alert" className={styles.errorMessage}>
            {errors?.[fieldName]?.message}
          </Typography>
        )
      }
      return null
    },
    [errors]
  )

  const handleValidateDate = () => {
    const invalidDateMessage = 'Insira uma data válida'

    try {
      if (!formData?.paymentDate) return 'Este campo é obrigatório'

      const momentValue = moment(formData?.paymentDate)
      if (!momentValue?.isValid()) return invalidDateMessage

      clearErrors('paymentDate')
      return true
    } catch {
      return invalidDateMessage
    }
  }

  const handleInputType = useMemo(
    () => paymentItem => {
      const { label, type, name } = paymentItem

      switch (type) {
        case 'date':
          return (
            <>
              <Typography>{label}</Typography>
              <DatePicker
                variant="inline"
                mask="__/__/____"
                format="dd/MM/yyyy"
                defaultValue={null}
                refuse={/[^\d\\.]+/gi}
                placeholder="DD/MM/AAAA"
                className={styles.datePicker}
                value={formData[name] || null}
                {...register(name, { validate: handleValidateDate })}
                onChange={newValue => setValue(name, newValue)}
              />
              {renderRequiredErrorMessage(name)}
            </>
          )
        case 'money':
          return (
            <>
              <Typography>{label}</Typography>
              <MoneyInput
                fullWidth
                defaultValue={0}
                decimalScale={2}
                {...register(name)}
                placeholder="R$ 0,00"
                style={{ fontSize: '16px' }}
                onBlur={calculateAmountPaid}
                className={styles.listInput}
                value={formatCurrency(formData[name])}
                onChange={e => setValue(name, e.floatValue || 0)}
              />
            </>
          )
        case 'money-readonly':
          return <></>

        case 'formOfPayment':
          return (
            <Grid>
              <Typography>{label}</Typography>
              <Select
                label=""
                required
                defaultValue=""
                value={formData[name]}
                name="formOfPayment"
                onChange={e => setValue(name, e.target.value || '')}
                options={METHOD_PAYMENT_OPTIONS_SELECT}
                selectStyles={styles.select}
                {...register(name)}
              />
            </Grid>
          )

        default:
          return null
      }
    },
    [formData, errors, handleValidateDate]
  )

  const onSubmit = async ({ inconsistentInstallmentType = null }) => {
    const payload = { ...formData, amountPaid }

    if (moment(payload.paymentDate)?.isValid()) {
      if (amountPaid < 0) {
        showToast({ type: 'error', message: 'O valor total deve ser igual ou maior que zero.' })
        return
      }

      payload.paymentDate = moment(payload.paymentDate).format('YYYY-MM-DD')
    } else {
      setError('paymentDate', '')
    }

    setLoading(true)

    if (inconsistentInstallmentType) setDialogOpen(false)
    if (screen === 'installment') payload.inconsistentInstallmentType = inconsistentInstallmentType

    const action = screen === 'receipt' ? updatePaymentReceivable : updatePaymentInstallment
    const payloadId = screen === 'receipt' ? budgetReceivableId : installmentId

    await dispatch(action(payloadId, payload))
      .then(response => {
        setLoading(false)
        props?.onClose()

        if (response) {
          showToast({ type: 'info', message: response })
        } else {
          showToast({ type: 'success' })
        }
      })
      .catch(error => {
        const errorMessage = formatErrorMessage(error)
        setLoading(false)

        if (errorMessage.includes('inconsistência')) {
          setDialogOpen(true)
        } else {
          showToast({ type: 'error', message: errorMessage })
        }
      })
  }

  useEffect(() => {
    handleValidateDate()
  }, [formData?.paymentDate])

  useEffect(() => {
    calculateAmountPaid()
  }, [])

  return (
    <CommonModal
      {...props}
      onSubmit={handleSubmit(onSubmit)}
      handleInputType={handleInputType}
      loading={loading}
      modalType="payment"
    >
      <Grid className={styles.totalValueContainer}>
        <Typography className={styles.headerText}>Valor pago</Typography>
        <Typography className={styles.totalValue}>{formatCurrency(amountPaid)}</Typography>
      </Grid>

      <InconsistentInstallmentModal
        open={dialogOpen}
        close={() => {
          setDialogOpen(false)
          props?.onClose()
        }}
        onSubmit={onSubmit}
      />
    </CommonModal>
  )
}
