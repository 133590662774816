import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  header: {
    display: 'flex',
    height: spacing(72),
    background: palette.primary.main,
    padding: spacing(0, 26.67),
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'null',
  },
  logo: {
    width: spacing(105),
    height: spacing(38),
  },
}))
