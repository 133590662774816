/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Dialog, DialogContent, DialogActions } from '@mui/material'
import QuestionIcon from '_assets/svgs/question-circle-blue.svg'
import { Button } from '@refera/ui-web'

import useStyles from './styles'
import Svg from '_/components/svg'

export const InconsistentInstallmentModal = ({ open, close, onSubmit }) => {
  const styles = useStyles()
  return (
    <Dialog icon={QuestionIcon} open={open}>
      <div className={styles.dialogContainer}>
        <div className={styles.iconWrapper}>
          <Svg icon={QuestionIcon} className={styles.iconModal} />
        </div>
        <DialogContent container className={styles.wrapper}>
          <span className={styles.description}>
            Há inconsistência entre o status da parcela e da antecipação. <br />O que deseja fazer?
          </span>
        </DialogContent>
        <DialogActions className={styles.dialogActionWrapper}>
          <div className={styles.actionButtons}>
            <Button
              className={styles.button}
              onClick={() => onSubmit({ inconsistentInstallmentType: 'pending' })}
              color="primary"
              variant="secondary"
            >
              Parcela e Antecipação pendentes
            </Button>
            <Button
              className={styles.button}
              onClick={() => onSubmit({ inconsistentInstallmentType: 'paid' })}
              color="primary"
              variant="secondary"
            >
              Parcela e Antecipação pagas
            </Button>
          </div>
          <Button className={styles.button} onClick={close} color="red" variant="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
