import { TEXTFIELD_MASK } from '_components/textfield'
import { CLIENT_TYPE, CLIENT_TYPE_LABEL } from '_utils/user'

export const SETUP_FORM_FIELDS = {
  ADDRESS: 'address',
  ATTACHMENTS: 'attachments',
  CEP: 'cep',
  CLIENT_TYPE: 'clientType',
  COMPLEMENT: 'complement',
  CONTACT_CPF_CNPJ: 'contactCpfCnpj',
  CONTACT_EMAIL: 'contactEmail',
  CONTACT_NAME: 'contactName',
  CONTACT_NUMBER: 'contactNumber',
  DESCRIPTION: 'description',
  EXTRA_ADDRESS_INFO: 'complement',
  ID: 'id',
  IS_CONTRACT_ACTIVE: 'isContractActive',
  KEY_LOCATION: 'propertyKeysLocation',
  LISTING_ID: 'listingId',
  NUMBER: 'number',
  CITY: 'city',
  NEIGHBORHOOD: 'neighborhood',
  CODE: 'code',
  UF: 'uf',
}

const SETUP_FORM_FIELDS_LABELS = {
  [SETUP_FORM_FIELDS.CLIENT_TYPE]: 'Tipo do cliente',
  [SETUP_FORM_FIELDS.CONTACT_NAME]: 'Meu nome é',
  [SETUP_FORM_FIELDS.CONTACT_EMAIL]: 'E o meu e-mail é',
  [SETUP_FORM_FIELDS.CONTACT_NUMBER]: 'Se precisar, meu telefone é',
  [SETUP_FORM_FIELDS.CONTACT_CPF_CNPJ]: 'E o meu CPF/CNPJ é',
  [SETUP_FORM_FIELDS.CEP]: 'O CEP do meu imóvel é',
  [SETUP_FORM_FIELDS.ADDRESS]: 'O endereço é',
  [SETUP_FORM_FIELDS.NUMBER]: 'Número',
  [SETUP_FORM_FIELDS.EXTRA_ADDRESS_INFO]: 'Complemento*',
  [SETUP_FORM_FIELDS.DESCRIPTION]: 'Descrição',
  [SETUP_FORM_FIELDS.LISTING_ID]: 'Código do imóvel',
  [SETUP_FORM_FIELDS.IS_CONTRACT_ACTIVE]: 'O contrato está ativo?',
  [SETUP_FORM_FIELDS.KEY_LOCATION]: 'Qual a localização da chave?',
  [SETUP_FORM_FIELDS.CITY]: 'Cidade',
  [SETUP_FORM_FIELDS.NEIGHBORHOOD]: 'Bairro',
  [SETUP_FORM_FIELDS.CODE]: 'Código do imóvel',
}

export const OPTIONAL_STAFF_FIELDS = [
  SETUP_FORM_FIELDS.CONTACT_NAME,
  SETUP_FORM_FIELDS.CONTACT_EMAIL,
  SETUP_FORM_FIELDS.CONTACT_NUMBER,
  SETUP_FORM_FIELDS.CONTACT_CPF_CNPJ,
]

export const INITIAL_STATE = {
  [SETUP_FORM_FIELDS.CLIENT_TYPE]: '',
  [SETUP_FORM_FIELDS.CONTACT_NAME]: '',
  [SETUP_FORM_FIELDS.CONTACT_EMAIL]: '',
  [SETUP_FORM_FIELDS.CONTACT_NUMBER]: '',
  [SETUP_FORM_FIELDS.CONTACT_CPF_CNPJ]: '',
  [SETUP_FORM_FIELDS.CEP]: '',
  [SETUP_FORM_FIELDS.ADDRESS]: '',
  [SETUP_FORM_FIELDS.NUMBER]: '',
  [SETUP_FORM_FIELDS.COMPLEMENT]: '',
  [SETUP_FORM_FIELDS.DESCRIPTION]: '',
  [SETUP_FORM_FIELDS.ATTACHMENTS]: [],
  [SETUP_FORM_FIELDS.STAFF_CONTACT_NAME]: '',
  [SETUP_FORM_FIELDS.LISTING_ID]: '',
  [SETUP_FORM_FIELDS.IS_CONTRACT_ACTIVE]: '',
  [SETUP_FORM_FIELDS.KEY_LOCATION]: '',
  [SETUP_FORM_FIELDS.CITY]: '',
  [SETUP_FORM_FIELDS.NEIGHBORHOOD]: '',
  [SETUP_FORM_FIELDS.CODE]: '',
}

const STEPS = {
  TENANT0: `${CLIENT_TYPE.TENANT}0`,
  TENANT1: `${CLIENT_TYPE.TENANT}1`,
  TENANT2: `${CLIENT_TYPE.TENANT}2`,
  STAFF0: `${CLIENT_TYPE.STAFF}0`,
  STAFF1: `${CLIENT_TYPE.STAFF}1`,
  STAFF2: `${CLIENT_TYPE.STAFF}2`,
  STAFF3: `${CLIENT_TYPE.STAFF}3`,
}

const TRUE_FALSE_SELECT_OPTIONS = [
  { value: 'true', label: 'Sim' },
  { value: 'false', label: 'Não' },
]

export const FIRST_STEP = {
  title: 'Como a gente pode falar com você?',
  inputs: [
    {
      name: SETUP_FORM_FIELDS.CLIENT_TYPE,
      label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CLIENT_TYPE],
      options: Object.values(CLIENT_TYPE).map(value => ({
        value,
        label: `Sou ${CLIENT_TYPE_LABEL[value]?.toLocaleLowerCase()}`,
      })),
    },
  ],
}

const LAST_STEP = {
  title: 'E pra finalizar, conta mais sobre os seus problemas',
  inputs: [
    {
      name: SETUP_FORM_FIELDS.DESCRIPTION,
      label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.DESCRIPTION],
      isMultiline: true,
    },
    {
      name: SETUP_FORM_FIELDS.ATTACHMENTS,
      isPictureInput: true,
    },
  ],
}

export const TENANT_FORM = {
  [STEPS.TENANT0]: {
    title: FIRST_STEP.title,
    inputs: [
      ...FIRST_STEP.inputs,
      {
        name: SETUP_FORM_FIELDS.CONTACT_NAME,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CONTACT_NAME],
      },
      {
        name: SETUP_FORM_FIELDS.CONTACT_EMAIL,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CONTACT_EMAIL],
      },
      {
        name: SETUP_FORM_FIELDS.CONTACT_NUMBER,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CONTACT_NUMBER],
        mask: TEXTFIELD_MASK.PHONE_NUMBER,
      },
      {
        name: SETUP_FORM_FIELDS.CONTACT_CPF_CNPJ,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CONTACT_CPF_CNPJ],
        isCpfCnpj: true,
      },
    ],
  },
  [STEPS.TENANT1]: {
    title: 'Vamos começar: conta um pouco sobre o seu imóvel',
    inputs: [
      {
        name: SETUP_FORM_FIELDS.CEP,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CEP],
        mask: TEXTFIELD_MASK.CEP,
      },
      {
        name: SETUP_FORM_FIELDS.ADDRESS,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.ADDRESS],
        helperText: 'Logradouro',
      },
      {
        name: SETUP_FORM_FIELDS.NUMBER,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.NUMBER],
      },
      {
        name: SETUP_FORM_FIELDS.EXTRA_ADDRESS_INFO,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.EXTRA_ADDRESS_INFO],
        helperText: '*Opcional',
      },
      {
        name: SETUP_FORM_FIELDS.NEIGHBORHOOD,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.NEIGHBORHOOD],
      },
      {
        name: SETUP_FORM_FIELDS.CITY,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CITY],
      },
    ],
  },
  [STEPS.TENANT2]: LAST_STEP,
  [STEPS.STAFF0]: {
    title: 'Vamos começar: conta um pouco sobre a situação do imóvel',
    inputs: [
      ...FIRST_STEP.inputs,
      {
        name: SETUP_FORM_FIELDS.CODE,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CODE],
      },
      {
        name: SETUP_FORM_FIELDS.IS_CONTRACT_ACTIVE,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.IS_CONTRACT_ACTIVE],
        options: TRUE_FALSE_SELECT_OPTIONS,
      },
    ],
  },
  [STEPS.STAFF1]: {
    title: 'Dados do Cliente',
    isOptional: true,
    inputs: [
      {
        name: SETUP_FORM_FIELDS.CONTACT_NAME,
        label: 'Nome',
      },
      {
        name: SETUP_FORM_FIELDS.CONTACT_EMAIL,
        label: 'Email',
      },
      {
        name: SETUP_FORM_FIELDS.CONTACT_NUMBER,
        label: 'Telefone',
        mask: TEXTFIELD_MASK.PHONE_NUMBER,
      },
      {
        name: SETUP_FORM_FIELDS.CONTACT_CPF_CNPJ,
        label: 'CPF/CNPJ',
        isCpfCnpj: true,
      },
    ],
  },
  [STEPS.STAFF2]: {
    title: 'Localização do imóvel',
    inputs: [
      {
        name: SETUP_FORM_FIELDS.CEP,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CEP],
        mask: TEXTFIELD_MASK.CEP,
      },
      {
        name: SETUP_FORM_FIELDS.ADDRESS,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.ADDRESS],
        helperText: 'Logradouro, bairro e cidade',
      },
      {
        name: SETUP_FORM_FIELDS.NUMBER,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.NUMBER],
      },
      {
        name: SETUP_FORM_FIELDS.EXTRA_ADDRESS_INFO,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.EXTRA_ADDRESS_INFO],
        helperText: '*Opcional',
      },
      {
        name: SETUP_FORM_FIELDS.NEIGHBORHOOD,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.NEIGHBORHOOD],
      },
      {
        name: SETUP_FORM_FIELDS.CITY,
        label: SETUP_FORM_FIELDS_LABELS[SETUP_FORM_FIELDS.CITY],
      },
    ],
  },
  [STEPS.STAFF3]: LAST_STEP,
}
