import React, { useEffect, useState, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { getCategoryList } from '_/modules/service-orders/actions'
import Button from '_/components/button'
import useModal from '_hooks/use-toggle'

import Accordion from '../../index'
import AccordionInfo from '../../accordion-info'

import AddCategoryModal from '_components/modal/add-category-modal'
import useStyles from '../../styles'

const AddCategories = ({ isProvider, isEditing, setValue, data }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [categoryList, setCategoryList] = useState()
  const [modalOpen, handleModal] = useModal()

  const parsedData = useMemo(() => {
    const sortById = []
    data?.forEach(item => sortById.push(item?.id))

    return sortById
  }, [data, categoryList])

  const handleSubmit = async formValues => {
    const payload = []

    await formValues.forEach(item => {
      const filteredCategory = categoryList.find(category => category?.id === item)

      if (filteredCategory) {
        payload.push({ id: filteredCategory?.id, name: filteredCategory?.name, icon: null })
      }
    })

    setValue(payload)
  }

  const handleDeleteCategory = catObj => {
    setValue(data.filter(currentCategory => currentCategory.id !== catObj.id))
  }

  useEffect(() => {
    dispatch(getCategoryList({})).then(res => setCategoryList(res))
  }, [])

  const handleAddCategory = () => {
    handleModal()
  }

  return (
    <Accordion
      id="categorias-da-empresa-1"
      title="Categorias de manutenção"
      className={styles.containerAdd}
    >
      <Grid className={styles.userListAdd}>
        {data
          ?.sort((x, y) => (x.name < y.name ? -1 : 1))
          ?.map((category, index) => (
            <AccordionInfo
              // eslint-disable-next-line react/no-array-index-key
              key={category.value}
              isFirst={index === 0}
              type="category"
              values={category}
              isProvider={isProvider}
              isEditing={isEditing}
              handleDeleteValue={handleDeleteCategory}
            />
          ))}
      </Grid>
      {isEditing && (
        <Button
          className={styles.buttonAdd}
          variant="contained"
          color="primary"
          onClick={handleAddCategory}
        >
          +Adicionar registro
        </Button>
      )}
      {modalOpen && (
        <AddCategoryModal
          value={parsedData}
          patchCategories={handleSubmit}
          handleModal={handleModal}
          title="Categoria de manutenção"
          tradesmanCategories={data}
        />
      )}
    </Accordion>
  )
}

export default AddCategories
