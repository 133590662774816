import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  title: {
    color: '#1671ED',
    cursor: 'move',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '40px',
  },
  form: {
    padding: spacing(32, 32, 32),
  },
  radioGroupStyles: {
    gap: '56px',
  },
  labelInputRadioButton: {
    marginLeft: '0px',
    marginRight: '0px',
    gap: '10px',
  },
  dialog: {
    maxheight: '900px',
    minHeight: '68%',
  },
  dialogAction: {
    padding: '20px 30px',
  },
  required: {
    marginLeft: '5px',
    color: 'red',
    fontSize: '16px',
  },
  textTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    fontWeight: '500',
    fontSize: '16px',
    color: 'black',
  },
  textInput: {
    display: 'block',
    width: '100%',
    height: '132px',
    fontSize: 16,
  },
  warrantyGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  warrantyMonths: {
    width: '132px',
  },
  errorMessage: {
    fontSize: spacing(12),
    color: Theme.Colors.Red.Base,
    zIndex: 2,
    marginTop: spacing(4),
  },
}))
