import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  mainTable: {
    marginTop: '16px',
    width: 'auto',
    height: '616px',
  },
  grayColumn: {
    backgroundColor: Theme.Colors.Grayscale.Four,
  },
  idColumn: {
    backgroundColor: Theme.Colors.Grayscale.Four,
    color: Theme.Colors.Primary.Base,
  },
  buttonsContainer: {
    display: 'flex',
    gap: '16px',
  },
}))
