import { defineAction } from '_utils/redux'
import * as menuItemServices from '_services/menu-items'

export const GET_MENU_ITEMS = defineAction('GET_MENU_ITEMS')

export const getMenuItems = () => async (dispatch, getState) => {
  const data = await menuItemServices.getRoleMenuItems(getState().user.authToken)
  dispatch({
    type: GET_MENU_ITEMS.ACTION,
    payload: async () => data,
  })
  return data
}
