import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

const CustomAccordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '8px 0',
  '&::before': {
    display: 'none',
  },
}))

const AccordionTitle = styled(props => <Typography {...props} />)(() => ({
  color: '#0943A6',
  fontSize: '24px !important',
  fontWeight: 'bold !important',
  lineHeight: '105% !important',

  '@media (max-width: 640px)': {
    fontSize: '18px !important',
  },
}))

const AccordionDescription = styled(props => <Typography {...props} />)(() => ({
  fontSize: '16px !important',
  lineHeight: '18px !important',
}))

const AccordionSummary = styled(props => <MuiAccordionSummary {...props} />)(() => ({
  flexDirection: 'row-reverse',
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}))

export function CustomizedAccordions({ title, description, open, expanded }) {
  return (
    <div>
      <CustomAccordion expanded={expanded} onChange={open}>
        <AccordionSummary>
          <AccordionTitle>{title}</AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionDescription>{description}</AccordionDescription>
        </AccordionDetails>
      </CustomAccordion>
    </div>
  )
}
