import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  titleBlue: {
    color: '#1671ED',
    cursor: 'move',
  },
  dialog: {
    maxheight: '900px',
    minHeight: '68%',
  },
  container: {
    display: 'flex',
    width: '800px',
    maxWidth: '800px',
    height: '100%',
    padding: '35px',
    flexDirection: 'column',
    borderTop: '2px solid #E3E3E3',
    overflowX: 'hidden',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  dialogAction: {
    padding: '20px 30px',
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0 8px 8px 0',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  button: {
    height: '40px',
    width: '140px',
    padding: '10px 16.5px',
  },
  footer: {
    padding: '30px 30px 25px 0px',
  },
  icon: {
    fontSize: '22px',
  },
  labels: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '10px',
  },
  subjectWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
  },
  subjectField: {
    display: 'flex',
    width: '100%',
    fontSize: '20px',
    color: '#B4B4B4',
    paddingBottom: '6px',
  },
  textField: {
    fontSize: '20px',
    height: '152px',
  },
  messageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid #E3E3E3',
  },
  messageTitle: {
    fontWeight: '600',
    fontSize: '19px',
    color: '#141414',
    marginBottom: '12px',
  },
  messageBody: {
    fontWeight: '400',
    fontSize: '16px',
    color: '#696969',
    marginBottom: '12px',
    width: '94%',
    overflowWrap: 'break-word',
  },
  messageFooter: {
    fontWeight: '400',
    fontSize: '16px',
    color: '#B4B4B4',
  },
  answerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '20px',
    paddingBottom: '20px',
    width: '100%',
    position: 'relative',
  },
  answerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  trashContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '36px',
    position: 'absolute',
    right: '0',
    top: '-10px',
  },
  checkbox: {
    position: 'absolute',
    bottom: '3%',
  },
  checkboxLabel: {
    fontSize: '16px',
  },
  trashIcon: {
    width: '24px',
    height: '24px',
  },
}))
