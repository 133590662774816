// GENERAL CHECKS FOR FAQ AND ARTICLE

export const filterFaqs = (item, user, pathname, userOnly = false) => {
  const isRightType = item.type === 'frequently_asked_questions'
  const isRightScreen = item.screens.some(screen =>
    screen?.route === '/' ? pathname === screen?.route : pathname?.includes(screen?.route)
  )

  const isRightUser =
    (isRightType &&
      item?.roles?.some(role => role === user?.getRoles || user?.getRoles?.includes(role))) ||
    (isRightType && item?.roles.includes(user?.getRoles))

  if (userOnly) {
    return isRightType && isRightUser && item.screens.length < 1
  }

  return isRightType && isRightUser && isRightScreen
}

export const filterArticles = (item, user, pathname, filterByUserOnly = false) => {
  const isRightType = item.type === 'suggested_article'
  const isRightScreen = item.screens.some(screen =>
    screen?.route === '/' ? pathname === screen?.route : pathname?.includes(screen?.route)
  )

  const isRightUser =
    (isRightType &&
      item?.roles?.some(role => role === user?.getRoles || user?.getRoles?.includes(role))) ||
    (isRightType && item?.roles.includes(user?.getRoles))

  if (filterByUserOnly) {
    return isRightType && isRightUser && item.screens.length < 1
  }

  return isRightType && isRightUser && isRightScreen
}
