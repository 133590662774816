import React, { useMemo } from 'react'
import {
  Select as MUISelect,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Priority, { PRIORITY_VALUES } from '../priority'

const Select = ({
  options,
  flowsOptions,
  isFlowsOptionField,
  budgetTemplate,
  isBudgetTemplateField,
  regions,
  label,
  helperText,
  name,
  className,
  emptyValue,
  IconComponent,
  isPriorityOptions,
  agencyWorker = false,
  selectedCategories,
  error,
  labelProps,
  ...otherProps
}) => {
  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  return (
    <FormControl fullWidth className={className} error={error}>
      <InputLabel id={`${name}-${label}`} {...labelProps}>
        {label}
      </InputLabel>
      <MUISelect
        MenuProps={menuProps}
        labelId={`${name}-${label}`}
        label={label}
        name={name?.toString()}
        displayEmpty={!!emptyValue.length}
        IconComponent={IconComponent}
        {...otherProps}
      >
        {!!emptyValue.length && <MenuItem value="">{emptyValue}</MenuItem>}
        {options?.map(option =>
          selectedCategories?.find(x => x?.label === option?.label) ? (
            <MenuItem value={option.label} key={option?.value} {...option} disabled>
              {option?.label}
            </MenuItem>
          ) : (
            <MenuItem
              style={{ minHeight: 25 }}
              disabled={
                agencyWorker &&
                option?.value !== 'agency_worker_client' &&
                option?.value !== 'false' &&
                option?.value !== 'true'
              }
              value={option?.classificationName ? option?.id : option?.label}
              key={option?.value}
              {...option}
            >
              {option?.classificationName ? option?.classificationName : option?.label}
            </MenuItem>
          )
        )}
        {isFlowsOptionField &&
          flowsOptions.length &&
          flowsOptions?.map(flow => (
            <MenuItem
              style={{ minHeight: 25 }}
              value={flow?.name ? flow?.name : flow?.label}
              key={flow?.name}
              {...flow}
            >
              {flow?.description ? flow?.description : flow?.label}
            </MenuItem>
          ))}
        {isBudgetTemplateField &&
          budgetTemplate?.map(template => (
            <MenuItem style={{ minHeight: 25 }} value={template.id} key={template.id}>
              {template.name}
            </MenuItem>
          ))}
        {regions?.map(region => (
          <MenuItem key={region} value={region}>
            {region}
          </MenuItem>
        ))}
        {isPriorityOptions &&
          !options.length &&
          Object.values(PRIORITY_VALUES).map(option => (
            <MenuItem value={option} key={option}>
              <Priority value={option} />
            </MenuItem>
          ))}
      </MUISelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  flowsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
      label: PropTypes.string,
    })
  ),
  budgetTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.number,
    })
  ),
  isBudgetTemplateField: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  emptyValue: PropTypes.string,
  IconComponent: PropTypes.shape({ viewBox: PropTypes.string }),
  isPriorityOptions: PropTypes.bool,
  isFlowsOptionField: PropTypes.bool,
  error: PropTypes.bool,
  labelProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.objectOf(PropTypes.string)])
  ),
}

Select.defaultProps = {
  helperText: '',
  className: '',
  emptyValue: '',
  label: '',
  options: [],
  flowsOptions: [],
  budgetTemplate: [],
  IconComponent: ExpandMoreIcon,
  isPriorityOptions: false,
  isFlowsOptionField: false,
  isBudgetTemplateField: false,
  error: false,
  labelProps: {},
}

export default React.memo(Select)
