import { useState, useCallback } from 'react'

const useToggle = (initialMode = false) => {
  const [value, setValue] = useState(initialMode)
  const handleValue = useCallback(() => {
    setValue(prevState => !prevState)
  }, [])

  return [value, handleValue]
}

export default useToggle
