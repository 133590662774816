import React from 'react'

import * as Modal from '_components/modal/generic-modal'

export function SkipModal({ openModal, setOpenModal, text = null, onClick }) {
  return (
    <>
      <Modal.Root open={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Content>
          <Modal.WarningIcon />
          <Modal.Title text="Pular etapa" />
          <Modal.Text>
            {text ??
              'Você tem certeza que deseja pular de etapa sem preencher os campos desta página?'}
          </Modal.Text>
        </Modal.Content>

        <Modal.Actions>
          <Modal.ButtonRed onClick={() => setOpenModal(false)}>Não</Modal.ButtonRed>
          <Modal.ButtonFullBlue onClick={onClick}>Sim</Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>
    </>
  )
}
