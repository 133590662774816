import { post, get, patch, put } from './requests'

export const login = credentials => post(['login'], { transformPayload: true }, credentials)

export const getUser = key => get(['user'], { key, transformPayload: true })

export const getVerificationToken = key =>
  get(['verification_token'], { key, transformPayload: true })

export const updateUser = key => payload => {
  return patch(['user'], { key }, payload)
}
export const resetPassword = payload =>
  post(['password', 'reset', 'confirm'], { transformPayload: true }, payload)

export const authenticateToken = payload =>
  post(['auth', 'token'], { transformPayload: true }, payload)

export const accessHistory = (key, payload) => post(['access-history'], { key }, payload)

export const pageAccessControl = (key, payload) =>
  put(['access-history', 'page_access_control'], { key, transformPayload: true }, payload)

export const passwordRecover = payload =>
  post(['password-reset'], { transformPayload: true }, payload)

export const disableApplication = () => get(['disable_applications'], { transformPayload: true })
