import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: spacing(35),
    paddingBottom: spacing(24),
  },
  inputsWrapper: {
    display: 'grid',
    gridTemplateColumns: `${spacing(20)} auto ${spacing(104)}`,
    gridColumnGap: spacing(8),
    alignItems: 'center',
    marginBottom: spacing(16),
  },
  inputsWrapperRemove: {
    gridTemplateColumns: `${spacing(20)} auto ${spacing(104)} ${spacing(24)}`,
  },
  removeButton: {
    cursor: 'pointer',
  },
  removeIcon: {
    width: spacing(24),
    height: spacing(24),
  },
  labelWrapper: {
    display: 'grid',
    gridTemplateColumns: `auto ${spacing(104)}`,
    gridColumnGap: spacing(8),
    alignItems: 'center',
    marginBottom: spacing(12),
  },
  totalWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderTop: `1px solid ${palette.gray[12]}`,
    marginTop: spacing(13),
  },
  label: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: palette.gray[64],
  },
  input: {
    '& .MuiOutlinedInput-input': {
      padding: spacing(10, 12),
      fontSize: spacing(14),
      lineHeight: spacing(20),
      color: palette.gray[80],
    },
  },
  icon: {
    width: spacing(10.67),
    height: spacing(10.67),
    fill: palette.gray[64],
    marginRight: spacing(6),
  },
  fieldset: {
    display: 'grid',
    padding: spacing(0, 10),
    gridTemplateColumns: '1fr 1fr',
    marginBottom: spacing(12),
    alignItems: 'center',
    '& .MuiFormLabel-root': {
      color: palette.gray[64],
    },
  },
  radioGroup: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, ${spacing(100)})`,
  },
  originalChecked: {
    color: '#000 !important',
  },
  originalDisabled: {
    color: '#000 !important',
  },
  disabledChecked: {
    color: `${palette.primary.main} !important`,
  },
  disabled: {
    color: `${palette.gray[32]} !important`,
  },
  contentDescriptions: {
    height: spacing(158),
    paddingRight: spacing(5),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: spacing(5),
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: spacing(2),
      backgroundColor: palette.gray[32],
    },
  },
  attachments: {
    borderTop: `1px solid ${palette.gray[12]}`,
    marginTop: spacing(13),
    padding: spacing(24, 0),
    overflow: 'hidden',
    maxWidth: 659,
  },
  title: {
    paddingBottom: spacing(20),
  },
  paymentResponsible: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: spacing(15),
    padding: spacing(16, 16, 20),
    border: `${spacing(1)} solid ${palette.gray[12]}`,
    borderRadius: spacing(4),
  },
  picturesContainer: {
    gridTemplateColumns: `repeat(auto-fill, ${spacing(74)})`,
  },
  percentField: {
    width: '100px',
  },
}))
