import { get, post, patch, del } from './requests'
import humps from 'humps'
import { createFormDataDocuments } from '_utils/helpers'

export const getMessagesByServiceOrderId = (key, serviceOrderId, params) =>
  get(['serviceorders', serviceOrderId, 'get-messages'], { key, params })

export const getMessageOptions = key => get(['messages', 'get-options'], { key })

export const getMessageSubjectOptions = key => params =>
  get(['messages', 'get-subject-options'], { key, params })

export const createMessage = (key, payload) =>
  post(
    ['messages'],
    { key, transformOnlyResponse: true, formData: true },
    createFormDataDocuments(payload)
  )

export const createHelpRequest = (key, payload) =>
  post(
    ['helprequests'],
    { key, transformOnlyResponse: true, formData: true },
    createFormDataDocuments(payload)
  )

export const updateMessage = (key, messageId, payload) =>
  patch(
    ['messages', messageId],
    { key, transformOnlyResponse: true, formData: true },
    createFormDataDocuments(payload)
  )

export const deleteMessage = (key, messageId) => del(['messages', messageId], { key })

export const getRatingRequester = (key, payload) =>
  get(['serviceorder-rating-requester', payload], { key }, payload)

export const createApplicantValuation = (key, serviceOrderId, payload) =>
  post(['serviceorders', serviceOrderId, 'serviceorders-rating-requester'], { key }, payload)

export const getAllNotes = key => params =>
  get(['notes', 'get-notes'], { key, params: humps.decamelizeKeys(params) })

export const updateNote = key => (noteId, payload) =>
  patch(['notes', noteId], { key, transformPayload: true }, payload)

export const deleteMessageAttachment = key => (messageId, attachmentId) =>
  patch(['messages', messageId, 'delete-attachment'], { key }, { attachmentId })

export const deletePaymentAnticipation = (key, messageId) =>
  del(['payment_anticipation', messageId], { key })
