import { createMuiTheme } from '@material-ui/core/styles'

import palette from '_styles/palette'

export const theme = createMuiTheme({
  palette,
  typography: {
    fontFamily: "'Roboto', sans-serif",
    pxToRem: size => `${size / 10}rem`,
    htmlFontSize: 16,
    h1: {
      fontSize: '3.2rem',
      fontWeight: 'normal',
      lineHeight: '4rem',
    },
    h2: {
      fontSize: '2.7rem',
      fontWeight: 'normal',
      lineHeight: '3.2rem',
    },
    h3: {
      fontSize: '2.4rem',
      fontWeight: 'normal',
      lineHeight: '2.9rem',
    },
    h4: {
      fontSize: '2rem',
      fontWeight: '400',
      lineHeight: '2.3rem',
    },
    h5: {
      fontSize: '1.6rem',
      fontWeight: 'normal',
      lineHeight: '2.4rem',
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '2rem',
    },
    body1: {
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '1.8rem',
    },
    body2: {
      fontSize: '1.2rem',
      fontWeight: 'normal',
      lineHeight: '1.6rem',
    },
    caption: {
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: '1.2rem',
    },
  },
  spacing: factor => `${(0.1 * factor).toFixed(3)}rem`,
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1.4rem',
        backgroundColor: 'rgba(38, 38, 38, 0.8)',
        padding: '0.8rem 1.6rem',
      },
    },
    MuiBadge: {
      badge: {
        fontSize: '1rem',
        height: '1.5rem',
        borderRadius: '50%',
        minWidth: '1.5rem',
        width: '1.5rem',
      },
    },
  },
})
