import humps from 'humps'
import { get } from './requests'

export const getHistoryLogs =
  key =>
  (serviceOrderId, mainEvents = undefined) =>
    get(['serviceorders', serviceOrderId, 'logs'], {
      key,
      transformPayload: true,
      params: humps.decamelizeKeys({ pageSize: 1000, mainEvents }),
    })
