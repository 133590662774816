import React from 'react'
import moment from 'moment'
import { Grid, Tooltip } from '@material-ui/core'
import { Home2 } from '@refera/ui-icons'
import { IconButton } from '@refera/ui-web'
import classnames from 'classnames'

import StatusCell from '_components/help-requests/status-cell'
import { generateUniqueKey } from '_/utils/helpers'

export const TYPE_SUBJECT_OPTIONS = {
  '': '(Todos)',
  help_request: 'Pedidos de ajuda',
  justified_delayed: 'Atrasos justificados',
}

export const FROM_TO_OPTIONS = {
  '': '(Todos)',
  intermediary_to_refera: 'Intermediária para Refera',
  tradesman_to_refera: 'Prestador para Refera',
  refera_to_intermediary: 'Refera para Intermediária',
}

export const SERVICE_ORDER_STATUS = {
  '': '(Todos)',
  active: 'Chamados ativos',
  finished: 'Chamados finalizados',
  cancelled: 'Chamados cancelados',
}

export const REQUESTER_OPTIONS = {
  '': '(Todos)',
  intermediary: 'Intermediária',
  others: 'Outro solicitante',
}

const REQUESTER_LABELS = {
  '': '-',
  refera_to_intermediary: 'Outro solicitante',
  intermediary_to_refera: 'Minha intermediária',
  tradesman_to_refera: 'Outro solicitante',
}

export const RequestsColumns = (styles, isIntermediary) => [
  {
    field: 'serviceOrder',
    headerName: 'ID',
    width: 100,
    editable: false,
    cellClassName: styles.idColumn,
    renderCell: params => (
      <div
        className={classnames(styles.serviceOrderCell, {
          [styles.wasntViewed]: !params.row.datetimeViewedByIntermediary,
        })}
        key={params.id}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'intermediaryAvailable',
    headerName: '',
    width: 40,
    editable: false,
    sortable: false,
    renderCell: params => {
      if (params.value) {
        return (
          <Grid
            style={{
              display: 'flex',
            }}
            key={generateUniqueKey(params.id)}
          >
            <Tooltip
              title="Este item de ajuda foi compartilhado com a intermediária."
              arrow
              placement="right"
            >
              <IconButton variant="ghost">
                <Home2 color="gray" />
              </IconButton>
            </Tooltip>
          </Grid>
        )
      }
      return <div />
    },
  },
  {
    field: 'createdAt',
    headerName: 'Aberto em',
    width: 100,
    editable: false,
    renderCell: ({ value, id, row }) => {
      return (
        <div
          key={generateUniqueKey(id)}
          className={classnames({ [styles.wasntViewed]: !row.datetimeViewedByIntermediary })}
        >
          {value ? moment(value).format('DD/MM/yyyy') : '-'}
        </div>
      )
    },
  },
  {
    field: 'datetimeNewestMessage',
    headerName: 'Data último trâmite',
    width: 144,
    editable: false,
    renderCell: ({ value, id, row }) => (
      <div
        key={generateUniqueKey(id)}
        className={classnames({ [styles.wasntViewed]: !row.datetimeViewedByIntermediary })}
      >
        {value ? moment(value).format('DD/MM/yyyy') : '-'}
      </div>
    ),
  },
  {
    field: 'days',
    headerName: 'Dias',
    width: 80,
    editable: false,
    renderCell: params => (
      <div
        key={generateUniqueKey(params.id)}
        className={classnames({
          [styles.greenColor]: params.row.datetimeResolved !== null,
          [styles.redColor]: params.row.datetimeResolved === null,
          [styles.wasntViewed]: !params.row.datetimeViewedByIntermediary,
        })}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'agency',
    headerName: 'Intermediária',
    width: 150,
    editable: false,
    renderCell: ({ value, id, row }) => (
      <div
        key={generateUniqueKey(id)}
        className={classnames({ [styles.wasntViewed]: !row.datetimeViewedByIntermediary })}
      >
        {value ?? '-'}
      </div>
    ),
  },
  {
    field: 'serviceOrderStepStatus',
    headerName: 'Status do chamado',
    width: 190,
    editable: false,
    valueFormatter: ({ value }) => (value === 'default' ? '-' : SERVICE_ORDER_STATUS[value]),
  },
  {
    field: 'fromTo',
    headerName: isIntermediary ? 'Quem solicitou ajuda' : 'De/Para',
    width: 190,
    editable: false,
    renderCell: ({ value, id, row }) => {
      const renderValue = value === 'default' ? '-' : null

      return (
        <div
          key={generateUniqueKey(id)}
          className={classnames({ [styles.wasntViewed]: !row.datetimeViewedByIntermediary })}
        >
          {renderValue || isIntermediary ? REQUESTER_LABELS[value] : FROM_TO_OPTIONS[value]}
        </div>
      )
    },
  },
  {
    field: 'subjectTranslated',
    headerName: 'Assunto',
    flex: 1,
    editable: false,
    renderCell: ({ value, id, row }) => (
      <div
        key={generateUniqueKey(id)}
        className={classnames({ [styles.wasntViewed]: !row.datetimeViewedByIntermediary })}
      >
        {row.registryType} - {value}
      </div>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
    renderCell: params => (
      <StatusCell
        isResolved={params.row.datetimeResolved !== null}
        wasntViewed={!params.row.datetimeViewedByIntermediary}
      />
    ),
  },
]
