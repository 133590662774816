import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    display: 'grid',
    gridRowGap: spacing(16),
    alignItems: 'center',
    width: spacing(463),
  },

  button: {
    marginTop: spacing(34),
    border: `0.1rem solid ${palette.gray[12]}`,
    borderRadius: spacing(24),
  },
}))
