import React from 'react'

export const reprovedCondolivreContract = errorMessage => {
  return (
    <p>
      Seu cadastro na Condolivre apresentou um ou mais problemas
      {errorMessage ? ' informados abaixo:' : '.'}
      <br />
      {errorMessage ? (
        <>
          <b style={{ lineHeight: '36px' }}>{errorMessage}</b>
          <br />
        </>
      ) : (
        ''
      )}
      Clique aqui para editar os documentos e reenviar para análise ou entre em contato com nosso
      suporte.
    </p>
  )
}
