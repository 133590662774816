import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'

import Button from '_components/button'

import { AddGroupPermissionModal } from './add-group-permission-modal'
import useStyles from './styles'
import Accordion from '_/components/accordion'
import { getPermissionsGroupSelector } from '_/modules/profile/selectors'
import { useFormContext } from 'react-hook-form'
import { AccordionInfo } from './accordion-info'
import { Typography } from '@mui/material'

export function AddGroupPermissions({ isEditing, permissionsGroup, setPermissionsGroup }) {
  const styles = useStyles()
  const [modalOpen, setModalOpen] = useState(false)

  const groupsPermission = useSelector(getPermissionsGroupSelector)
  const { watch, setError } = useFormContext()

  const handleModal = () => {
    setModalOpen(state => !state)
  }

  function handleSubmit() {
    const formValues = watch('addGroupPermission')
    const isDuplicated = permissionsGroup?.find(x => x.id === formValues)
    if (!formValues) {
      return setError('addGroupPermission', { message: 'Selecione um grupo de permissão.' })
    }
    const item = groupsPermission?.results?.find(x => x?.id === formValues)
    if (!isDuplicated && item) {
      setPermissionsGroup(pervState => [...pervState, item])
    }
    return handleModal()
  }

  const handleDeleteUser = userObj => {
    const updatedPermissionGroupData = permissionsGroup.filter(
      currentUser => currentUser.id !== userObj.id
    )
    setPermissionsGroup(updatedPermissionGroupData)
  }

  return (
    <Accordion
      id="add-group-permissions"
      title="Grupos de permissões"
      classNameContainer={styles.container}
      className={styles.container}
    >
      <Grid className={styles.userList}>
        {permissionsGroup.length === 0 && !isEditing && (
          <Typography className={styles.subTitle}>Nenhum grupo de permissão adicionado</Typography>
        )}
        {permissionsGroup
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map(user => (
            <AccordionInfo
              key={user?.id}
              values={user}
              isEditing={isEditing}
              handleDeleteValue={handleDeleteUser}
            />
          ))}
      </Grid>
      {isEditing && (
        <Grid>
          <Button
            className={styles.button}
            variant="outlined"
            color="primary"
            onClick={() => handleModal()}
          >
            + Adicionar registro
          </Button>
        </Grid>
      )}
      {modalOpen && (
        <AddGroupPermissionModal
          modalOpen={modalOpen}
          handleModal={handleModal}
          handleSubmit={() => handleSubmit()}
        />
      )}
    </Accordion>
  )
}
