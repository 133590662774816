import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'grid',
    gridRowGap: spacing(16),
    alignItems: 'center',
    width: spacing(463),
  },

  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(34),
    border: `0.1rem solid #C90000`,
    borderRadius: spacing(24),
    color: '#C90000',
    marginLeft: '390px',
    textTransform: 'capitalize',
  },

  description: {
    borderSizing: 'border-box',
    border: '1px solid #E3E3E3',
    padding: '16px',
    borderRadius: '4px',
    overflowWrap: 'break-Word',
  },
}))
