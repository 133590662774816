import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import Button from '_components/button'
import { updateBudget } from '_modules/budget/actions'

import useStyles from './styles'

const CancelReviseBudgetDialog = ({ isOpen, onClose, serviceOrderId, budgetId }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)

  const onCancelButtonRevision = useCallback(() => {
    setLoading(true)
    dispatch(updateBudget(serviceOrderId, budgetId, { isRevised: false }))
      .then(() => {
        setLoading(false)
        onSuccess()
      })
      .catch(setLoading(false))
  }, [budgetId, dispatch, serviceOrderId, isLoading])

  const onSuccess = useCallback(() => {
    if (isOpen) {
      onClose()
    }
  }, [isOpen, onClose])

  return (
    <Dialog open={isOpen} onClose={onClose} aria-describedby="cancelReviseBudgetDialog">
      <DialogContent>
        <DialogContentText id="cancelReviseBudgetDialog" className={styles.description}>
          Você tem certeza que deseja cancelar a revisão do orçamento e voltar a ocultá-lo da fonte
          pagadora?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Não
        </Button>
        <Button
          onClick={onCancelButtonRevision}
          isLoading={isLoading}
          color="primary"
          variant="contained"
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CancelReviseBudgetDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  budgetId: PropTypes.number.isRequired,
}

export default React.memo(CancelReviseBudgetDialog)
