import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  modal: {
    position: 'absolute',
    zIndex: 4,
    top: '10%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    display: 'flex',
    gap: spacing(24),
    justifyContent: 'flex-end',
  },
  labelMessage: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: Theme.Typography.FontSize.Tiny,
    marginBottom: spacing(8),
  },
  attachment: {
    paddingTop: spacing(24),
  },
  required: {
    color: Theme.Colors.Red.Base,
  },
  countArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: spacing(8),
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: Theme.Typography.FontSize.XSmall,
    lineHeight: '24px',
    weight: Theme.Typography.FontWeight.Small,
    color: Theme.Colors.Grayscale.SixtyFour,
  },
  statusWrapper: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    marginBottom: '10px',
  },
}))
