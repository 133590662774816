import React from 'react'
import { Preview } from '@refera/ui-web'

const PreviewComponent = ({ executionProofs, handleClose, open, selectedIndex }) => (
  <Preview
    open={open}
    onClose={handleClose}
    selectedIndex={selectedIndex}
    images={
      executionProofs &&
      executionProofs.map((item, index) =>
        item.id
          ? {
              id: item.id,
              url: item.file,
            }
          : {
              id: index,
              url: URL.createObjectURL(item),
            }
      )
    }
  />
)

export default PreviewComponent
