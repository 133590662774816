import React, { useCallback, useState, useContext } from 'react'
import { Button } from '@refera/ui-web'
import { navigate } from '@reach/router'

import { BudgetContext } from '_/utils/budget'
import useRolePermission from '_/hooks/use-role-permission'
import ModalDialog, { WARNING_MODAL } from '_/components/modal/modal-dialog'
import { ROUTES } from '_/utils/constants'

const ManageServicesButton = ({ serviceOrderId, budgetId }) => {
  const { isAdmin } = useRolePermission()

  const [modalDialog, setModalDialog] = useState({
    isOpen: false,
    textOkButton: 'Ok, entendi',
    subTitle: `Salve antes as edições deste registro.`,
    type: WARNING_MODAL,
  })

  const { isEditing } = useContext(BudgetContext)

  const handleButtonClick = useCallback(() => {
    return navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrderId}/orcamento/${budgetId}/servicos`)
  }, [modalDialog, navigate, isEditing, isAdmin, serviceOrderId])

  if (isAdmin) {
    return (
      <>
        <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
        <Button onClick={handleButtonClick} color="primary" variant="secondary">
          Gerenciar serviços
        </Button>
      </>
    )
  }

  return <></>
}

export default ManageServicesButton
