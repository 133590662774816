import React from 'react'
import { Alert, Toast } from '@refera/ui-web'

export const GlobalToast = ({ isOpen, type, message, hideToast }) => {
  return (
    <Toast draggable open={isOpen} autoHideDuration={6000} onClose={hideToast}>
      <Alert severity={type} title={message} onClose={hideToast} />
    </Toast>
  )
}
