import React, { useEffect, useMemo, useState } from 'react'

import { Attachments } from '_/components/refera-ui'
import { useToast } from '_/hooks/use-toast'
import { Controller, useFormContext } from 'react-hook-form'

const DOCUMENT_MAX_SIZE = 500 * 1024 // 500kb

export function GenerateContractCompanyDocumentsPage({ props }) {
  const { showToast } = useToast()
  const { control, setValue } = useFormContext()
  const [attachments, setAttachments] = useState([])

  const defaultCompanyAttachments = useMemo(() => {
    return props?.[0]?.company?.companyAttachments?.map(attachment => ({
      id: attachment.id,
      file: attachment.file,
    }))
  }, [props])

  const handleDropAttachment = files => {
    const hasInvalidFile = files?.some(file => file?.size > DOCUMENT_MAX_SIZE)

    if (hasInvalidFile) {
      showToast({
        type: 'error',
        message: 'Um ou mais arquivos excedem o tamanho máximo permitido',
      })
      return
    }

    setAttachments(old => [...old, ...files])
  }

  const handleRemoveAttachment = index => {
    setAttachments(attachments.filter((_, i) => i !== index))
  }

  useEffect(() => {
    if (defaultCompanyAttachments?.length > 0) setAttachments(defaultCompanyAttachments)
  }, [defaultCompanyAttachments])

  useEffect(() => {
    setValue('companyAttachments', attachments)
  }, [attachments])

  return (
    <Controller
      control={control}
      name="companyAttachments"
      defaultValue={defaultCompanyAttachments}
      as={
        <Attachments
          label="Contrato social ou documento equivalente, com última alteração"
          content="files"
          downloadable
          required
          boldLabel={false}
          files={attachments}
          accept={{ 'application/pdf': ['.pdf'] }}
          onDrop={handleDropAttachment}
          onRemoveItem={handleRemoveAttachment}
          onItemClick={index => window.open(attachments[index].file)}
        />
      }
    />
  )
}
