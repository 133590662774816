import { Grid, Typography } from '@material-ui/core'
import React, { useEffect, useCallback, useState, useMemo } from 'react'

import useStyles from './styles'

import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import Svg, { ICON } from '_/components/svg'
import { MinusCirlce as RemoveIcon } from '@refera/ui-icons'
import { generateUniqueKey } from '_/utils/helpers'
import { Attachments } from '_/components/refera-ui'
import { useToast } from '_/hooks/use-toast'
import { BasicInput, MaskBasicInput } from '_/components/inputs'
import { FormHelperText } from '@mui/material'

const DOCUMENT_MAX_SIZE = 5 * 1024 * 1024 // 5MB

function ResponsibleForm({ index, responsibleCount, onRemove, previousResponsibleData }) {
  const styles = useStyles()
  const { showToast } = useToast()
  const [attachments, setAttachments] = useState([])
  const {
    formState: { errors },
    control,
    setValue,
    watch,
    setError,
  } = useFormContext()

  const handleDropAttachment = useCallback(
    (files, name) => {
      const file = files[0]

      if (file?.size > DOCUMENT_MAX_SIZE) {
        showToast({ type: 'error', message: 'O arquivo excede o tamanho máximo permitido' })
        return
      }
      setAttachments([file])
      setValue(name, file)
    },
    [setValue]
  )

  const handleAttachment = async () => {
    const newAttachments = []

    if (previousResponsibleData?.documentFile) {
      newAttachments.push({
        id: 0,
        file: previousResponsibleData?.documentFile,
      })
      setValue(`responsibles[${index}].documentFile`, previousResponsibleData?.documentFile)
    } else if (watch(`responsibles[${index}].documentFile`)) {
      setValue(`responsibles[${index}].documentFile`, newAttachments[0])
      newAttachments.push(watch(`responsibles[${index}].documentFile`))
    }

    setAttachments(newAttachments)
  }

  const handleDownloadDocument = () =>
    window.open(watch(`responsibles[${index}].documentFile`)?.file)

  const handleRemoveDocument = () => {
    setAttachments([])
    setValue(`responsibles[${index}].documentFile`, null)
    setError(`responsibles[${index}].documentFile`, {
      type: 'required',
      message: 'Esse campo é obrigatório',
    })
  }

  useEffect(() => {
    handleAttachment()
  }, [])

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
        onClick={() => onRemove(index)}
      >
        <Typography align="left" variant="h4" component="h4" gutterBottom className={styles.title}>
          {`Responsável ${index + 1}`}
        </Typography>
        {responsibleCount > 1 && (
          <Grid className={styles.actionResponsibleContainer}>
            <RemoveIcon fontSize={16} fill="#C90000" />
            <Typography
              align="left"
              variant="h5"
              component="h5"
              gutterBottom
              className={styles.removeResponsibleText}
            >
              Remover contato
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid className={styles.responsibleInputContainer}>
        <BasicInput
          label="Nome Completo"
          name={`responsibles[${index}].fullName`}
          customErrorMessage={errors?.responsibles?.[index]?.fullName?.message}
          defaultValue={previousResponsibleData?.fullName ?? ''}
          className={styles.fillWidth}
          required
        />

        <BasicInput
          label="Email"
          name={`responsibles[${index}].email`}
          customErrorMessage={errors?.responsibles?.[index]?.email?.message}
          defaultValue={previousResponsibleData?.email ?? ''}
          className={styles.fillWidth}
          required
        />

        <MaskBasicInput
          label="CPF"
          name={`responsibles[${index}].cpf`}
          customErrorMessage={errors?.responsibles?.[index]?.cpf?.message}
          defaultValue={previousResponsibleData?.cpf ?? ''}
          className={styles.fillWidth}
          mask="999.999.999-99"
          required
        />

        <BasicInput
          label="Cargo"
          name={`responsibles[${index}].cargo`}
          customErrorMessage={errors?.responsibles?.[index]?.cargo?.message}
          defaultValue={previousResponsibleData?.cargo ?? ''}
          className={styles.fillWidth}
          required
        />

        <div className={styles.attachmentsContainer}>
          <Controller
            control={control}
            name={`responsibles[${index}].documentFile`}
            as={
              <Attachments
                label="Documento de identificação (RG ou CNH)"
                content="files"
                downloadable
                files={attachments || []}
                accept={{
                  'application/pdf': ['.pdf'],
                }}
                required
                multiple={false}
                onDrop={files => handleDropAttachment(files, `responsibles[${index}].documentFile`)}
                onRemoveItem={handleRemoveDocument}
                onItemClick={handleDownloadDocument}
                boldLabel={false}
              />
            }
          />
          {errors?.responsibles?.[index]?.documentFile?.message && (
            <FormHelperText className={styles.errorMessage}>
              {errors?.responsibles?.[index]?.documentFile?.message}
            </FormHelperText>
          )}
        </div>
      </Grid>
    </>
  )
}

export function GenerateContractResponsibleDataPage({ props }) {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'responsibles',
  })
  const styles = useStyles()

  const previousResponsibleData = useMemo(() => {
    return props?.map(responsible => ({
      cargo: responsible?.cargo || '',
      cpf: responsible?.cpf || '',
      documentFile: responsible?.documentFile || '',
      email: responsible?.email || '',
      fullName: responsible?.fullName || '',
    }))
  }, [props])

  const deleteResponsible = index => remove(index)
  const addResponsible = () => append({ key: generateUniqueKey() })

  useEffect(() => {
    if (previousResponsibleData?.length > 0) {
      deleteResponsible(0)
      previousResponsibleData?.map(data =>
        append({
          key: generateUniqueKey(),
          data,
        })
      )
    } else if (fields.length === 0) {
      addResponsible()
    }
  }, [previousResponsibleData])

  return (
    <Grid className={styles.container}>
      {fields.map((responsible, index) => (
        <ResponsibleForm
          key={`responsible-form-${responsible.key}`}
          index={index}
          responsibleCount={fields.length}
          previousResponsibleData={responsible.data}
          onRemove={() => deleteResponsible(index)}
        />
      ))}
      <Grid className={styles.actionResponsibleContainer} onClick={addResponsible}>
        <Svg type={ICON.ADD_CIRCLE} className={styles.icon} />
        <Typography
          align="left"
          variant="h5"
          component="h5"
          gutterBottom
          className={styles.addResponsibleText}
        >
          Adicionar o contato de outro responsável
        </Typography>
      </Grid>
    </Grid>
  )
}
