import React, { useMemo, useState } from 'react'

import { Grid, Checkbox, Tooltip, Typography } from '@material-ui/core'
import { Modal, Button } from '@refera/ui-web'
import useStyles from '../styles'
import { companySelector } from '_/modules/company/selector'
import { useSelector } from 'react-redux'

const defaultCheckboxes = [false, false]
const handleEmptyField = field => field || 'Não informado'

const AnticipationTermsModal = ({ open, onConfirm, onCancel, anticipationTermsValues }) => {
  const styles = useStyles()
  const [checkboxes, setCheckboxes] = useState(defaultCheckboxes)
  const company = useSelector(companySelector)

  const handleCheckboxChange = index => {
    setCheckboxes(prev => {
      const updatedCheckboxes = [...prev]
      updatedCheckboxes[index] = !updatedCheckboxes[index]
      return updatedCheckboxes
    })
  }

  const bankAccountInfo = useMemo(() => {
    return (
      <span>
        Dados da conta de destino: <br />
        Banco: {handleEmptyField(company?.bank?.name)} <br />
        Número da agência: {handleEmptyField(company?.bankAgencyNumber)} <br />
        Número da conta: {handleEmptyField(company?.bankAccountWithDigit)} <br />* Caso queira
        alterar esses dados antes, cancele essa operação e altere-as na opção “Conta” no menu “Mais”
        do seu APP Refera. Em seguida, solicite a antecipação novamente.
      </span>
    )
  }, [company])

  return (
    <Modal
      className={styles.modal}
      title="Dar baixa no Aceite de termos e juros de antecipação"
      onBackdropClick={onCancel}
      onClose={onCancel}
      open={open}
      actionsButtons={
        <Grid className={styles.modalButtons}>
          <Button color="red" variant="secondary" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="primary"
            onClick={onConfirm}
            disabled={checkboxes.some(check => !check)}
          >
            Concordar
          </Button>
        </Grid>
      }
    >
      <Grid className={styles.modalWrapper}>
        <Typography onClick={() => handleCheckboxChange(0)} className={styles.checkboxRow}>
          <Checkbox color="primary" checked={checkboxes[0]} id="checkbox-1" name="checkbox-1" />
          <span>
            Concordo que será aplicada uma taxa de antecipação com desconto de{' '}
            {anticipationTermsValues?.interestValue} sobre o valor solicitado.
          </span>
        </Typography>
        <Typography onClick={() => handleCheckboxChange(1)} className={styles.checkboxRow}>
          <Checkbox color="primary" checked={checkboxes[1]} id="checkbox-2" name="checkbox-2" />
          <span>
            Concordo que os recursos serão disponibilizados na{' '}
            <Tooltip title={bankAccountInfo}>
              <span className={styles.anticipationTermsLink}>minha conta</span>
            </Tooltip>{' '}
            no dia {anticipationTermsValues?.anticipationDay}.
          </span>
        </Typography>
      </Grid>
    </Modal>
  )
}

export default AnticipationTermsModal
