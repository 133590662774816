import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(8, 16),
    maxHeight: spacing(600),
  },
  title: {
    color: 'black',
    fontSize: spacing(16, '!important'),
    fontWeight: '500 !important',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    marginTop: spacing(16),
    gap: spacing(16),
  },
  emptyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    marginTop: spacing(16),
    gap: spacing(20),
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingTop: spacing(10),
    paddingBottom: spacing(20),
  },
  emptyIcon: {
    display: 'flex',
    background: Theme.Colors.Primary.Lightest,
    height: spacing(80),
    width: spacing(80),
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyDescription: {
    color: 'black',
    fontSize: spacing(16, '!important'),
    fontWeight: '400 !important',
  },
  badge: {
    fontSize: spacing(10),
    width: spacing(20),
    height: spacing(20),

    '& .MuiBadge-badge': {
      width: spacing(0, '!important'),
    },
  },
  bigBadge: {
    '& .MuiBadge-badge': {
      width: spacing(26, '!important'),
    },
  },
  iconButton: {
    fontSize: spacing(20, '!important'),
  },
  tooltipMargin: {
    margin: 40,
  },
}))
