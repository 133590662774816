import React, { useState } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import Svg from '_components/svg'
import ReferaHelperIcon from '_assets/svgs/refera-helper.svg'
import HelpLady from '_assets/svgs/help-lady.svg'
import CloseBtn from '_assets/svgs/blue-close.svg'
import SpeechBubble from '_assets/svgs/speech-bubble.svg'

import useStyles from './styles'
import PropTypes from 'prop-types'
import JungleButton from '_components/button'
import SelfLearningModal from './self-learning-modal'

/**
 *
 * @param {string} message
 * @description Mostra uma mensagem de texto anterior ao botão "Clique aqui". Não aparece na versão mobile.
 *
 * @param {string} mobileMessage
 * @default ''
 * @description Apenas se hasMobile = true, determina mensagem do botão azul inferior para mobile.
 *
 * @param {string} modalTitle
 * @description Determina o título no topo do modal.
 *
 * @param {Component} modalTemplate
 * @description É usado como child do modal e mostra o conteúdo dentro dele. Largura do modal é determinada pela largura do child exceto caso mobile
 *
 * @param {boolean} hasMobile
 * @default false
 * @description Determina se o componente utiliza sua função reativa ou não.
 */
const SelfLearning = ({
  message,
  mobileMessage = '',
  modalTitle,
  modalTemplate,
  hasMobile = false,
  supportService,
}) => {
  const styles = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [hideComponent, setHideComponent] = useState(false)

  const handleHelpText = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Grid className={styles.container}>
        <Svg icon={SpeechBubble} className={styles.speech} />
        <Typography className={styles.messageWrapper}>
          {message}
          <JungleButton className={styles.button} onClick={() => setModalOpen(true)}>
            Clique aqui
          </JungleButton>
        </Typography>
        <Svg icon={ReferaHelperIcon} className={styles.icon} />
      </Grid>

      {hasMobile && !hideComponent && (
        <Grid className={styles.containerMobile}>
          <Button className={styles.closeBtnWrapper} onClick={() => setHideComponent(true)}>
            <Svg icon={CloseBtn} className={styles.closeBtn} />
          </Button>
          <Svg icon={HelpLady} className={styles.iconMobile} />
          <JungleButton className={styles.buttonMobile} onClick={() => setModalOpen(true)}>
            {mobileMessage}
          </JungleButton>
        </Grid>
      )}
      {modalOpen && (
        <SelfLearningModal
          title={modalTitle}
          modalOpen={modalOpen}
          onCancel={handleHelpText}
          template={modalTemplate}
          supportService={supportService}
        />
      )}
    </>
  )
}
SelfLearning.propTypes = {
  message: PropTypes.string,
  modalTitle: PropTypes.string,
}
SelfLearning.defaultProps = {
  message: '',
  modalTitle: '',
}
export default React.memo(SelfLearning)
