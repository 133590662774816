import { GlobalToast } from '_/components/globalToast'
import React, { createContext, useContext, useState, useCallback, useMemo } from 'react'

const ToastContext = createContext()

export const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro ao realizar a operação.'
export const DEFAULT_SUCCESS_MESSAGE = 'Ação executada com sucesso.'

/**
 * @typedef {Object} ToastProps
 * @property {'success' | 'error' | 'info' | 'warning'} type - The type of the toast.
 * @property {string} message - The toast displayed message.
 */

/**
 * @typedef {Object} ToastContextValue
 * @property {function(ToastProps): void} showToast - Shows the toast with the specified properties.
 */

/**
 * Use the toast context.
 *
 * @returns {ToastContextValue} The toast context value.
 */
export const useToast = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

export const ToastProvider = ({ children }) => {
  const [toastInfo, setToastInfo] = useState({ isOpen: false, type: '', message: '' })

  // use showToast({type: 'success' || 'error'}) to use default toast messages
  const showToast = useCallback(({ message, type }) => {
    let toastMessage = message

    if (!message) {
      if (type === 'success') toastMessage = DEFAULT_SUCCESS_MESSAGE
      if (type === 'error') toastMessage = DEFAULT_ERROR_MESSAGE
    }

    setToastInfo({ isOpen: true, type, message: toastMessage })
  }, [])

  const hideToast = useCallback(() => {
    setToastInfo({ isOpen: false, type: '', message: '' })
  }, [])

  const renderToast = useMemo(
    () => toastInfo?.isOpen && <GlobalToast {...toastInfo} hideToast={hideToast} />,
    [toastInfo?.isOpen]
  )

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {renderToast}
    </ToastContext.Provider>
  )
}
