/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { notEmptyPayload, createFormDataDocuments } from '_/utils/helpers'
import { get, post, del, patch } from './requests'
import humps from 'humps'

const decamelizeParams = params => {
  Object.values(params)?.map(param => {
    if (typeof param === 'string') {
      const replaceKey = Object.keys(params).find(paramKey => params[paramKey] === param)
      params[replaceKey] = humps.decamelize(param)
    }
  })
  return params
}

export const getHelpRequests = key => params => {
  const payloadParams = decamelizeParams(params)

  return get(['helprequests'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(payloadParams)),
  })
}

export const getHelpRequestsByServiceOrderId = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'get-help-requests'], { key })

export const getHelpRequestById = key => helpRequestId => {
  return get(['helprequests', helpRequestId], {
    key,
    transformPayload: true,
  })
}

export const createHelpRequest = (key, payload) => post(['helprequests'], { key }, payload)

export const editHelpRequest = key => (helpRequestId, payload) =>
  patch(['helprequests', helpRequestId], { key }, payload)

export const getHelpRequestPendings = key => helpRequestId => {
  return get(['helprequests', helpRequestId, 'pendings'], { key, transformPayload: true })
}

export const createHelpRequestPendings = (key, payload, helpRequestId) =>
  post(
    ['helprequests', helpRequestId, 'pendings'],
    { key, transformOnlyResponse: true, formData: true },
    createFormDataDocuments(payload)
  )

export const editHelpRequestPendings = key => (payload, helpRequestId, procedureId) =>
  patch(
    ['helprequests', helpRequestId, 'pendings', procedureId],
    { key, transformOnlyResponse: true, formData: true },
    createFormDataDocuments(payload)
  )

export const deletePendingRequest = key => (helpRequestId, procedureId) =>
  del(['helprequests', helpRequestId, 'pendings', procedureId, 'delete-pending'], { key })

export const deletePendingAttachment = key => (helpRequestId, procedureId, attachmentId) =>
  patch(
    ['helprequests', helpRequestId, 'pendings', procedureId, 'delete-attachment'],
    { key },
    { attachmentId }
  )

export const updateHelpRequestAvailability = key => (helpRequestId, payload) =>
  patch(['helprequests', helpRequestId, 'update-avaliability'], { key }, payload)

export const verifyStepLog = key => helpRequestId =>
  get(['helprequests', helpRequestId, 'verify-step-log'], { key })
