import { formatFormData } from '_/utils/helpers'
import { get, patch, post } from './requests'

export const getCompany = key => get(['company'], { key, transformPayload: true })
export const updateCompany = (key, companyId, payload, transformOnlyResponse) =>
  patch(
    ['company', companyId],
    {
      key,
      transformOnlyResponse,
      formData: true,
    },
    payload
  )

export const getCompanyById = (key, companyId) => get(['company', companyId], { key })

export const getCompanies = key => get(['company'], { key, params: { page_size: 1000 } })

export const getCompaniesSimple = key => get(['companyboard', 'get-simple-company'], { key })

export const postContractSigners = (key, payload) =>
  post(
    ['contract-user-info'],
    {
      key,
      transformOnlyResponse: true,
      formData: true,
    },
    formatFormData(payload)
  )

export const getContractSigners = key => get(['contract-user-info'], { key })

export const postGenerateContract = key => post(['create-document-contract'], { key })

export const postRequestSignatureEmail = key => post(['send-request-signature-email'], { key })

export const getContractOptions = key =>
  get(['company', 'contract-options'], { key, transformPayload: true })
