import { Record, Map, List } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { responsibleSchema } from '_models/responsible'

import { GET_RESPONSIBLES, GET_USERSAGENTS } from './actions'

const INITIAL_STATE = new Record({
  results: Map(),
  order: Map(),
  userAgents: List(),
})()

export default createReducer(INITIAL_STATE, {
  [GET_RESPONSIBLES.FULFILLED]: (state, { payload }) => {
    const normalizeResponsibles = normalize(payload.results, responsibleSchema)
    return state.set('results', Map(normalizeResponsibles.entities.responsible))
  },

  [GET_USERSAGENTS.FULFILLED]: (state, { payload }) => {
    return state.set('userAgents', List(payload))
  },
})
