import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  header: {
    display: 'flex',
    height: spacing(100),
    backgroundColor: '#fff',
    padding: spacing(0, 28),
    justifyContent: 'center',
    alignItems: 'flex-start',
    boxShadow: 'none',
    border: `1px solid ${palette.gray[12]}`,
    position: 'relative',

    [breakpoints.down('md')]: {
      height: spacing(80),
    },
  },
}))
