import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { Loader } from '@refera/ui-web'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EmptyWidgetIcon from '_assets/svgs/rafiki.svg'

import Table from '_components/widgets-table'
import Svg from '_components/svg'

import { getWidgetsOptionsSelector, getWidgetsFilter } from '_modules/widgets/selectors'
import { userWidgetsSelectorByPosition } from '_modules/authentication/selectors'
import { getWidgetResults } from '_/modules/widgets/actions'
import { updateUser } from '_modules/authentication/actions'

import Button from '../button'

import { useToast } from '_/hooks/use-toast'

import classNames from 'classnames'
import useStyles from './styles'

const DEFAULT_SELECT_OPTION = { id: 'select', title: 'Selecione' }

const WidgetsBoard = ({ position }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()

  const options = useSelector(getWidgetsOptionsSelector)
  const widgetsParams = useSelector(getWidgetsFilter)
  const userWidgetsByPosition = useSelector(userWidgetsSelectorByPosition(position))

  const [anchorEl, setAnchorEl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [widgetInfo, setWidgetInfo] = useState({})

  const handleClick = event => setAnchorEl(prev => (prev ? null : event.currentTarget))

  const fetchWidgets = useCallback(
    async (selectedOption = null) => {
      const filteredOption =
        selectedOption ?? options?.find(option => option?.id === userWidgetsByPosition)

      if (
        (filteredOption && userWidgetsByPosition === filteredOption?.id) ||
        (selectedOption && selectedOption?.id !== 'select')
      ) {
        const { id, title } = filteredOption

        setIsLoading(true)
        setWidgetInfo({ id, title })
        await dispatch(getWidgetResults({ optionId: id, params: widgetsParams?.toJS() }))
          .then(results => {
            setWidgetInfo({ id, title, results })
            setIsLoading(false)
          })
          .catch(() => {
            showToast({
              message: 'Ocorreu um erro ao buscar dados dos widgets',
              type: 'error',
            })
            setIsLoading(false)
          })
      } else {
        setIsLoading(false)
        setWidgetInfo({})
      }
      setAnchorEl(null)
    },
    [dispatch, userWidgetsByPosition, showToast, options, widgetsParams]
  )

  const handleSelectWidget = useCallback(
    async option => {
      setAnchorEl(null)
      const { id } = option

      if (widgetInfo?.id !== id) {
        setIsLoading(true)

        await dispatch(updateUser({ widgets_options: { [position]: id } }))
          .then(() => fetchWidgets(option))
          .catch(() => {
            showToast({
              message: 'Ocorreu um erro selecionar widget do usuário',
              type: 'error',
            })
            setIsLoading(false)
          })
      }
    },
    [fetchWidgets, widgetInfo, showToast, dispatch]
  )

  const widgetOptions = useMemo(() => {
    return options?.map(option => (
      <Typography
        key={option?.id}
        onClick={() => handleSelectWidget(option)}
        className={styles.menuOptions}
      >
        {option?.title}
      </Typography>
    ))
  }, [options, handleSelectWidget])

  useEffect(() => {
    fetchWidgets()
  }, [options, widgetsParams])

  return (
    <>
      <Grid className={styles.container}>
        <Grid className={styles.boxes}>
          <Grid className={styles.box}>
            <Grid className={styles.boxTitle}>
              <Grid className={styles.boxTitleContainer}>
                <Typography component="h4" variant="h4" color="primary">
                  {widgetInfo?.title ?? 'Selecione'}
                  {widgetInfo?.results && ` (${widgetInfo?.results?.length})`}
                </Typography>
              </Grid>

              <Grid className={styles.selectWidget}>
                <div className={styles.selectButton}>
                  <Button
                    id="basic-button"
                    aria-controls={anchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEl ? 'true' : undefined}
                    onClick={event => handleClick(event)}
                    disabled={isLoading}
                  >
                    <ExpandMoreIcon className={styles.expandIcon} />
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid className={styles.division} />
            {isLoading && (
              <Grid className={classNames(styles.emptyWidget, styles.loadingContainer)}>
                <Loader open={isLoading} />
              </Grid>
            )}
            {anchorEl && (
              <Grid className={styles.menuContainer}>
                <Typography
                  onClick={() => handleSelectWidget(DEFAULT_SELECT_OPTION)}
                  className={[styles.selectOption, styles.menuOptions]}
                >
                  {DEFAULT_SELECT_OPTION.title}
                </Typography>
                {widgetOptions}
              </Grid>
            )}
            {widgetInfo?.results?.length ? (
              <Table items={widgetInfo?.results} />
            ) : (
              <Grid className={styles.emptyWidget}>
                <Svg icon={EmptyWidgetIcon} className={styles.emptyWidgetIcon} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(WidgetsBoard)
