import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@refera/ui-web'
import { STEP_STATUS } from '_/utils/constants/service-order'

const POOL_MESSAGES = {
  [STEP_STATUS.WAITING_POOL_ACCEPTANCE]: {
    title: 'Aguardando aceite em pool',
    message: (
      <p>
        Este chamado foi enviado a mais prestadores ao mesmo tempo, para ver quem o aceita primeiro.
        Dúvidas,{' '}
        <a
          style={{ color: '#1671ed', textDecoration: 'none' }}
          href="https://refera.movidesk.com/kb/pt-br/article/494003/aguardando-aceite-em-pool"
        >
          clique aqui
        </a>
        .
      </p>
    ),
  },
  [STEP_STATUS.WAITING_PRE_APPROVED_PROPOSAL_POOL]: {
    title: 'Aguardando pool da proposta pré-aprovada',
    message: (
      <p>
        Este orçamento pré-aprovado foi enviado a mais prestadores ao mesmo tempo, para ver quem o
        aceita primeiro. Dúvidas,
        <a
          style={{ color: '#1671ed', textDecoration: 'none' }}
          href="https://refera.movidesk.com/kb/pt-br/article/494005/aguardando-pool-da-proposta-pre-aprovada"
        >
          clique aqui
        </a>
        .
      </p>
    ),
  },
}

export function Primitive({ stepStatus, ...rest }) {
  const [visible, setVisible] = useState(true)

  if (POOL_MESSAGES[stepStatus] && visible) {
    return (
      <div {...rest}>
        <Alert
          severity="info"
          title={POOL_MESSAGES[stepStatus].title}
          onClose={() => setVisible(false)}
        >
          {POOL_MESSAGES[stepStatus].message}
        </Alert>
      </div>
    )
  }

  return null
}
Primitive.propTypes = {
  stepStatus: PropTypes.oneOf(Object.values(STEP_STATUS)).isRequired,
}

export const PoolWarning = React.memo(Primitive)
