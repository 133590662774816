import React, { useCallback, useMemo, useEffect } from 'react'

import Svg, { ICON } from '_components/svg'

import useStyles from './styles'
import { Link, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '_/modules/authentication/selectors'
import { linksSelector } from '_/modules/lgpdLinks/selector'
import { getLinks } from '_/modules/lgpdLinks/actions'

export const ConfidentialDataWarning = ({ style = {}, inServiceForm }) => {
  const styles = useStyles()
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const lesseeLgpdLinks = useSelector(linksSelector)

  useEffect(() => {
    if (!user?.lgpdLinks?.length && !lesseeLgpdLinks?.length && inServiceForm) {
      dispatch(getLinks('lessee'))
    }
  }, [])

  const lgpdLinks = useMemo(() => {
    if (user?.lgpdLinks?.length > 0)
      return { termsOfUse: user.lgpdLinks[0], privacyPolicy: user.lgpdLinks[1] }

    if (lesseeLgpdLinks?.privacyPolicy)
      return {
        termsOfUse: lesseeLgpdLinks.termsOfUse,
        privacyPolicy: lesseeLgpdLinks.privacyPolicy,
      }

    return { termsOfUse: null, privacyPolicy: null }
  }, [user?.lgpdLinks?.length, lesseeLgpdLinks?.privacyPolicy])

  const renderLink = useCallback(
    (linkKey, linkText) => {
      return (
        <Link key={linkKey} className={styles.link} href={lgpdLinks[linkKey]} target="_blank">
          {linkText}
        </Link>
      )
    },
    [lgpdLinks.privacyPolicy]
  )
  const getMessage = useCallback(() => {
    // inServiceForm - Tela de abertura do chamado
    if (inServiceForm) {
      return (
        <>
          Não compartilhe dados pessoais ou sensíveis neste campo. Para mais informações{' '}
          {renderLink('privacyPolicy', 'clique aqui')}.
        </>
      )
    }

    return (
      <>
        Não compartilhe dados pessoais ou sensíveis neste campo. Caso necessário leia os{' '}
        {renderLink('termsOfUse', 'Termos de Uso')} e{' '}
        {renderLink('privacyPolicy', 'Políticas de Privacidade')}.
      </>
    )
  }, [inServiceForm])

  return (
    <>
      <div className={styles.container} style={style}>
        <Svg type={ICON.INFO_CIRCLE_BLUE} className={styles.icon} />
        <Typography className={styles.text}>{getMessage()}</Typography>
      </div>
    </>
  )
}
