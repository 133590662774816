import { defineAction } from '_utils/redux'
import * as linksServices from '_services/lgpdLinks'

export const GET_LINKS = defineAction('GET_LINKS')

export const getLinks = payload => dispatch => {
  dispatch({
    type: GET_LINKS.ACTION,
    payload: linksServices.getLinks()(payload),
  })
}
