import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    position: 'absolute',
    inset: 'auto 0 0 0',
    backgroundColor: palette.gray[4],
    padding: spacing(16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerContent: {
    color: palette.gray[100],
  },
  info: {
    lineHeight: spacing(20),
    color: palette.gray[32],
    margin: spacing(4, 0, 48),
  },
  paper: {
    maxHeight: spacing(568),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(40, 32, 32),
    boxShadow: `${spacing(0, 1, 2)} rgba(0, 0, 0, 0.12)`,
    maxWidth: spacing(368),

    [breakpoints.down('md')]: {
      maxWidth: spacing(328),
    },
  },
  key: {
    height: spacing(80),
    width: spacing(80),
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props =>
      props.successState ? palette.green.lightest : palette.primary.lightest,
    marginBottom: spacing(16),
  },
  keyIcon: {
    height: spacing(40),
    width: spacing(40),
  },
  check: {
    height: spacing(60),
    width: spacing(60),
    color: palette.green.main,
  },
  button: {
    marginTop: spacing(16),
  },
}))
