import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: spacing(75),
    width: '100%',
    padding: spacing(0, 71),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  main: {
    height: '100%',
    display: 'flex',
    padding: '13px',
  },
  treeWrapper: {
    width: '35%',
    marginRight: '5px',
  },
  displayWrapper: {
    width: '100%',
    borderLeft: '1px solid lightgrey',
    paddingLeft: '30px',
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: `${Theme.Typography.FontSize.Large}px`,
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  buttonWrapper: {
    width: '160px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  modal: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectWrapper: {
    display: 'flex',
    width: '100%',
    height: 'fit-content',
    justifyContent: 'center',
    marginTop: '30px',
  },
  modalContent: {
    minHeight: spacing(270),
  },
  selectTitle: {
    fontSize: '20px',
  },
  serviceLabel: {
    fontSize: '18px',
    marginBottom: spacing(10),
  },
  autocomplete: {
    marginBottom: '20px',
    width: '100%',

    '& .MuiAutocomplete-popper': {
      maxHeight: spacing(200),
    },

    '& .MuiAutocomplete-endAdornment': {
      top: '2px',
    },
  },
}))
