import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(26, 34),
  },
  divider: {
    borderColor: Theme.Colors.Grayscale.Zero,
  },
  modalContent: {
    padding: spacing(20),
  },
}))
