/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  floatingContainer: {
    display: 'flex',
    position: 'absolute',
    left: '30vw',
    transform: 'translateX(-30vw)',
    bottom: 0,
  },
  floatingWrapper: {
    display: 'flex',
    width: 'auto',
    backgroundColor: '#fff',
    maxWidth: spacing(564),
    height: spacing(101),
    borderRadius: spacing(4),
    border: `1px solid ${Theme.Colors.Primary.Base}`,
    boxShadow: ' 0px 1px 4px rgba(0, 0, 0, 0.16)',
  },
  floatingContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(16, 28, 16, 8),
    marginLeft: spacing(12),
    gap: spacing(8),
  },
  floatingLabels: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(16),
    marginLeft: spacing(4),
  },
  draggable: {
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: spacing(32),
    backgroundColor: Theme.Colors.Primary.Base,

    '&:active': {
      cursor: 'grabbing',
    },
  },
  dotsIcon: {
    fontSize: spacing(20),
  },
  button: {
    padding: spacing(4, '!important'),
    height: 'fit-content',
  },
  redButton: {
    padding: spacing(4, '!important'),
    height: 'fit-content',
    color: `${Theme.Colors.Red.Base} !important`,

    '&:hover': {
      color: `${Theme.Colors.Red.Dark} !important`,
    },
  },
  buttonsContainer: {
    display: 'flex',
    gap: spacing(16),
  },
}))
