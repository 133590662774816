import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    '& .MuiDrawer-paper': {
      width: spacing(392),
      top: spacing(48),
      overflow: 'hidden',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
  header: {
    padding: spacing(16, 10, 10, 32),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    width: spacing(32),
    height: spacing(32),
    fontSize: spacing(Theme.Typography.FontSize.Small),
    borderRadius: '50%',
    backgroundColor: Theme.Colors.Primary.Lightest,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacing(12),
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    width: spacing(24),
    height: spacing(24),
    fontSize: spacing(Theme.Typography.FontSize.Tiny),
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: spacing(16),
    height: spacing(16),
  },
  autocompleteContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
  },
  title: {
    color: Theme.Colors.Primary.Base,
    fontWeight: Theme.Typography.FontWeight.Medium,
  },
  divider: {
    backgroundColor: Theme.Colors.Grayscale.Twelve,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: spacing(32),
    height: '84%',
    overflow: 'hidden',
    marginBottom: spacing(80),
    marginTop: spacing(30),
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(24),
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    marginTop: spacing(20),
    gap: spacing(16),
    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '3px',
      height: '20px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  chipsContainer: {
    display: 'flex',
    gap: spacing(10),
    position: 'fixed',
    top: 125,
    right: 0,
    backgroundColor: '#fff',
    height: spacing(80),
    width: spacing(392),
    padding: spacing(16, 26),
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: '1px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  inputsWrapper: {
    display: 'flex',
    gap: spacing(14),
  },
  dotDivision: {
    marginTop: spacing(32),
  },
  doubleInput: {
    width: '44%',
  },
  chip: {
    backgroundColor: Theme.Colors.Primary.Lightest,
    color: Theme.Colors.Primary.Base,
    borderRadius: spacing(24),
    height: spacing(24),
    padding: spacing(4, 8),
    textTransform: 'none',
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: Theme.Typography.FontSize.XXSmall,
    lineHeight: '112.5%',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  chipEnabled: {
    backgroundColor: Theme.Colors.Primary.Base,
    color: Theme.Colors.Primary.Lightest,
  },
  footer: {
    boxSizing: 'border-box',
    position: 'absolute',
    width: '384px',
    height: '64px',
    left: '0px',
    bottom: '50px',
    background: '#FFFFFF',
    borderTop: '1px solid #E3E3E3',
  },
  muiButton: {
    height: '40px',
    width: 'auto',
    padding: '10px 16.5px',
    borderRadius: '20px',
    textTransform: 'none',
    fontWeight: '500',
    position: 'absolute',
    left: '24.96%',
    right: '17.84%',
    top: '25%',
    bottom: '25%',
    alignItems: 'center',
  },
  muiArrow: {
    height: '40px',
    width: 'auto',
    borderRadius: '20px',
    position: 'absolute',
    left: '81.84%',
    right: '12.83%',
    top: '25%',
    bottom: '25%',
  },
}))
