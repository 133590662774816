import { STEP_STATUS } from '_/utils/constants/service-order'

export const INFO_MESSAGES = {
  CANNOT_CHANGE_APPROVED_BUDGET:
    'Esse orçamento já foi APROVADO. Caso necessite de alguma alteração, favor entrar em contato com a Refera.',
  CANNOT_CHANGE_REFUSED_BUDGET:
    'Esse orçamento já foi RECUSADO. Caso necessite de alguma alteração, favor entrar em contato com a Refera.',
  INVALID_BUDGET:
    'Infelizmente esta ação não pode mais ser executada porque este orçamento já não é mais válido. Por favor contate nossa empresa para mais detalhes.',
  ALREADY_APPROVED_BUDGET: 'Este orçamento já está aprovado por você.',
  ALREADY_REFUSED_BUDGET: 'Este orçamento já está recusado por você.',
  ALREADY_APPROVED_PROPOSAL: 'Esta proposta já foi aprovada!',
  ALREADY_APPROVED_PROPOSAL_CONTACT:
    'Esta proposta já está aprovada. Favor contatar a Refera para informar esta sua decisão, indicando o código do orçamento ID',
  ALREADY_REFUSED_PROPOSAL_CONTACT:
    'Esta proposta já está recusada. Favor contatar a Refera para informar esta sua decisão, indicando o código do orçamento ID',
  ALREADY_REFUSED_PROPOSAL: 'Esta proposta já foi recusada!',
  CANNOT_EDIT_ERROR:
    'Esta ação não pode ser executada no momento. Por favor contate nossa empresa para mais detalhes.',
  CONFIRM_ACTION: 'Você tem certeza que deseja executar esta ação?',
}

export const stepStatusRefusedBudget = [
  STEP_STATUS.BUDGET_REFUSED_PAYER,
  STEP_STATUS.PAYER_REFUSED_INNER_BUDGET,
]

export const stepStatusBudgetIsNotRefusedYet = [
  STEP_STATUS.WAITING_FOR_PAYER_APPROVAL,
  STEP_STATUS.WAITING_PAYER_APPROVE_INNER_BUDGET,
  STEP_STATUS.BUDGET_APPROVED_PAYER,
  STEP_STATUS.PAYER_APPROVED_INNER_BUDGET,
]

export const stepStatusApprovedBudget = [
  STEP_STATUS.BUDGET_APPROVED_PAYER,
  STEP_STATUS.PAYER_APPROVED_INNER_BUDGET,
]

export const stepStatusBudgetIsNotApprovedYet = [
  STEP_STATUS.WAITING_FOR_PAYER_APPROVAL,
  STEP_STATUS.WAITING_PAYER_APPROVE_INNER_BUDGET,
  STEP_STATUS.BUDGET_REFUSED_PAYER,
  STEP_STATUS.PAYER_REFUSED_INNER_BUDGET,
]

export const stepStatusRefusedOrApprovedBudget = [
  STEP_STATUS.BUDGET_REFUSED_PAYER,
  STEP_STATUS.PAYER_REFUSED_INNER_BUDGET,
  STEP_STATUS.BUDGET_APPROVED_PAYER,
  STEP_STATUS.PAYER_APPROVED_INNER_BUDGET,
]
