import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  dialog: {
    padding: spacing(20),
    '& .MuiDialog-paperWidthSm': {
      width: spacing(280),
    },
  },
  description: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: palette.gray[64],
  },
  differentTradesmanActions: {
    display: 'grid',
    gridTemplate: 'auto / 1fr auto auto',
  },
  cancelButton: {
    width: 'auto',
    justifySelf: 'start',
  },
}))
