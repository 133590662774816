import React, { useCallback, useEffect, useMemo, useState } from 'react'

import useStyles from './styles'
import { PageHeader } from './PageHeader'
import { Datagrid } from '@refera/ui-web'
import { Grid } from '@mui/material'
import { confirmationMenuTypes, leadProviderListColumns } from './constants'
import { getLeadProviders } from '_/modules/provider/actions'
import { TABLE_SX } from '_/views/finance/utils/constants'
import { useDispatch } from 'react-redux'

import humps from 'humps'
import { ListLeadProvidersFilters } from './ListLeadProvidersFilters'
import { ListLeadProvidersFloatingMenu } from './ListLeadProvidersFloatingMenu'
import { ImportProvidersConfirmationScreen } from './ImportProvidersConfirmationScreen'

const formatFiltersPayload = filters => {
  const payload = { ...filters }

  if (payload?.mainServices) {
    payload.mainServices = payload.mainServices.map(service => service.id).join()
  }

  return payload
}

export default function ListLeadProviders() {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [leadProviders, setLeadProviders] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [orderBy, setOrderBy] = useState('name')
  const [menuType, setMenuType] = useState('default')
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [filters, setFilters] = useState({})
  const [selectedItems, setSelectedItems] = useState([])

  const handleOrderBy = useCallback(orderObj => {
    const order = orderObj[0]
    if (!order) {
      handleOrderBy('name')
      return
    }
    const { field, sort } = order
    const decamelizedField = humps.decamelize(field)
    sort === 'desc' ? setOrderBy(`-${decamelizedField}`) : setOrderBy(decamelizedField)
  }, [])

  const handleGetLeadProviders = useCallback(
    (filterParams = null) => {
      const formattedPayload = formatFiltersPayload(filterParams || filters)

      dispatch(getLeadProviders({ ...formattedPayload, page: page + 1, pageSize, orderBy }))
        .then(data => {
          setLeadProviders(data?.results || [])
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))

      if (filterParams) {
        setFilters(filterParams)
      }
    },
    [page, pageSize, orderBy, filters]
  )

  const handleFloatingModalNavigation = useCallback(type => {
    if (confirmationMenuTypes.includes(type)) setMenuType(type)
  }, [])

  useEffect(() => {
    handleGetLeadProviders()
  }, [page, pageSize, orderBy])

  const renderFilters = useMemo(
    () => (
      <ListLeadProvidersFilters
        open={filterDrawerOpen}
        close={() => setFilterDrawerOpen(false)}
        applyFilters={handleGetLeadProviders}
        filters={filters}
        setFilters={setFilters}
      />
    ),
    [filterDrawerOpen, filters, handleGetLeadProviders]
  )

  const handleGoBackAndUpdate = useCallback(
    ({ update = false }) => {
      setMenuType('default')

      if (update) {
        handleGetLeadProviders()
        setSelectedItems([])
      }
    },
    [menuType, handleGetLeadProviders]
  )

  const renderConfirmationScreen = useMemo(() => {
    if (menuType === 'import') {
      return (
        <ImportProvidersConfirmationScreen
          menuType={menuType}
          selectedIds={selectedItems}
          providers={leadProviders}
          goBack={handleGoBackAndUpdate}
        />
      )
    }
    return null
  }, [menuType, selectedItems, leadProviders, handleGoBackAndUpdate])

  return (
    <main className={styles.container}>
      {menuType === 'default' && (
        <>
          {renderFilters}
          <PageHeader isLoading={isLoading} setFilterDrawerOpen={setFilterDrawerOpen} />

          <Grid className={styles.tableContainer}>
            <Grid className={styles.mainTable}>
              <Datagrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                }}
                onSortModelChange={handleOrderBy}
                loading={isLoading}
                rows={leadProviders}
                columns={leadProviderListColumns}
                paginationMode="server"
                onPageChange={setPage}
                page={page}
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                rowCount={leadProviders.length || 0}
                sx={TABLE_SX}
                noRowsLabel="Nenhum resultado encontrado"
                checkboxSelection
                onSelectionModelChange={setSelectedItems}
                selectionModel={selectedItems}
              />
            </Grid>
          </Grid>
          {selectedItems?.length > 0 && (
            <ListLeadProvidersFloatingMenu
              selectedItems={selectedItems}
              handleButtonsActions={handleFloatingModalNavigation}
            />
          )}
        </>
      )}
      {renderConfirmationScreen}
    </main>
  )
}
