import React, { useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Divider } from '@mui/material'
import { ArrowRight3 as ArrowIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Svg from '_components/svg'
import Button from '_components/button'

import { dataAttributesValidator } from './validators'
import useStyles from './styles'

const WidgetSecondaryAction = ({ dataAttributes, description, onClick, ...props }) => {
  const styles = useStyles()

  return (
    <>
      <Divider />
      <Button
        onClick={onClick}
        classes={{
          label: styles.content,
          root: styles.button,
        }}
        {...dataAttributes}
      >
        {props.icon && <props.icon className={styles.smallIcon} color={Theme.Colors.Red.Base} />}
        <Typography
          className={classnames(styles.description, {
            [styles.redColor]: !props?.icon,
          })}
        >
          {description}
        </Typography>
        <ArrowIcon className={styles.arrowIcon} color={Theme.Colors.Grayscale.SixtyFour} />
      </Button>
    </>
  )
}

const WidgetTradesman = ({ dataAttributes, icon, title, quantity, onClick, children }) => {
  const styles = useStyles()

  const renderIcon = () => {
    if (typeof icon === 'string' && icon.endsWith('.gif')) {
      return <img src={icon} alt="GIF" className={styles.gif} />
    }
    return <Svg icon={icon} className={styles.icon} />
  }

  const descriptionStyle = useMemo(
    () => (title === 'Chamados URGENTES' ? styles.redDescription : styles.description),
    [title]
  )

  return (
    <Grid className={styles.box}>
      <Button
        onClick={onClick}
        classes={{
          label: styles.mainContent,
          root: styles.button,
        }}
        {...dataAttributes}
      >
        {renderIcon()}
        <Typography className={styles.number}>{quantity}</Typography>
        <Typography className={descriptionStyle}>{title}</Typography>
        <ArrowIcon className={styles.arrowIcon} color={Theme.Colors.Grayscale.SixtyFour} />
      </Button>
      {children}
    </Grid>
  )
}

WidgetTradesman.propTypes = {
  children: PropTypes.node,
  dataAttributes: dataAttributesValidator,
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      viewBox: PropTypes.string,
      id: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  onClick: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

WidgetTradesman.defaultProps = {
  children: null,
  dataAttributes: undefined,
  icon: undefined,
}

WidgetSecondaryAction.propTypes = {
  dataAttributes: dataAttributesValidator,
  description: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      viewBox: PropTypes.string,
      id: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  onClick: PropTypes.func.isRequired,
}

WidgetSecondaryAction.defaultProps = {
  dataAttributes: undefined,
  icon: undefined,
}

WidgetTradesman.WidgetSecondaryAction = WidgetSecondaryAction

export default WidgetTradesman
