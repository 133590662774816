import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  label: {
    color: `${Theme.Colors.Grayscale.OneThousand} !important`,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: `${Theme.Typography.FontSize.Tiny}px !important`,
    width: '100%',
    marginBottom: '-16px !important',
  },
  wrapper: {
    display: 'flex',
    gap: '14px',
  },
  dotDivision: {
    marginTop: '32px',
  },
  error: {
    color: Theme.Colors.Red.Base,
    marginTop: '8px',
    fontSize: `${Theme.Typography.FontSize.Tiny}px`,
  },
  required: {
    color: Theme.Colors.Red.Base,
    marginLeft: '4px',
  },
}))
