import { Record } from 'immutable'
import { createReducer } from '_utils/redux'
import { GET_TRADESMAN_SEQUENCE } from './actions'

const INITIAL_STATE = new Record({ tradesmanSequenceResults: [] })()

const tradesmanSequences = createReducer(INITIAL_STATE, {
  [GET_TRADESMAN_SEQUENCE.FULFILLED]: (state, { payload }) =>
    state.set('tradesmanSequenceResults', payload),
})

export default tradesmanSequences
