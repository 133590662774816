import { schema } from 'normalizr'

import Model from './model'

class HelpRequest extends Model({
  activeBudget: undefined,
  agency: undefined,
  budget: undefined,
  createdAt: undefined,
  datetimeIntermediaryAvailable: undefined,
  datetimeNewestMessage: undefined,
  datetimeResolved: undefined,
  datetimeViewedByIntermediary: undefined,
  days: undefined,
  fromTo: undefined,
  helpRequestParent: undefined,
  id: undefined,
  intermediaryAvailable: undefined,
  sender: undefined,
  serviceOrder: undefined,
  serviceOrderStatus: undefined,
  subject: undefined,
  subjectTranslated: undefined,
  unreadMessages: undefined,
  updatedAt: undefined,
  serviceOrderStepStatus: undefined,
  pendingItems: undefined,
  message: undefined,
  registryType: undefined,
  expectedDeadline: undefined,
  typeSubject: undefined,
  locked: undefined,
  typeDelay: undefined,
}) {}

const helpRequestNewSchema = new schema.Entity(
  'helpRequest',
  {},
  {
    processStrategy: entity => {
      return new HelpRequest(entity)
    },
  }
)

const helpRequestSchema = [helpRequestNewSchema]

export { helpRequestSchema, HelpRequest as default }
