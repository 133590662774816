import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: spacing(8),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
    width: '94%',
    maxWidth: spacing(504),
    padding: spacing(8),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    fontSize: Theme.Typography.FontSize.Large,
    alignSelf: 'flex-end',
    cursor: 'pointer',
  },
  modalIcon: {
    fontSize: Theme.Typography.FontSize.XXLarge,
  },
  modalIconBackground: {
    padding: spacing(16),
    borderRadius: '50%',
  },
  contractIconBackgroundColor: {
    backgroundColor: Theme.Colors.Primary.Lightest,
  },
  warningIconBackgroundColor: {
    backgroundColor: Theme.Colors.Yellow.Lightest,
  },
  successIconBackgroundColor: {
    backgroundColor: Theme.Colors.Green.Base,
  },
  header: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: Theme.Typography.FontSize.Medium,
    fontWeight: Theme.Typography.FontWeight.Medium,
    color: Theme.Colors.Grayscale.OneThousand,
    textAlign: 'center',
  },
  headerVariant1: {
    lineHeight: spacing(22),
    letterSpacing: '-2%',
  },
  headerVariant2: {
    lineHeight: spacing(26.04),
  },
  paragraph: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Small,
    color: Theme.Colors.Grayscale.SixtyFour,
    lineHeight: spacing(20),
    textAlign: 'center',
  },
  actionButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: spacing(16),
  },
}))
