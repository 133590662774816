/* eslint-disable react/forbid-prop-types */
import React from 'react'
import CurrencyInput from 'react-currency-input'
import PropTypes from 'prop-types'

import useStyles from './styles'

const CurrencyTextField = ({ disabled, data, name, setValue, ...otherProps }) => {
  const styles = useStyles({ disabled })

  const formatter = e => {
    return Number(e.replace('R$', '').replace('.', '').replace(',', '.'))
  }

  return (
    <CurrencyInput
      disabled={disabled}
      className={styles.currencyField}
      decimalSeparator=","
      thousandSeparator="."
      placeholder="0,00"
      prefix="R$"
      onChange={e => e && setValue(`${name}`, formatter(e))}
      value={data?.[name]}
      {...otherProps}
    />
  )
}

CurrencyTextField.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.any,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  setValue: PropTypes.func,
}

CurrencyTextField.defaultProps = {
  placeholder: 'R$ 0,00',
  disabled: false,
  id: '',
  register: () => {},
  setValue: () => {},
}

export default CurrencyTextField
