import React, { useEffect, useRef, useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, useConfirm } from '@refera/ui-web'
import { Grid, Typography, TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import AccordionInfo from '_/components/accordion/accordion-info'
import Accordion from '_/views/finance/components/Accordion'
import Autocomplete from '@mui/material/Autocomplete'
import useModal from '_hooks/use-toggle'
import * as Modal from '_components/modal/generic-modal'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'
import FormHelperText from '@mui/material/FormHelperText'

import useStyles from './styles'
import categoryStyles from '../styles'
import { HTMLEditor } from '_/components/inputs'

const CategoryEdit = ({ data, services, category, handleUpdate = () => {} }) => {
  const styles = useStyles()
  const pageContextStyles = categoryStyles()
  const formRef = useRef()
  const {
    setValue,
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const [dialogInfo, setDialogInfo] = useState()
  const [newService, setNewService] = useState()
  const [modalOpen, handleModal] = useModal()

  const { isConfirmed } = useConfirm()

  const handleServiceSelect = useCallback(() => {
    if (newService) {
      const updatedServices = [...data?.services, { id: newService.id, name: newService.name }]
      setValue('services', updatedServices)
      reset({
        ...data,
        services: updatedServices,
      })
    }
    handleModal()
  }, [data, newService, setValue, reset, handleModal])

  const handleDeleteService = useCallback(
    async service => {
      setDialogInfo({ message: 'Você tem certeza que deseja executar esta ação?' })
      const confirmed = await isConfirmed()

      if (data?.services && confirmed) {
        const updatedServices = data.services.filter(
          currentService => currentService.id !== service.id
        )
        setValue('services', updatedServices)
        reset({
          ...data,
          services: updatedServices,
        })
      }
    },
    [data, setValue, reset]
  )

  const getOptionLabel = useCallback(
    option => {
      return option?.name
    },
    [services]
  )

  const getOptionSelected = useCallback(
    (option, currentValue) => {
      return option.id === currentValue
    },
    [services]
  )

  const getOptionDisabled = useCallback(
    option => {
      return data?.services && data?.services.some(item => item.id === option.id)
    },
    [data]
  )

  useEffect(() => {
    reset({
      name: category?.name,
      isActive: category?.isActive,
      allowsNoAttachments: category?.allowsNoAttachments,
      allowsTheDistance: category?.allowsTheDistance,
      budgeteerMeets: category?.budgeteerMeets,
      services: category?.services || [],
      noticesToProvider: category?.noticesToProviderJson ?? null,
      servicesQtExample: category?.servicesQtExampleJson ?? null,
    })
  }, [category])

  return (
    <form className={styles.categoryForm} ref={formRef} onSubmit={handleSubmit(handleUpdate)}>
      <Typography className={styles.title}>Editar Categoria</Typography>
      <Typography className={styles.label}>Nome</Typography>
      <TextField
        error={errors?.name}
        id={category?.name}
        name="name"
        inputRef={register({ required: true })}
        className={styles.textfield}
        defaultValue={category?.name}
        variant="standard"
        InputProps={{
          style: {
            fontSize: 16,
          },
        }}
      />
      {errors?.name && (
        <FormHelperText style={{ fontSize: '12px', marginTop: 0, color: 'red' }}>
          Campo requerido.
        </FormHelperText>
      )}
      <Controller
        name="isActive"
        control={control}
        defaultValue={category?.isActive}
        render={({ value, onChange }) => {
          return (
            <FormControlLabel
              label="Ativo"
              control={
                <Checkbox
                  checked={value}
                  defaultChecked={value}
                  onChange={e => onChange(e.target.checked)}
                />
              }
            />
          )
        }}
      />
      <Controller
        name="allowsTheDistance"
        control={control}
        defaultValue={category?.allowsTheDistance}
        render={({ value, onChange }) => {
          return (
            <FormControlLabel
              label="Permite orçamento à distância"
              control={
                <Checkbox
                  checked={value}
                  defaultValue={category?.allowsTheDistance}
                  onChange={e => onChange(e.target.checked)}
                />
              }
            />
          )
        }}
      />
      <Controller
        name="allowsNoAttachments"
        control={control}
        defaultValue={category?.allowsNoAttachments}
        render={({ value, onChange }) => {
          return (
            <FormControlLabel
              label="Permite não inserir anexos"
              control={
                <Checkbox
                  checked={value}
                  defaultChecked={value}
                  onChange={e => onChange(e.target.checked)}
                />
              }
            />
          )
        }}
      />
      <Controller
        name="allowsBypass"
        control={control}
        defaultValue={category?.allowsBypass}
        render={({ value, onChange }) => {
          return (
            <FormControlLabel
              label="Permite bypass na revisão do orçamento"
              control={
                <Checkbox
                  checked={value}
                  defaultValue={category?.allowsBypass}
                  onChange={e => onChange(e.target.checked)}
                />
              }
            />
          )
        }}
      />
      <Controller
        name="budgeteerMeets"
        control={control}
        defaultValue={category?.budgeteerMeets}
        render={({ value, onChange }) => {
          return (
            <FormControlLabel
              label="Orçamentista atende"
              control={
                <Checkbox
                  checked={value}
                  defaultValue={category?.budgeteerMeets}
                  onChange={e => onChange(e.target.checked)}
                />
              }
            />
          )
        }}
      />
      <Controller
        name="pool"
        control={control}
        defaultValue={category?.pool}
        render={({ value, onChange }) => {
          return (
            <FormControlLabel
              label="Pool habilitado"
              control={
                <Checkbox
                  checked={value}
                  defaultValue={category?.allowsBypass}
                  onChange={e => onChange(e.target.checked)}
                />
              }
            />
          )
        }}
      />

      <HTMLEditor
        name="noticesToProvider"
        label="Avisos ao prestador"
        labelClasses={pageContextStyles.htmlInputLabel}
        style={{ marginTop: '12px' }}
        defaultValue={category?.noticesToProviderJson ?? null}
      />

      <HTMLEditor
        name="servicesQtExample"
        label="Exemplo de qtde. de serviços"
        labelClasses={pageContextStyles.htmlInputLabel}
        style={{ marginTop: '12px' }}
        defaultValue={category?.servicesQtExampleJson ?? null}
      />

      <Accordion id="category-services" title="Serviços vinculados" className={styles.accordion}>
        <Grid className={styles.containerAdd}>
          {data?.services?.map((item, index) => (
            <AccordionInfo
              // eslint-disable-next-line react/no-array-index-key
              isFirst={index === 0}
              key={item.id}
              values={item}
              type="service"
              isEditing
              handleDeleteValue={handleDeleteService}
            />
          ))}
          <Button
            style={{ width: '150px', marginTop: '20px' }}
            variant="contained"
            color="primary"
            onClick={handleModal}
          >
            +Adicionar registro
          </Button>
        </Grid>
      </Accordion>

      {dialogInfo?.message && <ConfirmRemoveDialog message={dialogInfo.message} />}

      <Modal.Root open={modalOpen} onClose={() => handleModal()} maxWidth="sm" keepMounted>
        <Modal.TitleModal title="Selecione um serviço" />

        <Modal.Content className={styles.modalContent}>
          <Grid className={{ width: '600px' }}>
            <Controller
              name="services"
              shouldUnregister={false}
              control={control}
              defaultValue=""
              render={() => {
                return (
                  <>
                    <Typography className={styles.serviceLabel}>Serviço</Typography>
                    <Autocomplete
                      disablePortal
                      freeSolo
                      multiple={false}
                      options={services}
                      className={styles.autocomplete}
                      getOptionSelected={getOptionSelected}
                      getOptionLabel={getOptionLabel}
                      getOptionDisabled={getOptionDisabled}
                      noOptionsText="Nenhum serviço encontrado."
                      onChange={(event, newValue) => {
                        setNewService(newValue)
                      }}
                      ListboxProps={{
                        style: {
                          fontSize: '14px',
                          height: '250px',
                        },
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { height: '55px', paddingTop: '13px' },
                          }}
                        />
                      )}
                    />
                  </>
                )
              }}
            />
          </Grid>
        </Modal.Content>

        <Modal.Actions>
          <Modal.ButtonRed onClick={() => handleModal()}>Cancelar</Modal.ButtonRed>
          <Modal.ButtonFullBlue
            disabled={!newService}
            onClick={() => handleServiceSelect(newService)}
          >
            Salvar
          </Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>

      <Button
        style={{ width: '120px', marginTop: '15px' }}
        color="primary"
        variant="primary"
        type="submit"
      >
        Salvar
      </Button>
    </form>
  )
}

export default CategoryEdit
