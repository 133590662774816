import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(4),
  },
  icon: {
    height: spacing(18),
    width: spacing(18),
    marginTop: spacing(-2),
  },
}))
