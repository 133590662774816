/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useParams } from '@reach/router'
import { useConfirm } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  createHelpRequest,
  getMessagesByServiceOrderId,
  getMessageSubjectOptions,
  updateMessage,
} from '_modules/messages/actions'
import { currentServiceOrderSelector, getServiceOrderByID } from '_modules/service-orders/selectors'
import { userSelector } from '_modules/authentication/selectors'
import { getNonOriginalBudgetListSelector } from '_modules/budget/selectors'
import {
  getCurrentMessageByIdSelector,
  getMessageSubjectSelectorList,
} from '_modules/messages/selector'
import { getCompanySelector } from '_modules/company/selector'
import { getCompanyById } from '_modules/company/actions'
import Button from '_components/button'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'

import useStyles from './styles'
import { formatDateTime, FROM_TO_OPTIONS, OPTIONS_TO_INTERMEDIARY_AVAILABLE } from './constants'
import {
  CLEAR_PROVIDER_FIELDS,
  CLEAR_STATE,
  FILL_STATE,
  FORM_FIELD_NAME,
  INITIAL_STATE,
  reducer,
  UPDATE_CHECKBOX,
  UPDATE_STATE,
} from './reducer'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'
import { useToast } from '_/hooks/use-toast'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const subjectToAllowRoleMap = {
  tradesman_to_refera: 'allowTradesman',
  intermediary_to_refera: 'allowCsmanagerCsapprover',
  refera_to_intermediary: 'allowCsrefera',
}

const DISABLED_FIELDS = {
  [FORM_FIELD_NAME.FROM_TO]: false,
  [FORM_FIELD_NAME.SUBJECT]: false,
}

const HelpForm = ({
  closeModal,
  message,
  setMessage,
  onCancel,
  initialState = INITIAL_STATE,
  disabledFields = DISABLED_FIELDS,
  handleUpdate,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [localState, localDispatch] = useReducer(reducer, initialState)
  const { serviceOrderId } = useParams()

  const { role, isTradesman, isIntermediary, isAdmin } = useRolePermission()
  const serviceOrder = useSelector(state => getServiceOrderByID(state, serviceOrderId))
  const filteredSubjectOptionList = useSelector(getMessageSubjectSelectorList)
  const subjectList = useSelector(getMessageSubjectSelectorList)
  const budgetList = useSelector(getNonOriginalBudgetListSelector)
  const company = useSelector(getCompanySelector)
  const user = useSelector(userSelector)
  const currentMessage = useSelector(getCurrentMessageByIdSelector(message?.id))
  const tradesman = useSelector(currentServiceOrderSelector)?.get('tradesmanUser')
  const { showToast } = useToast()
  const [picturesLoading, setPicturesLoading] = useState(false)
  const [editAttachments] = useToggle()
  const { isConfirmed } = useConfirm()
  const [dialogMessage, setDialogMessage] = useState('')
  const [helpRequest, setHelpRequest] = useState(undefined)

  const MENU_PROPS = {
    classes: { paper: styles.subjectField },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  }

  const fromToOptions = useMemo(() => {
    if (!isTradesman && !isAdmin)
      return FROM_TO_OPTIONS.filter(option => option.value !== 'tradesman_to_refera')
    return FROM_TO_OPTIONS
  }, [isTradesman, FROM_TO_OPTIONS])

  const subjectOptionsList = useMemo(() => {
    if (filteredSubjectOptionList?.length)
      return filteredSubjectOptionList.map(option => ({
        label: option.option,
        value: option.status,
        allowCsrefera: option.allowCsrefera,
        allowCsmanagerCsapprover: option.allowCsmanagerCsapprover,
        allowTradesman: option.allowTradesman,
      }))
    return []
  }, [filteredSubjectOptionList])

  const selectedSubjectHaveABudget = useMemo(() => {
    const subjectOption =
      filteredSubjectOptionList?.length &&
      filteredSubjectOptionList?.find(
        option => option?.status === localState[FORM_FIELD_NAME.SUBJECT]
      )
    return subjectOption ? subjectOption?.requiredBudget : false
  }, [localState[FORM_FIELD_NAME.SUBJECT]])

  const budgetOptions = useMemo(() => {
    return (
      selectedSubjectHaveABudget &&
      budgetList
        .map((option, index) => ({
          label: `Orçamento  ${index + 1}`,
          value: option.id,
        }))
        ?.reverse()
    )
  }, [selectedSubjectHaveABudget, budgetList])

  const fromToOrSubjectWasChanged = useCallback(
    (name, value) =>
      (name === FORM_FIELD_NAME.FROM_TO && value !== localState[FORM_FIELD_NAME.FROM_TO]) ||
      (name === FORM_FIELD_NAME.SUBJECT && value !== localState[FORM_FIELD_NAME.SUBJECT]),
    [localState[FORM_FIELD_NAME.FROM_TO], localState[FORM_FIELD_NAME.SUBJECT]]
  )

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      if (fromToOrSubjectWasChanged(name, value)) {
        localDispatch({
          type: CLEAR_PROVIDER_FIELDS,
        })
      }
      localDispatch({ type: UPDATE_STATE, payload: { name, value } })
    },
    [fromToOrSubjectWasChanged]
  )

  const handleMultiSelection = useCallback((newValue, value) => {
    localDispatch({
      type: UPDATE_STATE,
      payload: { name: FORM_FIELD_NAME.SUBJECT, value: value?.value },
    })
  }, [])

  const [isChecked, setIsChecked] = useState()

  const [loading, setLoading] = useState(false)

  const onChangeCheckbox = useCallback(({ target: { name, checked } }) => {
    localDispatch({ type: UPDATE_CHECKBOX, payload: { name } })
    setIsChecked(checked)
  }, [])

  const handleGoToMovidesk = useCallback(() => {
    if (isAdmin) {
      return 'https://refera.movidesk.com/kb/article/367569/o-que-pausa-um-chamado'
    }

    if (isIntermediary) {
      return 'https://refera.movidesk.com/kb/article/368897/o-que-e-um-chamado-pausado'
    }

    if (isTradesman) {
      return 'https://refera.movidesk.com/kb/article/367572/o-que-e-um-chamado-pausado'
    }
  }, [isAdmin, isIntermediary, isTradesman])

  const onSuccessSubmit = useCallback(() => {
    setMessage({})
    localDispatch({ type: CLEAR_STATE })
    closeModal()

    if (helpRequest?.message) {
      const url = handleGoToMovidesk()
      showToast({
        type: 'info',
        message: (
          <Typography component="span">
            {helpRequest?.message} <br />
            <Typography component="span">
              <a href={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                Clique aqui para saber mais
              </a>
            </Typography>
          </Typography>
        ),
      })
    } else {
      showToast({ type: 'success' })
    }
  }, [helpRequest])

  const onCancelModal = useCallback(() => {
    localDispatch({ type: CLEAR_STATE })
    onCancel()
  }, [])

  const companyId = useMemo(() => {
    if (localState[FORM_FIELD_NAME.BUDGET]) {
      const companyTargetId = budgetList.find(
        budgetTarget => budgetTarget.id === localState[FORM_FIELD_NAME.BUDGET]
      )?.tradesman?.company
      return companyTargetId
    }
    return null
  }, [localState[FORM_FIELD_NAME.BUDGET]])

  const senderList = useMemo(() => {
    return (
      localState[FORM_FIELD_NAME.BUDGET] &&
      company[companyId]?.staffUsers?.map(option => ({
        label: option.name,
        value: { id: option.id, name: option.name },
      }))
    )
  }, [localState[FORM_FIELD_NAME.BUDGET], company, companyId])

  // If the user is a cs_refera put a default value in 'De / Para' field
  useEffect(() => {
    if (isAdmin && !localState[FORM_FIELD_NAME.FROM_TO]) {
      localDispatch({
        type: UPDATE_STATE,
        payload: { name: FORM_FIELD_NAME.FROM_TO, value: 'refera_to_intermediary' },
      })
    }
  }, [isAdmin])

  useEffect(() => {
    const params = {
      stepStatus: serviceOrder?.stepStatus,
      typeSubject: 'help_request',
    }
    dispatch(getMessageSubjectOptions(params))
  }, [])

  useEffect(() => {
    if (localState[FORM_FIELD_NAME.BUDGET]) {
      dispatch(getCompanyById(companyId))
    }
  }, [localState[FORM_FIELD_NAME.BUDGET], companyId])

  useEffect(() => {
    if (message?.id) dispatch(getMessagesByServiceOrderId(serviceOrderId))
  }, [message])

  const [pendingAttachments, setPendingAttachments] = useState([])

  useEffect(() => {
    if (message?.pendingAttachment) {
      setPendingAttachments(
        message?.pendingAttachment?.map(file => ({ file: file?.file, id: file?.id }))
      )
    }
  }, [message?.pendingAttachment])

  const attachmentsImages = useMemo(
    () =>
      pendingAttachments.filter(file =>
        file instanceof File ? file.type !== 'application/pdf' : file.file.indexOf('.pdf') === -1
      ),
    [pendingAttachments]
  )

  useEffect(() => {
    if (message?.subject && currentMessage) {
      const payload = {
        [FORM_FIELD_NAME.RESOLVED]: message.resolved,
        [FORM_FIELD_NAME.MESSAGE]: message.message,
        [FORM_FIELD_NAME.ANSWER]: message.answer,
        [FORM_FIELD_NAME.INTERMEDIARY_AVAILABLE]: currentMessage?.intermediaryAvailable,
      }
      localDispatch({ type: FILL_STATE, payload })
    }
  }, [message?.subject, currentMessage])

  const sender = useMemo(() => {
    if (
      isTradesman ||
      isIntermediary ||
      (isAdmin && localState[FORM_FIELD_NAME.FROM_TO] === 'refera_to_intermediary')
    )
      return user.id
    if (isAdmin) {
      switch (localState[FORM_FIELD_NAME.FROM_TO]) {
        case 'intermediary_to_refera': {
          const { responsible } = serviceOrder
          return responsible?.get('id')
        }

        case 'tradesman_to_refera': {
          const { tradesmanUser } = serviceOrder
          if (selectedSubjectHaveABudget && localState[FORM_FIELD_NAME.SENDER]) {
            return localState[FORM_FIELD_NAME.SENDER].id
          }
          return tradesmanUser
        }
        default:
          return null
      }
    }

    return null
  }, [isTradesman, isIntermediary, isAdmin, localState, serviceOrder, selectedSubjectHaveABudget])

  const handleErrorOnSubmit = useCallback(() => {
    setLoading(prevState => !prevState)
    showToast({ type: 'error', message: 'Não foi possível executar esta ação, tente novamente.' })
  }, [loading])

  const handleSave = newHelpRequestData => {
    dispatch(createHelpRequest(newHelpRequestData))
      .then(helpRequestData => {
        setHelpRequest(helpRequestData)
        handleUpdate()
        onSuccessSubmit()
      })
      .catch(() => {
        handleErrorOnSubmit()
      })
  }

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      setPicturesLoading(prevState => !prevState)
      const newAttachments = pendingAttachments.filter(item => item?.id === undefined)

      const payload = {
        ...localState,
        origin: 'help_request',
        type: 'request',
        serviceOrder: Number(serviceOrderId),
        sender,
        senderName: localState[FORM_FIELD_NAME.SENDER]?.name,
        ...(isIntermediary && { [FORM_FIELD_NAME.INTERMEDIARY_AVAILABLE]: true }),
      }

      if (message?.subject) {
        const updatedPayload = {
          ...payload,
          subject: message.subject,
          recipient: user.id,
          recipientName: user.name,
          pendingAttachment: newAttachments,
        }
        dispatch(updateMessage(message.id, updatedPayload))
          .then(() => {
            handleUpdate()
            onSuccessSubmit()
          })
          .catch(() => {
            handleErrorOnSubmit()
          })
      } else {
        const updatedPayload = {
          ...payload,
          pendingAttachment: newAttachments,
        }

        if (
          updatedPayload?.fromTo === 'tradesman_to_refera' ||
          updatedPayload?.fromTo === 'refera_to_intermediary'
        ) {
          setMessage(
            'Essa alteração poderá fazer esse registro pausar o prazo do chamado ao qual está vinculado. Você tem certeza que deseja continuar?'
          )

          const confirmed = await isConfirmed()

          if (confirmed) {
            handleSave(updatedPayload)
          } else {
            setLoading(prevState => !prevState)
            setPicturesLoading(prevState => !prevState)
          }
        } else {
          handleSave(updatedPayload)
        }
      }
    },
    [
      sender,
      isIntermediary,
      localState,
      message,
      pendingAttachments,
      selectedSubjectHaveABudget,
      serviceOrderId,
      tradesman,
      user,
      dispatch,
      helpRequest,
      loading,
    ]
  )

  const subjectTitle = useMemo(() => {
    if (message?.subject && subjectList.length > 0)
      return subjectList.find(option => option.status === message.subject).option
    return ''
  }, [message?.subject, subjectList])

  const handleRemoveAttachments = useCallback(
    async index => {
      setDialogMessage('Você deseja deletar este item?')
      const confirmed = await isConfirmed()
      if (confirmed) {
        const currentFile = attachmentsImages[index]
        if (currentFile?.id) {
          setPendingAttachments(old => old.filter(current => current.id !== currentFile.id))
        } else {
          setPendingAttachments(old =>
            old.filter(current => current.lastModified !== currentFile?.lastModified)
          )
        }
      }
    },
    [pendingAttachments, setDialogMessage]
  )

  useEffect(() => {
    if (isIntermediary)
      localDispatch({
        type: UPDATE_STATE,
        payload: { name: FORM_FIELD_NAME.FROM_TO, value: 'intermediary_to_refera' },
      })
  }, [isIntermediary])
  return (
    <>
      <Dialog
        open
        maxWidth="xl"
        disableEscapeKeyDown
        onClose={closeModal}
        PaperComponent={PaperComponent}
        TransitionComponent={Transition}
        className={styles.dialog}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle className={styles.titleBlue} id="draggable-dialog-title">
            Item de Ajuda
          </DialogTitle>
          <DialogContent className={styles.container}>
            {message?.subject && (
              <>
                <Grid container direction="column" className={styles.messageWrapper}>
                  <Typography className={styles.messageTitle}>{subjectTitle}</Typography>
                  {message?.resolved || message?.senderId !== user?.id ? (
                    <Typography className={styles.messageBody}>{message.message}</Typography>
                  ) : (
                    <TextField
                      multiline
                      fullWidth
                      required
                      name={FORM_FIELD_NAME.MESSAGE}
                      value={localState[FORM_FIELD_NAME.MESSAGE]}
                      onChange={onChange}
                      className={styles.editMessage}
                      placeholder="Escreva aqui uma pergunta."
                      defaultValue={message.message}
                      inputProps={{
                        maxLength: 255,
                      }}
                      variant="outlined"
                      InputProps={{ style: { fontSize: 20 } }}
                    />
                  )}
                  <Typography className={styles.messageFooter}>{`${
                    message.senderName || '-'
                  } - ${formatDateTime(message.createdAt)}`}</Typography>
                </Grid>
                <Grid container className={styles.answerContainer}>
                  <Typography className={styles.labels}>
                    Resposta <b className={styles.required}> *</b>
                  </Typography>

                  <TextField
                    multiline
                    fullWidth
                    required={isChecked}
                    rows={5}
                    name={FORM_FIELD_NAME.ANSWER}
                    value={localState[FORM_FIELD_NAME.ANSWER]}
                    onChange={onChange}
                    placeholder={`Escreva aqui ${
                      message?.subject ? 'uma resposta para o prestador' : 'o seu item de ajuda'
                    }.`}
                    defaultValue={message?.answer ? message?.answer : ''}
                    inputProps={{
                      maxLength: 255,
                    }}
                    variant="outlined"
                    InputProps={{ style: { fontSize: 20 } }}
                  />
                </Grid>
                <FormControlLabel
                  className={styles.checkbox}
                  required
                  onChange={onChangeCheckbox}
                  control={
                    <Checkbox
                      name={FORM_FIELD_NAME.RESOLVED}
                      style={{
                        transform: 'scale(1.2)',
                        color: '#1671ED',
                      }}
                    />
                  }
                  label={
                    <Typography variant="h4" color="textSecondary">
                      Resolvido
                    </Typography>
                  }
                />
              </>
            )}
            {!message?.subject && (
              <>
                <Grid container>
                  <Grid item sm={12}>
                    <Typography className={styles.labels}>
                      De / Para<b className={styles.required}> *</b>
                    </Typography>
                  </Grid>
                  <FormControl item sm={12} fullWidth required>
                    <Select
                      className={styles.subjectField}
                      MenuProps={MENU_PROPS}
                      name={FORM_FIELD_NAME.FROM_TO}
                      value={localState[FORM_FIELD_NAME.FROM_TO]}
                      onChange={onChange}
                      disabled={isIntermediary || disabledFields[FORM_FIELD_NAME.FROM_TO]}
                      required
                    >
                      {fromToOptions?.map(option => {
                        return (
                          <MenuItem key={option?.value} value={option?.value}>
                            {option?.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container>
                  <Grid item sm={12} className={styles.inlineLabel}>
                    <Typography className={styles.labels}>
                      Assunto<b className={styles.required}> *</b>
                    </Typography>
                  </Grid>
                  <FormControl item sm={12} fullWidth required>
                    <Autocomplete
                      id={FORM_FIELD_NAME.SUBJECT}
                      options={(subjectOptionsList || [])
                        .filter(option => {
                          const allowRole =
                            subjectToAllowRoleMap[localState[FORM_FIELD_NAME.FROM_TO]]
                          return allowRole ? option[allowRole] : true
                        })
                        .map(option => ({
                          value: option?.value,
                          label: option?.label,
                        }))}
                      getOptionLabel={option => option?.name || option?.label}
                      multiple={false}
                      required
                      noOptionsText="Nenhum assunto encontrado com esse nome"
                      onChange={handleMultiSelection}
                      renderInput={params => <TextField {...params} required />}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-between">
                  {selectedSubjectHaveABudget && (
                    <Grid container item md={5}>
                      <Grid item sm={12} className={styles.inlineLabel}>
                        <Typography className={styles.labels}>
                          Orçamento<b className={styles.required}> *</b>
                        </Typography>
                      </Grid>
                      <FormControl item sm={12} fullWidth>
                        <Select
                          className={styles.subjectField}
                          MenuProps={MENU_PROPS}
                          name={FORM_FIELD_NAME.BUDGET}
                          value={localState[FORM_FIELD_NAME.BUDGET]}
                          onChange={onChange}
                          required
                        >
                          {budgetOptions?.map(option => {
                            return (
                              <MenuItem key={option?.value} value={option?.value}>
                                {option?.label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {senderList?.length > 0 && (
                    <Grid container item md={5}>
                      <Grid item sm={12}>
                        <Typography className={styles.labels}>
                          Solicitante<b className={styles.required}> *</b>
                        </Typography>
                      </Grid>
                      <FormControl item sm={12} fullWidth>
                        <Select
                          className={styles.subjectField}
                          MenuProps={MENU_PROPS}
                          name={FORM_FIELD_NAME.SENDER}
                          value={localState[FORM_FIELD_NAME.SENDER]}
                          onChange={onChange}
                          required
                        >
                          {senderList?.map(option => {
                            return (
                              <MenuItem key={option?.value} value={option?.value}>
                                {option?.label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid container style={{ marginTop: 12 }}>
                  <Grid item sm={12}>
                    <Typography className={styles.labels}>
                      Mensagem<b className={styles.required}> *</b>
                    </Typography>
                  </Grid>

                  <TextField
                    multiline
                    fullWidth
                    rows={5}
                    name={FORM_FIELD_NAME.MESSAGE}
                    placeholder="Escreva aqui uma resposta para o prestador"
                    inputProps={{
                      maxLength: 500,
                    }}
                    variant="outlined"
                    InputProps={{ style: { fontSize: 16 } }}
                    onChange={onChange}
                    required
                  />

                  <ConfidentialDataWarning style={{ marginTop: 8 }} />
                </Grid>
                <Grid container className={styles.attachment}>
                  <Grid item sm={12}>
                    <Typography className={styles.labels}>Anexos</Typography>
                  </Grid>
                  {picturesLoading ? (
                    <Grid container justify="center" alignItems="center" className={styles.loading}>
                      <CircularProgress size={40} />
                    </Grid>
                  ) : (
                    <>
                      <Attachments
                        label="Imagens e vídeos"
                        readOnly={editAttachments}
                        files={attachmentsImages || []}
                        accept={{
                          'image/jpeg': [
                            '.jpeg',
                            '.png',
                            '.jpg',
                            '.bmp',
                            '.flv',
                            '.mkv',
                            '.mp4',
                            '.mov',
                          ],
                        }}
                        onDrop={files => setPendingAttachments(old => [...old, ...files])}
                        onRemoveItem={index => handleRemoveAttachments(index, false)}
                        onItemClick={index => {
                          window.open(attachmentsImages[index]?.file)
                        }}
                      />
                    </>
                  )}
                </Grid>
                {dialogMessage && <ConfirmRemoveDialog message={dialogMessage} />}
              </>
            )}
            {role === 'cs_refera' && (
              <Grid container className={styles.intermediaryContainer}>
                <Typography className={styles.labels}>
                  Disponibilizar pedido para intermediária?<b className={styles.required}> *</b>
                </Typography>
                <FormControl item sm={12} fullWidth>
                  <Select
                    name={FORM_FIELD_NAME.INTERMEDIARY_AVAILABLE}
                    MenuProps={MENU_PROPS}
                    onChange={onChange}
                    displayEmpty
                    value={localState[FORM_FIELD_NAME.INTERMEDIARY_AVAILABLE]}
                    defaultValue={localState[FORM_FIELD_NAME.INTERMEDIARY_AVAILABLE]}
                    className={styles.subjectField}
                    required
                  >
                    <MenuItem disabled value="">
                      Selecione a resposta
                    </MenuItem>

                    {OPTIONS_TO_INTERMEDIARY_AVAILABLE.map(option => {
                      return (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <Typography className={styles.fLabel}>
                  Opção ativada tornará o item de ajuda visível para a intermediária.
                </Typography>
              </Grid>
            )}
          </DialogContent>

          <DialogActions className={styles.dialogAction}>
            <Button
              className={styles.button}
              onClick={onCancelModal}
              color="red"
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              className={styles.button}
              color="primary"
              variant="contained"
              type="submit"
              disabled={loading}
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {message && <ConfirmRemoveDialog type="warning" description={message} message="Atenção" />}
    </>
  )
}

HelpForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default React.memo(HelpForm)
