import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  modal: {
    marginTop: spacing(10),
    position: 'absolute',
    backgroundColor: 'white',
    padding: spacing(16, 14),
    boxShadow: `${spacing(0, 5, 24)} rgba(0, 0, 0, 0.2)`,
    width: spacing(240),
    maxHeight: spacing(415),
    right: '40%',
    zIndex: 9,
  },
  search: {
    marginBottom: spacing(10),
    '& .MuiInput-underline:before': {
      borderColor: palette.divider,
    },
    '& .MuiInputAdornment-positionStart': {
      marginBottom: spacing(10),
    },

    '& .MuiInputBase-input': {
      marginBottom: spacing(10),
    },
  },

  radioGroup: {
    marginBottom: spacing(10),
    maxHeight: spacing(280),
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: spacing(4),
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
      borderRadius: spacing(2),
    },
    '&.MuiFormGroup-root': {
      flexWrap: 'nowrap',
    },
  },
  label: {
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      fontSize: spacing(14),
      lineHeight: spacing(14),
    },
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: `${spacing(88)} ${spacing(88)}`,
    gridTemplateRows: spacing(40),
    gap: spacing(16),
    justifyContent: 'center',
  },
  button: {
    textTransform: 'capitalize',
  },
}))
