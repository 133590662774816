import { parseToNumber, validationCNPJ, validationCPF } from '_/utils/helpers'

export const removeCountryId = str => {
  const onlyNumbers = str.replace(/\D/g, '')
  if (onlyNumbers?.length === 13) return onlyNumbers.substring(2, onlyNumbers?.length)
  if (onlyNumbers?.length < 13) {
    const newNumber =
      onlyNumbers.substring(0, 2) === '55'
        ? onlyNumbers.substring(2, onlyNumbers?.length)
        : onlyNumbers

    return newNumber
  }
  return str
}

export const transformData = obj => {
  const data = obj

  for (const key of Object.keys(data)) {
    if (data[key] === 'true') {
      data[key] = true
    }
    if (data[key] === 'false') {
      data[key] = false
    }
  }

  return data
}

export const ERRORS = {
  UNAUTHORIZED_EMAIL:
    'Você não pode utilizar o e-mail de um usuário da aplicação como e-mail do responsável.',
  CANNOT_EDIT_INSTALLMENTS:
    'Não é possivel alterar a quantidade de parcelas, pois o serviço já foi finalizado.',
  CANNOT_EDIT_BUDGET_PAID:
    'Não é possível alterar os Dados do pagador, pois o pagamento já foi feito.',
  EMPTY_FIELD: 'Por favor complete todos os campos antes de realizar o envio.',
  INVALID_FORM: 'Um ou mais dados do formulário possuem problemas.',
  INVALID_CPF: 'CPF inválido.',
  INVALID_CNPJ: 'CNPJ inválido.',
  INVALID_EMAIL: 'E-mail inválido.',
  INVALID_TELEPHONE: 'O telefone deve incluir no mínimo 9 caractéres, incluindo DDD.',
}

export const validateFormCpfCnpj = data => {
  let message = ''

  if (
    (parseToNumber(data?.responsibleDocNumber).length <= 11 &&
      !validationCPF(data?.responsibleDocNumber)) ||
    (data?.recipientInvoice === 'other' &&
      parseToNumber(data?.invoiceDocNumber).length <= 11 &&
      !validationCPF(data?.invoiceDocNumber))
  ) {
    message = ERRORS.INVALID_CPF
  }
  if (
    (parseToNumber(data?.responsibleDocNumber).length > 11 &&
      parseToNumber(data?.responsibleDocNumber).length <= 14 &&
      !validationCNPJ(data?.responsibleDocNumber)) ||
    (data?.recipientInvoice === 'other' &&
      parseToNumber(data?.invoiceDocNumber).length > 11 &&
      parseToNumber(data?.invoiceDocNumber).length <= 14 &&
      !validationCNPJ(data?.invoiceDocNumber))
  ) {
    message = ERRORS.INVALID_CNPJ
  }

  return message
}

export const cpfCnpjMask = value => {
  let newValue = value
  newValue = newValue.replace(/\D/g, '')

  if (newValue.length < 13) {
    newValue = newValue.replace(/(\d{3})(\d)/, '$1.$2')
    newValue = newValue.replace(/(\d{3})(\d)/, '$1.$2')
    newValue = newValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    newValue = newValue.replace(/^(\d{2})(\d)/, '$1.$2')
    newValue = newValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    newValue = newValue.replace(/\.(\d{3})(\d)/, '.$1/$2')
    newValue = newValue.replace(/(\d{4})(\d)/, '$1-$2')
  }
  return newValue
}
