import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  main: {
    width: '100%',
  },
  container: {
    width: '100%',
    paddingTop: '76px',
    display: 'flex',
  },
  accordionSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
  },
  aside: {
    backgroundColor: palette.gray[4],
    padding: spacing(32),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '20px',
    width: spacing(400),
  },
  cards: {
    width: '100%',
    padding: spacing(32),
    marginBottom: spacing(20),
  },
  save: {
    zIndex: 2,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '15px 10% 15px 30px',
    width: '100%',
    boxShadow: `${spacing(0, 1, 22)} rgba(0, 0, 0, 0.12)`,
  },
  button: {
    margin: spacing(0, 16),
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loading: {
    height: '100vh',
  },
  containerWithColumns: {
    gridTemplateColumns: `auto 1fr`,
  },
  navigate: {
    height: spacing(24),
    width: spacing(24),
  },
  buttonEdit: {
    color: palette.primary.main,
    borderColor: palette.primary.main,
  },
  buttonEditIcon: {
    paddingLeft: spacing(5),
  },
  subjectField: {
    width: '200px',
    maxHeight: '200px',
    fontSize: '20px',
    color: '#B4B4B4',

    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  cpfCnpjField: {
    '& .Refera__MaskInput-ErrorMessage': {
      bottom: '-20px',
    },
  },
  cnpjLabel: {
    color: palette.text.secondary,
    fontSize: spacing(10),
    top: spacing(-22, '!important'),
  },
  error: {
    color: Theme.Colors.Red.Base,
    marginTop: spacing(4),
  },
  accordion: {
    display: 'grid',
  },
  accordionGap: {
    marginTop: spacing(24),
    columnGap: spacing(16),
    rowGap: spacing(20),
  },
  switch: {
    marginTop: spacing(16),
    marginLeft: spacing(0),
  },
  headerButtonsContainer: {
    display: 'flex',
    gap: spacing(8),
  },
  actionButtons: {
    display: 'flex',
    gap: spacing(8),
  },
  cepContainer: {
    height: '32px !important',
  },
  cepLabel: {
    gap: '0px !important',
  },
}))
