import React, { useCallback, useMemo, useState } from 'react'

import { Button } from '@refera/ui-web'
import { navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { ROUTES } from '_/utils/constants'
import { getVerifyTradesmanInstallments } from '_/modules/finance/actions'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { menuItemsSelector } from '_/modules/menu-items/selector'
import useRolePermission from '_/hooks/use-role-permission'

const ViewInstallmentsButton = () => {
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { isTradesman, isSAAS } = useRolePermission()
  const [isLoading, setIsLoading] = useState(false)

  const serviceOrder = useSelector(currentServiceOrderSelector)
  const serviceOrderId = useMemo(() => serviceOrder?.get('id'), [serviceOrder?.get('id')])

  const userMenuItems = useSelector(menuItemsSelector)

  // Based on tradesman menu item 'receipts' if it's active
  const showInstallmentsButton = useMemo(
    () => userMenuItems?.find(item => item.path === ROUTES.INSTALLMENTS_RECEIPTS && item.active),
    [userMenuItems]
  )

  const fetchVerifyTradesmanInstallments = useCallback(() => {
    setIsLoading(true)
    dispatch(getVerifyTradesmanInstallments(serviceOrderId))
      .then(() => navigate(`${ROUTES.INSTALLMENTS_RECEIPTS}/?chamado=${serviceOrderId}`))
      .catch(error =>
        showToast({
          type: 'error',
          message: formatErrorMessage(error),
        })
      )
      .finally(() => setIsLoading(false))
  }, [dispatch, serviceOrderId])

  // Check this rule to SAAS
  if ((!showInstallmentsButton || !isTradesman) && !isSAAS) {
    return null
  }

  return (
    <Button variant="secondary" onClick={fetchVerifyTradesmanInstallments} disabled={isLoading}>
      Ver parcelas
    </Button>
  )
}

export default ViewInstallmentsButton
