import { defineAction } from '_utils/redux'
import * as userService from '_services/user'

export const CREATE_REFERA_USERS = defineAction('CREATE_REFERA_USERS')
export const GET_REFERA_USER_DETAILS = defineAction('GET_REFERA_USER_DETAILS')
export const LIST_REFERA_USERS = defineAction('LIST_REFERA_USERS')
export const UPDATE_REFERA_USER = defineAction('UPDATE_REFERA_USER')
export const DELETE_REFERA_USER = defineAction('DELETE_REFERA_USER')
export const ACTIVATE_REFERA_USER_EMAIL = defineAction('ACTIVATE_REFERA_USER_EMAIL')

export const createReferaUser = payload => async (dispatch, getState) => {
  const data = await userService.createReferaUser(getState().user.authToken)(payload)

  dispatch({
    type: CREATE_REFERA_USERS.ACTION,
    payload: async () => data,
  })

  return data
}

export const getReferaUserDetails = userId => async (dispatch, getState) => {
  const data = await userService.getReferaUserDetails(getState().user.authToken)(userId)

  dispatch({
    type: GET_REFERA_USER_DETAILS.ACTION,
    payload: async () => data,
  })

  return data
}

export const listReferaUsers = params => async (dispatch, getState) => {
  const data = await userService.listReferaUsers(getState().user.authToken)(params)

  dispatch({
    type: LIST_REFERA_USERS.ACTION,
    payload: async () => data,
  })

  return data
}

export const updateReferaUser = (userId, payload) => async (dispatch, getState) => {
  const data = await userService.updateReferaUser(getState().user.authToken)(userId, payload)

  dispatch({
    type: UPDATE_REFERA_USER.ACTION,
    payload: async () => data,
  })

  return data
}

export const deleteReferaUser = userId => async (dispatch, getState) => {
  const data = await userService.deleteReferaUser(getState().user.authToken)(userId)

  dispatch({
    type: DELETE_REFERA_USER.ACTION,
    payload: async () => data,
  })

  return data
}

export const activateReferaUserEmail = userId => async (dispatch, getState) => {
  const data = await userService.activateReferaUserEmail(getState().user.authToken)(userId)

  dispatch({
    type: ACTIVATE_REFERA_USER_EMAIL.ACTION,
    payload: async () => data,
  })

  return data
}
