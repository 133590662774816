import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    width: '60px',
    height: '60px',
    margin: '20px',
  },
  title: {
    color: '#141414',
    display: 'flex',
    alignItems: 'center',
    weight: '500px',
    height: '40px',
    left: '444px',
    lineHeight: '40px',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    size: '32px',
    marginBottom: '20px',

    '& @media(max-width: 767px)': {
      fontSize: '24px',
      lineHeight: '32px',
      weight: '328px',
      height: '40px',
      left: '444px',
      fontFamily: '',
      size: '24px',
      marginBottom: '20px',
    },
  },
  subTitle: {
    fontSize: '13px',
    lineHeight: '24px',
    color: '#696969',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    top: '272px',
    textAlign: 'center',
    width: '400px',
    height: '47px',
    left: '440px',
    fontFamily: 'Roboto',

    '& @media(max-width: 767px)': {
      fontSize: '13px',
      lineHeight: '20px',
      top: '80px',
      textAlign: 'center',
      width: '328px',
      height: '47px',
      left: '440px',
      fontFamily: 'Roboto',
    },
  },
}))
