import { defineAction } from '_utils/redux'
import * as BudgetTemplate from '_services/budgetTemplate'

export const GET_BUDGET_TEMPLATES = defineAction('GET_BUDGET_TEMPLATES')
export const GET_BUDGET_TEMPLATE_BY_ID = defineAction('GET_BUDGET_TEMPLATE_ID')
export const CREATE_BUDGET_TEMPLATE = defineAction('CREATE_BUDGET_TEMPLATE')
export const UPDATE_BUDGET_TEMPLATE = defineAction('UPDATE_BUDGET_TEMPLATE')
export const DELETE_BUDGET_TEMPLATE = defineAction('DELETE_BUDGET_TEMPLATE')

export const getBudgetTemplates = params => async (dispatch, getState) => {
  const queryParams = {
    pageSize: params?.pageSize || getState().budgetTemplate.size,
    order: params?.order || getState().budgetTemplate.params?.order,
    ...params,
  }

  const data = await BudgetTemplate.getBudgetTemplates(getState().user.authToken)(queryParams)

  dispatch({
    type: GET_BUDGET_TEMPLATES.ACTION,
    payload: async () => data,
    meta: queryParams,
  })

  return data
}

export const getBudgetTemplateById = BudgetTemplateById => async (dispatch, getState) => {
  const data = await BudgetTemplate.getBudgetTemplateById(getState().user.authToken)(
    BudgetTemplateById
  )

  dispatch({
    type: GET_BUDGET_TEMPLATE_BY_ID.ACTION,
    payload: async () => data,
  })

  return data
}

export const createBudgetTemplate = payload => async (dispatch, getState) => {
  const data = await BudgetTemplate.createBudgetTemplate(getState().user.authToken)(payload)

  dispatch({
    type: CREATE_BUDGET_TEMPLATE.ACTION,
    payload: async () => data,
  })

  return data
}

export const updateBudgetTemplate = (BudgetTemplateById, payload) => async (dispatch, getState) => {
  const data = await BudgetTemplate.updateBudgetTemplate(getState().user.authToken)(
    BudgetTemplateById,
    payload
  )

  dispatch({
    type: UPDATE_BUDGET_TEMPLATE.ACTION,
    payload: async () => data,
  })

  return data
}

export const deleteBudgetTemplate = BudgetTemplateById => async (dispatch, getState) => {
  const data = await BudgetTemplate.deleteBudgetTemplate(getState().user.authToken)(
    BudgetTemplateById
  )

  dispatch({
    type: DELETE_BUDGET_TEMPLATE.ACTION,
    payload: async () => data,
  })

  return data
}
