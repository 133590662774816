/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { InputLabel, FormControl } from '@material-ui/core'
import InputMask from 'react-input-mask'
import { FormHelperText, TextField } from '@mui/material'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import generalInputStyles from '../styles'

/**
 * Basic input component that uses Material-UI TextField and React Hook Form Controller.
 * @param {Object} props - The props object.
 * @param {string} props.label - The label of the input.
 * @param {string} props.name - The name of the input. (Hook-Form related)
 * @param {boolean} [props.required=false] - Whether the input is required or not.
 * @param {string} [props.defaultValue=''] - The default value of the input.
 * @param {string} [props.mask=''] - The default mask of the input.
 * @param {string} [props.maskChar=''] - The default maskChar of the input.
 * @param {Object} [props.rules={}] - The validation rules for the input. Follows the same rules as React Hook Form.
 * @param {Object} [props.style={}] - The style object to be applied to the component.
 * @param {string} [props.containerClasses] - The classes to be applied to the container element.
 * @param {string} [props.labelClasses] - The classes to be applied to the label element.
 * @returns {JSX.Element} - The BasicInput component.
 */
function MaskBasicInput({
  label,
  name,
  required = false,
  defaultValue = '',
  rules = {},
  style = {},
  mask = '',
  maskChar = '',
  showErrorMessage = true,
  containerClasses,
  labelClasses,
  customErrorMessage = '',
  onBlur = () => {},
  disabled = false,
  ...rest
}) {
  const generalStyles = generalInputStyles()

  const { errors, control, setValue, trigger } = useFormContext()

  const errorMessage = useMemo(() => {
    return errors?.[name]?.message || customErrorMessage
  }, [errors?.[name], customErrorMessage])

  useEffect(() => {
    setValue(name, defaultValue)
    if (defaultValue) trigger(name)
  }, [defaultValue, name, setValue])

  return (
    <FormControl
      style={{ ...style }}
      className={classNames(generalStyles.formControl, containerClasses ?? null)}
    >
      <InputLabel
        variant="standard"
        id={`${name}-label`}
        style={{ color: errorMessage ? '#C90000' : null, top: '-38px' }}
        className={classNames(generalStyles.inputLabel, labelClasses ?? null)}
      >
        {label}
        {required && <span className={generalStyles.required}>*</span>}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        disabled={disabled}
        render={({ ref, onBlur: fieldOnBlur, ...restRender }) => (
          <InputMask
            mask={mask}
            maskChar={maskChar}
            fullWidth
            disabled={disabled}
            id={name}
            inputRef={ref}
            onBlur={e => {
              onBlur && onBlur()
              fieldOnBlur(e)
            }}
            {...rest}
            {...restRender}
            InputProps={{
              style: { fontSize: 14, height: 35 },
              inputProps: { minLength: 9 },
            }}
          >
            {inputProps => (
              <TextField
                id={name}
                variant="standard"
                disabled={disabled}
                defaultValue={defaultValue}
                error={!!errorMessage}
                required={required}
                InputProps={{
                  style: {
                    fontSize: 16,
                  },
                  maxLength: rules?.maxLength?.value || rules?.maxLength || 255,
                }}
                {...inputProps.field}
                {...inputProps}
              />
            )}
          </InputMask>
        )}
      />
      {showErrorMessage && (
        <FormHelperText className={generalStyles.errorMessage} error={!!errorMessage}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

MaskBasicInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  defaultValue: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  rules: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
  containerClasses: PropTypes.string,
  labelClasses: PropTypes.string,
}

MaskBasicInput.defaultProps = {
  required: false,
  showErrorMessage: true,
  defaultValue: '',
  mask: '',
  maskChar: '',
  rules: {},
  style: {},
  containerClasses: null,
  labelClasses: null,
}

export default MaskBasicInput
