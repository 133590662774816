import React, { useRef } from 'react'
import { Button } from '@refera/ui-web'
import { Grid, Typography, Checkbox, FormControlLabel } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import * as Input from '_/components/inputs/Input'

import useStyles from './styles'

export function ProblemCreate({ handlePost = () => {}, problemSelectId }) {
  const styles = useStyles()
  const formRef = useRef()

  const { control, handleSubmit } = useFormContext()

  return (
    <Grid className={styles.container}>
      <Typography className={styles.title}>
        {problemSelectId ? 'Editar problema' : 'Problema'}
      </Typography>
      <form className={styles.problemForm} ref={formRef} onSubmit={handleSubmit(handlePost)}>
        <Input.Root name="name">
          <Input.Label required name="name" labelClasses={styles.label}>
            Nome
          </Input.Label>
          <Input.ControllerText
            required
            rules={{
              maxLength: { value: 500, message: `Esse campo aceita no máximo 500 caracteres` },
              required: { value: true, message: 'Esse campo é obrigatório' },
            }}
            name="name"
          />
          <Input.ErrorMessage name="name" />
        </Input.Root>
        <Controller
          name="active"
          control={control}
          defaultValue=""
          render={({ value, onChange }) => {
            return (
              <FormControlLabel
                label="Ativo"
                control={
                  <Checkbox
                    checked={value}
                    defaultChecked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                }
              />
            )
          }}
        />

        <Button
          color="primary"
          variant="primary"
          type="submit"
          style={{ width: '120px', marginTop: '15px' }}
        >
          Salvar
        </Button>
      </form>
    </Grid>
  )
}
