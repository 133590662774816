import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    borderRadius: spacing(4),
    justifyContent: 'space-between',
    background: 'linear-gradient(225deg, #4FBEFC 0%, #2968FA 100%)',

    '@media (max-width: 716px)': {
      flexDirection: 'column',
    },
  },
  messageWrapper: {
    padding: spacing(16, 12),
    maxWidth: '74%',
    height: '100%',

    '@media (max-width: 716px)': {
      maxWidth: '100%',
    },
  },
  containerMessage: {
    color: 'white',
    fontSize: spacing(16),
  },
  moreInfoLink: {
    whiteSpace: 'nowrap',
    marginLeft: spacing(4),
    fontWeight: 'bold',
    color: 'white',
  },
  actionButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(8),
    width: 'fit-content',
  },
  actionButton: {
    color: 'white',
    fontSize: spacing(16),
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  arrowIcon: {
    transform: 'scaleX(-1)',
    marginLeft: spacing(4),
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 700px)': {
      overflowX: 'hidden',
    },
  },
  coinsImage: {
    width: 'auto',
    height: '100%',

    '@media (max-width: 778px)': {
      padding: spacing(0, 0, 0, 8),
    },
  },
}))
