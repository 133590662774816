import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  root: {
    '&.Refera__MaskInput-Root': {
      position: 'relative',
    },

    '& .Refera__MaskInput-Label': {
      fontSize: spacing(10),
      paddingTop: spacing(6),
    },

    '& .Refera__MaskInput-LabelRequired::after': {
      content: '"*"',
      marginLeft: spacing(2),
      color: palette.error.main,
    },

    '& .Refera__MaskInput-LabelError': {
      color: palette.error.main,
    },

    '& .Refera__MaskInput-Control': {
      width: '100%',

      '& input': {
        padding: spacing(6, 0, 7, '!important'),
      },
    },

    '& .Refera__MaskInput-ErrorMessage': {
      color: `${palette.error.main} !important`,
      margin: 0,
      fontSize: spacing(10),
      fontFamily: 'Roboto',
      bottom: '-5px',
      position: 'absolute',
    },
  },
}))
