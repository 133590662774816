import React, { useCallback, useState, useEffect, useReducer, useMemo } from 'react'
import { SwipeableDrawer, Divider, Grid, Slide, Typography, Tooltip } from '@material-ui/core'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'

import { FilterSearch as FilterIcon, CloseCircle } from '@refera/ui-icons'
import { useDispatch, useSelector } from 'react-redux'
import Theme from '@refera/ui-core'
import { Button, Input } from '@refera/ui-web'

import { updateListBudgetReceivablesFilter } from '_/modules/finance/actions'
import { getBudgetReceivablesFilterSelector } from '_/modules/finance/selectors'

import { FORM_FIELD_NAME, initialState, reducer, RESET_STATE, UPDATE_STATE } from './reducer'

import useStyles from './styles'
import classNames from 'classnames'

import { useLocation } from '@reach/router'

import {
  METHOD_PAYMENT_OPTIONS_SELECT_FILTER,
  RECEIVABLES_STATUS_FIELDS,
} from '_/views/finance/manage-installments/utils/constants'

import useRolePermission from '_/hooks/use-role-permission'
import { useToast } from '_/hooks/use-toast'

import { formatServiceOrderFilter } from '../../../manage-installments/utils/functions'

import Select from '_components/common/select'
import TextFieldComponent from '_components/textfield'
import { DatePickerInterval } from '_/components/date-picker-interval'
import { InputNumberInterval } from '_/components/input-number-interval'

import { formatInterval } from '_/views/finance/utils/functions'
import { cpfCnpjMask } from '_/views/finance/payer-data/utils'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

// TODO: Implement react-hook-form
const Filters = ({ handleFilterDrawer, params, open, setPage, handleGetReceivables }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { isApprover, isManager } = useRolePermission()

  const filters = useSelector(getBudgetReceivablesFilterSelector)
  const { search: urlParams } = useLocation()

  const [apply, setApply] = useState(false)
  const [selectedTab, setSelectedTab] = useState('main')
  const [initialStateFilter, setInitialStateFilter] = useState(0)

  const { showToast } = useToast()

  const [localState, localDispatch] = useReducer(reducer, initialState(filters))
  const invalidFilters = useMemo(
    () => (localState?.invalidFields && Object.keys(localState?.invalidFields)?.length) || false,
    [localState?.invalidFields]
  )

  const handleChange = useCallback(
    (value, field) => {
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          [field]: value,
        },
      })
    },
    [localDispatch]
  )

  const handleMultiSelection = useCallback(
    (newValue, field) => {
      localDispatch({ type: UPDATE_STATE, payload: { [field]: newValue } })
    },
    [localDispatch]
  )

  const handleInputChange = useCallback(
    event => {
      const { name: field, value } = event.target

      localDispatch({
        type: UPDATE_STATE,
        payload: {
          [field]: value,
        },
      })
    },
    [localDispatch]
  )

  const cleanFilters = () => {
    const url = window.location.href.split('?')[0]
    window.history.replaceState({}, '', url)
    localDispatch({ type: RESET_STATE })
  }

  const handleServiceOrderFilter = serviceOrder => {
    const formattedObj = formatServiceOrderFilter(serviceOrder)

    if (formattedObj?.message) {
      showToast({ ...formattedObj })
      return null
    }

    return formattedObj?.formattedServiceOrder
  }

  const handleApplyFilters = useCallback(() => {
    const {
      serviceOrderId,
      agency,
      voucherStart,
      voucherEnd,
      paymentDateStart,
      paymentDateEnd,
      dueDateStart,
      dueDateEnd,
      invoiceStart,
      invoiceEnd,
      serviceOrderEndDateStart,
      serviceOrderEndDateEnd,
      installmentValueStart,
      installmentValueEnd,
      installmentNumberStart,
      installmentNumberEnd,
      invalidFields,
      ...rest
    } = localState

    if (Object.keys(invalidFields)?.length) {
      setApply(false)
      return
    }

    const paymentDate = formatInterval('date', paymentDateStart, paymentDateEnd)
    const dueDate = formatInterval('date', dueDateStart, dueDateEnd)
    const voucher = formatInterval('number', voucherStart, voucherEnd)
    const installmentNumber = formatInterval('number', installmentNumberStart, installmentNumberEnd)
    const invoice = formatInterval('number', invoiceStart, invoiceEnd)
    const installmentValue = formatInterval('number', installmentValueStart, installmentValueEnd)
    const serviceOrderEndDate = formatInterval(
      'date',
      serviceOrderEndDateStart,
      serviceOrderEndDateEnd
    )

    let serviceOrderIds = serviceOrderId

    if (serviceOrderIds?.trim()) {
      serviceOrderIds = handleServiceOrderFilter(serviceOrderIds)
      if (!serviceOrderIds) return
      handleChange(serviceOrderIds, 'serviceOrder')
    }

    const updatedFilters = {
      ...rest,
      serviceOrderId: serviceOrderIds,
      agency: isManager || isApprover ? '' : agency,
      dueDate,
      paymentDate,
      voucher,
      invoice,
      installmentValue,
      installmentNumber,
      serviceOrderEndDate,
    }

    const url = window.location.href.split('?')[0]
    window.history.replaceState({}, '', url)

    dispatch(updateListBudgetReceivablesFilter(updatedFilters))
    handleFilterDrawer()
    setApply(true)
  }, [dispatch, filters, localState, handleFilterDrawer])

  const renderInputTooltip = useCallback(text => {
    return (
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={<Typography className={styles.tooltipText}>{text}</Typography>}
        placement="bottom-end"
      >
        <HelpOutlineOutlinedIcon className={styles.tooltipIcon} />
      </Tooltip>
    )
  }, [])

  const MainFilters = useMemo(() => {
    return (
      <>
        <>
          <Input
            key={FORM_FIELD_NAME.SERVICE_ORDER_ID}
            name={FORM_FIELD_NAME.SERVICE_ORDER_ID}
            label="ID do chamado"
            value={localState?.serviceOrderId}
            placeholder="12345"
            style={{ fontSize: '16px' }}
            onChange={handleInputChange}
            fullWidth
          />
          {!(isApprover || isManager) && (
            <Input
              key={FORM_FIELD_NAME.AGENCY}
              name={FORM_FIELD_NAME.AGENCY}
              label="Nome da intermediária"
              value={localState?.agency}
              placeholder="Nome da intermediária"
              style={{ fontSize: '16px' }}
              onChange={handleInputChange}
              fullWidth
              endAdornment={renderInputTooltip('Busque por nome, email, telefone ou CPF/CNPJ')}
            />
          )}
          <Select
            label="Status"
            defaultValue={localState?.status}
            value={localState?.status}
            onChange={handleInputChange}
            name={FORM_FIELD_NAME.STATUS}
            options={RECEIVABLES_STATUS_FIELDS}
          />
          <Select
            label="Forma de pagamento"
            defaultValue={localState?.formOfPayment}
            value={localState?.formOfPayment}
            onChange={handleInputChange}
            name={FORM_FIELD_NAME.PAYMENT_TYPE}
            options={METHOD_PAYMENT_OPTIONS_SELECT_FILTER}
          />
        </>
        <InputNumberInterval
          name="voucher"
          label="Boleto"
          localState={localState}
          handleChange={handleInputChange}
          error={localState?.invalidFields?.voucher}
        />
        <DatePickerInterval
          name="dueDate"
          label="Data de vencimento"
          localState={localState}
          handleChange={handleChange}
          disableFuture={false}
          error={localState?.invalidFields?.dueDate}
        />
        <InputNumberInterval
          name="installmentNumber"
          label="Número da parcela"
          localState={localState}
          handleChange={handleInputChange}
          error={localState?.invalidFields?.installmentNumber}
        />
      </>
    )
  }, [localState, handleMultiSelection, handleChange, selectedTab])

  const MoreFilters = useMemo(() => {
    return (
      <>
        {/* <InputNumberInterval
          name="invoice"
          label="NFS-e"
          localState={localState}
          handleChange={handleInputChange}
          error={localState?.invalidFields?.invoice}
        /> */}
        <DatePickerInterval
          name="serviceOrderEndDate"
          label="Data da 1ª finalização do chamado"
          localState={localState}
          handleChange={handleChange}
          error={localState?.invalidFields?.serviceOrderEndDate}
        />
        <Input
          key={FORM_FIELD_NAME.CLIENT_NAME}
          name={FORM_FIELD_NAME.CLIENT_NAME}
          label="Nome do cliente"
          value={localState?.[FORM_FIELD_NAME.CLIENT_NAME]}
          placeholder="Nome do cliente"
          style={{ fontSize: '16px' }}
          onChange={handleInputChange}
          fullWidth
        />
        <Grid>
          <Typography className={styles.label}>CPF/CNPJ do cliente</Typography>
          <TextFieldComponent
            isCpfCnpj
            name={FORM_FIELD_NAME.CLIENT_CPF_CNPJ}
            key={FORM_FIELD_NAME.CLIENT_CPF_CNPJ}
            onChange={({ target: { value } }) =>
              handleChange(cpfCnpjMask(value), FORM_FIELD_NAME.CLIENT_CPF_CNPJ)
            }
            value={localState?.[FORM_FIELD_NAME.CLIENT_CPF_CNPJ]}
            defaultValue={localState?.[FORM_FIELD_NAME.CLIENT_CPF_CNPJ]}
            placeholder="000.000.000-00"
            className={styles.inputSize}
            InputProps={{
              style: { fontSize: 16, height: 40, marginTop: 4 },
              inputProps: { maxLength: 18, minLength: 13 },
            }}
            InputLabelProps={{
              shrink: true,
              className: styles.inputLabel,
            }}
          />
        </Grid>
        <Input
          key={FORM_FIELD_NAME.COMPANY}
          name={FORM_FIELD_NAME.COMPANY}
          label="Nome da empresa do prestador"
          value={localState?.[FORM_FIELD_NAME.COMPANY]}
          placeholder="Nome da empresa"
          style={{ fontSize: '16px' }}
          onChange={handleInputChange}
          fullWidth
        />
        <Input
          key={FORM_FIELD_NAME.CLASSIFICATION}
          name={FORM_FIELD_NAME.CLASSIFICATION}
          label="Classificação"
          value={localState?.[FORM_FIELD_NAME.CLASSIFICATION]}
          placeholder="Classificação"
          style={{ fontSize: '16px' }}
          onChange={handleInputChange}
          fullWidth
        />
        <Input
          key={FORM_FIELD_NAME.COMMENT}
          name={FORM_FIELD_NAME.COMMENT}
          label="Comentário contenha"
          value={localState?.[FORM_FIELD_NAME.COMMENT]}
          placeholder="Comentário"
          style={{ fontSize: '16px' }}
          onChange={handleInputChange}
          fullWidth
        />
        <InputNumberInterval
          name="installmentValue"
          label="Valor da parcela"
          localState={localState}
          handleChange={handleInputChange}
          error={localState?.invalidFields?.installmentValue}
        />
      </>
    )
  }, [selectedTab, localState, handleInputChange])

  useEffect(() => {
    if (urlParams && initialStateFilter < 2) {
      setInitialStateFilter(prev => prev + 1)
      localDispatch({
        type: UPDATE_STATE,
        payload: initialState,
      })
    }
  }, [urlParams, initialStateFilter])

  useEffect(() => {
    if (!filters) {
      return
    }

    if ((Object.values(filters)?.some(value => value) && apply) || apply) {
      setPage(0)
      handleGetReceivables()
      setApply(false)
    }
  }, [filters, apply, params])

  return (
    <>
      <SwipeableDrawer
        component="section"
        anchor="right"
        open={open}
        onClose={handleFilterDrawer}
        className={styles.container}
        TransitionComponent={Transition}
      >
        <Grid className={styles.header}>
          <Grid className={styles.headerTitle}>
            <Grid className={styles.iconWrapper}>
              <FilterIcon color={Theme.Colors.Primary.Base} />
            </Grid>
            <Typography component="h1" variant="h5" className={styles.title}>
              Filtros
            </Typography>
          </Grid>
          <Button onClick={handleFilterDrawer} variant="ghost">
            <CloseCircle color={Theme.Colors.Primary.Base} className={styles.closeIcon} />
          </Button>
        </Grid>
        <Divider className={styles.divider} />
        <Grid className={styles.tabFilters}>
          <Grid
            className={classNames(styles.tab, selectedTab === 'main' && styles.selectedTab)}
            onClick={() => setSelectedTab('main')}
          >
            <Typography>Filtros principais</Typography>
          </Grid>
          <Grid
            className={classNames(styles.tab, selectedTab === 'more' && styles.selectedTab)}
            onClick={() => setSelectedTab('more')}
          >
            <Typography>Mais filtros</Typography>
          </Grid>
        </Grid>
        <Grid className={styles.content}>
          {selectedTab === 'main' && MainFilters}
          {selectedTab === 'more' && MoreFilters}
        </Grid>

        <Grid className={styles.buttonsContainer}>
          <Button variant="ghost" color="primary" onClick={cleanFilters}>
            Limpar filtros
          </Button>
          <Button
            variant="primary"
            color="orange"
            disabled={invalidFilters}
            onClick={handleApplyFilters}
          >
            Aplicar filtro
          </Button>
        </Grid>
      </SwipeableDrawer>
    </>
  )
}

export default Filters
