/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  currencyField: {
    width: '100%',
    height: spacing(38),
    fontSize: spacing(14),
    color: props => (!props.disabled ? palette.gray[64] : palette.gray[32]),
    border: '1px solid rgba(0, 0, 0, 0.30)',
    borderRadius: spacing(4),
    padding: spacing(0, 8),
    
    '&:hover': {
      border: props => (!props.disabled && '1px solid #717171'),
    },
    
    '&:focus-visible': {
      outline: 'none',
      border: props => (!props.disabled && `2px solid ${palette.primary.main} `),
      padding: spacing(0, 7),
    },
  },
}))
