import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import Button from '_components/button'
import { sendBudgetCounterProposal } from '_modules/budget/actions'

import useStyles from './styles'

const SelectProviderDialog = ({
  isOpen,
  onClose,
  serviceOrderId,
  budgetId,
  onSendCounterProposalSuccess,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(false)

  const onCancel = useCallback(() => {
    const payload = {
      requestedChangeServiceProvider: false,
    }

    setLoading(true)

    dispatch(sendBudgetCounterProposal({ serviceOrderId, budgetId, payload }))
      .then(() => onSendCounterProposalSuccess())
      .finally(() => setLoading(false))
  }, [budgetId, dispatch, serviceOrderId])

  const onSearchForAnotherProvider = useCallback(() => {
    const payload = {
      requestedChangeServiceProvider: true,
    }

    setLoading(true)

    dispatch(sendBudgetCounterProposal({ serviceOrderId, budgetId, payload }))
      .then(() => onSendCounterProposalSuccess())
      .finally(() => setLoading(false))
  }, [budgetId, dispatch, serviceOrderId])

  return (
    <Dialog open={isOpen} onClose={onClose} aria-describedby="selectProviderOnCancelDialog">
      <DialogContent>
        <DialogContentText id="selectProviderOnCancelDialog" className={styles.description}>
          Que tal se você nos deixar procurar um outro prestador que aceite este seu valor
          informado?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" isLoading={isLoading}>
          Não, feche o orçamento em questão
        </Button>
        <Button
          onClick={onSearchForAnotherProvider}
          color="primary"
          variant="contained"
          isLoading={isLoading}
        >
          Sim, pode procurar outro prestador
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SelectProviderDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  budgetId: PropTypes.string.isRequired,
  onSendCounterProposalSuccess: PropTypes.func.isRequired,
}

export default React.memo(SelectProviderDialog)
