import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import Header, { HEADER_THEME } from '_components/header'
import TickCircle from '_assets/icons/ic-success.svg'
import Svg from '_components/svg'

import useStyles from './styles'

const ApplicantValuationConfirm = () => {
  const styles = useStyles()

  return (
    <>
      <Header theme={HEADER_THEME.NO_BUTTONS} />
      <Grid component="main" className={styles.container}>
        <Svg icon={TickCircle} className={styles.image} />
        <Typography component="h1" className={styles.title}>
          Avaliação concluída com sucesso
        </Typography>
        <Typography className={styles.subTitle}>
          Agradecemos muito sua avaliação! Sua opinião é importante para podermos continuar
          melhorando e facilitando nossos processos para todos!
        </Typography>
      </Grid>
    </>
  )
}

export default React.memo(ApplicantValuationConfirm)
