/* eslint-disable import/no-cycle */
import React, { useMemo, useCallback, useState } from 'react'
import { TextField as MUITextField, InputAdornment } from '@material-ui/core'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { NumericFormat as NumberFormat } from 'react-number-format'

import { parseToNumber } from '_utils/helpers'

// Core
import Theme from '@refera/ui-core'

// Components
import { IconButton } from '@refera/ui-web'

// Icons
import { MinusCirlce } from '@refera/ui-icons'

import DynamicMask from './dynamic-mask'
import useStyles from './styles'

export const TEXTFIELD_MASK = {
  CEP: '99999-999',
  PHONE_NUMBER: '+55 (99) 9 9999-9999',
  DATE: '99/99/9999',
  CPF: '999.999.999-99',
  AGENCY: '9999',
  BANK_WITH_DIGIT: '9999999999-9',
  EMPLOYEE_NUMBER: '999',
  NUMBER: '',
}

const TextField = ({
  endAdornment,
  isNumbersOnly,
  mask,
  phoneMask,
  onChange,
  startAdornment,
  value,
  onBlur,
  isCpfCnpj,
  error,
  disabled,
  isFormattedNumber,
  maxCharSize,
  onClick,
  ...otherProps
}) => {
  const styles = useStyles()
  const [valueChanged, setValue] = useState('')

  const inputProps = useMemo(() => {
    if (endAdornment || error) {
      return {
        endAdornment: (
          <InputAdornment disableTypography className={styles.adornment} position="end">
            {error ? <ErrorOutlineIcon color="error" /> : endAdornment}
          </InputAdornment>
        ),
      }
    }
    if (startAdornment) {
      return {
        startAdornment: (
          <InputAdornment disableTypography className={styles.adornment} position="start">
            {startAdornment}
          </InputAdornment>
        ),
      }
    }
    return {}
  }, [endAdornment, error, startAdornment, styles.adornment])

  const handleNumbersOnly = useCallback(
    event => {
      const { name, value: textFieldValue } = event.target
      const parsedInt = parseToNumber(textFieldValue)

      onChange({ target: { name, value: parsedInt } })
    },
    [onChange]
  )

  if (isFormattedNumber) {
    return (
      <NumberFormat
        {...otherProps}
        value={value}
        customInput={TextField}
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        disabled={disabled}
        InputProps={inputProps}
      />
    )
  }

  if (isCpfCnpj) {
    return (
      <DynamicMask
        fullWidth
        value={value}
        onChange={onChange}
        InputProps={inputProps}
        error={error}
        disabled={disabled}
        {...otherProps}
      />
    )
  }

  if (mask) {
    if (otherProps.numberPhoneQuantity > 1) {
      return (
        <InputMask
          value={value}
          onChange={isNumbersOnly ? handleNumbersOnly : onChange}
          mask={mask}
          disabled={disabled}
          {...otherProps}
        >
          {() => (
            <div className={styles.containerNumber}>
              <MUITextField error={error} fullWidth disabled={disabled} {...otherProps} />
              <IconButton variant="ghost" onClick={onClick}>
                <MinusCirlce color={Theme.Colors.Red.Base} />
              </IconButton>
            </div>
          )}
        </InputMask>
      )
    }
    return (
      <InputMask
        value={value}
        onChange={isNumbersOnly ? handleNumbersOnly : onChange}
        mask={mask}
        disabled={disabled}
        {...otherProps}
      >
        {() => <MUITextField error={error} fullWidth disabled={disabled} {...otherProps} />}
      </InputMask>
    )
  }

  if (phoneMask) {
    return (
      <InputMask
        mask={valueChanged.replaceAll(' ', '').length < 14 ? '(99) 9999-99999' : '(99) 9 9999-9999'}
        maskChar=""
        disabled={disabled}
        value={valueChanged}
        onChange={e => setValue(e.target.value)}
      >
        {() => <MUITextField error={error} fullWidth disabled={disabled} {...otherProps} />}
      </InputMask>
    )
  }

  return (
    <MUITextField
      fullWidth
      value={value}
      onChange={isNumbersOnly ? handleNumbersOnly : onChange}
      InputProps={inputProps}
      onBlur={onBlur}
      error={error}
      disabled={disabled}
      inputProps={maxCharSize && { maxLength: maxCharSize }}
      {...otherProps}
    />
  )
}

TextField.propTypes = {
  endAdornment: PropTypes.node,
  isNumbersOnly: PropTypes.bool,
  mask: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  startAdornment: PropTypes.node,
  isCpfCnpj: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  isFormattedNumber: PropTypes.bool,
  maxCharSize: PropTypes.number,
}

TextField.defaultProps = {
  endAdornment: null,
  isNumbersOnly: false,
  mask: '',
  onBlur: () => {},
  startAdornment: null,
  isCpfCnpj: false,
  error: false,
  disabled: false,
  isFormattedNumber: false,
  maxCharSize: 0,
}

export default React.memo(TextField)
