import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  adornment: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: '2.4rem',
  },
  containerNumber: {
    display: 'flex',
  },
}))
