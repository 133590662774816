import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'grid',
    gridRowGap: spacing(24),
  },
  modal: {
    maxWidth: spacing(600),
    minWidth: spacing(390),
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  customTag: {
    color: 'white',
    fontSize: spacing(12),
    lineHeight: spacing(20),
    backgroundColor: palette.primary.main,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    minHeight: spacing(100),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
    minWidth: spacing(500),
    maxWidth: spacing(600),
    borderTop: '2px solid #E3E3E3',
  },
  contentState: {
    width: '100%',
    marginBottom: spacing(15),
    '& .MuiChip-deleteIcon': {
      color: 'white !important',
    },
  },
  contentCity: {
    width: '100%',
  },
  line: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: spacing(8),
  },
  select: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    height: '100%',
    width: '100%',
  },
  dialogAction: {
    padding: '20px 30px',
  },
  button: {
    height: '40px',
    width: spacing(90),
    padding: `${spacing(10)} ${spacing(16)}`,
  },
  footer: {
    padding: '30px 30px 25px 0px',
  },
  titleBlue: {
    color: palette.primary.main,
    cursor: 'move',
    width: '100%',
  },
  formControl: {
    color: palette.primary.main,
  },
  title: {
    color: palette.primary.main,
    fontSize: spacing(20),
    lineHeight: spacing(20),
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}))
