import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  categoryForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '22px',
    marginBottom: '15px',
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  label: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '12px !important',
    fontWeight: '500',
    lineHeight: '112.5%',
    color: `${Theme.Colors.Grayscale.OneThousand} !important`,
  },
  labelSelect: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '12px !important',
    fontWeight: '500',
    lineHeight: '112.5%',
    color: `${Theme.Colors.Grayscale.OneThousand} !important`,
    marginTop: '15px',
  },
  textfield: {
    marginRight: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  select: {
    width: '90px',
    textAlign: 'center',
  },
  sectionWrapper: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'row',
  },
  sectionRowWrapper: {
    gap: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  fieldWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  accordion: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(10),
  },
  containerAdd: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(10),
  },
}))
