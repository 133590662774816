import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '750px',
    padding: '0 16px 16px 16px',
    maxHeight: '85%',
    backgroundColor: 'white',
    borderRadius: '8px',
  },
  templateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '14px',
  },
  exitButton: {
    display: 'flex',
    paddingRight: 0,
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  exitIcon: {
    width: '24px',
    height: '24px',
  },
  image: {
    display: 'flex',
    maxHeight: '250px',
    objectFit: 'contain',
  },
  header: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#141414',
    padding: '15px',
  },
  title: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '28px',
    color: '#141414',
    marginBottom: '16px',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#434343',
  },
  dotWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  dot: {
    height: '6px',
    width: '6px',
    borderRadius: '100px',
    backgroundColor: '#B4B4B4',
    marginRight: '5px',
    marginBottom: '32px',
  },
  selectedDot: {
    width: '24px',
    backgroundColor: '#1671ED',
  },
}))
