import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  autoCompletePointer: {
    '& .MuiAutocomplete-input': {
      cursor: 'pointer',
    },
  },
  autoCompleteDisabled: {
    '& .MuiAutocomplete-input': {
      cursor: 'not-allowed',
    },
  },
  contentState: {
    width: '100%',
    '& .MuiChip-deleteIcon': {
      color: 'white !important',
    },
  },
  customTag: {
    color: 'white',
    fontSize: spacing(12),
    lineHeight: spacing(20),
    backgroundColor: palette.primary.main,
  },
  label: {
    fontSize: spacing(16),
    lineHeight: spacing(20),
    color: palette.gray[64],
  },
  labelError: {
    color: `#C90000 !important`,
  },
  required: {
    '&:after': {
      content: '"*"',
      color: '#C90000',
      marginLeft: 4,
    },
  },
}))
