import { schema } from 'normalizr'

import Model from './model'
import BankAccount from './bank-account'

class Agency extends Model({
  address: undefined,
  agencyCreationDate: undefined,
  agencyCompletionPercentage: undefined,
  bankAccount: undefined,
  brandLogoPngUrl: undefined,
  cep: undefined,
  city: undefined,
  contactEmail: undefined,
  contractSocialStatute: undefined,
  cpfCnpj: undefined,
  extraAddressInfo: undefined,
  id: undefined,
  isActive: undefined,
  name: undefined,
  nearbyLandmark: undefined,
  neighborhood: undefined,
  personResponsibleName: undefined,
  phoneNumber: undefined,
  registrationStatus: undefined,
  referaCompletionPercentage: undefined,
  responsibleRefera: undefined,
  responsibleReferaSectors: undefined,
  state: undefined,
  streetNumber: undefined,
  staffUsers: undefined,
  sendNotification: undefined,
  supportService: undefined,
  contractType: undefined,
  agencyAttachments: undefined,
  customFields: undefined,
  showReferaValue: undefined,
  pilotAutoTicket: undefined,
  vipLimit: undefined,
  flows: undefined,
  isVipAgency: undefined,
  minimumSaleValue: undefined,
  supportPhone: undefined,
  supportWhatsapp: undefined,
  defineResponsible: undefined,
  bypass: undefined,
  maxBypassValue: undefined,
  minBypassValue: undefined,
  reviewBudget: undefined,
  allowsRentDiscount: undefined,
  contractStatus: undefined,
  contractGenerationDate: undefined,
  contractBlockDate: undefined,
  contractSigningDate: undefined,
  sellerConnection: undefined,
}) {}

const agencyNewSchema = new schema.Entity(
  'agency',
  {},
  {
    processStrategy: entity =>
      new Agency(entity).set('bankAccount', new BankAccount(entity.bankAccount)),
  }
)

const agencySchema = [agencyNewSchema]

export { agencySchema, Agency as default }
