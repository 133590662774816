import React from 'react'
import { Menu, MenuItem } from '@material-ui/core'

export default function OthersMenu({
  openOthersMenu,
  setOpenOthersMenu,
  handleClickContactRequester,
  handleClickContactPropertyOwner,
  handleClickContactPayer,
}) {
  const handleClose = () => {
    setOpenOthersMenu(null)
  }

  function handleRequesterButton() {
    handleClickContactRequester()

    return handleClose()
  }

  function handleOwnerButton() {
    handleClickContactPropertyOwner()

    handleClose()
  }

  function handlePayerButton() {
    handleClickContactPayer()

    handleClose()
  }

  return (
    <Menu
      id="basic-menu"
      open={Boolean(openOthersMenu)}
      anchorEl={openOthersMenu}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        style: {
          marginTop: 40,
          marginLeft: 8,
        },
      }}
    >
      <MenuItem onClick={handleRequesterButton}>Solicitante</MenuItem>
      <MenuItem onClick={handleOwnerButton}>Proprietário</MenuItem>
      <MenuItem onClick={handlePayerButton}>Pagador</MenuItem>
    </Menu>
  )
}
