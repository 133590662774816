import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  iconWhatsApp: {
    width: spacing(20),
    marginRight: spacing(6),
  },
  whatsButton: {
    background: palette.green.light,
    color: 'white',
  },
}))
