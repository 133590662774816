import React, { useState, useEffect, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { Datagrid } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'
import { batchesColumns } from '../../utils/constants'

import useStyles from '../../styles'

import { navigate } from '@reach/router'

import { FINANCE_ROUTES } from '_/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { getBatches } from '_/modules/finance/actions'
import { batchesSelector } from '_/modules/finance/selectors'
import { TABLE_SX } from '_/views/finance/utils/constants'

const BatchesScreen = () => {
  const styles = useStyles()
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState('-id')
  const [pageSize, setPageSize] = useState(10)
  const [batchesList, setBatchesList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const batches = useSelector(batchesSelector)

  const dispatch = useDispatch()

  const goBack = () => navigate(FINANCE_ROUTES.MANAGE_INSTALLMENTS)

  const handleGetBatches = () => {
    Promise.resolve(
      dispatch(
        getBatches({
          page: page + 1,
          pageSize,
          orderBy,
        })
      )
    ).then(() => setIsLoading(false))
  }

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('-id')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  useEffect(() => {
    setIsLoading(true)
    handleGetBatches()
  }, [orderBy, page, pageSize])

  useEffect(() => {
    if (batches) setBatchesList(batches?.results)
  }, [batches])

  return (
    <Grid>
      <HeaderTitle title="Lotes de pagamento" backButtonAction={goBack} />
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            loading={isLoading}
            rows={batchesList ?? []}
            columns={batchesColumns(styles)}
            paginationMode="server"
            onRowClick={params => navigate(`${FINANCE_ROUTES.BATCHES}/${params.row.id}`)}
            onPageChange={setPage}
            page={page}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowCount={batches?.count}
            onSortModelChange={handleOrderBy}
            sx={TABLE_SX}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BatchesScreen
