import React from 'react'
import { Box, Modal, Typography } from '@mui/material'
import { Button } from '@refera/ui-web'
import { DocumentText1, Danger, TickCircle } from '@refera/ui-icons'

import useStyles from './styles'

/**
 *
 * @param {Object} props
 * @param {string} [props.sx] - Styles object override
 *
 * @returns {React.ReactElement}
 */
function BaseContractModalRoot({ sx = {}, children }) {
  const styles = useStyles()

  return (
    <Modal open disableEscapeKeyDown>
      <Box className={styles.container} style={sx}>
        {/* <CloseCircle className={styles.closeIcon} color="#696969" onClick={handleClose} /> */}
        {children}
      </Box>
    </Modal>
  )
}

/**
 *
 * @param {Object} props
 * @param {string} props.iconType - Icon type
 * @param {string} [props.sx] - Styles object override
 *
 * @returns {React.ReactElement}
 *
 * @example
 * <BaseContractModal.Icon iconType="contract" />
 *
 * @example
 * <BaseContractModal.Icon iconType="warning" />
 */
function BaseContractModalIcon({ iconType, sx = {} }) {
  const styles = useStyles()

  function getIconByType() {
    switch (iconType) {
      case 'contract':
        return (
          <Box className={[styles.modalIconBackground, styles.contractIconBackgroundColor]} sx={sx}>
            <DocumentText1 className={styles.modalIcon} color="#1671ED" />
          </Box>
        )
      case 'warning':
        return (
          <Box className={[styles.modalIconBackground, styles.warningIconBackgroundColor]} sx={sx}>
            <Danger className={styles.modalIcon} color="#F2BD00" />
          </Box>
        )
      case 'check':
        return (
          <Box className={[styles.modalIconBackground, styles.successIconBackgroundColor]} sx={sx}>
            <TickCircle className={styles.modalIcon} color="#FFFFFF" />
          </Box>
        )
      default:
        return null
    }
  }

  return getIconByType()
}

/**
 *
 * @param {Object} props
 * @param {string} props.variant - Header variant
 * @param {string} [props.sx] - Styles object override
 *
 * @returns {React.ReactElement}
 *
 * @example
 * <BaseContractModal.Header variant="variant1">Título</BaseContractModal.Header>
 *
 * @example
 * <BaseContractModal.Header variant="variant2">Título</BaseContractModal.Header>
 */
function BaseContractModalHeader({ variant, sx = {}, children }) {
  const styles = useStyles()
  const variantStyles = {
    variant1: styles.headerVariant1,
    variant2: styles.headerVariant2,
  }

  return (
    <Typography className={[styles.header, variantStyles[variant]]} style={sx}>
      {children}
    </Typography>
  )
}

function BaseContractModalParagraph({ sx = {}, children }) {
  const styles = useStyles()

  return (
    <Typography className={styles.paragraph} style={sx}>
      {children}
    </Typography>
  )
}

function BaseContractModalActionButtonContainer({ sx = {}, children }) {
  const styles = useStyles()

  return (
    <Box className={styles.actionButtonContainer} sx={sx}>
      {children}
    </Box>
  )
}

/**
 *
 * @param {Object} props
 * @param {string} [props.rest] - Button props.
 *
 * For more details, see:
 * - https://github.com/Refera-Tech/refera-ui/tree/dev/packages/web/src/components/Button
 * - https://mui.com/material-ui/api/button/#props
 *
 *
 * @returns {React.ReactElement}
 */
function BaseContractModalActionButton({ children, ...rest }) {
  return <Button {...rest}>{children}</Button>
}

export const BaseContractModal = {
  Root: BaseContractModalRoot,
  Icon: BaseContractModalIcon,
  Header: BaseContractModalHeader,
  Paragraph: BaseContractModalParagraph,
  ActionButtonContainer: BaseContractModalActionButtonContainer,
  ActionButton: BaseContractModalActionButton,
}
