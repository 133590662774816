import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  modalContent: {
    width: '100%',
    height: '180px',
    padding: '20px 20px 0 20px',
  },
  input: {
    width: '100%',
    marginTop: '20px',
  },
}))
