import React from 'react'
import { Dialog } from '@refera/ui-web'
import { CloseCircle as CloseCircleIcon } from '@refera/ui-icons'

const RefusePreApprovedBudgetConfirmationModal = ({ handleCancel, handleConfirm, isOpen }) => {
  return (
    <Dialog
      open={isOpen}
      icon={CloseCircleIcon}
      type="error"
      subject="Atenção!"
      description="Você tem certeza que deseja recusar este orçamento pré-aprovado?"
      labelApprove="Sim"
      labelCancel="Não"
      onApprove={handleConfirm}
      onCancel={handleCancel}
      closeButton
    />
  )
}

export default RefusePreApprovedBudgetConfirmationModal
