export function formatServiceOrderFilter(
  serviceOrderInput,
  defaultErrorMessage = 'Filtro de ID do chamado só pode conter números.'
) {
  // verifica se contém caracteres além de números, vírgula e ponto e vírgula
  if (!/^[0-9,;]+$/.test(serviceOrderInput.replace(/\s/g, ''))) {
    return { message: defaultErrorMessage, type: 'error' }
  }

  // verifica se contém espaços nas extremidades
  let formattedInput = serviceOrderInput.trim()

  // verifica se termina com ponto e vírgula
  if (formattedInput.endsWith(';') || formattedInput.endsWith(',')) {
    formattedInput = formattedInput.slice(0, -1)
  }

  // substitui virgula, ponto e vírgula e espaços por vírgula
  formattedInput = formattedInput.replace(/[,\s;]+/g, ',')

  // remove virgulas e ponto e vírgula do começo e fim
  formattedInput = formattedInput.replace(/^[,;]+|[,;]+$/g, '')

  return { formattedServiceOrder: formattedInput }
}
