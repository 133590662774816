import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette: { error } }) => ({
  loading: {
    display: 'flex',
    '& .MuiCircularProgress-root': {
      color: '#FFFFFF',
    },
  },
  loadingDefault: {
    '& .MuiCircularProgress-root': {
      color: Theme.Colors.Primary.Base,
    },
  },
  button: {
    borderRadius: spacing(24),
    padding: spacing(10, 12),
    textTransform: 'none',
    fontWeight: '500',

    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  redOutlined: {
    borderColor: error.main,
    color: error.main,
  },
}))
