import React, { useCallback, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { Alert, Button, Loader, Modal, TextArea, Toast } from '@refera/ui-web'
import { useDispatch, useSelector } from 'react-redux'
import { navigate, useParams } from '@reach/router'

import { getOriginalBudget, getReasonsToRefuseSelector } from '_modules/budget/selectors'
import { refuseBudget } from '_modules/budget/actions'
import { ROUTES } from '_utils/constants'
import { REASON_TO_REFUSE_BUDGET } from '_utils/constants/budget'
import Select from '_components/common/select'

import useStyles from './styles'

const defaultToast = { isOpen: false, type: '', message: '' }

const RefusePreApprovedBudgetModal = ({ handleClose, isOpen }) => {
  const styles = useStyles()
  const { budgetId } = useParams()
  const reasonsToRefuseList = useSelector(getReasonsToRefuseSelector('inner_budget'))
  const [reasonForRefusal, setReasonForRefusal] = useState()
  const [refusalMoreDetails, setRefusalMoreDetails] = useState()
  const [errors, setErrors] = useState({})
  const [loading, setIsLoading] = useState(false)
  const [toastInfo, setToastInfo] = useState(defaultToast)

  const dispatch = useDispatch()
  const budget = useSelector(getOriginalBudget(budgetId))?.toJS()

  const isMoreDetailsRequired = useMemo(
    () => reasonForRefusal === REASON_TO_REFUSE_BUDGET.OTHER,
    [reasonForRefusal]
  )

  const reasonsToRefuseOptions = useMemo(
    () => reasonsToRefuseList?.map(item => ({ value: item.slug, label: item.reason })),
    [reasonsToRefuseList]
  )

  const handleSelectChange = useCallback(event => {
    setReasonForRefusal(event.target.value)
  }, [])

  const handleInputChange = useCallback(event => {
    setRefusalMoreDetails(event.target.value)
  }, [])

  const handleRefuseBudgetSuccess = () => navigate(ROUTES.TRADESMAN)

  const onSubmit = useCallback(() => {
    setIsLoading(true)

    if (!reasonForRefusal || (isMoreDetailsRequired && !refusalMoreDetails)) {
      setErrors({
        ...(!reasonForRefusal && {
          reasonForRefusal: 'Campo obrigatório',
        }),
        ...(isMoreDetailsRequired &&
          !refusalMoreDetails && {
            refusalMoreDetails: 'Campo obrigatório',
          }),
      })
      setIsLoading(false)
      return
    }

    setErrors()

    const payload = {
      reasonForRefusal,
      refusalMoreDetails,
      alsoCancelServiceOrder: 'no',
      ignoreRevision: true,
      ...(isMoreDetailsRequired && {
        anotherReasonForRefusal: refusalMoreDetails,
      }),
    }

    dispatch(
      refuseBudget({
        serviceOrderId: budget?.serviceOrder,
        budgetId,
        payload,
      })
    )
      .then(() => {
        setIsLoading(false)
        handleRefuseBudgetSuccess()
      })
      .catch(() => {
        setIsLoading(false)
        setToastInfo({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um erro ao executar a ação.',
        })
      })
  }, [
    budgetId,
    dispatch,
    isMoreDetailsRequired,
    reasonForRefusal,
    refusalMoreDetails,
    budget?.serviceOrder,
    handleRefuseBudgetSuccess,
  ])

  const actionsButtons = useMemo(
    () => (
      <Grid container spacing={8} direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Button onClick={handleClose} color="red" variant="secondary">
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onSubmit} disabled={loading}>
            Confirmar
          </Button>
        </Grid>
      </Grid>
    ),
    [handleClose, onSubmit, loading]
  )

  return (
    <Modal
      title="Recusar orçamento pré-aprovado"
      onBackdropClick={handleClose}
      onClose={handleClose}
      open={isOpen}
      actionsButtons={actionsButtons}
    >
      <Loader hasBackdrop open={loading} label="Aguarde..." />
      <Grid container component="form" rowSpacing={3} className={styles.form}>
        <Grid item xs={12}>
          <Select
            label="Selecione o motivo da recusa"
            onChange={handleSelectChange}
            value={reasonForRefusal}
            options={reasonsToRefuseOptions}
            errorMessage={errors?.reasonForRefusal}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            label="Mais detalhes"
            fullWidth
            InputLabelProps={{ classes: { root: styles.textareaLabel }, shrink: true }}
            InputProps={{ classes: { root: styles.textarea } }}
            className={styles.textarea}
            required={isMoreDetailsRequired}
            error={Boolean(errors?.refusalMoreDetails)}
            helperText={errors?.refusalMoreDetails}
            value={refusalMoreDetails}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Toast
        draggable
        open={toastInfo.isOpen}
        autoHideDuration={6000}
        onClose={() => setToastInfo(defaultToast)}
      >
        <Alert
          severity={toastInfo.type}
          title={toastInfo.message}
          onClose={() => setToastInfo(defaultToast)}
        />
      </Toast>
    </Modal>
  )
}

export default RefusePreApprovedBudgetModal
