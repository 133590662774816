import React, { useState, useEffect, useMemo } from 'react'
import { Grid } from '@mui/material'
import { useParams, navigate } from '@reach/router'
import { Datagrid } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'
import { FINANCE_ROUTES } from '_/utils/constants'
import useStyles from '../../styles'
import { ReturnFileDetailsColumns, addLeadingZeros } from '../../utils/constants'
import { useDispatch } from 'react-redux'
import { getPaymentData } from '_/modules/finance/actions'
import { useToast } from '_/hooks/use-toast'
import { TABLE_SX } from '_/views/finance/utils/constants'

const ReturnFileDetails = () => {
  const styles = useStyles()
  const { processingDate } = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [paymentDataList, setPaymentDataList] = useState([])

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const { showToast } = useToast()

  const goBack = () => navigate(FINANCE_ROUTES.BANK_RETURN_FILES)

  const handleGetPaymentData = async () => {
    setIsLoading(true)
    const isoDate = processingDate.replace('@', '.')
    await dispatch(getPaymentData({ processingDate: isoDate, page: page + 1, pageSize }))
      .then(res => setPaymentDataList(res))
      .catch(err => {
        showToast({ message: 'Ocorreu um erro ao buscar os registros', type: 'error' })
        setIsLoading(false)
        console.error(err)
      })
    setIsLoading(false)
  }

  const batchId = useMemo(() => {
    if (paymentDataList?.results && paymentDataList[0]?.batch) {
      return paymentDataList?.results[0]?.batch
    }
    return ''
  }, [paymentDataList?.results])

  useEffect(() => {
    if (processingDate) handleGetPaymentData()
  }, [page, pageSize])

  return (
    <Grid>
      <HeaderTitle
        title={`Detalhes do arquivo de retorno ${batchId && `- Lote ${addLeadingZeros(batchId)}`}`}
        backButtonAction={goBack}
      />
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            loading={isLoading}
            rows={paymentDataList?.results || []}
            columns={ReturnFileDetailsColumns}
            paginationMode="server"
            rowCount={paymentDataList?.count}
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            sx={TABLE_SX}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReturnFileDetails
