import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'grid',
    gridRowGap: spacing(24),
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  label: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: palette.gray[64],
  },
  modal: {
    width: '100vw',
  },
}))
