import { Block } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto',
  },
  containerWithColumns: {
    gridTemplateColumns: 'auto 1fr',
  },
  cards: {
    padding: spacing(32),
    marginBottom: spacing(64),
  },
  navigate: {
    display: Block,
    marginRight: 'auto',
    height: spacing(24),
    width: spacing(24),
  },
  save: {
    backgroundColor: 'white',
    padding: spacing(18),
    height: spacing(72),
    boxShadow: `${spacing(0, 1, 2)} rgba(0, 0, 0, 0.12);`,
  },
  button: {
    margin: spacing(0, 16),
  },
  loading: {
    height: '100vh',
  },
  buttonEdit: {
    color: palette.primary.main,
    borderColor: palette.primary.main,
  },
  buttonEditIcon: {
    paddingLeft: spacing(5),
  },
}))
