import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  priority: {
    display: 'flex',
    alignItems: 'center',
    width: '150px',
    marginRight: 'auto',
    paddingLeft: '8px',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: 8,
  },
  buttons: {
    padding: '7px 15px',
    color: '#1671ED',
    marginRight: '10px',
    borderColor: '#1671ED',
    borderRadius: '20px',
    textTransform: 'none',
  },
  buttonRed: {
    color: 'red',
    borderColor: 'red',
  },
  buttonSend: {
    borderRadius: '20px',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}))
