import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  titleBlue: {
    color: '#1671ED',
    cursor: 'move',
  },
  dialog: {
    // marginTop: '6%',
    maxheight: '900px',
    minHeight: '68%',
  },
  container: {
    display: 'flex',
    width: '800px',
    maxWidth: '800px',
    height: '100%',
    padding: '50px',
    flexDirection: 'column',
    borderTop: '2px solid #E3E3E3',
    overflowX: 'hidden',
  },
  dialogAction: {
    padding: '20px 30px',
  },
  button: {
    height: '40px',
    width: '140px',
    padding: '10px 16.5px',
  },
  inlineLabel: {
    display: 'flex',
    gap: '6px',
  },
  required: {
    color: '#C90000',
  },
  editMessage: {
    marginBottom: '10px',
  },
  messageTitle: {
    fontWeight: '600',
    fontSize: '20px',
    color: '#141414',
    marginBottom: '12px',
  },
  messageBody: {
    fontWeight: '400',
    fontSize: '16px',
    color: '#696969',
    marginBottom: '12px',
    width: '100%',
    overflowWrap: 'break-word',
  },
  messageFooter: {
    fontWeight: '400',
    fontSize: '16px',
    color: '#B4B4B4',
  },
  checkbox: {
    position: 'absolute',
    bottom: '3%',
  },
  labels: {
    fontWeight: '500',
    fontSize: '1.2rem',
    marginBottom: '10px',
    color: palette.gray[100],
  },
  attachment: {
    paddingTop: '12px',
  },
  subjectField: {
    maxHeight: 200,
    fontSize: '1.6rem',
    color: palette.gray[32],
    overflowY: 'auto',
    marginBottom: 16,

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  intermediaryContainer: {
    marginTop: 16,
  },
  fLabel: {
    fontWeight: '400',
    fontSize: '1.2rem',
    marginTop: '-11px',
    color: '#434343',
  },
  messageWrapper: {
    marginBottom: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid #E3E3E3',
  },
  answerContainer: {
    marginTop: 20,
  },
}))
