import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { Alert, Button, Datagrid, Toast } from '@refera/ui-web'
import Theme from '@refera/ui-core'

import { navigate } from '@reach/router'

import HeaderTitle from '_/components/header-title'
import { FINANCE_ROUTES } from '_/utils/constants'

import Svg, { ICON } from '_components/svg'
// import { PDFDownloadLink } from '@react-pdf/renderer'
import useStyles from '../styles'
import { useDispatch } from 'react-redux'
import {
  // downloadReturnFilePdf,
  getReturnFilesList,
  uploadReturnFile,
} from '_/modules/finance/actions'
// import ReturnFilePdf from './returnFilePdf'
import {
  fileErrorLoadMessage,
  fileErrorUploadFileType,
  fileErrorUploadMessage,
  fileSuccessMessage,
  //  addLeadingZeros,
  ReturnFilesColumns,
} from '../utils/constants'

// const errorDownloadMessage =
//   'Algo de errado aconteceu com o download do arquivo de retorno. Tente novamente'

const BankReturnFiles = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const goBack = () => navigate(FINANCE_ROUTES.MANAGE_INSTALLMENTS)
  const fileInputRef = useRef(null)
  // const buttonRef = useRef(null)
  const [toastMessage, setToastMessage] = useState('')
  const [returnFiles, setReturnFiles] = useState([])
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState('')
  const [pageSize, setPageSize] = useState(10)
  // const [pdfInfo, setPdfInfo] = useState({})
  // const [disableDownloadButton, setDisableDownloadButton] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleGetReturnFilesList = async () => {
    setIsLoading(true)
    await dispatch(
      getReturnFilesList({
        page: page + 1,
        pageSize,
        orderBy,
      })
    )
      .then(res => {
        setReturnFiles(res)
      })
      .catch(() => setToastMessage(fileErrorLoadMessage))
    setIsLoading(false)
  }

  // const handlePdfDownload = () => {
  //   if (Object.keys(pdfInfo).length === 0) return
  //   // eslint-disable-next-line consistent-return
  //   return (
  //     <PDFDownloadLink
  //       document={<ReturnFilePdf pdfInfo={pdfInfo} />}
  //       fileName={`Retorno_${addLeadingZeros(pdfInfo.id)}.pdf`}
  //     >
  //       <button type="button" ref={buttonRef}>
  //         &nbsp;
  //       </button>
  //     </PDFDownloadLink>
  //   )
  // }

  // const handlePdfButtonClick = () => {
  //   if (buttonRef.current) {
  //     buttonRef.current.click()
  //   }
  //   setIsLoading(false)
  //   setDisableDownloadButton(false)
  // }

  // useEffect(() => {
  //   if (Object.keys(pdfInfo).length > 0) {
  //     setTimeout(handlePdfButtonClick, 500)
  //   }
  // }, [pdfInfo])

  // const handleDownloadReturnFilePdf = async ({ batchId }) => {
  //   setDisableDownloadButton(true)
  //   setIsLoading(true)
  //   await dispatch(downloadReturnFilePdf({ batchId }))
  //     .then(res => setPdfInfo(res.results))
  //     .catch(() => {
  //       setToastMessage(errorDownloadMessage)
  //       handlePdfButtonClick()
  //     })
  // }

  const handleCloseToast = () => setToastMessage('')
  const toastSeverity = useMemo(
    () => (toastMessage.includes('sucesso') ? 'success' : 'error'),
    [toastMessage]
  )

  const handleFileChange = async event => {
    setIsLoading(true)
    const file = event.target.files[0]
    const fileTypes = ['.rem', '.txt']
    const endsWithFileType = fileTypes.some(type => file?.name?.toLowerCase().endsWith(type))

    if (file && endsWithFileType) {
      const payload = { returnFile: file }
      await dispatch(uploadReturnFile(payload))
        .then(() => {
          setToastMessage(fileSuccessMessage)
          setIsLoading(false)
          handleGetReturnFilesList()
        })
        .catch(error => {
          setToastMessage(error?.detail ? error.detail : fileErrorUploadMessage)
          setIsLoading(false)
        })
    } else {
      setToastMessage(fileErrorUploadFileType)
    }
    fileInputRef.current.value = ''
    setIsLoading(false)
  }

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleRowClick = processingDate => {
    if (processingDate) {
      const formattedDate = processingDate.replace('.', '@')
      navigate(`${FINANCE_ROUTES.BANK_RETURN_FILES}/${formattedDate}`)
    }
  }

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('processingDate')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  useEffect(() => {
    handleGetReturnFilesList()
  }, [page, pageSize, orderBy])

  return (
    <Grid>
      <HeaderTitle title="Arquivos de retorno" backButtonAction={goBack}>
        <Button
          variant="ghost"
          onClick={handleFileClick}
          startIcon={
            <Svg
              type={ICON.DOWNLOAD}
              color={Theme.Colors.Primary.Base}
              style={{ rotate: '180deg' }}
            />
          }
          // disabled={selectedItems.length === 0}
        >
          <input
            type="file"
            ref={fileInputRef}
            accept=".rem"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          Fazer upload de novo arquivo
        </Button>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            loading={isLoading}
            rows={returnFiles?.results?.length ? returnFiles?.results : []}
            columns={ReturnFilesColumns}
            paginationMode="server"
            rowCount={returnFiles?.count}
            page={page}
            pageSize={pageSize}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            onSortModelChange={handleOrderBy}
            onRowClick={params => handleRowClick(params.row.processingDate)}
            sx={{
              fontSize: '1.4rem',
              '& .MuiDataGrid-cell:hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Grid>
      </Grid>
      {toastMessage.length !== 0 && (
        <Toast
          draggable
          open={toastMessage.length > 1}
          autoHideDuration={6000}
          onClose={handleCloseToast}
          severity={toastSeverity}
        >
          <Alert severity={toastSeverity} title={toastMessage} onClose={handleCloseToast} />
        </Toast>
      )}
      {/* {handlePdfDownload()} */}
    </Grid>
  )
}

export default BankReturnFiles
