/* eslint-disable consistent-return */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams, navigate } from '@reach/router'
import { useForm, FormProvider } from 'react-hook-form'

import BudgetForm from '_components/budget/budget-form'
import Navbar from '_components/budget/navbar'
import Header, { HEADER_THEME } from '_components/header'
import Loading from '_components/loading'
import Footer from '_components/footer'
import ModalDialog, { SUCCESS_MODAL, WARNING_MODAL } from '_components/modal/modal-dialog'
import {
  deleteBudgetPictures,
  getBudgetById,
  getStepStatusLog,
  updateBudget,
  updateBudgetItems,
} from '_modules/budget/actions'
import {
  getOriginalBudget,
  getStepStatusLogSelector,
  updateBudgetErrorSelector,
} from '_modules/budget/selectors'
import {
  getButtonsAction,
  getServiceOrder,
  getServiceOrderTradesman,
} from '_modules/service-orders/actions'
import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'
import { useIsCurrentBudgetTradesman } from '_hooks/budget/use-is-current-budget-tradesman'
import { ROUTES } from '_utils/constants'
import { BudgetContext, UPDATE_BUDGET_TYPE } from '_utils/budget'
import { createFormDataDocuments } from '_utils/helpers'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'

import useStyles from './styles'
import { useToast } from '_/hooks/use-toast'
import { useDialog } from '_/hooks/use-dialog'

const BudgetView = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { budgetId } = useParams()
  const { isAdmin, isIntermediary, isLessee, isTradesman, isSAAS } = useRolePermission()
  const { showDialog, closeDialog } = useDialog()

  const isCurrentTradesman = useIsCurrentBudgetTradesman(getOriginalBudget(budgetId))
  const errorBudget = useSelector(updateBudgetErrorSelector)
  const budget = useSelector(getOriginalBudget(budgetId))?.toJS()
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const category = serviceOrder?.category.toJS()[0]
  const categoryAllowsNoAttachments = category?.allowsNoAttachments

  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const isProposeOpened = useMemo(() => budget?.proposeOpened, [budget?.proposeOpened])
  const [isEditing, toggleIsEditing] = useToggle()
  const [isLoading, setIsLoading] = useState(true)

  const formatBudgetPictures = useMemo(
    () =>
      budget?.budgetPictures?.map(item => {
        return { id: item.id, file: item.picture }
      }) || [],
    [budget]
  )

  const [budgetPictures, setBudgetPictures] = useState(formatBudgetPictures)
  const [deletePictures, setDeletePictures] = useState([])
  const [updateBudgetType, setUpdateBudgetType] = useState('default')
  const [sendBudget, setSendBudget] = useState(false)
  const [budgetTemplate, setBudgetTemplate] = useState([])

  const budgetItems = useMemo(
    () =>
      budget?.budgetItems?.map(item => ({
        description: item.description,
        price: item.price,
      })) || [],
    [budget?.budgetItems]
  )

  const defaultValues = useMemo(
    () => ({
      diagnosis: budget?.diagnosis ?? '',
      budgetItems,
      proposedSolution: budget?.proposedSolution ?? '',
      laborValue: 0,
      displacementValue: 0,
      installments: budget?.installments || 0,
      warrantyMonths: budget?.warrantyMonths || 0,
      acceptsInstallment:
        typeof budget?.acceptsInstallment === 'boolean'
          ? String(budget.acceptsInstallment)
          : 'true',
      visitHappened: budget?.visitHappened === undefined ? 'true' : String(budget?.visitHappened),
      acceptsWarranty:
        typeof budget?.acceptsWarranty === 'boolean' ? String(budget.acceptsWarranty) : 'true',
    }),
    [budget, budgetItems]
  )

  const methods = useForm({ defaultValues })
  const { errors, getValues, reset } = methods

  const isEditingOpenedPropose = useMemo(
    () => isProposeOpened && isTradesman,
    [isProposeOpened, isTradesman]
  )

  const getPayload = useCallback(() => {
    const rawData = getValues()
    const formattedData = Object.keys(rawData).reduce((previousValue, currentKey) => {
      if (rawData[currentKey] === 'true') {
        return {
          ...previousValue,
          [currentKey]: true,
        }
      }

      if (rawData[currentKey] === 'false') {
        return {
          ...previousValue,
          [currentKey]: false,
        }
      }

      return {
        ...previousValue,
        [currentKey]: rawData[currentKey],
      }
    }, {})

    const newPictures = budgetPictures.filter(img => !img.file)

    const payload = {
      ...formattedData,
      ...(newPictures.length ? { budgetPictures: newPictures } : {}),
      budgetItems: formattedData?.budgetItems?.map(item => ({
        description: item?.description,
        price: item?.price,
      })),
    }

    return payload
  }, [budgetPictures, updateBudgetType, isAdmin])

  const onConfirmAndNavigate = useCallback(() => {
    dispatch(getBudgetById(budgetId))
    dispatch(getStepStatusLog(budget?.serviceOrder, budgetId))
    dispatch(getServiceOrder(serviceOrder?.get('id')))

    navigate(
      `${isAdmin || isSAAS ? ROUTES.SERVICE_ORDER : ROUTES.DETAIL_SERVICE_ORDER}/${
        budget?.serviceOrder
      }`
    )
  }, [dispatch, budgetId, budget?.serviceOrder, serviceOrder, isAdmin, isSAAS])

  const onUpdateBudgetSuccess = useCallback(() => {
    if (updateBudgetType === UPDATE_BUDGET_TYPE.TEMPORARY) {
      setModalDialog({
        isOpen: true,
        subTitle: 'Orçamento salvo temporariamente',
        type: SUCCESS_MODAL,
      })
    }

    if (updateBudgetType === UPDATE_BUDGET_TYPE.DEFAULT) {
      setModalDialog({
        isOpen: true,
        subTitle: isProposeOpened ? 'Registro salvo com sucesso.' : 'O orçamento foi enviado.',
        type: SUCCESS_MODAL,
        onClick: onConfirmAndNavigate,
      })
    }
    toggleIsEditing()
  }, [isProposeOpened, updateBudgetType, onConfirmAndNavigate])

  const onUpdateBudgetError = useCallback(() => {
    if (updateBudgetType === UPDATE_BUDGET_TYPE.TEMPORARY) {
      setModalDialog({
        isOpen: true,
        subTitle: `Não foi possível salvar as alterações feitas nesse orçamento.
              Tente novamente mais tarde.`,
        type: WARNING_MODAL,
      })

      return
    }

    setModalDialog({
      isOpen: true,
      subTitle: `Não foi possível salvar as alterações feitas nesse orçamento.
        Tente novamente mais tarde.`,
      type: WARNING_MODAL,
    })
  }, [updateBudgetType])

  const handleUpdateBudget = useCallback(
    (updateBudgetTypeProps = UPDATE_BUDGET_TYPE.DEFAULT) => {
      const rawData = getValues()

      const {
        diagnosis,
        proposedSolution,
        installments,
        acceptsInstallment,
        warrantyMonths,
        acceptsWarranty,
        budgetItems: items,
      } = rawData

      let subTitleModalWarning = 'Por favor, preencha os campos obrigatórios antes de continuar.'
      const hasErrorInAttachments = !categoryAllowsNoAttachments && budgetPictures.length === 0
      const hasErrors = Object.keys(errors).length > 0 || !items?.length || hasErrorInAttachments

      const isNotValidData =
        (!sendBudget &&
          (Number(installments) < 2 || isNaN(installments)) &&
          acceptsInstallment === 'true') ||
        ((Number(warrantyMonths) < 1 || isNaN(warrantyMonths)) && acceptsWarranty === 'true') ||
        !diagnosis ||
        !proposedSolution ||
        (items &&
          items.some(
            item =>
              Number(item.price) <= 0 || !item.description || typeof item.description !== 'string'
          ))

      if (Number(installments) < 2) {
        subTitleModalWarning =
          'Você não pode parcelar em apenas uma vez. Indique outra quantidade de parcelamento para prosseguir.'
      }

      if (isNotValidData) {
        if (!sendBudget) {
          subTitleModalWarning = 'Por favor, preencha os campos obrigatórios antes de continuar.'
        } else if (
          acceptsInstallment === 'true' &&
          (Number(installments) < 2 || isNaN(installments))
        ) {
          subTitleModalWarning =
            'A quantidade de parcelas informada é inválida. Indique um valor maior que 1.'
        } else if (
          acceptsWarranty === 'true' &&
          (Number(warrantyMonths) < 1 || isNaN(warrantyMonths))
        ) {
          subTitleModalWarning = 'Informe o número de meses de garantia.'
        } else if (!diagnosis) {
          subTitleModalWarning = 'A descrição do diagnóstico está em branco.'
        } else if (!proposedSolution) {
          subTitleModalWarning = 'A descrição da solução proposta está em branco.'
        } else if (
          items &&
          items.some(
            item =>
              Number(item.price) <= 0 || !item.description || typeof item.description !== 'string'
          )
        ) {
          subTitleModalWarning = 'Existem itens com preço zerado ou descrição em branco.'
        } else if (!categoryAllowsNoAttachments && budgetPictures.length === 0) {
          subTitleModalWarning = `Você não pode enviar orçamentos de chamados da categoria ${category?.name} sem anexos. Favor inserir anexos para prosseguir.`
        }
      }

      if (
        (hasErrors || isNotValidData) &&
        !isEditingOpenedPropose &&
        updateBudgetType !== UPDATE_BUDGET_TYPE.TEMPORARY
      ) {
        setModalDialog({
          isOpen: true,
          subTitle: subTitleModalWarning,
          type: WARNING_MODAL,
        })
        return
      }

      if (deletePictures.length > 0) {
        const pictureIds = deletePictures.map(picture => picture?.id)
        dispatch(deleteBudgetPictures(budget.serviceOrder, budgetId, { ids: pictureIds }))
        return
      }

      const payload = createFormDataDocuments(getPayload())
      payload.delete('budget_items')

      if (budget?.proposeOpened && isAdmin) {
        payload.append('saving', true)
      }

      payload.append('budget_items', JSON.stringify(getPayload().budgetItems))

      payload.append('released', updateBudgetTypeProps === UPDATE_BUDGET_TYPE.DEFAULT && !isAdmin)

      setIsLoading(true)

      // TODO: Check a better way to handle with budget pictures in the payload
      dispatch(
        updateBudget(budget?.serviceOrder, budgetId, payload, {
          transformPayload: false,
          formData: true,
        })
      )
        .then(() => {
          if (updateBudgetTypeProps === UPDATE_BUDGET_TYPE.TEMPORARY) {
            showDialog({
              type: 'success',
              subject: 'Sucesso!',
              description: 'Orçamento salvo temporariamente',
              onApprove: closeDialog,
              labelApprove: 'Ok',
            })
          } else {
            showDialog({
              type: 'success',
              subject: 'Sucesso!',
              description: isProposeOpened
                ? 'Registro salvo com sucesso.'
                : 'O orçamento foi enviado.',
              onApprove: () => {
                closeDialog()
                onConfirmAndNavigate()
              },
              labelApprove: 'Ok',
            })
          }
          toggleIsEditing()
        })
        .catch(onUpdateBudgetError)
        .finally(() => {
          setIsLoading(false)
        })
    },
    [
      budget?.serviceOrder,
      budgetId,
      deletePictures,
      dispatch,
      isEditingOpenedPropose,
      sendBudget,
      budgetPictures,
      categoryAllowsNoAttachments,
      updateBudgetType,
      isAdmin,
    ]
  )

  const handleUpdateBudgetItems = useCallback(
    async (updateBudgetTypeProps = UPDATE_BUDGET_TYPE.DEFAULT) => {
      let payload = {
        budget_items: getPayload().budgetItems,
      }

      if (updateBudgetTypeProps !== UPDATE_BUDGET_TYPE.DEFAULT) {
        payload = {
          ...payload,
          released: false,
        }
      }

      setIsLoading(true)
      dispatch(
        updateBudgetItems(budget?.serviceOrder, budgetId, payload, {
          transformPayload: false,
        })
      )
        .then(() => {
          handleUpdateBudget(updateBudgetTypeProps)
        })
        .catch(() => {
          showToast({ type: 'error' })
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [budget?.serviceOrder, budgetId, getPayload, updateBudgetType, isAdmin]
  )

  useEffect(() => {
    if (errorBudget?.first() === 'service_order_canceled') {
      setModalDialog({
        isOpen: true,
        subTitle: `Infelizmente este chamado acabou de ser cancelado.
          Favor contatar a Refera para obter maiores informações.`,
        type: WARNING_MODAL,
      })
    }
  }, [errorBudget, isEditing, isProposeOpened, isAdmin])

  const checkUserAccess = useCallback(() => {
    if (!budget) {
      return
    }

    const shouldRedirectToExpiredLink =
      isIntermediary || isLessee || (isTradesman && !isCurrentTradesman)

    const shouldRedirectToCommonBudgetView =
      isAdmin && (!budget.proposeOpened || (budget.proposeOpened && budget.diagnosis))

    if (shouldRedirectToCommonBudgetView) {
      return (
        <Redirect to={`${ROUTES.SERVICE_ORDER}/${budget.serviceOrder}/orcamento/${budget.id}`} />
      )
    }

    if (shouldRedirectToExpiredLink) {
      return <Redirect to="/link-expirado" />
    }
  }, [budget, isAdmin, isCurrentTradesman, isIntermediary, isLessee, isTradesman, stepStatusLog])

  const onGetBudgetSuccess = useCallback(async () => {
    setIsLoading(true)
    reset(defaultValues)
    setBudgetPictures(formatBudgetPictures)
    await dispatch(getServiceOrderTradesman(budget?.serviceOrder))
    await dispatch(getStepStatusLog(budget?.serviceOrder, budgetId)).then(() => checkUserAccess())

    setIsLoading(false)

    if (!isEditing && isProposeOpened && isAdmin) {
      toggleIsEditing()
    }
  }, [
    budget?.serviceOrder,
    budgetId,
    defaultValues,
    dispatch,
    isAdmin,
    isEditing,
    isProposeOpened,
    toggleIsEditing,
    formatBudgetPictures,
  ])

  const fetchBudget = useCallback(async () => {
    if (budgetId !== 'null') {
      setIsLoading(true)
      dispatch(getBudgetById(budgetId)).catch(onReject)

      await dispatch(getButtonsAction()).finally(() => setIsLoading(false))
    }
  }, [budgetId])

  useEffect(() => {
    fetchBudget()
  }, [budgetId])

  useEffect(() => {
    reset(defaultValues)
  }, [budget?.diagnosis, budget?.proposedSolution])

  const onReject = useCallback(() => {
    return <Redirect to="/link-expirado" />
  }, [])

  const budgetContextValue = useMemo(
    () => ({
      handleUpdateBudgetItems,
      isEditing,
      toggleIsEditing,
      budgetPictures,
      setBudgetPictures,
      deletePictures,
      setDeletePictures,
      setUpdateBudgetType,
      sendBudget,
      setSendBudget,
      formatBudgetPictures,
      handleUpdateBudget,
      budgetTemplate,
      setBudgetTemplate,
      onUpdateBudgetSuccess,
      onUpdateBudgetError,
      setIsLoading,
    }),
    [
      isEditing,
      toggleIsEditing,
      budgetPictures,
      setBudgetPictures,
      deletePictures,
      setDeletePictures,
      setUpdateBudgetType,
      handleUpdateBudget,
      sendBudget,
      setSendBudget,
      formatBudgetPictures,
      budgetTemplate,
      setBudgetTemplate,
      onUpdateBudgetSuccess,
      onUpdateBudgetError,
      setIsLoading,
    ]
  )

  useEffect(() => {
    if (budget?.id) onGetBudgetSuccess()
  }, [budget?.id])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Header theme={HEADER_THEME.NO_BUTTONS} />
      <BudgetContext.Provider value={budgetContextValue}>
        <main>
          <FormProvider {...methods}>
            <Navbar />
            <BudgetForm />
          </FormProvider>
          <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
        </main>
      </BudgetContext.Provider>
      <Footer className={styles.footer} />
    </>
  )
}

export default BudgetView
