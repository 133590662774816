import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  closeButton: {
    position: 'absolute',
    right: 4,
    top: 12,
  },
  title: {
    ' &.MuiDialogTitle-root': {
      padding: '16px 24px',
    },
    color: palette.primary.main,
  },
  overflow: {
    '&.MuiDialog-root .MuiDialog-paper': {
      overflowY: 'visible',
    },
  },
  dialog: {
    '&.MuiDialogContent-root': {
      padding: '0 24px 16px 24px',
      overflow: 'visible',
    },
  },
  contentContainer: {
    minWidth: 500,
  },
  selectInput: {
    marginTop: '24px',
  },
  optionContainer: {
    width: 490,
    '&.MuiMenuItem-root': {
      whiteSpace: 'unset',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  selectContainer: {
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'normal',
    },
  },
  optionLabel: {
    color: palette.gray[80],
  },
  optionHelperText: {
    fontSize: '1.2rem',
    color: palette.gray[32],
  },
  buttonContainer: {
    marginTop: '24px',
    padding: '16px 24px',
  },
  button: {
    minWidth: 90,
  },
  selectInputLabel: {
    color: palette.gray[80],
    fontWeight: 'bold',
    paddingBottom: '4px',
  },
}))
