export const leadProviderListColumns = [
  {
    field: 'rating',
    headerName: 'Nota atual',
    width: 100,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Nome',
    flex: 1,
    editable: false,
  },
  {
    field: 'personResponsibleName',
    headerName: 'Responsável',
    flex: 1,
    editable: false,
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    flex: 1,
    editable: false,
  },
  {
    field: 'categories',
    headerName: 'Categorias',
    flex: 1,
    editable: false,
  },
  {
    field: 'contactEmail',
    headerName: 'E-mail',
    flex: 1,
    editable: false,
  },
]

export const confirmationMenuTypes = ['import']
