import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Grid } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import * as Modal from '_components/modal/generic-modal'
import { Button } from '@refera/ui-web'
import {
  deleteProblem,
  getProblemById,
  getProblemList,
  postProblem,
  updateProblem,
} from '_/modules/service-orders/actions'
import Loading from '_components/loading'
import { useToast } from '_/hooks/use-toast'

import useStyles from './styles'
import { ListProblems } from './list'
import { ProblemCreate } from './main'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

const ProblemsScreen = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { checkIfUserDoesNotPermission } = useRolePermission()

  const [deleteModal, setDeleteModal] = useState(false)

  const [isDeleting, setIsDeleting] = useState(false)
  const [problemSelectId, setProblemSelectId] = useState(null)
  const [problemsList, setProblemsList] = useState([])

  const methods = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {
      name: '',
      active: false,
      servicesList: [],
    },
  })

  const { setValue, reset } = methods

  const message = {
    success: 'Ação executada com sucesso.',
  }

  const openDeleteModal = () => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.PROBLEMS, PERMISSIONS_ACTIONS.DELETE)) {
      return
    }

    setDeleteModal(true)
  }

  const handleSelection = useCallback(
    selectedId => {
      dispatch(getProblemById(selectedId)).then(res => {
        reset({
          id: res?.id,
          name: res?.name,
          active: res?.active,
          services: res?.services,
        })
        setProblemSelectId(res?.id)
      })
    },
    [dispatch, setValue]
  )

  const handleCreate = useCallback(() => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.PROBLEMS, PERMISSIONS_ACTIONS.ADD)) {
      return
    }

    setProblemSelectId(null)

    reset({
      name: '',
      active: false,
      servicesList: [],
    })
  }, [])

  const handleDelete = useCallback(async () => {
    setIsDeleting(true)
    await Promise.resolve(dispatch(deleteProblem(problemSelectId)))
      .catch(() =>
        showToast({
          message: message.error,
          type: 'error',
        })
      )
      .then(() => {
        setIsDeleting(() => false)
        showToast({
          message: message.success,
          type: 'success',
        })

        setProblemSelectId(null)

        dispatch(getProblemList()).then(resList => setProblemsList(resList))
      })

    setIsDeleting(() => false)
    setDeleteModal(false)
  }, [problemSelectId, handleCreate])

  const handlePost = useCallback(
    payload => {
      if (
        checkIfUserDoesNotPermission(
          PERMISSIONS.PROBLEMS,
          problemSelectId
            ? [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT]
            : PERMISSIONS_ACTIONS.ADD
        )
      ) {
        return
      }

      dispatch(problemSelectId ? updateProblem(problemSelectId, payload) : postProblem(payload))
        .catch(error =>
          showToast({
            message: error?.error_message ?? message.error,
            type: 'error',
          })
        )
        .then(() => {
          showToast({
            message: message.success,
            type: 'success',
          })

          dispatch(getProblemList()).then(resList => setProblemsList(resList))
        })
    },
    [problemSelectId]
  )

  useEffect(() => {
    dispatch(getProblemList()).then(res => setProblemsList(res))
  }, [])

  if (!problemsList) {
    return <Loading />
  }

  return (
    <FormProvider {...methods}>
      <Grid className={styles.container}>
        <Grid className={styles.header}>
          <Typography variant="h4" component="h1" className={styles.title}>
            Problemas
          </Typography>
          <Grid className={styles.buttonWrapper}>
            <Button onClick={() => handleCreate()}>Novo</Button>
            <Button color="red" disabled={!problemSelectId || isDeleting} onClick={openDeleteModal}>
              Excluir
            </Button>
          </Grid>
        </Grid>

        <Grid className={styles.main}>
          <ListProblems list={problemsList} handleClick={handleSelection} />

          <ProblemCreate handlePost={handlePost} problemSelectId={problemSelectId} />
        </Grid>

        <Modal.Root
          className={styles.rootConfirm}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        >
          <Modal.TitleModal title="Excluir categoria" />
          <Modal.Content className={styles.dialogContent}>
            <Typography>Você tem certeza que deseja executar esta ação?</Typography>
          </Modal.Content>

          <Modal.Actions>
            <Modal.ButtonFullRed onClick={() => setDeleteModal(false)}>Não</Modal.ButtonFullRed>
            <Modal.ButtonFullBlue onClick={handleDelete}>Sim</Modal.ButtonFullBlue>
          </Modal.Actions>
        </Modal.Root>
      </Grid>
    </FormProvider>
  )
}

export default ProblemsScreen
