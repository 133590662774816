import humps from 'humps'

import { SERVICE_ORDER_STATUS } from '_utils/constants'
import { notEmptyPayload, createFormDataDocuments } from '_utils/helpers'

import { get, patch, put, post, del } from './requests'

const renderQueryParams = params => {
  const newParams = { ...params }

  if (newParams?.orderStatus === SERVICE_ORDER_STATUS.ALL) {
    newParams.orderStatus = ''
  }
  newParams.active = 'true'

  if (newParams?.stage === 'cancelled') {
    newParams.active = 'false'
    newParams.stage = ''
  }

  if (newParams?.stage === 'allStages') {
    newParams.active = ''
    newParams.stage = ''
  }

  if (newParams?.status === 'allStages') {
    newParams.active = ''
    newParams.status = ''
  }

  return humps.decamelizeKeys(notEmptyPayload(newParams))
}

export const getServiceOrders = key => params => {
  return get(['serviceordersboard'], {
    key,
    transformPayload: true,
    params: renderQueryParams(params),
  })
}

export const getServiceOrder = key => serviceOrderId =>
  get(['serviceordersboard', serviceOrderId], { key })

export const getActiveBudget = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'get_active_budget'], { key })

export const getReasonsToReprove = key =>
  get(['reasons-to-reprove'], { key, transformPayload: true })

// ------------------ CATEGORY REQUESTS
export const getCategoryList = key => params =>
  get(['categories-tree'], { key, transformPayload: true, params })

export const getCategoryById = key => categoryId =>
  get(['category', categoryId], { key, transformPayload: true })

export const deleteCategory = key => categoryId =>
  del(['category', categoryId], { key, transformPayload: true })

export const postCategory = key => payload =>
  post(['category'], { key, transformPayload: true }, payload)

export const updateCategory = key => (categoryId, payload) =>
  patch(['category', categoryId], { key, transformPayload: true }, payload)

// ------------------ SERVICE REQUESTS
export const getServiceList = key => params =>
  get(['services'], { key, transformPayload: true, params })

export const getServiceById = key => serviceId =>
  get(['services', serviceId], { key, transformPayload: true })

export const updateService = key => (serviceId, payload) =>
  patch(['services', serviceId], { key, transformPayload: true }, payload)

export const deleteService = key => serviceId =>
  del(['services', serviceId], { key, transformPayload: true })

export const postService = key => payload =>
  post(['services'], { key, transformPayload: true }, payload)

// -------------------------------

// ------------------ SERVICE REQUESTS
export const getProblemList = key => params =>
  get(['problem'], { key, transformPayload: true, params })

export const getProblemById = key => problemId =>
  get(['problem', problemId], { key, transformPayload: true })

export const updateProblem = key => (problemId, payload) =>
  patch(['problem', problemId], { key, transformPayload: true }, payload)

export const deleteProblem = key => problemId =>
  del(['problem', problemId], { key, transformPayload: true })

export const postProblem = key => payload =>
  post(['problem'], { key, transformPayload: true }, payload)

// -------------------------------

export const getServiceApproved = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'service-approved'], { key })

export const postStatusChange = key => serviceOrderId =>
  post(['serviceorders', serviceOrderId, 'change-stepstatus'], { key })

export const cancelServiceOrderApproved = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'cancel-approve-finished-service'], { key })

export const reopenServiceOrder = key => serviceOrderId =>
  patch(['serviceordersboard', serviceOrderId, 'reopen-serviceorder'], { key })

export const cancelServiceOrderRejected = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'cancel-reproved-end-of-service'], { key })

export const resendEmailOnCancelServiceOrderRejected = key => (serviceOrderId, payload) =>
  post(['serviceorders', serviceOrderId, 'send-email-cancelled-reprove'], { key }, payload)

export const getServiceOrderApprovalAndExecutionInfos = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'approval-and-execution-infos'], { key })

export const getTradesmanById = key => tradesmanId =>
  get(['cs-serviceprovider', tradesmanId], { key })

export const updateServiceOrder = key => (serviceOrderId, payload) =>
  patch(
    ['serviceordersboard', serviceOrderId],
    {
      key,
      transformOnlyResponse: !!payload?.attachments,
      transformPayload: true,
      formData: !!payload?.attachments?.length,
    },
    payload?.attachments?.length ? createFormDataDocuments(payload) : payload
  )

export const updateServiceOrderClassifications = key => (serviceOrderId, payload) =>
  patch(['serviceorders', serviceOrderId, 'update-serviceorder-classifications'], { key }, payload)

export const updateServiceOrderDateSchedule = (key, serviceOrderId, payload) =>
  patch(['serviceorders', serviceOrderId], { key }, payload)

export const updateDistanceBudget = key => (serviceOrderId, payload) =>
  patch(['serviceorders', serviceOrderId, 'update-distance-budget'], { key }, payload)

export const updateAppointmentDateSchedule = (key, serviceOrderId, payload) =>
  patch(['serviceorders', serviceOrderId, 'serviceorders-appointment'], { key }, payload)

export const deleteAttachment = key => (serviceOrderId, attachmentId) =>
  patch(['serviceordersboard', serviceOrderId, 'delete-attachment'], { key }, { attachmentId })

export const cancelServiceOrder = key => (serviceOrderId, cancelReasonId, cancelDetails) =>
  patch(
    ['serviceordersboard', serviceOrderId, 'cancel-service'],
    { key },
    { reasonToCancelUuid: cancelReasonId, cancelDetails }
  )

export const closeServiceOrder = key => (serviceOrderId, payload) =>
  post(['serviceorders', serviceOrderId, 'close'], { key }, payload)

export const cancelServiceOrderFinish = key => (serviceOrderId, reproveReason) =>
  post(
    ['serviceorders', serviceOrderId, 'reprove-finish-service'],
    { key, transformOnlyResponse: true, formData: true },
    createFormDataDocuments(reproveReason)
  )

export const updateStepStatus = key => (serviceOrderId, payload) =>
  put(['serviceorders', serviceOrderId, 'update-step-status'], { key }, payload)

export const registerExecution = key => (serviceOrderId, payload) =>
  post(['serviceorders', serviceOrderId, 'register-execution'], { key }, payload)

export const registerExecutionPostWork = key => (serviceOrderId, payload) =>
  patch(['serviceorders', serviceOrderId, 'register-execution-post-work'], { key }, payload)

export const scheduleExecution = key => (serviceOrderId, payload) =>
  post(['serviceorders', serviceOrderId, 'appointments'], { key }, payload)

export const sendExecutionProofsAttachments = key => (serviceOrderId, payload) =>
  post(
    ['serviceorders', serviceOrderId, 'send-execution-proofs'],
    { key, transformOnlyResponse: true, formData: true },
    createFormDataDocuments(payload)
  )

export const deleteExecutionProofsAttachments = key => serviceOrderId =>
  del(['serviceorders', serviceOrderId, 'delete-execution-proofs'], { key })

export const sendInvoice = key => (serviceOrderId, payload) =>
  post(
    ['serviceorders', serviceOrderId, 'send-invoice'],
    { key, transformOnlyResponse: true, formData: true },
    payload
  )

export const deleteInvoice = key => serviceOrderId =>
  del(['serviceorders', serviceOrderId, 'delete-invoice'], { key })

export const duplicateServiceOrder = key => serviceOrderId =>
  patch(['serviceordersboard', serviceOrderId, 'duplicate'], { key })

export const verifyNewBudget = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'verify-new-budget'], { key })

export const createReasonsToReprove = key => (serviceOrderId, payload) =>
  post(
    ['serviceorders', serviceOrderId, 'reprove-finish-service'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const getServiceorderStatus = (key, serviceOrderId) =>
  get(['serviceorders', serviceOrderId, 'get-serviceorder-status'], { key })
export const getPaymentAnticipationInfo = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'payment-anticipation-info'], { key })

export const getPaymentAnticipations = key => params =>
  get(['serviceorders', 'payment-anticipations'], {
    key,
    transformPayload: true,
    params: renderQueryParams(params),
  })

export const createPaymentAnticipation = key => payload =>
  post(['payment_anticipation'], { key, transformPayload: true }, payload)

export const reactivateServiceOrder = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'reactivate-service-order'], { key })

export const getServiceOrderTradesman = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId], { key })

export const getReasonsToRefuseMetabaseSuggestion = key =>
  get(['companyboard', 'get-reasons'], { key })

export const getStepStatusFilter = key => params => get(['filters-step-so'], { key, params })

export const getAllStepStatusFilterOptions = key => get(['options-filter-so'], { key })

export const getStepStatusOptions = key => params =>
  get(['options-step-so/detail-cache'], { key, params })

export const getButtonsAction = key => params => get(['buttons-action-so'], { key, params })

export const getGenericParameters = key => params => get(['generic-parameters'], { key, params })

export const getGenericParametersAgencies = key => params =>
  get(['generic-parameters/agencies'], { key, params })

export const getGenericParametersNewForm = key => params =>
  get(['generic-parameters/new-form'], { key, params })

export const getRequesterGenericParameters = key => params =>
  get(['serviceorders-requester', 'generic-parameters'], { key, params })

export const completeCanceledServiceOrder = key => serviceOrderId =>
  patch(['serviceorders', serviceOrderId, 'complete-canceled-service-order'], { key })

export const getReactivateButton = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'get-reactivate-button'], { key })

export const refuseServiceOrder = key => (serviceOrderId, payload) =>
  post(['serviceorders', serviceOrderId, 'refuse'], { key, transformPayload: true }, payload)

export const reproveserviceOrder = key => serviceOrderId =>
  post(['serviceorders', serviceOrderId, 'reprove-service-order'], { key })

export const updatePaymentAnticipations = key => payload =>
  patch(['payment_anticipation', 'old-update-payment-anticipations'], { key }, payload)

export const postSendRatingLink = key => serviceOrderId =>
  post(['serviceorders-requester', serviceOrderId, 'send-rating-link'], {
    key,
    transformPayload: true,
  })

export const postSendRating = key => (serviceOrderId, payload) =>
  post(
    ['serviceorders-requester', serviceOrderId, 'status-rating'],
    {
      key,
    },
    payload
  )

export const cancelExecutionSchedule = key => serviceOrderId =>
  post(['serviceorders', serviceOrderId, 'cancel-execution-schedule'], {
    key,
  })

export const cancelExecution = key => (serviceOrderId, payload) =>
  post(['serviceorders', serviceOrderId, 'cancel-execution'], { key }, payload)

export const updateServiceOrderBusinessFront = key => (serviceOrderId, payload) =>
  patch(['serviceorders', serviceOrderId, 'update-serviceorder-business-front'], { key }, payload)

export const getOptionsPaymentResponsible = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'get-options-payment-responsible'], {
    key,
    transformPayload: true,
  })

export const getCurrentTradesmanSchedule = key => serviceOrderId =>
  get(['serviceorders', serviceOrderId, 'get-current-tradesman-schedule'], {
    key,
    transformPayload: true,
  })

export const verifyWhatsappUpdate = key => serviceOrderId =>
  post(['serviceorders', serviceOrderId, 'verify-whatsapp-update'], {
    key,
    transformPayload: true,
  })

export const updateReferaService = key => (serviceOrderId, payload) =>
  patch(
    ['serviceorders', serviceOrderId, 'update-refera-service'],
    { key, transformPayload: true },
    payload
  )
