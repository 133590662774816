export const filterArticles = (item, user) => {
  const isRightType = item.type === 'suggested_article'

  const isRightUser =
    (isRightType &&
      item?.roles?.some(role => role === user?.getRoles || user?.getRoles?.includes(role))) ||
    (isRightType && item?.roles.includes(user?.getRoles))

  return isRightType && isRightUser
}

export const filterVideos = (item, user) => {
  const isRightType = item.type === 'video'

  const isRightUser =
    (isRightType &&
      item?.roles?.some(role => role === user?.getRoles || user?.getRoles?.includes(role))) ||
    (isRightType && item?.roles.includes(user?.getRoles))

  return isRightType && isRightUser
}

export const filterFaqs = (item, user) => {
  const isRightType = item.type === 'frequently_asked_questions'

  const isRightUser =
    (isRightType &&
      item?.roles?.some(role => role === user?.getRoles || user?.getRoles?.includes(role))) ||
    (isRightType && item?.roles.includes(user?.getRoles))

  return isRightType && isRightUser
}
