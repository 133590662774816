import * as yup from 'yup'
import { EMAIL_REGEX } from '_/utils/constants'

const CPF_REGEX = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/

export const createAndEditReferaUsersFormSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório').max(255, 'Máximo de 255 caracteres'),
  cpf: yup.string().required('Campo obrigatório').matches(CPF_REGEX, 'CPF inválido'),
  email: yup.string().matches(EMAIL_REGEX, 'E-mail inválido').required('Campo obrigatório'),
  digisacUserId: yup.string().nullable(),
})

export function applyCPFMask(input) {
  const pattern = /(\d{3})(\d{3})(\d{3})(\d{2})/
  return input.replace(pattern, '$1.$2.$3-$4')
}
