import React from 'react'
import PropTypes from 'prop-types'
import { Radio, RadioGroup, Typography } from '@refera/ui-web'
import { FormControl } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import useStyles from './styles'

const CustomRadio = ({ label, name, onChange, options, ...props }) => {
  const styles = useStyles()
  const { control } = useFormContext()

  return (
    <FormControl>
      <Typography component="label" id={`radio-group-label-${name}`} className={styles.label}>
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        render={({ onChange: controllerOnChange, ...params }) => (
          <RadioGroup
            {...params}
            {...props}
            aria-labelledby={`radio-group-label-${name}`}
            className={styles.radioGroup}
            onChange={(event, value) => {
              if (onChange) {
                onChange(event, value, name)
                return
              }

              controllerOnChange(event, value)
            }}
          >
            {options.map(({ label: labelOption, value }) => (
              <Radio key={value} label={labelOption} value={value} />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

CustomRadio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

CustomRadio.defaultProps = {
  onChange: undefined,
}

export default CustomRadio
