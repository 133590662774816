import React, { useCallback, useEffect, useReducer, useState, useMemo } from 'react'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button as ReferaButton, Alert } from '@refera/ui-web'
import {
  OutlinedInput,
  FormControlLabel,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  Input,
} from '@material-ui/core'

import { navigate, useParams } from '@reach/router'

import { createBudgetTemplate, updateBudgetTemplate } from '_/modules/budgetTemplate/actions'
import { getBudgetTemplateByIdSelector } from '_/modules/budgetTemplate/selector'

import { reducer, INITIAL_STATE, ACTIONS } from '../reducer'
import { userSelector } from '_modules/authentication/selectors'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'
import BudgetPaymentDetails from '_/components/budget/budget-payment-details'
import { formatInputNumber } from '_utils/helpers'
import HeaderTitle from '_components/header-title'
import { useDialog } from '_/hooks/use-dialog'

import { ROUTES } from '_utils/constants'
import { useToast } from '_/hooks/use-toast'
import useStyles from './styles'

const TradesmanTemplateForm = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const methods = useForm()
  const { control, errors, handleSubmit, setValue, watch } = methods
  const { templateId } = useParams()

  const { showToast } = useToast()

  const [localState, localDispatch] = useReducer(reducer, INITIAL_STATE)
  const user = useSelector(userSelector)
  const budgetTemplate = useSelector(getBudgetTemplateByIdSelector(templateId))
  const [budgetItems, setBudgetItems] = useState()
  const { showDialog, closeDialog } = useDialog()

  useEffect(() => {
    if (budgetTemplate) {
      setValue('name', budgetTemplate?.name || '')
      setValue('diagnosis', budgetTemplate?.diagnosis || '')
      setValue('proposedSolution', budgetTemplate?.proposedSolution || '')
      setValue('warrantyMonths', budgetTemplate?.warrantyMonths || '')
      setValue('acceptsWarranty', String(!!budgetTemplate?.warrantyMonths))
      setValue('budgetTemplateServices', budgetTemplate?.budgetTemplateServices || [])
      setBudgetItems(budgetTemplate?.budgetTemplateServices)
    }
  }, [budgetTemplate, setValue])

  const renderErrorMessage = useMemo(
    () => fieldName => {
      if (errors?.[fieldName]) {
        return (
          <Typography role="alert" className={styles.errorMessage}>
            {errors[fieldName]?.message}
          </Typography>
        )
      }
      return null
    },
    [errors]
  )

  const handleNumberInputChange = useCallback(event => {
    const { name } = event.target
    setValue(name, formatInputNumber(event, 0, 12))
  }, [])

  const minValue = value => {
    if (value === 'Nenhum') {
      return true
    }

    return Number(value) > 1 || "Valor deve ser maior que '1'"
  }
  const handleBackNavigation = useCallback(() => {
    navigate(ROUTES.BUDGET_TEMPLATE)
  }, [])

  const onSuccessSubmit = useCallback(() => {
    localDispatch({ type: ACTIONS.RESET_STATE })
    showToast({ type: 'success' })
    handleBackNavigation()
  }, [])

  const handleErrorOnSubmit = useCallback(err => {
    let message = ''
    if (err?.message) {
      message = err?.message
    } else {
      message = 'Não foi possível executar esta ação, tente novamente.'
    }
    showToast({ type: 'error', message })
  }, [])

  const onSubmit = useCallback(
    async formData => {
      if (!formData.budgetItems) {
        showDialog({
          type: 'warning',
          subject: 'Por favor, preencha os campos obrigatórios antes de continuar.',
          labelApprove: 'Ok',
          onApprove: closeDialog,
        })
        return
      }
      const payload = {
        ...formData,
      }
      if (budgetTemplate) {
        await dispatch(updateBudgetTemplate(budgetTemplate?.id, payload))
          .then(onSuccessSubmit)
          .catch(err => handleErrorOnSubmit(err))
      } else {
        await dispatch(createBudgetTemplate(payload))
          .then(onSuccessSubmit)
          .catch(err => handleErrorOnSubmit(err))
      }
    },
    [user, localState, budgetTemplate]
  )

  return (
    <main className={styles.container}>
      <FormProvider {...methods}>
        <HeaderTitle title="Modelo de orçamento" backButtonAction={handleBackNavigation}>
          <Grid className={styles.headerButtons}>
            <ReferaButton
              className={styles.button}
              onClick={handleSubmit(onSubmit)}
              form="templateForm"
            >
              Salvar
            </ReferaButton>
            <ReferaButton variant="secondary" color="red" onClick={handleBackNavigation}>
              Cancelar
            </ReferaButton>
          </Grid>
        </HeaderTitle>
        <form className={styles.form}>
          <Grid className={styles.textContainer}>
            <Alert
              severity="info"
              title='Você também pode incluir modelos abrindo orçamentos já enviados e clicando em "Salvar como modelo".'
            />
            <Typography className={styles.textTitle}>
              Nome do modelo
              <span className={styles.required}>*</span>
            </Typography>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              rules={{ required: 'Campo obrigatório' }}
              as={
                <OutlinedInput
                  multiline
                  className={styles.textInput}
                  placeholder="Escreva aqui o nome do modelo"
                  inputProps={{
                    maxLength: 30,
                  }}
                  maxRows={6}
                  minRows={1}
                />
              }
            />
            {renderErrorMessage('name')}
            <ConfidentialDataWarning style={{ marginTop: 10 }} />
          </Grid>
          <Grid className={styles.textContainer}>
            <Typography className={styles.textTitle}>
              Diagnóstico do problema
              <span className={styles.required}>*</span>
            </Typography>
            <Controller
              control={control}
              name="diagnosis"
              defaultValue=""
              rules={{ required: 'Campo obrigatório' }}
              as={
                <OutlinedInput
                  multiline
                  className={styles.textInput}
                  placeholder="Escreva aqui uma mensagem para o prestador."
                  maxRows={6}
                  minRows={1}
                />
              }
            />
            {renderErrorMessage('diagnosis')}
            <ConfidentialDataWarning style={{ marginTop: 10 }} />
          </Grid>
          <Grid className={styles.textContainer}>
            <Typography className={styles.textTitle}>
              Solução para o problema
              <span className={styles.required}>*</span>
            </Typography>
            <Controller
              control={control}
              name="proposedSolution"
              defaultValue=""
              rules={{ required: 'Campo obrigatório' }}
              as={
                <OutlinedInput
                  multiline
                  placeholder="Descreva aqui a solução para o problema."
                  className={styles.textInput}
                  maxRows={6}
                  minRows={1}
                />
              }
            />
            {renderErrorMessage('proposedSolution')}
            <ConfidentialDataWarning style={{ marginTop: 10 }} />
          </Grid>
          <BudgetPaymentDetails
            isEditing
            budgetTemplateItems={budgetItems}
            importType="importFromBudgetTemplate"
          />
          <Grid className={styles.warrantyGroup}>
            <Grid item xs={4} className={styles.radioInput}>
              <Typography className={styles.textTitle}>Tem garantia?</Typography>
              <Controller
                control={control}
                name="acceptsWarranty"
                defaultValue="true"
                as={
                  <RadioGroup classes={{ root: styles.radioGroupStyles }} row>
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Não"
                      classes={{ root: styles.labelInputRadioButton }}
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Sim"
                      classes={{ root: styles.labelInputRadioButton }}
                    />
                  </RadioGroup>
                }
              />
            </Grid>
            <Grid item xs={4} className={styles.selectInput}>
              <Typography className={styles.textTitle}>Garantia (meses)</Typography>
              {watch('acceptsWarranty') === 'true' || budgetTemplate?.warrantyMonths ? (
                <Controller
                  name="warrantyMonths"
                  defaultValue=""
                  rules={{ validate: minValue }}
                  control={control}
                  render={({ value, name }) => (
                    <Input
                      className={styles.warrantyMonths}
                      name={name}
                      value={value}
                      fullWidth
                      required={watch('acceptsWarranty') === 'true'}
                      onChange={handleNumberInputChange}
                    />
                  )}
                />
              ) : (
                <Typography variant="h5" className={styles.numberInput}>
                  Nenhum
                </Typography>
              )}
              {renderErrorMessage('warrantyMonths')}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </main>
  )
}

export default TradesmanTemplateForm
