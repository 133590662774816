import React, { useCallback, useContext } from 'react'
import { Button } from '@refera/ui-web'
import { useFormContext } from 'react-hook-form'

import { BudgetContext } from '_utils/budget'

const CancelEditBudgetButton = () => {
  const { isEditing, toggleIsEditing } = useContext(BudgetContext)

  const {
    formState: { defaultValues },
    reset,
  } = useFormContext()

  const handleCancel = useCallback(() => {
    reset(defaultValues)
    toggleIsEditing()
  }, [toggleIsEditing])

  if (!isEditing) {
    return null
  }

  return (
    <Button onClick={handleCancel} color="red" variant="secondary">
      Cancelar
    </Button>
  )
}

export default CancelEditBudgetButton
