import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  box: {
    border: '1px solid #E3E3E3',
    boxSizing: 'borderBox',
    borderRadius: spacing(8),
    position: 'relative',
    maxWidth: '45vw',
    minWidth: '400px',
  },
  boxTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 16px 0px 16px',
  },
  boxTitleContainer: {
    display: 'flex',
    gap: '8px',
  },
  selectWidgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '6px 22px 6px 16px',
    minWidth: '528px',
    width: 'auto',
    height: '280px',
  },
  menuContainer: {
    padding: '8px',
    border: '1px solid #E3E3E3',
    boxShadow: `${spacing(0, 2, 4)} rgba(0, 0, 0, 0.25)`,
    borderRadius: '8px',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'absolute',
    backgroundColor: '#FFF',
    zIndex: 3,

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  menuOptions: {
    cursor: 'pointer',
    padding: '8px 0 8px 12px',
    color: '#757575',

    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
  selectOption: {
    color: '#CCC',
  },
  division: {
    display: 'flex',
    border: '1px solid #E3E3E3',
    margin: '0 22px 0 16px',
  },
  emptyWidget: {
    display: 'flex',
    height: '280px',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '45vw',
    minWidth: '400px',
  },
  emptyWidgetIcon: {
    fontSize: '260px',
  },
  iconButton: {
    height: spacing(16),
    width: spacing(16),
  },
  selectWidget: {
    minWidth: spacing(150),
    marginBottom: spacing(15),
  },
  title: {
    marginBottom: 10,
  },
  selectButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  expandIcon: {
    fill: '#696969',
    height: '24px',
    width: '24px',
  },
  loadingContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
}))
