import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { navigate, useLocation } from '@reach/router'
import { Grid, LinearProgress, Typography } from '@material-ui/core'
import { Button, Loader, Toast, Alert, Dialog } from '@refera/ui-web'
import { Cookies } from 'react-cookie'

import Svg, { ICON } from '_components/svg'
import { InfoCircle as InfoCircleIcon } from '@refera/ui-icons'
import { COOKIES_OPTIONS, ROUTES } from '_/utils/constants'
import { HEADER_TAGS } from '_/utils/header-tags'

import useStyles from './styles'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '_/modules/authentication/selectors'
import {
  getCompany,
  getContractSigners,
  postContractSigners,
  postGenerateContract,
  updateCompany,
} from '_/modules/company/actions'
import { getUser, setAuthToken } from '_/modules/authentication/actions'
import {
  NUMBER_OF_STEPS,
  STEPS,
  STEP_LENGTH,
  formatAttachmentsPayload,
  formatFormDataPayload,
} from './constants'
import { companySelector } from '_/modules/company/selector'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { GenerateContractResponsibleDataPage } from './pages/Step1'
import { GenerateContractCompanyDocumentsPage } from './pages/Step2'
import { GenerateContractResultPage } from './pages/Step4'
import { GenerateContractCompanyDataPage } from './pages/Step3'
import { formatFormData, validationCNPJ, validationCPF } from '_/utils/helpers'

const cookies = new Cookies()
const defaultDialog = {
  isOpen: false,
  subject: '',
  description: '',
  iconType: 'info',
  labelApprove: 'Ok, entendi',
  onApprove: () => {},
}

const REQUIRED_FIELD_MESSAGE = 'Esse campo é obrigatório'
const strMaxLenMessage = size => `Esse campo aceita no máximo ${size || 255} caracteres`

const responsiblesSchema = yup.object().shape({
  responsibles: yup.array().of(
    yup.object().shape({
      fullName: yup.string().required(REQUIRED_FIELD_MESSAGE).max(255, strMaxLenMessage()),
      email: yup
        .string()
        .email('Insira um email válido.')
        .required(REQUIRED_FIELD_MESSAGE)
        .max(255, strMaxLenMessage()),
      cpf: yup
        .string()
        .required(REQUIRED_FIELD_MESSAGE)
        .test('validateCpf', 'Insira um CPF válido.', value => validationCPF(value)),
      cargo: yup.string().required(REQUIRED_FIELD_MESSAGE).max(255, strMaxLenMessage()),
      documentFile: yup.mixed().required(REQUIRED_FIELD_MESSAGE),
    })
  ),
})

const attachmentsSchema = yup.object().shape({
  companyAttachments: yup.array().of(yup.mixed().required(REQUIRED_FIELD_MESSAGE)),
})

const companySchema = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD_MESSAGE).max(255, strMaxLenMessage()),
  address: yup.string().required(REQUIRED_FIELD_MESSAGE).max(80, strMaxLenMessage(80)),
  cep: yup.string().required(REQUIRED_FIELD_MESSAGE).length(9, 'Insira um CEP válido.'),
  city: yup.string().required(REQUIRED_FIELD_MESSAGE).max(80, strMaxLenMessage(80)),
  cnpj: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test('validateCNPJ', 'Insira um CNPJ válido.', value => validationCNPJ(value)),
  state: yup.string().required(REQUIRED_FIELD_MESSAGE).max(80, strMaxLenMessage(80)),
  neighborhood: yup.string().required(REQUIRED_FIELD_MESSAGE).max(80, strMaxLenMessage(80)),
  streetNumber: yup.string().required(REQUIRED_FIELD_MESSAGE).max(80, strMaxLenMessage(80)),
})

export function GenerateContract() {
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(true)
  const [pageSetupCompleted, setPageSetupCompleted] = useState(false)
  const [postContractProps, setPostContractProps] = useState({})
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' })
  const [dialogInfo, setDialogInfo] = useState(defaultDialog)

  const styles = useStyles()
  const searchParams = new URLSearchParams(useLocation().search)
  const user = useSelector(userSelector)
  const company = useSelector(companySelector)
  const dispatch = useDispatch()

  const { pathname } = useLocation()

  const isCondolivreContract = useMemo(
    () => pathname.includes(ROUTES.CONDOLIVRE_CONTRACT),
    [pathname]
  )

  const selectSchema = () => {
    switch (currentStep) {
      case 0:
        return responsiblesSchema
      case 1:
        return attachmentsSchema
      case 2:
        return companySchema
      default:
        return {}
    }
  }

  const methods = useForm({
    mode: 'all',
    shouldUnregister: true,
    resolver: yupResolver(selectSchema()),
  })

  const currentStepLength = useMemo(() => {
    return (currentStep + 1) * STEP_LENGTH
  }, [currentStep, STEP_LENGTH])

  const [componentProps, setComponentProps] = useState([])
  const { handleSubmit } = methods

  const stepOnSubmitFunctions = {
    0: onSubmitStep1,
    1: onSubmitStep2,
    2: onSubmitStep3,
  }

  const removeCookies = ev => {
    ev.preventDefault()
    searchParams.has('mobileToken') &&
      cookies.remove(COOKIES_OPTIONS.authTokenName, {
        path: COOKIES_OPTIONS.path,
      })
  }

  async function onSubmitStep1(data) {
    const { responsibles } = data
    const formattedPayload = formatFormDataPayload(responsibles)

    await dispatch(postContractSigners(formattedPayload))
      .then(() => {
        setCurrentStep(1)
        setLoading(false)
      })
      .catch(err => {
        const field = Object.keys(err)[0]
        const message = field === 'email' ? 'E-mail já cadastrado' : 'Erro ao cadastrar responsável'

        setLoading(false)
        setToast({
          isOpen: true,
          message,
          type: 'error',
        })
      })
  }

  async function onSubmitStep2(data) {
    setLoading(false)

    if (!data?.companyAttachments?.length) {
      setToast({
        isOpen: true,
        message: 'É necessário inserir pelo menos um documento.',
        type: 'error',
      })
      return
    }
    setCurrentStep(2)
  }

  async function onSubmitStep3(data) {
    // eslint-disable-next-line no-unused-vars
    const { responsibles, companyAttachments, ...payload } = data
    const formattedAttachments = formatAttachmentsPayload(companyAttachments)

    const newPayload = { ...formattedAttachments, ...payload }

    if (isCondolivreContract) newPayload.condolivreContract = true

    let success = true

    if (Object.keys(data).length > 0) {
      await dispatch(updateCompany(company.id, formatFormData(newPayload), true)).catch(err => {
        setLoading(false)
        setToast({
          isOpen: true,
          message: 'Erro ao atualizar dados da empresa',
          type: 'error',
        })
        success = false
        return err
      })
    }

    if (!success) return

    if (isCondolivreContract) {
      setLoading(false)
      setCurrentStep(prevState => prevState + 1)
      setPostContractProps({
        returnToMobile: searchParams.has('mobileToken'),
        result: 'successCondolivre',
      })
      return
    }

    await dispatch(postGenerateContract())
      .then(() => {
        setLoading(false)
        setCurrentStep(prevState => prevState + 1)
        setPostContractProps({
          returnToMobile: searchParams.has('mobileToken'),
          result: 'success',
        })
      })
      .catch(() => {
        setLoading(false)
        setCurrentStep(prevState => prevState + 1)
        setPostContractProps({
          returnToMobile: searchParams.has('mobileToken'),
          result: 'error',
        })
      })
  }

  const onSubmit = useCallback(
    async data => {
      setLoading(true)

      try {
        await stepOnSubmitFunctions[currentStep](data)
      } catch (error) {
        console.error(error)
        setToast({
          isOpen: true,
          message: 'Erro ao salvar dados. Por favor, tente novamente mais tarde.',
          type: 'error',
        })
        setLoading(false)
      }
    },
    [dispatch, currentStep, stepOnSubmitFunctions]
  )

  const handleCloseToast = () => {
    setToast(prevState => ({ ...prevState, isOpen: false }))
  }

  async function checkIfComingFromMobile() {
    // force browse unauthenticated user to login
    if (!searchParams.has('mobileToken')) {
      navigate(ROUTES.LOGIN, { replace: true })
    }

    const token = searchParams.get('mobileToken')

    cookies.set(COOKIES_OPTIONS.authTokenName, token, {
      path: COOKIES_OPTIONS.path,
    })

    dispatch(setAuthToken(token))
  }

  // Check if user is not a tradesman. If so, redirect to home
  async function checkUserRole() {
    // Not logged in
    if (!user.getRoles)
      await dispatch(getUser()).then(data => {
        if (data.getRoles !== 'tradesman') {
          navigate('/', { replace: true })
        }
      })
    else if (user.getRoles !== 'tradesman') {
      navigate('/', { replace: true })
    }
  }

  const redirect = () => {
    if (!searchParams.has('mobileToken')) {
      navigate('/')
    }
    window.location.href = 'referaproapp://home'
  }

  const handleBackButton = () => {
    if (currentStep === 0) {
      redirect()
    } else {
      setCurrentStep(prev => prev - 1)
    }
  }

  const renderNextButtonText = useMemo(() => {
    if (isCondolivreContract && currentStep === 2) return 'Enviar'
    return currentStep < NUMBER_OF_STEPS - 1 ? STEPS[currentStep].buttonLabels.next : ''
  }, [isCondolivreContract, currentStep, STEPS, NUMBER_OF_STEPS])

  function checkContractStatus(_company) {
    if (isCondolivreContract) return

    switch (_company.contractStatus) {
      case 'signed':
        setDialogInfo({
          isOpen: true,
          iconType: 'info',
          labelApprove: 'Ok',
          subject: 'Contrato já assinado',
          description:
            'O seu contrato já está assinado e você pode vê-lo no cadastro de sua empresa na web. Operação cancelada.',
          onApprove: redirect,
        })
        break
      case 'in_signature':
        setDialogInfo({
          isOpen: true,
          iconType: 'info',
          labelApprove: 'Ok',
          subject: 'Contrato já em assinatura',
          description:
            'O seu contrato já está no estágio de assinatura. Caso queira gerar um novo contrato, faça o login na plataforma da Refera. Operação cancelada.',
          onApprove: redirect,
        })
        break
      case 'cancelled':
        setToast({
          isOpen: true,
          message:
            'O contrato gerado anteriormente foi cancelado por algum assinante. Outro contrato deve ser gerado.',
          type: 'info',
        })
        break
      default:
    }
  }

  async function getAndSetContractSigners() {
    setLoading(true)
    await dispatch(getContractSigners())
      .then(data => {
        setComponentProps(data.results.length > 0 ? data.results : [])
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  async function initPage() {
    try {
      // Check if authToken exists
      if (!user.authToken) await checkIfComingFromMobile()
      // User is logged in but got here by typing the url
      else !user?.id && !isCondolivreContract && navigate('/', { replace: true })

      await checkUserRole()

      // Coming from navigation
      if (Object.hasOwn(company, 'contractStatus')) {
        checkContractStatus(company)
      } else {
        // Coming from mobile
        await dispatch(getCompany()).then(({ results }) => {
          checkContractStatus(results[0])
        })
      }
    } catch (error) {
      console.error(error)
      setToast({
        isOpen: true,
        message:
          'Erro no processo de inicialização do formulário. Por favor recarregue a página ou tente mais tarde.',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    initPage()
      .then(() => {
        setPageSetupCompleted(true)
        setLoading(false)
        // remove cookies when the user leaves the application by closing the tab or browser
        window.addEventListener('beforeunload', removeCookies)
      })
      .catch(() => setLoading(false))

    return () => {
      try {
        window.removeEventListener('beforeunload', removeCookies)
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  useEffect(() => {
    if (pageSetupCompleted) getAndSetContractSigners()
  }, [pageSetupCompleted])

  return (
    <>
      {HEADER_TAGS[ROUTES.GENERATE_CONTRACT].header}
      <Grid className={styles.container}>
        <Grid className={styles.header} component="header">
          <LinearProgress
            className={styles.linearProgress}
            variant="determinate"
            value={currentStepLength}
          />
          {currentStep < NUMBER_OF_STEPS - 1 && (
            <Grid className={styles.headerContent}>
              <Typography
                align="center"
                variant="h4"
                component="h4"
                gutterBottom
                className={styles.pageTitle}
              >
                {`Contrato com a Refera - Etapa ${currentStep + 1} de ${NUMBER_OF_STEPS - 1}`}
              </Typography>
              <Grid className={styles.titleGrid}>
                <Typography
                  align="center"
                  variant="h1"
                  component="h1"
                  gutterBottom
                  className={styles.stepTitle}
                >
                  {STEPS[currentStep].title}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>

        <FormProvider {...methods}>
          <form id="generateContractForm" className={styles.form} onSubmit={() => {}}>
            <div style={{ display: currentStep !== 0 && 'none' }}>
              <GenerateContractResponsibleDataPage props={componentProps} />
            </div>
            <div style={{ display: currentStep !== 1 && 'none' }}>
              <GenerateContractCompanyDocumentsPage props={componentProps} />
            </div>
            <div style={{ display: currentStep !== 2 && 'none' }}>
              <GenerateContractCompanyDataPage props={componentProps} />
            </div>
          </form>
        </FormProvider>
        <div
          style={{ display: currentStep !== 3 ? 'none' : 'flex' }}
          className={styles.resultContainer}
        >
          <GenerateContractResultPage {...postContractProps} />
        </div>
        <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {currentStep < NUMBER_OF_STEPS - 1 && (
            <Grid className={[styles.buttonArea, styles.buttonAreaColumn]}>
              <Button
                className={[styles.button, styles.buttonFillWidth]}
                variant="secondary"
                color={currentStep === 0 && 'red'}
                onClick={handleBackButton}
                disabled={loading}
              >
                {STEPS[currentStep].buttonLabels.back}
              </Button>

              <Button
                onClick={handleSubmit(onSubmit)}
                className={[styles.button, styles.buttonFillWidth]}
                disabled={loading}
              >
                {renderNextButtonText}
                {STEPS[currentStep].svgInNextButton && (
                  <Svg type={ICON.ARROW_RIGHT} className={styles.icon} />
                )}
              </Button>
            </Grid>
          )}
          <Grid component="footer" className={styles.footer}>
            <Typography align="center" component="h5" variant="h5" className={styles.footerText}>
              © 2021 Refera Tecnologia - Desenvolvido por Refera
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert severity={toast.type} title={toast.message} onClose={handleCloseToast} />
      </Toast>
      {dialogInfo.isOpen && (
        <Dialog
          open={dialogInfo.isOpen}
          type={dialogInfo.iconType}
          icon={InfoCircleIcon}
          subject={dialogInfo.subject}
          description={dialogInfo.description}
          labelApprove={dialogInfo.labelApprove}
          onApprove={dialogInfo.onApprove}
        />
      )}
      <Loader hasBackdrop open={loading} label="Aguarde..." />
    </>
  )
}
