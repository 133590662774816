import PaintRollerIcon from '_assets/images/landing-page-rs-b2c/paint-roller.webp'
import TruckIcon from '_assets/images/landing-page-rs-b2c/truck.webp'
import StarsIcon from '_assets/images/landing-page-rs-b2c/stars.webp'
import BlocksIcon from '_assets/images/landing-page-rs-b2c/blocks.webp'
import CleanIcon from '_assets/images/landing-page-rs-b2c/clean.webp'
import EnergyIcon from '_assets/images/landing-page-rs-b2c/energy.webp'

export const BENEFITS_LIST = [
  {
    text: 'Orçamento completo com material e mão de obra ',
    description:
      'Nossos orçamentos são completos e incluem todos os custos do serviço, incluindo material e mão de obra. Isso garante que você saiba exatamente quanto vai pagar antes de contratar o serviço.',
  },
  {
    text: 'Garantia total do serviço ',
    description:
      'Oferecemos garantia para diversos serviços prestados no Rio Grande do Sul. Consulte a disponibilidade de garantia com um de nossos consultores quando receber o orçamento.',
  },
  {
    text: 'Parcelamento do serviço em até 10x vezes ',
    description:
      'Oferecemos parcelamento do serviço em até 10x vezes sem juros, para facilitar o pagamento.',
  },
  {
    text: 'Prestadores de elite selecionados pela Refera ',
    description:
      'Trabalhamos com uma rede de prestadores de elite selecionados pela Refera. Esses prestadores são qualificados e experientes, e estão comprometidos em prestar um serviço de excelência.',
  },
  {
    text: 'Time de suporte para te ajudar ',
    description:
      'Temos um time de suporte dedicado para te ajudar em todas as etapas do processo, desde a solicitação do orçamento até a execução do serviço.',
  },
]

export const SERVICES_LIST = [
  { icon: StarsIcon, text: 'Limpeza de imóvel' },
  { icon: CleanIcon, text: 'Remoção de lama' },
  { icon: TruckIcon, text: 'Remoção de entulho' },
  { icon: PaintRollerIcon, text: 'Pintura interna e externa' },
  { icon: BlocksIcon, text: 'Troca de rodapé' },
  { icon: EnergyIcon, text: 'Revisão elétrica' },
]

export const STEPS_LIST = [
  { title: 'Solicite um orçamento', description: '', extraInfo: '' },
  {
    title: 'Envie fotos e vídeos',
    description: 'explicando sua necessidade de manutenção do seu imóvel.',
    extraInfo: 'Quanto mais informações você fornecer, mais rápido será orçado.',
  },
  {
    title: 'Receba o orçamento',
    description: 'completo, com materiais e mão de obra já inclusos.',
    extraInfo: 'Nada de surpresas e despesas extras.',
  },
  {
    title: 'Contrate o serviço',
    description: 'Sem burocracia e com parcelamento em até 10x sem juros.',
    extraInfo: '',
  },
  {
    title: 'Receba um dos profissionais de elite',
    description: 'parceiros da Refera altamente experientes e qualificados.',
    extraInfo: 'Maior segurança para você e sua família.',
  },
  {
    title: 'Avalie sua experiência',
    description: 'com a prestação de serviço.',
    extraInfo: '',
  },
  {
    title: 'Receba a garantia',
    description: 'de 90 dias sobre o serviço prestado.',
    extraInfo: 'Caso tenha algum problema, nós fazemos a correção gratuitamente.',
  },
]
