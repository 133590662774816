import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  dialog: {
    position: 'absolute',
    width: '504px',
    height: '294px',
  },
  button: {
    width: '100px',
    borderRadius: '24px',
    height: '40px',
    textTransform: 'none',
    margin: '15px',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    overflow: 'hidden',
    marginTop: '10px',
  },
  dialogAction: {
    justifyContent: 'center',
    marginTop: '-20px',
  },
  titleIconTrash: {
    marginBottom: '-16px',
    backgroundColor: '#EBF6EB',
    color: '#C90000',
    fontSize: '6rem',
    '& .MuiSvgIcon-root': {
      fontSize: '6rem',
    },
  },
  iconeSVG: {
    width: '50px',
    height: '50px',
  },
}))
