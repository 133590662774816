import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Datagrid } from '@refera/ui-web'
import PropTypes from 'prop-types'

import {
  serviceOrdersSelector,
  serviceOrdersCountSelector,
  getServiceOrderLoadingSelector,
} from '_modules/service-orders/selectors'
import { ROUTES } from '_utils/constants'

import { COLUMNS } from './constants'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const ServiceOrderMainTable = ({
  onPageChange,
  onPageSizeChange,
  onSortModelChange,
  page,
  pageSize,
  isTradesman,
}) => {
  const isServiceOrdersLoading = useSelector(getServiceOrderLoadingSelector)
  const serviceOrders = useSelector(serviceOrdersSelector)
  const serviceOrdersCount = useSelector(serviceOrdersCountSelector)
  const { checkIfUserDoesNotPermission } = useRolePermission()

  const rows = useMemo(() => serviceOrders ?? [], [serviceOrders])

  const handleRowClick = useCallback(
    event => {
      if (
        checkIfUserDoesNotPermission(PERMISSIONS.SERVICE_ORDER, [
          PERMISSIONS_ACTIONS.ADD,
          PERMISSIONS_ACTIONS.EDIT,
          PERMISSIONS_ACTIONS.READ,
        ])
      ) {
        return
      }
      if (isTradesman) {
        window.open(
          `${ROUTES.DETAIL_SERVICE_ORDER}/${event.row.id}`,
          '_blank',
          'noopener,noreferrer'
        )
      } else {
        window.open(`${ROUTES.SERVICE_ORDER}/${event.row.id}`, '_blank', 'noopener,noreferrer')
      }
    },
    [isTradesman]
  )

  const dataGridStyle = {
    fontSize: '1.4rem',
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
  }

  return (
    <Datagrid
      loading={isServiceOrdersLoading}
      rows={rows}
      columns={isTradesman ? COLUMNS.TRADESMAN : COLUMNS.DEFAULT}
      paginationMode="server"
      rowCount={serviceOrdersCount}
      sx={dataGridStyle}
      onRowClick={handleRowClick}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortModelChange={onSortModelChange}
      page={page}
      pageSize={pageSize}
    />
  )
}

ServiceOrderMainTable.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onSortModelChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  isTradesman: PropTypes.bool,
}

ServiceOrderMainTable.defaultProps = {
  isTradesman: false,
}

export default React.memo(ServiceOrderMainTable)
