import moment from 'moment'

export const FORM_FIELD_NAME = {
  AGENCY: 'agency',
  COMPANY: 'company',
  ANTICIPATION: 'anticipation',
  TRANSFER_DATE_START: 'transferDateStart',
  TRANSFER_DATE_END: 'transferDateEnd',
  PAYMENT_DATE_START: 'paymentDateStart',
  PAYMENT_DATE_END: 'paymentDateEnd',
  SOLICITATION_DATE_START: 'solicitationDateStart',
  SOLICITATION_DATE_END: 'solicitationDateEnd',
  SERVICE_ORDER_END_DATE_START: 'serviceOrderEndDateStart',
  SERVICE_ORDER_END_DATE_END: 'serviceOrderEndDateEnd',
  SERVICE_ORDER_LAST_END_DATE_START: 'serviceOrderLastEndDateStart',
  SERVICE_ORDER_LAST_END_DATE_END: 'serviceOrderLastEndDateEnd',
  PROVIDER_VALUE_START: 'providerValueStart',
  PROVIDER_VALUE_END: 'providerValueEnd',
  RESPONSIBLE: 'responsible',
  SERVICE_ORDER: 'serviceOrder',
  STATUS: 'status',
  SUBJECT: 'subject',
  ABLE_TO_PAY: 'ableToPay',
  BATCH: 'batch',
  BATCH_ERROR: 'batchError',
  PAYMENT_METHOD: 'paymentMethod',
  BLOCKED: 'blocked',
}

export const INITIAL_STATE = {
  [FORM_FIELD_NAME.AGENCY]: [],
  [FORM_FIELD_NAME.COMPANY]: [],
  [FORM_FIELD_NAME.ANTICIPATION]: '',
  [FORM_FIELD_NAME.TRANSFER_DATE_END]: null,
  [FORM_FIELD_NAME.TRANSFER_DATE_START]: null,
  [FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_START]: null,
  [FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_END]: null,
  [FORM_FIELD_NAME.SERVICE_ORDER_LAST_END_DATE_START]: null,
  [FORM_FIELD_NAME.SERVICE_ORDER_LAST_END_DATE_END]: null,
  [FORM_FIELD_NAME.PROVIDER_VALUE_START]: null,
  [FORM_FIELD_NAME.PROVIDER_VALUE_END]: null,
  [FORM_FIELD_NAME.PAYMENT_DATE_START]: null,
  [FORM_FIELD_NAME.PAYMENT_DATE_END]: null,
  [FORM_FIELD_NAME.SOLICITATION_DATE_START]: null,
  [FORM_FIELD_NAME.SOLICITATION_DATE_END]: null,
  [FORM_FIELD_NAME.RESPONSIBLE]: '',
  [FORM_FIELD_NAME.SERVICE_ORDER]: '',
  [FORM_FIELD_NAME.STATUS]: '',
  [FORM_FIELD_NAME.SUBJECT]: '',
  [FORM_FIELD_NAME.ABLE_TO_PAY]: '',
  [FORM_FIELD_NAME.BATCH]: '',
  [FORM_FIELD_NAME.BATCH_ERROR]: '',
  [FORM_FIELD_NAME.PAYMENT_METHOD]: '',
  [FORM_FIELD_NAME.BLOCKED]: '',
  isValid: true,
  transferDate: '',
  paymentDate: '',
  solicitationDate: '',
  serviceOrderEndDate: '',
  serviceOrderLastEndDate: '',
  providerValue: '',
}

const TRANSFER_DATE_FIELDS = [
  FORM_FIELD_NAME.TRANSFER_DATE_END,
  FORM_FIELD_NAME.TRANSFER_DATE_START,
]
const SERVICE_ORDER_END_DATE_FIELDS = [
  FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_START,
  FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_END,
]
const SERVICE_ORDER_LAST_END_DATE_FIELDS = [
  FORM_FIELD_NAME.SERVICE_ORDER_LAST_END_DATE_START,
  FORM_FIELD_NAME.SERVICE_ORDER_LAST_END_DATE_END,
]
const PAYMENT_DATE_FIELDS = [FORM_FIELD_NAME.PAYMENT_DATE_START, FORM_FIELD_NAME.PAYMENT_DATE_END]
const SOLICITATION_DATE_FIELDS = [
  FORM_FIELD_NAME.SOLICITATION_DATE_START,
  FORM_FIELD_NAME.SOLICITATION_DATE_END,
]

export const UPDATE_STATE = 'updateState'
export const RESET_STATE = 'resetState'

const handleValidDates = (startDate, endDate) => {
  if (startDate || endDate) {
    return moment(startDate).isValid() || moment(endDate).isValid()
  }

  return false
}

export const reducer = (state, { type, payload }) => {
  const dateValidation = () => {
    const newState = {
      ...state,
      ...payload,
    }

    return Object.keys(payload).reduce((acc, payloadKey) => {
      if (!acc) {
        return acc
      }

      if (TRANSFER_DATE_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.TRANSFER_DATE_START],
          newState[FORM_FIELD_NAME.TRANSFER_DATE_END]
        )
      }

      if (SERVICE_ORDER_END_DATE_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_START],
          newState[FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_END]
        )
      }

      if (SERVICE_ORDER_LAST_END_DATE_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.SERVICE_ORDER_LAST_END_DATE_START],
          newState[FORM_FIELD_NAME.SERVICE_ORDER_LAST_END_DATE_END]
        )
      }

      if (PAYMENT_DATE_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.PAYMENT_DATE_START],
          newState[FORM_FIELD_NAME.PAYMENT_DATE_END]
        )
      }
      if (SOLICITATION_DATE_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.SOLICITATION_DATE_START],
          newState[FORM_FIELD_NAME.SOLICITATION_DATE_END]
        )
      }

      return acc
    }, true)
  }

  switch (type) {
    case UPDATE_STATE:
      return {
        ...state,
        ...payload,
        isValid: dateValidation(),
      }
    case RESET_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
