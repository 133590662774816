import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Button from '_components/button'
import useStyles from './styles'
import { TextArea } from '@refera/ui-web'
import { createBudgetTemplate } from '_modules/budget/actions'
import DialogModal, { WARNING_MODAL } from '_components/modal/modal-dialog'
import { useToast } from '_hooks/use-toast'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const BudgetTemplateForm = ({ closeModal, budget }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [modelName, setModelName] = useState('')
  const [openProposal, setOpenProposal] = useState(false)
  const { showToast } = useToast()
  const handleShowModal = useCallback(message => {
    setModalDialog({
      isOpen: true,
      subTitle: message,
      type: WARNING_MODAL,
    })
    setOpenProposal(true)
  }, [])
  const { handleSubmit, register } = useForm({ mode: 'all' })
  const handleSaveModel = async () => {
    dispatch(
      createBudgetTemplate(budget.id, {
        budget_template_name: modelName,
      })
    )
      .then(res => {
        handleShowModal(res?.message)
        showToast({
          type: 'success',
          message: res?.message,
        })
      })
      .catch(error => {
        handleShowModal(error?.message)
        showToast({
          type: 'error',
          message: error?.message,
        })
      })
    closeModal()
  }
  const handleModelName = e => {
    setModelName(e.target.value)
  }

  return (
    <>
      <Dialog
        open
        maxWidth="xl"
        disableEscapeKeyDown
        onClose={closeModal}
        PaperComponent={PaperComponent}
        TransitionComponent={Transition}
        className={styles.dialog}
      >
        <DialogTitle className={styles.titleBlue} id="draggable-dialog-title-t">
          Salvar Modelo
        </DialogTitle>
        <DialogContent className={styles.container}>
          <form
            id="saveModel"
            onSubmit={handleSubmit(handleSaveModel)}
            className={styles.subcontainer}
          >
            <Grid className={styles.subjectWrapper}>
              <Grid className={styles.inlineLabel}>
                <Typography className={styles.labels}>Nome do modelo</Typography>
                <Typography className={styles.required}>*</Typography>
              </Grid>

              <TextArea
                multiline
                required
                minRows={2}
                maxRows={2}
                name="modelName"
                onChange={handleModelName}
                defaultValue={modelName}
                placeholder="Escreva aqui o nome do modelo."
                inputProps={{
                  maxLength: 30,
                }}
                inputRef={register({ maxLength: 30 })}
                variant="outlined"
                InputProps={{ style: { fontSize: 14 } }}
              />
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={styles.dialogAction}>
          <Button className={styles.button} onClick={closeModal} color="red" variant="outlined">
            Cancelar
          </Button>
          <Button
            className={styles.button}
            form="saveModel"
            type="submit"
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {openProposal && <DialogModal modalDialog={modalDialog} setModalDialog={setModalDialog} />}
    </>
  )
}

BudgetTemplateForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default BudgetTemplateForm
