import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import useStyles from './styles'
import { Danger as DangerIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { LoadingBackdrop } from '_/components/LoadingBackdrop'

export function Root({ children, isLoading, ...rest }) {
  const styles = useStyles()

  return (
    <Dialog className={styles.root} aria-labelledby="customized-dialog-title" {...rest}>
      <LoadingBackdrop open={isLoading} />
      {children}
    </Dialog>
  )
}

export function TitleModal({ title, children, ...rest }) {
  const styles = useStyles()

  return (
    <DialogTitle className={styles.dialogTitle} {...rest}>
      {title && <Typography className={styles.title}>{title}</Typography>}
      {children}
    </DialogTitle>
  )
}

export function Content({ children, ...rest }) {
  const styles = useStyles()

  return (
    <DialogContent className={styles.dialogContent} {...rest}>
      {children}
    </DialogContent>
  )
}

export function Actions({ children, ...rest }) {
  const styles = useStyles()

  return (
    <DialogActions className={styles.dialogActions} {...rest}>
      {children}
    </DialogActions>
  )
}

export function ButtonBlue({ children, ...rest }) {
  const styles = useStyles()

  return (
    <Button className={[styles.buttonBlue, styles.button]} {...rest}>
      {children}
    </Button>
  )
}

export function ButtonRed({ children, ...rest }) {
  const styles = useStyles()

  return (
    <Button className={[styles.buttonRed, styles.button]} {...rest}>
      {children}
    </Button>
  )
}

export function ButtonFullBlue({ children, ...rest }) {
  const styles = useStyles()

  return (
    <Button className={[styles.buttonFullBlue, styles.button]} {...rest}>
      {children}
    </Button>
  )
}

export function ButtonFullRed({ children, ...rest }) {
  const styles = useStyles()

  return (
    <Button className={[styles.buttonFullRed, styles.button]} {...rest}>
      {children}
    </Button>
  )
}

export function Text({ children, ...rest }) {
  const styles = useStyles()
  return (
    <Typography className={styles.text} {...rest}>
      {children}
    </Typography>
  )
}

export function SubTitle({ children, ...rest }) {
  const styles = useStyles()
  return (
    <Typography className={styles.subTitle} {...rest}>
      {children}
    </Typography>
  )
}

export function Title({ text, ...rest }) {
  const styles = useStyles()
  return (
    <Typography className={styles.title} {...rest}>
      {text}
    </Typography>
  )
}

export function WarningIcon({ ...rest }) {
  const styles = useStyles()
  return (
    <Grid className={styles.iconDiv}>
      <DangerIcon color={Theme.Colors.Yellow.Base} fontSize={48} {...rest} />
    </Grid>
  )
}

export function InputDisabled({ text, label, ...rest }) {
  const styles = useStyles()
  return (
    <>
      <Typography className={styles.text}>
        <strong>{label}</strong>
      </Typography>
      <Grid className={styles.card}>
        <Typography className={styles.label} {...rest}>
          {text}
        </Typography>
      </Grid>
    </>
  )
}
