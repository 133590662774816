import * as tradesmanSequence from '_services/tradesman-sequence'
import { defineAction } from '_utils/redux'

export const CREATE_TRADESMAN_SEQUENCE = defineAction('CREATE_TRADESMAN_SEQUENCE')
export const GET_TRADESMAN_SEQUENCE = defineAction('GET_TRADESMAN_SEQUENCE')

export const createTradesmanSequence = (serviceOrderId, payload) => async (dispatch, getState) => {
  const data = await tradesmanSequence.createTradesmanSequence(
    getState().user.authToken,
    serviceOrderId,
    payload
  )
  dispatch({
    type: CREATE_TRADESMAN_SEQUENCE,
    payload: async () => data,
  })

  return data
}

export const getTradesmanSequence = serviceOrderId => (dispatch, getState) =>
  dispatch({
    type: GET_TRADESMAN_SEQUENCE,
    payload: tradesmanSequence.getTradesmanSequence(getState().user.authToken, serviceOrderId),
  })
