import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  mainTable: {
    marginTop: '16px',
    width: 'auto',
    height: '650px',
  },
  button: {
    padding: spacing(8, 16),
    margin: '0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    minWidth: spacing(120),

    fontSize: spacing(14),
    borderRadius: '75px',

    background: palette.primary.main,
    color: palette.gray[4],
    border: 'none',

    '&:not(:disabled):hover': {
      background: palette.primary.main,
      opacity: '0.8',
    },

    '&:disabled': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
}))
