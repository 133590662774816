import { INITIAL_STATE } from './constants'

export const ACTION = {
  UPDATE_STATE: 'UPDATE_STATE',
  UPDATE_CURRENT_TAB: 'UPDATE_CURRENT_TAB',
  UPDATE_CURRENT_BUTTON: 'UPDATE_CURRENT_BUTTON',
  CLOSE_MENU_BUTTON: 'CLOSE_MENU_BUTTON',
  TOGGLE_MENU_BUTTON: 'TOGGLE_MENU_BUTTON',
  CLOSE_COMMENT_MODAL: 'CLOSE_COMMENT_MODAL',
  TOGGLE_COMMENT_MODAL: 'TOGGLE_COMMENT_MODAL',
  RESET_STATE: 'RESET_STATE',
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION.UPDATE_STATE:
      return {
        ...state,
        ...payload,
      }
    case ACTION.UPDATE_CURRENT_TAB:
      return {
        ...state,
        ...payload,
        currentButton: payload.currentTab.defaultButton,
      }
    case ACTION.UPDATE_CURRENT_BUTTON:
      return {
        ...state,
        currentButton: payload,
        isMenuButtonOpen: false,
      }
    case ACTION.CLOSE_MENU_BUTTON:
      return {
        ...state,
        isMenuButtonOpen: false,
      }
    case ACTION.TOGGLE_MENU_BUTTON:
      return {
        ...state,
        isMenuButtonOpen: !state.isMenuButtonOpen,
      }
    case ACTION.CLOSE_COMMENT_MODAL:
      return {
        ...state,
        isCommentModalOpen: false,
      }
    case ACTION.TOGGLE_COMMENT_MODAL:
      return {
        ...state,
        isCommentModalOpen: !state.isCommentModalOpen,
      }
    case ACTION.RESET_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
