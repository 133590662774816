import { makeStyles } from '@material-ui/styles'
import styled from 'styled-components'

import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    overflowX: 'hidden',
  },

  logoContainer: {
    display: 'flex',
    gap: spacing(8),
  },

  intro: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#0943A6',
    width: '100%',
    position: 'relative',
    height: '82vh',

    '@media (max-width: 640px)': {
      height: '540px',
    },
  },
  introWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: spacing(1600),
    position: 'absolute',
    height: '100%',
  },
  introImgContainer: {
    width: '50%',
    height: 'auto',
    position: 'relative',

    '@media (max-width: 640px)': {
      position: 'absolute',
      left: '40px',
      top: '40px',
    },
  },
  introReferaLogo: {
    height: spacing(96),
    width: 'auto',
    position: 'absolute',
    left: '56%',
    top: '44%',

    '@media (max-width: 1366px)': {
      height: spacing(72),
    },

    '@media (max-width: 840px)': {
      left: 'auto',
      right: '24px',
    },

    '@media (max-width: 640px)': {
      height: spacing(56),
      top: '38%',
      left: '0',
    },

    '@media (max-width: 640px) and (max-height: 420px)': {
      top: '30%',
    },
  },
  introContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(120, 0, 40, 40),
    textAlign: 'left',
    width: '50%',
    justifyContent: 'center',

    '@media (max-height: 640px)': {
      padding: spacing(40, 0, 40, 40),
    },

    '@media (max-width: 640px) or (max-height: 420px)': {
      width: '100%',
      padding: spacing(80, 40, 0, 40),
    },
  },
  introTitle: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '64px',
    fontWeight: 'bold',
    lineHeight: '105%',
    color: 'white',

    '@media (max-width: 1200px)': {
      fontSize: '54px',
    },
    '@media (max-width: 1020px)': {
      fontSize: '46px',
    },
    '@media (max-width: 880px)': {
      fontSize: '40px',
    },
    '@media (max-width: 640px) or (max-height: 420px)': {
      width: '82%',
      fontSize: '36px',
    },
    '@media (max-width: 540px)': {
      fontSize: '30px',
    },
    '@media (max-height: 420px)': {
      fontSize: '36px',
    },
  },
  introDescription: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: '20px',
    lineHeight: '24px',
    color: 'white',
    margin: '24px 0',

    '@media (min-width: 1160px)': {
      maxWidth: '400px',
    },
    '@media (max-width: 640px)': {
      textAlign: 'center',
      fontSize: '16px',
    },
  },
  button: {
    padding: '10px 24px',
    borderRadius: '100px',
    border: 'none',
    cursor: 'pointer',
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textAlign: 'center',
    display: 'flex',
    width: 'fit-content',
    gap: '8px',

    [breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  whiteButton: {
    backgroundColor: 'white',
    color: '#0943A6',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#E1E1E1',
    },
  },

  buttonsContainer: {
    display: 'flex',
    width: spacing(600),
    gap: spacing(16),

    '@media (max-width: 640px)': {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
  },

  blueButton: {
    color: 'white',
    backgroundColor: '#0943A6',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: Theme.Colors.Primary.Base,
    },
  },

  orangeButton: {
    color: 'white',
    backgroundColor: '#E37021',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: Theme.Colors.Orange.Dark,
    },
  },

  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: spacing(28),
    fontWeight: 'bold',
    lineHeight: spacing(28),
  },

  // services
  services: {
    backgroundColor: '#0943A6',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  servicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    maxWidth: spacing(1600),
    padding: spacing(0, 40),

    // removing flaw on header image on bottom
    backgroundColor: '#0943A6',
    paddingTop: spacing(4),
    marginTop: spacing(-4),
  },

  servicesTitle: {
    maxWidth: spacing(340),
    color: 'white',

    '@media (max-width: 640px)': {
      textAlign: 'center',
    },
  },

  servicesList: {
    display: 'flex',
    justifyContent: 'left',
    gap: '20px',
    padding: '24px 0 40px',
    flexWrap: 'wrap',
  },

  serviceItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: spacing(20),
    width: spacing(180),
    minWidth: spacing(180),
    gap: spacing(16),
    backgroundColor: 'white',
    borderRadius: '8px',

    '@media (max-width: 440px)': {
      width: 'calc(50% - 10px)',
      minWidth: '120px',
    },
  },

  serviceIcon: {
    filter:
      'invert(13%) sepia(94%) saturate(3757%) hue-rotate(215deg) brightness(89%) contrast(93%)',
    height: spacing(50),
    width: 'fit-content',

    '@media (max-width: 640px)': {
      height: spacing(44),
    },
  },

  serviceText: {
    color: '#0943A6',
    fontSize: spacing(20),
    lineHeight: spacing(24),
    fontWeight: 500,
  },

  // steps
  steps: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '100%',
  },

  stepsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: spacing(1600),
    padding: spacing(60, 40),
    gap: spacing(32),
  },

  stepsTitle: {
    maxWidth: spacing(300),
    color: '#0943A6',

    '@media (max-width: 640px)': {
      textAlign: 'center',
    },
  },

  stepItem: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  timeline: {
    height: spacing(8),
    backgroundColor: '#0943A6',
    width: spacing(300),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    transform: 'translateX(-12px)',
    position: 'absolute',
  },

  timelineFirstCircle: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  timelineCircle: {
    height: spacing(32),
    width: spacing(32),
    borderRadius: '50%',
    backgroundColor: '#0943A6',
  },

  timelineInfoWrapper: {
    width: spacing(260),
    paddingTop: spacing(32),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(12),
  },

  timelineInfoTitle: {
    maxWidth: spacing(220),
    color: 'black',
  },

  timelineInfoDescription: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    color: 'black',
    fontSize: spacing(16),
    width: spacing(150),
    lineHeight: spacing(18),
  },

  timelineExtraInfoSeparator: {
    width: spacing(84),
    height: spacing(1),
    backgroundColor: '#0943A6',
  },

  timelineExtraInfo: {
    color: '#E37021',
    width: spacing(170),
    fontSize: spacing(12),
    lineHeight: spacing(14),
  },

  // stats
  stats: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F1F1F1',
    width: '100%',
  },

  statsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: spacing(1600),
    padding: spacing(60, 40),
    gap: spacing(48),
    position: 'relative',
  },

  quarterCircleImg: {
    width: spacing(200),
    height: 'auto',
    position: 'absolute',
    right: 0,
    top: '-32px',

    '@media (max-width: 740px)': {
      width: spacing(160),
      top: '-16px',
    },
  },

  statsTitle: {
    color: '#E37021',
    fontSize: spacing(36),
    lineHeight: '104%',

    '@media (max-width: 660px)': {
      fontSize: spacing(28),
      paddingRight: '50%',
    },
  },

  statsList: {
    display: 'flex',
    justifyContent: 'center',
    gap: spacing(48),
    textAlign: 'center',

    '@media (max-width: 600px)': {
      flexDirection: 'column',
      gap: spacing(36),
    },
  },

  statsItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
  },

  statsDescription: {
    width: spacing(150),
    color: 'black',
    letterSpacing: '1px',
  },

  // benefits
  benefits: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '100%',
    minHeight: spacing(600),
  },
  benefitsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'auto',
    maxWidth: spacing(1600),
    padding: spacing(80, 64),

    '@media (max-width: 640px)': {
      flexDirection: 'column',
      padding: spacing(40),
    },
  },
  benefitsDescriptionContainer: {
    width: '50%',
    height: '100%',
    paddingRight: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: spacing(24),

    '@media (max-width: 640px)': {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: '0',
      paddingBottom: spacing(24),
    },
  },
  benefitsDescription: {
    fontSize: spacing(24),
    color: 'black',
    textAlign: 'left',
    lineHeight: '105%',
    marginBottom: spacing(12),

    '@media (max-width: 640px)': {
      textAlign: 'center',
      fontSize: spacing(20),
    },
  },
  benefitsContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: '50%',

    '@media (max-width: 640px)': {
      width: '100%',
    },
  },
  benefitsTitleButtonWrapper: {
    gap: spacing(24),
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 640px)': {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  benefitsTitle: {
    '@media (max-width: 640px)': {
      textAlign: 'center',
      paddingRight: 0,
    },
  },
  benefitsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  benefitItem: {
    display: 'flex',
    gap: spacing(16),
  },
  checkIcon: {
    height: spacing(32),
    width: spacing(32),
  },

  benefitItemText: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: '16px',
    lineHeight: '32px',
    color: '#49454F',
  },

  // ending
  ending: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '100%',
  },
  endingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: spacing(1600),
    padding: spacing(0, 64, 48),

    '@media (max-width: 640px)': {
      flexDirection: 'column',
      gap: spacing(36),
    },
  },

  endingLeft: {
    width: '50%',
    paddingRight: '10%',
    display: 'flex',
    justifyContent: 'flex-start',

    '@media (max-width: 640px)': {
      width: '100%',
      paddingRight: 0,
    },
  },

  endingTitle: {
    maxWidth: spacing(240),

    '@media (max-width: 640px)': {
      maxWidth: '100%',
      paddingRight: 0,
      textAlign: 'center',
    },
  },

  endingRight: {
    width: '50%',
    display: 'flex',
    gap: spacing(16),
    alignItems: 'center',
    justifyContent: 'flex-end',

    '@media (max-width: 640px)': {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  referaLogoBlue: {
    height: spacing(64),
    width: 'auto',

    '@media (max-width: 640px)': {
      height: spacing(46),
    },
  },
  endingMessage: {
    color: '#0943A6',
    fontWeight: 700,
    fontSize: spacing(20),
    maxWidth: spacing(240),
    lineHeight: '103%',

    '@media (max-width: 640px)': {
      fontSize: spacing(14),
    },
  },

  footer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#0943A6',
    width: '100%',
    marginTop: 'auto',
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: spacing(1600),
    padding: spacing(60, 48),
    alignItems: 'center',

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: spacing(16),
      alignItems: 'center',
      padding: spacing(24, 24, 72),
    },
  },
  referaLogo: {
    height: spacing(44),
    width: 'auto',
  },
  logoText: {
    fontFamily: Theme.Typography.FontFamily.Inter,
    color: '#0943A6',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '-0.02em',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  footerGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing(12),

    [breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  footerGroupTitle: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '21px',
    color: 'white',
  },
  contactButtonsContainer: {
    display: 'flex',
    gap: spacing(16),
  },
  contactButton: {
    cursor: 'pointer',
  },
  contactIcons: {
    fontSize: spacing(24),
  },
}))

export const BackgroundImage = styled.div`
  background-image: url(${props => props.image});
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 82vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 640px) {
    height: 54vh;
    margin-bottom: 200px;
  }

  @media (max-height: 460px) {
    height: 100vh;
  }
`
