import React from 'react'
import { FormControlLabel, Typography } from '@material-ui/core'

import Accordion from '_/components/accordion'
import SwitchComponent from '_/components/switch'

import { MoneyInput } from '@refera/ui-web'

import useStyles from './styles'

export const AgencyBypass = ({ isEditing, ...params }) => {
  const styles = useStyles()

  return (
    <Accordion title="Parâmetros da empresa" className={styles.accordion}>
      <div>
        <Typography component="h3" className={styles.label}>
          Bypass revisão de orçamento
        </Typography>
        <FormControlLabel
          className={styles.switch}
          control={
            <SwitchComponent
              name="bypass"
              checked={params?.values?.bypass}
              onChange={e => params.setFieldValue('bypass', e.target.checked)}
              disabled={!isEditing}
            />
          }
        />
      </div>
      <div>
        <Typography component="h3" className={styles.label}>
          Valor mínimo para bypass de orçamento
        </Typography>
        <MoneyInput
          disabled={!isEditing || !params?.values?.bypass}
          allowNegative={false}
          allowLeadingZeros={false}
          placeholder="R$ "
          decimalScale={2}
          name="minBypassValue"
          label=""
          value={params?.values?.minBypassValue}
          onChange={e => params.setFieldValue('minBypassValue', e.floatValue)}
          style={{ fontSize: '14px' }}
        />
        {params.errors?.minBypassValue && (
          <Typography className={styles.error}>{params.errors?.minBypassValue}</Typography>
        )}
      </div>
      <div>
        <Typography component="h3" className={styles.label}>
          Valor máximo para bypass de orçamento
        </Typography>
        <MoneyInput
          disabled={!isEditing || !params?.values?.bypass}
          allowNegative={false}
          allowLeadingZeros={false}
          placeholder="R$ "
          decimalScale={2}
          name="maxBypassValue"
          label=""
          value={params?.values?.maxBypassValue}
          onChange={e => params.setFieldValue('maxBypassValue', e.floatValue)}
          style={{ fontSize: '14px' }}
        />
        {params.errors?.maxBypassValue && (
          <Typography className={styles.error}>{params.errors?.maxBypassValue}</Typography>
        )}
      </div>
    </Accordion>
  )
}
