import React, { useCallback, useState, useEffect, useMemo } from 'react'

import { Button as ReferaButton, Loader } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'
import useStyles from './styles'

import { navigate, useParams } from '@reach/router'

import { Grid, InputLabel } from '@material-ui/core'

import { useForm, FormProvider } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { updateProperty } from '_modules/property/actions'
import { ERRORS } from '../payer-data/utils'

import Button from '_components/button'
import { BUTTONS_ACTION } from '_utils/constants/service-order'
import { CPF_CNPJ_REGEX, EMAIL_REGEX, PHONE_REGEX, ROUTES } from '_utils/constants'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { getOptionsPaymentResponsible, getServiceOrder } from '_/modules/service-orders/actions'
import useCanDoButtonAction from '_/hooks/use-can-do-button-action'
import { getHistoryLogs } from '_modules/history-logs/actions'
import { cpfCnpjMask, parseToNumber, validationCNPJ, validationCPF } from '_utils/helpers'
import { useToast } from '_/hooks/use-toast'
import SelectField from '../components/SelectField'
import { formatErrorMessage } from '../utils/FormatErrorMessage'
import { BasicInput, MaskBasicInput, Checkbox } from '_/components/inputs'
import { cpfCnpjMaskSwitch } from '../utils/functions'
import classNames from 'classnames'
import { getStepStatusLog } from '_/modules/budget/actions'
import { includeDDI } from '_/views/new-service/utils/includeDDI'

export const validateCPFCNPJ = value => {
  const parseNumber = parseToNumber(value)

  if (parseNumber.length === 0) return true
  if (parseNumber.length <= 11 && !validationCPF(value)) return ERRORS.INVALID_CPF
  if (parseNumber.length > 11 && parseNumber.length <= 14 && !validationCNPJ(value))
    return ERRORS.INVALID_CNPJ

  return true
}

const OwnerData = () => {
  const styles = useStyles()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()
  const { showToast } = useToast()

  const [paymentResponsibleOptions, setPaymentResponsibleOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const canEditPayerResponsible = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.EDIT_PAYER_RESPONSIBLE,
  })

  const propertyInfo = useMemo(() => {
    return serviceOrder?.toJS()?.property
  }, [serviceOrder])

  const methods = useForm({ mode: 'all' })
  const { watch, control, errors, handleSubmit } = methods

  const cpfOrCnpjWatch = watch('cpfOrCnpj')
  const tenantCpfOrCnpjWatch = watch('tenantCpfOrCnpj')

  const fetchPayerData = useCallback(() => {
    dispatch(getOptionsPaymentResponsible(serviceOrderId)).then(data =>
      setPaymentResponsibleOptions(data?.options)
    )
  }, [dispatch, serviceOrderId])

  const fetchServiceOrder = useCallback(() => {
    setIsLoading(true)
    dispatch(getServiceOrder(serviceOrderId))
      .then(() => {
        setIsLoading(false)
      })
      .catch(() => {
        showToast({ type: 'error' })
        setIsLoading(false)
      })
  }, [serviceOrderId])

  const handleBackNavigation = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))
    return navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrderId}`)
  }, [navigate, serviceOrderId, dispatch])

  const onSubmit = useCallback(
    async dataValues => {
      if (Object.keys(errors)?.length) return false
      const responsiblePaymentField = dataValues?.paymentResponsible
      const isNewResponsiblePayment =
        responsiblePaymentField !== null && responsiblePaymentField !== ''

      if (isNewResponsiblePayment) {
        if (!canEditPayerResponsible) {
          showToast({
            message: 'A etapa do chamado não permite a edição do responsável pelo pagamento.',
            type: 'error',
          })
          return null
        }
      }

      setIsLoading(true)
      const payload = {
        serviceOrderId,
        serviceUpdateOwner: true,
        ...dataValues,
      }
      await dispatch(updateProperty(serviceOrder?.propertyId, payload))
        .then(async () => {
          const isWaitingPayerData = serviceOrder?.stepStatus === 'waiting_payer_data'
          if (isWaitingPayerData) {
            const {
              tenantName,
              tenantEmail,
              tenantWhatsapp,
              propertyEmail,
              propertyName,
              propertyWhatsapp,
              paymentResponsible,
            } = dataValues

            const arePayerDataFilled =
              paymentResponsible === 'property'
                ? propertyEmail && propertyName && propertyWhatsapp
                : tenantName && tenantEmail && tenantWhatsapp

            if (!arePayerDataFilled) {
              showToast({
                message:
                  'O chamado ainda ficará na etapa "Aguardando dados do pagador" até que você preencha todos os dados do responsável pelo pagamento.',
                type: 'info',
              })
              setIsLoading(false)
            }
          }
          showToast({ type: 'success' })
          setIsLoading(false)
          fetchServiceOrder()
        })
        .catch(err => {
          setIsLoading(false)
          const errorMessage = formatErrorMessage(err)
          showToast({
            message: errorMessage,
            type: 'error',
          })
        })
      return null
    },
    [serviceOrderId, fetchServiceOrder, serviceOrder, errors, canEditPayerResponsible]
  )

  useEffect(() => {
    if (!serviceOrder) fetchServiceOrder()
    dispatch(getStepStatusLog(serviceOrderId))
  }, [serviceOrder])

  useEffect(() => {
    fetchPayerData()
  }, [serviceOrderId])

  return (
    <>
      <Loader open={isLoading} hasBackdrop />
      <FormProvider {...methods}>
        <form id="ownerForm" onSubmit={handleSubmit(onSubmit)}>
          <HeaderTitle title="Dados dos envolvidos" backButtonAction={handleBackNavigation}>
            <Grid className={styles.headerButtons}>
              <Button
                className={styles.button}
                type="submit"
                form="ownerForm"
                color="primary"
                variant="contained"
                disabled={isLoading}
              >
                Salvar
              </Button>
              <ReferaButton variant="secondary" color="red" onClick={handleBackNavigation}>
                Cancelar
              </ReferaButton>
            </Grid>
          </HeaderTitle>
          {serviceOrder && (
            <Grid className={styles.container}>
              <Grid className={styles.subcontainer}>
                <InputLabel className={styles.label}>Geral</InputLabel>
                <Grid className={styles.wrapper}>
                  <Grid className={classNames(styles.input, styles.select)}>
                    <SelectField
                      id="payer"
                      label="Responsável pelo pagamento"
                      name="paymentResponsible"
                      defaultValue={propertyInfo?.paymentResponsible || ''}
                      control={control}
                      items={paymentResponsibleOptions}
                      defaultSelectStyle={false}
                      emptyItem
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={styles.subcontainer}>
                <InputLabel className={styles.label}>Dados do proprietário</InputLabel>
                <Grid className={styles.wrapper}>
                  <Grid className={styles.input}>
                    <BasicInput
                      label="Nome do proprietário"
                      name="propertyName"
                      labelClasses={styles.inputLabel}
                      defaultValue={propertyInfo?.propertyName || ''}
                    />
                  </Grid>
                  <Grid className={styles.input}>
                    <MaskBasicInput
                      label="CPF/CNPJ do proprietário"
                      name="cpfOrCnpj"
                      mask={cpfCnpjMaskSwitch(cpfOrCnpjWatch)}
                      number
                      labelClasses={styles.inputLabel}
                      defaultValue={cpfCnpjMask(propertyInfo?.cpfOrCnpj) || ''}
                      rules={{
                        pattern: { value: CPF_CNPJ_REGEX, message: 'CPF ou CNPJ inválido' },
                        validate: {
                          checkCpfCnpj: v => validateCPFCNPJ(v) || '',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className={styles.wrapper}>
                  <Grid className={styles.input}>
                    <BasicInput
                      label="E-mail do proprietário"
                      name="propertyEmail"
                      labelClasses={styles.inputLabel}
                      defaultValue={propertyInfo?.propertyEmail || ''}
                      rules={{
                        pattern: { value: EMAIL_REGEX, message: 'E-mail inválido' },
                      }}
                    />
                  </Grid>

                  <Grid className={styles.input}>
                    <MaskBasicInput
                      label="WhatsApp do proprietário"
                      name="propertyWhatsapp"
                      mask="+55 (99) 9 9999-9999"
                      rules={{
                        pattern: { value: PHONE_REGEX, message: 'WhatsApp inválido' },
                      }}
                      labelClasses={styles.inputLabel}
                      defaultValue={
                        propertyInfo?.propertyWhatsapp
                          ? includeDDI(propertyInfo?.propertyWhatsapp)
                          : ''
                      }
                    />
                  </Grid>
                  <Grid className={styles.input}>
                    <Checkbox
                      label="Enviar atualizações pelo WhatsApp"
                      name="propertyWhatsappUpdate"
                      defaultValue={propertyInfo?.propertyWhatsappUpdate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={styles.subcontainer}>
                <InputLabel className={styles.label}>Dados do inquilino</InputLabel>
                <Grid className={styles.wrapper}>
                  <Grid className={styles.input}>
                    <BasicInput
                      label="Nome do inquilino"
                      name="tenantName"
                      labelClasses={styles.inputLabel}
                      defaultValue={propertyInfo?.tenantName || ''}
                    />
                  </Grid>
                  <Grid className={styles.input}>
                    <MaskBasicInput
                      label="CPF/CNPJ do inquilino"
                      name="tenantCpfOrCnpj"
                      mask={cpfCnpjMaskSwitch(tenantCpfOrCnpjWatch)}
                      number
                      labelClasses={styles.inputLabel}
                      defaultValue={cpfCnpjMask(propertyInfo?.tenantCpfOrCnpj) || ''}
                      rules={{
                        pattern: { value: CPF_CNPJ_REGEX, message: 'CPF ou CNPJ inválido' },
                        validate: {
                          checkCpfCnpj: v => validateCPFCNPJ(v) || '',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className={styles.wrapper}>
                  <Grid className={styles.input}>
                    <BasicInput
                      label="E-mail do inquilino"
                      name="tenantEmail"
                      labelClasses={styles.inputLabel}
                      defaultValue={propertyInfo?.tenantEmail || ''}
                      rules={{
                        pattern: { value: EMAIL_REGEX, message: 'E-mail inválido' },
                      }}
                    />
                  </Grid>

                  <Grid className={styles.input}>
                    <MaskBasicInput
                      label="WhatsApp do inquilino"
                      name="tenantWhatsapp"
                      mask="+55 (99) 9 9999-9999"
                      rules={{
                        pattern: { value: PHONE_REGEX, message: 'WhatsApp inválido' },
                      }}
                      labelClasses={styles.inputLabel}
                      defaultValue={propertyInfo?.tenantWhatsapp || ''}
                    />
                  </Grid>
                  <Grid className={styles.input}>
                    <Checkbox
                      label="Enviar atualizações pelo WhatsApp"
                      name="tenantWhatsappUpdate"
                      defaultValue={propertyInfo?.tenantWhatsappUpdate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </form>
      </FormProvider>
    </>
  )
}

export default OwnerData
