import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  required: {
    '&::after': {
      content: '"*"',
      color: Theme.Colors.Red.Base,
      marginLeft: spacing(4),
      fontSize: spacing(12),
    },
  },
}))
