import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Link } from '@material-ui/core'

import { Modal } from '@refera/ui-web'

import useStyles from './styles'

const SelfLearningModal = ({ title, modalOpen, onCancel, template, supportService }) => {
  const styles = useStyles()
  const whatsappLink = `https://wa.me/${supportService}`
  const emailLink = 'https://refera.movidesk.com/form/6753/'

  const intermediarySupportService = useMemo(() => (
    <Grid>
      <Typography className={styles.title} variant="h4">
        Ainda em dúvidas?
      </Typography>
      <Typography className={styles.description}>
        Para falar com a Refera por e-mail,{' '}
        <Link
          underline="hover"
          classes={{ root: styles.link }}
          target="_blank"
          rel="noopener"
          href={emailLink}
        >
          clique aqui
        </Link>
        . Para falar com a Refera pelo WhatsApp,{' '}
        <Link
          underline="hover"
          classes={{ root: styles.link }}
          target="_blank"
          rel="noopener"
          href={whatsappLink}
        >
          clique aqui
        </Link>
        .
      </Typography>
    </Grid>
  ))

  return (
    <Modal
      onBackdropClick={() => onCancel()}
      onClose={() => onCancel()}
      open={modalOpen}
      title={title}
      className={styles.modal}
      actionsButtons={supportService ? intermediarySupportService : ''}
    >
      {template}
    </Modal>
  )
}

SelfLearningModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default SelfLearningModal
