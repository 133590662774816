import { createSelector } from 'reselect'

import { getBudgetsSelector } from '_modules/budget/selectors'

import {
  GET_SERVICE_ORDERS,
  GET_MORE_SERVICE_ORDERS,
  GET_SERVICE_ORDER,
  UPDATE_SERVICE_ORDER,
  GET_REASONS_REPROVE,
  DUPLICATE_SERVICE_ORDER,
  GET_ALL_STEP_STATUS_FILTER_OPTIONS,
  GET_ALL_STEP_STATUS_OPTIONS,
  GET_STEP_STATUS_FILTER,
  UPDATE_APPOINTMENT_DATE_SCHEDULE,
  GET_GENERIC_PARAMETERS,
  GET_SERVICE_LIST,
  GET_PROBLEM_LIST,
  GET_CATEGORY_LIST,
} from './actions'

export const getServiceOrderLoadingSelector = state =>
  !!state.loading.get(GET_SERVICE_ORDERS.ACTION) ||
  !!state.loading.get(GET_MORE_SERVICE_ORDERS.ACTION)

export const serviceOrdersSelector = state => {
  const { results, order } = state.serviceOrders
  return order.map(id => results.get(id.toString()))
}
export const serviceOrdersCountSelector = state => state.serviceOrders.count
export const serviceOrdersLoadedPagesSelector = state => state.serviceOrders.loadedPages
export const serviceOrdersPageSelector = state => state.serviceOrders.page
export const serviceOrdersReducerSelector = state => state.serviceOrders

export const serviceOrderCurrentSelector = state => {
  const serviceOrder = state.serviceOrders.getIn(['results', state.serviceOrders.current])
  // TODO remove this validation once we fix the inconsistency on the reducer and all service-order key are strings
  if (!serviceOrder) {
    return state.serviceOrders.getIn(['results', Number(state.serviceOrders.current)])
  }
  return serviceOrder
}

export const currentServiceOrderSelector = createSelector(
  serviceOrderCurrentSelector,
  getBudgetsSelector,
  (currentServiceOrder, budgets) => {
    const formattedBudgets = currentServiceOrder?.budget?.map(budget =>
      budgets?.get(`${budget?.get?.('id') || budget}`)
    )

    return currentServiceOrder?.set('budget', formattedBudgets)
  }
)

export const getServiceOrderByIdLoadingSelector = state =>
  state.loading.get(GET_SERVICE_ORDER.ACTION)

export const getServiceOrderByID = (state, id) => state.serviceOrders?.getIn(['results', id])

export const updateServiceOrderLoadingSelector = state =>
  state.loading.get(UPDATE_SERVICE_ORDER.ACTION)

export const updateServiceOrderErrorSelector = state => state.error.get(UPDATE_SERVICE_ORDER.ACTION)

export const reasonsToReproveOptionSelector = state => state.loading.get(GET_REASONS_REPROVE.ACTION)

export const getApprovedServiceSelector = (state, id) => {
  const serviceOrder = state.serviceOrders.getIn(['results', `${id}`])
  return serviceOrder
}

export const reasonsToRefuseMetabaseSelector = ({ serviceOrders }) => {
  const list = serviceOrders.reasons?.map(option => {
    const value = Object.keys(option)[0]
    const label = option[value]
    return { value, label }
  })
  return list.size > 0 && list
}

export const getSendEmailDefinition = state => {
  return state?.serviceOrders.get('sendEmailDefinition')
}

export const getServiceOrderIsFilterDirtySelector = state =>
  state?.serviceOrders?.get('isFilterDirty')

export const getServiceOrderFilterSelector = state => state?.serviceOrders?.get('filter')?.toJS()

export const isGetDuplicateServiceOrderLoadingSelector = state =>
  state.loading.get(DUPLICATE_SERVICE_ORDER.ACTION)

export const getStepStatusFilterSelector = state =>
  state.serviceOrders.get('stepStatusFilter')?.toJS()

export const getStepStatusFilterLoadingSelector = state =>
  state.loading.get(GET_STEP_STATUS_FILTER.ACTION)

export const getStepStatusCanceledListSelector = state =>
  state.serviceOrders.get('stepStatusCanceledList')?.toJS()

export const getStepStatusFinalizedListSelector = state =>
  state.serviceOrders.get('stepStatusFinalizedList')?.toJS()

export const getAllStepStatusFilterOptionsSelector = state =>
  state.serviceOrders.get('allStepStatusFilterOptions')?.toJS()

export const getAllStepStatusFilterOptionsLoadingSelector = state =>
  state.loading.get(GET_ALL_STEP_STATUS_FILTER_OPTIONS.ACTION)

export const getAllStepStatusOptionsSelector = state =>
  state.serviceOrders.get('allStepStatusOptions')?.toJS()

export const getAllStepStatusOptionsLoadingSelector = state =>
  state.loading.get(GET_ALL_STEP_STATUS_OPTIONS.ACTION)

export const getButtonsActionSelector = state => state.serviceOrders?.get('buttonsAction')

export const getButtonsActionByNameSelector = nameButton => state =>
  state.serviceOrders?.get('buttonsAction')?.get(nameButton)

export const getGenericParametersSelector = state => state.serviceOrders?.get('genericParameters')

export const getGenericParametersRequesterSelector = state =>
  state.serviceOrders?.get('genericParametersRequester')

export const getGenericParametersLoadingSelector = state =>
  !!state.loading.get(GET_GENERIC_PARAMETERS.ACTION)

export const getReactivateButtonSelector = state => state.serviceOrders?.get('reactivateButton')

export const updateAppointmentDateScheduleErrorSelector = state =>
  state.error.get(UPDATE_APPOINTMENT_DATE_SCHEDULE.ACTION)

export const updateAppointmentDateScheduleLoadingSelector = state =>
  state.loading.get(UPDATE_APPOINTMENT_DATE_SCHEDULE.ACTION)

export const getPaymentAnticipationSelector = state =>
  state.serviceOrders?.get('paymentAnticipation')

export const agencyServiceOrderFlows = state => state.serviceOrders.get('flowsOptions')

export const activeBudgetSelector = state => state.serviceOrders.get('activeBudget')

export const getServiceListLoadingSelector = state => !!state.loading.get(GET_SERVICE_LIST.ACTION)

export const getProblemListLoadingSelector = state => !!state.loading.get(GET_PROBLEM_LIST.ACTION)

export const getCategoryListLoadingSelector = state => !!state.loading.get(GET_CATEGORY_LIST.ACTION)
