import React, { useCallback, useState, useEffect, useReducer, useMemo } from 'react'
import { SwipeableDrawer, Divider, Grid, Slide, Typography } from '@material-ui/core'
import { FilterSearch as FilterIcon, CloseCircle } from '@refera/ui-icons'
import { useDispatch, useSelector } from 'react-redux'
import Theme from '@refera/ui-core'
import {
  Button,
  DatePicker,
  Input,
  MoneyInput,
  Typography as ReferaTypography,
} from '@refera/ui-web'
import moment from 'moment'
import classNames from 'classnames'

import { agenciesSimpleSelector } from '_modules/agency/selectors'
import { updateBudgetInstallmentsFilter } from '_modules/finance/actions'
import { getBudgetInstallmentsFilterSelector } from '_modules/finance/selectors'
import { getCompaniesSimpleSelector } from '_modules/company/selector'
import Select from '_components/common/select'

import { ANTICIPATIONS_PAYMENT_METHOD_OPTIONS, STATUS_FIELDS } from '../../utils/constants'
import AutoComplete from '../../../components/AutoComplete'

import { FORM_FIELD_NAME, INITIAL_STATE, reducer, RESET_STATE, UPDATE_STATE } from './reducer'
import useStyles from './styles'
import { formatServiceOrderFilter } from '../../utils/functions'
import { useToast } from '_/hooks/use-toast'
import {
  DEFAULT_SELECT_FILTER_OPTIONS,
  DEFAULT_SELECT_FILTER_OPTIONS_WITH_NULL,
} from '_/views/finance/utils/constants'

const ANTICIPATION_OPTIONS = [
  {
    value: '',
    label: '(Todos)',
  },
  {
    value: 'true',
    label: 'Com antecipação',
  },
  {
    value: 'false',
    label: 'Sem antecipação',
  },
]

const SORTED_ANTICIPATIONS_PAYMENT_METHOD_OPTIONS = ANTICIPATIONS_PAYMENT_METHOD_OPTIONS.sort(
  (a, b) => a.label.localeCompare(b.label)
)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

// TODO: Implement react-hook-form
const Filters = ({ handleFilterDrawer, params, open, setPage, handleGetInstallments }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const filters = useSelector(getBudgetInstallmentsFilterSelector)

  const [selectedTab, setSelectedTab] = useState('main')
  const companies = useSelector(getCompaniesSimpleSelector)
  const agencies = useSelector(agenciesSimpleSelector)
  const { showToast } = useToast()

  const [apply, setApply] = useState(false)

  const checkValidInitialDate = date => {
    return moment(date).isValid() ? moment(date) : null
  }

  const initialState = useMemo(() => {
    const hasTransferDateValue =
      filters?.transferDate?.length && typeof filters?.transferDate === 'string'
    const hasServiceOrderEndDateValue =
      filters?.serviceOrderEndDate?.length && typeof filters?.serviceOrderEndDate === 'string'
    const hasServiceOrderLastEndDateValue =
      filters?.serviceOrderLastEndDate?.length &&
      typeof filters?.serviceOrderLastEndDate === 'string'
    const hasPaymentDateValue =
      filters?.paymentDate?.length && typeof filters?.paymentDate === 'string'
    const hasSolicitationDateValue =
      filters?.solicitationDate?.length && typeof filters?.solicitationDate === 'string'
    const hasProviderValue =
      filters?.providerValue?.length && typeof filters?.providerValue === 'string'

    const [transferDateStart, transferDateEnd] = hasTransferDateValue
      ? filters.transferDate.split(',')
      : []
    const [serviceOrderEndDateStart, serviceOrderEndDateEnd] = hasServiceOrderEndDateValue
      ? filters.serviceOrderEndDate.split(',')
      : []
    const [serviceOrderLastEndDateStart, serviceOrderLastEndDateEnd] =
      hasServiceOrderLastEndDateValue ? filters.serviceOrderLastEndDate.split(',') : []
    const [paymentDateStart, paymentDateEnd] = hasPaymentDateValue
      ? filters.paymentDate.split(',')
      : []
    const [solicitationDateStart, solicitationDateEnd] = hasSolicitationDateValue
      ? filters.solicitationDate.split(',')
      : []
    const [providerValueStart, providerValueEnd] = hasProviderValue
      ? filters.providerValue.split(',')
      : []

    const state = Object.values(FORM_FIELD_NAME).reduce((acc, currentFieldKey) => {
      switch (currentFieldKey) {
        case FORM_FIELD_NAME.TRANSFER_DATE_END:
          return {
            ...acc,
            [currentFieldKey]: hasTransferDateValue
              ? checkValidInitialDate(transferDateEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.TRANSFER_DATE_START:
          return {
            ...acc,
            [currentFieldKey]: hasTransferDateValue
              ? checkValidInitialDate(transferDateStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_END:
          return {
            ...acc,
            [currentFieldKey]: hasServiceOrderEndDateValue
              ? checkValidInitialDate(serviceOrderEndDateEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.SERVICE_ORDER_END_DATE_START:
          return {
            ...acc,
            [currentFieldKey]: hasServiceOrderEndDateValue
              ? checkValidInitialDate(serviceOrderEndDateStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.SERVICE_ORDER_LAST_END_DATE_START:
          return {
            ...acc,
            [currentFieldKey]: hasServiceOrderLastEndDateValue
              ? checkValidInitialDate(serviceOrderLastEndDateStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.SERVICE_ORDER_LAST_END_DATE_END:
          return {
            ...acc,
            [currentFieldKey]: hasServiceOrderLastEndDateValue
              ? checkValidInitialDate(serviceOrderLastEndDateEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.PAYMENT_DATE_START:
          return {
            ...acc,
            [currentFieldKey]: hasPaymentDateValue
              ? checkValidInitialDate(paymentDateStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.PAYMENT_DATE_END:
          return {
            ...acc,
            [currentFieldKey]: hasPaymentDateValue
              ? checkValidInitialDate(paymentDateEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.SOLICITATION_DATE_START:
          return {
            ...acc,
            [currentFieldKey]: hasSolicitationDateValue
              ? checkValidInitialDate(solicitationDateStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.SOLICITATION_DATE_END:
          return {
            ...acc,
            [currentFieldKey]: hasSolicitationDateValue
              ? checkValidInitialDate(solicitationDateEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.TOTAL_VALUE_START:
          return {
            ...acc,
            [currentFieldKey]: hasProviderValue
              ? parseFloat(providerValueStart)
              : INITIAL_STATE[currentFieldKey],
          }
        case FORM_FIELD_NAME.TOTAL_VALUE_END:
          return {
            ...acc,
            [currentFieldKey]: hasProviderValue
              ? parseFloat(providerValueEnd)
              : INITIAL_STATE[currentFieldKey],
          }
        default:
          return {
            ...acc,
            [currentFieldKey]: filters?.[currentFieldKey] || INITIAL_STATE[currentFieldKey],
          }
      }
    }, {})

    return {
      ...INITIAL_STATE,
      ...state,
    }
  }, [filters])

  const [localState, localDispatch] = useReducer(reducer, initialState)

  const handleChange = (value, field) => {
    localDispatch({
      type: UPDATE_STATE,
      payload: {
        [field]: value,
      },
    })
  }

  const handleMultiSelection = useCallback((newValue, field) => {
    localDispatch({ type: UPDATE_STATE, payload: { [field]: newValue } })
  }, [])

  const handleInputChange = event => {
    const { name: field, value } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: {
        [field]: value,
      },
    })
  }

  const cleanFilters = () => {
    localDispatch({ type: RESET_STATE })
  }

  const getDateInterval = (startDate, endDate) => {
    if (!startDate && !endDate) {
      return undefined
    }

    if (startDate && !endDate) {
      return `${startDate.startOf('day').toISOString()},`
    }
    if (!startDate && endDate) {
      return `,${endDate.endOf('day').toISOString()}`
    }

    return `${startDate.startOf('day').toISOString()},${endDate.endOf('day').toISOString()}`
  }

  const handleMultipleNumbersFilter = (input, defaultMessage = '') => {
    const formattedObj = formatServiceOrderFilter(input, defaultMessage)

    if (formattedObj?.message) {
      showToast({ ...formattedObj })
      return null
    }

    return formattedObj?.formattedServiceOrder
  }

  const getMoneyInterval = (startValue, endValue) => {
    if (!startValue && !endValue) {
      return null
    }
    if (startValue && !endValue) {
      return `${localState?.providerValueStart},`
    }
    if (!startValue && endValue) {
      return `,${localState?.providerValueEnd}`
    }
    return `${localState?.providerValueStart},${localState?.providerValueEnd}`
  }

  const handleApplyFilters = useCallback(() => {
    const transferDate = localState?.isValid
      ? getDateInterval(localState?.transferDateStart, localState?.transferDateEnd)
      : null
    const serviceOrderEndDate = localState?.isValid
      ? getDateInterval(localState?.serviceOrderEndDateStart, localState?.serviceOrderEndDateEnd)
      : null
    const serviceOrderLastEndDate = localState?.isValid
      ? getDateInterval(
          localState?.serviceOrderLastEndDateStart,
          localState?.serviceOrderLastEndDateEnd
        )
      : null
    const paymentDate = localState?.isValid
      ? getDateInterval(localState?.paymentDateStart, localState?.paymentDateEnd)
      : null
    const solicitationDate = localState?.isValid
      ? getDateInterval(localState?.solicitationDateStart, localState?.solicitationDateEnd)
      : null

    const providerValue = localState?.isValid
      ? getMoneyInterval(localState?.providerValueStart, localState?.providerValueEnd)
      : null

    // destructuring localState to avoid passing the whole object with all dates
    const {
      agency,
      company,
      responsible,
      serviceOrder,
      status,
      anticipation,
      ableToPay,
      batch,
      batchError,
      paymentMethod,
    } = localState

    let serviceOrderIds = serviceOrder
    let batchIds = batch

    if (serviceOrderIds?.trim()) {
      serviceOrderIds = handleMultipleNumbersFilter(serviceOrderIds)
      if (!serviceOrderIds) return
      handleChange(serviceOrderIds, 'serviceOrder')
    }

    if (batchIds?.trim()) {
      batchIds = handleMultipleNumbersFilter(batchIds, 'Filtro de lote só pode conter números.')
      if (!batchIds) return
      handleChange(batchIds, 'batch')
    }

    const updatedFilters = {
      agency,
      company,
      responsible,
      status,
      anticipation,
      ableToPay,
      serviceOrder: serviceOrderIds,
      transferDate,
      serviceOrderEndDate,
      serviceOrderLastEndDate,
      solicitationDate,
      paymentDate,
      providerValue,
      batch: batchIds,
      batchError,
      paymentMethod,
    }

    dispatch(updateBudgetInstallmentsFilter(updatedFilters))
    setApply(true)
  }, [dispatch, filters, localState])

  useEffect(() => {
    if (!filters) {
      return
    }

    if ((Object.values(filters)?.some(value => value) && apply) || apply) {
      setPage(0)
      handleGetInstallments()
      setApply(false)
    }
  }, [filters, apply, params])

  const MainFilters = useMemo(() => {
    return (
      <>
        <>
          <ReferaTypography className={styles.label}>Nome da empresa</ReferaTypography>
          <AutoComplete
            name="company"
            placeholder="Empresa"
            options={companies}
            value={localState[FORM_FIELD_NAME.COMPANY]}
            onChange={handleMultiSelection}
          />
        </>
        <>
          <ReferaTypography className={styles.label}>Nome da Intermediária</ReferaTypography>
          <AutoComplete
            name="agency"
            placeholder="Intermediária"
            options={agencies}
            value={localState[FORM_FIELD_NAME.AGENCY]}
            onChange={handleMultiSelection}
          />
        </>

        <Grid className={styles.inputsWrapper}>
          <DatePicker
            key="transferDateStart"
            label="Data de repasse"
            value={localState?.transferDateStart}
            onChange={value => handleChange(value, 'transferDateStart')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            maxDate={localState?.transferDateEnd || undefined}
            mask="__/__/____"
          />
          <Typography className={styles.dotDivision}>•</Typography>
          <DatePicker
            key="transferDateEnd"
            label=" "
            value={localState?.transferDateEnd}
            onChange={value => handleChange(value, 'transferDateEnd')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            minDate={localState?.transferDateStart || undefined}
            mask="__/__/____"
          />
        </Grid>
        <Select
          label="Status"
          defaultValue={localState?.status}
          value={localState?.status}
          onChange={handleInputChange}
          name="status"
          options={STATUS_FIELDS}
        />
        <Select
          label="Antecipação"
          defaultValue={localState?.anticipation}
          value={localState?.anticipation}
          onChange={handleInputChange}
          name="anticipation"
          options={ANTICIPATION_OPTIONS}
        />
      </>
    )
  }, [
    selectedTab,
    localState,
    handleMultiSelection,
    handleChange,
    handleInputChange,
    agencies,
    companies,
  ])

  const MoreFilters = useMemo(() => {
    return (
      <>
        <Input
          key="serviceOrderId"
          label="ID do chamado"
          value={localState?.serviceOrder}
          placeholder="12345"
          style={{ fontSize: '16px' }}
          onChange={handleInputChange}
          fullWidth
          name="serviceOrder"
        />

        <Grid>
          <ReferaTypography className={styles.label}>
            Data da 1ª finalização do chamado
          </ReferaTypography>
          <Grid className={styles.inputsWrapper}>
            <DatePicker
              key="serviceOrderEndDateStart"
              label=" "
              value={localState?.serviceOrderEndDateStart}
              onChange={value => handleChange(value, 'serviceOrderEndDateStart')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              invalidDateMessage="Insira uma data válida"
              maxDate={localState?.serviceOrderEndDateEnd || undefined}
              disableFuture
              mask="__/__/____"
            />
            <Typography className={styles.dotDivision}>•</Typography>
            <DatePicker
              key="serviceOrderEndDateEnd"
              label=" "
              value={localState?.serviceOrderEndDateEnd}
              onChange={value => handleChange(value, 'serviceOrderEndDateEnd')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              invalidDateMessage="Insira uma data válida"
              disableFuture
              minDate={localState?.serviceOrderEndDateStart || undefined}
              mask="__/__/____"
            />
          </Grid>
        </Grid>
        <Grid>
          <ReferaTypography className={styles.label}>
            Data de última finalização do chamado
          </ReferaTypography>
          <Grid className={styles.inputsWrapper}>
            <DatePicker
              key="serviceOrderLastEndDateStart"
              label=" "
              value={localState?.serviceOrderLastEndDateStart}
              onChange={value => handleChange(value, 'serviceOrderLastEndDateStart')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              invalidDateMessage="Insira uma data válida"
              maxDate={localState?.serviceOrderLastEndDateEnd || undefined}
              disableFuture
              mask="__/__/____"
            />
            <Typography className={styles.dotDivision}>•</Typography>
            <DatePicker
              key="serviceOrderLastEndDateEnd"
              label=" "
              value={localState?.serviceOrderLastEndDateEnd}
              onChange={value => handleChange(value, 'serviceOrderLastEndDateEnd')}
              className={styles.datePicker}
              refuse={/[^\d\\.]+/gi}
              variant="inline"
              format="dd/MM/yyyy"
              placeholder="DD/MM/AAAA"
              invalidDateMessage="Insira uma data válida"
              disableFuture
              minDate={localState?.serviceOrderLastEndDateStart || undefined}
              mask="__/__/____"
            />
          </Grid>
        </Grid>
        <Grid className={styles.inputsWrapper}>
          <MoneyInput
            key="valor-min"
            label="Valor do prestador"
            value={localState?.providerValueStart || ''}
            style={{ fontSize: '16px' }}
            placeholder="R$ "
            onChange={e => handleChange(e.floatValue, 'providerValueStart')}
            className={styles.doubleInput}
            fullWidth
          />
          <Typography className={styles.dotDivision}>•</Typography>
          <MoneyInput
            key="valor-max"
            label=" "
            value={localState?.providerValueEnd || ''}
            style={{ fontSize: '16px', marginTop: '12px' }}
            className={styles.doubleInput}
            placeholder="R$ "
            onChange={e => handleChange(e.floatValue, 'providerValueEnd')}
            fullWidth
          />
        </Grid>
        <Grid className={styles.inputsWrapper}>
          <DatePicker
            key="paymentDateStart"
            label="Data de pagamento"
            value={localState?.paymentDateStart}
            onChange={value => handleChange(value, 'paymentDateStart')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            maxDate={localState?.paymentDateEnd || undefined}
            disableFuture
            mask="__/__/____"
          />
          <Typography className={styles.dotDivision}>•</Typography>
          <DatePicker
            key="paymentDateEnd"
            label=" "
            value={localState?.paymentDateEnd}
            onChange={value => handleChange(value, 'paymentDateEnd')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            disableFuture
            minDate={localState?.paymentDateStart || undefined}
            mask="__/__/____"
          />
        </Grid>
        <Grid className={styles.inputsWrapper}>
          <DatePicker
            key="solicitationDateStart"
            label="Data da solicitação"
            value={localState?.solicitationDateStart}
            onChange={value => handleChange(value, 'solicitationDateStart')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            maxDate={localState?.solicitationDateEnd || undefined}
            disableFuture
            mask="__/__/____"
          />
          <Typography className={styles.dotDivision}>•</Typography>
          <DatePicker
            key="solicitationDateEnd"
            label=" "
            value={localState?.solicitationDateEnd}
            onChange={value => handleChange(value, 'solicitationDateEnd')}
            className={styles.datePicker}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            invalidDateMessage="Insira uma data válida"
            disableFuture
            minDate={localState?.solicitationDateStart || undefined}
            mask="__/__/____"
          />
        </Grid>
        <Select
          label="Atualmente apto a pagar"
          defaultValue={localState?.ableToPay}
          value={localState?.ableToPay}
          onChange={handleInputChange}
          name="ableToPay"
          options={DEFAULT_SELECT_FILTER_OPTIONS}
        />
        <Select
          label="Bloqueadas"
          defaultValue={localState?.blocked}
          value={localState?.blocked}
          onChange={handleInputChange}
          name="blocked"
          options={DEFAULT_SELECT_FILTER_OPTIONS_WITH_NULL}
        />
        <Input
          key="batch"
          label="Lote"
          value={localState?.batch}
          placeholder="12345"
          style={{ fontSize: '16px' }}
          onChange={handleInputChange}
          fullWidth
          name="batch"
        />
        <Input
          key="batchError"
          label="Tipos de erro de lote"
          value={localState?.batchError}
          placeholder="Erro informado pelo banco"
          style={{ fontSize: '16px' }}
          onChange={handleInputChange}
          fullWidth
          name="batchError"
        />
        <Select
          label="Forma de pgto."
          defaultValue={localState?.paymentMethod}
          value={localState?.paymentMethod}
          name="paymentMethod"
          onChange={handleInputChange}
          options={SORTED_ANTICIPATIONS_PAYMENT_METHOD_OPTIONS}
        />
      </>
    )
  }, [selectedTab, localState, handleInputChange])

  return (
    <>
      <SwipeableDrawer
        component="section"
        anchor="right"
        open={open}
        onClose={handleFilterDrawer}
        className={styles.container}
        TransitionComponent={Transition}
      >
        <Grid className={styles.header}>
          <Grid className={styles.headerTitle}>
            <Grid className={styles.iconWrapper}>
              <FilterIcon color={Theme.Colors.Primary.Base} />
            </Grid>
            <Typography component="h1" variant="h5" className={styles.title}>
              Filtros
            </Typography>
          </Grid>
          <Button onClick={handleFilterDrawer} variant="ghost">
            <CloseCircle color={Theme.Colors.Primary.Base} className={styles.closeIcon} />
          </Button>
        </Grid>
        <Divider className={styles.divider} />
        <Grid className={styles.tabFilters}>
          <Grid
            className={classNames(styles.tab, selectedTab === 'main' && styles.selectedTab)}
            onClick={() => setSelectedTab('main')}
          >
            <Typography>Filtros principais</Typography>
          </Grid>
          <Grid
            className={classNames(styles.tab, selectedTab === 'more' && styles.selectedTab)}
            onClick={() => setSelectedTab('more')}
          >
            <Typography>Mais filtros</Typography>
          </Grid>
        </Grid>
        <Grid className={styles.content}>
          {selectedTab === 'main' && MainFilters}
          {selectedTab === 'more' && MoreFilters}
        </Grid>

        <Grid className={styles.buttonsContainer}>
          <Button variant="ghost" color="primary" onClick={cleanFilters}>
            Limpar filtros
          </Button>
          <Button
            variant="primary"
            color="orange"
            disabled={!localState?.isValid}
            onClick={handleApplyFilters}
          >
            Aplicar filtro
          </Button>
        </Grid>
      </SwipeableDrawer>
    </>
  )
}

export default Filters
