import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  content: {
    padding: spacing(0, 20, 16, 20),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(24),
    maxHeight: '',
  },
  title: {
    color: '#1671ED !important',
    fontSize: '1.600rem !important',
    fontWeight: 'bold !important',
    lineHeight: '2.400rem !important',
    letterSpacing: '-0.010rem !important',
  },
  container: {
    display: 'grid',
    gridRowGap: spacing(16),
    alignItems: 'center',
    width: '100%',
  },
  description: {
    borderSizing: 'border-box',
    border: '1px solid #E3E3E3',
    padding: '16px',
    borderRadius: '4px',
    overflowWrap: 'break-Word',
  },
  info: {
    display: 'flex',
    width: '100%',
  },
  box: {
    gap: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid #E3E3E3`,
    borderRadius: '5px',
    padding: '30px 20px',
  },
  textContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    marginLeft: spacing(20),
  },
  iconButton: {
    height: '80px',
    width: '80px',
  },
}))
