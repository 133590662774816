import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: '"acceptsWarranty warrantyMonths" "visitHappened ."',
    columnGap: spacing(80),
    rowGap: spacing(16),
    minWidth: 500,
  },
  acceptsWarranty: {
    gridArea: 'acceptsWarranty',
  },
  warrantyMonths: {
    gridArea: 'warrantyMonths',
  },
  visitHappened: {
    gridArea: 'visitHappened',
  },
}))

export const inputStyle = {
  fontSize: `${Theme.Typography.FontSize.XXSmall}px`,
}
