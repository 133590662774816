import { schema } from 'normalizr'
import { List, Map } from 'immutable'

import Model from './model'

class Budget extends Model({
  acceptsInstallment: undefined,
  acceptsWarranty: undefined,
  agencyCompletionPercentage: undefined,
  anotherReasonForRefusal: undefined,
  approved: undefined,
  budgetApprovalConfirmationPictures: List(),
  blockFinanceTransfers: undefined,
  budgetItems: List(),
  budgetPayer: undefined,
  budgetParent: undefined,
  budgetPictures: List(),
  cancelServiceOrder: undefined,
  comfortableAmount: undefined,
  counterProposalAccepted: undefined,
  createdAt: undefined,
  coverageValue: undefined,
  datetimeExecutionBudgetApproved: undefined,
  datetimeExecutionScheduled: null,
  deadlineStart: undefined,
  diagnosis: undefined,
  discount: undefined,
  displacementValue: undefined,
  estimatedTradesmanTotal: undefined,
  executionProof: undefined,
  id: undefined,
  installments: undefined,
  invoice: undefined,
  isOriginal: undefined,
  isRevised: undefined,
  laborValue: undefined,
  oldTradesmanPrice: undefined,
  observations: undefined,
  personPaying: undefined,
  personPayingCpf: undefined,
  personPayingEmail: undefined,
  personPayingName: undefined,
  personPayingWhatsapp: undefined,
  priceAgency: undefined,
  priceRefera: undefined,
  priceTotal: undefined,
  proposedSolution: undefined,
  proposeOpened: undefined,
  reasonForRefusal: undefined,
  referaCompletionPercentage: undefined,
  requestedChangeServiceProvider: undefined,
  refused: undefined,
  released: undefined,
  serviceOrder: undefined,
  stage: undefined,
  status: undefined,
  totalPriceTradesman: undefined,
  totalPrice: undefined,
  tradesman: Map(),
  variationAccepted: undefined,
  visitHappened: undefined,
  warrantyMonths: undefined,
  personPayingAuth: undefined,
  reasonDevolution: undefined,
  refusalMoreDetails: undefined,
  reasonForCancelServiceOrder: undefined,
  withholdingTax: undefined,
  withholdingTaxValue: undefined,
  financialIncome: undefined,
  financialIncomeValue: undefined,
  maxInstallmentsAllowed: undefined,
  maxTaxAllowed: undefined,
  priceServiceProvider: undefined,
  totalPriceServiceProvider: undefined,
  liquidValue: undefined,
}) {}

const budgetNewSchema = new schema.Entity(
  'results',
  {},
  {
    processStrategy: entity => new Budget(entity),
  }
)

const budgetSchema = [budgetNewSchema]

export { budgetSchema, Budget as default }
