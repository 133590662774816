import React, { useMemo } from 'react'
import { Tooltip, Typography } from '@material-ui/core'
import isAfter from 'date-fns/isAfter'
import { Danger as InfoDangerIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import classnames from 'classnames'

import useRolePermission from '_hooks/use-role-permission'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'
import { formatDate } from '_utils/helpers'
import { USER_ROLES, USER_ROLES_LABEL } from '_utils/user'

import useStyles from './styles'

const Situation = ({
  deadline,
  actionResponsible: responsible,
  hasNotViewedPendingHelpRequest,
  isPaused,
}) => {
  const styles = useStyles()
  const { isAdmin, isTradesman } = useRolePermission()
  const isPauseServiceOrderActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.PAUSE_SERVICE_ORDER,
  })

  const isDelayed = useMemo(
    () => (deadline ? isAfter(new Date(), new Date(deadline)) : false),
    [deadline]
  )

  const formattedDeadline = useMemo(() => {
    if (!deadline) {
      return null
    }

    return formatDate(new Date(deadline))
  }, [deadline])

  const renderTitle = useMemo(() => {
    if (isPaused && isPauseServiceOrderActive) {
      return (
        <span>
          <Typography>Chamado pausado</Typography>

          {hasNotViewedPendingHelpRequest && (
            <Typography>
              <br />
              Novidades no(s) pedido(s) de ajuda.
            </Typography>
          )}
        </span>
      )
    }

    if (!formattedDeadline) {
      return (
        <span>
          <Typography>Não há prazo cadastrado </Typography>
          {isAdmin && USER_ROLES_LABEL[responsible] && (
            <Typography>Responsável: {USER_ROLES_LABEL[responsible]}</Typography>
          )}
        </span>
      )
    }

    return (
      <span>
        <Typography>{isDelayed ? 'Chamado atrasado' : 'Dentro do prazo'}</Typography>
        <br />
        <Typography>Prazo: {formattedDeadline}</Typography>
        {isAdmin && <Typography>Responsável: {USER_ROLES_LABEL[responsible]}</Typography>}
      </span>
    )
  }, [
    formattedDeadline,
    hasNotViewedPendingHelpRequest,
    isAdmin,
    isDelayed,
    isPaused,
    isPauseServiceOrderActive,
    responsible,
  ])

  const defaultIcon = useMemo(() => {
    const showDot = !isDelayed || (isTradesman && responsible !== USER_ROLES.TRADESMAN)

    if (isDelayed) {
      return (
        <span>
          <InfoDangerIcon
            className={styles.deadlineIcon}
            color={Theme.Colors.Red.Base}
            width={16}
          />
        </span>
      )
    }

    if (showDot) {
      return <span className={classnames(styles.dot, styles.greenDot)} />
    }

    return null
  }, [isDelayed, isTradesman, responsible, styles.deadlineIcon, styles.dot, styles.greenDot])

  const icon = useMemo(() => {
    const isTradesmanResponsible = responsible === USER_ROLES.TRADESMAN

    const showAlertDefault = isDelayed && !isPaused
    const showAlert = isTradesman ? showAlertDefault && isTradesmanResponsible : showAlertDefault

    if (showAlert) {
      return (
        <span>
          <InfoDangerIcon
            className={styles.deadlineIcon}
            color={Theme.Colors.Red.Base}
            width={16}
          />
        </span>
      )
    }

    const showGreenDot = (!isDelayed && !isPaused) || !deadline
    const showYellowDot = isTradesman && isPaused && hasNotViewedPendingHelpRequest
    const showGrayDot = isPaused
    const showDot = showGrayDot || showGreenDot || showYellowDot

    if (showDot) {
      return (
        <span
          className={classnames(styles.dot, {
            [styles.greenDot]: showGreenDot,
            [styles.yellowDot]: showYellowDot,
          })}
        />
      )
    }

    return null
  }, [
    deadline,
    hasNotViewedPendingHelpRequest,
    isDelayed,
    isPaused,
    isPauseServiceOrderActive,
    isTradesman,
    responsible,
    styles.deadlineIcon,
    styles.dot,
    styles.greenDot,
    styles.yellowDot,
  ])

  const renderIcon = useMemo(
    () => (isPauseServiceOrderActive ? icon : defaultIcon),
    [defaultIcon, icon, isPauseServiceOrderActive]
  )

  if ((isAdmin || isTradesman) && renderIcon) {
    return <Tooltip title={renderTitle}>{renderIcon}</Tooltip>
  }

  return null
}

export default React.memo(Situation)
