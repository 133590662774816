import React from 'react'
import { Trash } from '@refera/ui-icons'
import { Tooltip } from '@material-ui/core'
import IconButton from '_components/svg/icon-button'

export const BudgetServicesColumns = deleteCallback => [
  {
    field: 'serviceName',
    headerName: 'Serviço',
    width: 300,
    editable: false,
  },
  {
    field: 'gridDesc',
    headerName: 'Descrição na grid',
    flex: 1,
    editable: false,
  },
  {
    field: 'labor',
    headerName: 'Mão de obra',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'material',
    headerName: 'Materiais',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'quantity',
    headerName: 'Qtde.',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value
        ? Number(value).toLocaleString('pt-br', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })
        : '-',
  },
  {
    field: 'totalPrice',
    headerName: 'Preço final',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'actions',
    headerName: 'Ações',
    width: 150,
    editable: false,
    renderCell: ({ row }) => (
      <Tooltip title="Excluir serviço">
        <IconButton variant="ghost" onClick={event => deleteCallback(event, row)}>
          <Trash color="gray" />
        </IconButton>
      </Tooltip>
    ),
  },
]
