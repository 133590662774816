import { Record, Map, List } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { citySchema } from '_models/city'
import { bankSchema } from '_models/bank'
import { userSchema } from '_/models/user'
import { toSlug } from '_utils/helpers'

import {
  GET_CITIES,
  GET_STATES,
  GET_BANK_LIST,
  GET_CITIES_FILTERED,
  TRIGGER_SNACKBAR,
  GET_REASONS_TO_CANCEL,
  GET_REASONS_TO_CANCEL_SERVICE,
  GET_CSREFERA,
  GET_SECTORS_OPTIONS,
  GET_ARTICLES,
  TOGGLE_SHOW_CONTRACT_MODAL_MANAGER,
  GET_ALL_ROLES,
  GET_ALL_ROLE_GROUPS,
} from './actions'
import { stateSchema } from '_/models/state'

const INITIAL_STATE = new Record({
  articles: [],
  cities: Map(),
  states: Map(),
  banks: Map(),
  snackbarError: Map(),
  csrefera: Map(),
  cancel: List(),
  showContractModalManager: true,
  sectorsOptions: List(),
  roleList: List(),
  roleGroupList: List(),
})()

const utils = createReducer(INITIAL_STATE, {
  [GET_CITIES.FULFILLED]: (state, { payload }) => {
    const normalizeCity = normalize(payload.results, citySchema)
    return state.set('cities', Map(normalizeCity.entities.city))
  },
  [GET_STATES.FULFILLED]: (state, { payload }) => {
    const normalizeState = normalize(payload.results, stateSchema)
    return state.set('states', Map(normalizeState.entities.state))
  },
  [GET_ARTICLES.FULFILLED]: (state, { payload: { results } }) => {
    return state.set('articles', results)
  },
  [GET_CSREFERA.FULFILLED]: (state, { payload }) => {
    const normalizeCsrefera = normalize(payload.results, userSchema)
    return state.set('csrefera', Map(normalizeCsrefera.entities.user))
  },
  [GET_SECTORS_OPTIONS.FULFILLED]: (state, { payload: { results } }) => {
    return state.set('sectorsOptions', results)
  },
  [GET_BANK_LIST.FULFILLED]: (state, { payload }) => {
    const normalizeBanks = normalize(payload.results, bankSchema)
    return state.set('banks', Map(normalizeBanks.entities.bank))
  },
  [GET_CITIES_FILTERED]: (state, { payload }) => {
    return state.set(
      'cities',
      Map(state.cities.filter(item => item.slug.includes(toSlug(payload))))
    )
  },
  [TRIGGER_SNACKBAR.FULFILLED]: (state, { meta }) => {
    return state.set('snackbarError', Map(meta.error))
  },
  [GET_REASONS_TO_CANCEL.FULFILLED]: (state, { payload: { results } }) => {
    return state.set(
      'cancel',
      List(results.map(value => ({ label: value.reason, value: value.uuid })))
    )
  },
  [GET_REASONS_TO_CANCEL_SERVICE.FULFILLED]: (state, { payload: { results } }) => {
    return state.set(
      'cancelReason',
      List(results.map(value => ({ label: value.reasons, value: value.id })))
    )
  },
  [TOGGLE_SHOW_CONTRACT_MODAL_MANAGER]: state => {
    return state.set('showContractModalManager', !state.showContractModalManager)
  },
  [GET_ALL_ROLES.FULFILLED]: (state, { payload: { results } }) => {
    return state.set('roleList', results)
  },
  [GET_ALL_ROLE_GROUPS.FULFILLED]: (state, { payload: { results } }) => {
    return state.set('roleGroupList', results)
  },
})

export default utils
