import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  svg: {
    height: `${spacing(30)} !important`,
    width: `${spacing(30)} !important`,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: spacing(60),
    width: spacing(60),
    height: spacing(60),
    borderRadius: '50%',
    backgroundColor: palette.green.main,
    color: palette.white,
    flexWrap: 'wrap',
    padding: 0,

    position: 'absolute',
    bottom: spacing(30),
    right: spacing(30),

    boxShadow: `2px 2px 8px 0px rgba(0, 0, 0, 0.40)`,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.green.main,
      opacity: 0.9,
    },

    [breakpoints.down('md')]: {
      bottom: spacing(20),
      right: spacing(20),
    },
  },
}))
