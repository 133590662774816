import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    '& .MuiDrawer-paper': {
      width: spacing(392),
      top: spacing(48),
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
  header: {
    padding: spacing(16, 10, 10, 32),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    width: spacing(32),
    height: spacing(32),
    fontSize: spacing(18),
    borderRadius: '50%',
    backgroundColor: Theme.Colors.Primary.Lightest,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacing(12),
  },
  icon: {
    width: spacing(16),
    height: spacing(16),
  },
  closeIcon: {
    width: spacing(24),
    height: spacing(24),
    fontSize: spacing(12),
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: Theme.Colors.Primary.Base,
    fontWeight: 500,
  },
  divider: {
    backgroundColor: Theme.Colors.Primary.Base,
  },
  tabFilters: {
    display: 'flex',
  },
  tab: {
    display: 'flex',
    height: spacing(56),
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    cursor: 'pointer',
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  selectedTab: {
    color: Theme.Colors.Primary.Base,
    borderBottom: `3px solid ${Theme.Colors.Primary.Base}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(24),
    padding: spacing(32),
    height: '84%',
    overflowY: 'auto',
    marginBottom: spacing(128),
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(24),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: spacing(24),
    position: 'fixed',
    bottom: 0,
    right: 0,
    backgroundColor: '#fff',
    height: spacing(88),
    width: spacing(392),
    padding: '24px 0',
  },
  inputsWrapper: {
    display: 'flex',
    gap: spacing(14),
  },
  label: {
    fontSize: '12px !important',
    overflowX: 'visible',
    width: '100%',
    color: Theme.Colors.Grayscale.OneThousand,
    fontWeight: '500 !important',
    marginBottom: '-12px !important',
  },
  dotDivision: {
    marginTop: spacing(32),
  },
  doubleInput: {
    width: '44%',
  },
  checkboxContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))
