const FORM_FIELD_NAME = {
  NAME: 'name',
  PROPOSED_SOLUTION: 'proposedSolution',
  BUDGET_TEMPLATE_SERVICES: 'budgetTemplateServices',
  DIAGNOSIS: 'diagnosis',
  WARRANTY_MONTHS: 'warrantyMonths',
  ACCEPTS_WARRANTY: 'acceptsWarranty',
}

export const ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
  RESET_STATE: 'RESET_STATE',
  FILL_STATE: 'fillState',
  NAME: 'name',
}

export const INITIAL_STATE = {
  [FORM_FIELD_NAME.NAME]: '',
  [FORM_FIELD_NAME.PROPOSED_SOLUTION]: '',
  [FORM_FIELD_NAME.BUDGET_TEMPLATE_SERVICES]: [],
  [FORM_FIELD_NAME.DIAGNOSIS]: '',
  [FORM_FIELD_NAME.WARRANTY_MONTHS]: '',
  [FORM_FIELD_NAME.ACCEPTS_WARRANTY]: true,

  page: 0,
  pageSize: 10,
  order: '-name',
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...payload,
      }

    case ACTIONS.FILL_STATE: {
      let result = { ...state }
      Object.keys(payload).forEach(field => {
        result = { ...result, [field]: payload[field] }
      })
      return result
    }
    case ACTIONS.RESET_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
