import { defineAction } from '_utils/redux'
import * as notificationsServices from '_services/notifications'

export const GET_NOTIFICATIONS = defineAction('GET_NOTIFICATIONS')
export const READ_NOTIFICATION = defineAction('READ_NOTIFICATION')

export const getNotifications = () => (dispatch, getState) => {
  const data = notificationsServices.getNotificationsByUserId(getState().user.authToken)
  dispatch({
    type: GET_NOTIFICATIONS,
    payload: data,
  })
  return data
}

export const readNotifications = notificationId => (dispatch, getState) => {
  const data = notificationsServices.readNotificationsById(
    getState().user.authToken,
    notificationId
  )
  dispatch({
    type: READ_NOTIFICATION,
    payload: data,
    meta: notificationId,
  })
  return data
}
