import * as paymentAdvanceServices from '_services/paymentAdvance'
import { defineAction } from '_utils/redux'

export const GET_PAYMENT_ADVANCE = defineAction('GET_PAYMENT_ADVANCE')

export const getPaymentAdvance = serviceOrderId => (dispatch, getState) => {
  const data = paymentAdvanceServices.getPaymentAdvance(getState().user.authToken, serviceOrderId)

  dispatch({
    type: GET_PAYMENT_ADVANCE.ACTION,
    payload: data,
  })
}
