import React, { useMemo, useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import {
  Typography,
  Dialog as MUIDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
} from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { useToast } from '_/hooks/use-toast'

import { updateAppointmentDateSchedule } from '_modules/service-orders/actions'

import Button from '_components/button'
import useStyles from './styles'

const ScheduleModal = ({ serviceOrder, serviceOrderId, open, onClose }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()

  const defaultDate = useMemo(() => {
    const newDate = serviceOrder?.visitSchedule ? new Date(serviceOrder?.visitSchedule) : ''
    const localDate = moment(newDate).local()

    return localDate
  }, [serviceOrder])

  const [selectedDate, setSelectedDate] = useState(defaultDate)

  const handleSubmit = () => {
    const payload = {
      dateAndTime: moment(selectedDate)?.format(),
      externalId: serviceOrder?.externalId && serviceOrder?.externalId,
      responsibleTeam: serviceOrder?.responsibleTeam && serviceOrder?.responsibleTeam,
      requestType: serviceOrder?.requestType && serviceOrder?.requestType,
    }
    dispatch(updateAppointmentDateSchedule(serviceOrderId, payload))
      .then(
        () => showToast({ type: 'success' }),
        () => showToast({ type: 'error' })
      )
      .then(() => onClose())
  }

  return (
    <MUIDialog open={open} classes={{ paper: styles.dialog }}>
      <DialogTitle className={styles.dialogTitle}>
        <Box mt={5} />
        <Typography variant="h6">Dados da visita</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={styles.dialogContent}>
        <KeyboardDateTimePicker
          ampm={false}
          label={
            <Typography className={styles.text}>
              Visita para orçamento em<b className={styles.required}> *</b>
            </Typography>
          }
          value={selectedDate}
          onChange={setSelectedDate}
          format="dd/MM/yyyy' às' HH:mm"
          placeholder="DD/MM/AAAA' às' HH:MM"
          emptyLabel="DD/MM/AAAA' às' HH:MM"
          className={styles.selectPlaceholder}
          InputLabelProps={{
            style: { marginTop: '-10px' },
            shrink: true,
          }}
          InputProps={{
            style: { borderBottomColor: '1px solid #E3E3E3' },
          }}
        />
      </DialogContent>
      <DialogActions className={styles.dialogAction}>
        <Button color="red" variant="outlined" className={styles.button} onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={() => handleSubmit()}
          type="submit"
        >
          Salvar
        </Button>
      </DialogActions>
    </MUIDialog>
  )
}

export default ScheduleModal
