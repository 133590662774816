import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  chip: {
    background: palette.green.light,
    color: 'white',
    borderColor: palette.green.light,
    fontSize: spacing(12),
    lineHeight: spacing(16),
  },
  incomplete: {
    background: palette.yellow.main,
    color: palette.gray[100],
    borderColor: palette.yellow.main,
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))
