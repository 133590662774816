import React from 'react'

import { navigate } from '@reach/router'
import { Button } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'

import { ROUTES } from '_/utils/constants'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

export function PageHeader({ isLoading }) {
  const styles = useStyles()
  const { checkIfUserDoesNotPermission } = useRolePermission()

  const createReferaUserButtonClick = () => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.REFERA_USERS, PERMISSIONS_ACTIONS.ADD)) {
      return null
    }

    return navigate(`${ROUTES.REFERA_USERS}/novo`)
  }

  return (
    <HeaderTitle title="Usuários da Refera" backButtonAction={() => navigate('/')}>
      <div className={styles.actionButtons}>
        <Button
          style={{ width: '100px' }}
          onClick={createReferaUserButtonClick}
          disabled={isLoading}
        >
          Novo
        </Button>
      </div>
    </HeaderTitle>
  )
}
