import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'grid',
    gridRowGap: spacing(24),
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  label: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: palette.gray[64],
  },
  modal: {
    width: '100vw',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
  },
  contentState: {
    width: '100%',
    marginBottom: spacing(15),
  },
  contentCity: {
    width: '100%',
  },
}))
