import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  modal: {
    position: 'absolute',
    top: '15%',
    left: '36%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  modalContent: {
    width: '100%',
    fontSize: '14px',
    display: 'grid',
    columnGap: '32px',
    gap: '4px',
    gridTemplateColumns: '1fr 1fr',
  },
  modalColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
    width: '200px',
  },
  error: {
    color: palette.custom.red,
    fontSize: spacing(10),
  },
  dateContent: {
    marginTop: '-3.6px !important',
  },
}))
