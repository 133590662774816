import React, { useMemo, useCallback, useState } from 'react'
import { Button, Dialog, Loader } from '@refera/ui-web'
import { Grid } from '@material-ui/core'
import { useParams, navigate, useLocation } from '@reach/router'
import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import { InfoCircle as InfoCircleIcon } from '@refera/ui-icons'
import HeaderTitle from '_components/header-title'
import useStyles from './styles'
import { BUTTONS_ACTION, STATUS_RATING } from '_/utils/constants/service-order'
import useRolePermission from '_/hooks/use-role-permission'
import { FinishServiceModal } from '../modals/FinishServiceModal'
import { useToast } from '_/hooks/use-toast'
import {
  cancelExecutionSchedule,
  getServiceOrder,
  cancelExecution,
  closeServiceOrder,
  postSendRating,
} from '_/modules/service-orders/actions'
import { useDispatch, useSelector } from 'react-redux'

import { getStepStatusLog } from '_/modules/budget/actions'
import moment from 'moment'
import { ScheduleExecutionModal } from '_/views/service-order-details/components/modals/schedule-service-execution'
import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { RateServiceModal } from '../modals/RateServiceModal'
import { InvoiceModal } from '../modals/InvoiceModal'
import { getBudgetByIdSelector } from '_/modules/budget/selectors'
import Tips from '_/components/tips'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { CancelServiceFinish } from '_/views/service-order-details/components/modals/cancel-service-finish'

const DEFAULT_MODAL_PROPS = { isOpen: false, message: '', icon: '', type: 'info', action: () => {} }

const ExecutionViewHeader = ({ executionProofs, isEditing, invoices }) => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const { serviceOrderId, budgetId } = useParams()

  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const { search, state } = useLocation()
  const [finishServiceModalOpen, setFinishServiceModalOpen] = useState(false)
  const [scheduleExecutionModalOpen, setScheduleExecutionModalOpen] = useState(false)
  const [cancelServiceFinishModalOpen, setCancelServiceFinishModalOpen] = useState(false)
  const [rateServiceModalOpen, setRateServiceModalOpen] = useState(false)
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false)
  const {
    isIntermediary,
    isAdmin,
    isTradesman,
    checkUserPermission,
    isSAAS,
    checkIfUserDoesNotPermission,
  } = useRolePermission()
  const [modalProps, setModalProps] = useState(DEFAULT_MODAL_PROPS)

  const { showToast } = useToast()

  const [loading, setIsLoading] = useState(false)

  const token = useMemo(() => search.split('verification_token=')[1], [search])
  const showTip = useMemo(() => !!serviceOrder?.datetimeFinished, [serviceOrder?.datetimeFinished])

  const canFinishService = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.FINISH_SERVICE,
  })

  const canCancelFinishService = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.CANCEL_SERVICE_FINISH,
  })

  const canFinishServiceOrder = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.FINISH_SERVICE_ORDER,
  })

  const canCancelExecutionSchedule = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.CANCEL_EXECUTION_SCHEDULE,
  })

  const canScheduleServiceExecution = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.SCHEDULE_SERVICE_EXECUTION,
  })

  const canRateService = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.RATE_SERVICE,
  })

  const canSendInvoice = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.SEND_INVOICE,
  })

  const handleCloseModal = useCallback(() => {
    setModalProps(prev => ({ ...prev, isOpen: false }))
  }, [])

  /**
   *
   * @param {errorObject | boolean} error
   * @param {string} message
   */
  const handleActionCompletion = useCallback(
    (error = undefined, message = undefined) => {
      if (error || message) console.error({ error, message })

      showToast({ type: error ? 'error' : 'success', message })

      setIsLoading(false)
      handleCloseModal()
    },
    [handleCloseModal, showToast]
  )

  const fetchServiceOrderInfos = () => {
    dispatch(getServiceOrder(serviceOrderId))
    dispatch(getStepStatusLog(serviceOrderId))
  }

  const cancelFinishService = useCallback(
    async payload => {
      const formattedPayload = {
        ...payload,
        dateAndTimeFinish: payload.dateAndTimeFinish?.toISOString(),
      }

      setIsLoading(true)
      dispatch(cancelExecution(serviceOrderId, formattedPayload))
        .then(() => {
          setCancelServiceFinishModalOpen(false)
          fetchServiceOrderInfos()
          handleActionCompletion()
        })
        .catch(err => handleActionCompletion(err))
    },
    [isIntermediary, serviceOrderId, handleActionCompletion, fetchServiceOrderInfos]
  )

  const handleBackNavigation = useCallback(() => {
    if (isTradesman) {
      navigate(state?.from ?? `/detalhes-do-chamado/${serviceOrderId}`)
    } else {
      navigate(`/chamado/${serviceOrderId}/orcamento/${budgetId}`)
    }
  }, [budgetId, serviceOrderId, token, isTradesman])

  const cancelScheduleExecution = useCallback(() => {
    dispatch(cancelExecutionSchedule(serviceOrderId))
      .then(() => {
        setModalProps(prev => ({ ...prev, isOpen: false }))
        showToast({ type: 'success' })
        fetchServiceOrderInfos()
      })
      .catch(() => {
        setModalProps(prev => ({ ...prev, isOpen: false }))
        showToast({ type: 'error' })
      })
  }, [serviceOrderId, fetchServiceOrderInfos])

  const finishServiceOrder = useCallback(async () => {
    setIsLoading(true)
    dispatch(closeServiceOrder(serviceOrderId, { datetimeFinished: moment().toISOString() }))
      .then(() => {
        dispatch(getStepStatusLog(serviceOrderId))
          .then(() => handleActionCompletion())
          .catch(err => handleActionCompletion(err))
      })
      .catch(err => handleActionCompletion(err))
  }, [serviceOrderId, handleActionCompletion])

  const handleFinishService = useCallback(() => {
    if (isIntermediary) {
      showToast({
        message: 'Você não tem permissão para esta ação.',
        type: 'error',
      })
      return
    }

    setFinishServiceModalOpen(true)
  }, [isIntermediary])

  const handleScheduleExecutionButtonClick = useCallback(() => {
    if (isIntermediary) {
      showToast({
        type: 'error',
        message: 'Você não tem permissão para esta ação.',
      })
      return
    }

    setScheduleExecutionModalOpen(true)
  }, [isIntermediary])

  const handleCancelExecutionSchedule = useCallback(() => {
    if (isIntermediary) {
      showToast({
        type: 'error',
        message: 'Você não tem permissão para esta ação.',
      })
      return
    }
    setModalProps({
      isOpen: true,
      icon: InfoCircleIcon,
      type: 'error',
      subject: 'Você tem certeza que deseja cancelar o agendamento da execução?',
      labelApprove: 'Sim',
      labelCancel: 'Não',
      onApprove: cancelScheduleExecution,
      onCancel: handleCloseModal,
    })
  }, [isIntermediary, handleCloseModal])

  const handleCancelFinishServiceButtonClick = useCallback(
    payload => {
      setModalProps({
        isOpen: true,
        icon: InfoCircleIcon,
        type: 'error',
        title: 'Atenção!',
        subject: 'Você tem certeza que deseja executar esta ação?',
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onApprove: () => cancelFinishService(payload),
        onCancel: handleCloseModal,
      })
    },
    [cancelFinishService, handleCloseModal]
  )

  const handleFinishServiceOrderButtonClick = useCallback(() => {
    if (
      serviceOrder?.requesterRatingFeature &&
      serviceOrder?.lastStepLogDetails.get('statusRating') !== STATUS_RATING.SERVICE_APPROVED
    ) {
      return showToast({
        type: 'error',
        message:
          'Não é possível finalizar o chamado! Primeiro, é necessário a aprovação do serviço.',
      })
    }

    if (!executionProofs?.length || !invoices?.length) {
      return showToast({
        type: 'error',
        message:
          'Para finalizar um chamado, você deve primeiro inserir o arquivo de NF e fotos/vídeos do serviço executado.',
      })
    }

    if (isIntermediary) {
      return showToast({
        type: 'error',
        message: 'Você não tem permissão para esta ação.',
      })
    }

    return setModalProps({
      isOpen: true,
      icon: InfoCircleIcon,
      type: 'info',
      title: 'Deseja finalizar o chamado?',
      subject: 'Ao confirmar, você está informando a Refera que o chamado foi finalizado.',
      labelApprove: 'Confirmar',
      labelCancel: 'Cancelar',
      onApprove: finishServiceOrder,
      onCancel: handleCloseModal,
    })
  }, [budget, serviceOrder])

  const handleSendRating = useCallback(
    data => {
      handleCloseModal()

      if (
        checkIfUserDoesNotPermission(
          PERMISSIONS.RATE_SERVICE,
          PERMISSIONS_ACTIONS.ADD,
          'Não foi possível executar esta ação, tente novamente.'
        )
      ) {
        return
      }

      const { solution_approved: solutionApproved, negative_feedback: negativeFeedback } = data

      const isSolutionApproved = solutionApproved === 'Sim'
      const payload = {
        solutionApproved: isSolutionApproved,
      }

      if (!isSolutionApproved) {
        payload.negativeFeedback = negativeFeedback
      }

      setIsLoading(true)
      dispatch(postSendRating(serviceOrderId, payload))
        .then(() => {
          fetchServiceOrderInfos()
          showToast({ type: 'success' })
          setIsLoading(false)
          setRateServiceModalOpen(false)
        })
        .catch(err => {
          showToast({ type: 'error', message: err?.error_message })
          setIsLoading(false)
        })
    },
    [handleCloseModal, showToast, serviceOrderId, fetchServiceOrderInfos]
  )

  const handleRateService = useCallback(
    data => {
      const { solution_approved: solutionApproved } = data

      const modalMessage =
        solutionApproved === 'Sim'
          ? 'Você tem certeza que deseja aprovar o serviço do prestador pelo morador? Essa ação vai indicar que o serviço foi executado corretamente e tem a aprovação do morador.'
          : 'Você tem certeza que deseja reprovar o serviço do prestador pelo morador? Essa ação vai indicar que o serviço não foi executado corretamente e tem a reprovação do morador.'

      setModalProps({
        isOpen: true,
        icon: InfoCircleIcon,
        type: 'info',
        subject: modalMessage,
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onApprove: () => handleSendRating(data),
        onCancel: handleCloseModal,
      })
    },
    [handleCloseModal, handleSendRating]
  )

  return (
    <HeaderTitle title="Execução do serviço" backButtonAction={handleBackNavigation}>
      <Grid className={styles.buttonsContainer}>
        {canCancelExecutionSchedule && (
          <Button
            color="red"
            variant="secondary"
            onClick={() =>
              checkUserPermission({
                permission: PERMISSIONS.SCHEDULE_SERVICE_EXECUTION,
                permissionAction: PERMISSIONS_ACTIONS.ADD,
                functionAccess: handleCancelExecutionSchedule,
              })
            }
          >
            Cancelar agendamento da execução
          </Button>
        )}
        {canScheduleServiceExecution && (
          <Button
            color="primary"
            variant="secondary"
            onClick={() =>
              checkUserPermission({
                permission: PERMISSIONS.SCHEDULE_SERVICE_EXECUTION,
                permissionAction: PERMISSIONS_ACTIONS.ADD,
                functionAccess: handleScheduleExecutionButtonClick,
              })
            }
          >
            Agendar execução do serviço
          </Button>
        )}
        {canFinishService && (
          <Button
            color="primary"
            variant="primary"
            onClick={() =>
              checkUserPermission({
                permission: PERMISSIONS.END_SERVICE_CANCEL_ACTION,
                permissionAction: [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT],
                functionAccess: handleFinishService,
              })
            }
          >
            Finalizar serviço
          </Button>
        )}
        {canCancelFinishService && (
          <Button
            color="red"
            variant="secondary"
            onClick={() =>
              checkUserPermission({
                permission: PERMISSIONS.END_SERVICE_CANCEL_ACTION,
                permissionAction: [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT],
                functionAccess: () => setCancelServiceFinishModalOpen(true),
              })
            }
          >
            Cancelar finalização do serviço
          </Button>
        )}
        {(isAdmin || isSAAS) && canRateService && (
          <Button
            color="primary"
            variant="secondary"
            onClick={() =>
              checkUserPermission({
                permission: PERMISSIONS.RATE_SERVICE,
                permissionAction: PERMISSIONS_ACTIONS.ADD,
                functionAccess: () => setRateServiceModalOpen(true),
              })
            }
          >
            Avaliar serviço
          </Button>
        )}
        {canSendInvoice &&
          (showTip ? (
            <Tips component="invoice">
              <Button
                color="primary"
                variant="secondary"
                onClick={() =>
                  checkUserPermission({
                    permission: PERMISSIONS.INSERT_INVOICE,
                    permissionAction: PERMISSIONS_ACTIONS.ADD,
                    functionAccess: () => setInvoiceModalOpen(true),
                  })
                }
              >
                Nota Fiscal
              </Button>
            </Tips>
          ) : (
            <Button
              color="primary"
              variant="secondary"
              onClick={() =>
                checkUserPermission({
                  permission: PERMISSIONS.INSERT_INVOICE,
                  permissionAction: PERMISSIONS_ACTIONS.ADD,
                  functionAccess: () => setInvoiceModalOpen(true),
                })
              }
            >
              Nota Fiscal
            </Button>
          ))}

        {canFinishServiceOrder && (
          <Button
            color="primary"
            variant="primary"
            onClick={() =>
              checkUserPermission({
                permission: PERMISSIONS.FINISH_SERVICE_ORDER_CANCEL_ACTION,
                permissionAction: PERMISSIONS_ACTIONS.ADD,
                functionAccess: handleFinishServiceOrderButtonClick,
              })
            }
          >
            Finalizar chamado
          </Button>
        )}
      </Grid>
      {scheduleExecutionModalOpen && (
        <ScheduleExecutionModal
          key="ScheduleExecutionModal"
          open={scheduleExecutionModalOpen}
          onClose={() => setScheduleExecutionModalOpen(false)}
          onCancel={() => setScheduleExecutionModalOpen(false)}
        />
      )}
      {cancelServiceFinishModalOpen && (
        <CancelServiceFinish
          key="cancelFinishServiceModal"
          open={cancelServiceFinishModalOpen}
          onConfirm={handleCancelFinishServiceButtonClick}
          onCancel={() => setCancelServiceFinishModalOpen(false)}
        />
      )}
      {finishServiceModalOpen && (
        <FinishServiceModal
          key="finishServiceModal"
          open={finishServiceModalOpen}
          executionProofs={executionProofs}
          onClose={() => setFinishServiceModalOpen(false)}
          onCancel={() => setFinishServiceModalOpen(false)}
          requesterRatingFeature={serviceOrder?.requesterRatingFeature}
        />
      )}
      {rateServiceModalOpen && (
        <RateServiceModal
          key="rateServiceModal"
          open={rateServiceModalOpen}
          executionProofs={executionProofs}
          onClose={() => setRateServiceModalOpen(false)}
          onSubmit={handleRateService}
        />
      )}
      {invoiceModalOpen && (
        <InvoiceModal
          key="invoiceModal"
          open={invoiceModalOpen}
          onClose={() => setInvoiceModalOpen(false)}
          isEditing={isEditing}
        />
      )}

      <Dialog
        open={modalProps?.isOpen}
        icon={modalProps?.icon}
        type={modalProps?.type}
        subject={modalProps?.title}
        description={modalProps?.subject}
        onApprove={modalProps?.onApprove}
        labelApprove={modalProps?.labelApprove}
        labelCancel={modalProps?.labelCancel}
        onCancel={modalProps?.onCancel}
      />
      <Loader hasBackdrop open={loading} label="Aguarde..." />
    </HeaderTitle>
  )
}

export default ExecutionViewHeader
