import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  root: {
    padding: 0,

    '& .MuiPaper-root': {
      width: '100%',
    },
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  form: {
    width: '100%',
    flex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: spacing(75),
    width: '100%',
    padding: spacing(0, 71),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(20),
    padding: spacing(40),
    minHeight: spacing(50),
  },
  rootConfirm: {
    padding: 0,

    '& .MuiPaper-root': {
      padding: spacing(30, 20),
      width: '100%',
    },
  },
  actionConfirm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(20),
  },
  actionEdit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing(20),
    padding: spacing(0, 20),
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: Theme.Typography.FontSize.Large,
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  tableContainer: {
    flex: 1,
    width: '100%',
  },
  mainTable: {
    width: 'auto',
    height: 630,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  link: {
    textDecorationLine: 'underline',
    color: '#1671ED',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  },
}))
