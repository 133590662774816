import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  searchBar: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'end',
      padding: spacing(10, 24),
      fontSize: spacing(14),
      lineHeight: spacing(20),
      color: palette.gray[32],
    },
    '& .MuiInputBase-root': {
      borderRadius: spacing(24),
      height: spacing(40),
      background: 'white',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.gray[32],
    },
  },
}))
