import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    gridColumn: '1',
    position: 'relative',
    width: '100%',
    paddingTop: props => (props.isEditing ? spacing(160) : spacing(80)),
  },
  alert: {
    marginTop: 60,
  },

  mainInfo: {
    padding: spacing(32, 72),

    '& + &': {
      paddingTop: spacing(16, '!important'),
      paddingBottom: '0 !important',
    },
  },

  poolWarning: {
    paddingTop: spacing(16, '!important'),
    paddingBottom: 0,
  },

  divider: {
    color: palette.gray[12],
    margin: spacing(24, 0),
  },
  view: {
    display: 'grid',
    gridTemplateColumns: `minmax(${spacing(955)}, auto) ${spacing(328)}`,
    height: `calc(100vh - ${spacing(72)})`,
  },
  descriptionTitle: {
    marginBottom: spacing(24),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(20),
    padding: spacing(40, 20),
  },
  autocomplete: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: palette.gray[64],
      fontSize: spacing(24),
      flexWrap: 'wrap',
      display: 'flex',
      textAlign: 'center',
      fontWeight: 600,
      height: spacing(30),
    },

    '& .MuiInputBase-root': {
      paddingTop: spacing(20),
    },
  },
  switchLabel: {
    color: palette.gray[64],
    marginBottom: spacing(6),
    fontSize: spacing(12),
    width: 'max-content',
    display: 'block',
  },
  switchInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-13px',
    gap: '1px',
  },
}))
