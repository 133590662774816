import { schema } from 'normalizr'

import Model from './model'

class Bank extends Model({
  id: undefined,
  name: undefined,
  bankNumber: undefined,
}) {}

const bankNewSchema = new schema.Entity(
  'bank',
  {},
  {
    processStrategy: entity => new Bank(entity),
  }
)

const bankSchema = [bankNewSchema]

export { bankSchema, Bank as default }
