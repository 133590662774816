import humps from 'humps'
import * as widgetsServices from '_services/widgets'
import { defineAction } from '_utils/redux'
import { notEmptyPayload } from '_utils/helpers'

export const GET_SERVICE_ORDERS_COUNT_WIDGET = defineAction('GET_SERVICE_ORDERS_COUNT_WIDGET')
export const GET_WIDGETS_OPTIONS = defineAction('GET_WIDGETS_OPTIONS')
export const SET_WIDGETS_FILTER = defineAction('SET_WIDGETS_FILTER')
export const GET_WIDGET_RESULTS = defineAction('GET_WIDGET_RESULTS')

export const setWidgetsFilter = payload => dispatch =>
  dispatch({
    type: SET_WIDGETS_FILTER.ACTION,
    payload,
  })

export const getServiceOrdersCountWidgets = params => (dispatch, getState) => {
  const queryParams = humps.decamelizeKeys(notEmptyPayload(params))

  dispatch({
    type: GET_SERVICE_ORDERS_COUNT_WIDGET.ACTION,
    payload: widgetsServices.getServiceOrdersCountWidgets(getState().user.authToken)(queryParams),
  })
}

export const getWidgetsOptions = () => (dispatch, getState) => {
  const data = widgetsServices.getWidgetsOptions(getState().user.authToken)()
  dispatch({
    type: GET_WIDGETS_OPTIONS.ACTION,
    payload: data,
  })
  return data
}

export const getWidgetResults =
  ({ optionId, params }) =>
  (dispatch, getState) => {
    const queryParams = humps.decamelizeKeys(notEmptyPayload(params))
    const data = widgetsServices.getWidgetResults(getState().user.authToken)({
      optionId,
      params: queryParams,
    })
    dispatch({
      type: GET_WIDGET_RESULTS.ACTION,
      payload: data,
    })
    return data
  }
