import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { Snackbar as MuiSnackbar, SnackbarContent, IconButton } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'

import { usePrevious } from '_hooks/use-previous'
import { TRIGGER_SNACKBAR } from '_modules/utils/actions'
import useBoolean from '_hooks/use-toggle'
import { materialStyles } from '_components/snackbar/material-ui'
import { snackBarMessageSelector } from '_modules/utils/selectors'

export const SNACKBAR_VARIANT = {
  ERROR: 'error',
  SUCCESS: 'success',
}

const variantIcon = {
  [SNACKBAR_VARIANT.SUCCESS]: CheckCircleIcon,
  [SNACKBAR_VARIANT.ERROR]: ErrorIcon,
}

const DURATION = 5000

/* 
  To use this programmatically (with the triggerSnackbar action)
  you need to pass the TRIGGER_SNACKBAR action has the linked action
  to trigger messages like this: 
      <Snackbar
        action={[
          ...
          TRIGGER_SNACKBAR.ACTION,
        ]}
      />
  then dispatch triggerSnackbar when need with a error object eg:({error: string})
*/

const Snackbar = ({ className, action, isLocalDispatch, ...other }) => {
  const classes = materialStyles()
  const [showSnackbar, handleSnackbar] = useBoolean()
  const snackBarErrorMessage = useSelector(snackBarMessageSelector)

  const hasError = useSelector(state => {
    if (typeof action === 'string') {
      return !!state.error.get(action)
    }

    return action.reduce((acc, curr) => {
      return acc || !!state.error.get(curr)
    }, false)
  })

  const errorMessage = useSelector(state => {
    if (typeof action === 'string') {
      return !!state.error.get(action)
    }

    return action.reduce((acc, curr) => {
      return acc || state.error.getIn([curr, 'error_message'])
    }, false)
  })

  const isLoading = useSelector(state => {
    if (typeof action === 'string') {
      return !!state.loading.get(action)
    }

    return action.reduce((acc, curr) => {
      return acc || !!state.loading.get(curr)
    }, false)
  })

  const colorVariant = useMemo(
    () =>
      hasError || snackBarErrorMessage.size ? SNACKBAR_VARIANT.ERROR : SNACKBAR_VARIANT.SUCCESS,
    [hasError, snackBarErrorMessage.size]
  )

  const snackbarText = useMemo(() => {
    if (errorMessage && !snackBarErrorMessage.first()) {
      return errorMessage
    }
    if ((hasError || snackBarErrorMessage.size) && snackBarErrorMessage.get('error')) {
      return snackBarErrorMessage.get('error')
    }
    if (hasError || snackBarErrorMessage.size) {
      return 'Não foi possível efetuar essa operação!'
    }
    return 'Operação efetuada com sucesso!'
  }, [errorMessage, hasError, snackBarErrorMessage])

  const Icon = hasError || snackBarErrorMessage.size ? variantIcon.error : variantIcon.success

  const renderMessage = useMemo(() => {
    return (
      <span id="client-snackbar" className={classes.message}>
        {<Icon />}
        {snackbarText}
      </span>
    )
  }, [classes.message, snackbarText])

  const prevIsLoading = usePrevious(isLoading)

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      handleSnackbar(true)
    }
  }, [handleSnackbar, isLoading, prevIsLoading])

  useEffect(() => {
    if (isLocalDispatch) {
      handleSnackbar(true)
    }
  }, [handleSnackbar, isLocalDispatch])

  const renderAnchorOrigin = useMemo(
    () => ({
      vertical: 'bottom',
      horizontal: 'left',
    }),
    []
  )

  const renderAction = useMemo(() => {
    return [
      <IconButton key="close" aria-label="close" color="inherit" onClick={handleSnackbar}>
        <CloseIcon className={classes.icon} />
      </IconButton>,
    ]
  }, [classes.icon, handleSnackbar])

  return (
    <MuiSnackbar
      anchorOrigin={renderAnchorOrigin}
      open={showSnackbar}
      autoHideDuration={DURATION}
      onClose={handleSnackbar}
    >
      <SnackbarContent
        className={classnames(classes[colorVariant], className)}
        aria-describedby="client-snackbar"
        message={renderMessage}
        action={renderAction}
        {...other}
      />
    </MuiSnackbar>
  )
}

Snackbar.propTypes = {
  className: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  isLocalDispatch: PropTypes.bool,
}

Snackbar.defaultProps = {
  className: '',
  action: TRIGGER_SNACKBAR.ACTION,
  isLocalDispatch: false,
}

export default React.memo(Snackbar)
