import { createReducer } from '_/utils/redux'
import { GET_BUSINESSFRONTS, GET_USER_AGENCY_FLOWS } from './actions'

const { List, Record } = require('immutable')

const INITIAL_STATE = new Record({
  businessFrontList: List(),
})()

export default createReducer(INITIAL_STATE, {
  [GET_BUSINESSFRONTS.FULFILLED]: (state, { payload }) => {
    return state.set('businessFrontList', payload.results)
  },
  [GET_USER_AGENCY_FLOWS.FULFILLED]: (state, { payload }) => {
    return state.set('businessFrontList', payload)
  },
})
