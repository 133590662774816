import { createReducer } from '_/utils/redux'
import { GET_SET_ITEMS, GET_MESSAGING } from './actions'
import { Record } from 'immutable'

const INITIAL_STATE = new Record({
  setItems: {},
  messageList: {},
})()

export default createReducer(INITIAL_STATE, {
  [GET_SET_ITEMS.FULFILLED]: (state, { payload }) => {
    return state.set('setItems', payload)
  },
  [GET_MESSAGING.FULFILLED]: (state, { payload }) => {
    return state.update('messageList', () => payload)
  },
})
