import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  page: {
    height: `calc(100vh - ${spacing(48)})`,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  container: {
    padding: spacing(24, 32),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing(32),
    overflow: 'auto',
    flex: 1,

    [breakpoints.down('md')]: {
      maxWidth: '100vw',
    },

    '&::-webkit-scrollbar': {
      width: '6px',
      height: 0,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '100vh',
      backgroundColor: palette.gray[300],
      margin: spacing(5),
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.gray[300],
      borderRadius: '100vh',
      border: `3px solid grey`,
    },
  },
  headerTitle: {
    marginTop: spacing(48),
  },
  content: {
    boxSizing: 'border-box',
    minHeight: `100%`,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: spacing(16),
    width: '100%',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: spacing(8),
    marginTop: spacing(16),
    marginBottom: spacing(8),
  },
  title: {
    color: palette.grey[900],
  },
  textfield: {
    width: '100%',
    maxWidth: spacing(200),
  },
  radio: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
  },
  modal: {
    width: '100%',
  },
  errorMessage: {
    color: '#C90000 !important',
    margin: 0,
    marginTop: spacing(8),
    fontSize: spacing(11),
    fontFamily: 'Roboto',
  },
  actionsModal: {
    padding: spacing(20),
    gap: spacing(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
