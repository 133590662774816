/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react'

// Components
import { Grid } from '@material-ui/core'
import { Modal, Button, Loader } from '@refera/ui-web'
import Select from '_components/common/select'

import useStyles from '../styles'
import { asaasSpreadSheetTypes } from '_/views/finance/utils/constants'

export const AsaasDownloadSpreadsheet = ({ open, close, onConfirm, isLoading }) => {
  const styles = useStyles()
  const [spreadsheetType, setSpreadsheetType] = useState('')

  return (
    <>
      <Loader hasBackdrop open={isLoading} />
      <Modal
        className={styles.modal}
        title="Modelo de planilha"
        onBackdropClick={close}
        onClose={close}
        open={open}
        actionsButtons={
          <Grid className={styles.modalButtons}>
            <Button color="red" variant="secondary" onClick={close}>
              Fechar
            </Button>
            <Button
              disabled={!spreadsheetType || isLoading}
              color="primary"
              variant="primary"
              onClick={() => onConfirm({ spreadsheetType })}
            >
              Ok
            </Button>
          </Grid>
        }
      >
        <Grid className={styles.wrapper}>
          <Select
            label="Planilha"
            required
            defaultValue=""
            value={spreadsheetType}
            onChange={event => setSpreadsheetType(event.target.value)}
            options={asaasSpreadSheetTypes}
            selectStyles={styles.select}
          />
        </Grid>
      </Modal>
    </>
  )
}
