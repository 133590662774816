import React, { useState, useEffect } from 'react'
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'

const RadioSelect = ({ label, name, options, defaultValue, required, style, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  useEffect(() => {
    setSelectedValue(defaultValue)
  }, [defaultValue])

  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <Grid item style={style}>
      <FormControl component="fieldset" required={required}>
        <FormLabel component="legend" color="default">
          {label}
        </FormLabel>
        <RadioGroup aria-label={name} name={name} value={selectedValue} onChange={handleChange}>
          <Grid container spacing={3} direction="row">
            {options.map(option => (
              <Grid item key={option.name}>
                <FormControlLabel
                  value={option.name}
                  control={<Radio color="default" />}
                  label={option.description}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
    </Grid>
  )
}

export default React.memo(RadioSelect)
