import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  box: {
    maxWidth: spacing(450),
    width: spacing(450),
    boxSizing: 'border-box',
  },
  header: {
    padding: spacing(23, 32),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: spacing(12),
    alignItems: 'center',
  },
  rejectIcon: {
    height: spacing(32),
    width: spacing(32),
  },
  title: {
    fontWeight: 'bold',
    color: palette.gray[80],
  },
  errorText: {
    marginTop: spacing(4),
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Red.Base,
  },
  close: {
    height: spacing(14),
    width: spacing(14),
  },
  divider: {
    backgroundColor: palette.gray[12],
  },
  content: {
    padding: spacing(32),
    boxSizing: 'border-box',
  },
  input: {
    marginTop: spacing(12),
  },
  label: {
    fontSize: '1.4rem',
    lineHeight: '2rem',
    color: palette.gray[64],
    paddingTop: spacing(40),
    paddingBottom: spacing(10),
  },
  radioGroup: {
    flexDirection: 'row',
  },
  radio: {
    paddingRight: spacing(30),
  },
  action: {
    padding: spacing(0, 32),
    display: 'inline-grid',
    gridTemplateColumns: `repeat(2, ${spacing(90)})`,
    gridColumnGap: spacing(16),
  },
  refusedContent: {
    padding: spacing(48, 88),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  refusedProposalIcon: {
    width: spacing(128),
    height: spacing(128),
  },
  cancelledTitle: {
    fontWeight: 500,
    color: palette.gray[80],
    padding: spacing(32, 0, 12),
  },
  textTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '30px',
    fontWeight: '500',
    fontSize: '13px',
    color: '#141414',
    fontFamily: 'Roboto',
  },
  select: {
    width: '100%',
    padding: '6px 0px',
    fontSize: '16px',
    marginTop: '10px',
  },
  textInput: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
  textInputN: {
    display: 'block',
    width: '100%',
    height: '132px',
  },
  selectPlaceholder: {
    fontSize: '16px',
  },
  subjectField: {
    width: '200px',
    maxHeight: '200px',
    fontSize: '20px',
    color: '#B4B4B4',

    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
}))
