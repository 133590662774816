import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { AppBar } from '@material-ui/core'

import Svg from '_components/svg'
import IconLogo from '_assets/svgs/refera-logo.svg'

import useStyles from './styles'

const Header = ({ className }) => {
  const styles = useStyles()

  return (
    <AppBar elevation={0} position="fixed" className={classnames(styles.header, className)}>
      <Svg className={styles.logo} icon={IconLogo} />
    </AppBar>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

Header.defaultProps = {
  className: '',
}

export default React.memo(Header)
