import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, TextField } from '@material-ui/core'
import { Modal, Button, Toast, Alert } from '@refera/ui-web'
import { useForm } from 'react-hook-form'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { userSelector } from '_modules/authentication/selectors'
import { getServiceOrder, updateAppointmentDateSchedule } from '_modules/service-orders/actions'
import { formatAddress } from '_utils/helpers'

import useStyles from './styles'
import { getStepStatusLog } from '_/modules/budget/actions'

const SuggestDateModal = ({ open, onCancel }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const formRef = useRef()

  const serviceOrder = useSelector(currentServiceOrderSelector)
  const tradesman = useSelector(userSelector)

  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' })

  const fullAddress = useMemo(
    () => formatAddress(serviceOrder?.property, false, true, false),
    [serviceOrder?.property]
  )
  const defaultValues = useMemo(
    () => ({
      message:
        `Olá, sou o prestador ${tradesman?.companyName}, parceiro da Refera.\n\n` +
        `Estou entrando em contato com você para sugerir um horário para ` +
        `realizar a visita de orçamento sobre o chamado ${serviceOrder?.id}.\n` +
        `Esse chamado está vinculado a intermediária ${serviceOrder?.agency.get('name')} ` +
        `e refere-se ao imóvel localizado no endereço abaixo.\n` +
        `${fullAddress}\n\n` +
        `Gostaria de saber se você poderia me receber no dia:`,
    }),
    [fullAddress, serviceOrder?.agency.get('name'), serviceOrder?.id, tradesman?.companyName]
  )
  const { errors, getValues, handleSubmit, register } = useForm({ defaultValues })

  const sendWhatsApp = useCallback(
    message => {
      const phone = `${serviceOrder.contactNumber?.toString().slice(1)}`
      const encodedText = window.encodeURIComponent(message)
      window.open(`https://web.whatsapp.com/send?phone=${phone}&text=${encodedText}`, '_blank')
    },
    [serviceOrder]
  )

  const onUpdateAppointmentDateScheduleSuccessful = useCallback(
    message => {
      setToast({
        isOpen: true,
        message: 'Registro salvo com sucesso.',
        type: 'success',
      })
      dispatch(getServiceOrder(serviceOrder?.id))
      dispatch(getStepStatusLog(serviceOrder?.id))
      sendWhatsApp(message)
    },
    [sendWhatsApp]
  )

  const onUpdateAppointmentDateScheduleRejected = useCallback(() => {
    setToast({
      isOpen: true,
      message: 'Não foi possível executar esta ação, tente novamente.',
      type: 'error',
    })
  }, [])

  const handleCloseToast = useCallback(() => {
    const cancel = toast.type === 'success'

    setToast(prevToast => ({
      ...prevToast,
      isOpen: false,
    }))

    if (cancel) {
      onCancel()
    }
  }, [toast.type])

  const onSubmit = useCallback(
    handleSubmit(() => {
      const message = getValues('message')

      const payload = {
        schedulingInWhatsapp: true,
      }
      dispatch(updateAppointmentDateSchedule(serviceOrder.id, payload))
        .then(() => {
          onUpdateAppointmentDateScheduleSuccessful(message)
        })
        .catch(() => {
          onUpdateAppointmentDateScheduleRejected()
        })
    }),
    [dispatch, serviceOrder.id]
  )

  const handleConfirmClick = useCallback(() => {
    handleSubmit(onSubmit)()
  }, [handleSubmit, onSubmit])

  return (
    <>
      {toast.type !== 'success' && (
        <Modal
          className={styles.modal}
          title="Sugerir horário"
          onBackdropClick={onCancel}
          onClose={onCancel}
          open={open}
          actionsButtons={
            <Grid className={styles.modalButtons}>
              <Button color="red" variant="secondary" onClick={onCancel}>
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="primary"
                disabled={errors?.message}
                onClick={handleConfirmClick}
              >
                Confirmar
              </Button>
            </Grid>
          }
        >
          <form onSubmit={onSubmit} ref={formRef} className={styles.modalContent}>
            <Grid className={styles.modalContent}>
              <Typography className={styles.text}>
                Complete a mensagem abaixo, que preparamos para você enviar ao cliente. Sugira uma
                nova data e horário para visitação e clique em Confirmar para ser direcionado ao seu
                WhatsApp.
              </Typography>
              <Typography className={styles.label}>Mensagem</Typography>
              <TextField
                className={styles.textArea}
                variant="outlined"
                required
                name="message"
                inputRef={register({ required: true })}
                fullWidth
                multiline
              />
            </Grid>
          </form>
        </Modal>
      )}
      <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert severity={toast.type} title={toast.message} onClose={handleCloseToast} />
      </Toast>
    </>
  )
}

export default SuggestDateModal
