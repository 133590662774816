import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  modal: {
    position: 'absolute',
    zIndex: 4,
    top: '40%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentContainer: {
    width: 'auto',
    height: spacing(300),
  },
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `${spacing(1)} solid ${Theme.Colors.Grayscale.Twelve}`,
    paddingBottom: spacing(16),
  },
  headerText: {
    fontWeight: '400',
    minWidth: spacing(140),
    fontSize: spacing(14),
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `${spacing(1)} solid ${Theme.Colors.Grayscale.Twelve}`,
    paddingBottom: spacing(16),
    paddingTop: spacing(16),
    textWrap: 'nowrap',
  },
  listText: {
    fontWeight: '300',
    width: spacing(140),
    fontSize: spacing(14),
    display: 'flex',
  },

  titleBlue: {
    color: Theme.Colors.Primary.Base,
    cursor: 'grab',
    width: '100%',

    '&:active': {
      cursor: 'grabbing',
    },
  },
}))
