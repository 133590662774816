export const STATUS_FIELDS = [
  {
    value: 'pending',
    label: 'Pendentes',
  },
  {
    value: 'paid',
    label: 'Pagos',
  },
  {
    value: 'late',
    label: 'Atrasados',
  },
  {
    value: '',
    label: 'Todos',
  },
]

export const ANTICIPATION_OPTIONS = [
  {
    value: '',
    label: '(Todos)',
  },
  {
    value: 'true',
    label: 'Com antecipação',
  },
  {
    value: 'false',
    label: 'Sem antecipação',
  },
]

export const defaultToast = { isOpen: false, type: '', message: '' }

export const defaultInfoDialog = {
  isOpen: false,
  icon: '',
  type: '',
  subject: '',
  description: '',
}

const REPROVED_ANTICIPATION_MESSAGE =
  'Este Pedido de Antecipação já foi recusado pela Refera e não precisa/pode ser excluído para fins de informação histórica.'
const APPROVED_ANTICIPATION_MESSAGE =
  'Este Pedido de Antecipação já foi aprovado pela Refera e está em processo de pagamento. Se quiser cancelar, contate a Refera imediatamente.'
const PAID_ANTICIPATION_MESSAGE =
  'Este Pedido de Antecipação já foi pago pela Refera e não pode mais ser cancelado.'
const NON_ANTICIPATING_INSTALLMENT_MESSAGE =
  'Este pedido de antecipação não pode ser cancelado pois as parcelas vinculadas a ele estão em etapas que não permitem essa operação. Se quiser cancelar o pedido de antecipação, contate a Refera imediatamente'
const ERROR_GET_ANTICIPATIONS_MESSAGE = 'Ocorreu um erro ao buscar antecipações.'
export const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro ao realizar a operação.'
export const DEFAULT_SUCCESS_MESSAGE = 'Ação executada com sucesso.'

export const antiticipationMessage = status => {
  switch (status) {
    case 'reproved':
      return REPROVED_ANTICIPATION_MESSAGE
    case 'approved':
      return APPROVED_ANTICIPATION_MESSAGE
    case 'paid':
      return PAID_ANTICIPATION_MESSAGE
    case 'get':
      return ERROR_GET_ANTICIPATIONS_MESSAGE
    case 'non_anticipating_installment':
      return NON_ANTICIPATING_INSTALLMENT_MESSAGE
    default:
      return DEFAULT_ERROR_MESSAGE
  }
}
