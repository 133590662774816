import { useEffect, useState } from 'react'

const CEP_LENGTH = 8

const useCepAutoComplete = (value, isEditing = true) => {
  const [data, setData] = useState({})
  const numberCep = value && value.replace(/[^0-9]+/g, '')

  useEffect(() => {
    if (numberCep && numberCep.length === CEP_LENGTH && isEditing) {
      fetch(`https://viacep.com.br/ws/${numberCep}/json/`)
        .then(response => response.json())
        .then(values => {
          const payload = {
            city: values.localidade,
            neighborhood: values.bairro,
            address: values.logradouro,
            cep: numberCep,
            state: values.uf,
          }
          setData(payload)
        })
        .catch(() => {
          setData({})
        })
    } else {
      setData({})
    }
  }, [numberCep, isEditing])
  return data
}

export default useCepAutoComplete
