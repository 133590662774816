/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { ArrowLeft as ArrowIcon } from '@refera/ui-icons'
import { Alert, Toast } from '@refera/ui-web'

import Svg from '_/components/svg'
import CoinsSvg from '_assets/svgs/coins-banner.svg'

import useStyles from './styles'
import { ANTICIPATIONS_MORE_INFO_URL, ROUTES } from '_/utils/constants'
import { navigate } from '@reach/router'
import { useSelector } from 'react-redux'
import { anticipationsBannerInfoSelector } from '_/modules/finance/selectors'

const defaultToast = { isOpen: false, title: '', severity: '' }

const TradesmanAnticipationBanner = () => {
  const styles = useStyles()
  const anticipationsBannerInfo = useSelector(anticipationsBannerInfoSelector)
  const [toast, setToast] = useState(defaultToast)

  // Substituir quando liberar financeiro para prestador em prod
  // const handleGoToAnticipations = () => navigate(`${ROUTES.INSTALLMENTS_RECEIPTS}`)

  const handleGoToAnticipations = () =>
    setToast({
      isOpen: true,
      title:
        'Essa opção não está disponível no momento, e será disponibilizada na próxima atualização da Refera.',
      severity: 'error',
    })

  const handleCloseToast = () => setToast(defaultToast)

  return (
    <Grid className={styles.container}>
      <Grid className={styles.messageWrapper}>
        <Typography className={styles.containerMessage}>
          Você pode antecipar {anticipationsBannerInfo?.anticipationValue} direto na sua conta no
          dia {anticipationsBannerInfo?.anticipationDate}.
          <a
            href={ANTICIPATIONS_MORE_INFO_URL}
            target="_blank"
            rel="noreferrer"
            className={styles.moreInfoLink}
          >
            Saiba mais
          </a>
        </Typography>
        <Typography className={styles.actionButtonWrapper}>
          <button type="button" className={styles.actionButton} onClick={handleGoToAnticipations}>
            Clique aqui para antecipar
            <ArrowIcon color="#FFF" fontSize={24} className={styles.arrowIcon} />
          </button>
        </Typography>
      </Grid>
      <Grid className={styles.imageWrapper}>
        <Svg icon={CoinsSvg} className={styles.coinsImage} />
      </Grid>
      {toast.isOpen && (
        <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert {...toast} onClose={handleCloseToast} />
        </Toast>
      )}
    </Grid>
  )
}

export default TradesmanAnticipationBanner
