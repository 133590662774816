import React, { useCallback } from 'react'
import { Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useParams, navigate } from '@reach/router'

import IconButton from '_components/svg/icon-button'
import Profile from '_assets/icons/ic-profile.svg'
import Trash from '_assets/icons/ic-trash.svg'
import { USER_ROLES, USER_FIELDS, USER_FIELDS_LABEL } from '_utils/user'
import { phoneNumberMask } from '_utils/helpers'

import useStyles from './styles'

const USER_INFO = [USER_FIELDS.NAME, USER_FIELDS.EMAIL, USER_FIELDS.PHONE]

const UserInfo = ({ user, index, handleSelectedUser, handleDeleteUser, isProvider }) => {
  const styles = useStyles()
  const { agencyId } = useParams()

  const handleUser = useCallback(() => {
    navigate(`/imobiliaria/${agencyId}/usuario/${user?.id}`)
  }, [handleSelectedUser, index, user])

  const handleDelete = useCallback(() => {
    handleDeleteUser(user)
  }, [handleDeleteUser, user])

  return (
    <Grid className={styles.wrapper}>
      <button className={styles.details} type="button" onClick={handleUser}>
        {USER_INFO.map(name => (
          <Grid key={`user-${index}-${name}`}>
            <Typography className={styles.title} component="p" variant="body2">
              {USER_FIELDS_LABEL[name]}
            </Typography>
            <Typography className={styles.value} component="p" variant="body1">
              {name === USER_FIELDS.PHONE ? phoneNumberMask(user?.[name]) : user?.[name]}
            </Typography>
          </Grid>
        ))}
      </button>
      {!isProvider && (
        <Grid container className={styles.actions}>
          <IconButton
            icon={Profile}
            color={USER_ROLES.CS_MANAGER === user.role ? 'primary' : 'inherit'}
            tooltip={USER_ROLES.CS_MANAGER === user.role ? 'CS Gestor' : 'CS Aprovador'}
          />
          <IconButton icon={Trash} onClick={handleDelete} />
        </Grid>
      )}
    </Grid>
  )
}

UserInfo.propTypes = {
  user: PropTypes.shape({ role: PropTypes.string }).isRequired,
  index: PropTypes.number.isRequired,
  handleSelectedUser: PropTypes.func.isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
  isProvider: PropTypes.bool,
}

UserInfo.defaultProps = {
  isProvider: false,
}

export default React.memo(UserInfo)
