import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Button } from '@material-ui/core'

import Modal from './detail-modal'
import useStyles from './styles'

const TradesmanReasonToRefuseDetailModal = ({ handleModal, tradesmanRefuseDetail }) => {
  const styles = useStyles()

  return (
    <Modal title="Mais Detalhes" handleModal={handleModal} hideActionButtons>
      <Grid className={styles.container}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Dm Sans',
            fontStyle: 'normal',
            fontSize: '12px',
            color: '#141414',
          }}
        >
          Descrição
        </Typography>
        <Typography className={styles.description}>{tradesmanRefuseDetail}</Typography>
      </Grid>
      <Button className={styles.button} onClick={handleModal}>
        Fechar
      </Button>
    </Modal>
  )
}

TradesmanReasonToRefuseDetailModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  tradesmanRefuseDetail: PropTypes.string,
}

TradesmanReasonToRefuseDetailModal.defaultProps = {
  tradesmanRefuseDetail: '',
}

export default React.memo(TradesmanReasonToRefuseDetailModal)
