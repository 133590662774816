export const BENEFITS_LIST = [
  {
    text: 'Orçamento completo com material e mão de obra: ',
    description:
      'Nossos orçamentos são completos e incluem todos os custos do serviço, incluindo material e mão de obra. Isso garante que você saiba exatamente quanto vai pagar antes de contratar o serviço.',
  },
  {
    text: 'Garantia total do serviço: ',
    description:
      'Todos os serviços prestados pela Refera são garantidos por 90 dias. Isso significa que, se houver algum problema com o serviço, nós o corrigimos gratuitamente.',
  },
  {
    text: 'Parcelamento do serviço em até 10x vezes: ',
    description:
      'Oferecemos parcelamento do serviço em até 10x vezes sem juros, para facilitar o pagamento.',
  },
  {
    text: 'Prestadores de elite selecionados pela Refera: ',
    description:
      'Trabalhamos com uma rede de prestadores de elite selecionados pela Refera. Esses prestadores são qualificados e experientes, e estão comprometidos em prestar um serviço de excelência.',
  },
  {
    text: 'Time de suporte para te ajudar: ',
    description:
      'Temos um time de suporte dedicado para te ajudar em todas as etapas do processo, desde a solicitação do orçamento até a execução do serviço.',
  },
]
