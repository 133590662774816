/* eslint-disable no-lone-blocks */
import React from 'react'
import { TextField, Typography } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'

import useStyles from './styles'

// Core
import Theme from '@refera/ui-core'

// Components
import { Input, IconButton } from '@refera/ui-web'

// Icons
import { MinusCirlce } from '@refera/ui-icons'

export const InputFieldMask = ({
  label,
  name,
  mask,
  control,
  errors,
  rules = {},
  onBlur,
  placeholder = '',
  defaultValue = '',
  isToAddTelephone = false,
  removeTelephone,
  onChange,
  ...props
}) => {
  const styles = useStyles()
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        as={
          <InputMask
            mask={mask}
            maskChar=""
            placeholder={placeholder}
            error={!!errors[`${name}`]}
            fullWidth
            {...props}
          >
            {inputProps => (
              <div className={styles.inputField}>
                <Input
                  label={label}
                  style={{ fontSize: '1.4rem' }}
                  required={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={errors[`${name}`]?.message}
                  {...inputProps}
                />
                {isToAddTelephone && (
                  <IconButton
                    variant="ghost"
                    className={styles.iconBtn}
                    onClick={() => removeTelephone(name)}
                  >
                    <MinusCirlce color={Theme.Colors.Red.Base} />
                  </IconButton>
                )}
              </div>
            )}
          </InputMask>
        }
      />
      {!!errors[`${name}`] && (
        <Typography style={{ display: errors ? 'none' : 'block' }} className={styles.errorMessage}>
          {errors[`${name}`]?.message}
        </Typography>
      )}
    </>
  )
}

export const InputField = ({
  label,
  name,
  errors,
  inputRef,
  type = 'text',
  placeholder = '',
  defaultValue = '',
  field = false,
  disabled = false,
  ...props
}) => {
  // "field" IS USED TO CREATE A SPECIAL TEXTFIELD WITH MANUAL LABEL SHRINKING
  // A FORM DATA VALUE SHOULD BE PASSED AS FIELD, SUCH AS data.name
  const styles = useStyles()
  if (field) {
    return (
      <TextField
        label={
          <Typography variant="body1" style={{ display: 'inline-flex' }}>
            {label}
          </Typography>
        }
        name={name}
        type={type}
        InputLabelProps={{ required: false, shrink: !!field }}
        inputRef={inputRef}
        defaultValue={defaultValue}
        fullWidth
        placeholder={placeholder}
        disabled={disabled}
        error={!!errors[`${name}`]}
        {...props}
      />
    )
  }

  return (
    <>
      <TextField
        label={
          <Typography variant="body1" style={{ display: 'inline-flex' }}>
            {label}
          </Typography>
        }
        name={name}
        type={type}
        InputLabelProps={{ required: false }}
        inputRef={inputRef}
        defaultValue={defaultValue}
        fullWidth
        placeholder={placeholder}
        disabled={disabled}
        error={!!errors[`${name}`]}
        helperText={errors[`${name}`]?.message || null}
        FormHelperTextProps={{
          classes: { root: styles.errorMessage },
        }}
        {...props}
      />
      {!!errors[`${name}`] && (
        <Typography style={{ display: errors ? 'none' : 'block' }} className={styles.errorMessage}>
          {errors[`${name}`]?.message}
        </Typography>
      )}
    </>
  )
}

export const MultilineField = ({
  label,
  name,
  data,
  errors,
  inputRef,
  type = 'text',
  placeholder = '',
  defaultValue = '',
  disabled = false,
  rows = 3,
}) => {
  return (
    <TextField
      label={
        <Typography variant="body1" style={{ display: 'inline-flex' }}>
          {label}
        </Typography>
      }
      multiline
      minRows={rows || 1}
      maxRows={1000}
      name={name}
      type={type}
      InputLabelProps={{ required: false }}
      inputRef={inputRef}
      defaultValue={defaultValue}
      fullWidth
      placeholder={placeholder}
      disabled={disabled}
      error={!!errors[`${name}`]}
      helperText={`${data.description?.length || 0} / 3000` || null}
      FormHelperTextProps={{
        style: { textAlign: 'right', marginRight: '5px' },
      }}
    />
  )
}
