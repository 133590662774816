import { defineAction } from '_utils/redux'
import * as classificationServices from '_services/classification'

export const GET_CLASSIFICATION = defineAction('GET_CLASSIFICATION')
export const GET_CLASSIFICATIONS_FOR_SERVICE_ORDER = defineAction(
  'GET_CLASSIFICATIONS_FOR_SERVICE_ORDER'
)
export const GET_CLASSIFICATION_BY_ID = defineAction('GET_CLASSIFICATION_BY_ID')
export const GET_CLASSIFICATION_COLOR = defineAction('GET_CLASSIFICATION_COLOR')
export const GET_CLASSIFICATION_TYPE = defineAction('GET_CLASSIFICATION_TYPE')
export const POST_CLASSIFICATION = defineAction('POST_CLASSIFICATION')
export const PATCH_CLASSIFICATION = defineAction('PATCH_CLASSIFICATION')
export const DELETE_CLASSIFICATION = defineAction('DELETE_CLASSIFICATION')

export const getClassification = params => async (dispatch, getState) => {
  const queryParams = {
    pageSize: params?.pageSize || getState().classification.size,
    order: params?.order || getState().classification.params?.order,
    orderStatus: params?.orderStatus || getState().classification.orderStatus,
    ...params,
  }

  const data = await classificationServices.getClassification(getState().user.authToken)(
    queryParams
  )
  const type = params?.serviceOrder
    ? GET_CLASSIFICATIONS_FOR_SERVICE_ORDER.ACTION
    : GET_CLASSIFICATION.ACTION

  dispatch({
    type,
    payload: async () => data,
    meta: queryParams,
  })

  return data
}

export const getClassificationColor = () => (dispatch, getState) => {
  const data = classificationServices.getClassificationColor(getState().user.authToken)
  dispatch({
    type: GET_CLASSIFICATION_COLOR.ACTION,
    payload: data,
  })
}

export const getClassificationType = () => (dispatch, getState) => {
  const data = classificationServices.getClassificationType(getState().user.authToken)
  dispatch({
    type: GET_CLASSIFICATION_TYPE.ACTION,
    payload: data,
  })
}

export const getClassificationById = classificationId => (dispatch, getState) => {
  const data = classificationServices.getClassificationById(getState().user.authToken)(
    classificationId
  )

  dispatch({
    type: GET_CLASSIFICATION_BY_ID.ACTION,
    payload: data,
  })

  return data
}

export const postClassification = payload => (dispatch, getState) => {
  const data = classificationServices.postClassification(getState().user.authToken)(payload)

  dispatch({
    type: POST_CLASSIFICATION.ACTION,
    payload: data,
  })

  return data
}

export const patchClassification = (classificationId, payload) => (dispatch, getState) => {
  const data = classificationServices.patchClassification(getState().user.authToken)(
    classificationId,
    payload
  )

  dispatch({
    type: PATCH_CLASSIFICATION.ACTION,
    payload: data,
  })

  return data
}

export const deleteClassification = classificationId => (dispatch, getState) => {
  const data = classificationServices.deleteClassification(getState().user.authToken)(
    classificationId
  )

  dispatch({
    type: DELETE_CLASSIFICATION.ACTION,
    payload: data,
  })

  return data
}
