import React, { useCallback, useEffect, useReducer, useState } from 'react'

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Slide,
  Typography,
  Collapse,
} from '@material-ui/core'

import { useDispatch } from 'react-redux'
import { useParams } from '@reach/router'
import { getVipClients, createVipClient, updateVipClient } from '_/modules/agencyVip/actions'
import { useToast } from '_/hooks/use-toast'

import Button from '_components/button'
import TextFieldComponent from '_components/textfield'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { parseToNumber, validationCNPJ, validationCPF } from '_/utils/helpers'

import { INITIAL_STATE, ACTIONS, FORM_FIELD_NAME, reducer } from '../reducer'
import { cpfCnpjMask, ERRORS } from '../../finance/payer-data/utils'
import { Alert } from '@refera/ui-web'
import { AlertTitle } from '@material-ui/lab'
import useStyles from './styles'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const validateCPFCNPJ = value => {
  let message = ''
  if (parseToNumber(value).length <= 11 && !validationCPF(value)) {
    message = ERRORS.INVALID_CPF
  }
  if (
    parseToNumber(value).length > 11 &&
    parseToNumber(value).length <= 14 &&
    !validationCNPJ(value)
  ) {
    message = ERRORS.INVALID_CNPJ
  }

  return message
}

const VipClientsForm = ({ closeModal, client, onCancel, initialState = INITIAL_STATE }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [localState, localDispatch] = useReducer(reducer, initialState)
  const [loading, setLoading] = useState(false)
  const [maskedValue, setMaskedValue] = useState(initialState[FORM_FIELD_NAME.CPF_CNPJ] || '')
  const [error, setError] = useState({ isOpen: false, message: '' })
  const [isChecked, setIsChecked] = useState(initialState[FORM_FIELD_NAME.ISACTIVE])
  const [isVipChecked, setIsVipChecked] = useState(initialState[FORM_FIELD_NAME.ISVIP])
  const [forbiddenContactChecked, setForbiddenContactChecked] = useState(
    initialState[FORM_FIELD_NAME.FORBIDDENCONTACT]
  )
  const { showToast } = useToast()
  const { agencyId } = useParams()

  const onCancelModal = useCallback(() => {
    localDispatch({ type: ACTIONS.RESET_STATE })
    onCancel()
  }, [])

  const onChangeCheckbox = useCallback(({ target: { name, checked } }) => {
    localDispatch({ type: ACTIONS.UPDATE_CHECKBOX, payload: { name } })
    setIsChecked(checked)
  }, [])

  const onChangeVipCheckbox = useCallback(({ target: { name, checked } }) => {
    localDispatch({ type: ACTIONS.UPDATE_VIP_CHECKBOX, payload: { name } })
    setIsVipChecked(checked)
  }, [])

  const onChangeForbiddenContactCheckbox = useCallback(({ target: { name, checked } }) => {
    localDispatch({ type: ACTIONS.UPDATE_FORBIDDEN_CONTACT_CHECKBOX, payload: { name } })
    setForbiddenContactChecked(checked)
  }, [])

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      let updatedValue = value
      if (name === FORM_FIELD_NAME.CPF_CNPJ) {
        updatedValue = cpfCnpjMask(value)
        setMaskedValue(updatedValue)
      }
      localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { [name]: value } })
    },
    [localDispatch]
  )

  const handleCPFCNPJHasErrors = useCallback(
    value => {
      const message = validateCPFCNPJ(value)
      if (message.length > 0) {
        setError({
          isOpen: true,
          message,
        })
        return true
      }
      setError({
        isOpen: false,
        message: '',
      })
      return false
    },
    [setError]
  )

  useEffect(() => {
    if (client) {
      const { cpfCnpj, isActive, isVip, forbiddenContact } = client
      const payload = {
        [FORM_FIELD_NAME.CPF_CNPJ]: cpfCnpj,
        [FORM_FIELD_NAME.ISACTIVE]: isActive,
        [FORM_FIELD_NAME.ISVIP]: isVip,
        [FORM_FIELD_NAME.FORBIDDENCONTACT]: forbiddenContact,
      }

      localDispatch({ type: ACTIONS.FILL_STATE, payload })
      setMaskedValue(cpfCnpj)
      setIsChecked(isActive)
      setIsVipChecked(isVip)
      setForbiddenContactChecked(forbiddenContact)
    }
  }, [client])

  const handleErrorOnSubmit = useCallback(
    err => {
      setLoading(prevState => !prevState)
      let message = ''
      if (err?.error_message) {
        message = err?.error_message
      } else {
        message = 'Não foi possível executar esta ação, tente novamente.'
      }
      showToast({ type: 'error', message })
    },
    [loading]
  )

  const onSuccessSubmit = useCallback(() => {
    localDispatch({ type: ACTIONS.RESET_STATE })
    closeModal()
    showToast({ type: 'success' })
    dispatch(
      getVipClients({
        ...localState,
        page: localState.page + 1,
        agency: agencyId,
      })
    )
  }, [])

  const handleCloseError = () => {
    setError({ ...error, isOpen: false })
  }

  const handleSubmit = useCallback(() => {
    if (handleCPFCNPJHasErrors(maskedValue)) return setLoading(true)
    const payload = {
      ...localState,
      agency: agencyId,
    }
    if (client) {
      const updatedPayload = {
        ...payload,
      }
      dispatch(updateVipClient(client?.id, updatedPayload))
        .then(onSuccessSubmit)
        .catch(err => handleErrorOnSubmit(err))
    } else {
      dispatch(createVipClient(payload))
        .then(onSuccessSubmit)
        .catch(err => handleErrorOnSubmit(err))
    }
    return true
  }, [client, maskedValue, localState])

  return (
    <>
      <Dialog
        open
        maxWidth="xl"
        disableEscapeKeyDown
        onClose={closeModal}
        PaperComponent={PaperComponent}
        TransitionComponent={Transition}
        className={styles.dialog}
      >
        <form>
          <DialogTitle className={styles.title}>Cliente VIP</DialogTitle>
          <DialogContent className={styles.container}>
            <Grid>
              <Collapse in={error?.isOpen}>
                <Alert severity="info" onClose={handleCloseError}>
                  <AlertTitle>{ERRORS.INVALID_FORM}</AlertTitle>
                  {error?.message}
                </Alert>
              </Collapse>
              <Grid className={styles.labelContainer}>
                <Typography className={styles.label}>
                  CPF/CNPJ do responsável
                  <Typography className={styles.required}> *</Typography>
                </Typography>
              </Grid>
              <TextFieldComponent
                isCpfCnpj
                name={FORM_FIELD_NAME.CPF_CNPJ}
                onChange={onChange}
                value={maskedValue}
                required
                placeholder="000.000.000-00"
                className={styles.inputSize}
                InputProps={{
                  style: { fontSize: 16, height: 40, marginTop: 4 },
                  inputProps: { maxLength: 18, minLength: 13 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <FormControlLabel
              className={styles.checkbox}
              required
              onChange={onChangeVipCheckbox}
              checked={isVipChecked}
              control={
                <Checkbox
                  name={FORM_FIELD_NAME.ISVIP}
                  style={{
                    transform: 'scale(1.2)',
                    color: '#1671ED',
                  }}
                />
              }
              label={
                <Typography variant="h4" color="textSecondary">
                  É vip
                </Typography>
              }
            />
            <FormControlLabel
              className={styles.checkbox}
              required
              onChange={onChangeForbiddenContactCheckbox}
              checked={forbiddenContactChecked}
              control={
                <Checkbox
                  name={FORM_FIELD_NAME.FORBIDDENCONTACT}
                  style={{
                    transform: 'scale(1.2)',
                    color: '#1671ED',
                  }}
                />
              }
              label={
                <Typography variant="h4" color="textSecondary">
                  Contato proibido
                </Typography>
              }
            />
            <FormControlLabel
              className={styles.checkbox}
              required
              onChange={onChangeCheckbox}
              checked={isChecked}
              control={
                <Checkbox
                  name={FORM_FIELD_NAME.ISACTIVE}
                  style={{
                    transform: 'scale(1.2)',
                    color: '#1671ED',
                  }}
                />
              }
              label={
                <Typography variant="h4" color="textSecondary">
                  Ativo
                </Typography>
              }
            />
          </DialogContent>
          <DialogActions className={styles.dialogAction}>
            <Button
              className={styles.button}
              onClick={onCancelModal}
              color="red"
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              className={styles.button}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              // disabled={loading}
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

VipClientsForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default React.memo(VipClientsForm)
