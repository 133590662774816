/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'

import { Grid, Tooltip, IconButton } from '@material-ui/core'

import { Menu, Typography, Badges } from '@refera/ui-web'
import { Notification, DirectboxNotif } from '@refera/ui-icons'
import Theme from '@refera/ui-core'

import { getNotifications } from '_/modules/notifications/actions'
import { notificationSelector } from '_/modules/notifications/selector'
import { useDispatch, useSelector } from 'react-redux'
import NotificationOptions from './components/notificationOptions'
import useStyles from './styles'

const Notifications = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const notificationList = useSelector(notificationSelector).filter(
    notification => !notification?.datetimeReading
  )

  const [anchorEl, setAnchorEl] = useState(null)
  const [emptyList, setEmptyList] = useState(true)

  const handleClickNotifications = event => {
    setAnchorEl(event.currentTarget)
    setEmptyList(!notificationList.length)
    dispatch(getNotifications())
  }

  useEffect(() => {
    dispatch(getNotifications())
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getNotifications())
    }, 3600000) // ADJUSTS in Notification TIME to 1 Hour - It is temporary

    setEmptyList(!notificationList.length)
    return () => clearInterval(interval)
  }, [notificationList])

  return (
    <>
      <Tooltip title="Notificações" placement="bottom">
        <IconButton onClick={handleClickNotifications}>
          <Badges
            className={notificationList?.length > 99 ? styles.bigBadge : styles.badge}
            badgeContent={notificationList?.length}
            max={99}
          >
            <Notification className={styles.iconButton} color={Theme.Colors.Grayscale.Zero} />
          </Badges>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            marginTop: 20,
            marginLeft: '-60px',
            width: '456px',
            minHeight: '100px',
          },
        }}
      >
        <Grid className={styles.container}>
          <Typography className={styles.title}>Notificações</Typography>

          {!emptyList ? (
            <Grid className={styles.wrapper}>
              {notificationList.map(notification => (
                <NotificationOptions key={notification.id} notification={notification} />
              ))}
            </Grid>
          ) : (
            <Grid className={styles.emptyWrapper}>
              <Grid className={styles.emptyIcon}>
                <DirectboxNotif fontSize={30} color={Theme.Colors.Primary.Base} />
              </Grid>
              <Typography className={styles.emptyDescription}>
                Você não tem nenhuma nova notificação.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Menu>
    </>
  )
}

export default Notifications
