import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px 2% 0 34px',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '16px',
  },
  accordionWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: '16px',

    [breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  accordionItem: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100px',
    gap: '8px',
    marginRight: '16px',
  },
  accordionLabel: {
    fontSize: '12px !important',
    color: Theme.Colors.Grayscale.ThirtyTwo,
    textWrap: 'nowrap',
  },
  accordionValue: {
    fontSize: '14px !important',
    color: Theme.Colors.Grayscale.SixtyFour,
  },
}))
