import React, { useMemo } from 'react'
import { Grid, Select, MenuItem, InputLabel, Typography } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import useStyles from './styles'

const SelectField = ({
  id,
  name,
  defaultValue,
  label,
  disabled,
  control,
  required = false,
  items,
  emptyItem = false,
  defaultSelectStyle = true,
}) => {
  const styles = useStyles()

  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  return (
    <>
      <Grid container direction="column" className={defaultSelectStyle && styles.selectDefault}>
        <InputLabel shrink id={id} className={styles.inputLabel}>
          {label}
          {required && <Typography />}
        </InputLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          as={
            <Select
              name={name}
              control={control}
              id={id}
              className={styles.select}
              MenuProps={menuProps}
              disabled={disabled}
              required={required}
              defaultValue={defaultValue}
            >
              {emptyItem && (
                <MenuItem key="empty" value="" className={styles.selectEmpty} selected>
                  Selecione
                </MenuItem>
              )}
              {items?.map(item => (
                <MenuItem key={item?.status} value={item?.status}>
                  {item?.option}
                </MenuItem>
              ))}
            </Select>
          }
        />
      </Grid>
    </>
  )
}

export default SelectField
