import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  container: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  introductionContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginTop: spacing(48),
    gap: 16,
    [breakpoints.down('576')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  fullRow: {
    gridColumn: '1 / 3',
    justifySelf: 'center',
    [breakpoints.down('576')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  leftColumn: {
    justifySelf: 'end',
  },
  rightColumn: {
    justifySelf: 'start',
  },
  card: {
    width: 368,
    padding: 16,
    marginTop: spacing(5),
    border: `1px solid ${palette.gray[12]}`,
    borderRadius: 8,
    display: 'grid',
    gridTemplateColumns: '60px auto',
    alignItems: 'center',
    [breakpoints.down('576')]: {
      width: '100%',
    },
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: palette.gray[80],
    fontFamily: 'DM Sans, sans-serif',
  },
  cardContent: {
    color: palette.text.secondary,
    gridColumn: '2 / 3',
    alignSelf: 'start',
  },
  image: {
    width: 60,
    height: 60,
    marginTop: spacing(32),
  },
  title: {
    marginTop: spacing(16),
    fontSize: 24,
    color: palette.primary.main,
    fontWeight: 500,
    marginBottom: spacing(24),
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: spacing(24),
    },
  },
  dateTime: {
    marginTop: spacing(11),
    color: palette.text.secondary,
  },
  userIcon: {
    width: 32,
    height: 32,
  },
  section: {
    width: 752,
    marginTop: 24,
    [breakpoints.down('576')]: {
      width: '100%',
    },
  },
  sectionContent: {
    padding: 16,
    marginTop: 12,
    border: `1px solid ${palette.gray[12]}`,
    borderRadius: 8,
    color: palette.text.secondary,
    [breakpoints.down('576')]: {
      width: '100%',
    },
  },
  budget: {
    marginTop: 24,
  },
  productContainer: {
    display: 'grid',
    gridTemplateColumns: '560px 1fr',
    rowGap: 8,
    columnGap: 16,
    color: palette.gray[80],
    [breakpoints.down('576')]: {
      width: '100%',
      gridTemplateColumns: '60% 1fr',
    },
  },
  firstRow: {
    marginTop: 16,
    fontSize: 16,
  },
  product: {
    padding: '10px 7px',
    border: `1px solid ${palette.gray[12]}`,
    borderRadius: 8,
  },
  totalContainer: {
    marginTop: spacing(16),
    gridColumn: '2 / 3',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalLabel: {
    fontSize: 16,
    fontWeight: 500,
    color: palette.text.secondary,
  },
  total: {
    fontSize: 24,
    color: palette.primary.main,
    fontWeight: 500,
  },
  postWorkAttach: {
    marginTop: spacing(24),
    [breakpoints.down('576')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  footer: {
    marginTop: spacing(24),
    width: '100%',
  },
  shield: {
    fill: props =>
      props?.acceptsWarranty ? Theme.Colors.Primary.Base : Theme.Colors.Grayscale.SixtyFour,
    left: '9.38%',
    right: '9.38%',
    top: '5.21%',
    bottom: '5.21%',
  },
  warranty: {
    color: props => (props?.acceptsWarranty ? Theme.Colors.Primary.Base : Theme.Colors.Red.Base),
    fontFamily: 'DM Sans, sans-serif',
    fontSize: 14,
    fontWeight: 200,
  },
  warrantyContent: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '620px',
    [breakpoints.down('576')]: {
      width: '100%',
      justifyContent: 'flex-end',
      marginLeft: 0,
    },
  },
  iconContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  copyrigth: {
    fill: Theme.Colors.Primary.Base,
    left: '9.38%',
    right: '9.38%',
    top: '5.21%',
    bottom: '5.21%',
  },
  link: {
    fontSize: spacing(12),
    fontWeight: 500,
    color: palette.primary.main,
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(16),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(12),
    width: '100%',
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing(12),
    width: '100%',
  },
  textArea: {
    color: palette.gray[500],
    fontSize: spacing(14),
    width: '100%',
    backgroundColor: palette.white,
    resize: 'none',
    textAlign: 'justify',
    display: 'flex',
    border: 'none',

    '&::-webkit-scrollbar': {
      width: spacing(0.75),
      height: 0,
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '100vh',
      backgroundColor: palette.grey[100],
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.grey[300],
      borderRadius: '100vh',
      border: `3px solid ${palette.grey[300]}`,
    },
  },
  errorMessage: {
    margin: 0,
    marginTop: '-10px',
    color: `${palette.error.main} !important`,
    fontSize: spacing(10.5),
    fontFamily: 'Roboto',
    height: spacing(10),
  },
}))
