import { createSelector } from 'reselect'

import { GET_CATEGORIES, GET_CATEGORIES_PARAMS } from './actions'

export const getCategoriesLoadingSelector = state =>
  !!state.loading.get(GET_CATEGORIES.ACTION) || !!state.loading.get(GET_CATEGORIES_PARAMS.ACTION)

export const categoriesSelector = state => state.categories.results.valueSeq().toArray()

export const categoriesParamsSelector = state => state.categories.resultsParams

export const getBudgetTemplateSelector = state => state.categories.budgetTemplate

const getParam = (state, param) => param
export const categoryOptionSelector = state =>
  state.categories.results
    .valueSeq()
    .toArray()
    .map(value => ({
      label: value.name,
      value: value.id,
      subcategories: value?.subcategories?.valueSeq()?.toArray(),
    }))

export const serviceTypesSelector = state =>
  state.categories.results
    .valueSeq()
    .toArray()
    .map(service => service?.subcategories)

export const serviceAndServiceTypeSelector = createSelector(
  [categoriesSelector, getParam],
  (categories, [category, subcategories = []]) => {
    const currentService = categories.find(({ id }) => id === category)

    const subcategoriesLabels = subcategories.length
      ? subcategories
          .map(
            type =>
              currentService?.subcategories?.size &&
              currentService?.subcategories?.get(String(type))?.name
          )
          .join(', ')
      : ''
    return {
      label: currentService?.name,
      subcategoriesLabels,
    }
  }
)
