import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '94%',
    maxWidth: spacing(700),
    backgroundColor: '#fff',
    boxShadow: spacing(24),
    borderRadius: spacing(8),
    outline: 'none',
  },
  header: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: Theme.Typography.FontSize.Medium,
    fontWeight: Theme.Typography.FontWeight.Medium,
    color: Theme.Colors.Primary.Base,
    margin: `${spacing(16)} ${spacing(24)}`,
    lineHeight: spacing(32),
    letterSpacing: '-0.02em',
  },
  contentContainer: {
    display: 'flex',
    marginTop: spacing(24),
    marginBottom: spacing(10),
    overflow: 'auto',
    height: spacing(400),
  },
  content: {
    color: Theme.Colors.Grayscale.Eighty,
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Small,
    lineHeight: spacing(20),
    letterSpacing: '-0.02em',
  },
  footer: {},
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: `${spacing(8)} ${spacing(24)}`,
  },
  checkboxLabel: {
    color: Theme.Colors.Grayscale.Eighty,
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Small,
    lineHeight: spacing(20),
    letterSpacing: '-0.02em',
    marginLeft: spacing(8),
    marginTop: spacing(2),
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: `${spacing(16)} ${spacing(24)}`,
    gap: spacing(16),
  },
}))
