import { makeStyles } from '@material-ui/core/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  header: {
    height: 80,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    width: spacing(24),
    height: spacing(24),
    fontSize: spacing(Theme.Typography.FontSize.Tiny),
    borderRadius: '50%',
  },
  dialogContent: {
    width: '100%',
    fontSize: spacing(Theme.Typography.FontSize.XXSmall),
    fontWeight: Theme.Typography.FontWeight.Small,
    fontStyle: 'normal',
    lineHeight: '20px',

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
    },
    '&.MuiDialogContent-dividers': {
      borderBottom: '0px',
    },
  },
  text: {
    fontSize: spacing(Theme.Typography.FontSize.XXSmall),
    lineHeight: spacing(16),
    color: palette.gray[64],
    marginTop: spacing(25),
    fontFamily: Theme.Typography.FontFamily.Roboto,
  },
  required: {
    color: '#C90000',
  },
  select: {
    marginBottom: spacing(10),
    minWidth: spacing(120),
  },
  input: {
    '& .MuiOutlinedInput-multiline': {
      alignItems: 'baseline',
      minHeight: spacing(104),
      marginTop: spacing(10),
      marginBottom: '5px',
    },
  },
  msgInput: {
    height: '100%',
    '& .MuiOutlinedInput-multiline': {
      alignItems: 'baseline',
      height: '100%',
      minHeight: spacing(104),
      maxHeight: '100%',
      marginTop: spacing(10),
      marginBottom: '5px',
      overflowY: 'scroll',
    },
  },
  btnWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px',
    gap: '10px',
  },
  button: {
    width: '100px',
    padding: '10px 0',
    borderRadius: '24px',
  },
  column: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  section: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'space-around',
  },
  subsection: {
    display: 'flex',
    gap: '20px',
  },
}))
