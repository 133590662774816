import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText, DialogActions, Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { sendWhatsapp } from '_/modules/budget/actions'
import Button from '_components/button'
import useToggle from '_hooks/use-toggle'

import MessageQueueDialog from '../message-queue-dialog'
import SuccessDialog from '../success-dialog'

import useStyles from './styles'

export default function SendWhatsappDialog({ closeModal, serviceOrderId, budgetId }) {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [isWhatsappResponseOpen, onToggleWhatsappResponseDialog] = useToggle()
  const [isSuccessResponseOpen, onToggleSuccessResponseDialog] = useToggle()
  const handleSendWhatsapp = async () => {
    await Promise.resolve(
      dispatch(sendWhatsapp(serviceOrderId, budgetId)).then(
        res => {
          if (res.message === 128) {
            onToggleWhatsappResponseDialog()
          }
          if (res.message === 72) {
            onToggleSuccessResponseDialog()
          }
        },
        [onToggleWhatsappResponseDialog, onToggleSuccessResponseDialog]
      )
    )
  }

  return (
    <Grid>
      <Dialog open onClose={closeModal}>
        <DialogContent>
          <DialogContentText className={styles.description}>
            Você tem certeza que deseja executar esta ação?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant="outlined">
            Não
          </Button>
          <Button onClick={handleSendWhatsapp} color="primary" variant="contained">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      {isWhatsappResponseOpen && (
        <MessageQueueDialog isOpen closeModal={onToggleWhatsappResponseDialog} />
      )}
      {isSuccessResponseOpen && <SuccessDialog isOpen closeModal={onToggleSuccessResponseDialog} />}
    </Grid>
  )
}

SendWhatsappDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
}
