import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid } from '@material-ui/core'
import classnames from 'classnames'

import useStyles from './styles'

const Loading = ({ size, className }) => {
  const styles = useStyles()
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classnames(styles.loading, className)}
    >
      <CircularProgress size={size} />
    </Grid>
  )
}

Loading.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
}
Loading.defaultProps = {
  size: 50,
  className: '',
}

export default memo(Loading)
