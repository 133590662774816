import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'grid',
    gridRowGap: spacing(16),
    alignItems: 'center',
    width: spacing(463),
  },

  title: {
    fontWeight: Theme.Typography.FontWeight.Medium,
  },

  inputLabel: {
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.Eighty,
  },

  action: {
    marginTop: '40px',
    marginLeft: '250px',
    display: 'inline-grid',
    gridTemplateColumns: `repeat(2, ${spacing(90)})`,
    gridColumnGap: spacing(16),
  },

  button: {
    width: '100px',
    borderRadius: '24px',
    height: '40px',
    textTransform: 'none',
    marginBottom: '15px',
  },

  description: {
    overflowWrap: 'break-word',
    fontFamily: 'Dm Sans',
    fontStyle: 'normal',
    fontSize: '40px',
  },
}))
