import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { Modal, Button } from '@refera/ui-web'

import { DownloadPdfButton } from '_components/service-order'
import { getPayerOptionsSelector, getBudgetByIdSelector } from '_modules/budget/selectors'
import { getInstallmentRules } from '_/modules/finance/actions'
import { getPayerOptions } from '_modules/budget/actions'
import { getInstallmentRulesSelector } from '_/modules/finance/selectors'
import Select from '_components/common/select'

import useStyles from './styles'

const ModalConfirmInstallment = ({ open, onCancel, serviceOrderId, budgetId }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const installmentRules = useSelector(getInstallmentRulesSelector)
  const [installments, setInstallments] = useState('')
  const payerOptions = useSelector(getPayerOptionsSelector)

  const installmentOptions = useMemo(() => {
    return payerOptions?.installmentOptions?.slice(0, installments)
  }, [payerOptions?.installmentOptions, installments])

  useEffect(() => {
    if (!installmentRules && budgetId) dispatch(getInstallmentRules({ budgetId, installment: 9 }))
  }, [dispatch, installmentRules, budgetId])

  const handleInstallmentQuantity = useMemo(() => {
    if (!installmentRules || !budget) return null

    const { installment, minValue, maxValue } = installmentRules
    const { priceTotal } = budget

    if (minValue <= priceTotal && priceTotal <= maxValue && installment < 9) {
      return installment
    }
    return null
  }, [installmentRules, budget])

  useEffect(() => {
    dispatch(getPayerOptions(serviceOrderId, budgetId))
  }, [budgetId, serviceOrderId])

  const options = useMemo(() => {
    const defaultOption = {
      value: '',
      label: 'Selecione',
    }

    if (handleInstallmentQuantity) {
      const list = [...Array(handleInstallmentQuantity).keys()].map(index => ({
        value: index + 1,
        label: `${index + 1}`,
      }))
      return [defaultOption, ...list]
    }

    return [defaultOption]
  }, [handleInstallmentQuantity])

  const handleChange = useCallback(event => setInstallments(event.target.value), [])

  return (
    <Modal
      className={styles.modal}
      title="PDF do orçamento"
      onBackdropClick={() => onCancel()}
      onClose={() => onCancel()}
      open={open}
      actionsButtons={
        <Grid className={styles.modalButtons}>
          <Button color="red" variant="secondary" onClick={() => onCancel()}>
            Cancelar
          </Button>
          <DownloadPdfButton onCancel={onCancel} installments={installmentOptions} />
        </Grid>
      }
    >
      <Select
        label="Quantidade de parcelas disponível para o pagador"
        required
        defaultValue=""
        value={installments}
        onChange={handleChange}
        options={options}
      />
    </Modal>
  )
}

export default ModalConfirmInstallment
