import React, { useMemo } from 'react'
import { IconButton as MUIButton, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import Svg, { ICON as SVG_ICONS } from '../index'

export const ICON = SVG_ICONS
const IconButton = ({
  onClick,
  disabled,
  children,
  buttonClassName,
  tooltip,
  to,
  ...otherProps
}) => {
  const renderComponent = useMemo(() => {
    if (children) {
      return (
        <MUIButton
          component={to ? Link : 'button'}
          className={buttonClassName}
          onClick={onClick}
          disabled={disabled}
          to={to}
          {...otherProps}
        >
          {children}
        </MUIButton>
      )
    }
    return (
      <MUIButton
        component={to ? Link : 'button'}
        className={buttonClassName}
        onClick={onClick}
        disabled={disabled}
        to={to}
      >
        <Svg {...otherProps} />
      </MUIButton>
    )
  }, [buttonClassName, children, disabled, onClick, otherProps, to])

  if (tooltip) {
    return (
      <Tooltip arrow title={tooltip}>
        {renderComponent}
      </Tooltip>
    )
  }

  return renderComponent
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  buttonClassName: PropTypes.string,
  to: PropTypes.string,
}

IconButton.defaultProps = {
  disabled: false,
  children: null,
  buttonClassName: '',
  to: '',
  onClick: () => {},
}
export default React.memo(IconButton)
