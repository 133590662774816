export function openWhatsApp(phone) {
  const whatsappLink = `https://wa.me/${phone.replace(/\D+/g, '')}`
  window.open(whatsappLink, '_blank')
}

export function openWhatsAppWithMessage({ message, phone }) {
  const whatsappLink = `https://wa.me/${phone.replace(/\D+/g, '')}`
  if (!message) {
    window.open(whatsappLink, '_blank')
  } else {
    const messageWa = encodeURIComponent(message)
    window.open(`${whatsappLink}?text=${messageWa}`, '_blank')
  }
}
