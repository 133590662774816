import React from 'react'
import moment from 'moment'
import { parse } from 'query-string'
import { Tooltip } from '@material-ui/core'

import humps from 'humps'

import Theme from '@refera/ui-core'
import { Trash } from '@refera/ui-icons'

import IconButton from '_components/svg/icon-button'

import { cpfCnpjMask } from '_/utils/helpers'

export const addLeadingZeros = id => {
  const zeros = '0000'
  const strId = id ? id.toString() : ''
  const numZeros = 4 - strId.length
  const zeroPrefix = zeros.substring(0, numZeros)
  return zeroPrefix + strId
}

export const METHOD_PAYMENT_OPTIONS = [
  {
    value: 'boleto',
    label: 'Boleto',
  },
  {
    value: 'credit_card',
    label: 'Cartão de Crédito',
  },
  {
    value: 'rent_discount',
    label: 'Desconto no aluguel',
  },
  {
    value: 'pix',
    label: 'PIX',
  },
  {
    value: 'realestate_installment',
    label: 'Parcelamento intermediária',
  },
]

export const METHOD_PAYMENT_OPTIONS_SELECT = [
  {
    value: '',
    label: 'Selecione',
  },
  ...METHOD_PAYMENT_OPTIONS,
]

export const METHOD_PAYMENT_OPTIONS_SELECT_FILTER = [
  {
    value: '',
    label: '(Todos)',
  },
  ...METHOD_PAYMENT_OPTIONS,
]

export const handlePaymentMethod = value => {
  if (value) {
    const optionObj = METHOD_PAYMENT_OPTIONS.find(option => option.value === value)
    return optionObj ? optionObj?.label : value
  }
  return '-'
}

const handleBatchField = ({ batchId, isBatchScreen }) => {
  if (!batchId) {
    return '-'
  }

  if (isBatchScreen) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            // color: Theme.Colors.Grayscale,
            cursor: 'pointer',
          }}
        >
          {addLeadingZeros(batchId)}
        </span>
      </div>
    )
  }
  return (
    <Tooltip title="Ir para página do lote">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            color: Theme.Colors.Primary.Base,
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {addLeadingZeros(batchId)}
        </span>
      </div>
    </Tooltip>
  )
}

export const Columns = ({ styles, isBatchScreen = false }) => [
  {
    field: 'serviceOrderId',
    headerName: 'Chamado',
    width: 100,
    editable: false,
    headerClassName: styles.grayColumn,
    cellClassName: styles.idColumn,
    renderCell: params => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor: Theme.Colors.Primary.Lightest,
          padding: '4px 8px',
        }}
        // key={Math.random(0, params.id)}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'tradesmanName',
    headerName: 'Prestador',
    width: 180,
    editable: false,
  },
  {
    field: 'agencyName',
    headerName: 'Intermediária',
    width: 180,
    editable: false,
  },
  // {
  //   field: 'value',
  //   headerName: 'Valor da parcela',
  //   width: 150,
  //   editable: false,
  //   valueFormatter: ({ value }) =>
  //     value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  // },
  {
    field: 'providerValue',
    headerName: 'Valor do prestador',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  // {
  //   field: 'referaValue',
  //   headerName: 'Valor da Refera',
  //   width: 150,
  //   editable: false,
  //   valueFormatter: ({ value }) =>
  //     value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  // },
  {
    field: 'currentInstallment',
    headerName: 'Parcela',
    width: 100,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
    renderCell: params => statusField(params.row.status),
  },
  {
    field: 'datetimeFinished',
    headerName: 'Data da finalização do chamado',
    width: 220,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'anticipationDate',
    headerName: 'Data da solicitação',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'dueDate',
    headerName: 'Data do vencimento',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'lastBatchId',
    headerName: 'Lote',
    width: 150,
    editable: false,
    renderCell: params => handleBatchField({ batchId: params.value, isBatchScreen }),
  },
  {
    field: 'datetimePaid',
    headerName: 'Data de pagamento',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'condolivreId',
    headerName: 'ID Condolivre',
    width: 220,
    editable: false,
    valueFormatter: ({ value }) => value || '-',
  },
  {
    field: 'condolivreStatus',
    headerName: 'Status Condolivre',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => value || '-',
  },
  {
    field: 'condolivreDatetime',
    headerName: 'Data/Hora',
    width: 220,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy - HH:mm') : '-'),
  },
  // {
  //   field: 'actions',
  //   headerName: 'Ações',
  //   width: 100,
  //   editable: false,
  // },
]

const STATUS_OPTIONS = {
  adjust: { label: 'Ajuste', color: Theme.Colors.Grayscale.OneThousand },
  anticipating: { label: 'Em antecipação', color: Theme.Colors.Orange.Base },
  approved: { label: 'Aprovado', color: Theme.Colors.Green.Base },
  canceled: { label: 'Cancelado', color: Theme.Colors.Grayscale.SixtyFour },
  error: { label: 'Erro', color: Theme.Colors.Orange.Base },
  late: { label: 'Atrasado', color: Theme.Colors.Red.Base },
  paid: { label: 'Pago', color: Theme.Colors.Primary.Base },
  paying: { label: 'Em pagamento', color: Theme.Colors.Green.Base },
  inPayment: { label: 'Em pagamento', color: Theme.Colors.Orange.Base },
  pending: { label: 'Pendente', color: Theme.Colors.Yellow.Base },
  processing: { label: 'Processando', color: Theme.Colors.Green.Base },
  reproved: { label: 'Reprovado', color: Theme.Colors.Red.Base },
}

export const statusField = status => {
  const { label, color } = STATUS_OPTIONS[status] || STATUS_OPTIONS.error

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        backgroundColor: Theme.Colors.Grayscale.Four,
        padding: '4px 8px',
      }}
      key={Math.random(0, status)}
    >
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: color,
          marginRight: 8,
        }}
      />
      <span style={{ color: Theme.Colors.Grayscale.SixtyFour, fontSize: '14px' }}>{label}</span>
    </div>
  )
}

export const STATUS_FIELDS = [
  {
    value: 'pending',
    label: 'Pendentes',
  },
  {
    value: 'anticipating',
    label: 'Em antecipação',
  },
  {
    value: 'paying',
    label: 'Em pagamento',
  },
  {
    value: 'paid',
    label: 'Pagos',
  },
  {
    value: 'error',
    label: 'Erro',
  },
  {
    value: 'late',
    label: 'Atrasados',
  },
  {
    value: 'adjust',
    label: 'Ajuste',
  },
  {
    value: '',
    label: 'Todos',
  },
]

export const RECEIVABLES_STATUS_FIELDS = [
  {
    value: '',
    label: 'Todos',
  },
  {
    value: 'pending',
    label: 'Pendentes',
  },
  {
    value: 'paid',
    label: 'Pagos',
  },
]

export const formatArrayToString = array => (Array.isArray(array) ? array.join(',') : '')

export const handleFormatFilter = filters => {
  const params = filters

  if (filters?.company) {
    params.company = Array.isArray(filters.company)
      ? filters.company?.map(option => option.value)?.join(',')
      : filters.company
  }
  if (filters?.agency) {
    params.agency = Array.isArray(filters.agency)
      ? filters.agency?.map(option => option.id)?.join(',')
      : filters.agency
  }

  return params
}

export const TRANSLATED_FILTERS_KEYS = {
  ANTECIPAÇÃO: 'anticipation',
  DATA_PAGAMENTO_INICIAL: 'paymentDateStart',
  DATA_PAGAMENTO_FINAL: 'paymentDateEnd',
  SOLICITANTE: 'requester',
  CHAMADO: 'serviceOrder',
  STATUS: 'status',
  VENCIMENTO_INICIAL: 'dueDateStart',
  VENCIMENTO_FINAL: 'dueDateEnd',
  VALOR_PAGAMENTO_INICIAL: 'paymentValueStart',
  VALOR_PAGAMENTO_FINAL: 'paymentValueEnd',
}

export const translateUrlParams = params => {
  if (!params) return ''

  const parsedParams = parse(params)
  const translatedParams = {}

  Object.keys(parsedParams).forEach(key => {
    const translatedKey = TRANSLATED_FILTERS_KEYS[key.toUpperCase()]

    if (translatedKey) {
      translatedParams[translatedKey] = parsedParams[key]
    }
  })

  return translatedParams
}

export const PAYMENT_METHOD_OPTIONS = [
  { value: 'pix', label: 'PIX' },
  { value: 'manual_ted', label: 'TED manual' },
  { value: 'automatic_ted', label: 'TED automático' },
]

export const ANTICIPATIONS_PAYMENT_METHOD_OPTIONS = [
  ...PAYMENT_METHOD_OPTIONS,
  { value: 'condolivre', label: 'Condolivre' },
]

export const batchesColumns = styles => [
  {
    field: 'id',
    headerName: 'ID',
    width: 110,
    editable: false,
    headerClassName: styles.grayColumn,
    cellClassName: styles.idColumn,
    renderCell: params => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor: Theme.Colors.Primary.Lightest,
          padding: '4px 8px',
        }}
        // key={Math.random(0, params.id)}
      >
        Lote {addLeadingZeros(params.value)}
      </div>
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Data de criação do lote',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    editable: false,
    renderCell: params => statusField(params.row.status),
  },
  {
    field: 'paymentDate',
    headerName: 'Data de pagamento',
    width: 200,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'batchType',
    headerName: 'Tipo do lote',
    width: 200,
    editable: false,
    valueFormatter: ({ value }) => (value ? humps.pascalize(value) : '-'),
  },
]

export const ReturnFilesColumns = [
  // export const returnFilesColumns = ({ handleDownloadReturnFilePdf, disableDownloadButton }) => [
  {
    field: 'fileName',
    headerName: 'Nome do arquivo',
    width: 300,
    editable: false,
    valueFormatter: ({ value }) => value || '-',
  },
  {
    field: 'processingDate',
    headerName: 'Data de processamento',
    width: 200,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy [às] HH:mm:ss') : '-'),
  },
  // {
  //   field: 'download',
  //   headerName: 'Download',
  //   minWidth: '100',
  //   flex: 1,
  //   headerAlign: 'right',
  //   editable: false,
  //   renderCell: params => (
  //     <div
  //       style={{
  //         display: 'flex',
  //         justifyContent: 'flex-end',
  //         alignItems: 'flex-end',
  //         width: '100%',
  //       }}
  //       key={params.row.returnFile}
  //     >
  //       <Tooltip title="Realizar Download">
  //         <button
  //           type="button"
  //           disabled={disableDownloadButton}
  //           onClick={() => handleDownloadReturnFilePdf({ batchId: params.row.id })}
  //           style={{ cursor: 'pointer' }}
  //         >
  //           <Svg type={ICON.DOWNLOAD} style={{ color: Theme.Colors.Primary.Base }} />
  //         </button>
  //       </Tooltip>
  //     </div>
  //   ),
  // },
]

export const ReturnFileDetailsColumns = [
  {
    field: 'result',
    headerName: 'Resultado',
    width: 250,
    editable: false,
    valueFormatter: ({ value }) => value ?? '-',
  },
  {
    field: 'tradesmanCpfOrCnpj',
    headerName: 'CPF/CNPJ',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => (value ? cpfCnpjMask(value) : '-'),
  },
  {
    field: 'tradesman',
    headerName: 'Prestador',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => value ?? '-',
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'paymentInstallmentDate',
    headerName: 'Pagamento',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'amountPaid',
    headerName: 'Valor',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'status',
    headerName: 'Status atual',
    width: 200,
    editable: false,
    renderCell: params => statusField(params.row.status),
  },
  {
    field: 'cpfCnpjRecipient',
    headerName: 'Banco CPF/CNPJ',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => (value ? cpfCnpjMask(value) : '-'),
  },
  {
    field: 'recipient',
    headerName: 'Banco Favorecido',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => value ?? '-',
  },
  {
    field: 'paymentDate',
    headerName: 'Banco Pagamento',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => value ?? '-',
  },
  {
    field: 'value',
    headerName: 'Banco Valor',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'bankError',
    headerName: 'Banco Erro',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => value ?? '-',
  },
]

export const paymentConfirmationColumns = ({ styles }) => [
  {
    field: 'serviceOrderId',
    headerName: 'ID do chamado',
    width: 150,
    editable: false,
    headerClassName: styles.grayColumn,
    cellClassName: styles.idColumn,
    renderCell: params => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor: Theme.Colors.Primary.Lightest,
          padding: '4px 8px',
        }}
        // key={Math.random(0, params.id)}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'tradesmanName',
    headerName: 'Prestador',
    width: 180,
    editable: false,
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'datetimePaid',
    headerName: 'Pagamento',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'currentInstallment',
    headerName: 'Parcela',
    width: 120,
    editable: false,
  },
  {
    field: 'providerValue',
    headerName: 'Valor total',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'anticipationTax',
    headerName: 'Taxa de antecipação',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'value',
    headerName: 'Valor líquido',
    width: 160,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 160,
    editable: false,
    renderCell: params => statusField(params.row.status),
  },
]

export const TradesmanInstallmentsColumns = ({ styles }) => [
  {
    field: 'serviceOrderId',
    headerName: 'ID',
    width: 100,
    editable: false,
    headerClassName: styles.grayColumn,
    cellClassName: styles.idColumn,
    renderCell: params => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor: Theme.Colors.Primary.Lightest,
          padding: '4px 8px',
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'currentInstallment',
    headerName: 'Parcela',
    width: 100,
    editable: false,
  },
  {
    field: 'payer',
    headerName: 'Pagador',
    sortable: false,
    width: 120,
    editable: false,
  },
  {
    field: 'requester',
    headerName: 'Cliente',
    width: 280,
    editable: false,
    valueFormatter: ({ value }) => value || `-`,
  },
  {
    field: 'priceServiceProvider',
    headerName: 'Valor',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'anticipating',
    headerName: 'Antecipação',
    width: 120,
    editable: false,
    valueFormatter: ({ value }) => (value ? 'Sim' : 'Não'),
  },
  {
    field: 'paymentDate',
    headerName: 'Pagamento',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
    renderCell: params => statusField(params.row.status),
  },
]

export const ReceiptsCsReferaColumns = ({ styles }) => [
  {
    field: 'serviceOrderId',
    headerName: 'Chamado',
    width: 100,
    editable: false,
    headerClassName: styles.grayColumn,
    cellClassName: styles.idColumn,
    renderCell: params => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor: Theme.Colors.Primary.Lightest,
          padding: '4px 8px',
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'customer',
    headerName: 'Cliente',
    width: 300,
    editable: false,
    valueFormatter: ({ value }) => value || `-`,
  },
  {
    field: 'voucher',
    headerName: 'Boleto',
    width: 300,
    editable: false,
  },
  {
    field: 'number',
    headerName: 'Nº',
    width: 60,
    editable: false,
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'value',
    headerName: 'Valor',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
    renderCell: params => statusField(params.row.status),
  },
  {
    field: 'paymentDate',
    headerName: 'Pagamento',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'amountPaid',
    headerName: 'Valor recebido',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
]

export const JustSelectedReceiptsCsReferaColumns = ({ styles }) => [
  {
    field: 'serviceOrderId',
    headerName: 'Chamado',
    width: 100,
    editable: false,
    headerClassName: styles.grayColumn,
    cellClassName: styles.idColumn,
    renderCell: params => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor: Theme.Colors.Primary.Lightest,
          padding: '4px 8px',
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'agency',
    headerName: 'Intermediadora',
    width: 300,
    editable: false,
    valueFormatter: ({ value }) => value || `-`,
  },
  {
    field: 'formOfPayment',
    headerName: 'Forma de pagamento',
    width: 300,
    editable: false,
    valueFormatter: ({ value }) => handlePaymentMethod(value),
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'value',
    headerName: 'Valor',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
]

export const TradesmanInstallmentsConfirmationColumns = ({ styles }) => {
  const excludedColumns = ['anticipation', 'paymentDate', 'status']
  const filteredColumns = TradesmanInstallmentsColumns({ styles }).filter(
    column => !excludedColumns.includes(column.field)
  )

  return [
    ...filteredColumns,
    {
      field: 'anticipationTax',
      headerName: 'Taxa de antecipação',
      width: 150,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
    },
    {
      field: 'value',
      headerName: 'Valor líquido',
      width: 150,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
    },
  ]
}

export const TradesmanAnticipationsColumns = ({ styles, handleRemoveAnticipation }) => {
  return [
    {
      field: 'id',
      headerName: 'Pedido de antecipação',
      width: 180,
      editable: false,
      headerClassName: styles.grayColumn,
      cellClassName: styles.idColumn,
      renderCell: params => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            backgroundColor: Theme.Colors.Primary.Lightest,
            padding: '4px 8px',
          }}
        >
          Antecipação {addLeadingZeros(params.value)}
        </div>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Solicitado em',
      width: 120,
      editable: false,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
    },
    {
      field: 'amountPaidTotal',
      headerName: 'Valor a receber',
      width: 120,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
    },
    {
      field: 'paymentDate',
      headerName: 'Pagamento',
      width: 120,
      editable: false,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: false,
      renderCell: params => statusField(params.row.status),
    },
    {
      field: '-',
      headerName: 'Ações',
      width: 120,
      editable: false,
      flex: 1,
      headerAlign: 'right',
      renderCell: ({ row }) => (
        <div className={styles.columnFlexEnd}>
          <Tooltip title="Excluir antecipação">
            <div>
              <IconButton variant="ghost" onClick={() => handleRemoveAnticipation(row)}>
                <Trash color="red" />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      ),
    },
  ]
}

export const ChargeFileDetailsColumns = ({ styles }) => [
  {
    field: 'serviceOrderId',
    headerName: 'Chamado',
    width: 100,
    editable: false,
    headerClassName: styles.grayColumn,
    cellClassName: styles.idColumn,
    renderCell: params =>
      params?.value ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            backgroundColor: Theme.Colors.Primary.Lightest,
            padding: '4px 8px',
          }}
        >
          {params.value}
        </div>
      ) : (
        '-'
      ),
  },
  {
    field: 'client',
    headerName: 'Cliente',
    width: 220,
    editable: false,
    valueFormatter: ({ value }) => value ?? '-',
  },
  {
    field: 'cpfCnpj',
    headerName: 'CPF/CNPJ',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => (value ? cpfCnpjMask(value) : '-'),
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/yyyy') : '-'),
  },
  {
    field: 'value',
    headerName: 'Valor',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Descrição',
    width: 280,
    editable: false,
  },
  {
    field: 'referaId',
    headerName: 'ID Refera',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => value ?? '-',
  },
  {
    field: 'fidcId',
    headerName: 'ID Fidc',
    width: 210,
    editable: false,
    valueFormatter: ({ value }) => value ?? '-',
  },
]

export const fileSuccessMessage = 'Seu arquivo foi processado com sucesso!'
export const fileErrorUploadFileType = 'Arquivo inválido'
export const fileErrorUploadMessage =
  'Algo de errado aconteceu com o seu arquivo de retorno. Tente novamente'
export const fileErrorLoadMessage =
  'Algo de errado aconteceu ao buscar a lista de arquivos de retorno. Tente novamente'
