import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Grid } from '@mui/material'
import Theme from '@refera/ui-core'
import { Alert, Button, Datagrid, Dialog } from '@refera/ui-web'
import { FilterSearch as FilterIcon, MoneySend, Danger as DangerIcon } from '@refera/ui-icons'
import { useDispatch, useSelector } from 'react-redux'

import Filters from './components/Filters'
import HeaderTitle from '_/components/header-title'
import AnticipationConfirmation from '../anticipation-confirmation'
import FloatingMenu from '../../components/FloatingMenu'

import {
  TradesmanInstallmentsColumns,
  handleFormatFilter,
  translateUrlParams,
} from '../../manage-installments/utils/constants'
import { ROUTES } from '_/utils/constants'

import useFetchCall from '_/hooks/use-fetch-call'
import {
  PAY_BUDGET_INSTALLMENTS,
  CREATE_ANTICIPATIONS_TRADESMAN,
  getFinanceGenericParameters,
  getTradesmanInstallments,
  updateBudgetInstallmentsFilter,
  POST_CONDOLIVRE_ANTICIPATIONS_INFO,
} from '_/modules/finance/actions'
import { getAgencies } from '_/modules/agency/actions'
import { getCompanies, getCompaniesSimple } from '_/modules/company/actions'

import {
  budgetInstallmentsLoadingSelector,
  tradesmanInstallmentsSelector,
  getBudgetInstallmentsFilterSelector,
  financeGenericParametersSelector,
  // getBudgetInstallmentsIsFilterDirtySelector,
} from '_/modules/finance/selectors'

import useStyles from '../styles'
import moment from 'moment'
import { getVerificationToken } from '_/modules/authentication/actions'
import { CalculateTradesmanAnticipationValues } from '../../utils/CalculateTradesmanAnticipationValues'
// import { agenciesResultSelector } from '_/modules/agency/selectors'
// import { getCompaniesNamesSelector } from '_/modules/company/selector'
import { navigate } from '@reach/router'
import { defaultInfoDialog } from '../utils/constants'
import { useToast } from '_/hooks/use-toast'
import { TABLE_SX } from '../../utils/constants'
import useRequest from '_/hooks/use-request'

const ALERT_TITLE =
  'Atenção: as parcelas pagas em 2022 não foram importadas para a plataforma. Caso haja dúvidas sobre tais informações, contate a Refera.'

const NON_IMPORTED_DATA_DATE = moment('2023-10-01')
const NON_IMPORTED_OLD_DATA_MESSAGE =
  'Os dados deste pagamento não foram importados para este sistema. Para maiores informações, contate a Refera.'

const InstallmentsReceipts = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const installments = useSelector(tradesmanInstallmentsSelector)
  const financeGenericParameters = useSelector(financeGenericParametersSelector)
  const [toggleMenu, setToggleMenu] = useState('default')
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [infoDialog, setInfoDialog] = useState(defaultInfoDialog)
  // eslint-disable-next-line no-unused-vars
  const [selectingType, setSelectingType] = useState('')
  const [floatingMenuOpen, setFloatingMenuOpen] = useState(false)
  const filters = useSelector(getBudgetInstallmentsFilterSelector)
  // const isFilterDirty = useSelector(getBudgetInstallmentsIsFilterDirtySelector)
  const [orderBy, setOrderBy] = useState('')
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  // const agencies = useSelector(agenciesResultSelector) || []
  // const companies = useSelector(getCompaniesNamesSelector) || []

  const isLoadingInstallments = useSelector(budgetInstallmentsLoadingSelector)
  const [isLoadingPayInstallments] = useFetchCall(PAY_BUDGET_INSTALLMENTS.ACTION)
  const [isLoadingCreateAnticipations] = useFetchCall(CREATE_ANTICIPATIONS_TRADESMAN.ACTION)
  const [isLoadingFetchCondolivre] = useFetchCall(POST_CONDOLIVRE_ANTICIPATIONS_INFO.ACTION)

  const [isLoadingGetAgencies] = useRequest(getAgencies())
  const [isLoadingGetCompanies] = useRequest(getCompanies())

  const { showToast } = useToast()
  const isLoading = useMemo(
    () =>
      isLoadingInstallments ||
      isLoadingGetAgencies ||
      isLoadingGetCompanies ||
      isLoadingPayInstallments ||
      isLoadingCreateAnticipations ||
      isLoadingFetchCondolivre,
    [
      isLoadingInstallments,
      isLoadingGetAgencies,
      isLoadingGetCompanies,
      isLoadingPayInstallments,
      isLoadingCreateAnticipations,
      isLoadingFetchCondolivre,
    ]
  )

  // const hasActiveBatch = useMemo(() => {
  //   return selectedPayments.some(payment => payment.activeBatch)
  // }, [selectedPayments])

  const handleGetInstallments = useCallback(() => {
    const urlParams = window.location.search
    const formattedFilters = handleFormatFilter(filters)
    const formattedUrlParams = translateUrlParams(urlParams)

    const params = { ...formattedFilters, ...formattedUrlParams }

    if (formattedUrlParams && Object.values(formattedUrlParams).find(item => item)) {
      dispatch(updateBudgetInstallmentsFilter(params))
    }

    Promise.resolve(
      dispatch(
        getTradesmanInstallments({
          page: page + 1,
          pageSize,
          orderBy,
          ...params,
        })
      )
    )
  }, [installments, page, pageSize, orderBy, filters])

  // const handleGetAgencies = useCallback(() => {
  //   if (agencies.length < 1) {
  //     Promise.resolve(dispatch(getAgencies()))
  //   }
  // }, [agencies])

  // const handleGetCompanies = useCallback(() => {
  //   if (companies.length < 1) {
  //     Promise.resolve(dispatch(getCompanies()))
  //     dispatch(getCompaniesSimple())
  //   }
  // }, [companies])

  const handleGetFinanceGenericParameters = useCallback(() => {
    dispatch(getFinanceGenericParameters())
  }, [])

  const handleGetVerificationToken = useCallback(() => {
    dispatch(getVerificationToken())
  }, [])

  const handleSuccessPayment = useCallback(
    (success, message) => {
      if (!success) {
        showToast({ type: 'error', message: message || 'Ocorreu um erro ao executar a ação.' })

        return
      }
      showToast({ type: 'success' })

      setToggleMenu('default')
      setSelectedItems([])
      handleGetInstallments()
    },
    [handleGetInstallments, setToggleMenu, setSelectedItems]
  )

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('dueDate')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const handleSelectedPayments = useCallback(() => {
    if (selectedItems.length > 0) {
      const newArray = [...selectedPayments?.filter(item => selectedItems.includes(item.id))]

      installments?.results?.map(
        item =>
          selectedItems.includes(item.id) &&
          !selectedPayments.filter(payment => item.id === payment.id)[0] &&
          newArray.push(item)
      )

      setSelectedPayments(newArray)
      setFloatingMenuOpen(true)
    } else {
      if (floatingMenuOpen) setFloatingMenuOpen(false)
      setSelectedPayments([])
    }
  }, [selectedItems, selectedPayments, installments])

  useEffect(() => {
    handleSelectedPayments()
  }, [selectedItems])

  useEffect(() => {
    // handleGetAgencies()
    // handleGetCompanies()
    dispatch(getCompaniesSimple())
    handleGetFinanceGenericParameters()
    handleGetVerificationToken()
  }, [])

  useEffect(() => {
    handleGetInstallments()
  }, [page, pageSize, orderBy])

  const handleFilters = () => setFilterDrawerOpen(prevState => !prevState)

  const BudgetInstallmentsFilter = useMemo(
    () => (
      <Filters
        handleFilterDrawer={handleFilters}
        isOpen={filterDrawerOpen}
        params={filters}
        open={filterDrawerOpen}
        setPage={setPage}
        handleGetInstallments={handleGetInstallments}
      />
    ),
    [filterDrawerOpen, filters]
  )

  const buttonColor = useMemo(() => {
    return isLoading ? Theme.Colors.Grayscale.ThirtyTwo : Theme.Colors.Primary.Base
  }, [isLoading])

  useEffect(() => {
    if (selectedPayments?.length > 0) {
      let type = ''
      selectedPayments.some(payment => payment.status === 'paid')
        ? (type = 'paid')
        : (type = 'pending')

      setSelectingType(type)

      let newSelectedIds = []

      if (type === 'paid') {
        newSelectedIds = selectedPayments.filter(item => item.status === 'paid')
      } else {
        newSelectedIds = selectedPayments.filter(item => item.status !== 'paid')
      }
      setSelectedIds(newSelectedIds.map(item => item.id))
    }
    if (selectedPayments?.length === 0) {
      setSelectingType('')
      setSelectedIds([])
    }
  }, [selectedPayments])

  const verifyDueDate = () => {
    const anticipationDay = moment(financeGenericParameters.anticipationDay)

    const invalid = selectedPayments.some(payment =>
      moment(payment.dueDate).isBefore(anticipationDay)
    )

    return { invalid, dueDate: anticipationDay.format('DD/MM/yyyy') }
  }

  const handleGoToConfirm = useCallback(() => {
    const checkDueDate = verifyDueDate()

    if (checkDueDate.invalid) {
      setInfoDialog({
        isOpen: true,
        icon: DangerIcon,
        type: 'error',
        subject: 'Erro',
        description: `Tem uma ou mais parcelas selecionadas cujo vencimento é menor que a próxima data de antecipação de ${checkDueDate.dueDate}. Favor desmarcá-las e repetir a operação.`,
      })
      return
    }
    setToggleMenu('pay')
  }, [verifyDueDate])

  const handleCellClick = useCallback(params => {
    if (params?.field === '__check__') return
    if (moment(params?.row?.paymentDate).isBefore(NON_IMPORTED_DATA_DATE)) {
      showToast({ message: NON_IMPORTED_OLD_DATA_MESSAGE, type: 'error' })
      return
    }
    window.open(
      `${ROUTES.INSTALLMENTS_RECEIPTS}/parcela/${params.row.id}`,
      '_blank',
      'noopener,noreferrer'
    )
  }, [])

  // REMOVED WHILE ANTICIPATIONS ARE BLOCKED FOR TRADESMAN ON B.O.
  // const handleSelectRow = useCallback(params => {
  //   return params?.row?.status === 'pending' && !params?.row?.isOverdue
  // }, [])

  const itemsWithTaxes = useMemo(
    () =>
      CalculateTradesmanAnticipationValues({ items: selectedPayments, financeGenericParameters }),
    [selectedPayments, financeGenericParameters]
  )

  const handleGoToAnticipationsScreen = () =>
    navigate(`${ROUTES.INSTALLMENTS_RECEIPTS}/antecipacoes`)

  const renderAnticipationsConfirmation = useMemo(() => {
    return (
      <AnticipationConfirmation
        loading={isLoading}
        toggleMenu={() => setToggleMenu('default')}
        menuType={toggleMenu}
        selectedIds={selectedIds}
        items={selectedPayments}
        handleSuccessPayment={handleSuccessPayment}
        totalItemsWithTaxes={itemsWithTaxes}
      />
    )
  }, [isLoading, toggleMenu, selectedIds, selectedPayments, handleSuccessPayment, itemsWithTaxes])

  return (
    <Grid>
      {BudgetInstallmentsFilter}
      {toggleMenu === 'default' ? (
        <>
          <HeaderTitle title="Recebimentos">
            <Grid className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                startIcon={<MoneySend color={buttonColor} />}
                onClick={handleGoToAnticipationsScreen}
                disabled={isLoading}
              >
                Pedidos de antecipação
              </Button>
              <Button
                variant="ghost"
                startIcon={<FilterIcon color={buttonColor} />}
                onClick={handleFilters}
                disabled={isLoading}
              >
                Filtros
              </Button>
            </Grid>
          </HeaderTitle>
          <Grid className={styles.tableContainer}>
            <Alert severity="warning" title={ALERT_TITLE} />
            <Grid className={styles.mainTable}>
              <Datagrid
                loading={isLoading}
                rows={installments?.results?.length ? installments?.results : []}
                columns={TradesmanInstallmentsColumns({ styles })}
                paginationMode="server"
                onCellClick={handleCellClick}
                onPageChange={setPage}
                page={page}
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                rowCount={installments?.count}
                onSortModelChange={handleOrderBy}
                disableSelectionOnClick
                // REMOVED WHILE ANTICIPATIONS ARE BLOCKED FOR TRADESMAN ON B.O.
                // checkboxSelection
                // isRowSelectable={handleSelectRow}
                // onSelectionModelChange={setSelectedItems}
                // keepNonExistentRowsSelected
                // selectionModel={selectedItems}
                sx={TABLE_SX}
              />
            </Grid>
            {floatingMenuOpen && (
              <FloatingMenu
                selected={selectedIds.length}
                items={itemsWithTaxes}
                handleGoToConfirm={handleGoToConfirm}
              />
            )}
          </Grid>
        </>
      ) : (
        renderAnticipationsConfirmation
      )}
      {infoDialog.isOpen && (
        <Dialog
          open={infoDialog.isOpen}
          icon={infoDialog.icon}
          type={infoDialog.type}
          subject={infoDialog.subject}
          description={infoDialog.description}
          onApprove={() => setInfoDialog(defaultInfoDialog)}
        />
      )}
    </Grid>
  )
}

export default InstallmentsReceipts
