/* Generals */
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import useStyles from './styles'
import { navigate, useParams } from '@reach/router'
import { ROUTES } from '_utils/constants'

/* Redux */
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '_modules/authentication/selectors'
import {
  postClassification,
  getClassificationColor,
  getClassificationType,
  getClassificationById,
  patchClassification,
  deleteClassification,
} from '_modules/classification/actions'
import {
  getClassificationLoadingSelector,
  optionsClassificationColorSelector,
  optionsClassificationTypeSelector,
} from '_modules/classification/selectors'

/* Forms */
import { FormProvider, useForm } from 'react-hook-form'
import { Select, MultilineInput, BasicInput, Checkbox } from '_components/inputs'

/* Components */
import Loading from '_components/loading'
import HeaderTitle from '_components/header-title'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import { Button, Toast, Alert, useConfirm } from '@refera/ui-web'
import { Typography } from '@material-ui/core'

const SectionTitle = ({ title, style }) => {
  const styles = useStyles()

  return (
    <Typography style={{ ...style }} className={styles.sectionTitle}>
      {title}
    </Typography>
  )
}

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}

const TOAST_INITIAL_STATE = {
  isOpen: false,
  type: '',
  message: '',
}

const ClassificationForm = () => {
  // TODO: delete Classifications & patch request for existing classification
  const styles = useStyles()
  const dispatch = useDispatch()
  const { agencyId, classificationId } = useParams()
  const { isConfirmed } = useConfirm()

  /* REACT STATES */
  const [classification, setClassification] = useState({})
  const [deleteMessage, setDeleteMessage] = useState('')
  const [toastInfo, setToastInfo] = useState(TOAST_INITIAL_STATE)

  const title = useMemo(
    () => (classificationId ? 'Editar classificação' : 'Criar classificação'),
    [classificationId]
  )

  /* REDUX SELECTORS */
  const user = useSelector(userSelector)
  const types = useSelector(optionsClassificationTypeSelector)
  const colors = useSelector(optionsClassificationColorSelector)
  const loading = useSelector(getClassificationLoadingSelector)

  /* FORMS */
  const methods = useForm()
  const { handleSubmit, reset } = methods
  /* FUNCTIONS */
  const handleGoBack = useCallback(() => navigate(-1), [])

  const onSubmit = useCallback(
    handleSubmit(data => {
      const isEditing = classificationId && classification
      const payload = { ...data, agency: agencyId }

      if (isEditing) {
        dispatch(patchClassification(classificationId, { ...payload })).then(
          () => setToastInfo({ isOpen: true, type: 'success', message: 'Salvo com sucesso!' }),
          error =>
            setToastInfo({
              isOpen: true,
              type: 'error',
              message: error?.message[0] || 'Algo deu errado. :(',
            })
        )
        return
      }

      dispatch(postClassification(payload)).then(
        () => {
          setToastInfo({ isOpen: true, type: 'success', message: 'Salvo com sucesso!' })
          return handleGoBack()
        },
        error =>
          setToastInfo({
            isOpen: true,
            type: 'error',
            message: error?.message[0] || 'Algo deu errado. :(',
          })
      )
    }),
    [classification, classificationId, user?.agency]
  )

  const onDelete = useCallback(async () => {
    setDeleteMessage('Deseja excluir essa classificação?')
    const confirmed = await isConfirmed()

    if (confirmed) {
      dispatch(deleteClassification(classificationId)).then(
        () => {
          setToastInfo({
            isOpen: true,
            type: 'success',
            message: 'Excluído com sucesso.',
          })
          setTimeout(() => {
            navigate(`${ROUTES.AGENCY}/${agencyId}${ROUTES.CLASSIFICATION_LIST}`)
          }, 6000)
        },
        () => {
          setToastInfo({
            isOpen: true,
            type: 'error',
            message:
              'Este registro já está sendo utilizado e não pode ser excluído. Favor inativá-lo.',
          })
          setTimeout(() => {
            navigate(`${ROUTES.AGENCY}/${agencyId}${ROUTES.CLASSIFICATION_LIST}`)
          }, 6000)
        }
      )
    }
  }, [deleteMessage, setDeleteMessage])

  const closeToast = useCallback(() => {
    setToastInfo(TOAST_INITIAL_STATE)
  }, [])
  /* USE-EFFECTS */
  useEffect(() => {
    dispatch(getClassificationColor())
    dispatch(getClassificationType())

    if (classificationId) {
      dispatch(getClassificationById(classificationId)).then(res => setClassification(res))
    }
  }, [])

  useEffect(() => {
    if (classification) {
      reset({
        classificationName: classification?.classificationName,
        classificationType: classification?.classificationType?.name,
        color: classification?.color?.colorName,
        active: classification?.active,
        description: classification?.description,
      })
    }
  }, [classification])

  if (!user || loading) {
    return <Loading />
  }

  return (
    <main className={styles.container}>
      <HeaderTitle title={title} backButtonAction={handleGoBack}>
        <Button onClick={onSubmit}>Salvar</Button>
        {classificationId && classification && (
          <Button color="red" onClick={onDelete}>
            Excluir
          </Button>
        )}
      </HeaderTitle>

      <div className={styles.content}>
        <FormProvider {...methods}>
          <form id="classificationSetupForm" className={styles.form}>
            <div>
              <SectionTitle title="Detalhes da Classificação" />
            </div>
            <div className={styles.inputWrapper}>
              <BasicInput
                label="Nome"
                name="classificationName"
                getValue={item => item.description}
                getKey={item => item.name}
                defaultValue={classification?.classificationName}
                required
              />
              <Checkbox
                id="active"
                label="Ativo"
                name="active"
                defaultValue={classification?.active || true}
                style={{ gridArea: 'defaultMessage', marginTop: '-20px' }}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Select
                label="Tipo de classificação"
                name="classificationType"
                getValue={item => item.name}
                getLabel={item => item.description}
                getKey={item => item?.id}
                options={types}
                menuProps={menuProps}
              />
              <Select
                label="Cor"
                name="color"
                getValue={item => item.colorName}
                getLabel={item => item.colorName}
                getKey={item => item?.id}
                options={colors}
                menuProps={menuProps}
                required
              />
            </div>
            <MultilineInput
              label="Descrição"
              name="description"
              getValue={item => item.desc}
              getKey={item => item?.id}
              menuProps={menuProps}
              defaultValue={classification?.description}
            />
          </form>
        </FormProvider>
      </div>
      <ConfirmRemoveDialog message={deleteMessage} type="remove" />
      <Toast open={toastInfo.isOpen} autoHideDuration={6000} onClose={closeToast}>
        <Alert severity={toastInfo.type} title={toastInfo.message} onClose={closeToast} />
      </Toast>
    </main>
  )
}

export default ClassificationForm
