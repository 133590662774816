import { Record, Map } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { categorySchema } from '_models/category'
import { toSlug } from '_utils/helpers'

import {
  GET_BUDGET_TEMPLATE,
  GET_CATEGORIES,
  GET_CATEGORIES_FILTERED,
  GET_CATEGORIES_PARAMS,
} from './actions'

const INITIAL_STATE = new Record({
  resultsParams: [],
  results: Map(),
  budgetTemplate: [],
  order: Map(),
})()

const categories = createReducer(INITIAL_STATE, {
  [GET_CATEGORIES.FULFILLED]: (state, { payload }) => {
    const normalizeServices = normalize(payload.results, categorySchema)
    return state
      .set('results', Map(normalizeServices.entities.service))
      .set('order', normalizeServices.result)
  },
  [GET_CATEGORIES_PARAMS.FULFILLED]: (state, { payload }) => {
    return state.set('resultsParams', payload)
  },
  [GET_CATEGORIES_FILTERED]: (state, { payload }) => {
    return state.set(
      'results',
      Map(state.results.filter(item => toSlug(item.name).includes(toSlug(payload))))
    )
  },
  [GET_BUDGET_TEMPLATE.FULFILLED]: (state, { payload }) => {
    return state.set('budgetTemplate', payload)
  },
})

export default categories
