/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

import { InputLabel } from '@material-ui/core'
import { FormHelperText } from '@mui/material'
import classNames from 'classnames'
import htmlEditorStyles from './styles'
import generalInputStyles from '../styles'
import PropTypes from 'prop-types'

import { defaultToolbarOptions } from './constants'

// - Docs: https://jpuri.github.io/react-draft-wysiwyg/#/docs

/**
 * A component that provides a rich text editor for HTML content.
 *
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the input field. (Hook-Form related)
 * @param {string} props.label - The label for the input field.
 * @param {string} [props.placeholder='Digite sua mensagem aqui...'] - The placeholder text for the editor.
 * @param {string} [props.labelClasses] - Additional classes to apply to the label element.
 * @param {boolean} [props.required=false] - Whether the input field is required.
 * @param {Object} [props.toolbarOptions={}] - The options to customize the toolbar of the editor. Check the docs for more info.
 * @param {string} [props.defaultValue=''] - The default value for the editor.
 * @param {Object} [props.style={}] - Additional styles to apply to the component container.
 * @param {string} [props.containerClasses] - Additional classes to apply to the component container.
 * @param {string} [props.wrapperClassNames] - Additional classes to apply to the editor wrapper element.
 * @param {string} [props.toolbarClassNames] - Additional classes to apply to the editor toolbar element.
 * @param {string} [props.editorClassNames] - Additional classes to apply to the editor element.
 *
 * @returns {JSX.Element} The HTMLEditor component.
 */
function HTMLEditor({
  name,
  label,
  placeholder,
  labelClasses,
  required = false,
  showLabelSpan = true,
  toolbarOptions = {},
  defaultValue = '',
  style = {},
  containerClasses,
  wrapperClassNames,
  toolbarClassNames,
  editorClassNames,
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const { errors, setValue, register, unregister } = useFormContext()
  const localStyles = htmlEditorStyles()
  const generalStyles = generalInputStyles()

  // expects the content to be a valid json string with the contentState
  const transformValue = useCallback(value => {
    if (typeof value === 'string') {
      if (value.length > 0) {
        const html = draftToHtml(JSON.parse(value))
        return {
          json: value,
          html,
        }
      }

      const newEmptyContent = EditorState.createEmpty().getCurrentContent()

      return {
        json: JSON.stringify(newEmptyContent.toJS()),
        html: null,
      }
    }

    return undefined
  }, [])

  // on default value change, update the editor state and the form value
  useEffect(() => {
    try {
      const formValue = transformValue(defaultValue)

      setValue(name, formValue)

      if (formValue) {
        const parsedDefaultValue = JSON.parse(defaultValue)

        const rawDraftDefaultValue = convertFromRaw(parsedDefaultValue)

        const newEditorState = EditorState.createWithContent(rawDraftDefaultValue)

        setEditorState(newEditorState)
      }
    } catch (error) {
      console.error('[HTMLEditor]:\n<Invalid default value for HTMLEditor>\n[Error]:', error)
    }

    return () => {
      unregister(name)
    }
  }, [defaultValue])

  const onEditorStateChange = currentState => {
    setEditorState(currentState)

    const currentContent = convertToRaw(currentState.getCurrentContent())

    const formValue = {
      json: JSON.stringify(currentContent),
      html: draftToHtml(currentContent),
    }

    setValue(name, formValue)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
      className={classNames(generalStyles.formControl, containerClasses ?? null)}
    >
      <InputLabel
        variant="standard"
        id={`${name}-label`}
        error={!!errors[name]}
        className={classNames(generalStyles.inputLabel, labelClasses ?? null)}
      >
        {label}
        {required && showLabelSpan && <span className={generalStyles.required}>*</span>}
      </InputLabel>
      <Editor
        editorState={editorState}
        toolbar={{ ...defaultToolbarOptions, ...toolbarOptions }}
        placeholder={placeholder ?? 'Digite sua mensagem aqui...'}
        wrapperClassName={classNames(
          localStyles.wrapperClassName,
          !!errors[name] && localStyles.wrapperOnError,
          wrapperClassNames ?? null
        )}
        toolbarClassName={classNames(localStyles.toolbarClassName, toolbarClassNames ?? null)}
        editorClassName={classNames(localStyles.editorClassName, editorClassNames ?? null)}
        onEditorStateChange={onEditorStateChange}
        editorRef={register(
          name,
          required
            ? {
                validate: {
                  required: value => {
                    try {
                      const parsedValue = JSON.parse(value?.json)
                      return parsedValue?.blocks[0]?.text.length > 0 || 'Esse campo é obrigatório'
                    } catch (error) {
                      console.error('[HTMLEditor]:\n<Validation error>\n[Error]:', error)
                      return 'Esse campo é obrigatório'
                    }
                  },
                },
              }
            : {}
        )}
      />
      <FormHelperText className={generalStyles.errorMessage} error={!!errors[name]}>
        {errors[name]?.message}
      </FormHelperText>
    </div>
  )
}

HTMLEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelClasses: PropTypes.string,
  required: PropTypes.bool,
  toolbarOptions: PropTypes.object,
  defaultValue: PropTypes.string,
  style: PropTypes.object,
  containerClasses: PropTypes.string,
  wrapperClassNames: PropTypes.string,
  toolbarClassNames: PropTypes.string,
  editorClassNames: PropTypes.string,
}

HTMLEditor.defaultProps = {
  required: false,
  toolbarOptions: {},
  defaultValue: '',
  style: {},
  placeholder: 'Digite sua mensagem aqui...',
  labelClasses: null,
  containerClasses: null,
  wrapperClassNames: null,
  toolbarClassNames: null,
  editorClassNames: null,
}

export default HTMLEditor
