import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  MenuItem,
} from '@material-ui/core'
import { FaStar } from 'react-icons/fa'
import { useParams, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import { createApplicantValuation, getRatingRequester } from '_/modules/messages/actions'
import Header, { HEADER_THEME } from '_components/header'
import CallDetails from '_assets/svgs/detalhes-do-chamado.svg'
import Svg from '_components/svg'
import { userSelector } from '_modules/authentication/selectors'
import { getServiceorderStatus } from '_modules/service-orders/actions'

import useStyles from './styles'

const ApplicantValuation = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { serviceOrderId, ratingId } = useParams()
  const stars = Array(10).fill(0)
  const colors = {
    yellow: '#F2BD00',
    gray: '#B4B4B4',
  }

  const [hoverStarFam, setHoverStarFam] = useState(undefined)
  const [hoverStarAll, setHoverStarAll] = useState(undefined)
  const user = useSelector(userSelector)
  const [serviceOrderStatus, setServiceOrderStatus] = useState()
  const [readOnly, setReadOnly] = useState(false)
  const [renderPage, setRenderPage] = useState(false)

  useEffect(() => {
    Promise.resolve(dispatch(getServiceorderStatus(serviceOrderId))).then(res => {
      setServiceOrderStatus(res?.status)
      setRenderPage(true)
    })
  }, [serviceOrderStatus])

  useEffect(() => {
    if (!readOnly && (serviceOrderStatus === 'finished' || serviceOrderStatus === 'canceled')) {
      navigate('/link-expirado')
    }
  }, [serviceOrderStatus, readOnly])

  const handleMouseOverStarFam = value => {
    setHoverStarFam(value)
  }

  const handleMouseLeaveStarFam = () => {
    setHoverStarFam(undefined)
  }

  const handleMouseOverStarAll = value => {
    setHoverStarAll(value)
  }

  const handleMouseLeaveStarAll = () => {
    setHoverStarAll(undefined)
  }

  const { handleSubmit } = useForm({ mode: 'all' })
  const handleApplicantValuation = async () => {
    if (values.solutionApproved === undefined) {
      // set default value
      values.solutionApproved = true
      values.reasonRepproved = ''
    } else if (values.solutionApproved === 'true') {
      values.solutionApproved = true
      values.reasonRepproved = ''
    } else if (values.solutionApproved === 'false') {
      values.solutionApproved = false
    }

    await dispatch(createApplicantValuation(serviceOrderId, values))

    navigate('/avaliacao-solicitante-confirmar')
  }

  const [values, setValues] = useState({
    id: undefined,
    solutionApproved: undefined,
    reasonRepproved: '',
    oddsRecommend: undefined,
    ratingRequester: undefined,
    howImprove: undefined,
    serviceOrder: serviceOrderId || undefined,
    createdAt: undefined,
    updatedAt: undefined,
  })

  useEffect(() => {
    if (ratingId) {
      Promise.resolve(dispatch(getRatingRequester(ratingId))).then(res => {
        if (res.solutionApproved === true) {
          res.solutionApproved = 'true'
        } else {
          res.solutionApproved = 'false'
        }
        setReadOnly(true)
        handleFormValues(undefined, res)
      })
    }
  }, [user, readOnly])

  const handleFormValues = (field, inputValues) => {
    if (!field)
      setValues(old => ({
        ...old,
        ...inputValues,
      }))
    else if (field) {
      setValues(old => ({
        ...old,
        [field]: inputValues,
      }))
    }
  }
  return !serviceOrderStatus ||
    serviceOrderStatus !== 'finished' ||
    serviceOrderStatus !== 'canceled' ? (
    <>
      {renderPage && (
        <>
          <Header theme={HEADER_THEME.NO_BUTTONS} />
          <Grid component="main" className={styles.container}>
            <Svg icon={CallDetails} className={styles.image} />
            <Typography component="h1" className={styles.title}>
              Avaliação do processo de Manutenção
            </Typography>
            {!readOnly && (
              <Typography className={styles.subTitle}>
                Por favor, avalie nosso processo de Manutenção para que possamos entender como
                podemos melhorar.
              </Typography>
            )}
          </Grid>
          <form id="applicantValuation" onSubmit={handleSubmit(handleApplicantValuation)}>
            <>
              <Grid component="main" className={styles.noContainer}>
                <Typography className={styles.textTitle}>
                  Você aprova a solução da sua solicitação?{' '}
                  <span className={styles.aster}> * </span>
                </Typography>
                {readOnly ? (
                  <Typography className={styles.textMessage}>
                    {values.solutionApproved === 'true' ? 'Sim' : 'Não'}
                  </Typography>
                ) : (
                  <>
                    <FormControl>
                      <RadioGroup
                        required
                        value={values.solutionApproved}
                        onChange={e => handleFormValues('solutionApproved', e.target.value)}
                        row
                        defaultValue="true"
                        name="solutionApproved"
                      >
                        <FormControlLabel
                          value="true"
                          disabled={readOnly}
                          control={<Radio color="#1671ED" className={styles.radioButton} />}
                          label="Sim"
                          className={
                            values.solutionApproved === 'true' ||
                            values.solutionApproved === undefined
                              ? styles.radioButton
                              : ''
                          }
                        />
                        <FormControlLabel
                          value="false"
                          disabled={readOnly}
                          control={<Radio color="#1671ED" className={styles.radioButton} />}
                          label="Não"
                          className={values.solutionApproved === 'false' ? styles.radioButton : ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </>
                )}
                {values.solutionApproved === 'false' && (
                  <>
                    <Typography className={styles.textTitle}>
                      Motivo da sua reprovação: <span className={styles.aster}> * </span>
                    </Typography>
                    <TextField
                      multiline
                      fullWidth
                      required
                      rows={5}
                      defaultValue=""
                      placeholder="Descreva aqui o motivo da sua reprovação."
                      inputProps={{
                        maxLength: 2000,
                      }}
                      variant="outlined"
                      className={styles.textInput}
                      name="reasonRepproved"
                      onChange={e => handleFormValues('reasonRepproved', e.target.value)}
                      disabled={readOnly}
                      value={values.reasonRepproved}
                    />
                  </>
                )}
                <Typography className={styles.textTitle}>
                  Qual seria a probabilidade de você recomendar o prestador que lhe atendeu para
                  amigos ou familiares?
                </Typography>
                <Grid className={styles.stars}>
                  {stars.map((_, index) => {
                    return (
                      <FaStar
                        key={Math.random().toString(16).slice(2)}
                        size={42}
                        style={{
                          marginRight: 20,
                          cursor: 'pointer',
                        }}
                        color={
                          (hoverStarFam || values.oddsRecommend) > index
                            ? colors.yellow
                            : colors.gray
                        }
                        onClick={() => !readOnly && handleFormValues('oddsRecommend', index + 1)}
                        onMouseOver={() => !readOnly && handleMouseOverStarFam(index + 1)}
                        onMouseLeave={() => !readOnly && handleMouseLeaveStarFam()}
                      />
                    )
                  })}
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={3} md={3}>
                    <MenuItem className={styles.optional}>Não recomendo</MenuItem>
                  </Grid>
                  <Grid item xs={5}>
                    <MenuItem> </MenuItem>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <MenuItem className={styles.optionalRight}>Recomendo muito</MenuItem>
                  </Grid>
                </Grid>
                <Typography className={styles.textTitle}>
                  Qual a nota você daria para o processo de manutenção como um todo?{' '}
                  <span className={styles.aster}> * </span>
                </Typography>
                <Grid className={styles.stars}>
                  {stars.map((_, index) => {
                    return (
                      <FaStar
                        key={Math.random().toString(16).slice(2)}
                        size={42}
                        style={{
                          marginRight: 20,
                          cursor: 'pointer',
                          borderColor: '#000000',
                        }}
                        color={
                          (hoverStarAll || values.ratingRequester) > index
                            ? colors.yellow
                            : colors.gray
                        }
                        onClick={() => !readOnly && handleFormValues('ratingRequester', index + 1)}
                        onMouseOver={() => !readOnly && handleMouseOverStarAll(index + 1)}
                        onMouseLeave={() => !readOnly && handleMouseLeaveStarAll()}
                      />
                    )
                  })}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={3} md={3}>
                    <MenuItem className={styles.optional}>Não recomendo</MenuItem>
                  </Grid>
                  <Grid item xs={5}>
                    <MenuItem> </MenuItem>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <MenuItem className={styles.optionalRight}>Recomendo muito</MenuItem>
                  </Grid>
                </Grid>
                <Typography className={styles.textTitle}>
                  Como podemos melhorar nosso processo de Manutenção?
                </Typography>
                {readOnly ? (
                  <Typography className={styles.textMessage}>{values.howImprove}</Typography>
                ) : (
                  <TextField
                    multiline
                    fullWidth
                    rows={5}
                    defaultValue=""
                    placeholder="Descreva aqui alguma observação a respeito do serviço."
                    inputProps={{
                      maxLength: 2000,
                    }}
                    variant="outlined"
                    className={styles.textInput}
                    name="howImprove"
                    onChange={e => handleFormValues('howImprove', e.target.value)}
                    disabled={readOnly}
                    value={values.howImprove}
                  />
                )}
              </Grid>
              <Grid className={styles.buttonContainer}>
                {!ratingId && (
                  <Button
                    className={styles.button}
                    form="applicantValuation"
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Enviar
                  </Button>
                )}
              </Grid>
            </>
          </form>
        </>
      )}
    </>
  ) : null
}

export default React.memo(ApplicantValuation)
