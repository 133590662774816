import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import Button from '_components/button'
import { updateBudget } from '_modules/budget/actions'

import useStyles from './styles'

const CancelSentEmailRevised = ({
  isOpen,
  onClose,
  serviceOrderId,
  budgetId,
  handleCancelRevise,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(false)

  const onCancelButtonRevision = useCallback(() => {
    setLoading(true)
    dispatch(updateBudget(serviceOrderId, budgetId, { isRevised: false }))
      .then(() => {
        if (isOpen) {
          handleCancelRevise()
          onClose()
          setLoading(false)
        }
      })
      .catch(() => setLoading(false))
  }, [budgetId, dispatch, serviceOrderId, isOpen])

  return (
    <Dialog open={isOpen} onClose={onClose} aria-describedby="cancelReviseBudgetDialog">
      <DialogContent>
        <DialogContentText id="cancelReviseBudgetDialog" className={styles.description}>
          Este orçamento já foi enviado ao pagador. Você tem certeza que deseja cancelar sua revisão
          e habilitar sua edição?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Não
        </Button>
        <Button
          onClick={onCancelButtonRevision}
          isLoading={isLoading}
          color="primary"
          variant="contained"
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CancelSentEmailRevised.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  budgetId: PropTypes.number.isRequired,
  handleCancelRevise: PropTypes.func.isRequired,
}

export default React.memo(CancelSentEmailRevised)
