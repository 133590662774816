import moment from 'moment'

export const SERVICE_ORDER_STATUS = {
  active: 'Chamados ativos',
  finished: 'Chamados finalizados',
  cancelled: 'Chamados cancelados',
}

export const FORM_FIELD_NAME = {
  AGENCY: 'agency',
  FROM_TO: 'fromTo',
  TYPE_SUBJECT: 'typeSubject',
  MESSAGE: 'message',
  OPENED_AT_END: 'openedAtEnd',
  OPENED_AT_START: 'openedAtStart',
  EXPECTED_DEADLINE_START: 'expectedDeadlineAtStart',
  EXPECTED_DEADLINE_END: 'expectedDeadlineAtEnd',
  RESOLVED_AT_END: 'resolvedAtEnd',
  RESOLVED_AT_START: 'resolvedAtStart',
  RESPONSIBLE: 'responsible',
  REQUESTER: 'requester',
  SERVICE_ORDER: 'serviceOrder',
  SERVICE_ORDER_STATUS: 'serviceOrderStatus',
  INTERMEDIARY_AVAILABLE: 'availableIntermediary',
  STATUS: 'status',
  SUBJECT: 'subject',
}

export const INITIAL_STATE = {
  [FORM_FIELD_NAME.AGENCY]: [],
  [FORM_FIELD_NAME.TYPE_SUBJECT]: '',
  [FORM_FIELD_NAME.FROM_TO]: '',
  [FORM_FIELD_NAME.MESSAGE]: '',
  [FORM_FIELD_NAME.OPENED_AT_END]: null,
  [FORM_FIELD_NAME.OPENED_AT_START]: null,
  [FORM_FIELD_NAME.EXPECTED_DEADLINE_END]: null,
  [FORM_FIELD_NAME.EXPECTED_DEADLINE_START]: null,
  [FORM_FIELD_NAME.RESOLVED_AT_END]: null,
  [FORM_FIELD_NAME.RESOLVED_AT_START]: null,
  [FORM_FIELD_NAME.RESPONSIBLE]: '',
  [FORM_FIELD_NAME.SERVICE_ORDER]: '',
  [FORM_FIELD_NAME.SERVICE_ORDER_STATUS]: '',
  [FORM_FIELD_NAME.REQUESTER]: '',
  [FORM_FIELD_NAME.STATUS]: '',
  [FORM_FIELD_NAME.INTERMEDIARY_AVAILABLE]: '',
  [FORM_FIELD_NAME.SUBJECT]: '',
  isValid: true,
  openedAt: '',
  resolvedAt: '',
}

const OPENED_AT_FIELDS = [FORM_FIELD_NAME.OPENED_AT_END, FORM_FIELD_NAME.OPENED_AT_START]
const RESOLVED_AT_FIELDS = [FORM_FIELD_NAME.RESOLVED_AT_END, FORM_FIELD_NAME.RESOLVED_AT_START]
const EXPECTED_DEADLINE_AT_FIELDS = [
  FORM_FIELD_NAME.EXPECTED_DEADLINE_END,
  FORM_FIELD_NAME.EXPECTED_DEADLINE_START,
]

export const UPDATE_STATE = 'updateState'
export const RESET_STATE = 'resetState'

const handleValidDates = (startDate, endDate) => {
  if (startDate && endDate) {
    return moment(startDate).isValid() || moment(endDate).isValid()
  }

  return false
}

export const reducer = (state, { type, payload }) => {
  const dateValidation = () => {
    const newState = {
      ...state,
      ...payload,
    }

    return Object.keys(payload).reduce((acc, payloadKey) => {
      if (!acc) {
        return acc
      }

      if (OPENED_AT_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.OPENED_AT_START],
          newState[FORM_FIELD_NAME.OPENED_AT_END]
        )
      }

      if (RESOLVED_AT_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.RESOLVED_AT_START],
          newState[FORM_FIELD_NAME.RESOLVED_AT_END]
        )
      }

      if (EXPECTED_DEADLINE_AT_FIELDS.includes(payloadKey)) {
        return handleValidDates(
          newState[FORM_FIELD_NAME.EXPECTED_DEADLINE_START],
          newState[FORM_FIELD_NAME.EXPECTED_DEADLINE_END]
        )
      }

      return acc
    }, true)
  }

  switch (type) {
    case UPDATE_STATE:
      return {
        ...state,
        ...payload,
        isValid: dateValidation(),
      }
    case RESET_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
