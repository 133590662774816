import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    paddingBottom: spacing(10),
    paddingTop: spacing(16),
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    fontSize: spacing(12),
  },
}))
