export const BUTTON_VALUES = {
  ADVANCE_ORDER: 'ADVANCE_ORDER',
  JUSTIFIED_DELAY: 'JUSTIFIED_DELAY',
  HELP_REQUEST: 'HELP_REQUEST',
  COMMENT: 'COMMENT',
}

export const BUTTON_OPTIONS = [
  {
    label: '+ Antecipação de pagamento',
    value: BUTTON_VALUES.ADVANCE_ORDER,
    adminOnly: true,
  },
  {
    label: '+ Atraso justificado',
    value: BUTTON_VALUES.JUSTIFIED_DELAY,
    adminOnly: false,
  },
  {
    label: '+ Item de ajuda',
    value: BUTTON_VALUES.HELP_REQUEST,
    adminOnly: false,
  },
  {
    label: '+ Comentário',
    value: BUTTON_VALUES.COMMENT,
    adminOnly: false,
  },
]

const ADVANCE_ORDER_BUTTON = BUTTON_OPTIONS.find(
  button => button.value === BUTTON_VALUES.ADVANCE_ORDER
)
const JUSTIFIED_DELAY_BUTTON = BUTTON_OPTIONS.find(
  button => button.value === BUTTON_VALUES.JUSTIFIED_DELAY
)
const HELP_REQUEST_BUTTON = BUTTON_OPTIONS.find(
  button => button.value === BUTTON_VALUES.HELP_REQUEST
)
const COMMENT_BUTTON = BUTTON_OPTIONS.find(button => button.value === BUTTON_VALUES.COMMENT)

export const TAB_OPTIONS = {
  ALL: {
    label: 'Todos',
    value: 'orderedList',
    defaultButton: HELP_REQUEST_BUTTON,
  },
  IMPORTANT: {
    label: 'Importante',
    value: 'important',
    defaultButton: HELP_REQUEST_BUTTON,
  },
  COMMENTS: {
    label: 'Comentários',
    value: 'notes',
    defaultButton: COMMENT_BUTTON,
  },
  JUSTIFIED_DELAY: {
    label: 'Atraso',
    value: 'justifiedDelay',
    defaultButton: JUSTIFIED_DELAY_BUTTON,
  },
  PAYMENT_ANTICIPATION: {
    label: 'Antecipações',
    value: 'advanceOrders',
    defaultButton: ADVANCE_ORDER_BUTTON,
  },
  HELP_REQUEST: {
    label: 'Item de Ajuda',
    value: 'helpRequest',
    defaultButton: HELP_REQUEST_BUTTON,
  },
}

export const INITIAL_STATE = {
  currentButton: BUTTON_OPTIONS[3],
  currentTab: TAB_OPTIONS.ALL,
  isMenuButtonOpen: false,
  isCommentModalOpen: false,
  notes: [],
}
