import { Record } from 'immutable'

import { createReducer } from '_utils/redux'

import { GET_FORMATTED_ADDRESS } from './actions'

const INITIAL_STATE = new Record({
  formattedAddress: undefined,
})()

const property = createReducer(INITIAL_STATE, {
  [GET_FORMATTED_ADDRESS.FULFILLED]: (state, { payload }) => {
    return state.set('formattedAddress', payload.detail)
  },
})

export default property
