import React, { useEffect, useMemo, useState } from 'react'
import { Typography } from '@material-ui/core'
import { DatePicker, MoneyInput } from '@refera/ui-web'

import useStyles from './styles'

import { useForm } from 'react-hook-form'
import { formatCurrency } from '_/utils/helpers'
import moment from 'moment'
import { useToast } from '_/hooks/use-toast'
import { useDispatch } from 'react-redux'
import { updateBudgetInstallment, updateBudgetReceivable } from '_/modules/finance/actions'
import { useParams } from '@reach/router'
import { CommonModal } from './CommonModal'
import { formatErrorMessage } from '../../utils/FormatErrorMessage'

const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório'

export const EditInstallmentModal = props => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { installmentId, budgetReceivableId } = useParams()

  const screen = useMemo(
    () => (budgetReceivableId ? 'receipt' : 'installment'),
    [budgetReceivableId]
  )

  const defaultValues = useMemo(() => {
    const values = {}

    props?.installmentInfo?.forEach(item => {
      const { name, value } = item
      values[name] = value
    })

    return values
  }, [props?.installmentInfo])

  const methods = useForm({
    defaultValues,
    mode: 'all',
  })

  const { register, errors, setError, clearErrors, handleSubmit, setValue, watch } = methods
  const formData = watch()

  const { showToast } = useToast()
  const styles = useStyles({ errors, modal: 'installment' })

  const renderErrorMessage = useMemo(
    () => fieldName => {
      if (errors?.[fieldName]) {
        return (
          <Typography role="alert" className={styles.errorMessage}>
            {errors?.[fieldName]?.message}
          </Typography>
        )
      }
      return null
    },
    [errors]
  )

  const handleValidateDate = () => {
    const invalidDateMessage = 'Insira uma data válida'

    try {
      if (!formData?.dueDate) return REQUIRED_FIELD_MESSAGE

      const momentValue = moment(formData?.dueDate)
      if (!momentValue?.isValid()) return invalidDateMessage

      clearErrors('dueDate')
      return true
    } catch {
      return invalidDateMessage
    }
  }

  const handleValidateInput = () => {
    const monetaryField = screen === 'installment' ? 'providerValue' : 'value'

    if (!formData?.[monetaryField]) {
      setError(monetaryField, { message: REQUIRED_FIELD_MESSAGE })
      return false
    }
    if (formData?.[monetaryField] < 0) {
      setError(monetaryField, {
        message: 'Deve ser igual ou maior que zero.',
      })
      return false
    }
    clearErrors(monetaryField)
    return true
  }

  const validateInputs = () => handleValidateDate() && handleValidateInput()

  const handleInputType = useMemo(
    () => paymentItem => {
      const { label, type, name } = paymentItem

      switch (type) {
        case 'date':
          return (
            <>
              <Typography className={styles.headerText}>{label}</Typography>
              <DatePicker
                variant="inline"
                mask="__/__/____"
                format="dd/MM/yyyy"
                defaultValue={null}
                refuse={/[^\d\\.]+/gi}
                placeholder="DD/MM/AAAA"
                className={styles.datePicker}
                value={formData[name] || null}
                {...register(name, { validate: handleValidateDate })}
                onChange={newValue => setValue(name, newValue)}
              />
              {renderErrorMessage(name)}
            </>
          )
        case 'money':
          return (
            <>
              <Typography className={styles.headerText}>{label}</Typography>
              <MoneyInput
                fullWidth
                // defaultValue={0}
                decimalScale={2}
                {...register(name)}
                placeholder="R$ 0,00"
                style={{ fontSize: '16px' }}
                onBlur={handleValidateInput}
                className={styles.listInput}
                value={formData[name] ? formatCurrency(formData[name]) : null}
                onChange={e => setValue(name, e.floatValue)}
              />
              {renderErrorMessage(name)}
            </>
          )
        case 'money-readonly':
          return <></>

        default:
          return null
      }
    },
    [formData, errors, handleValidateDate]
  )

  const onSubmit = async () => {
    if (!validateInputs()) return

    setLoading(true)

    const payload = formData
    payload.dueDate = moment(payload.dueDate).format('YYYY-MM-DD')

    const action = screen === 'installment' ? updateBudgetInstallment : updateBudgetReceivable
    const payloadId = screen === 'installment' ? installmentId : budgetReceivableId

    await dispatch(action(payloadId, payload))
      .then(() => {
        showToast({ type: 'success' })
        setLoading(false)
        props?.onClose()
      })
      .catch(error => {
        showToast({ type: 'error', message: formatErrorMessage(error) })
        setLoading(false)
      })
  }

  useEffect(() => {
    handleValidateDate()
  }, [formData?.dueDate])

  return (
    <CommonModal
      {...props}
      onSubmit={handleSubmit(onSubmit)}
      handleInputType={handleInputType}
      loading={loading}
      modalType="installment"
    />
  )
}
