import React, { useMemo, useState } from 'react'

import { SwipeableDrawer, Divider, Grid, Slide, Typography } from '@material-ui/core'
import { Button } from '@refera/ui-web'

import { FilterSearch as FilterIcon, CloseCircle } from '@refera/ui-icons'
import Theme from '@refera/ui-core'

import useStyles from './styles'
import classNames from 'classnames'

/**
* @param {Function} open - Function to indicate that the filter drawer is open.
* @param {Function} close - Function to close the filter drawer.
* @param {Function} cleanFilters - Function for the button to clear the filter fields.
* @param {Function} applyFilters - Function for the button to apply the filter fields.
* @param {boolean} disabled - Indicates if the apply filter button is disabled
* @param {JSX.Element} mainFilters - JSX element of filter fields.
* @param {JSX.Element} [moreFilters=null] - JSX element of more filters, creating new tab.

* @returns {JSX.Element} - The rendered component.
*/

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

export const SideFilter = ({
  open,
  close,
  cleanFilters,
  applyFilters,
  disabled,
  mainFilters,
  moreFilters = null,
}) => {
  const styles = useStyles()
  const [selectedTab, setSelectedTab] = useState('main')

  const renderTabFilter = useMemo(() => {
    if (moreFilters) return selectedTab === 'more' ? moreFilters : mainFilters
    return mainFilters
  }, [selectedTab, mainFilters, moreFilters])

  return (
    <SwipeableDrawer
      component="section"
      anchor="right"
      open={open}
      onClose={close}
      className={styles.container}
      TransitionComponent={Transition}
    >
      <Grid className={styles.header}>
        <Grid className={styles.headerTitle}>
          <Grid className={styles.iconWrapper}>
            <FilterIcon color={Theme.Colors.Primary.Base} />
          </Grid>
          <Typography component="h1" variant="h5" className={styles.title}>
            Filtros
          </Typography>
        </Grid>
        <Button onClick={close} variant="ghost">
          <CloseCircle color={Theme.Colors.Primary.Base} className={styles.closeIcon} />
        </Button>
      </Grid>
      <Divider className={styles.divider} />
      {mainFilters && moreFilters && (
        <Grid className={styles.tabFilters}>
          <Grid
            className={classNames(styles.tab, selectedTab === 'main' && styles.selectedTab)}
            onClick={() => setSelectedTab('main')}
          >
            <Typography>Filtros principais</Typography>
          </Grid>
          <Grid
            className={classNames(styles.tab, selectedTab === 'more' && styles.selectedTab)}
            onClick={() => setSelectedTab('more')}
          >
            <Typography>Mais filtros</Typography>
          </Grid>
        </Grid>
      )}
      <Grid className={styles.content}>{renderTabFilter}</Grid>

      <Grid className={styles.buttonsContainer}>
        <Button variant="ghost" color="primary" onClick={cleanFilters}>
          Limpar filtros
        </Button>
        <Button variant="primary" color="orange" disabled={disabled} onClick={applyFilters}>
          Aplicar filtro
        </Button>
      </Grid>
    </SwipeableDrawer>
  )
}
