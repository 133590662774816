import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Modal from '_components/modal/remove-picture-modal'

import useStyles from './styles'

const RemovePictureModal = ({ handleModal, handleRemove }) => {
  const styles = useStyles()

  const renderTitle = 'Você deseja realmente apagar o arquivo?'

  const handleRemovePicture = useCallback(() => {
    handleRemove()
    handleModal()
  }, [handleModal, handleRemove])

  return (
    <Modal
      handleModal={handleModal}
      handleSubmit={handleRemovePicture}
      title={renderTitle}
      className={styles.modal}
    />
  )
}

RemovePictureModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
}

export default React.memo(RemovePictureModal)
