/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { useDispatch } from 'react-redux'

import Button, { BUTTON_COLOR } from '_components/button'
import { deleteMessage } from '_/modules/messages/actions'

import useStyles from './styles'

const CHECK = 'Check'

export const SUCCESS_MODAL = { icon: 'Check', title: '' }

export default function ConfirmCancelJustified(props) {
  const { confirmCancelJustified, setConfirmCancelJustified } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  function GetIconButton() {
    if (confirmCancelJustified.type?.icon === CHECK)
      return (
        <IconButton disableRipple className={classes.titleIconSuccess}>
          <Check className={classes.iconeSVG} />
        </IconButton>
      )
  }

  const handleConfirm = async justifiedId => {
    dispatch(deleteMessage(justifiedId))

    window.location.reload()
  }

  return (
    <Dialog open={confirmCancelJustified.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Box mt={10} />
        <GetIconButton />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="">{confirmCancelJustified.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          color={BUTTON_COLOR.RED}
          variant="outlined"
          className={classes.button}
          onClick={() => setConfirmCancelJustified({ ...confirmCancelJustified, isOpen: false })}
        >
          {confirmCancelJustified.textNoButton ? confirmCancelJustified.textNoButton : 'Não'}
        </Button>

        {confirmCancelJustified ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => handleConfirm(confirmCancelJustified?.messageId)}
          >
            {confirmCancelJustified.textYesButton ? confirmCancelJustified.textYesButton : 'Sim'}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setConfirmCancelJustified({ ...confirmCancelJustified, isOpen: false })}
          >
            {confirmCancelJustified.textYesButton ? confirmCancelJustified.textYesButton : 'Sim'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
