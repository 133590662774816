import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import useToggle from '_hooks/use-toggle'
import ShowMoreDetailRefuseModal from '_components/modal/show-more-refuse-detail'

import ServiceOrderAlert from '..'

const ServiceOrderCanceledAlert = () => {
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const [showCancelDetails, toggleShowCancelDetails] = useToggle()

  const showAlert = useMemo(() => {
    return serviceOrder?.isCancelled
  }, [serviceOrder?.isCancelled])

  return (
    <>
      {showAlert && (
        <ServiceOrderAlert
          title="Chamado cancelado"
          description={`Este chamado foi cancelado pelo seguinte motivo:
          ${' '}${serviceOrder?.reasonForCancellation?.get('reason')}.`}
          onClick={toggleShowCancelDetails}
          color="error"
        />
      )}
      {showCancelDetails && (
        <ShowMoreDetailRefuseModal
          handleModal={toggleShowCancelDetails}
          refusalMoreDetails={serviceOrder?.serviceOrderCancelDetails}
        />
      )}
    </>
  )
}

export default ServiceOrderCanceledAlert
