/* eslint-disable no-param-reassign */

import { createReducer } from '_utils/redux'

import { GET_GROUP, GET_GROUP_ACTION_PERMISSION, GET_GROUP_DATA_PERMISSION } from './actions'
import { Record } from 'immutable'

const INITIAL_STATE = new Record({
  groupDataPermissions: {},
  groupActionPermissions: {},
  group: {},
})()

export const groups = createReducer(INITIAL_STATE, {
  [GET_GROUP_DATA_PERMISSION.FULFILLED]: (state, { payload }) => {
    return state.update('groupDataPermissions', () => payload)
  },
  [GET_GROUP_ACTION_PERMISSION.FULFILLED]: (state, { payload }) => {
    return state.update('groupActionPermissions', () => payload)
  },
  [GET_GROUP.FULFILLED]: (state, { payload }) => {
    return state.update('group', () => payload)
  },
})
