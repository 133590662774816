import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  label: {
    color: palette.gray[32],
    fontWeight: 500,
    marginBottom: spacing(8),
  },
  images: {
    display: 'grid',
    gridTemplateColumns: `repeat(9, ${spacing(74)})`,
    columnGap: spacing(10),
    rowGap: spacing(10),
    [breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(auto-fill, ${spacing(74)})`,
    },
  },
  container: {
    width: '100%',
  },
  button: {
    height: spacing(74),
    width: spacing(74),
    backgroundColor: palette.gray[4],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: spacing(4),
  },
  media: {
    height: spacing(74),
    width: spacing(74),
  },
  fileSelector: {
    display: 'none',
  },
  icon: {
    color: palette.gray[32],
    height: spacing(52),
    width: spacing(52),
  },
}))
