import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button as MuiButton, Grid } from '@material-ui/core'
import { Loader } from '@refera/ui-web'
import { Link } from '@reach/router'
import humps from 'humps'

import useStyles from './styles'

export const BUTTON_COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  RED: 'red',
  INHERIT: 'inherit',
  DEFAULT: 'default',
}

const ACCEPTED_COLORS_MATERIAL_UI = [
  BUTTON_COLOR.PRIMARY,
  BUTTON_COLOR.SECONDARY,
  BUTTON_COLOR.INHERIT,
  BUTTON_COLOR.DEFAULT,
]

const Button = ({
  children,
  className,
  isLoading,
  type,
  disabled,
  color,
  to,
  variant,
  ...props
}) => {
  const styles = useStyles()

  const renderStyle = useMemo(() => {
    if (ACCEPTED_COLORS_MATERIAL_UI.includes(color)) {
      return { color, className: classnames(styles.button, className), variant }
    }
    return {
      color: BUTTON_COLOR.DEFAULT,
      variant,
      className: classnames(
        styles.button,
        className,
        styles[humps.camelize(`${color}_${variant}`)]
      ),
    }
  }, [className, color, styles, variant])

  return (
    <MuiButton
      type={type}
      disableElevation
      to={to}
      component={to ? Link : 'button'}
      disabled={isLoading || disabled}
      {...renderStyle}
      {...props}
    >
      {isLoading ? (
        <Grid
          className={classnames(styles.loading, {
            [styles.loadingDefault]: renderStyle.color === BUTTON_COLOR.DEFAULT,
          })}
        >
          <Loader size={25} />
        </Grid>
      ) : (
        children
      )}
    </MuiButton>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  to: PropTypes.string,
  color: PropTypes.oneOf(Object.values(BUTTON_COLOR)),
  variant: PropTypes.string,
}

Button.defaultProps = {
  className: '',
  isLoading: false,
  disabled: false,
  type: 'button',
  variant: 'text',
  to: '',
  color: BUTTON_COLOR.DEFAULT,
}

export default memo(Button)
