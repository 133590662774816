import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  modal: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  datePicker: {
    width: '100%',
  },
  select: {
    color: Theme.Colors.Grayscale.Eighty,
    fontSize: '16px',
  },
}))
