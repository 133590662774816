import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Typography } from '@refera/ui-web'
import Draggable from 'react-draggable'

import Theme from '@refera/ui-core'

import DotsGroup from '_assets/svgs/dots-group.svg'
import { CardSlash, CardReceive, GridEdit } from '@refera/ui-icons'
import Svg from '_/components/svg'

import useStyles from '../styles'
import { formatCurrency } from '_/utils/helpers'

const AsaasFloatingMenu = ({ selectedItems, items, handleButtonsActions }) => {
  const styles = useStyles()

  const priceToReceive = useMemo(() => {
    return items.reduce(
      ({ totalPrice, totalValue }, item) => {
        const itemValue = item?.value || 0
        const itemTotal = item?.total || 0

        return {
          totalPrice: totalPrice + itemTotal,
          totalValue: totalValue + itemValue,
        }
      },
      {
        totalPrice: 0,
        totalValue: 0,
      }
    )
  }, [items])

  return (
    <Draggable handle="#draggable-floating-menu" cancel={'[class*="MuiDialogContent-root"]'}>
      <Grid className={styles.floatingContainer}>
        <Grid className={styles.floatingWrapper}>
          <Grid className={styles.draggable} id="draggable-floating-menu">
            <Svg className={styles.dotsIcon} icon={DotsGroup} />
          </Grid>
          <Grid className={styles.floatingContent}>
            <Grid className={styles.floatingLabels}>
              <Typography fontSize={14}>
                Selecionado:{' '}
                <b>
                  {selectedItems.length}
                  {selectedItems.length > 1 ? ' itens' : ' item'}
                </b>
              </Typography>
              <Typography fontSize={14}>|</Typography>
              <Typography fontSize={14}>
                Valor final a receber:{' '}
                <b style={{ color: Theme.Colors.Primary.Base }} fontSize={14}>
                  {formatCurrency(priceToReceive.totalValue)}
                </b>
              </Typography>
            </Grid>
            <Grid className={styles.floatingLabels}>
              <Button
                className={styles.redButton}
                variant="ghost"
                startIcon={<CardSlash color={Theme.Colors.Red.Base} style={{ fontSize: '20px' }} />}
                onClick={() => handleButtonsActions('block')}
              >
                Bloquear repasses financeiros
              </Button>
              <Button
                variant="ghost"
                startIcon={
                  <CardReceive color={Theme.Colors.Primary.Base} style={{ fontSize: '20px' }} />
                }
                onClick={() => handleButtonsActions('release')}
              >
                Liberar repasses
              </Button>
              <Button
                variant="ghost"
                startIcon={
                  <GridEdit color={Theme.Colors.Primary.Base} style={{ fontSize: '20px' }} />
                }
                onClick={() => handleButtonsActions('download')}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Draggable>
  )
}

export default AsaasFloatingMenu
