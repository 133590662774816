import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { getStepStatusLogSelector } from '_modules/budget/selectors'
import { userSelector } from '_modules/authentication/selectors'

export const useIsCurrentBudgetTradesman = budgetSelector => {
  const budget = useSelector(budgetSelector)?.toJS()
  const serviceOrder = useSelector(currentServiceOrderSelector)?.toJS()
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const user = useSelector(userSelector)?.toJS()

  const lastTradesman = useMemo(
    () => stepStatusLog?.[0]?.tradesmanId,
    [stepStatusLog?.[0]?.tradesmanId]
  )

  const isCurrentTradesman = useMemo(
    () =>
      (!budget?.proposeOpened && serviceOrder?.tradesman === user?.getServiceProviderId) ||
      (budget?.proposeOpened && lastTradesman === user?.getServiceProviderId),
    [budget?.proposeOpened, lastTradesman, serviceOrder?.tradesman, user?.getServiceProviderId]
  )

  return isCurrentTradesman
}
