import React, { useCallback } from 'react'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import { Button } from '@refera/ui-web'
import { ROUTES } from '_/utils/constants'
import { useSelector } from 'react-redux'
import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { navigate } from '@reach/router'
import useStyles from './styles'

export function SendRatingRequestButton() {
  const styles = useStyles()
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const handleSendRatingRequest = useCallback(() => {
    navigate(`${ROUTES.SEND_RATING_LINK}/${serviceOrder?.id}`)
  }, [serviceOrder])

  return (
    <Button
      className={styles.button}
      color="primary"
      variant="secondary"
      onClick={handleSendRatingRequest}
    >
      <StarOutlineIcon className={styles.icon} />
      Enviar link de avaliação
    </Button>
  )
}
