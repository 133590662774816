import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import useStyles from './styles'

export function LoadingBackdrop({ ...rest }) {
  const styles = useStyles()

  return (
    <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} {...rest}>
      <CircularProgress className={styles.circular} />
    </Backdrop>
  )
}
