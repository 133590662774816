import React, { useCallback, useContext, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TimePicker } from '@material-ui/pickers'
import { Typography } from '@mui/material'
import moment from 'moment'
import Theme from '@refera/ui-core'
import { Input, IconButton, MaskedInput } from '@refera/ui-web'
import { MinusCirlce } from '@refera/ui-icons'

import { formatTimeToDate } from '_utils/helpers'

import useStyles from './styles'

// NOTE: Needed to create a component and add a Context to stylize and pass other props to MaskedInput's input.
// It doesn't work with memo or calling a component like <Component {...props} />
export const PhoneFieldContext = React.createContext()

const CustomInput = props => {
  const styles = useStyles()
  const { index, isToAddTelephone, label, removePhone } = useContext(PhoneFieldContext)

  const handleRemovePhone = useCallback(() => {
    removePhone(index)
  }, [index, removePhone])

  const inputStyle = {
    fontSize: '1.4rem',
  }

  const renderEndAdornment = useMemo(() => {
    if (!isToAddTelephone) {
      return null
    }

    return (
      <IconButton variant="ghost" className={styles.iconButton} onClick={handleRemovePhone}>
        <MinusCirlce color={Theme.Colors.Red.Base} />
      </IconButton>
    )
  }, [isToAddTelephone, handleRemovePhone, styles.iconButton])

  return (
    <Input
      {...props}
      label={label}
      style={inputStyle}
      endAdornment={renderEndAdornment}
      className={styles.fullWidth}
    />
  )
}

export const PhoneInputField = ({ name, rules = {}, defaultValue }) => {
  const { control, errors } = useFormContext()

  const [prefix, index, suffix] = useMemo(() => name.split('.'), [name])

  const fieldError = useMemo(
    () => errors?.[prefix]?.[Number(index)]?.[suffix]?.message,
    [errors?.[prefix], name]
  )

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      control={control}
      render={({ onChange, value }) => (
        <MaskedInput
          value={value}
          onChange={currentValue => onChange(currentValue.formattedValue)}
          fullWidth
          error={Boolean(fieldError)}
          errorMessage={fieldError}
          customInput={CustomInput}
          format="+## (##) # ####-####"
          mask=" "
        />
      )}
    />
  )
}

export const TimePickerField = ({ fieldToCompare, defaultValue, name, ...props }) => {
  const styles = useStyles()
  const { control, errors, watch } = useFormContext()

  const [prefix, index, suffix] = useMemo(() => name.split('.'), [name])
  const fieldToCompareWatch = watch(prefix)

  const fieldError = useMemo(
    () => errors?.[prefix]?.[Number(index)]?.[suffix]?.message,
    [errors?.[prefix], name]
  )

  const timeToCompare = useMemo(() => {
    if (!fieldToCompare) {
      return null
    }

    const [, fieldIndex, fieldSuffix] = fieldToCompare.split('.')

    return fieldToCompareWatch[fieldIndex][fieldSuffix]
  }, [fieldToCompare, fieldToCompareWatch])

  const validateHourRange = useCallback(
    value => {
      const timeToCompareAsDate =
        typeof timeToCompare === 'string' ? formatTimeToDate(timeToCompare) : timeToCompare

      const valueAsMoment = moment(value)
      const timeToCompareAsMoment = moment(timeToCompareAsDate)

      if (valueAsMoment.isAfter(timeToCompareAsMoment)) {
        const diffHours = valueAsMoment.diff(timeToCompareAsMoment, 'hours', true)
        return Math.abs(diffHours) >= 2 || 'Horário inválido'
      }

      const diffHours = timeToCompareAsMoment.diff(valueAsMoment, 'hours', true)
      return Math.abs(diffHours) >= 2 || 'Horário inválido'
    },
    [timeToCompare]
  )

  const rules = useMemo(
    () => ({
      validate: {
        ...(timeToCompare && { validateHourRange }),
      },
    }),
    [timeToCompare, validateHourRange]
  )

  const defaultValueAsDate = useMemo(() => {
    if (!defaultValue?.length) {
      return null
    }

    return formatTimeToDate(defaultValue)
  }, [defaultValue])

  return (
    <>
      <Controller
        control={control}
        rules={rules}
        defaultValue={defaultValueAsDate}
        name={name}
        as={
          <TimePicker
            variant="inline"
            ampm={false}
            format="HH:mm"
            placeholder="HH:MM"
            className={styles.input}
            error={Boolean(fieldError)}
            errorMessage={fieldError}
          />
        }
        {...props}
      />
      {Boolean(fieldError) && <Typography className={styles.errorMessage}>{fieldError}</Typography>}
    </>
  )
}
