export const NEGOCIATION_TYPE = [
  {
    value: 'false',
    label: 'Aceito, sem negociação',
    helperText: 'Orçamento dentro dos padrões de preço e qualidade da Refera.',
  },
  {
    value: 'true',
    label: 'Negociado',
    helperText:
      'Orçamento fora dos padrões de preço e qualidade, mas a operação negociou com o prestador e ele aceitou a negociação.',
  },
]

export const CLASSIFICATED_SERVICE_TYPE = [
  {
    value: 'true',
    label: 'Serviço já classificado pela Refera',
    helperText: ' Este tipo de serviço está catalogado na planilha de Dados.',
  },
  {
    value: 'false',
    label: 'Serviço ainda não classificado pela Refera',
    helperText: 'Este tipo de serviço não está catalogado na planilha de Dados.',
  },
]

export const INITIAL_STATE = {
  wasBudgetNegociated: null,
  isServiceCataloged: null,
}
