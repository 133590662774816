import React from 'react'
import { BaseContractModal } from '_components/modal/contracts/base'
import { Box } from '@mui/material'

function CondolivreAlertsModal({
  title = '',
  description = '',
  icon = '',
  confirmButtonLabel = 'Ok',
  onConfirm = () => {},
  onCancel = null,
}) {
  return (
    <BaseContractModal.Root sx={{ width: 'auto', minWidth: '400px', maxWidth: '650px' }}>
      <BaseContractModal.Icon iconType={icon} sx={{ marginTop: '24px' }} />
      <Box display="flex" flexDirection="column" width="auto" marginTop="24px">
        <BaseContractModal.Header variant="variant2" sx={{ margin: '0 22px' }}>
          {title}
        </BaseContractModal.Header>
        <BaseContractModal.Paragraph sx={{ margin: '16px 32px 0' }}>
          {description}
        </BaseContractModal.Paragraph>
      </Box>
      <BaseContractModal.ActionButtonContainer sx={{ margin: '40px 0 16px' }}>
        {onCancel && (
          <BaseContractModal.ActionButton
            variant="secondary"
            color="red"
            onClick={onCancel}
            sx={{ width: '165px' }}
          >
            Fechar
          </BaseContractModal.ActionButton>
        )}
        <BaseContractModal.ActionButton
          variant="primary"
          onClick={onConfirm}
          sx={{ width: '165px' }}
        >
          {confirmButtonLabel}
        </BaseContractModal.ActionButton>
      </BaseContractModal.ActionButtonContainer>
    </BaseContractModal.Root>
  )
}

export default CondolivreAlertsModal
