import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  dragIcon: {
    // TODO: Remove !important once refera-ui lib fixes button's specificity
    paddingLeft: '0 !important',
    width: 'fit-content',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}))
