import React, { useCallback, useMemo } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import Svg, { ICON } from '_components/svg'
import HistoryItem from '_components/history-item'
import { getHistoryLogsSelector, getEventsFilter } from '_modules/history-logs/selectors'

import useStyles from './styles'
import { setEventsFilter } from '_/modules/history-logs/actions'

const History = () => {
  const styles = useStyles()
  const historyLogs = useSelector(getHistoryLogsSelector)
  const filter = useSelector(getEventsFilter)
  const dispatch = useDispatch()

  const handleFilter = useCallback(
    name => {
      dispatch(setEventsFilter({ name, value: !filter?.get('mainEvents') }))
    },
    [filter]
  )

  const chipColor = useMemo(
    () => (filter?.get('mainEvents') ? styles.chipEnabled : styles.chipDisabled),
    [filter]
  )

  return (
    <Grid className={styles.history}>
      <Grid container alignItems="center" className={styles.historyHeader}>
        <Svg className={styles.icon} type={ICON.HISTORY} />
        <Typography component="h1" variant="h5" className={styles.timeLine}>
          Linha do tempo
        </Typography>
        <button
          type="button"
          className={`${styles.chip} ${chipColor}`}
          onClick={() => handleFilter('mainEvents')}
        >
          Ver somente principais
        </button>
      </Grid>
      <Grid className={styles.content}>
        {historyLogs?.map(
          (item, index) =>
            item?.isActive && (
              <HistoryItem
                item={item}
                isLastItem={historyLogs.length - 1 === index}
                key={`history-item-${item?.id}`}
              />
            )
        )}
      </Grid>
    </Grid>
  )
}

export default History
