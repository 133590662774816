import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Modal from '_components/modal'

import useStyles from './styles'

const CancelApproveModal = ({ handleModal, handleConfirm }) => {
  const styles = useStyles()

  const [isConfirClicked, setConfirClicked] = useState(false)

  const handleConfirmAction = useCallback(() => {
    setConfirClicked(true)
    handleConfirm()
  }, [handleConfirm])

  return (
    <Modal
      textButton
      showHeader={false}
      handleModal={handleModal}
      disabledSubmit={isConfirClicked}
      handleSubmit={handleConfirmAction}
      className={styles.modal}
      leftButtonText="Sim"
    >
      <p className={styles.modalText}>
        Você tem certeza que deseja cancelar a aprovação deste orçamento?
      </p>
    </Modal>
  )
}

CancelApproveModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
}

export default React.memo(CancelApproveModal)
