import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  children: {
    gridArea: 'children',
    overflowX: 'hidden',
  },
  app: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    flex: 1,
    position: 'relative',
    height: '100%',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    justifyContent: 'center',
    padding: spacing(30),
    gap: spacing(20),

    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  containerConcluded: {
    display: 'flex',
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    padding: spacing(30),
    gap: spacing(30),
  },
  box: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: spacing(6),
    border: `1px solid ${palette.gray[12]}`,
    padding: spacing(24),
    gap: spacing(30),

    [breakpoints.down('sm')]: {
      gap: spacing(20),
    },
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    gap: spacing(4),
  },
  title: {
    color: palette.gray[100],
    fontSize: spacing(20),
    fontWeight: 500,
    lineHeight: spacing(26),
  },
  text: {
    color: palette.gray[100],
    fontSize: spacing(20),
  },
  infoHeader: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: spacing(30, 30, 0, 30),
    gap: spacing(10),
  },
  progressBar: {
    width: '100%',
    height: spacing(8),
    borderRadius: spacing(80),
    backgroundColor: palette.gray[12],

    '&.MuiLinearProgress-bar': {
      borderRadius: spacing(80),
    },
  },
  buttonsField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing(16),
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacing(16),
    width: '100%',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  backButton: {
    padding: spacing(10),
    textTransform: 'capitalize',
    borderRadius: spacing(24),
    color: palette.gray[86],
    maxWidth: spacing(130),
    width: '100%',

    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  dropButton: {
    padding: spacing(10),
    textTransform: 'capitalize',
    borderRadius: spacing(24),
    color: palette.primary.main,
    maxWidth: spacing(130),
    width: '100%',

    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  button: {
    padding: spacing(10, 14),
    width: '100%',
    textTransform: 'capitalize',
    borderRadius: spacing(24),
    maxWidth: spacing(130),
    backgroundColor: palette.primary.main,
    color: palette.gray[4],

    '&:not(:disabled):hover': {
      background: palette.primary.main,
      opacity: '0.8',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'visible',
      color: palette.gray[32],
      backgroundColor: palette.gray[4],
      border: `1px solid ${palette.gray[32]}`,
    },

    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  nextIcon: {
    height: '1.2rem',
  },
  logo: {
    height: spacing(50),
  },
}))
