import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

import Button from '_components/button'

import useStyles from './styles'

const CancelEditDialog = ({ isOpen, onClose, onCancelEdit }) => {
  const styles = useStyles()

  return (
    <Dialog open={isOpen} onClose={onClose} aria-describedby="cancelEditBudgetDialog">
      <DialogContent>
        <DialogContentText id="cancelEditBudgetDialog" className={styles.description}>
          Você tem certeza que deseja cancelar a edição?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Não
        </Button>
        <Button onClick={onCancelEdit} color="primary" variant="contained">
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CancelEditDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
}

export default React.memo(CancelEditDialog)
