import * as responsiblesServices from '_services/responsible'
import { defineAction } from '_utils/redux'

export const GET_RESPONSIBLES = defineAction('GET_RESPONSIBLES')
export const GET_USERSAGENTS = defineAction('GET_USERSAGENTS')

export const getResponsibles = () => (dispatch, getState) =>
  dispatch({
    type: GET_RESPONSIBLES.ACTION,
    payload: responsiblesServices.getResponsibles(getState().user.authToken),
  })

export const getUserAgents = () => (dispatch, getState) => {
  const data = responsiblesServices.getUserAgents(getState().user.authToken)
  dispatch({
    type: GET_USERSAGENTS.ACTION,
    payload: data,
  })
  return data
}
