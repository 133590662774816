/* eslint-disable no-extra-boolean-cast */
import React, { useState, useMemo, useCallback } from 'react'
import { CardMedia as MUICardMedia, IconButton, Typography } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import PlayIcon from '_assets/icons/ic-play.svg'
import PDFIcon from '_assets/icons/ic-pdf.svg'
import useToggle from '_hooks/use-toggle'
import Svg from '_components/svg'

import FullPictureModal from '../modal/full-picture-modal'

import RemovePictureModal from './remove-picture-modal'
import useStyles from './styles'

const CardMedia = ({
  id,
  file,
  lockView,
  isEditing = false,
  onRemoveClick,
  className,
  showOnly = false,
}) => {
  const [modalOpen, handleModal] = useToggle()
  const [fullscreen, setFullscreen] = useState(false)
  const styles = useStyles()

  const renderMedia = useMemo(() => {
    if (!file) {
      return ''
    }

    if (
      typeof file === 'object' &&
      (file?.type?.includes('video') || file?.type?.includes('pdf'))
    ) {
      return false
    }

    if (typeof file === 'string') {
      if (
        // Verifies if the file is a link to a video
        file?.includes('.mp4?') ||
        file?.includes('.flv?') ||
        file?.includes('.mkv?') ||
        file?.includes('.mov?') ||
        file?.includes('.pdf?')
      ) {
        return false
      }
      // If both checks fail, returns the string itself
      return file
    }

    return URL.createObjectURL(file)
  }, [file])

  const typeCheck = () => {
    if (typeof file === 'object' && file?.type?.includes('video')) {
      return 'video'
    }
    if (typeof file === 'object' && file?.type?.includes('pdf')) {
      return 'pdf'
    }

    if (typeof file === 'string') {
      if (
        file?.includes('.mp4?') ||
        file?.includes('.flv?') ||
        file?.includes('.mkv?') ||
        file?.includes('.mov?')
      ) {
        return 'video'
      }

      if (file?.includes('.pdf?')) {
        return 'pdf'
      }
    }

    return false
  }

  const renderName = useMemo(() => {
    if (id || id === 0) {
      return id
    }

    if (typeof file === 'string') {
      return file
    }

    return file?.lastModified
  }, [file, id])

  const getNameOrType = () => {
    if (typeof file === 'string') {
      return file.toUpperCase().slice(file.lastIndexOf('?') - 3, file.lastIndexOf('?')) || ''
    }

    if (typeof file === 'object') {
      const { name } = file
      return name.toUpperCase().substring(name.lastIndexOf('.') + 1) || ''
    }

    return ''
  }

  const handleRemove = useCallback(() => {
    onRemoveClick(renderName, id)
  }, [id, onRemoveClick, renderName])

  const handleRemovePicture = useCallback(() => {
    handleModal()
  }, [handleModal])

  return (
    <>
      {typeCheck(file) !== 'pdf' ? (
        <MUICardMedia
          className={
            !!renderMedia ? clsx(styles.media, className) : clsx(styles.mediaSVG, className)
          }
          image={renderMedia || null}
          onClick={() => setFullscreen(true)}
        >
          {typeCheck(file) === 'video' && (
            <>
              <Typography className={styles.svgFileType}>{getNameOrType()}</Typography>
              <Svg className={styles.svgIcon} icon={PlayIcon} />
            </>
          )}

          {!showOnly && (
            <IconButton className={styles.iconButton} onClick={handleRemovePicture}>
              <CancelIcon className={styles.closeIcon} />
            </IconButton>
          )}

          {modalOpen && (
            <RemovePictureModal
              modalOpen={modalOpen}
              handleModal={handleModal}
              handleRemove={handleRemove}
            />
          )}

          {fullscreen && !isEditing && !lockView && (
            <FullPictureModal imageURL={file} open={fullscreen} closeModal={setFullscreen} />
          )}
        </MUICardMedia>
      ) : (
        <MUICardMedia
          className={clsx(styles.mediaSVG, className)}
          onClick={() => !isEditing && !lockView && window.open(file, '_blank')}
        >
          {typeCheck(file) === 'pdf' && <Svg className={styles.svgPDF} icon={PDFIcon} />}

          {!showOnly && (
            <IconButton className={styles.iconButton} onClick={handleRemovePicture}>
              <CancelIcon className={styles.closeIcon} />
            </IconButton>
          )}

          {modalOpen && (
            <RemovePictureModal
              modalOpen={modalOpen}
              handleModal={handleModal}
              handleRemove={handleRemove}
            />
          )}
        </MUICardMedia>
      )}
    </>
  )
}

CardMedia.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.shape({
      lastModified: PropTypes.number,
      name: PropTypes.string,
    }),
  ]).isRequired,
  id: PropTypes.number,
  onRemoveClick: PropTypes.func,
  className: PropTypes.string,
  isEditing: PropTypes.bool,
  lockView: PropTypes.bool,
  showOnly: PropTypes.bool,
}

CardMedia.defaultProps = {
  id: null,
  className: '',
  isEditing: false,
  showOnly: false,
  lockView: false,
  onRemoveClick: () => {},
}

export default CardMedia
