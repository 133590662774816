import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: spacing(16, 0, 32),
  },
  title: {
    fontWeight: 500,
    fontSize: spacing(16),
    color: palette.gray[100],
  },
  contentLabelContainer: {
    display: 'flex',
    width: '100%',
    margin: spacing(12, 0, 12),
    fontWeight: 'normal',
    fontSize: spacing(16),
    lineHeight: spacing(24),
  },
  fieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: spacing(12),
  },
  field: {
    width: '70%',
    marginRight: spacing(12),
  },
  secondFields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '29%',
    gap: spacing(12),
  },
  iconCloseButton: {
    position: 'relative',
  },
  closeIcon: {
    fontSize: spacing(34),
    height: spacing(30),
    position: 'absolute',

    '&:hover': {
      padding: spacing(6),
      height: spacing(42),
      width: spacing(42),
      borderRadius: '50%',
      backgroundColor: 'rgba(240, 119, 110, 0.14)',
    },
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: spacing(18),
  },
  totalMaterialContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '70%',
    marginRight: spacing(12),
  },
  button: {
    width: spacing(136),
    height: spacing(36),
    marginTop: spacing(6),
  },
  listItem: {
    listStyleType: 'none',
  },
  disabled: {
    visibility: 'hidden',
  },
  totalPriceContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(12),
    padding: spacing(16, 0),
    borderTop: '1px solid #E3E3E3',
    borderBottom: '1px solid #E3E3E3',
  },
  finalPrice: {
    fontSize: spacing(24),
  },
  currencyField: {
    width: '100%',
    height: spacing(38),
    fontSize: spacing(14),
    color: props => (!props.disabled ? palette.gray[64] : palette.gray[32]),
    border: '1px solid rgba(0, 0, 0, 0.30)',
    borderRadius: spacing(4),
    padding: spacing(0, 8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      border: props => !props.disabled && '1px solid #717171',
    },

    '&:focus-visible': {
      outline: 'none',
      border: props => !props.disabled && `2px solid ${palette.primary.main} `,
      padding: spacing(0, 7),
    },
  },
  moneyInput: {
    padding: 0,

    '& input': {
      padding: 0,
    },

    '&:after': {
      display: 'none',
    },

    '&:before': {
      display: 'none',
    },
  },
}))
