import { formatCurrency } from '_/utils/helpers'

export const CalculateCondolivreAnticipationValues = ({ items }) => {
  let interestValue = 0
  let totalValue = 0
  let totalPriceServiceProvider = 0

  items.forEach(item => {
    totalPriceServiceProvider += item.priceServiceProvider
    interestValue += item?.anticipationTax
    totalValue += item?.value
  })

  return {
    totalPriceServiceProvider: formatCurrency(totalPriceServiceProvider || 0),
    interestValue: formatCurrency(interestValue || 0),
    totalValue: formatCurrency(totalValue || 0),
  }
}
