import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    flex: 1,
    margin: '24px 2% 0 34px',
  },
  actionButtons: {
    display: 'flex',
    gap: spacing(8),
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100% - 75px)',
    overflowY: 'auto',
  },
  mainTable: {
    marginTop: '16px',
    width: 'auto',
    height: '650px',
  },
  default: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(4),
    borderRadius: spacing(16),
    flex: 1,
  },
}))
