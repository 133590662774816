import { OrderedMap } from 'immutable'

import Model from './model'

class StaffUser extends Model({
  email: undefined,
  getServiceProviderId: undefined,
  id: undefined,
  name: undefined,
  phone: undefined,
  registrationConfirmed: undefined,
  role: undefined,
  termsAccepted: undefined,
  contractAttachment: undefined,
}) {}

const transformOrderedMap = payload =>
  OrderedMap(payload?.map(value => [value.id, new StaffUser({ ...value })]))

export { StaffUser as default, transformOrderedMap }
