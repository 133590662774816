import React from 'react'
import { Tooltip } from '@material-ui/core'
import Checkbox from '_/components/checkbox'

export const groupColumns = (handleSetValue, formState) => [
  {
    field: 'permissionName',
    headerName: 'Permissão',
    minWidth: '100%',
    maxWidth: '100%',
    editable: false,
  },
  {
    field: 'read',
    headerName: 'Ver',
    editable: false,
    renderCell: ({ row, value }) => (
      <Tooltip title="Ver permissão">
        <Checkbox
          defaultChecked={formState?.find(item => item?.id === row?.id)?.read ?? value}
          name="read"
          onChange={event => handleSetValue(row, event.target?.name, event.target?.value)}
        />
      </Tooltip>
    ),
  },
  {
    field: 'create',
    headerName: 'Gerenciar',
    editable: false,
    renderCell: ({ row, value }) => (
      <Tooltip title="Incluir permissão">
        <Checkbox
          defaultChecked={formState?.find(item => item?.id === row?.id)?.create ?? value}
          name="create"
          onChange={event => handleSetValue(row, event.target?.name, event.target?.value)}
        />
      </Tooltip>
    ),
  },
  {
    field: 'delete',
    headerName: 'Excluir',
    editable: false,
    renderCell: ({ row, value }) => (
      <Tooltip title="Excluir permissão">
        <Checkbox
          defaultChecked={formState?.find(item => item?.id === row?.id)?.delete ?? value}
          name="delete"
          onChange={event => handleSetValue(row, event.target?.name, event.target?.value)}
        />
      </Tooltip>
    ),
  },
]

export const TABLE_SX = {
  fontSize: '1.4rem',
  width: '100%',

  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%',
  },

  '& .MuiDataGrid-columnHeadersInner': {
    width: '100%',

    '> div': {
      width: '100%',
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    width: '100%',
  },

  '& .MuiDataGrid-columnHeader': {
    width: '100%',
  },

  '& .MuiDataGrid-row': {
    width: '100%',
  },

  '& .MuiDataGrid-cell': {
    width: '100%',
  },

  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
}
