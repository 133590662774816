import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  contentImg: {
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
  videoPlayer: {
    zIndex: 1,
  },
}))
