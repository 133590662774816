import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ palette, transitions, spacing }) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2.5rem',
    gap: '3rem',
  },
  row: {
    display: 'flex',
    gap: '2rem',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '0.5rem',
  },
  modal: {
    maxWidth: '900px',
  },
  modalContent: {
    paddingBottom: '1.5rem',
  },
  modalTotalValueContainer: {
    width: '100%',
    margin: '-1rem 0 1.5rem 0',

    '& p': {
      color: Theme.Colors.Grayscale.SixtyFour,
      fontSize: '1.4rem',
    },

    '& b': {
      marginLeft: '1rem',
      fontSize: '1.6rem',
      color: Theme.Colors.Grayscale.Eighty,
    },
  },
  filterField: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '100%',
    backgroundColor: palette.gray[4],
    border: '1px solid #E0E0E0',
    padding: '1rem',
    borderRadius: 8,
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem 0',
  },
  switches: {
    width: 28,
    height: 16,
    padding: 0,
    borderRadius: 8,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: palette.gray[12],
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: palette.primary.main,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: `0 2px 4px 0 ${palette.gray[12]}`,
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 8,
      opacity: 1,
      backgroundColor: palette.gray[32],
      boxSizing: 'border-box',
    },
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: '500',
  },
  label: {
    marginTop: '-20px !important',
  },
  labelMoney: {
    marginTop: '-39px !important',
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconWrapper: {
    width: spacing(32),
    height: spacing(32),
    fontSize: spacing(18),
    borderRadius: '50%',
    backgroundColor: Theme.Colors.Primary.Lightest,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacing(12),
  },
}))
