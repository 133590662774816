import { createReducer } from '_utils/redux'
import { GET_LINKS } from './actions'
import { Record } from 'immutable'

const INITIAL_STATE = new Record({
  privacyPolicy: undefined,
  termsOfUse: undefined,
})()

const lgpdLinks = createReducer(INITIAL_STATE, {
  [GET_LINKS.FULFILLED]: (_, { payload }) => {
    return {
      privacyPolicy: payload.policiesLink,
      termsOfUse: payload.termsLink,
    }
  },
})

export default lgpdLinks
