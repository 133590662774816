import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Link, useLocation } from '@reach/router'

import AccessDeniedIcon from '_/assets/svgs/access-denied.svg'
import NotFoundIcon from '_/assets/svgs/curious-rafiki.svg'
import ExpiredLinkIcon from '_assets/svgs/link-expirado.svg'
import ToolIcon from '_/assets/icons/ic-tool-blue.svg'
import ReferaLogo from '_assets/svgs/refera-logo.svg'
import Svg from '_/components/svg'
import { formatDate } from '_/utils/helpers'

import useStyles from './styles'

export const ErrorScreen = ({
  title,
  logo,
  fullscreen,
  size = null,
  date = null,
  subTitle = null,
}) => {
  const styles = useStyles({ fullscreen, size })

  const location = useLocation()
  const systemPrevisionDate = location.state && location.state.systemPrevisionDate

  return (
    <Grid>
      {fullscreen && (
        <Grid className={styles.header}>
          <Link to="/">
            <Svg className={styles.logoIcon} icon={ReferaLogo} />
          </Link>
        </Grid>
      )}
      <Grid className={styles.container}>
        <Svg className={styles.mainSvg} icon={logo} />
        <Grid className={styles.messageContainer}>
          <Grid className={styles.textTitle}>
            <Grid className={styles.titleContainer}>
              <Svg className={styles.icon} icon={ToolIcon} />
              <Typography className={styles.message}>{title}</Typography>
            </Grid>
            {subTitle && <Typography className={styles.message}>{subTitle}</Typography>}
          </Grid>
          {(systemPrevisionDate || date) && (
            <Grid className={styles.messageContent}>
              <Typography className={styles.date}>
                Previsão para normalização do sistema:{' '}
                <strong>{formatDate(date ?? systemPrevisionDate)}</strong>.
              </Typography>
              <Typography className={styles.support}>
                Caso tenha alguma dúvida, você pode entrar em contato com o suporte pelos nossos
                canais de atendimento ou via email:{' '}
                <a
                  className={styles.link}
                  href="mailto:suporte@refera.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  suporte@refera.com.br
                </a>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export const AccessDenied = ({ fullscreen = false }) => {
  return (
    <ErrorScreen
      title="Acesso negado"
      subTitle="Você não tem permissão para visualizar os dados desta área da aplicação.
Por favor, fale com seu administrador."
      logo={AccessDeniedIcon}
      fullscreen={fullscreen}
    />
  )
}

export const NotFound = ({ fullscreen = false }) => {
  return <ErrorScreen title="Página não encontrada" logo={NotFoundIcon} fullscreen={fullscreen} />
}

export const ExpiredLink = ({ fullscreen = false }) => {
  return <ErrorScreen title="Link expirado" logo={ExpiredLinkIcon} fullscreen={fullscreen} />
}

export const SystemDown = ({ fullscreen = false }) => {
  return (
    <ErrorScreen
      title="O sistema está temporariamente em manutenção."
      logo={AccessDeniedIcon}
      fullscreen={fullscreen}
    />
  )
}
