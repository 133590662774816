import { useDispatch } from 'react-redux'
import React, { useCallback, useMemo, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Datagrid, Loader } from '@refera/ui-web'

import { ReceiptsCsReferaColumns } from '../../manage-installments/utils/constants'

import ConfirmDateAndPaymentModal from '../../manage-installments/components/ConfirmDateAndPaymentModal'
import { ROUTES } from '_/utils/constants'

import HeaderTitle from '_/components/header-title'

import useStyles from '../styles'
import { TABLE_SX, asaasConfirmationScreenTypes } from '../../utils/constants'
import {
  patchCancelConfirmReceipts,
  postBlockFinanceTransfersByReceipts,
  postConfirmReceipts,
} from '_/modules/finance/actions'
import { formatErrorMessage } from '../../utils/FormatErrorMessage'
import { useToast } from '_/hooks/use-toast'

const tableInitialState = {
  sorting: {
    sortModel: [{ field: 'serviceOrderId', sort: 'desc' }],
  },
}

const modalLabels = {
  title: 'Recebimento',
  paymentMethodLabel: 'Forma de recebimento',
  paymentDateLabel: 'Data de recebimento',
}

const titleMapping = {
  confirm: 'Confirmação de recebimento',
  cancel: 'Confirmação de cancelamento de recebimento',
  block: 'Confirmação de bloqueio de recebimento',
  release: 'Confirmação de liberação de recebimento',
}

const actionsMapping = {
  confirm: postConfirmReceipts,
  cancel: patchCancelConfirmReceipts,
  block: postBlockFinanceTransfersByReceipts,
  release: postBlockFinanceTransfersByReceipts,
}

const ReceiptsConfirmation = ({ goBack, items, menuType }) => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const [selectedIds, setSelectedIds] = useState(items?.map(item => item.id) || [])
  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const { showToast } = useToast()

  const title = useMemo(() => {
    return titleMapping[menuType] || ''
  }, [menuType, titleMapping])

  const receiptsConfirmationColumns = useMemo(() => {
    const columnsToRemove = ['voucher', 'status']

    if (menuType === 'confirm') columnsToRemove.push('paymentDate', 'amountPaid')
    if (!['block', 'release'].includes(menuType)) columnsToRemove.push('number')

    return ReceiptsCsReferaColumns({ styles }).filter(
      column => !columnsToRemove.includes(column.field)
    )
  }, [ReceiptsCsReferaColumns])

  const handleConfirmAction = useCallback(
    async (data = null) => {
      const action = actionsMapping[menuType]

      if (!action) return

      setIsLoading(true)

      setSelectedIds(currentSelectedIds => {
        const payload = {
          budgetReceivablesIds: currentSelectedIds,
        }

        if (menuType === 'confirm') {
          payload.paymentDate = data?.datetimePayment?.format('yyyy-MM-DD')
          payload.formOfPayment = data?.paymentMethod
        }

        if (menuType === 'block' || menuType === 'release') {
          payload.budgetReceivablesIds = selectedIds
          payload.block = menuType === 'block'
        }

        dispatch(action(payload))
          .then(() => {
            showToast({ type: 'success' })
            setIsLoading(false)
            goBack({ update: true })
          })
          .catch(error => {
            showToast({ message: formatErrorMessage(error), type: 'error' })
            setIsLoading(false)
            setModalOpen(false)
          })

        return currentSelectedIds
      })
    },
    [menuType, actionsMapping, showToast, goBack]
  )

  const handleCellClick = useCallback(params => {
    if (params.field === '__check__') return
    if (params.field === 'serviceOrderId') {
      window.open(
        `${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }, [])

  const handleConfirmButton = useMemo(() => {
    if (menuType === 'confirm') return () => setModalOpen(true)
    return handleConfirmAction
  }, [menuType])

  const disableCheckbox = useMemo(() => {
    return asaasConfirmationScreenTypes.includes(menuType)
  }, [menuType])

  return (
    <Grid>
      <Loader hasBackdrop open={isLoading} label="Aguarde..." />
      <HeaderTitle title={title} backButtonAction={goBack}>
        <Grid style={{ display: 'flex', gap: '8px' }}>
          <Button color="red" variant="secondary" onClick={goBack}>
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="primary"
            onClick={handleConfirmButton}
            disabled={selectedIds.length === 0}
          >
            Confirmar
          </Button>
        </Grid>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            rows={items.length ? items : []}
            columns={receiptsConfirmationColumns}
            paginationMode="client"
            autoHeight={items.length < 10}
            onCellClick={handleCellClick}
            columnVisibilityModel={{ actions: false }}
            rowCount={items?.length}
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            checkboxSelection={!disableCheckbox}
            onSelectionModelChange={setSelectedIds}
            keepNonExistentRowsSelected
            selectionModel={selectedIds}
            initialState={tableInitialState}
            sx={TABLE_SX}
          />
        </Grid>
      </Grid>
      {modalOpen && (
        <ConfirmDateAndPaymentModal
          open={modalOpen}
          onConfirm={handleConfirmAction}
          onCancel={() => setModalOpen(false)}
          labelsProps={modalLabels}
        />
      )}
    </Grid>
  )
}

export default ReceiptsConfirmation
