/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Grid } from '@material-ui/core'

import Theme from '@refera/ui-core'

// Icons
import { TickCircle as CheckIcon, CloseCircle as CloseIcon } from '@refera/ui-icons'

// Components
import { Datagrid, Alert, Button, useConfirm, Toast } from '@refera/ui-web'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'

// Redux
import { useDispatch, useSelector } from 'react-redux'

import useStyles from './styles'

import HeaderTitle from '_/components/header-title'

import { FINANCE_ROUTES, ROUTES } from '_/utils/constants'
import { navigate, useParams, Redirect } from '@reach/router'

import {
  getFinanceGenericParameters,
  getPaymentAnticipationDetails,
  getVerifyUpdateAnticipations,
  updatePaymentAnticipations,
} from '_/modules/finance/actions'
import { TableColumns } from './components/TableColumns'
import { userSelector } from '_/modules/authentication/selectors'

import { financeGenericParametersSelector } from '_/modules/finance/selectors'
import { formatErrorMessage } from '../utils/FormatErrorMessage'
import { TABLE_SX } from '../utils/constants'

const defaultToast = { isOpen: false, title: '', severity: '' }

const PaymentAdvanceDetails = () => {
  const { paymentAnticipationId } = useParams()
  const styles = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const financeGenericParameters = useSelector(financeGenericParametersSelector)
  const isTradesman = useMemo(() => user?.getRoles?.includes('tradesman'), [user])

  const [toastInfo, setToastInfo] = useState(defaultToast)
  const [dialogMessage, setDialogMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const [installments, setInstallments] = useState([])
  const [status, setStatus] = useState('')
  const [isUnauthorized, setIsUnauthorized] = useState(false)

  const handleAnticipationActionErrors = error =>
    setToastInfo({
      isOpen: true,
      title: formatErrorMessage(error),
      severity: 'error',
    })

  const handleGetFinanceGenericParameters = useCallback(() => {
    if (!Object.keys(financeGenericParameters).length) {
      dispatch(getFinanceGenericParameters())
    }
  }, [financeGenericParameters])

  const getAnticipationDetails = async () => {
    setIsLoading(true)
    await Promise.resolve(dispatch(getPaymentAnticipationDetails(paymentAnticipationId)))
      .then(response => {
        setInstallments(response?.installments)
        setStatus(response?.status)
      })
      .catch(error => {
        if (error?.detail === 'unauthorized') {
          return setIsUnauthorized(true)
        }
        setToastInfo({
          isOpen: true,
          title: 'Ocorreu um erro ao buscar as parcelas da antecipação',
          severity: 'error',
        })
      })
    setIsLoading(false)
  }

  const fetchVerificationUpdateAnticipation = useCallback(async () => {
    setIsLoading(true)
    let updateAnticipationInfo = {}

    await dispatch(getVerifyUpdateAnticipations(paymentAnticipationId))
      .then(res => {
        updateAnticipationInfo = res
        setIsLoading(false)
      })
      .catch(error => {
        handleAnticipationActionErrors(error)
        updateAnticipationInfo = { error: true }
        setIsLoading(false)
      })
    return updateAnticipationInfo
  }, [paymentAnticipationId, status, dispatch, handleAnticipationActionErrors])

  useEffect(() => {
    if (installments?.length === 0) {
      getAnticipationDetails().catch(() => {})
      handleGetFinanceGenericParameters()
    }
  }, [])

  const { isConfirmed } = useConfirm()

  const handleUpdateAnticipation = async (buttonAction, recreate) => {
    setIsLoading(true)

    const payload = {
      id: paymentAnticipationId,
      status: buttonAction,
      recreatePaymentInstallment: recreate,
    }

    await dispatch(updatePaymentAnticipations(payload))
      .then(() => GoBack({ showSuccessToast: true }))
      .catch(res => handleAnticipationActionErrors(res))

    setIsLoading(false)
  }

  const handleButtonClick = async buttonAction => {
    setDialogMessage('Você tem certeza que deseja executar esta ação?')
    const confirmed = await isConfirmed()
    if (!confirmed) return
    let recreate = false

    if (buttonAction === 'cancel_reproval') {
      const updateAnticipationInfo = await fetchVerificationUpdateAnticipation()

      if (updateAnticipationInfo?.error) return

      if (updateAnticipationInfo?.hasOverduePayment) {
        setDialogMessage(
          'A data prevista de pagamento já passou. Você confirma que deseja recalcular o juros para a próxima data de antecipação?'
        )
        const confirmedCancelRepproval = await isConfirmed()

        if (!confirmedCancelRepproval) return
        recreate = true
      } else if (updateAnticipationInfo?.paymentDateIsCloseToAnticipationDate) {
        setDialogMessage(
          'A data prevista de pagamento é menor que a próxima data prevista. Queres manter a data atual de pagamento?'
        )
        const confirmedCancelRepproval = await isConfirmed()

        if (!confirmedCancelRepproval) recreate = true
      }
    }

    handleUpdateAnticipation(buttonAction, recreate)
  }

  const handleButtons = useMemo(() => {
    if (isTradesman) return null

    if (status === 'approved') {
      return (
        <Button
          variant="secondary"
          startIcon={<CheckIcon color={Theme.Colors.Primary.Base} />}
          onClick={() => handleButtonClick('cancel_approval')}
          disabled={isLoading}
        >
          Cancelar aprovação
        </Button>
      )
    }
    if (status === 'reproved') {
      return (
        <Button
          variant="secondary"
          startIcon={<CheckIcon color={Theme.Colors.Primary.Base} />}
          onClick={() => handleButtonClick('cancel_reproval')}
          disabled={isLoading}
        >
          Cancelar reprovação
        </Button>
      )
    }

    if (status) {
      return (
        <Grid className={styles.buttonsContainer}>
          <Button
            variant="secondary"
            startIcon={<CheckIcon color={Theme.Colors.Primary.Base} />}
            onClick={() => handleButtonClick('approve')}
            disabled={isLoading}
          >
            Aprovar antecipação
          </Button>
          <Button
            variant="secondary"
            startIcon={<CloseIcon color={Theme.Colors.Red.Base} />}
            color="red"
            onClick={() => handleButtonClick('reprove')}
            disabled={isLoading}
          >
            Reprovar antecipação
          </Button>
        </Grid>
      )
    }
    return null
  }, [status, isLoading, isTradesman])

  const handleCellClick = useCallback(
    params => {
      if (params.field === 'serviceOrderId') {
        const url = isTradesman
          ? `${ROUTES.PROVIDER}/detalhes-do-chamado/${params.row.serviceOrderId}`
          : `${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}`

        window.open(url, '_blank', 'noopener,noreferrer')
      }
      if (params.field !== 'serviceOrderId' && params.field !== ' ') {
        const url = isTradesman
          ? `${ROUTES.INSTALLMENTS_RECEIPTS}/antecipacoes/${paymentAnticipationId}/parcela/${params.row.id}`
          : `${FINANCE_ROUTES.PAYMENT_ADVANCE}/${paymentAnticipationId}/parcela/${params.row.id}`
        navigate(url)
      }
    },
    [isTradesman]
  )

  if (isUnauthorized) return <Redirect noThrow to="/acesso-negado" />

  const GoBack = ({ showSuccessToast = false }) => {
    isTradesman
      ? navigate(`${ROUTES.INSTALLMENTS_RECEIPTS}/antecipacoes`)
      : navigate(FINANCE_ROUTES.PAYMENT_ADVANCE, { state: { showSuccessToast } })
  }

  return (
    <>
      <Grid>
        <HeaderTitle title={`Antecipação ID ${paymentAnticipationId}`} backButtonAction={GoBack}>
          {handleButtons}
        </HeaderTitle>
        <Grid className={styles.tableContainer}>
          <Grid className={styles.mainTable}>
            <Datagrid
              loading={isLoading}
              rows={installments}
              onCellClick={handleCellClick}
              columns={TableColumns({ styles })}
              rowCount={installments?.length}
              sx={TABLE_SX}
            />
          </Grid>
        </Grid>
      </Grid>
      <ConfirmRemoveDialog message={dialogMessage} />
      {toastInfo?.isOpen && (
        <Toast
          draggable
          open={toastInfo.isOpen}
          autoHideDuration={6000}
          onClose={() => setToastInfo(defaultToast)}
        >
          <Alert
            severity={toastInfo.severity}
            title={toastInfo.title}
            onClose={() => setToastInfo(defaultToast)}
          />
        </Toast>
      )}
    </>
  )
}

export default PaymentAdvanceDetails
