import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  inputError: {
    '& .Mui-error:before': {
      borderBottomColor: `${palette.error.main} !important`,
    },
    '& .Mui-error:after': {
      borderBottomColor: `${palette.error.main} !important`,
    },
  },
  notErrored: {
    '& .MuiInput-underline::before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
    },
    '& .MuiInput-underline.Mui-error::after': {
      borderBottom: '2px solid rgb(25, 118, 210) !important',
      transform: 'scaleX(0)',
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87) !important',
    },

    '& .MuiInput-underline.Mui-focused::after': {
      borderBottomColor: 'rgb(25, 118, 210) !important',
      transform: 'scaleX(1) translateX(0px) !important',
    },
  },
  errorMessage: {
    height: spacing(16),
    color: palette.error.main,
    marginTop: `${spacing(4)} !important`,
  },
  datePicker: {
    width: '100%',
  },
}))
