import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

import { theme } from '../theme'

export default makeStyles(({ spacing }) => ({
  selectInput: {
    width: '100%',
  },
  selectInputLabel: {
    color: Theme.Colors.Grayscale.SixtyFour,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Small,
  },
  closePage: {
    display: 'none',
  },
  formControl: {
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    gap: spacing(30),
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  contentSubTitle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  agencyDivider: {
    textAlign: 'center',
    color: 'green',
    fontFamily: 'Comic Sans',
  },
  container: { gridArea: 'main' },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: spacing(80),
    width: spacing(80),
    borderRadius: '50%',
    backgroundColor: theme.palette.green.lightest,
    marginBottom: spacing(40),
  },
  icon: {
    height: spacing(16),
    width: spacing(16),
    marginRight: '5px',
  },
  checkIcon: {
    fill: theme.palette.green.main,
    height: spacing(50),
    width: spacing(50),
  },
  description: {
    fontWeight: 'normal',
    color: theme.palette.gray[64],
    marginTop: spacing(24),
    textAlign: 'center',
    marginBottom: 40,
    width: '50%',
  },
  title: {
    textAlign: 'center',
  },
  iconBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 400,
    fontSize: spacing(14),
    color: '#1671ED',
    margin: 0,
    padding: 0,
  },
  datePickerContainer: {
    width: '100%',

    '& .MuiTextField-root': {
      width: '100%',
      marginBottom: spacing(6),
      marginTop: spacing(6),
    },

    '& .MuiIconButton-root': {
      marginRight: spacing(4),
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(40),
    width: '100%',
  },
  dateInfo: {
    marginTop: spacing(8),
    color: Theme.Colors.Grayscale.SixtyFour,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: Theme.Typography.FontSize.Tiny,
    fontWeight: Theme.Typography.FontWeight.Small,
  },
  errorMessage: {
    color: Theme.Colors.Red.Base,
  },
  button: {
    color: '#1671ED',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
}))
