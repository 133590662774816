import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { Input } from '@refera/ui-web'
import Theme from '@refera/ui-core'

export const InputNumberInterval = ({
  name,
  label,
  localState,
  handleChange,
  error = null,
  ...rest
}) => {
  return (
    <Grid>
      <Grid style={{ display: 'flex', gap: '14px' }}>
        <Input
          key={`${name}Start`}
          name={`${name}Start`}
          label={label}
          value={localState?.[`${name}Start`]}
          placeholder="12345"
          style={{ fontSize: '16px' }}
          onChange={handleChange}
          fullWidth
          inputProps={{ type: 'number', max: localState?.[`${name}End`] || 0 }}
          error={error}
          {...rest}
        />
        <Typography style={{ marginTop: '32px' }}>•</Typography>
        <Input
          key={`${name}End`}
          name={`${name}End`}
          label="ㅤ"
          value={localState?.[`${name}End`]}
          placeholder="12345"
          style={{ fontSize: '16px' }}
          onChange={handleChange}
          fullWidth
          inputProps={{ type: 'number', min: localState?.[`${name}Start`] || 0 }}
          error={error}
          {...rest}
        />
      </Grid>
      {!!error && (
        <div
          style={{
            color: Theme.Colors.Red.Base,
            marginTop: '8px',
            fontSize: `12px`,
          }}
        >
          {error}
        </div>
      )}
    </Grid>
  )
}
