import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    padding: spacing(32),
    gap: spacing(40),
  },
  title: {
    fontSize: spacing(20),
    lineHeight: spacing(20),
    color: palette.gray[100],
  },
  subTitle: {
    fontSize: spacing(22),
    lineHeight: spacing(20),
    fontWeight: 'bold',
    color: `${palette.primary.main} !important`,
  },
  redText: {
    color: `${palette.error.main} !important`,
  },
  blueText: {
    color: `${palette.primary.main} !important`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: spacing(18),
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: spacing(0, 14),
    gap: spacing(16),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: spacing(24),
  },
  label: {
    fontSize: spacing(12),
    lineHeight: spacing(20),
    color: palette.gray[80],
    fontWeight: 'bold',
  },
  input: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: palette.gray[80],

    '&.MuiInputLabel-root': {
      color: `${palette.gray[80]} !important`,
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(8, 0),
    borderBottom: `1px solid ${palette.gray[12]}`,
    maxWidth: spacing(600),
    gap: spacing(12),
  },
  grid: {
    display: 'flex',
    width: '100%',
    gap: spacing(24),
  },
}))
