import { formatCurrency } from '_/utils/helpers'

export const CalculateAdminAnticipationValues = ({ items }) => {
  const totalPriceServiceProvider = items.reduce((acc, curr) => acc + curr.providerValueTotal, 0)

  const totalValue = items.reduce((acc, curr) => acc + curr.amountPaidTotal, 0)

  const interestValue = items.reduce((acc, curr) => acc + curr.anticipationTaxTotal, 0)

  const interestPercentage = (parseFloat(interestValue / totalPriceServiceProvider) * 100).toFixed(
    2
  )

  return {
    totalPriceServiceProvider: formatCurrency(totalPriceServiceProvider),
    totalValue: formatCurrency(totalValue),
    interestValue: formatCurrency(interestValue),
    interestPercentage,
  }
}
