import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import { setWidgetsFilter, getWidgetsOptions } from '_modules/widgets/actions'
import { getWidgetsFilter } from '_modules/widgets/selectors'
import { userSelector } from '_modules/authentication/selectors'

import { WIDGET_POSITION } from '_components/widgets-board/constants'
import WidgetsBoard from '_components/widgets-board'
import HeaderTitle from '_/components/header-title'
import Tour from '_/components/tour'

import useStyles from './styles'
import useRequest from '_/hooks/use-request'

const Widgets = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const userData = useSelector(userSelector)
  const filter = useSelector(getWidgetsFilter)

  const [isLoading] = useRequest(getWidgetsOptions())

  const handleFilter = useCallback(
    name => {
      dispatch(setWidgetsFilter({ name, value: !filter?.get('onlyMe') }))
    },
    [filter]
  )

  const chipColor = useMemo(
    () => (filter?.get('onlyMe') ? styles.chipEnabled : styles.chipDisabled),
    [filter]
  )

  return (
    <>
      <HeaderTitle title="Widgets operacionais">
        <button
          type="button"
          className={`${styles.chip} ${chipColor}`}
          onClick={() => handleFilter('onlyMe')}
          disabled={isLoading}
        >
          Ver somente meus chamados
        </button>
      </HeaderTitle>
      <Grid className={styles.container}>
        <Grid className={styles.boxes}>
          <WidgetsBoard position={WIDGET_POSITION.FIRST} />
          <WidgetsBoard position={WIDGET_POSITION.SECOND} />
          <WidgetsBoard position={WIDGET_POSITION.THIRD} />
          <WidgetsBoard position={WIDGET_POSITION.FOURTH} />
        </Grid>
      </Grid>
      <Tour user={userData} />
    </>
  )
}

export default Widgets
