import { defineAction } from '_utils/redux'
import * as tipServices from '_services/tips'

export const VIEW_TIP = defineAction('VIEW_TIP')

export const viewTip = params => (dispatch, getState) => {
  const queryParams = {
    tips: params?.tips,
    user: params?.user,
  }
  const data = tipServices.viewTip(getState().user.authToken)(queryParams)
  dispatch({
    type: VIEW_TIP.ACTION,
    payload: data,
    meta: queryParams,
  })
  return data
}
