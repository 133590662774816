import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Typography } from '@material-ui/core'
import { DatePicker } from '@refera/ui-web'
import moment from 'moment'
import TextField from '@mui/material/TextField'

import useStyles from './styles'

const ControlledDatePicker = ({
  name,
  defaultValue,
  rules,
  minDate,
  required = true,
  genericErrorMessage = null,
  ...props
}) => {
  const styles = useStyles()
  const minimumDate = !minDate ? moment() : minDate
  const { control, errors } = useFormContext()

  const renderInput = useCallback(
    params => (
      <TextField variant="standard" {...params} placeholder="DD/MM/AAAA" error={!!errors[name]} />
    ),
    []
  )

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={required ? { required: 'Campo obrigatório', ...rules } : { ...rules }}
        as={
          <DatePicker
            variant="inline"
            mask="__/__/____"
            format="dd/MM/yyyy"
            invalidDateMessage="Insira uma data válida"
            refuse={/[^\d.]+/gi}
            minDate={minimumDate}
            renderInput={renderInput}
            {...props}
          />
        }
      />
      {errors?.[name]?.message && (
        <Typography variant="subtitle1" component="span" className={styles.errorMessage}>
          {genericErrorMessage || errors[name].message}
        </Typography>
      )}
    </>
  )
}

export default ControlledDatePicker
