import React, { useCallback } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useToast } from '_/hooks/use-toast'
import Textfield from '_components/textfield'
import Button from '_components/button'
import Svg from '_components/svg'
import ReferaLogo from '_assets/svgs/refera-logo-negative.svg'
import useForm from '_hooks/use-form'
import { validationEmail } from '_utils/helpers'
import { HEADER_TAGS } from '_utils/header-tags'
import { ROUTES } from '_utils/constants'
import { passwordRecover } from '_/modules/authentication/actions'
import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import useStyles from '../styles'

const INITIAL_STATE = {
  email: '',
  confirmationEmail: '',
}

const PasswordRecover = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()

  const validate = useCallback(values => {
    const errors = {}
    if (!validationEmail(values.email)) {
      errors.email = 'Email inválido'
    }
    if (!values.confirmationEmail?.length) {
      errors.confirmationEmail = 'Campo Obrigatório'
    }

    return errors
  }, [])

  const { errors, values, touched, handleBlur, isValid, setTouchedAll, handleInputChange } =
    useForm(INITIAL_STATE, validate)

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setTouchedAll()
      if (isValid) {
        if (values?.email === values?.confirmationEmail) {
          dispatch(passwordRecover({ email: values?.email }))
            .then(() => {
              navigate(ROUTES.LOGIN, {
                state: {
                  dialogMessage:
                    'Enviamos um e-mail para o endereço informado. Caso não tenha recebido, por favor revise o e-mail digitado.',
                  showMessage: true,
                },
              })
            })
            .catch(() => {
              showToast({
                message: 'Não foi possível executar esta ação, tente novamente.',
                type: 'error',
              })
            })
        } else {
          showToast({
            message:
              'O e-mail de login não é o mesmo e-mail de confirmação. Preencha os campos corretamente e tente novamente.',
            type: 'error',
          })
        }
      }
    },
    [setTouchedAll, values?.email, isValid, navigate]
  )

  return (
    <>
      {HEADER_TAGS[ROUTES.MANAGE_ORDERS].header}
      <Grid className={styles.container}>
        <Grid container className={styles.content}>
          <Svg icon={ReferaLogo} ariaLabel="Refera logo" className={styles.icon} />
          <Typography component="h1" variant="h1" className={styles.title}>
            Recupere a sua senha
          </Typography>
          <Grid component="form" className={styles.form}>
            <Textfield
              label="E-mail de login"
              className={styles.textfield}
              name="email"
              onChange={handleInputChange}
              value={values?.email}
              error={touched?.email && !!errors?.email}
              helperText={(touched?.email && errors?.email) || ''}
              onBlur={handleBlur}
            />
            <Textfield
              label="Confirmar e-mail"
              className={styles.textfield}
              name="confirmationEmail"
              onChange={handleInputChange}
              value={values?.confirmationEmail}
              error={touched?.confirmationEmail && !!errors?.confirmationEmail}
              helperText={(touched?.confirmationEmail && errors?.confirmationEmail) || ''}
              onBlur={handleBlur}
            />
          </Grid>
          <Button className={styles.loginButton} type="submit" onClick={handleSubmit}>
            Recuperar senha
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default PasswordRecover
