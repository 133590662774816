import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  modal: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  modalContent: {
    width: '100%',
  },
  textlabel: {
    width: spacing(390),
    lineHeight: spacing(20),
    marginBottom: spacing(16, '!important'),
    fontSize: spacing(16, '!important'),
    color: Theme.Colors.Grayscale.SixtyFour,
  },
}))
