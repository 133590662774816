import { createFormDataDocuments } from '_utils/helpers'

import { post, patch, get } from './requests'

export const createServiceOrderForm = key => payload =>
  post(['serviceorder-form'], key ? { key } : {}, payload)

export const updateServiceOrderForm = uuid => (serviceId, payload) =>
  patch(
    ['serviceorder-form', serviceId],
    { params: { uuid }, transformOnlyResponse: true, formData: !!payload?.attachments?.length },
    payload?.attachments?.length ? createFormDataDocuments(payload) : payload
  )

export const finalizeServiceOrderForm = uuid => serviceId =>
  patch(['serviceorder-form', serviceId, 'finalize'], {
    params: { uuid },
  })

export const getAgencyBySlug = slug => get(['agency-slug', slug], {})

export const getServiceOrderForm = (serviceOrderId, uuid) =>
  get(['serviceorder-form', serviceOrderId], uuid ? { params: { uuid } } : {})

export const getDecrypt = payload =>
  get(
    ['decrypt'],
    { params: payload, transformOnlyResponse: true, formData: true },
    createFormDataDocuments(payload)
  )

export const updateAttachmentsNewServiceOrderForm = uuid => payload =>
  patch(
    ['update-attachments', uuid],
    { transformOnlyResponse: true, formData: true },
    createFormDataDocuments(payload)
  )

export const createNewServiceOrderForm = key => payload =>
  post(['new-service-order-form'], key ? { key } : {}, payload)
