import { schema } from 'normalizr'

import Model from './model'
import State from './state'

class ServiceOrderLink extends Model({
  id: undefined,
  action: undefined,
  createdAt: undefined,
  dateTimeToAccept: undefined,
}) {}

const serviceOrderLinkNewSchema = new schema.Entity(
  'serviceOrderLink',
  {},
  {
    processStrategy: entity => {
      return new ServiceOrderLink(entity).set('state', new State(entity.state))
    },
  }
)

const serviceOrderLinkSchema = [serviceOrderLinkNewSchema]

export { serviceOrderLinkSchema, ServiceOrderLink as default }
