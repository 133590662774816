import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing }) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: spacing(32, 50, 0),
  },
  datesContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    width: '100%',
    maxWidth: '1140px',
    marginBottom: spacing(24),
  },
  attachmentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(24),
    width: '100%',
  },
}))
