import { defineAction } from '_utils/redux'
import * as holidayServices from '_services/holidays'

export const GET_HOLIDAYS = defineAction('GET_HOLIDAYS')

export const getHolidays = () => (dispatch, getState) => {
  dispatch({
    type: GET_HOLIDAYS.ACTION,
    payload: holidayServices.getHolidays(getState().user.authToken),
  })
}
