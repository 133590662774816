/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from '@reach/router'

import { useToast } from '_/hooks/use-toast'
import useRolePermission from '_/hooks/use-role-permission'
import { useDialog } from '_/hooks/use-dialog'

import { Grid } from '@mui/material'
import { Typography } from '@material-ui/core'
import { Button, Datagrid, useConfirm, Loader } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'
import { SaveModelsModal } from './save-modal'
import { LoadModelsModal } from './load-modal'
import { ServiceModal } from './components/ServiceModal'

import useStyles from './styles'

import { TABLE_SX } from '../finance/utils/constants'
import { BudgetServicesColumns } from './constants'

import {
  fetchBudgetServices,
  fetchBudgetImportSuggestions,
  fetchBudgetUndoImportSuggestions,
  fetchBudgetAdjustServices,
  postBudgetSaveTemplates,
  postBudgetService,
  postBudgetLoadTemplates,
  patchBudgetService,
  deleteBudgetService,
} from '_/modules/budget/actions'

import { ROUTES } from '_/utils/constants'

export function BudgetServices() {
  const navigate = useNavigate()
  const { isAdmin } = useRolePermission()

  if (!isAdmin) {
    navigate('/')
    return null
  }

  const { serviceOrderId, budgetId } = useParams()
  const dispatch = useDispatch()
  const styles = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [dialogInfo, setDialogInfo] = useState()
  const [budgetServices, setBudgetServices] = useState([])
  const [serviceModalProps, setServiceModalProps] = useState({
    isOpen: false,
    loading: false,
    defaultValues: undefined,
    onSaveCallback: undefined,
  })
  const [pageLoading, setPageLoading] = useState(false)

  const { showToast } = useToast()
  const { showDialog, closeDialog } = useDialog()

  const { isConfirmed } = useConfirm()
  const [saveModalOpen, handleSaveModal] = useState(false)
  const [loadModalOpen, handleLoadModal] = useState(false)

  const totalPrice = budgetServices.reduce(
    (acc, service) => acc + parseFloat(service.totalPrice),
    0
  )

  const getBudgetServices = useCallback(() => {
    setIsLoading(true)

    const params = { budget: budgetId }

    dispatch(fetchBudgetServices(params))
      .then(data => {
        setBudgetServices(data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
      })
  }, [dispatch])

  const createNewService = useCallback(
    newServiceDTO => {
      setPageLoading(true)

      const {
        service: { id: serviceId },
        ...rest
      } = newServiceDTO

      const payload = {
        service: serviceId,
        ...rest,
        budget: parseInt(budgetId, 10),
      }

      dispatch(postBudgetService(payload))
        .then(() => {
          setPageLoading(false)
          setServiceModalProps(prev => ({ ...prev, isOpen: false }))
          getBudgetServices()
          showToast({ type: 'success' })
        })
        .catch(() => {
          setPageLoading(false)
          showToast({ type: 'error' })
        })
    },
    [budgetId, getBudgetServices]
  )

  const updateService = useCallback(
    (defaultValues, updatedServiceDTO) => {
      setPageLoading(true)

      const {
        service: { id: serviceId },
        ...rest
      } = updatedServiceDTO

      const payload = {
        service: serviceId,
        ...rest,
        budget: parseInt(budgetId, 10),
      }

      dispatch(patchBudgetService(defaultValues.id, payload))
        .then(() => {
          setPageLoading(false)
          setServiceModalProps(prev => ({ ...prev, isOpen: false }))
          getBudgetServices()
          showToast({ type: 'success' })
        })
        .catch(() => {
          setPageLoading(false)
          showToast({ type: 'error' })
        })
    },
    [budgetId]
  )

  const handleBudgetUndoImportSuggestions = useCallback(async () => {
    setDialogInfo({ message: 'Você tem certeza que deseja executar esta ação?' })
    const confirmed = await isConfirmed()

    if (!confirmed) return
    setPageLoading(true)

    await dispatch(fetchBudgetUndoImportSuggestions(budgetId))
      .then(data => {
        setBudgetServices(data)
        setPageLoading(false)
        showToast({
          type: 'success',
        })
      })
      .catch(error => {
        console.error('undo_import_suggestions ->', error)
        setPageLoading(false)
        showToast({
          type: 'error',
          message: error?.error_message || 'Ocorreu um erro desconhecido ao executar a ação.',
        })
      })
  }, [dispatch])

  const handleBudgetImportSuggestions = useCallback(async () => {
    setDialogInfo({ message: 'Você tem certeza que deseja executar esta ação?' })
    const confirmed = await isConfirmed()

    if (!confirmed) return
    setPageLoading(true)

    await dispatch(fetchBudgetImportSuggestions(budgetId))
      .then(data => {
        setBudgetServices(data)
        setPageLoading(false)
        showToast({
          type: 'success',
        })
      })
      .catch(error => {
        console.error('import-suggestions ->', error)
        setPageLoading(false)
        showToast({
          type: 'error',
          message: error?.error_message || 'Ocorreu um erro desconhecido ao executar a ação.',
        })
      })
  }, [dispatch])

  const handleBudgetAdjustServices = useCallback(async () => {
    setDialogInfo({ message: 'Você tem certeza que deseja executar esta ação?' })
    const confirmed = await isConfirmed()
    if (!confirmed) return

    setDialogInfo({ message: 'Você deseja calcular o "Revenue Share" no orçamento?' })
    const confirmedRevenueShare = await isConfirmed()

    const payload = { calculateRevenueShare: confirmedRevenueShare }
    setPageLoading(true)

    await dispatch(fetchBudgetAdjustServices(budgetId, payload))
      .then(() => {
        setPageLoading(false)
        showToast({
          type: 'success',
        })
      })
      .catch(error => {
        console.error('budget-adjust-services ->', error)
        setPageLoading(false)
        showToast({
          type: 'error',
          message: error?.error_message || 'Ocorreu um erro desconhecido ao executar a ação.',
        })
      })
  }, [dispatch])

  const toggleSaveTemplatesModal = useCallback(() => {
    if (!budgetServices?.length) {
      showToast({
        type: 'error',
        message: 'Não há serviços selecionados para realizar esta ação.',
      })
      return
    }

    handleSaveModal(prevState => !prevState)
  }, [budgetServices])

  const handleSaveTemplates = useCallback(
    async payload => {
      await dispatch(postBudgetSaveTemplates(budgetId, payload))
        .then(() => {
          setPageLoading(false)
          handleSaveModal(prevState => !prevState)
          showToast({
            type: 'success',
          })
        })
        .catch(error => {
          setPageLoading(false)
          showToast({
            type: 'error',
            message: error?.error_message || 'Ocorreu um erro desconhecido ao executar a ação.',
          })
        })
    },
    [budgetId]
  )

  const toggleBudgetLoadTemplates = useCallback(async () => {
    setDialogInfo({ message: 'Você tem certeza que deseja executar esta ação?' })
    const confirmed = await isConfirmed()

    if (!confirmed) return
    handleLoadModal(prevState => !prevState)
  }, [dispatch])

  const handleBudgetLoadTemplates = useCallback(
    async payload => {
      handleLoadModal(prevState => !prevState)

      const requestData = {
        templateId: payload?.templateId,
      }

      if (payload?.saveValue) {
        // IF SAVE_VALUES IS TRUE, CHECKS FOR PRICE RECALCULATION
        setDialogInfo({ message: 'Você deseja recalcular os preços?' })
        const confirmed = await isConfirmed()
        requestData.recalculateValues = confirmed
      }

      try {
        const data = await dispatch(postBudgetLoadTemplates(budgetId, requestData))
        setBudgetServices(data)
        showToast({
          type: 'success',
        })
      } catch (error) {
        showToast({
          type: 'error',
          message: error?.error_message || 'Ocorreu um erro desconhecido ao executar a ação.',
        })
      }
    },
    [dispatch, handleLoadModal, budgetId, isConfirmed, setDialogInfo, setBudgetServices, showToast]
  )

  const handleCellClick = useCallback(({ field, row }) => {
    if (field === 'actions') return
    setServiceModalProps({
      isOpen: true,
      defaultValues: { ...row },
      onSaveCallback: updateService,
    })
  }, [])

  const handleDeleteServiceClick = useCallback(
    (event, row) => {
      event.stopPropagation()
      showDialog({
        type: 'warning',
        subject: 'Você tem certeza que deseja excluir este serviço?',
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onApprove: () => {
          setPageLoading(true)

          dispatch(deleteBudgetService(row.id))
            .then(() => {
              setPageLoading(false)

              getBudgetServices()
              closeDialog()
              showToast({ type: 'success' })
            })
            .catch(() => {
              setPageLoading(false)

              closeDialog()
              showToast({ type: 'error' })
            })
        },
        onCancel: closeDialog,
      })
    },
    [dispatch, getBudgetServices]
  )

  const goBack = () => {
    window?.history?.length <= 2
      ? navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrderId}/orcamento/${budgetId}`)
      : navigate(-1)
  }

  // On page first load
  useEffect(() => {
    getBudgetServices()
  }, [])

  return (
    <Grid className={styles.page}>
      {dialogInfo?.message && <ConfirmRemoveDialog message={dialogInfo.message} />}
      <HeaderTitle title="Serviços do orçamento" backButtonAction={goBack}>
        <Grid className={styles.buttonsContainer}>
          <Button
            variant="primary"
            onClick={() => {
              setServiceModalProps({
                isOpen: true,
                defaultValues: undefined,
                onSaveCallback: createNewService,
              })
            }}
            disabled={isLoading}
          >
            Novo
          </Button>
          <Button variant="primary" onClick={handleBudgetAdjustServices} disabled={isLoading}>
            Ajustar orçamento
          </Button>
          <Button variant="primary" onClick={handleBudgetImportSuggestions} disabled={isLoading}>
            Importar sugestões
          </Button>
          <Button
            variant="primary"
            onClick={handleBudgetUndoImportSuggestions}
            disabled={isLoading}
          >
            Desfazer importação
          </Button>
          <Button variant="primary" onClick={toggleBudgetLoadTemplates} disabled={isLoading}>
            Carregar modelo
          </Button>
          <Button variant="primary" onClick={toggleSaveTemplatesModal} disabled={isLoading}>
            Salvar modelo
          </Button>
        </Grid>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.totalValueContainer}>
          <Typography>
            <b>Total: </b>
            {totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </Typography>
        </Grid>
        <Grid className={styles.mainTable}>
          <Datagrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'serviceName', sort: 'asc' }],
              },
            }}
            loading={isLoading}
            rows={budgetServices}
            columns={BudgetServicesColumns(handleDeleteServiceClick)}
            paginationMode="client"
            rowCount={budgetServices?.length || 0}
            sx={TABLE_SX}
            noRowsLabel="Nenhum resultado encontrado"
            onCellClick={handleCellClick}
          />
        </Grid>
      </Grid>

      <SaveModelsModal
        open={saveModalOpen}
        handleConfirm={handleSaveTemplates}
        handleModal={() => toggleSaveTemplatesModal()}
      />

      <LoadModelsModal
        open={loadModalOpen}
        handleConfirm={handleBudgetLoadTemplates}
        handleModal={() => handleLoadModal(prevState => !prevState)}
      />

      <ServiceModal
        budgetServices={budgetServices}
        clearOnClose
        defaultValues={serviceModalProps.defaultValues}
        loading={pageLoading}
        onClose={() => setServiceModalProps(prev => ({ ...prev, isOpen: false }))}
        open={serviceModalProps.isOpen}
        onSaveButtonClick={serviceModalProps.onSaveCallback}
      />
      <Loader open={pageLoading} hasBackdrop />
    </Grid>
  )
}
