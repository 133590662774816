/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    '& .MuiDrawer-paper': {
      display: 'flex',
      gap: spacing(4),
      flexDirection: 'column',
      height: '100vh',
      top: spacing(48),
      borderColor: palette.gray[12],
      transition: 'padding 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    },
  },
  containerOpened: {
    '& .MuiDrawer-paper': {
      padding: spacing(16, 12, 0, 14),
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      alignItems: 'center',
    },
  },
  containerClosed: {
    '& .MuiDrawer-paper': {
      padding: spacing(16, 16, 0),
    },
  },
  icon: {
    width: spacing(24),
    height: spacing(24),
    fill: `${palette.gray[32]}!important`,
  },
  menuIcon: {
    '&.MuiIconButton-root': {
      justifyContent: 'flex-start',
      marginRight: 'auto',
    },
  },
  menuItem: {
    width: spacing(190),
  },
  filled: {
    fill: `${palette.primary.main}!important`,
    color: `${palette.primary.main}!important`,
  },
  menuLabel: {
    display: 'flex',
    paddingLeft: spacing(18),
  },
  iconsExpanded: {
    display: 'flex',
    borderRadius: '8px',
  },
}))
