export const FORM_FIELDS = {
  PROVIDER_NAME: 'providerName',
  METABASE: 'metabase',
  REASON: 'reason',
  DETAILS: 'details',
  ERROR: 'error',
}

export const MANDATORY_FIELDS = [
  FORM_FIELDS.PROVIDER_NAME,
  FORM_FIELDS.METABASE,
  FORM_FIELDS.REASON,
]

export const FORM_INITIAL_STATE = {
  [FORM_FIELDS.PROVIDER_NAME]: null,
  [FORM_FIELDS.METABASE]: null,
  [FORM_FIELDS.REASON]: null,
  [FORM_FIELDS.DETAILS]: null,
  [FORM_FIELDS.ERROR]: null,
}

export const ACTIONS = {
  UPDATE_FIELD: 'updateField',
  SET_ERROR: 'setError',
}

export const reducer = (state, { type, payload: { name, value, error } }) => {
  switch (type) {
    case ACTIONS.UPDATE_FIELD:
      return { ...state, [name]: value, error: { ...state[FORM_FIELDS.ERROR], [name]: null } }
    case ACTIONS.SET_ERROR:
      return { ...state, error }
    default:
      return state
  }
}
