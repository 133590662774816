import React, { useEffect, useState, useMemo } from 'react'
import { Grid } from '@mui/material'
import HeaderTitle from '_/components/header-title'
import { Alert, Button, Loader, Typography } from '@refera/ui-web'
import { Edit2, Export3 } from '@refera/ui-icons'
import { FINANCE_ROUTES, ROUTES } from '_/utils/constants'
import Theme from '@refera/ui-core'
import Accordion from '../components/Accordion'
import useStyles from './styles'
import { navigate, useParams, Redirect } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '_/modules/authentication/selectors'
import {
  UPDATE_BUDGET_INSTALLMENT,
  UPDATE_PAYMENT_INSTALLMENT,
  getTradesmanInstallmentsDetails,
} from '_/modules/finance/actions'

import useRolePermission from '_/hooks/use-role-permission'
import useToggle from '_/hooks/use-toggle'
import { EditPaymentModal } from './modals/EditPaymentModal'
import { EditInstallmentModal } from './modals/EditInstallmentModal'
import useFetchCall from '_/hooks/use-fetch-call'
import {
  handleBankField,
  handleFormatInstallmentDetailsItemValue,
  handleStatusField,
  handleStringField,
} from '../utils/functions'

const ACCORDION_TITLES = ['Dados da parcela', 'Pagamento', 'Conta de depósito']
const EDITABLE_INSTALLMENT_FIELDS = ['dueDate', 'providerValue']

const InstallmentDetails = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { paymentAnticipationId, installmentId } = useParams()
  const user = useSelector(userSelector)
  const isTradesman = useMemo(() => user?.getRoles?.includes('tradesman'), [user])
  const { isAdmin } = useRolePermission()

  const [isEditPaymentDialogOpen, toggleEditPaymentDialogOpen] = useToggle()
  const [isEditInstallmentDialogOpen, toggleEditInstallmentDialogOpen] = useToggle()

  const [installmentInfo, setInstallmentInfo] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState('')

  useFetchCall(UPDATE_BUDGET_INSTALLMENT.ACTION, handleGetInstallmentInfo)
  useFetchCall(UPDATE_PAYMENT_INSTALLMENT.ACTION, handleGetInstallmentInfo)

  useEffect(() => {
    if (!Object.keys(installmentInfo).length && installmentId && !errors) handleGetInstallmentInfo()
  }, [installmentInfo, installmentId])

  const goBack = () => {
    if (isTradesman) {
      const url = paymentAnticipationId
        ? `${ROUTES.INSTALLMENTS_RECEIPTS}/antecipacoes/${paymentAnticipationId}`
        : ROUTES.INSTALLMENTS_RECEIPTS
      return navigate(url)
    }
    if (paymentAnticipationId) {
      return navigate(`${FINANCE_ROUTES.PAYMENT_ADVANCE}/${paymentAnticipationId}`)
    }

    return navigate(`${FINANCE_ROUTES.MANAGE_INSTALLMENTS}`)
  }

  const installmentValue = useMemo(
    () => installmentInfo?.budgetInstallment?.providerValue,
    [installmentInfo]
  )

  const pageInfo = useMemo(() => {
    const { budgetInstallment, paymentInstallment, bankAccount } = installmentInfo

    const amountPaid = paymentInstallment?.amountPaid || 0.0

    const detailsInfo = [
      { label: 'Status do pagamento', value: handleStatusField(budgetInstallment?.status) },
      { label: 'ID do chamado', value: handleStringField(budgetInstallment?.serviceOrderId) },
      { label: 'Parcela', value: handleStringField(budgetInstallment?.currentInstallment) },
      { label: 'Vencimento', value: budgetInstallment?.dueDate, type: 'date', name: 'dueDate' },
      { label: 'Valor da parcela', value: installmentValue, type: 'money', name: 'providerValue' },
    ]

    const paymentInfo = [
      {
        label: 'Pagamento',
        value: paymentInstallment?.paymentDate || null,
        type: 'date',
        name: 'paymentDate',
      },
      {
        label: '- Taxa antecipação',
        value: paymentInstallment?.anticipationTax || 0,
        type: 'money',
        name: 'anticipationTax',
      },
      {
        label: '- Desconto',
        value: paymentInstallment?.discount || 0,
        type: 'money',
        name: 'discount',
      },
      {
        label: '+ Acréscimo',
        value: paymentInstallment?.additionValue || 0,
        type: 'money',
        name: 'additionValue',
      },
      {
        label: '+ Juros',
        value: paymentInstallment?.interest || 0,
        type: 'money',
        name: 'interest',
      },
      {
        label: '+ Multa',
        value: paymentInstallment?.fine || 0,
        type: 'money',
        name: 'fine',
      },
      {
        label: 'Valor pago',
        value: amountPaid,
        type: 'money-readonly',
        name: 'amountPaid',
      },
    ]

    const accountInfo = [
      {
        label: 'Banco',
        value: handleStringField(bankAccount?.bankName),
      },
      { label: 'Agência', value: handleStringField(bankAccount?.bankAgencyNumber) },
      {
        label: 'Conta',
        value: handleBankField(bankAccount?.bankAccountNumber, bankAccount?.bankAccountDigit),
      },
      {
        label: 'Nome completo do titular',
        value: handleStringField(bankAccount?.bankAccountOwnerName),
      },
    ]
    return { detailsInfo, paymentInfo, accountInfo }
  }, [installmentInfo, isTradesman])

  const serviceOrderId = useMemo(() => {
    return installmentInfo?.budgetInstallment?.serviceOrderId
  }, [installmentInfo])

  const bannerMessage = useMemo(() => {
    switch (installmentInfo?.budgetInstallment?.status) {
      case 'paying':
        return 'Esta parcela ainda não está paga, mas já está em processamento pelo banco.'
      case 'anticipating':
        return 'Esta parcela pertence a um pedido de antecipação ainda não realizado. Os dados de pagamento são o que se espera receber caso o pedido seja aceito e processado.'
      case 'error':
        return 'Houve um erro no processamento de tal pagamento pelo banco. Contate a Refera para maiores informações.'
      default:
        return ''
    }
  }, [installmentInfo?.budgetInstallment?.status])

  async function handleGetInstallmentInfo() {
    await dispatch(getTradesmanInstallmentsDetails({ installmentId }))
      .then(res => setInstallmentInfo(res?.results))
      .catch(res => setErrors(res?.detail))
    setIsLoading(false)
  }

  const handleGoToServiceOrder = () => {
    const serviceOrderUrl = isTradesman
      ? `/detalhes-do-chamado/${serviceOrderId}`
      : `${ROUTES.SERVICE_ORDER}/${serviceOrderId}`
    return window.open(serviceOrderUrl, '_blank', 'noopener,noreferrer')
  }

  const editInstallmentValues = useMemo(
    () =>
      pageInfo?.detailsInfo?.filter(infoItem =>
        EDITABLE_INSTALLMENT_FIELDS.includes(infoItem.name)
      ),
    [pageInfo?.detailsInfo]
  )

  if (errors?.includes('permission') || errors?.toLowerCase()?.includes('unauthorized'))
    return <Redirect to="/acesso-negado" noThrow />
  if (errors?.includes('not found')) return <Redirect to="/nao-encontrado" noThrow />
  if (isLoading) return <Loader hasBackdrop open={isLoading} label="Aguarde..." />

  return (
    <Grid>
      <HeaderTitle title="Parcela" backButtonAction={goBack}>
        <Grid className={styles.buttonsContainer}>
          {isAdmin && (
            <>
              <Button
                className={styles.edit}
                variant="secondary"
                startIcon={<Edit2 color={Theme.Colors.Primary.Base} />}
                onClick={toggleEditInstallmentDialogOpen}
              >
                Editar parcela
              </Button>
              <Button
                className={styles.edit}
                variant="secondary"
                startIcon={<Edit2 color={Theme.Colors.Primary.Base} />}
                onClick={toggleEditPaymentDialogOpen}
              >
                Editar pagamento
              </Button>
            </>
          )}
          <Button
            variant="secondary"
            startIcon={<Export3 color={Theme.Colors.Primary.Base} />}
            onClick={handleGoToServiceOrder}
            disabled={isLoading}
          >
            Abrir chamado
          </Button>
        </Grid>
      </HeaderTitle>
      <Grid className={styles.container}>
        {bannerMessage && <Alert severity="info" title={bannerMessage} />}
        {Object.values(pageInfo)?.map((accordion, index) => {
          return (
            <Accordion title={ACCORDION_TITLES[index]} key={ACCORDION_TITLES[index]}>
              <Grid className={styles.accordionWrapper}>
                {accordion?.map(item => (
                  <Grid className={styles.accordionItem} key={item.label}>
                    <Typography className={styles.accordionLabel}>{item.label}</Typography>
                    <Typography className={styles.accordionValue}>
                      {handleFormatInstallmentDetailsItemValue(item)}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Accordion>
          )
        })}
      </Grid>
      {isEditInstallmentDialogOpen && (
        <EditInstallmentModal
          open={isEditInstallmentDialogOpen}
          onClose={toggleEditInstallmentDialogOpen}
          installmentInfo={editInstallmentValues}
        />
      )}
      {isEditPaymentDialogOpen && (
        <EditPaymentModal
          open={toggleEditPaymentDialogOpen}
          onClose={toggleEditPaymentDialogOpen}
          installmentInfo={pageInfo?.paymentInfo}
          installmentValue={installmentValue}
        />
      )}
    </Grid>
  )
}

export default InstallmentDetails
