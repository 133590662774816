import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  iconButton: {
    padding: spacing(6),
  },
  input: {
    padding: spacing(10, 0),
  },
  fullWidth: {
    // FIXME: Remove this !important once refera's lib allows to override styles
    width: '100% !important',
  },
  errorMessage: {
    color: Theme.Colors.Red.Base,
  },
}))
