import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  footer: {
    backgroundColor: palette.gray[4],
    padding: spacing(16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerContent: {
    color: palette.gray[100],
  },
}))
