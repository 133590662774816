import { createReducer } from '_utils/redux'
import { GET_PAYMENT_ADVANCE } from './actions'
import { List, Record } from 'immutable'

const INITIAL_STATE = new Record({
  paymentAdvance: List(),
})()

const payments = createReducer(INITIAL_STATE, {
  [GET_PAYMENT_ADVANCE.FULFILLED]: (state, { payload }) =>
    state.set('paymentAdvance', List(payload)),
})

export default payments
