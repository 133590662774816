import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  icon: {
    width: spacing(13.2),
    height: spacing(11),
    marginRight: spacing(8),
  },
  3: {
    fill: palette.custom.red,
  },
  2: {
    fill: palette.custom.yellow,
  },
  1: {
    fill: palette.primary.main,
  },
  boldText: {
    fontWeight: 'bold',
  },
}))
