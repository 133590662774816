import { GET_AGENCY_BY_SLUG } from './actions'

// DEPRECATED FILE (SHOULD BE DELETED LATER !!!!!!)

export const formSelector = state => state.form.serviceOrder
export const agencySelector = state => state.form.agency // REMOVE THIS AS SOON AS POSSIBLE, THIS CREATES UNECESSARY CONFUSION, USE agencySelector FROM AGENCY REDUX INSTEAD
export const uuidSelector = state => state.form.uuid
export const isAgencyLoadingSelector = state => !!state.loading.get(GET_AGENCY_BY_SLUG.ACTION)

/** QUICKFIX
 * formAgencyCustomFieldsSelector had this value before, so a new selector
 * was created to avoid breaking the code. This should be removed as soon as possible
 * refactoring StartPage and ContactPage from src\views\service-form\pages\index.jsx
 */
export const agencyCustomFieldsFromServiceFormSelector = state => state.form.agency.customFields

// EXCEPT FOR THE ONE BELOW. TRANSFER TO AGENCY INSTEAD (?)
export const formAgencyCustomFieldsSelector = state =>
  state.agencies?.results?.get(state.agencies?.currentAgency)?.get('customFields')

export const formPagesSelector = state => state?.form?.get('form')?.toArray()
