import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { DatePicker } from '@refera/ui-web'

import useStyles from './styles'
import classNames from 'classnames'

export const DatePickerInterval = ({
  name,
  label,
  handleChange,
  localState,
  error = null,
  firstError = null,
  secondError = null,
  disableFuture = true,
  required = false,
  labelClasses = null,
}) => {
  const styles = useStyles()

  return (
    <Grid>
      <Typography className={classNames(styles.accordion, labelClasses)}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </Typography>
      <Grid className={styles.wrapper}>
        <div>
          <DatePicker
            key={`${name}Start`}
            label=" "
            value={localState?.[`${name}Start`]}
            onChange={value => handleChange(value, `${name}Start`)}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            maxDate={localState?.[`${name}End`] || undefined}
            disableFuture={disableFuture}
            mask="__/__/____"
          />
          {!!firstError && <div className={styles.error}>{firstError}</div>}
        </div>
        <Typography className={styles.dotDivision}>•</Typography>
        <div>
          <DatePicker
            key={`${name}End`}
            label=" "
            value={localState?.[`${name}End`]}
            onChange={value => handleChange(value, `${name}End`)}
            refuse={/[^\d\\.]+/gi}
            variant="inline"
            format="dd/MM/yyyy"
            placeholder="DD/MM/AAAA"
            disableFuture={disableFuture}
            minDate={localState?.[`${name}Start`] || undefined}
            mask="__/__/____"
          />
          {!!secondError && <div className={styles.error}>{secondError}</div>}
        </div>
      </Grid>
      {!!error && <div className={styles.error}>{error}</div>}
    </Grid>
  )
}
