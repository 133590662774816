import { schema } from 'normalizr'

import Model from './model'

class Category extends Model({
  id: undefined,
  name: undefined,
  icon: undefined,
  parent: undefined,
  isActive: undefined,
}) {}

const categoryNewSchema = new schema.Entity(
  'service',
  {},
  {
    processStrategy: entity => {
      return new Category(entity)
    },
  }
)

const categorySchema = [categoryNewSchema]

export { categorySchema, Category as default }
