import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  switchStyles: {
    '&.ReferaSwitch__Root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1px',

      '& .ReferaSwitch__Label': {
        color: palette.gray[64],
        marginBottom: spacing(6),
        fontSize: spacing(12),
        width: 'max-content',
        display: 'block',
      },
    },
  },
}))
