import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    '& .MuiDrawer-paper': {
      width: spacing(392),
      top: spacing(48),
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
  header: {
    padding: spacing(16, 10, 10, 32),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    width: spacing(32),
    height: spacing(32),
    fontSize: spacing(18),
    borderRadius: '50%',
    backgroundColor: Theme.Colors.Primary.Lightest,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacing(12),
  },
  icon: {
    width: spacing(16),
    height: spacing(16),
  },
  closeIcon: {
    width: spacing(24),
    height: spacing(24),
    fontSize: spacing(12),
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: Theme.Colors.Primary.Base,
    fontWeight: 500,
  },
  divider: {
    backgroundColor: Theme.Colors.Primary.Base,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '84%',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(24),
    padding: spacing(32),
    overflowY: 'scroll',
    marginBottom: '25%',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing(24),
    padding: spacing(24),
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    zIndex: 2,
    bottom: 48,
  },
  inputsWrapper: {
    display: 'flex',
    gap: spacing(14),
  },
  dotDivision: {
    marginTop: spacing(32),
  },
  doubleInput: {
    width: '44%',
  },
  inputLabel: {
    color: Theme.Colors.Grayscale.OneThousand,
    fontFamily: 'DM Sans',
    fontSize: '12px',
    fontWeight: 500,
  },
}))
