export const getMessageSelector = state => state?.messaging?.get('messageList')

export const setItemsAsListSelector = state => {
  const { setItems } = state.messaging

  return Object.entries(setItems).reduce((acc, [key, value]) => {
    acc.push({ value: key, label: value })
    return acc
  }, [])
}

export const setItemsSelector = state => state.messaging.setItems
