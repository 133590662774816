import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  paper: {
    padding: spacing(16, 8, 30, 24),
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderColor: palette.gray[12],
    position: 'relative',
  },
  focusedPared: {
    borderColor: palette.primary.main,
  },
  paperWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: spacing(128),
    gap: spacing(24),
  },
  label: {
    color: palette.gray[64],
    marginTop: spacing(8),
  },
  value: {
    fontWeight: 'normal',
    color: palette.gray[80],
  },
  valueBreak: {
    whiteSpace: 'pre-wrap',
    fontWeight: 'normal',
    color: palette.gray[80],
  },
  smaller: {
    fontSize: spacing(14),
  },
  icon: {
    height: spacing(14),
    width: spacing(14),
  },
  button: {
    width: spacing(100),
    padding: spacing(4),
    fontWeight: 'normal',
    margin: 'auto 0 0 auto',
    position: 'absolute',
    right: spacing(4),
    bottom: spacing(10),

    '& .MuiButton-endIcon': {
      marginLeft: spacing(4),
    },
  },
  input: {
    outline: 'none',
    color: palette.gray[80],
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    resize: 'none',
  },
}))
