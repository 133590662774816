import { List, Record } from 'immutable'

import { Form, Agency } from '_models/'
import { createReducer } from '_utils/redux'
import humps from 'humps'

import {
  CREATE_SERVICE_ORDER_FORM,
  UPDATE_SERVICE_ORDER_FORM,
  GET_AGENCY_BY_SLUG,
  GET_SERVICE_ORDER_FORM,
  GET_FORM_AGENCY_CUSTOM_FIELDS,
  GET_FORM_AGENCY,
} from './actions'

// DEPRECATED FILE (CAN BE DELETED LATER)

const INITIAL_STATE = new Record({
  uuid: '',
  serviceOrder: new Form(),
  agency: new Agency(),
  form: null,
})()

const setForm = payload =>
  new Form({
    ...payload,
    ...payload.property,
    listingId: payload?.property?.id?.toString(),
    number: payload?.property?.number?.toString(),
    id: payload.id,
  })

export default createReducer(INITIAL_STATE, {
  [CREATE_SERVICE_ORDER_FORM.FULFILLED]: (state, { payload }) => {
    return state.set('serviceOrder', setForm(payload)).set('uuid', payload.uuid)
  },
  [GET_SERVICE_ORDER_FORM.FULFILLED]: (state, { payload }) => {
    return state.set('serviceOrder', setForm(payload)).set('uuid', payload.uuid)
  },
  [UPDATE_SERVICE_ORDER_FORM.FULFILLED]: (state, { payload }) => {
    return state.set(
      'serviceOrder',
      state.get('serviceOrder').merge(setForm(payload)).set('id', payload.id)
    )
  },
  [GET_AGENCY_BY_SLUG.FULFILLED]: (state, { payload }) => {
    return state.set('agency', new Agency(payload))
  },
  [GET_FORM_AGENCY_CUSTOM_FIELDS.FULFILLED]: (state, { payload }) => {
    const formattedPayload = payload?.results?.reduce((acc, { field, label, value }) => {
      const formattedField = humps.camelize(field)
      if (!acc[formattedField]) acc[formattedField] = [{ label, value }]
      else acc[formattedField] = [...acc[formattedField], { label, value }]

      return acc
    }, {})

    if (!Object.keys(formattedPayload).length) return state

    const agencyCustomFields = state.getIn(['agency', 'customFields'], {})
    const mergedCustomFields = { ...agencyCustomFields, ...formattedPayload }

    return state.setIn(['agency', 'customFields'], mergedCustomFields)
  },
  [GET_FORM_AGENCY.FULFILLED]: (state, { payload }) => {
    return state.set('form', List(payload))
  },
})
