import React, { useCallback, useEffect, useMemo, useState, useReducer } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Datagrid, Typography } from '@refera/ui-web'
import { useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from '_hooks/use-previous'

import { getVipClients } from '_/modules/agencyVip/actions'
import {
  vipClientSelector,
  vipClientCountSelector,
  vipClientPageSelector,
  vipClientPageSizeSelector,
} from '_/modules/agencyVip/selector'
import { agencySelector } from '_/modules/agency/selectors'
import useToggle from '_hooks/use-toggle'

import useStyles, { dataGridStyle } from '../../classification-management/styles'
import { COLUMNS } from './constants'
import { reducer, INITIAL_STATE, ACTIONS } from '../reducer'
import VipClientsForm from '../vip-clients-form/index'
import { useToast } from '_/hooks/use-toast'

const VIPClientesManagementView = () => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const wasLoading = usePrevious(isLoading)
  const { agencyId } = useParams()
  const agency = useSelector(agencySelector)
  const { showToast } = useToast()

  const [localState, localDispatch] = useReducer(reducer, INITIAL_STATE)
  const vipClients = useSelector(vipClientSelector)
  const vipClientCount = useSelector(vipClientCountSelector)
  const vipClientPage = useSelector(vipClientPageSelector)
  const vipClientPageSize = useSelector(vipClientPageSizeSelector)
  const [selectedClient, setSelectedClient] = useState(null)

  const [openClientForm, toggleClientForm] = useToggle()

  const rows = useMemo(() => vipClients ?? [], [vipClients])

  const activeVipClients = useCallback(() => {
    return vipClients?.filter(client => client?.isActive === true && client?.isVip).length
  }, [vipClients])

  const handleGoToClient = useCallback(event => {
    setSelectedClient(event.row)
    toggleClientForm()
  }, [])

  const handlePage = useCallback(page => {
    localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { page } })
  }, [])

  const handlePageSize = useCallback(pageSize => {
    localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { pageSize } })
  }, [])

  const handleSortModel = useCallback(orderObj => {
    if (orderObj[0]?.field && orderObj[0]?.sort) {
      const { field, sort } = orderObj[0]
      const order = `${sort === 'desc' ? '-' : ''}${field}`

      localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { order } })
    }
  }, [])

  const getClientsToList = useCallback(() => {
    dispatch(
      getVipClients({
        ...localState,
        page: localState.page + 1,
        agency: agencyId,
      })
    )

    return setIsLoading(false)
  }, [localState, agencyId])

  useEffect(() => {
    if (!wasLoading) {
      getClientsToList()
    }
  }, [wasLoading, localState, agencyId])

  const handleOpenForm = useCallback(() => {
    setSelectedClient(null)
    const activeVipCount = activeVipClients()
    if (activeVipCount >= agency?.vipLimit) {
      showToast({
        type: 'error',
        message: 'Você já atingiu o máximo de clientes VIPs autorizados pela Refera.',
      })
    } else {
      toggleClientForm()
    }
  }, [agency?.vipLimit, vipClients])

  return (
    <>
      <Grid className={styles.container}>
        <Grid className={styles.header}>
          <Typography variant="h4" component="h1" className={styles.title}>
            Clientes VIP
          </Typography>
          <Button onClick={handleOpenForm}>Novo</Button>
        </Grid>

        <Grid className={styles.tableContainer}>
          <Grid className={styles.mainTable}>
            <Datagrid
              paginationMode="server"
              sx={dataGridStyle}
              rowCount={vipClientCount}
              onRowClick={handleGoToClient}
              onPageChange={handlePage}
              onPageSizeChange={handlePageSize}
              onSortModelChange={handleSortModel}
              page={vipClientPage}
              pageSize={vipClientPageSize > 100 ? 100 : vipClientPageSize}
              rows={rows}
              columns={COLUMNS}
            />
          </Grid>
        </Grid>
        {openClientForm && (
          <VipClientsForm
            client={selectedClient}
            closeModal={() => toggleClientForm()}
            onCancel={() => toggleClientForm()}
          />
        )}
      </Grid>
    </>
  )
}

export default VIPClientesManagementView
