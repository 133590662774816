import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    paddingTop: spacing(20),
  },
  containerTitle: {
    fontSize: spacing(16),
    lineHeight: spacing(24),
    color: palette.primary.main,
    paddingBottom: spacing(13),
  },
  label: {
    fontSize: spacing(16),
    lineHeight: spacing(16),
    color: palette.gray[64],
  },
  labelText: {
    fontSize: spacing(16),
    lineHeight: spacing(16),
    color: palette.gray[32],
    marginTop: spacing(10),
  },
  text: {
    fontSize: spacing(16),
    lineHeight: spacing(16),
    color: palette.gray[64],
    marginTop: spacing(10),
  },
  buttons: {
    marginTop: spacing(20),
  },
  confirmButton: {
    marginRight: spacing(16),
  },
  input: {
    '& .MuiOutlinedInput-multiline': {
      alignItems: 'baseline',
      minHeight: spacing(152),
      marginTop: spacing(10),
    },
  },
}))
