import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: palette.primary.secondary,
  },
  alert: {
    marginBottom: spacing(40),
  },
  header: {
    position: 'absolute',
  },
  content: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    flexDirection: 'column',
    alignContent: 'center',
  },
  form: {
    width: spacing(368),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    letterSpacing: spacing(-0.3),
    color: palette.primary.main,
    marginBottom: spacing(32),
  },
  pic: {
    display: 'flex',
    justifyContent: 'center',
  },
  fileIcon: {
    width: spacing(80),
    height: spacing(80),
    marginTop: spacing(100),
    marginBottom: spacing(10),
  },
  diagnosis: {
    color: '#141414',
    fontWeigth: '500',
    marginBottom: spacing(20),
  },
  diagnosisText: {
    width: spacing(921),
    color: '#696969',
    fontWeigth: '400',
    padding: spacing(10),
    border: '1px solid #E3E3E3',
    boxSizing: 'border-box',
    borderRadius: '4px',
    whiteSpace: 'pre-line',
  },
  solution: {
    color: '#141414',
    fontWeigth: '500',
    marginBottom: spacing(20),
    marginTop: spacing(20),
  },
  tableTitle: {
    color: '#141414',
    fontWeigth: '500',
    marginBottom: spacing(20),
    marginTop: spacing(20),
  },
  tableList: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftList: {
    width: '83%',
  },
  listHeaderDescription: {
    color: palette.gray[80],
    paddingBottom: spacing(10),
  },
  leftListItems: {
    height: spacing(30),
    border: '1px solid #E3E3E3',
    borderRadius: spacing(4),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(10),
    fontSize: spacing(14),
    marginBottom: spacing(5),
    fontFamily: 'Roboto',
  },
  rightList: {
    width: '15%',
  },
  listHeaderPrice: {
    color: palette.gray[80],
    paddingBottom: spacing(10),
  },
  rightListItems: {
    height: spacing(30),
    border: '1px solid #E3E3E3',
    borderRadius: spacing(4),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(10),
    marginBottom: spacing(5),
    fontSize: spacing(14),
    fontFamily: 'Roboto',
  },
  totalPrice: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(20),
    marginBottom: spacing(20),
  },
  totalPriceText: {
    fontWeight: '500',
    fontSize: spacing(16),
  },
  totalPriceValue: {
    marginLeft: spacing(17),
    fontWeight: '500',
    fontSize: spacing(23),
    color: palette.primary.main,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: spacing(440),
    gap: spacing(8),
    color: '#141414',
  },
  selectInput: {
    '&:hover': {
      cursor: props => props.cannotEditBudget && 'not-allowed',
    },
    '& :after': {
      content: '" "',
      color: 'red',
    },
  },
  selectLabel: {
    color: '#141414',
    fontWeight: 'bold',
  },
  guarantee: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(10),
    margin: spacing(30, 0),
  },
  guaranteeText: {
    color: '#141414',
    fontWeight: 'bold',
  },
  employees: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(10),
  },
  employeesText: {
    marginLeft: spacing(10),
    color: '#141414',
    fontWeigth: '500',
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(10),
  },
  durationText: {
    marginLeft: spacing(10),
    color: '#141414',
    fontWeigth: '500',
  },
  mediaSection: {
    marginTop: spacing(30),
  },
  mediaWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '15px',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '20px',
    justifyContent: 'flex-start',
  },
  mediaTitle: {
    color: '#141414',
  },
  paymentTitle: {
    color: '#141414',
    marginTop: spacing(36),
  },
  paymentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: spacing(16, 0, 48),
  },
  budgetImage: {
    width: spacing(80),
    height: spacing(80),
    marginRight: spacing(10),
    borderRadius: spacing(4),
  },
  buttons: {
    marginTop: spacing(20),
  },
  confirmButton: {
    marginRight: spacing(16),
  },
  footer: {
    left: '0',
    bottom: '0',
    width: '100%',
    marginTop: spacing(50),
  },
}))
