import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Dialog, Grid, Typography, DialogContent, DialogActions } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Button, IconButton } from '@refera/ui-web'
import useStyles from './styles'

const DetailModal = ({
  handleModal,
  title,
  children,
  handleSubmit,
  disabledSubmit,
  className,
  contentClassName,
  hideActionButtons,
  isLoading,
  leftButtonText,
  rightButtonText,
  isHideLeftButton,
  isHideRightButton,
  isShowHeader,
  isTextButton,
}) => {
  const styles = useStyles()

  return (
    <Dialog open onClose={handleModal} className={classnames(styles.modal, className)}>
      {isShowHeader && (
        <Grid className={styles.header}>
          {title && (
            <div className={styles.title}>
              <Typography component="span" style={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
              <hr className={styles.hline} />
            </div>
          )}
          <Grid className={styles.buttons}>
            <IconButton
              aria-label="Close modal"
              onClick={handleModal}
              buttonClassName={styles.iconButton}
            >
              <CloseIcon className={styles.icon} />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <DialogContent className={classnames(styles.content, contentClassName)}>
        {children}
      </DialogContent>
      {!hideActionButtons && (
        <DialogActions className={styles.actions}>
          {!isHideLeftButton && (
            <Button
              disabled={disabledSubmit}
              className={classnames({
                [styles.textButton]: isTextButton,
                [styles.textButtonActive]: isTextButton,
                [styles.button]: !isTextButton,
              })}
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              isLoading={isLoading}
            >
              {leftButtonText}
            </Button>
          )}
          {!isHideRightButton && (
            <Button
              className={classnames({
                [styles.textButton]: isTextButton,
                [styles.button]: !isTextButton,
              })}
              onClick={handleModal}
              color="primary"
              variant="outlined"
            >
              {rightButtonText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

DetailModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  disabledSubmit: PropTypes.bool,
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  title: PropTypes.string,
  hideActionButtons: PropTypes.bool,
  isLoading: PropTypes.bool,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string,
  isHideLeftButton: PropTypes.bool,
  isHideRightButton: PropTypes.bool,
  isShowHeader: PropTypes.bool,
  isTextButton: PropTypes.bool,
}

DetailModal.defaultProps = {
  handleSubmit: () => {},
  className: '',
  contentClassName: '',
  disabledSubmit: false,
  title: '',
  hideActionButtons: false,
  isLoading: false,
  leftButtonText: 'Salvar',
  rightButtonText: 'Cancelar',
  isHideLeftButton: false,
  isHideRightButton: false,
  isShowHeader: true,
  isTextButton: false,
}
export default React.memo(DetailModal)
