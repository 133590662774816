export const REASON_TO_REFUSE_BUDGET = {
  DONT_WANT: 'customer_does_not_want_to_do',
  OWN: 'customer_will_do_on_their_own',
  PAYMENT: 'customer_did_not_like_payment_terms',
  SOLUTION: 'customer_did_not_like_solution',
  PRICE: 'customer_did_not_like_price',
  OTHER: 'another_reason',
  ACCOUNT_DELETED: 'account_was_deleted',
  MISSING_VISIT: 'missing_visit',
  INADEQUATE_SOLUTION: 'inadequate_solution',
  INADEQUATE_PRICE: 'inadequate_price',
  MISSING_PHOTO: 'missing_photo',
  NO_WARRANTY: 'no_warranty',
}

export const REASON_TO_REFUSE_BUDGET_OPTIONS = [
  { value: REASON_TO_REFUSE_BUDGET.DONT_WANT, label: 'O cliente não quer fazer' },
  { value: REASON_TO_REFUSE_BUDGET.OWN, label: 'O cliente vai fazer por conta própria' },
  {
    value: REASON_TO_REFUSE_BUDGET.PAYMENT,
    label: 'O cliente não gostou das condições de pagamento',
  },
  { value: REASON_TO_REFUSE_BUDGET.SOLUTION, label: 'O cliente não gostou da solução' },
  { value: REASON_TO_REFUSE_BUDGET.PRICE, label: 'O cliente não gostou do preço' },
  { value: REASON_TO_REFUSE_BUDGET.OTHER, label: 'Outro motivo' },
  { value: REASON_TO_REFUSE_BUDGET.ACCOUNT_DELETED, label: 'Prestador vinculado excluiu a conta' },
  { value: REASON_TO_REFUSE_BUDGET.MISSING_VISIT, label: 'Falta de visita' },
  { value: REASON_TO_REFUSE_BUDGET.INADEQUATE_SOLUTION, label: 'Solução não adequada' },
  { value: REASON_TO_REFUSE_BUDGET.INADEQUATE_PRICE, label: 'Preço não adequado' },
  { value: REASON_TO_REFUSE_BUDGET.MISSING_PHOTO, label: 'Falta de foto' },
  { value: REASON_TO_REFUSE_BUDGET.NO_WARRANTY, label: 'Sem garantia' },
].sort((a, b) => a.label.localeCompare(b.label))
