import React from 'react'
import { Grid } from '@material-ui/core'

import Chip from '_components/chip'
import Svg from '_components/svg'
import CategoryIndicator from '_components/category-indicator'

import CloseIcon from '_assets/icons/ic-close-circle.svg'
import SaveIcon from '_assets/icons/ic-tick-circle.svg'

import { MESSAGING_VALUES } from '_utils/constants'

import useStyles from './styles'

const DefaultCell = ({ value }) => {
  const styles = useStyles()

  return <p className={styles.text}>{value}</p>
}

const BusinessFrontCell = ({ value }) => {
  const styles = useStyles()

  if (value.length > 0) {
    const { description: firstDescription } = value[0]
    const extraDescriptions = value.slice(1).map(businessFront => businessFront?.description)

    return (
      <Grid container alignItems="center" className={styles.singleRowChip}>
        <Chip label={firstDescription} />
        {extraDescriptions?.length > 0 && (
          <CategoryIndicator
            tooltipText={extraDescriptions
              ?.sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
              ?.join(', ')}
            label={`+${extraDescriptions?.length}`}
          />
        )}
      </Grid>
    )
  }

  return null
}

const IdCell = ({ value }) => {
  const styles = useStyles()

  return <div className={styles.colunm}>{value}</div>
}
const Default = ({ value }) => {
  const styles = useStyles()
  return (
    <div className={styles.default}>
      {value ? (
        <Svg icon={SaveIcon} className={styles.icon} />
      ) : (
        <Svg icon={CloseIcon} className={styles.icon} />
      )}
    </div>
  )
}
const Define = ({ value }) => {
  const styles = useStyles()
  return (
    <div className={styles.define}>
      {value === 'Ambos' ? (
        <p className={styles.text}>WhatsApp, Email</p>
      ) : (
        <p className={styles.text}>{value}</p>
      )}
    </div>
  )
}

export const COLUMNS = {
  DEFAULT: [
    {
      field: MESSAGING_VALUES.ID,
      headerName: 'ID',
      sortable: true,
      width: 100,
      renderCell: IdCell,
    },
    {
      field: MESSAGING_VALUES.BUSINESS_FRONT,
      headerName: 'Fluxo',
      sortable: true,
      flex: 1,
      renderCell: ({ value }) => <BusinessFrontCell value={value} />,
    },
    {
      field: MESSAGING_VALUES.STEP_STATUS,
      headerName: 'Etapa',
      sortable: true,
      flex: 1,
      renderCell: ({ value }) => <DefaultCell value={value ? value?.desc : ''} />,
    },
    {
      field: MESSAGING_VALUES.DESTINATION,
      headerName: 'Destino',
      sortable: true,
      flex: 1,
      renderCell: ({ value }) => <DefaultCell value={value ? value?.desc : ''} />,
    },
    {
      field: MESSAGING_VALUES.DEFINITE_ITEM,
      headerName: 'Item definido',
      sortable: true,
      flex: 1,
      renderCell: ({ value }) => <Define value={value} />,
    },
    {
      field: MESSAGING_VALUES.DEFAULT,
      headerName: 'Default',
      sortable: true,
      width: 80,
      renderCell: ({ value }) => <Default value={value} />,
    },
  ],
}
