import * as historyLogsService from '_services/history-logs'
import { defineAction } from '_utils/redux'

export const GET_HISTORY_LOGS = defineAction('GET_HISTORY_LOGS')
export const GET_STEP_LOGS = defineAction('GET_STEP_LOGS')
export const SET_EVENTS_FILTER = defineAction('SET_EVENTS_FILTER')

export const getHistoryLogs =
  (serviceOrderId, mainEvents = undefined) =>
  async (dispatch, getState) => {
    const data = await historyLogsService.getHistoryLogs(getState().user.authToken)(
      serviceOrderId,
      mainEvents
    )
    dispatch({
      type: GET_HISTORY_LOGS.ACTION,
      payload: async () => data,
    })
    return data
  }

export const setEventsFilter = payload => dispatch =>
  dispatch({
    type: SET_EVENTS_FILTER.ACTION,
    payload,
  })
