import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

import Button from '_components/button'

import useStyles from './styles'

const DeleteBudgetItemDialog = ({ isOpen, onClose, onDeleteBudgetItemClick }) => {
  const styles = useStyles()

  return (
    <Dialog open={isOpen} onClose={onClose} aria-describedby="deleteBudgetItemDialog">
      <DialogContent>
        <DialogContentText id="deleteBudgetItemDialog" className={styles.description}>
          Você tem certeza que deseja remover o item do orçamento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Não
        </Button>
        <Button onClick={onDeleteBudgetItemClick} color="primary" variant="contained">
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteBudgetItemDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteBudgetItemClick: PropTypes.func.isRequired,
}

export default React.memo(DeleteBudgetItemDialog)
