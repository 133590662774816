export const getCompanySelector = state => state.company.get('results').toJS()
export const companySelector = state => state.company.get('company').toJS()
export const getCompaniesSelector = state => state.company.get('companiesList').toJS()
export const getCompaniesSimpleSelector = state =>
  state.company
    .get('companiesSimpleList')
    .toJS()
    .map(company => ({ label: company.name, value: company.id }))

export const getCompaniesNamesSelector = state =>
  state.company
    .get('companiesList')
    .toJS()
    ?.results?.map(company => ({ label: company.name, value: company.id }))

export const getCompanyByIdSelector = id => state => state.company.get('results').toJS()[id]

export const getContractOptionsSelector = state => state.company.get('contractOptions').toJS()
