import React, { useMemo } from 'react'

import { navigate } from '@reach/router'

import HeaderTitle from '_/components/header-title'
import { Button } from '@refera/ui-web'

import { ROUTES } from '_/utils/constants'

import useStyles from './styles'

import { FilterSearch as FilterIcon } from '@refera/ui-icons'

import Theme from '@refera/ui-core'

export function PageHeader({ isLoading, setFilterDrawerOpen }) {
  const styles = useStyles()

  const handleFilters = () => setFilterDrawerOpen(prevState => !prevState)

  const buttonColor = useMemo(() => {
    return isLoading ? Theme.Colors.Grayscale.ThirtyTwo : Theme.Colors.Primary.Base
  }, [isLoading])

  return (
    <HeaderTitle
      title="Prestadores disponíveis para importação"
      backButtonAction={() => navigate(ROUTES.MANAGE_PROVIDERS)}
    >
      <div className={styles.actionButtons}>
        <Button
          variant="ghost"
          startIcon={<FilterIcon color={buttonColor} />}
          onClick={handleFilters}
          disabled={isLoading}
        >
          Filtros
        </Button>
      </div>
    </HeaderTitle>
  )
}
