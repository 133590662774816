import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  header: {
    background: palette.primary.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(0, 20),
    height: spacing(62),
    width: '100%',
  },
  iconButton: { width: spacing(20), height: spacing(20) },
  buttons: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    width: spacing(20),
    height: spacing(20),
    '& .MuiSvgIcon-root': {
      width: spacing(20),
      height: spacing(20),
    },
    fill: 'white',
  },
  title: {
    fontSize: spacing(16),
    lineHeight: spacing(24),
    letterSpacing: spacing(-0.1),
    color: palette.gray[64],
  },

  actions: {
    '&.MuiDialogActions-root': {
      padding: spacing(0, 40, 30, 40),
      justifyContent: 'flex-end',
    },
  },
  singleAction: {
    justifyContent: 'center',
  },
  button: {
    width: spacing(88),
  },
  textButton: {
    width: spacing(88),
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    textTransform: 'uppercase',
    color: palette.gray[64],
  },
  textButtonActive: {
    marginLeft: 'auto',
    color: palette.primary.main,
    '&:hover': {
      background: 'white',
    },
  },
  editButton: {
    color: 'white',
    borderColor: 'white',
    marginRight: spacing(20),
  },
}))
