import React, { useMemo } from 'react'
import { Grid, LinearProgress, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import useIsCurrentStepStatusCanceled from '_hooks/use-is-current-step-status-canceled'
import { STAGE_TYPES } from '_utils/constants'

import useStyles from './styles'

const ProgressBar = ({ step }) => {
  const isCanceled = useIsCurrentStepStatusCanceled()

  const styles = useStyles({ step, isCanceled })

  const percentage = useMemo(() => {
    if (step === STAGE_TYPES.ORDER) {
      return 100 / 3
    }
    if (step === STAGE_TYPES.BUDGET) {
      return (100 * 2) / 3
    }
    return 100
  }, [step])

  return (
    <Grid className={styles.progress}>
      <LinearProgress className={styles.linearProgress} variant="determinate" value={percentage} />
      <Typography className={styles.firstStep}>Pedido</Typography>
      <Typography className={styles.secondStep}>Orçamento</Typography>
      <Typography className={styles.thirdStep}>Ordem de serviço</Typography>
    </Grid>
  )
}

ProgressBar.propTypes = {
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ProgressBar.defaultProps = {
  step: STAGE_TYPES.ORDER,
}

export default React.memo(ProgressBar)
