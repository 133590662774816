import React, { useCallback, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core'
import { useParams } from '@reach/router'
import { useDispatch } from 'react-redux'
import { AssignmentTurnedInOutlined } from '@material-ui/icons'

import Button from '_components/button'
import { reactivateServiceOrder } from '_modules/service-orders/actions'
import { getHistoryLogs } from '_modules/history-logs/actions'

import useStyles from './styles'

const ReactivateModal = ({ open, onClose, handleGetServiceOrderActive }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = useCallback(() => {
    setIsLoading(true)
    dispatch(reactivateServiceOrder(serviceOrderId))
      .then(() => {
        onReactivateServiceOrderSuccessful()
      })
      .finally(() => setIsLoading(false))
  }, [dispatch, serviceOrderId])

  const onReactivateServiceOrderSuccessful = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))
    onClose()
    handleGetServiceOrderActive()
  }, [dispatch, handleGetServiceOrderActive, onClose, serviceOrderId])

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Box mt={10} />
        <IconButton disableRipple className={classes.titleIconTrash}>
          <AssignmentTurnedInOutlined className={classes.iconeSVG} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>Você tem certeza que deseja reativar este chamado?</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          variant="outlined"
          color="red"
          className={classes.button}
          onClick={onClose}
          disabled={isLoading}
        >
          Não
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleConfirm}
          isLoading={isLoading}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReactivateModal
