import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  title: {
    color: palette.gray[32],
    marginBottom: spacing(6),
  },
  value: {
    color: props => (props.hasPlaceholder ? palette.gray[32] : palette.gray[64]),
  },
  wrapper: {
    minHeight: spacing(42),
  },
  select: {
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  modalLabel: {
    color: palette.gray[64],
  },
  icon: {
    height: spacing(16),
    width: spacing(16),
    marginRight: '5px',
  },
  iconBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 400,
    fontSize: spacing(14),
    color: '#1671ED',
    margin: 0,
    padding: 0,
  },
}))
