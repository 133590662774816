import React, { useState } from 'react'
import { BaseContractModal } from '_components/modal/contracts/base'
import { Box } from '@mui/material'

function ConfirmNewContractGenerationModal({ onConfirm = () => {}, onCancel = () => {} }) {
  const [isLoading, setIsLoading] = useState(false)

  function handleClickConfirm() {
    setIsLoading(true)
    onConfirm()
  }

  return (
    <BaseContractModal.Root>
      <BaseContractModal.Icon iconType="warning" sx={{ marginTop: '24px' }} />
      <Box display="flex" flexDirection="column" width="100%" marginTop="24px">
        <BaseContractModal.Header variant="variant2" sx={{ margin: '0 22px' }}>
          Tem certeza que deseja gerar um novo contrato?
        </BaseContractModal.Header>
        <BaseContractModal.Paragraph sx={{ margin: '16px 32px 0' }}>
          Caso um novo contrato seja gerado, as assinaturas já feitas serão perdidas. Essa ação não
          pode ser desfeita.
        </BaseContractModal.Paragraph>
      </Box>
      <BaseContractModal.ActionButtonContainer sx={{ margin: '24px 0 16px' }}>
        <BaseContractModal.ActionButton
          variant="secondary"
          sx={{ width: '165px' }}
          color="red"
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancelar
        </BaseContractModal.ActionButton>
        <BaseContractModal.ActionButton
          variant="primary"
          onClick={() => handleClickConfirm()}
          sx={{ width: '165px' }}
          disabled={isLoading}
        >
          Confirmar
        </BaseContractModal.ActionButton>
      </BaseContractModal.ActionButtonContainer>
    </BaseContractModal.Root>
  )
}

export default ConfirmNewContractGenerationModal
