import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  statusContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(4, 8),
    borderRadius: '16px',
    backgroundColor: Theme.Colors.Grayscale.Four,
    color: Theme.Colors.Grayscale.SixtyFour,
    fontSize: Theme.Typography.FontSize.XXSmall,
  },
  statusDot: {
    marginRight: spacing(8),
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: Theme.Colors.Yellow.Base,
  },
  statusDotResolved: {
    backgroundColor: Theme.Colors.Green.Base,
  },
  statusResolved: {
    color: Theme.Colors.Green.Base,
  },
  wasntViewed: {
    fontWeight: 500,
  },
}))
