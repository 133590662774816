import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: palette.primary.secondary,
    paddingTop: spacing(50),
  },
  alert: {
    marginBottom: spacing(40),
  },
  header: {
    position: 'absolute',
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    maxWidth: spacing(800),
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    flexDirection: 'column',
    alignContent: 'center',
    padding: spacing(20, 10, 20, 25),
  },
  form: {
    width: '100%',
    maxWidth: spacing(368),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  div: {
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    letterSpacing: spacing(-0.3),
    color: palette.primary.main,
    marginBottom: spacing(32),
    fontSize: spacing(32),

    [breakpoints.down('xs')]: {
      fontSize: spacing(24),
    },
  },
  pic: {
    display: 'flex',
    justifyContent: 'center',
  },
  fileIcon: {
    width: spacing(80),
    height: spacing(80),
    marginBottom: spacing(10),

    [breakpoints.down('xs')]: {
      width: spacing(65),
      height: spacing(65),
    },
  },
  diagnosis: {
    color: palette.text.primary,
    fontWeigth: '500',
    marginBottom: spacing(20),
  },
  diagnosisText: {
    maxWidth: spacing(921),
    flex: 1,
    width: '100%',
    color: palette.gray[64],
    fontWeigth: '400',
    padding: spacing(10),
    border: '1px solid #E3E3E3',
    boxSizing: 'border-box',
    borderRadius: '4px',
    whiteSpace: 'pre-line',
  },
  solution: {
    color: palette.text.primary,
    fontWeigth: '500',
    marginBottom: spacing(20),
    marginTop: spacing(20),
  },
  tableTitle: {
    color: palette.text.primary,
    fontWeigth: '500',
    marginBottom: spacing(20),
    marginTop: spacing(20),
  },
  tableList: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    justifyContent: 'space-between',
    width: '100%',
    gap: spacing(10),
  },
  leftList: {
    width: '100%',
  },
  listHeaderDescription: {
    color: palette.gray[80],
    paddingBottom: spacing(10),
  },
  leftListItems: {
    height: spacing(30),
    border: '1px solid #E3E3E3',
    borderRadius: spacing(4),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(10),
    fontSize: spacing(14),
    marginBottom: spacing(5),
    fontFamily: 'Roboto',
  },
  rightList: {
    width: '100%',
  },
  listHeaderPrice: {
    color: palette.gray[80],
    paddingBottom: spacing(10),
  },
  rightListItems: {
    height: spacing(30),
    border: '1px solid #E3E3E3',
    borderRadius: spacing(4),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(10),
    marginBottom: spacing(5),
    fontSize: spacing(14),
    fontFamily: 'Roboto',
  },
  totalPrice: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(20),
    marginBottom: spacing(20),
  },
  totalPriceText: {
    fontWeight: '500',
    fontSize: spacing(16),
  },
  totalPriceValue: {
    marginLeft: spacing(17),
    fontWeight: '500',
    fontSize: spacing(23),
    color: palette.primary.main,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: palette.text.primary,
  },
  selectInput: {
    display: 'flex',
    '&:hover': {
      cursor: props => props.cannotEditBudget && 'not-allowed',
    },
    '& :after': {
      content: '" *"',
      color: 'red',
    },
  },
  selectLabel: {
    color: palette.text.primary,
    fontWeight: 'bold',
  },
  guarantee: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(10),
    margin: spacing(30, 0),
  },
  guaranteeText: {
    color: palette.text.primary,
    fontWeight: 'bold',
  },
  employees: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(10),
  },
  employeesText: {
    marginLeft: spacing(10),
    color: palette.text.primary,
    fontWeigth: '500',
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(10),
  },
  durationText: {
    marginLeft: spacing(10),
    color: palette.text.primary,
    fontWeigth: '500',
  },
  mediaSection: {
    marginTop: spacing(30),
    width: '100%',
  },
  mediaWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '15px',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '20px',
    justifyContent: 'flex-start',
  },
  attachment: {
    [breakpoints.down('xs')]: {
      minWidth: '100% !important',
    },
  },
  mediaTitle: {
    color: palette.text.primary,
  },
  paymentTitle: {
    color: palette.text.primary,
    marginTop: spacing(36),
  },
  paymentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing(20),
    margin: spacing(20, 0, 48),

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: spacing(30),
    },
  },
  helpText: {
    display: 'flex',
    fleWrap: 'wrap',
    fontSize: spacing(12),
    color: palette.gray[64],
    marginTop: spacing(10),
  },
  budgetImage: {
    width: spacing(80),
    height: spacing(80),
    marginRight: spacing(10),
    borderRadius: spacing(4),
  },
  buttons: {
    marginTop: spacing(20),
    display: 'flex',
    gap: spacing(20),

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  button: {
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  footer: {
    left: '0',
    bottom: '0',
    width: '100%',
    marginTop: spacing(50),
  },
}))
