import React, { useCallback, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { Button, Datagrid, Input, Loader } from '@refera/ui-web'
import HeaderTitle from '_/components/header-title'
import useStyles from '../styles'
import { JustSelectedReceiptsCsReferaColumns } from '_/views/finance/manage-installments/utils/constants'
import { useDispatch } from 'react-redux'
import * as Modal from '_components/modal/generic-modal'
import { updateVoucherReceivables } from '_/modules/finance/actions'
import { useToast } from '_hooks/use-toast'
import { formatErrorMessage } from '../../../utils/FormatErrorMessage'
import { TABLE_SX } from '_/views/finance/utils/constants'

export function ConfirmVoucher({ menuType, items, handleGetReceivables, handleGoBackAndUpdate }) {
  const styles = useStyles()
  const [selectedIds, setSelectedIds] = useState(items?.map(item => item.id) || [])
  const [voucherNumber, setVoucherNumber] = useState('')
  const [voucherFull, setVoucherFull] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const dispatch = useDispatch()

  const { showToast } = useToast()

  const renderTitle = useMemo(
    () => `Confirmação de ${menuType === 'remove-voucher' ? 'exclusão' : 'inclusão'} de boletos`,
    [menuType]
  )

  const handleUpdateVoucher = useCallback(() => {
    setSelectedIds(currentSelectedIds => {
      const payload = {
        budgetReceivablesIds: currentSelectedIds?.filter(id => currentSelectedIds.includes(id)),
      }
      let error = false

      if (menuType === 'confirm-voucher') {
        payload.voucher = voucherNumber.replace(/[^\d]/g, '')
        payload.voucherFull = voucherFull.replace(/[^\d]/g, '')

        if (payload.voucher.length > 9) {
          error = { voucher: 'O número do boleto deve ter no máximo 9 caracteres' }
        }
        if (payload.voucherFull.length > 47) {
          error = { voucherFull: 'O número do boleto deve ter no máximo 47 caracteres' }
        }

        if (error) {
          setErrors(error)
          return currentSelectedIds
        }
        setErrors({})

        payload.voucher = parseInt(payload.voucher, 10)
        payload.type = 'confirm'
      } else {
        payload.type = 'remove'
      }

      setLoading(true)

      dispatch(updateVoucherReceivables(payload))
        .then(() => {
          setLoading(false)
          showToast({ type: 'success' })
          setOpenModal(false)
          handleGoBackAndUpdate({ update: true })
        })
        .catch(err => {
          setLoading(false)
          showToast({ message: formatErrorMessage(err), type: 'error' })
        })

      return currentSelectedIds
    })

    return null
  }, [selectedIds, dispatch, voucherNumber, voucherFull, handleGetReceivables])

  const handleConfirmAction = () => {
    menuType === 'confirm-voucher' ? setOpenModal(true) : handleUpdateVoucher()
  }

  return (
    <>
      <Loader hasBackdrop open={loading} label="Aguarde..." />
      <HeaderTitle backButtonAction={handleGoBackAndUpdate} title={renderTitle}>
        <>
          <Grid style={{ display: 'flex', gap: '8px' }}>
            <Button color="red" variant="secondary" onClick={handleGoBackAndUpdate}>
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="primary"
              onClick={handleConfirmAction}
              disabled={selectedIds.length === 0 || loading}
            >
              Confirmar
            </Button>
          </Grid>
        </>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            rows={items.length ? items : []}
            columns={JustSelectedReceiptsCsReferaColumns({ styles })}
            paginationMode="server"
            autoHeight
            columnVisibilityModel={{ actions: false }}
            rowCount={items?.length}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={setSelectedIds}
            keepNonExistentRowsSelected
            selectionModel={selectedIds}
            sx={TABLE_SX}
          />
        </Grid>
      </Grid>

      <Modal.Root open={openModal} onClose={() => setOpenModal(false)}>
        <Modal.TitleModal title="Número do boleto" />
        <Modal.Content>
          <Input
            name="voucher"
            label="Número do boleto:"
            value={voucherNumber}
            placeholder="12345"
            style={{ fontSize: '16px' }}
            onChange={({ target }) => setVoucherNumber(target.value)}
            fullWidth
            inputProps={{ type: 'number' }}
            error={Boolean(errors?.voucher)}
            errorMessage={errors?.voucher}
          />
          <Input
            name="voucherFull"
            label="Boleto completo:"
            value={voucherFull}
            placeholder="012345678910"
            style={{ fontSize: '16px' }}
            onChange={({ target }) => setVoucherFull(target.value)}
            fullWidth
            inputProps={{ type: 'number' }}
            error={Boolean(errors?.voucherFull)}
            errorMessage={errors?.voucherFull}
          />
        </Modal.Content>
        <Modal.Actions>
          <Modal.ButtonRed onClick={() => setOpenModal(false)}>Cancelar</Modal.ButtonRed>
          <Modal.ButtonFullBlue disabled={!voucherNumber} onClick={handleUpdateVoucher}>
            Salvar
          </Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>
    </>
  )
}
