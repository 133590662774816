/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormHelperText, TextField } from '@mui/material'
import { FormControl } from '@material-ui/core'

import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import autocompleteStyles from './styles'
import generalInputStyles from '../styles'

const AutocompleteComponent = ({ onChange, customOnChange, ...props }) => {
  const localStyles = autocompleteStyles()

  return (
    <MuiAutocomplete
      multiple
      {...props}
      id={props?.name}
      options={props?.loading || !props?.options ? [] : props.options}
      loading={props?.loading ?? false}
      loadingText="Carregando..."
      className={classNames(
        localStyles.contentState,
        props?.disabled ? localStyles.autoCompleteDisabled : localStyles.autoCompletePointer,
        props?.autocompleteClasses ?? null
      )}
      onChange={(_, data, reason) => {
        if (customOnChange) customOnChange(_, data, reason)
        onChange(data)
      }}
      style={{
        cursor: props?.disabled ? 'not-allowed' : 'pointer',
      }}
      classes={{
        tag: localStyles.customTag,
      }}
      disabled={props?.disabled ?? false}
      getOptionLabel={props?.getOptionLabel ?? (option => option)}
      noOptionsText="Registro não encontrado"
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          label={props?.label}
          disabled={props?.disabled ?? false}
          InputProps={{
            ...params.InputProps,
            style: {
              fontSize: 16,
              minHeight: 39,
            },
          }}
          InputLabelProps={{
            shrink: true,
            className: classNames(
              props?.required ? localStyles.required : null,
              props?.error ? localStyles.labelError : null,
              localStyles.label
            ),
          }}
        />
      )}
    />
  )
}

/**
 * Autocomplete component that uses Material-UI Autocomplete and React Hook Form Controller.
 * @param {Object} props - The props object.
 * @param {Object[]} props.options - The options to be displayed in the autocomplete.
 * @param {Function} props.getOptionLabel - The function that returns the label for each option.
 * @param {string} props.name - The name of the input.
 * @param {string} props.label - The label of the input.
 * @param {boolean} [props.loading=false] - Whether the options are currently being loaded.
 * @param {boolean} [props.required=false] - Whether the input is required.
 * @param {boolean} [props.disabled=false] - Whether the input is disabled.
 * @param {Function} [props.customOnChange] - Custom onChange function.
 * @param {Object} [props.style={}] - The style object to be applied to the component.
 * @param {string} [props.containerClasses] - The classes to be applied to the container element.
 * @param {string} [props.autocompleteClasses] - The classes to be applied to the Autocomplete component.
 * @param {string} [props.defaultValue=''] - The default value of the input.
 * @returns {JSX.Element} - The Autocomplete component.
 */
function Autocomplete({
  options,
  getOptionLabel,
  name,
  customOnChange,
  label,
  loading = false,
  required = false,
  disabled = false,
  multiple = true,
  style = {},
  containerClasses,
  autocompleteClasses,
  defaultValue,
}) {
  const { control, errors, setValue } = useFormContext()
  const generalStyles = generalInputStyles()

  useEffect(() => {
    if (multiple && !Array.isArray(defaultValue)) {
      setValue(name, [])
    } else {
      setValue(name, defaultValue)
    }
  }, [defaultValue, name])

  return (
    <FormControl
      style={{ ...style }}
      className={classNames(generalStyles.formControl, containerClasses ?? null)}
    >
      <Controller
        as={AutocompleteComponent}
        name={name}
        control={control}
        defaultValue={defaultValue ?? (multiple ? [] : null)}
        options={options}
        required={required}
        disabled={disabled}
        label={label}
        autocompleteClasses={autocompleteClasses}
        getOptionLabel={getOptionLabel}
        loading={loading}
        rules={{
          validate: value => {
            if (!disabled && required && (multiple ? !value?.length : !value))
              return 'Campo obrigatório'
            return true
          },
        }}
        error={!!errors[name]}
        multiple={multiple}
        customOnChange={customOnChange}
      />
      <FormHelperText className={generalStyles.errorMessage} error={!!errors[name]}>
        {errors[name]?.message}
      </FormHelperText>
    </FormControl>
  )
}

Autocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  containerClasses: PropTypes.string,
  autocompleteClasses: PropTypes.string,
}

Autocomplete.defaultProps = {
  loading: false,
  required: false,
  disabled: false,
  style: {},
  containerClasses: null,
  autocompleteClasses: null,
}

export default Autocomplete
