import { defineAction } from '_utils/redux'
import * as businessTimeServices from '_services/businessTimes'

export const GET_BUSINESSTIME = defineAction('GET_BUSINESSTIME')

export const getBusinessTimes = () => (dispatch, getState) => {
  dispatch({
    type: GET_BUSINESSTIME.ACTION,
    payload: businessTimeServices.getBusinessTimes(getState().user.authToken),
  })
}
