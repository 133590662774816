import { get } from './requests'

export const getServiceOrdersCountWidgets = key => params =>
  get(['widgets', 'service-orders-quantity'], { key, transformPayload: true, params })

export const getWidgetsOptions = key => () =>
  get(['/new-widgets/'], { key, transformPayload: true })

export const getWidgetResults =
  key =>
  ({ optionId, params }) =>
    get(['/new-widgets', optionId], { key, transformPayload: true, params })
