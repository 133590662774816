import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  tableContainer: {
    flex: 1,
    margin: spacing(20, 71),
  },
  buttonHeader: {
    display: 'flex',
    gap: spacing(10),
    alignContent: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: spacing(75),
    width: '100%',
    padding: '0 3.4rem',
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  mainTable: {
    width: 'auto',
    height: 630,
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: `${Theme.Typography.FontSize.Large}px`,
    lineHeight: '112.5%',
    color: Theme.Colors.Grayscale.OneThousand,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
}))
