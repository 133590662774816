import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    padding: spacing(0, 16),
    position: 'relative',
    minHeight: '90vh',
    '&.MuiTableContainer-root': {
      overflowX: 'unset',
    },
  },
  cell: {
    color: palette.gray[80],
    textAlign: 'left',
    fontSize: spacing(14),
    lineHeight: spacing(20),
    borderColor: palette.gray[12],
    maxWidth: spacing(150),
    padding: spacing(12),
    cursor: 'pointer',
  },
  tableRow: {
    '&:first-child': {
      paddingLeft: '0',
    },
    '&:last-child': {
      paddingRight: '0',
    },
  },
  cellHead: {
    textAlign: 'left',
    color: palette.gray[64],
    fontWeight: 'normal',
    fontSize: spacing(12),
    lineHeight: spacing(16),
    borderColor: palette.gray[12],
    padding: spacing(12),
  },
  pagination: {
    marginTop: spacing(12),
    '&.MuiTablePagination-root': {
      overflow: 'unset',
    },
  },
  main: {
    marginTop: spacing(40),
  },
  filterIcon: {
    padding: 0,
    marginLeft: spacing(14),
  },
  filter: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))
