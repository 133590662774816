import React, { useCallback } from 'react'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
  Grid,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import Button from '_components/button'
import Svg, { ICON } from '_components/svg'

import useStyles from './styles'

const Filter = ({
  handleModal,
  value,
  options,
  handleOption,
  search,
  handleSearch,
  handleApplyFilter,
  filter,
  placeholder,
}) => {
  const styles = useStyles()

  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <Svg type={ICON.BLOOM} />
      </InputAdornment>
    ),
  }

  const handleRadioButton = useCallback(
    event => {
      handleOption(event)
    },
    [handleOption]
  )
  const handleCancel = useCallback(() => {
    handleOption({ target: { value: '', name: value } })
    handleModal()
  }, [handleModal, handleOption, value])

  return (
    <Grid className={styles.modal}>
      {search && (
        <TextField
          className={styles.search}
          fullWidth
          placeholder={`Digite o nome ${placeholder}`}
          onChange={handleSearch}
          InputProps={inputProps}
        />
      )}
      <RadioGroup className={styles.radioGroup} value={value}>
        {options.map(item => (
          <FormControlLabel
            onChange={handleRadioButton}
            name={value}
            className={styles.label}
            key={item.value}
            value={item.value}
            control={<Radio color="primary" />}
            label={item.label}
            checked={filter && filter.some(itemSelected => itemSelected === item.value.toString())}
          />
        ))}
      </RadioGroup>
      <Grid className={styles.buttons}>
        <Button
          onClick={handleCancel}
          className={styles.button}
          variant="outlined"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleApplyFilter}
          className={styles.button}
          variant="contained"
          color="primary"
        >
          Ok
        </Button>
      </Grid>
    </Grid>
  )
}

Filter.propTypes = {
  handleModal: PropTypes.func.isRequired,
  handleOption: PropTypes.func.isRequired,
  handleApplyFilter: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.string.isRequired,
  search: PropTypes.bool,
  handleSearch: PropTypes.func,
  placeholder: PropTypes.string,
  filter: PropTypes.shape().isRequired,
}

Filter.defaultProps = {
  search: false,
  handleSearch: () => {},
  placeholder: '',
}

export default React.memo(Filter)
