import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100vh',
    width: '100%',

    [breakpoints.down('sm')]: {
      padding: spacing(0, 20),
    },
  },
  titulo: {
    color: '#1671ED',
    fontSize: '32px',
    fontFamily: 'DM Sans',
    marginBottom: spacing(16),
  },
  titulo_footer: {
    fontSize: '12px',
    fontFamily: 'DM Sans',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: spacing(14),
    maxWidth: spacing(480),
    textAlign: 'center',
    marginBottom: spacing(24),
  },
  button: {
    marginBottom: `${spacing(24)} !important`,
  },
  icon: {
    width: spacing(80),
    height: spacing(80),
    marginBottom: spacing(16),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    paddingBottom: spacing(20),
    display: 'flex',
    alignItems: 'center',
  },
}))
