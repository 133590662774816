import { post, get, patch, del } from './requests'
import humps from 'humps'
import { notEmptyPayload } from '_/utils/helpers'

export const createReferaUser = key => payload =>
  post(['refera-user'], { key, transformPayload: true }, payload)

export const getReferaUserDetails = key => userId => get(['refera-user', userId], { key })

export const listReferaUsers = key => params =>
  get(['refera-user'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(params)),
  })

export const updateReferaUser = key => (userId, payload) =>
  patch(['refera-user', userId], { key, transformPayload: true }, payload)

export const deleteReferaUser = key => userId => del(['refera-user', userId], { key })

export const activateReferaUserEmail = key => userId =>
  post(['refera-user', userId, 'activate-refera-user-email'], { key })

export const resendPassword = key => userId =>
  patch(['agency-user', userId, 'resend-password'], { key })
