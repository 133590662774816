/* eslint-disable radix */
import React, { useState, useEffect } from 'react'

import { SwipeableDrawer, Typography, Divider, Grid, Slide } from '@material-ui/core'

import { FilterSearch as FilterIcon, CloseCircle } from '@refera/ui-icons'

import Theme from '@refera/ui-core'
import { Input, Button, DatePicker, MoneyInput } from '@refera/ui-web'

import useStyles from './styles'
import { useToast } from '_/hooks/use-toast'
import Select from '_/components/common/select'
import { formatServiceOrderFilter } from '_/views/finance/manage-installments/utils/functions'
import {
  // ACCOUNT_TYPE_OPTIONS,
  DEFAULT_SELECT_FILTER_OPTIONS,
  DEFAULT_SELECT_FILTER_OPTIONS_WITH_NULL,
} from '_/views/finance/utils/constants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

const statusOptions = [
  { value: '', label: '(Todos)' },
  { value: 'approved', label: 'Aprovado' },
  { value: 'in_payment', label: 'Em pagamento' },
  { value: 'paid', label: 'Pago' },
  { value: 'pending', label: 'Pendente' },
  { value: 'reproved', label: 'Reprovado' },
]

const PaymentsFilter = ({
  handleFilterDrawer,
  setFilters,
  filters,
  getAnticipations,
  filterFields,
  open,
}) => {
  const styles = useStyles()
  const [serviceOrderId, setServiceOrderId] = useState(filters?.serviceOrderId || null)
  const [company, setCompany] = useState(filters?.company || null)
  const [startPriceServiceProvider, setStartPriceServiceProvider] = useState('')
  const [endPriceServiceProvider, setEndPriceServiceProvider] = useState('')
  const [status, setStatus] = useState(filters?.status || '')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startPaymentDate, setStartPaymentDate] = useState(null)
  const [endPaymentDate, setEndPaymentDate] = useState(null)
  const [ableToPay, setAbleToPay] = useState(filters?.ableToPay ?? '')
  const [hasIrrf, setHasIrrf] = useState(filters?.hasIrrf ?? '')
  const [blocked, setBlocked] = useState(filters?.blocked ?? '')
  // const [accountType, setAccountType] = useState(filters?.accountType ?? '')
  const [apply, setApply] = useState(false)

  const { showToast } = useToast()

  const cleanFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setStartPaymentDate(null)
    setEndPaymentDate(null)
    setServiceOrderId('')
    setCompany('')
    setHasIrrf('')
    setBlocked('')
    // setAccountType('')
    setStartPriceServiceProvider('')
    setEndPriceServiceProvider('')
    setStatus('')
    setAbleToPay('')
    setFilters(filterFields)
    setApply(true)
  }

  const formatDates = (start, end) =>
    `${start?.startOf('day')?.toISOString() || ''},${end?.endOf('day')?.toISOString() || ''}`

  const formatPriceServiceProvider = () => {
    return startPriceServiceProvider || endPriceServiceProvider
      ? `${startPriceServiceProvider || ''},${endPriceServiceProvider || ''}`
      : ''
  }

  const handleServiceOrderFilter = () => {
    const formattedObj = formatServiceOrderFilter(serviceOrderId)

    if (formattedObj?.message) {
      showToast({ ...formattedObj })
      return null
    }

    setServiceOrderId(formattedObj?.formattedServiceOrder)
    return formattedObj?.formattedServiceOrder
  }

  const handleApplyFilters = () => {
    let serviceOrderIds = serviceOrderId

    if (serviceOrderIds?.trim()) {
      serviceOrderIds = handleServiceOrderFilter()
      if (!serviceOrderIds) return
    }

    setFilters(old => ({
      ...old,
      serviceOrderId: serviceOrderIds,
      company,
      providerValueTotal: formatPriceServiceProvider(),
      status,
      hasIrrf,
      blocked,
      // accountType,
      ableToPay,
    }))
    setApply(true)
  }

  const handleDates = () => {
    if (startDate || endDate)
      setFilters(old => ({ ...old, createdAt: formatDates(startDate, endDate) }))

    if (startPaymentDate || endPaymentDate)
      setFilters(old => ({ ...old, paymentDate: formatDates(startPaymentDate, endPaymentDate) }))
  }

  useEffect(() => {
    if ((Object.values(filters).some(value => value) && apply) || apply) {
      getAnticipations({ page: 1 }).catch(() => {})
      setApply(false)
    }
  }, [filters, apply])

  useEffect(() => {
    handleDates()
  }, [startDate, endDate, startPaymentDate, endPaymentDate])

  return (
    <>
      <SwipeableDrawer
        component="section"
        anchor="right"
        open={open}
        onClose={handleFilterDrawer}
        className={styles.container}
        TransitionComponent={Transition}
      >
        <Grid className={styles.header}>
          <Grid className={styles.headerTitle}>
            <Grid className={styles.iconWrapper}>
              <FilterIcon color={Theme.Colors.Primary.Base} />
            </Grid>
            <Typography component="h1" variant="h5" className={styles.title}>
              Filtros
            </Typography>
          </Grid>
          <Button onClick={handleFilterDrawer} variant="ghost">
            <CloseCircle color={Theme.Colors.Primary.Base} className={styles.closeIcon} />
          </Button>
        </Grid>
        <Divider className={styles.divider} />
        <Grid className={styles.content}>
          <Grid className={styles.inputWrapper}>
            <Input
              key="id_chamado"
              label="ID do Chamado"
              value={serviceOrderId}
              placeholder="12345"
              style={{ fontSize: '16px' }}
              onChange={e => setServiceOrderId(e.target.value)}
              autoFocus
              fullWidth
            />
            <Input
              key="empresa"
              label="Nome da empresa do prestador."
              value={company}
              placeholder="Nome da Empresa"
              style={{ fontSize: '16px' }}
              onChange={e => setCompany(e.target.value)}
              fullWidth
            />
            <Grid className={styles.inputsWrapper}>
              <DatePicker
                key="data_de"
                label="Data da solicitação"
                value={startDate}
                onChange={e => setStartDate(e)}
                className={styles.doubleInput}
                refuse={/[^\d\\.]+/gi}
                variant="inline"
                format="dd/MM/yyyy"
                placeholder="DD/MM/AAAA"
                invalidDateMessage="Insira uma data válida"
                disableFuture
              />
              <Typography className={styles.dotDivision}>•</Typography>
              <DatePicker
                key="data_ate"
                label=" "
                value={endDate}
                onChange={e => setEndDate(e)}
                className={styles.doubleInput}
                refuse={/[^\d\\.]+/gi}
                variant="inline"
                format="dd/MM/yyyy"
                placeholder="DD/MM/AAAA"
                invalidDateMessage="Insira uma data válida"
                minDate={startDate || undefined}
                disableFuture
              />
            </Grid>
            <Grid className={styles.inputsWrapper}>
              <DatePicker
                key="data_pagamento_de"
                label="Pagamento entre"
                value={startPaymentDate}
                onChange={e => setStartPaymentDate(e)}
                className={styles.doubleInput}
                refuse={/[^\d\\.]+/gi}
                variant="inline"
                format="dd/MM/yyyy"
                placeholder="DD/MM/AAAA"
                invalidDateMessage="Insira uma data válida"
              />
              <Typography className={styles.dotDivision}>•</Typography>
              <DatePicker
                key="data_pagamento_ate"
                label=" "
                value={endPaymentDate}
                onChange={e => setEndPaymentDate(e)}
                className={styles.doubleInput}
                refuse={/[^\d\\.]+/gi}
                variant="inline"
                format="dd/MM/yyyy"
                placeholder="DD/MM/AAAA"
                invalidDateMessage="Insira uma data válida"
                minDate={startPaymentDate || undefined}
              />
            </Grid>
            {/* <Select
              label="Tipo de conta"
              defaultValue=""
              value={accountType}
              name="accountType"
              onChange={e => setAccountType(e.target.value)}
              options={ACCOUNT_TYPE_OPTIONS}
            /> */}
            <Grid className={styles.inputsWrapper}>
              <MoneyInput
                key="valor-min"
                label="Valor"
                value={startPriceServiceProvider || ''}
                style={{ fontSize: '16px' }}
                placeholder="R$ "
                onChange={e => setStartPriceServiceProvider(e.floatValue)}
                className={styles.doubleInput}
                fullWidth
              />
              <Typography className={styles.dotDivision}>•</Typography>
              <MoneyInput
                key="valor-max"
                label=" "
                value={endPriceServiceProvider || ''}
                style={{ fontSize: '16px', marginTop: '12px' }}
                className={styles.doubleInput}
                placeholder="R$ "
                onChange={e => setEndPriceServiceProvider(e.floatValue)}
                fullWidth
              />
            </Grid>
            <Select
              label="Status"
              defaultValue=""
              value={status}
              name="status"
              onChange={e => setStatus(e.target.value)}
              options={statusOptions}
            />
            <Select
              label="Atualmente apto a pagar"
              defaultValue=""
              value={ableToPay}
              name="ableToPay"
              onChange={e => setAbleToPay(e.target.value)}
              options={DEFAULT_SELECT_FILTER_OPTIONS}
            />
            <Select
              label="Bloqueadas"
              defaultValue=""
              value={blocked}
              name="blocked"
              onChange={e => setBlocked(e.target.value)}
              options={DEFAULT_SELECT_FILTER_OPTIONS_WITH_NULL}
            />
            <Select
              label="Com IRRF"
              defaultValue=""
              value={hasIrrf}
              name="hasIrrf"
              onChange={e => setHasIrrf(e.target.value)}
              options={DEFAULT_SELECT_FILTER_OPTIONS}
            />
          </Grid>
          <Grid className={styles.buttonsContainer}>
            <Button variant="ghost" color="primary" onClick={cleanFilters}>
              Limpar filtros
            </Button>
            <Button variant="primary" color="orange" onClick={handleApplyFilters}>
              Aplicar filtro
            </Button>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  )
}

export default PaymentsFilter
