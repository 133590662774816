import React, { useCallback, useMemo, useState } from 'react'
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core'
import { DatePicker, Loader } from '@refera/ui-web'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import DialogModal from '_components/modal/modal-dialog'
import Button from '_components/button'

import { getPaymentAnticipationSelector } from '_modules/service-orders/selectors'

import useStyles from './styles'
import { createAnticipationsTradesman } from '_/modules/finance/actions'
import { formatCurrency } from '_/utils/helpers'
import moment from 'moment'
import { useDialog } from '_/hooks/use-dialog'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const PaymentAnticipationForm = ({ closeModal }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [modalDialog, setModalDialog] = useState()
  const [requestPaymentDate, setRequestPaymentDate] = useState(moment())
  const [loading, setLoading] = useState(false)
  const paymentAnticipationData = useSelector(getPaymentAnticipationSelector)
  const { showDialog, closeDialog } = useDialog()

  const isSubmitDisabled = useMemo(
    () => !requestPaymentDate || !requestPaymentDate.isValid() || loading,
    [requestPaymentDate, loading]
  )

  const handleSubmit = useCallback(() => {
    const payload = {
      budgetId: paymentAnticipationData?.budget,
      requestPaymentDate: requestPaymentDate.format('YYYY-MM-DD'),
    }
    setLoading(true)
    dispatch(createAnticipationsTradesman(payload))
      .then(() => {
        setLoading(false)
        closeModal({ success: true })
      })
      .catch(err => {
        const shouldKeepDialogOpen = err?.keep_dialog_open || false
        setLoading(false)
        showDialog({
          type: 'error',
          subject: 'Erro ao solicitar antecipação',
          description: formatErrorMessage(err),
          onApprove: shouldKeepDialogOpen
            ? closeDialog
            : () => {
                closeDialog()
                closeModal({ success: false })
              },
          labelApprove: 'Ok',
        })
      })
  }, [paymentAnticipationData, requestPaymentDate])

  return (
    <>
      <Loader hasBackdrop open={loading} />

      {modalDialog ? (
        <DialogModal modalDialog={modalDialog} setModalDialog={setModalDialog} />
      ) : (
        <Dialog
          open
          maxWidth="xl"
          disableEscapeKeyDown
          onClose={closeModal}
          PaperComponent={PaperComponent}
          TransitionComponent={Transition}
          className={styles.dialog}
        >
          <DialogTitle className={styles.titleBlue} id="draggable-dialog-title">
            Novo - Antecipação de pagamento
          </DialogTitle>
          <DialogContent className={styles.container}>
            <Typography className={styles.textlabel}>
              Confirme as informações e clique em “Salvar” para criar o pedido de antecipação em
              nome do prestador.
            </Typography>
            <Grid container spacing={2} className={styles.wrapper}>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>Solicitante</Typography>
                </Grid>
                <Typography className={styles.label}>{paymentAnticipationData?.name}</Typography>
              </Grid>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>E-mail da empresa</Typography>
                </Grid>
                <Typography className={styles.label}>{paymentAnticipationData?.email}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={styles.wrapper}>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>Valor do prestador no orçamento</Typography>
                </Grid>
                <Typography className={styles.label}>
                  {formatCurrency(paymentAnticipationData?.price)}
                </Typography>
              </Grid>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>Data da solicitação</Typography>
                </Grid>
                <DatePicker
                  variant="inline"
                  mask="__/__/____"
                  format="dd/MM/yyyy"
                  invalidDateMessage="Insira uma data válida"
                  refuse={/[^\d.]+/gi}
                  onChange={setRequestPaymentDate}
                  value={requestPaymentDate}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={styles.dialogAction}>
            <Button className={styles.button} onClick={closeModal} color="red" variant="outlined">
              Cancelar
            </Button>
            <Button
              className={styles.button}
              form="justifiedForm"
              type="submit"
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              isLoading={loading}
              disabled={isSubmitDisabled}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

PaymentAnticipationForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default PaymentAnticipationForm
