import JsZip from 'jszip'
import FileSaver from 'file-saver'

/**
 * Get file format from url passed by param
 * @param url File link
 * @returns Extension of the file
 */
const getFileFormat = url => {
  const fileFormats = [
    '.jpeg',
    '.png',
    '.jpg',
    '.bmp',
    '.webp',
    '.mkv',
    '.mp4',
    '.mov',
    '.pdf',
    '.doc',
    '.docx',
  ]
  const { pathname } = new URL(url)
  return fileFormats.find(_ => pathname.endsWith(_))
}

const download = async url => {
  const response = await fetch(url, {
    mode: 'cors',
    cache: 'default',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Cache-Control': 'no-cache',
    },
  })
  const blob = await response.blob()
  return {
    format: getFileFormat(url),
    blob,
  }
}

/**
 * Download many files per group
 * @param urls Array of files url from server
 * @param filesPerGroup concurrency limit of download files
 * @returns FileObject array containing file format and file content (blob)
 */
/* eslint-disable no-await-in-loop */
const downloadByGroup = async (urls, filesPerGroup = 5) => {
  // separate urls in groups
  const urlGroups = []
  for (let i = 0; i < urls.length; i += filesPerGroup) {
    urlGroups.push(urls.slice(i, i + filesPerGroup))
  }

  // download files by group
  const files = []

  for (const group of urlGroups) {
    try {
      const groupFiles = await Promise.all(group.map(download))
      files.push(...groupFiles)
    } catch (err) {
      console.error(err)
    }
  }

  return files
}
/* eslint-enable no-await-in-loop */

/**
 * Generate folder and throw back the zip file to client
 * @param files Files with blob and format to save on zip archive
 */
const exportZip = async files => {
  const zip = new JsZip()

  files.forEach((file, i) => {
    const { format, blob } = file
    zip.file(`anexo-refera-${i}${format}`, blob)
  })

  const zipFile = await zip.generateAsync({ type: 'blob' })
  const currentDate = new Date().getTime()
  const fileName = `Anexos_Refera-${currentDate}.zip`
  return FileSaver.saveAs(zipFile, fileName)
}

const downloadAndZip = async urls => {
  const files = await downloadByGroup(
    urls.map(_ => _.file),
    5
  )
  const filteredFiles = files.filter(file => file !== null)

  if (filteredFiles.length === 0) {
    console.error('Nenhum arquivo para baixar')
  }
  await exportZip(filteredFiles)
}

export default downloadAndZip
