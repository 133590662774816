/* eslint-disable no-param-reassign */
import { notEmptyPayload } from '_/utils/helpers'
import { get, patch } from './requests'
import humps from 'humps'

const decamelizeParams = params => {
  Object.values(params)?.forEach(param => {
    if (typeof param === 'string') {
      const replaceKey = Object.keys(params).find(paramKey => params[paramKey] === param)
      params[replaceKey] = humps.decamelize(param)
    }
  })
  return params
}

export const getGroupById = key => id => {
  return get(['permissions-group', id], {
    key,
    transformPayload: true,
  })
}

// Permissions

export const getGroupPermission = key => (id, params) => {
  const payloadParams = decamelizeParams(params)

  return get(['permissions-group', id, 'permissions'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(payloadParams)),
  })
}

export const patchGroupPermission = key => payload => {
  return patch(
    ['permissions-group', 'update-crud-permissions'],
    {
      key,
    },
    payload
  )
}
