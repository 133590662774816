import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(() => ({
  inputLabel: {
    color: `${Theme.Colors.Grayscale.OneThousand} !important`,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: `${Theme.Typography.FontSize.Tiny}px !important`,
  },
  selectLabel: {
    marginBottom: '4px',
  },
  selectContainer: {
    margin: '18px 0 -18px',
  },
}))
