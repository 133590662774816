import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Modal } from '@refera/ui-web'
import { Typography } from '@material-ui/core'
import { Divider, Grid } from '@mui/material'
import { Danger as DangerIcon } from '@refera/ui-icons'

import HeaderTitle from '_components/header-title'
import { ROUTES } from '_utils/constants'
import { userSelector } from '_modules/authentication/selectors'
import { getServiceOrdersCountWidgets } from '_modules/widgets/actions'
import { getServiceOrderCountSelector } from '_modules/widgets/selectors'
import { getServiceOrders, updateServiceOrderFilter } from '_modules/service-orders/actions'

import WidgetTradesman from './widget-tradesman'
import { MainWidgets, modal, MODAL_TYPES, SecondaryWidgets } from './constants'
import useStyles from './styles'
import TradesmanAnticipationBanner from './anticipation-banner'
import { anticipationsBannerInfoSelector } from '_/modules/finance/selectors'
import { getAnticipationsBannerInfo } from '_/modules/finance/actions'
import Tour from '_/components/tour'

const MODAL_INITIAL_STATE = {
  isOpen: false,
  type: null,
}

const HomeTradesman = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const user = useSelector(userSelector)
  const countBy = useSelector(getServiceOrderCountSelector)
  const anticipationsBannerInfo = useSelector(anticipationsBannerInfoSelector)
  const [modalState, setModalState] = useState(MODAL_INITIAL_STATE)

  const showAnticipationsBanner = useMemo(() => {
    return Object.keys(anticipationsBannerInfo).length !== 0 && anticipationsBannerInfo?.showBanner
  }, [anticipationsBannerInfo])

  /**
   *
   * @param {string[]} stepStatuses
   * @description An array of `step_status filter option` that their count should be summed up
   *
   * @param {boolean} [total]
   * @default true
   * @description A flag indicating that only the "total" number of service orders should be summed up
   *
   * @param {boolean} [delayed]
   * @default false
   * @description A flag indicating that only delayed service orders with that attribute should be summed up
   */
  const sumAttributesCount = useCallback(
    (attributes, total = true, delayed = false) => {
      if (total) {
        return attributes.reduce((acc, currentAttribute) => {
          const currentCount = countBy?.[currentAttribute]?.total || 0

          return acc + currentCount
        }, 0)
      }

      if (delayed) {
        return attributes.reduce((acc, currentAttribute) => {
          const currentCount = countBy?.[currentAttribute]?.delayed || 0

          return acc + currentCount
        }, 0)
      }

      return attributes.reduce((acc, currentAttribute) => {
        const currentCount = countBy?.[currentAttribute] || 0

        return acc + currentCount
      }, 0)
    },
    [countBy]
  )

  const getAttributesDelayed = useCallback(attributes => {
    return attributes.reduce(
      (acc, currentAttribute) => {
        if (countBy?.[currentAttribute]?.delayed) {
          return { ...acc, count: acc.count + 1, names: [...acc.names, currentAttribute] }
        }

        return acc
      },
      { count: 0, names: [] }
    )
  }, [])

  const onClickFilter = useCallback(
    event => {
      // NOTE: Removing 'modalType' from the params object, because it isn't a valid param
      // eslint-disable-next-line no-unused-vars
      const { modalType, ...params } = event.currentTarget.dataset

      const payload = {
        pageSize: 10,
        tradesman: user?.getServiceProviderId,
        ...params,
      }

      dispatch(getServiceOrders(payload))
      dispatch(updateServiceOrderFilter({ ...payload, page: 0 }))
      navigate(ROUTES.NEW_ORDERS)
    },
    [dispatch, user?.getServiceProviderId]
  )

  const handleOpenModal = useCallback(event => {
    const { modalType } = event.currentTarget.dataset

    setModalState({
      isOpen: true,
      type: MODAL_TYPES[modalType],
    })
  }, [])

  const handleCloseModal = useCallback(() => {
    setModalState(MODAL_INITIAL_STATE)
  }, [])

  const renderSecondaryAction = useCallback(
    ({ secondaryAction, showDelayed, attributes, dataAttributes }) => {
      const hasSecondaryActionProps = Object.keys(secondaryAction).length > 0
      const totalDelayed = sumAttributesCount(attributes, false, true)
      const { count, names } = getAttributesDelayed(attributes)

      if (showDelayed && totalDelayed > 0) {
        const onClick = count > 1 ? handleOpenModal : onClickFilter
        const delayedModalType = dataAttributes['data-modal-type']
          ? `DELAYED_${dataAttributes['data-modal-type']}`
          : undefined

        const props = {
          icon: DangerIcon,
          description: `${totalDelayed} em atraso`,
          onClick,
          dataAttributes: {
            ...dataAttributes,
            'data-modal-type': delayedModalType,
            'data-situation': 'delayed',
            ...(count === 1 && {
              // TODO: This works only if the attribute this widget should filter is a stepStatus
              // Improve the logic to dynamically determine the type of attribute
              'data-step-status': names[0],
            }),
          },
        }

        return <WidgetTradesman.WidgetSecondaryAction {...props} />
      }

      if (hasSecondaryActionProps) {
        return <WidgetTradesman.WidgetSecondaryAction {...secondaryAction} />
      }

      return null
    },
    [getAttributesDelayed, handleOpenModal, onClickFilter, sumAttributesCount]
  )

  const renderWidgetItem = useCallback(
    ({
      dataAttributes = {},
      title,
      attributes,
      icon,
      onClick,
      secondaryAction = {},
      showDelayed = false,
      isDelayed = false,
      quantityDefault,
    }) => {
      const handleClick = attributes.length > 1 ? handleOpenModal : onClickFilter
      const quantity = quantityDefault ?? sumAttributesCount(attributes, !isDelayed, isDelayed)

      return (
        <Grid item key={title}>
          <WidgetTradesman
            title={title}
            icon={icon}
            quantity={quantity}
            onClick={onClick ?? handleClick}
            dataAttributes={dataAttributes}
            isDelayed={isDelayed}
          >
            {renderSecondaryAction({ secondaryAction, showDelayed, attributes, dataAttributes })}
          </WidgetTradesman>
        </Grid>
      )
    },
    [handleOpenModal, onClickFilter, renderSecondaryAction, sumAttributesCount]
  )

  const handleGoToHelpRequestView = useCallback(() => {
    navigate(ROUTES.HELP_REQUESTS)
  }, [])

  const mainWidgetsParams = useMemo(
    () => ({
      isPausedCount: sumAttributesCount(['is_paused'], false),
      notViewedHelpRequestCount: sumAttributesCount(['not_viewed_pending_help_request'], false),
      urgentPriorityCount: sumAttributesCount(['urgent_priority'], false),
      handleGoToHelpRequestView,
    }),
    [handleGoToHelpRequestView, sumAttributesCount]
  )

  const fetchAnticipationsBannerInfo = useCallback(
    () =>
      Object.keys(anticipationsBannerInfo).length === 0 && dispatch(getAnticipationsBannerInfo()),
    [dispatch, anticipationsBannerInfo]
  )

  useEffect(() => {
    dispatch(
      getServiceOrdersCountWidgets({
        stepStatus: [
          'new_service_orders',
          'budget_schedule',
          'send_budget',
          'send_for_execution',
          'finish_service_order',
          'waiting_budget_approval',
          'service_schedule',
          'finished_services',
          'problems_finishing_service',
          'budget_pre_approved',
          'service_finished',
        ].join(','),
        isPaused: true,
        notViewedPendingHelpRequest: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    fetchAnticipationsBannerInfo()
  }, [])

  return (
    <>
      <HeaderTitle title="Início" />
      <Grid className={styles.container} container gap={3}>
        {showAnticipationsBanner && <TradesmanAnticipationBanner />}
        <Grid container wrap="wrap" gap={2} item xs={12}>
          {MainWidgets(mainWidgetsParams).map(renderWidgetItem)}
        </Grid>
        <Grid item xs={12}>
          <Divider className={styles.divider} />
        </Grid>
        <Grid container wrap="wrap" gap={2} item xs={12}>
          {SecondaryWidgets.map(renderWidgetItem)}
        </Grid>
      </Grid>

      <Modal
        title="Selecione o filtro"
        onBackdropClick={handleCloseModal}
        onClose={handleCloseModal}
        open={modalState.isOpen}
      >
        <Typography>
          Selecione quais chamados você deseja ver na tela de Gestão de chamados:
        </Typography>
        <Grid className={styles.modalContent} container gap={2}>
          {modal?.[modalState.type]?.map(renderWidgetItem)}
        </Grid>
      </Modal>
      <Tour user={user} />
    </>
  )
}

export default HomeTradesman
