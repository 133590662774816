import { createReducer } from '_utils/redux'
import { GET_DAY_PERIODS } from './actions'
import { List, Record } from 'immutable'

const INITIAL_STATE = new Record({
  results: List(),
})()

const dayPeriods = createReducer(INITIAL_STATE, {
  [GET_DAY_PERIODS.FULFILLED]: (state, { payload }) =>
    state.update('results', () => List(payload.results)),
})

export default dayPeriods
