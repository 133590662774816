import React, { useState, useMemo, useCallback } from 'react'

import { Grid, Tooltip, IconButton, Link, Button } from '@material-ui/core'
import { useLocation } from '@reach/router'

import { Menu, Typography } from '@refera/ui-web'

import Svg from '_components/svg'
import { useSelector } from 'react-redux'
import QuestionIcon from '_assets/svgs/question-circle.svg'
import MessageQuestionIcon from '_assets/svgs/message-question-selected.svg'
import useRolePermission from '_hooks/use-role-permission'
import QuestionIconBlue from '_assets/svgs/question-circle-blue.svg'
import MovideskChatIcon from '_assets/svgs/messages.svg'
import SmallArrowBlue from '_assets/svgs/small-arrow-blue.svg'
import { getArticlesSelector } from '_/modules/utils/selectors'
import { userRoleSelector, userSelector } from '_/modules/authentication/selectors'
import { HEADER_TAGS } from '_utils/header-tags'
import useMovideskChat from '_hooks/use-movidesk-chat'
import Tips from '_components/tips'

import { filterArticles, filterFaqs } from './utils'
import useStyles from './styles'
import ModalDialog, { WARNING_MODAL } from '_/components/modal/modal-dialog'

const Support = () => {
  const styles = useStyles()
  const [modalDialog, setModalDialog] = useState({
    isOpen: false,
    textOkButton: 'Ok, entendi',
    subTitle: `Pedimos desculpas por isso, mas por enquanto você deve abrir itens de ajuda através do APP.`,
    type: WARNING_MODAL,
  })

  const articles = useSelector(getArticlesSelector)
  const { pathname } = useLocation()
  const user = useSelector(userSelector)
  const userRole = useSelector(userRoleSelector)

  const handleRequestHelpForm = useCallback(() => {
    return setModalDialog({
      isOpen: true,
      textOkButton: 'Ok, entendi',
      subTitle: `Pedimos desculpas por isso, mas por enquanto você deve abrir itens de ajuda através do APP.`,
      type: WARNING_MODAL,
    })
  }, [pathname])

  const [anchorEl, setAnchorEl] = useState(null)

  const [toggleMovideskChat] = useMovideskChat()
  const { isIntermediary, isTradesman } = useRolePermission()

  const links = useMemo(() => {
    const filteredFaqs = articles.filter(item => {
      const hasFaq = filterFaqs(item, user, pathname)

      return hasFaq && item
    })

    const filteredArticles = articles.filter(item => {
      const hasArticle = filterArticles(item, user, pathname)

      return hasArticle && item
    })

    const userFaqs = articles.filter(item => {
      const hasFaq = filterFaqs(item, user, pathname, true)

      return hasFaq && item
    })

    const userArticles = articles.filter(item => {
      const hasArticle = filterArticles(item, user, pathname, true)

      return hasArticle && item
    })

    if (filteredFaqs.length > 0 && filteredArticles.length > 0) {
      // HAS FILTERS FOR FAQs AND ARTICLES
      return { faq: filteredFaqs, articles: filteredArticles }
    }

    if (filteredFaqs.length > 0 && !filteredArticles.length > 0) {
      // HAS FILTERS FOR FAQs BUT NOT ARTICLES
      return { faq: filteredFaqs, articles: userArticles }
    }

    if (!filteredFaqs.length > 0 && filteredArticles.length > 0) {
      // HAS FILTERS FOR ARTICLES BUT NOT FAQs
      return { faq: userFaqs, articles: filteredArticles }
    }

    // HAS NO FILTERS, RETURNS BY USER
    return { faq: userFaqs, articles: userArticles }
  }, [articles, pathname])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const tip = useMemo(
    () =>
      user &&
      user?.tips?.filter(
        tipToSee =>
          pathname?.includes(tipToSee?.viewName?.replace('/', '')) &&
          tipToSee?.component === 'help_button' &&
          (Array.isArray(user?.getRoles) ? user.getRoles : [user?.getRoles]).some(role =>
            (tipToSee?.getRoles || []).includes(role)
          )
      )[0],
    [user, userRole, pathname]
  )

  const renderTooltip = useMemo(() => {
    const supportButton = (
      <IconButton onClick={handleClick}>
        <Svg icon={QuestionIcon} className={styles.supportIcon} />
      </IconButton>
    )

    return tip ? (
      <Tips component="help_button">{supportButton}</Tips>
    ) : (
      <Tooltip title="Ajuda" placement="bottom" PopperProps={{ style: { marginTop: -7 } }}>
        {supportButton}
      </Tooltip>
    )
  }, [tip])

  return (
    <>
      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
      {renderTooltip}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            marginTop: 20,
            padding: 8,
            marginLeft: '-90px',
            width: '350px',
            minHeight: '457px',
          },
        }}
      >
        {isIntermediary
          ? HEADER_TAGS.MOVIDESK_CHAT_INTERMEDIARY.header
          : HEADER_TAGS.MOVIDESK_CHAT_TRADESMAN.header}
        <Grid className={styles.container}>
          <Typography className={styles.title}>Olá, como podemos te ajudar?</Typography>
          <Grid className={styles.linksWrapper}>
            <Typography className={styles.subtitle}>Dúvidas frequentes</Typography>
            {links?.faq?.map(item => (
              <Link
                underline="always"
                target="_blank"
                rel="noopener"
                key={item.id}
                className={styles.link}
                href={item.link}
              >
                {item.name}
              </Link>
            ))}
            <Link
              key="moreFaqs"
              underline="none"
              target="_blank"
              rel="noopener"
              href="https://refera.movidesk.com/kb/articles/RecentArticles"
              className={styles.sublink}
            >
              Ver todos os artigos
              <Svg icon={SmallArrowBlue} className={styles.arrowIcon} />
            </Link>

            <Typography className={styles.subtitle}>Artigos sugeridos</Typography>
            {links?.articles?.map(item => (
              <Link
                underline="always"
                target="_blank"
                rel="noopener"
                key={item.id}
                className={styles.link}
                href={item.link}
              >
                {item.name}
              </Link>
            ))}
            <Link
              key="moreArticles"
              underline="none"
              target="_blank"
              rel="noopener"
              href="https://refera.movidesk.com/kb/articles/RecentArticles"
              className={styles.sublink}
            >
              Ver todos os artigos
              <Svg icon={SmallArrowBlue} className={styles.arrowIcon} />
            </Link>
          </Grid>

          <Button
            className={styles.bottomMenuItem}
            onClick={() =>
              window.open(
                'https://refera.movidesk.com/kb/articles/RecentArticles',
                '_blank',
                'noopener'
              )
            }
            startIcon={<Svg icon={QuestionIconBlue} className={styles.supportIcon} />}
          >
            Central de Ajuda
          </Button>

          {isTradesman && (
            <Button
              className={styles.bottomMenuItem}
              onClick={handleRequestHelpForm}
              startIcon={<Svg icon={MessageQuestionIcon} className={styles.supportIcon} />}
            >
              Abrir item de ajuda
            </Button>
          )}

          {(isIntermediary || isTradesman) && (
            <Button
              onClick={toggleMovideskChat}
              className={styles.bottomMenuItem}
              startIcon={<Svg icon={MovideskChatIcon} className={styles.supportIcon} />}
            >
              Atendimento via Chat
            </Button>
          )}
        </Grid>
      </Menu>
    </>
  )
}

export default Support
