import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const useRequest = (action, onSuccess = () => {}, onReject = () => {}) => {
  const dispatch = useDispatch()

  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState({})

  const fetchData = payload => {
    setIsLoading(true)

    dispatch(payload ? action(payload) : action)
      .then(response => {
        setData(response)
        onSuccess(response)
      })
      .catch(err => {
        setError(err)
        onReject(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return [isLoading, data, error, fetchData]
}

export default useRequest
