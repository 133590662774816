import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  box: {
    maxWidth: spacing(450),
    width: spacing(450),
    boxSizing: 'border-box',
  },
  header: {
    padding: spacing(23, 32),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: spacing(12),
    alignItems: 'center',
  },
  content: {
    padding: spacing(32),
  },
  rejectIcon: {
    height: spacing(32),
    width: spacing(32),
  },
  info: {
    margin: spacing(40, 0),
    display: 'grid',
    gridRowGap: spacing(14),
  },
  title: {
    color: palette.gray[80],
    fontSize: spacing(16),
    lineHeight: spacing(24),
    letterSpacing: spacing(-0.01),
    fontWeight: 500,
    '&.big': {
      fontSize: spacing(20),
    },
  },
  buttonContainer: {
    margin: spacing(48, 'auto', 16),
  },
  button: {
    width: spacing(88),
    '&.outlined': {
      color: palette.primary.main,
      border: `${spacing(1)} solid ${palette.primary.main}`,
    },
    '&.left': {
      marginLeft: spacing(16),
    },
  },
  attachFileButton: {
    background: 'white',
    border: `${spacing(1)} dashed ${palette.gray[32]}`,
    borderRadius: spacing(4),
  },
  iconAttachFile: {
    color: palette.gray[64],
    height: spacing(16),
    width: spacing(16),
  },
  total: {
    color: props => (props.hasDiscount ? palette.green.main : palette.primary.main),
    fontWeight: 500,
  },
  close: {
    width: spacing(14),
  },
  divider: {
    backgroundColor: palette.gray[12],
  },
  input: {
    height: spacing(64),
    marginBottom: spacing(24),
    '& .MuiInputBase-input': {
      height: spacing(40),
      padding: spacing(0),
    },
  },
}))
