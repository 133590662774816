import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  header: {
    padding: spacing(10, 0, 24),
  },
  text: {
    fontWeight: 500,
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: palette.gray[64],
    marginLeft: spacing(16.7),
  },
  back: {
    padding: 0,
    '& .MuiSvgIcon-root': {
      width: spacing(14),
      height: spacing(14),
    },
  },
  buttons: {
    display: 'flex',
    gap: spacing(16),
    alingItems: 'center',
  },
  headerButtons: {
    display: 'flex',
  },
  originalBudgetLink: {
    textDecoration: 'underline',
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: '2rem',
    verticalAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(10, 12),
  },
  editIcon: {
    width: spacing(12),
    height: spacing(12),
  },
  buttonIcon: {
    width: spacing(16),
    height: spacing(16),
    marginRight: spacing(16),
  },
  iconButton: {
    marginLeft: 'auto',
    border: `${spacing(1)} solid ${palette.gray[64]}`,
    width: spacing(40),
    height: spacing(40),
    padding: 0,

    '&:hover': {
      backgroundColor: 'inherit',
      opacity: 0.8,
    },

    '&:disabled': {
      backgroundColor: 'inherit',
      border: `${spacing(1)} solid ${palette.gray[64]}`,
    },
  },
  icon: {
    width: spacing(18),
    '& .MuiSvgIcon-root': {
      width: spacing(18),
      height: spacing(13),
    },
    fill: palette.gray[64],
  },
  blue: {
    border: 'none',
    background: palette.primary.main,
    '& .MuiSvgIcon-root': {
      width: spacing(18),
      fill: 'white',
    },

    '&:disabled': {
      backgroundColor: 'inherit',
      border: `${spacing(1)} solid ${palette.gray[64]}`,

      '& .MuiSvgIcon-root': {
        width: spacing(18),
        fill: palette.gray[64],
      },
    },
  },
  yellow: {
    border: 'none',
    background: palette.yellow.main,
  },
  red: {
    border: 'none',
    background: palette.custom.red,
    '& .MuiSvgIcon-root': {
      width: spacing(14),
      fill: 'white',
    },
    '&:disabled': {
      backgroundColor: 'inherit',
      border: `${spacing(1)} solid ${palette.gray[64]}`,

      '& .MuiSvgIcon-root': {
        width: spacing(18),
        fill: palette.gray[64],
      },
    },
  },
  small: {
    '& .MuiSvgIcon-root': {
      width: spacing(14),
      height: spacing(18),
    },
  },
  downloadButton: {
    border: '1px solid rgba(180, 180, 180, 1)',
  },
}))
