import React, { useMemo, useCallback, useState, useEffect } from 'react'
import Accordion from '_/components/accordion'
import { useConfirm } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import { Controller } from 'react-hook-form'
import useStyles from './styles'

const AddAttachments = ({ provider, isEditing, control, onChange }) => {
  const styles = useStyles()

  const [dialogMessage, setDialogMessage] = useState('')
  const [toDelete, setToDelete] = useState([])
  const { isConfirmed } = useConfirm()

  const [attachments, setAttachments] = useState([])

  useEffect(() => {
    if (provider?.contractAttachments) {
      setAttachments(
        provider?.contractAttachments.toArray().map(file => ({
          file: file?.get('file'),
          id: file?.get('id'),
        }))
      )
    }
  }, [provider?.contractAttachments])

  const attachmentsPdf = useMemo(
    () =>
      attachments.filter(file =>
        file instanceof File ? file.type === 'application/pdf' : file.file.indexOf('.pdf') !== -1
      ),
    [attachments]
  )

  const handlePdfClick = useCallback(index => {
    const currentFile = attachmentsPdf[index]
    if (currentFile?.id) {
      window.open(attachmentsPdf[index].file)
    } else {
      window.open(URL.createObjectURL(attachmentsPdf[index]))
    }
  })

  const handleRemoveAttachments = useCallback(
    async index => {
      setDialogMessage('Você deseja deletar este item?')
      const confirmed = await isConfirmed()
      if (confirmed) {
        const currentFile = attachmentsPdf[index]
        if (currentFile?.id) {
          setToDelete([...toDelete, currentFile.id])
          setAttachments(old => old.filter(current => current.id !== currentFile.id))
        } else {
          setAttachments(old =>
            old.filter(current => current.lastModified !== currentFile?.lastModified)
          )
        }
      }
    },
    [attachments, setDialogMessage]
  )

  useEffect(() => {
    if (attachments) {
      onChange({ attachments, toDelete })
    }
  }, [attachments, toDelete])

  return (
    <Accordion title="Contratos" className={styles.container}>
      <ConfirmRemoveDialog message={dialogMessage} />
      <Controller
        control={control}
        name="contract_attachments"
        defaultValue={attachments}
        as={
          <Attachments
            label="Contratos"
            content="files"
            downloadable
            readOnly={!isEditing}
            files={attachmentsPdf || []}
            accept={{
              'application/pdf': ['.pdf'],
            }}
            onDrop={files => {
              setAttachments(old => [...old, ...files])
            }}
            onRemoveItem={index => handleRemoveAttachments(index, true)}
            onItemClick={index => handlePdfClick(index)}
          />
        }
      />
    </Accordion>
  )
}

export default AddAttachments
