/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { cpfCnpjMask } from '_utils/helpers'

import TextField from '../index'

const DynamicMaskTextField = ({ value, name, onChange, ...otherProps }) => {
  const handleChange = useCallback(
    event => {
      const maskedValue = { target: { name, value: cpfCnpjMask(event.target.value) } }
      onChange(maskedValue)
    },
    [name, onChange]
  )

  return (
    <TextField
      mask={cpfCnpjMask(value)}
      name={name}
      value={value}
      onChange={handleChange}
      {...otherProps}
    />
  )
}

DynamicMaskTextField.propTypes = {
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isCpfCnpj: PropTypes.bool,
  onChange: PropTypes.func,
  height: PropTypes.number,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
}

DynamicMaskTextField.defaultProps = {
  mask: '',
  isCpfCnpj: false,
  height: 4.8,
  onChange: () => {},
  helperText: '',
  disabled: false,
}

export default React.memo(DynamicMaskTextField)
