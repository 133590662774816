import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: spacing(287),
      minHeight: spacing(112),
    },
    '& .MuiButton-disableElevation:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  content: {
    padding: spacing(30, 20),
    fontSize: spacing(14),
    lineHeight: spacing(20),
  },
  actions: {
    display: 'block',
    textAlign: 'end',
    padding: 0,
  },
  button: {
    width: spacing(88),
  },
  textButton: {
    padding: spacing(0, 22, 22, 12),
    width: spacing(88),
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    color: palette.gray[64],
    fontSize: spacing(14),
  },
  textButtonActive: {
    marginLeft: 'auto',
    color: palette.primary.main,
    '&:hover': {
      background: 'white',
    },
  },
}))
