import { Map, fromJS } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { getPage } from '_utils/helpers'
import { ORDER_BY_VALUES } from '_utils/constants'

import { GET_BUDGET_TEMPLATES, GET_BUDGET_TEMPLATE_BY_ID, UPDATE_BUDGET_TEMPLATE } from './actions'
import BudgetTemplate, { budgetTemplateSchema } from '_models/budget-template'

const INITIAL_STATE = new Map({
  results: new Map(),
  next: '',
  count: '',
  pageSize: 10,
  orderBudgetTemplates: new Map(),
  params: undefined,
  page: 0,
  isAsc: undefined,
})

export default createReducer(INITIAL_STATE, {
  [GET_BUDGET_TEMPLATES.FULFILLED]: (state, { payload, meta }) => {
    const { order } = meta
    const normalizeBudgetTemplate = normalize(payload.results, budgetTemplateSchema)

    return state
      .set('results', fromJS(normalizeBudgetTemplate.entities.budgetTemplate))
      .set('next', getPage(payload.next))
      .set('count', payload.count)
      .set('pageSize', meta.pageSize)
      .set('params', { order: meta.order })
      .set('page', meta.page)
      .set('orderBudgetTemplates', normalizeBudgetTemplate.result)
      .set('isAsc', order === ORDER_BY_VALUES.ASC)
  },

  [GET_BUDGET_TEMPLATE_BY_ID.FULFILLED]: (state, { payload }) => {
    return state.setIn(['results', payload?.id?.toString()], new BudgetTemplate(payload))
  },

  [UPDATE_BUDGET_TEMPLATE.FULFILLED]: (state, { payload }) => {
    const data = {
      ...payload,
    }

    return state.setIn(['results', payload?.id?.toString()], new BudgetTemplate(data))
  },
})
