import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    width: spacing(325),
    gap: spacing(12),
    paddingBottom: spacing(Theme.Spacing.Medium),
    paddingTop: spacing(Theme.Spacing.Medium),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  wrapper: {
    whiteSpace: 'break-spaces',
  },
  raw: {
    direction: 'raw',
    display: 'flex',
    whiteSpace: 'nowrap',
    gap: spacing(10),
  },
  icon: {
    display: 'flex',
    height: spacing(32),
    width: spacing(32),
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Theme.Colors.Primary.Base,
  },
  title: {
    display: 'flex',
    color: Theme.Colors.Grayscale.Eighty,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    lineHeight: '18px',
  },
  description: {
    display: '-webkit-box',
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontWeight: Theme.Typography.FontWeight.Small,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '16.41px',
    wordBreak: 'break-word',
    maxWidth: '100%',
    '-webkit-box-orient': ' vertical',
    '-webkit-line-clamp': 4,
  },
  link: {
    display: 'flex',
    textAlign: 'left',
    fontWeight: Theme.Typography.FontWeight.Small,
    padding: spacing(0),
    fontSize: Theme.Typography.FontSize.XXSmall,
    color: Theme.Colors.Primary.Base,
    textDecoration: 'underline',
    paddingBottom: spacing(14),
    paddingTop: spacing(5),
  },
  date: {
    display: 'flex',
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.SixtyFour,
  },
  buttons: {
    position: 'absolute',
    right: 0,
    justifySelf: 'end',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    height: '4px',
    padding: spacing(0),
  },
  span: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  spanMesage: {
    fontSize: '14px',
  },
  tag: {
    borderRadius: spacing(24),
    height: spacing(11),
    textTransform: 'none',
    fontFamily: [Theme.Typography.FontFamily.Roboto, 'sans-serif'],
    padding: '4px 8px',
    fontSize: Theme.Typography.FontSize.Tiny,
    lineHeight: '10.5%',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    backgroundColor: Theme.Colors.Grayscale.Four,
    color: Theme.Colors.Grayscale.SixtyFour,
  },
  statusTag: {
    borderRadius: spacing(24),
    height: spacing(24),
    width: 'fit-content',
    textTransform: 'none',
    padding: '4px 8px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    backgroundColor: Theme.Colors.Grayscale.Four,
    color: Theme.Colors.Grayscale.SixtyFour,
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(4),
    fontFamily: [Theme.Typography.FontFamily.Roboto, 'sans-serif'],
    fontSize: Theme.Typography.FontSize.Tiny,
    lineHeight: '112.5%',
    marginTop: spacing(4),
    marginBottom: spacing(4),
  },
  styleDisabled: {
    fill: Theme.Colors.Grayscale.Eighty,
    cursor: 'pointer',
    width: '16.33px',
    height: '16.23px',
    border: '1.5px',
  },
  styleEnabled: {
    cursor: 'pointer',
    color: Theme.Colors.Yellow.Base,
  },
  justifiedDelay: {
    backgroundColor: Theme.Colors.Yellow.Base,
  },
  paymentAnticipation: {
    backgroundColor: Theme.Colors.Green.Base,
  },
  helpRequest: {
    backgroundColor: Theme.Colors.Red.Base,
  },
  senderName: {
    color: Theme.Colors.Grayscale.Eighty,
  },
  helpRequestIcon: {
    fontSize: '14px',
  },
  trashIcon: {
    fill: Theme.Colors.Red.Base,
    width: '20.14px',
    height: '19.57px',
  },
  editIcon: {
    fill: Theme.Colors.Grayscale.Eighty,
    width: '20.14px',
    height: '19.57px',
  },
  edit: {
    fontFamily: Theme.Typography.FontFamily.Inter,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
  delete: {
    fontFamily: Theme.Typography.FontFamily.Inter,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    color: Theme.Colors.Red.Base,
  },
}))
