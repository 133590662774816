/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  Box,
  Chip,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { getCategoryList } from '_/modules/service-orders/actions'
import Modal from '_components/modal'

import useStyles from './styles'

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}

const emptyFunction = () => {}

const CategoryModal = ({
  value,
  handleModal,
  handleContinue = emptyFunction,
  patchCategories,
  leftButtonText,
  isServiceOrder = false,
  ...props
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [categoryList, setCategoryList] = useState([])
  const [selectCategories, setSelectCategories] = useState(value)
  const [hasError, setError] = useState(false)

  const handleSelect = event => {
    setError(false)
    setSelectCategories(event.target.value)
  }

  const handleSubmit = async () => {
    const error = value.sort().toString() === selectCategories.sort().toString()

    if (error && !handleContinue) {
      setError(true)
      return
    }

    patchCategories(selectCategories)

    if (handleContinue !== emptyFunction) {
      handleContinue()
    } else {
      handleModal()
    }
  }

  useEffect(() => {
    if (isServiceOrder) {
      dispatch(getCategoryList({ isActive: true, allowsSelection: true })).then(res => {
        const orderServiceCategories = props?.categoriesSO?.toJS()
        const newCategories = orderServiceCategories.filter(
          orderCat => !res.some(allCat => allCat.id === orderCat.id)
        )
        setCategoryList(res.concat(newCategories))
      })
    } else {
      dispatch(getCategoryList({ isActive: true, allowsSelection: true })).then(res => {
        const orderServiceCategories = props?.tradesmanCategories
        const newCategories = orderServiceCategories.filter(
          tradesmanCategories => !res.some(allCat => allCat.id === tradesmanCategories.id)
        )
        setCategoryList(res.concat(newCategories))
      })
    }
  }, [])

  // TEMPORARY FILTER, MAY BE REMOVED LATER
  const [selectFilter, setSelectFilter] = useState('')
  const [filteredCategory, setFilteredCategory] = useState([])

  useEffect(() => {
    if (props?.hasFilter) {
      const filterByName = categoryList?.filter(category =>
        category?.name?.toLowerCase().includes(selectFilter?.toLowerCase())
      )

      setFilteredCategory(filterByName)
    }
  }, [selectFilter])
  // -----------------------------------------

  return (
    <Modal
      title="Categorias do chamado"
      disabledSubmit={!selectCategories?.length}
      handleModal={handleModal}
      handleSubmit={handleSubmit}
      leftButtonText={leftButtonText || 'Salvar'}
      {...props}
    >
      <Grid className={styles.modal}>
        {props?.hasFilter && (
          <TextField
            id="categoryFilter"
            label="Filtro"
            className={styles.filter}
            value={selectFilter}
            onChange={e => setSelectFilter(e.target.value)}
          />
        )}
        <FormControl>
          <InputLabel id="categoryLabel">Categorias</InputLabel>
          <Select
            multiple
            id="categorySelect"
            labelId="categoryLabel"
            label="Categorias"
            MenuProps={MenuProps}
            className={styles.select}
            value={selectCategories}
            onChange={e => handleSelect(e)}
            renderValue={selected => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, padding: 5 }}>
                {selected.map(value => (
                  <Chip
                    key={value}
                    label={categoryList.find(item => item.id === value)?.name}
                    className={styles.chip}
                  />
                ))}
              </Box>
            )}
          >
            {!filteredCategory?.length > 0
              ? categoryList?.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              : filteredCategory?.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
          </Select>
          {hasError && <Typography className={styles.error}>Conteúdo já existente</Typography>}
        </FormControl>
      </Grid>
    </Modal>
  )
}

export default React.memo(CategoryModal)
