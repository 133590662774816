import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: spacing(10),
  },
  button: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),

    fontSize: '14px',
    color: palette.gray[80],

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.gray[4],
      color: palette.primary.main,
    },

    '&:selected': {
      cursor: 'pointer',
      backgroundColor: palette.gray[4],
      color: palette.primary.main,
    },
  },
  treeWrapper: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
    padding: spacing(0),
    width: 'auto',
    minWidth: spacing(240),

    '&::-webkit-scrollbar': {
      width: `${spacing(75)} !important`,
      height: 0,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '100vh',
      backgroundColor: `${palette.grey[100]} !important`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${palette.grey[300]} !important`,
      borderRadius: '100vh',
      border: `3px solid ${palette.grey[300]}`,
    },
  },
  searchInput: {
    marginBottom: spacing(20),
  },
  inputStyles: {
    fontSize: '1.5rem',
  },
}))
