import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  title: {
    color: '#1671ED',
    cursor: 'move',
  },
  container: {
    display: 'flex',
    width: '800px',
    maxWidth: '800px',
    height: '100%',
    padding: '50px',
    flexDirection: 'column',
    borderTop: '2px solid #E3E3E3',
    overflowX: 'hidden',
  },
  dialog: {
    maxheight: '900px',
    minHeight: '68%',
  },
  dialogAction: {
    padding: '20px 30px',
  },
  button: {
    height: '40px',
    width: '140px',
    padding: '10px 16.5px',
  },

  required: {
    color: '#C90000',
  },
  labelContainer: {
    width: '30%',
    display: 'flex',
  },
  label: {
    display: 'flex',
    fontWeight: '500',
    fontSize: '1.2rem',
    marginBottom: '10px',
    color: palette.gray[100],
    gap: '5px',
  },
  inputSize: {
    width: '100%',
  },
}))
