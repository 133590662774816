import { makeStyles } from '@material-ui/styles'
import styled from 'styled-components'

import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
  },
  header: {
    maxHeight: spacing(56),
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    maxWidth: spacing(1600),
    padding: spacing(12, 60),

    [breakpoints.down('xs')]: {
      padding: spacing(12, 16),
    },
  },
  referaLogo: {
    height: spacing(32),
    width: 'auto',
    marginTop: spacing(-2),
  },
  logoContainer: {
    display: 'flex',
    gap: spacing(8),
  },
  logoText: {
    fontFamily: Theme.Typography.FontFamily.Inter,
    color: '#0943A6',
    fontSize: '8.62px',
    fontWeight: '400',
    lineHeight: '10.44px',
    letterSpacing: '-0.02em',
    textAlign: 'left',
  },

  intro: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#0943A6',
    height: spacing(540),
    width: '100%',

    '@media (max-width: 1160px)': {
      height: 'fit-content',
    },
  },
  introWrapper: {
    display: 'flex',
    width: 'auto',
    maxWidth: spacing(1600),
  },
  introImgContainer: {
    width: '50%',
    height: '100%',
    overflow: 'hidden',

    '@media (max-width: 1160px)': {
      display: 'none',
    },
  },
  introContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(58, 64),
    textAlign: 'left',
    width: '50%',

    '@media (max-width: 1160px)': {
      width: '100%',
      padding: spacing(24),
      alignItems: 'center',
      textAlign: 'center',
    },

    '@media (max-width: 400px)': {
      fontSize: '40px',
      lineHeight: '50px',
    },
  },
  introTitle: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '64px',
    fontWeight: 'bold',
    lineHeight: '72px',
    color: 'white',

    [breakpoints.down('xs')]: {
      fontSize: '50px',
      lineHeight: '60px',
    },

    '@media (max-width: 400px)': {
      fontSize: '40px',
      lineHeight: '50px',
    },
  },
  introDescription: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    color: 'white',
    margin: '24px 0',

    '@media (min-width: 1160px)': {
      maxWidth: '500px',
    },
  },
  button: {
    padding: '10px 24px',
    borderRadius: '100px',
    border: 'none',
    cursor: 'pointer',
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textAlign: 'center',
    display: 'flex',
    width: 'fit-content',
    gap: '8px',

    [breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  whiteButton: {
    backgroundColor: 'white',
    color: '#0943A6',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#E1E1E1',
    },
  },
  blueButton: {
    color: 'white',
    backgroundColor: '#0943A6',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: Theme.Colors.Primary.Base,
    },
  },

  benefits: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '100%',
  },
  benefitsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'auto',
    maxWidth: spacing(1600),
    padding: spacing(80, 64),

    '@media (max-width: 1160px)': {
      padding: spacing(24),
    },
  },
  benefitsImgContainer: {
    width: '49%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',

    '@media (max-width: 1160px)': {
      display: 'none',
    },
  },
  benefitsContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: '49%',

    '@media (max-width: 1160px)': {
      width: '100%',
      alignItems: 'center',
    },
  },
  benefitsTitle: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '42px',
    fontWeight: 700,
    lineHeight: '62px',
    color: 'black',

    [breakpoints.down('xs')]: {
      fontSize: '34px',
      lineHeight: '44px',
    },

    '@media (max-width: 400px)': {
      fontSize: '30px',
      lineHeight: '40px',
    },
  },
  benefitsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(16),
    margin: spacing(32, 0),
  },
  benefitItem: {
    display: 'flex',
    gap: spacing(16),
  },
  checkIcon: {
    height: spacing(32),
    width: spacing(32),
  },
  arrowIcon: {
    fontSize: spacing(20),
    color: 'white',
    transform: 'rotate(45deg)',

    [breakpoints.down('xs')]: {
      marginBottom: spacing(-5),
      marginLeft: spacing(5),
    },
  },
  benefitItemText: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: '16px',
    lineHeight: '32px',
    color: '#49454F',
  },

  footer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#0943A6',
    width: '100%',
    marginTop: 'auto',
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: spacing(1600),
    padding: spacing(60, 48),
    alignItems: 'center',

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: spacing(16),
      alignItems: 'center',
      padding: spacing(24, 24, 72),
    },
  },
  footerGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing(16),

    [breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  footerGroupTitle: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '21px',
    color: 'white',
  },
  contactButtonsContainer: {
    display: 'flex',
    gap: spacing(16),
  },
  contactButton: {
    cursor: 'pointer',
  },
  contactIcons: {
    fontSize: spacing(24),
  },
}))

export const BackgroundImage = styled.div`
  background-image: url(${props => props.image});
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`
