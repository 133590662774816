import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  modal: {
    '& .MuiDialogActions-root': {
      padding: spacing(8),
    },
    '& .MuiDialog-paperWidthSm': {
      width: spacing(280),
      height: spacing(132),
      margin: spacing(0),
    },
    '& .MuiDialog-container': {
      width: '100vw',
    },
    '& .MuiDialogContent-root': {
      padding: spacing(20, 20, 8),
    },
  },
  label: {
    fontSize: '1.4rem',
    paddingRight: spacing(10),
    lineHeight: spacing(20),
    color: palette.gray[64],
  },
  modalText: {
    fontSize: '1.6rem',
    textAlign: 'center',
  },
}))
