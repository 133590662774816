import { Record, Map, List, fromJS } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { reasonToReproveSchema } from '_models/reason-to-reprove'
import { getPage, removeDuplicatedObjectFromArray } from '_utils/helpers'
import { SERVICE_ORDER_STATUS, ORDER_BY_VALUES } from '_utils/constants'
import { USER_ROLES } from '_utils/user'
import ServiceOrder, { serviceOrderSchema } from '_models/service-order'
import { REFUSE_BUDGET, SEND_BUDGET_EMAIL } from '_modules/budget/actions'

import { GET_USER, LOGIN } from '../authentication/actions'
import { UPDATE_PROPERTY } from '../property/actions'

import {
  GET_SERVICE_ORDERS,
  GET_MORE_SERVICE_ORDERS,
  HANDLE_PAGE,
  CHANGE_UNREAD,
  GET_SERVICE_ORDER,
  UPDATE_SERVICE_ORDER,
  DELETE_ATTACHMENT,
  CANCEL_SERVICE_ORDER,
  CANCEL_SERVICE_ORDER_FINISH,
  DUPLICATE_SERVICE_ORDER,
  SET_CURRENT,
  VERIFY_NEW_BUDGET,
  GET_SERVICE_APPROVED,
  GET_REASONS_REPROVE,
  GET_CANCEL_SERVICE_APPROVED,
  GET_CANCEL_SERVICE_REJECTED,
  GET_REASONS_TO_REFUSE_METABASE_SUGGESTION,
  SET_DEFAULT_SERVICE_ORDER_FILTER,
  UPDATE_SERVICE_ORDER_FILTER,
  GET_ALL_STEP_STATUS_FILTER_OPTIONS,
  GET_ALL_STEP_STATUS_OPTIONS,
  GET_BUTTONS_ACTION,
  GET_GENERIC_PARAMETERS,
  GET_REQUESTER_GENERIC_PARAMETERS,
  GET_STEP_STATUS_FILTER,
  GET_SERVICE_ORDER_TRADESMAN,
  GET_REACTIVATE_BUTTON,
  UPDATE_APPOINTMENT_DATE_SCHEDULE,
  REACTIVATE_SERVICE_ORDER,
  REGISTER_EXECUTION,
  GET_PAYMENT_ANTICIPATION_INFO,
  CANCEL_EXECUTION,
  CLOSE_SERVICE_ORDER,
  SCHEDULE_EXECUTION,
  UPDATE_SERVICE_ORDER_FLOWS_OPTIONS,
  GET_ACTIVE_BUDGET,
} from './actions'

const INITIAL_STATE = new Record({
  results: Map(),
  order: Map(),
  next: '',
  count: '',
  size: 10,
  params: '',
  loadedPages: List(),
  page: 0,
  orderStatus: '',
  current: '',
  currentAgencyId: '',
  isAsc: undefined,
  reasons: List(),
  sendEmailDefinition: undefined,
  paymentAnticipation: undefined,
  filter: Map({
    agency: '',
    classification: '',
    city: '',
    fullAddress: '',
    message: '',
    code: '',
    requesterData: '',
    responsible: '',
    serviceOrder: '',
    situation: '',
    stepStatus: '',
    tradesman: '',
    page: 0,
    pageSize: 10,
    orderBy: '',
  }),
  isFilterDirty: false,
  stepStatusFilter: undefined,
  stepStatusCanceledList: undefined,
  stepStatusFinalizedList: undefined,
  allStepStatusFilterOptions: undefined,
  allStepStatusOptions: undefined,
  buttonsAction: undefined,
  genericParameters: undefined,
  genericParametersRequester: undefined,
  reactivateButton: undefined,
  countByStepStatus: undefined,
  flowsOptions: undefined,
  activeBudget: undefined,
})()

const getServiceOrderById = (state, { payload, meta }) => {
  const budgets = payload?.budget?.map(budget => budget?.id)

  const agencyId = payload?.agency?.id
  const formattedPayload = { ...payload, budget: budgets }
  const { isCurrent } = meta

  if (isCurrent) {
    return state
      .setIn(['results', payload.id?.toString()], new ServiceOrder(formattedPayload))
      .set('current', payload.id?.toString())
      .set('currentAgencyId', agencyId)
  }

  return state
    .setIn(['results', payload.id?.toString()], new ServiceOrder(formattedPayload))
    .set('currentAgencyId', agencyId)
}

const serviceOrders = createReducer(INITIAL_STATE, {
  [LOGIN.FULFILLED]: (state, { payload }) => {
    return state.set(
      'orderStatus',
      payload.getRoles !== USER_ROLES.CS_REFERA ? SERVICE_ORDER_STATUS.IN_PROGRESS : ''
    )
  },
  [GET_USER.FULFILLED]: (state, { payload }) => {
    return state.set(
      'orderStatus',
      payload.getRoles !== USER_ROLES.CS_REFERA ? SERVICE_ORDER_STATUS.IN_PROGRESS : ''
    )
  },
  [GET_SERVICE_ORDERS.FULFILLED]: (state, { payload, meta }) => {
    const { order } = meta
    const normalizeServices = normalize(payload.results, serviceOrderSchema)

    return state
      .set('results', fromJS(normalizeServices.entities.serviceOrder))
      .set('order', normalizeServices.result)
      .set('next', getPage(payload.next))
      .set('count', payload.count)
      .set('size', meta.pageSize)
      .set('params', { order: meta.order })
      .set('loadedPages', List([1]))
      .set('page', 0)
      .set('orderStatus', meta?.orderStatus || state.orderStatus)
      .set('isAsc', order === ORDER_BY_VALUES.ASC)
  },
  [GET_MORE_SERVICE_ORDERS.FULFILLED]: (state, { payload }) => {
    const normalizeServices = normalize(payload.results, serviceOrderSchema)
    const newServices = state.get('results').merge(Map(normalizeServices.entities.serviceOrder))
    const requestedPage = Number.isNaN(getPage(payload.next))
      ? getPage(payload.next) - 1
      : state.get('page') + 1

    return state
      .set('results', newServices)
      .set('order', [...state.order, ...normalizeServices.result])
      .set('next', getPage(payload.next))
      .updateIn(['loadedPages'], loadedPages =>
        state.loadedPages.includes(requestedPage) ? loadedPages : loadedPages.push(requestedPage)
      )
  },
  [GET_SERVICE_ORDER.FULFILLED]: getServiceOrderById,
  [GET_SERVICE_ORDER_TRADESMAN.FULFILLED]: getServiceOrderById,
  [REACTIVATE_SERVICE_ORDER.FULFILLED]: getServiceOrderById,
  [CANCEL_SERVICE_ORDER.FULFILLED]: (state, { payload }) => {
    return state.setIn(['results', payload.id.toString()], new ServiceOrder(payload))
  },
  [CANCEL_SERVICE_ORDER_FINISH.FULFILLED]: (state, { payload }) => {
    return state.updateIn(
      ['results', payload.id.toString()],
      values => new ServiceOrder({ ...values.toJS(), ...payload })
    )
  },
  [VERIFY_NEW_BUDGET.FULFILLED]: (state, { payload }) => {
    if (payload) {
      return state.setIn(['results', payload])
    }
    return state
  },
  [VERIFY_NEW_BUDGET.REJECTED]: (state, { payload }) => {
    if (payload) {
      return state.setIn(['results', payload])
    }
    return state
  },
  [UPDATE_SERVICE_ORDER.FULFILLED]: (state, { payload }) => {
    const newPayload = {
      ...payload,
      property: state.getIn(['results', payload.id.toString(), 'property'])?.toJS(),
    }
    const newService = new ServiceOrder(newPayload)
    return state
      .mergeDeepIn(['results', payload.id.toString()], newService)
      .setIn(['results', payload.id.toString(), 'attachments'], fromJS(newService?.attachments))
      .setIn(
        ['results', payload.id.toString(), 'serviceOrderDuplications'],
        fromJS(payload.serviceOrderDuplications)
      )
      .setIn(['results', payload.id.toString(), 'category'], fromJS(payload.category))
      .setIn(['results', payload.id.toString(), 'subcategories'], fromJS(payload?.subcategories))
  },
  [UPDATE_PROPERTY.FULFILLED]: (state, { payload }) => {
    if (state.current) {
      return state.setIn(['results', state.current, 'property'], Map(payload))
    }
    return state
  },
  [DELETE_ATTACHMENT.FULFILLED]: (state, { meta: { attachmentId } }) => {
    if (attachmentId) {
      return state.updateIn(['results', state.current, 'attachments'], values =>
        values.filter(file => file.get('id') !== Number(attachmentId))
      )
    }
    return state
  },

  [DUPLICATE_SERVICE_ORDER.FULFILLED]: (state, { payload }) => {
    const duplicateServiceOrder = new ServiceOrder(payload)
    return state.updateIn(
      ['results', payload.duplicatedFrom.toString(), 'serviceOrderDuplications'],
      value => List([...value, duplicateServiceOrder])
    )
  },
  [HANDLE_PAGE]: (state, { payload }) => {
    return state.set('page', payload.page)
  },
  [CHANGE_UNREAD]: (state, { payload }) => {
    return state.updateIn(['results', payload.id.toString(), 'unread'], value => !value)
  },

  [REFUSE_BUDGET.FULFILLED]: (state, { payload, meta: { serviceOrderId } }) => {
    if (payload.cancelServiceOrder) {
      return state.setIn(['results', serviceOrderId.toString(), 'isActive'], false)
    }
    return state
  },
  [SET_CURRENT]: (state, { payload }) => {
    return state.set('current', payload.id.toString())
  },
  [GET_SERVICE_APPROVED.FULFILLED]: (state, { payload }) => {
    const approvedBudget = state?.get('results').find(budget => budget.get('approved') === true)
    const updatedBudget = state.mergeIn(
      ['results', String(approvedBudget?.get('id'))],
      Map(payload)
    )
    return updatedBudget
  },
  [GET_REASONS_REPROVE.FULFILLED]: (state, { payload }) => {
    const normalizeReasons = normalize(payload.results, reasonToReproveSchema)
    return state.set('results', Map(normalizeReasons.entities.reasonToReprove))
  },
  [GET_CANCEL_SERVICE_APPROVED.FULFILLED]: (state, { payload }) => {
    const approvedBudget = state?.get('results').find(budget => budget.get('approved') === true)
    const updatedBudget = state.mergeIn(
      ['results', String(approvedBudget?.get('id'))],
      Map(payload)
    )
    return updatedBudget
  },
  [GET_CANCEL_SERVICE_REJECTED.FULFILLED]: (state, { payload }) => {
    const { id, ...payloadData } = payload
    const updatedBudget = state
      .mergeIn(['results', id.toString()], Map(payloadData))
      .set('sendEmailDefinition', payload.sendEmail)

    return updatedBudget
  },
  [GET_REASONS_TO_REFUSE_METABASE_SUGGESTION.FULFILLED]: (state, { payload }) => {
    const reasonList = Object.keys(payload).map(reason => ({ [reason]: payload[reason] }))
    return state.set('reasons', List(reasonList))
  },

  [SET_DEFAULT_SERVICE_ORDER_FILTER]: (
    state,
    { payload: { isAdmin, isIntermediary = false, userId, agency } }
  ) => {
    return state
      .update('filter', prevFilter =>
        Map({
          ...prevFilter.toJS(),
          ...(isAdmin && { agency }),
          ...(isIntermediary && { responsible: userId }),
        })
      )
      .set('isFilterDirty', true)
  },

  [UPDATE_SERVICE_ORDER_FILTER]: (state, { payload }) => {
    return state.update('filter', prevFilter => Map({ ...prevFilter.toJS(), ...payload }))
  },

  [GET_STEP_STATUS_FILTER.FULFILLED]: (state, { payload }) => {
    return state
      .update('stepStatusFilter', () => {
        const result = payload.map(item => item.optionsFilterServiceOrder)

        return List(result)
      })
      .update('stepStatusCanceledList', () => {
        const allCanceled = payload.filter(item => item.cancellationOptionStep)
        const result = allCanceled.map(item => item.optionsStepServiceOrder)

        return List(...result)
      })
      .update('stepStatusFinalizedList', () => {
        const allFinalized = payload.filter(item => item.finalizationOptionStep)
        const result = allFinalized.map(item => item.optionsStepServiceOrder)

        return List(...result)
      })
  },

  [GET_ALL_STEP_STATUS_FILTER_OPTIONS.FULFILLED]: (state, { payload }) => {
    return state.update('allStepStatusFilterOptions', () => {
      if (payload?.results) {
        return List(payload.results)
      }

      return List(payload)
    })
  },

  [GET_ALL_STEP_STATUS_OPTIONS.FULFILLED]: (state, { payload }) => {
    return state.update('allStepStatusOptions', () => {
      if (payload?.results) {
        return List(payload.results)
      }

      return List(payload)
    })
  },

  [GET_BUTTONS_ACTION.FULFILLED]: (state, { payload }) => {
    return state.updateIn(['buttonsAction'], () => {
      if (!payload?.results?.length) {
        return Map()
      }

      if (Array.isArray(payload?.results)) {
        return payload.results.reduce((previousButtons, currentButton) => {
          return previousButtons.setIn([currentButton.nameButton], currentButton)
        }, Map())
      }

      return Map()
    })
  },

  [GET_GENERIC_PARAMETERS.FULFILLED]: (state, { payload }) => {
    return state.updateIn(['genericParameters'], prevValue => {
      if (prevValue?.length) {
        const value = [...prevValue, ...payload]
        const formattedValue = removeDuplicatedObjectFromArray(value, 'id')
        return formattedValue
      }

      return payload
    })
  },

  [GET_REQUESTER_GENERIC_PARAMETERS.FULFILLED]: (state, { payload }) => {
    return state.updateIn(['genericParametersRequester'], () => payload)
  },

  [GET_REACTIVATE_BUTTON.FULFILLED]: (state, { payload }) => {
    return state.updateIn(['reactivateButton'], () => payload)
  },

  [SEND_BUDGET_EMAIL.FULFILLED]: (state, { payload }) => {
    const { stepLogServiceOrder: lastStepLog } = payload
    const currentServiceOrder = state.get('current')

    return state.updateIn(
      ['results', currentServiceOrder],
      values => new ServiceOrder({ ...values.toJS(), stepStatus: lastStepLog.stepStatus })
    )
  },

  [UPDATE_APPOINTMENT_DATE_SCHEDULE.FULFILLED]: (state, { payload }) => {
    return state.updateIn(['results', payload.id.toString()], () => new ServiceOrder(payload))
  },

  [GET_PAYMENT_ANTICIPATION_INFO.FULFILLED]: (state, { payload }) => {
    return state.set('paymentAnticipation', payload)
  },

  [SCHEDULE_EXECUTION.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(['results', state.current, 'stepStatus'], payload.stepStatus)
      .setIn(['results', state.current, 'datetimeExecutionScheduled'], payload.dateAndTime)
      .setIn(['results', state.current, 'dateAndTimeFinish'], payload.dateAndTimeFinish)
  },

  [CANCEL_EXECUTION.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(['results', state.current, 'stepStatus'], payload.stepStatus)
      .setIn(['results', state.current, 'executedAt'], null)
  },

  [REGISTER_EXECUTION.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(['results', state.current, 'stepStatus'], payload.stepStatus)
      .setIn(['results', state.current, 'executedAt'], payload.executedAt)
  },

  [CLOSE_SERVICE_ORDER.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(['results', state.current, 'stepStatus'], payload.stepStatus)
      .setIn(['results', state.current, 'datetimeFinished'], payload.datetimeFinished)
  },
  [UPDATE_SERVICE_ORDER_FLOWS_OPTIONS]: (state, { payload }) => {
    return state.merge({ flowsOptions: payload })
  },
  [GET_ACTIVE_BUDGET.FULFILLED]: (state, { payload }) => {
    return state.set('activeBudget', payload)
  },
})

export default serviceOrders
