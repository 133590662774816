import React, { useState, useCallback, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Alert, Button, Datagrid, Dialog, Loader, useConfirm } from '@refera/ui-web'
import { Danger as DangerIcon } from '@refera/ui-icons'

import { navigate } from '@reach/router'

import {
  paymentConfirmationColumns,
  PAYMENT_METHOD_OPTIONS,
  formatArrayToString,
  ANTICIPATIONS_PAYMENT_METHOD_OPTIONS,
} from '../utils/constants'
import { FINANCE_ROUTES, ROUTES } from '_/utils/constants'

import HeaderTitle from '_/components/header-title'
import ConfirmDateAndPaymentModal from '../components/ConfirmDateAndPaymentModal'

import {
  getVerifyInstallmentsStepStatus,
  payBudgetInstallments,
  removeBudgetInstallments,
} from '_/modules/finance/actions'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'

import useStyles from '../styles'
import { TotalAnticipationValues } from '../../components/TotalAnticipationValues'
import { formatCurrency } from '_/utils/helpers'
import { formatErrorMessage } from '../../utils/FormatErrorMessage'
import { useToast } from '_/hooks/use-toast'

const defaultInfoDialog = {
  isOpen: false,
  icon: '',
  type: '',
  subject: '',
  description: '',
}

const PaymentConfirmation = ({
  menuType,
  toggleMenu,
  selectedIds,
  items,
  handleSuccessPayment,
  fromAnticipations,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState(selectedIds || [])
  const [dialogMessage, setDialogMessage] = useState()
  const [infoDialog, setInfoDialog] = useState(defaultInfoDialog)
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const { showToast } = useToast()

  const { isConfirmed } = useConfirm()

  const handleConfirmModal = async () => {
    if (menuType === 'pay') return setModalOpen(true)
    if (menuType === 'remove') {
      setDialogMessage({
        message: 'Você tem certeza que deseja remover o pagamento dos itens selecionados?',
        type: 'remove',
      })

      const confirmed = await isConfirmed()

      if (confirmed) {
        return handleRemovePayment()
      }
    }
    return undefined
  }

  const payInstallments = async ({ payload }) => {
    setIsLoading(true)

    const batchMethods = fromAnticipations
      ? // automatic_ted, condolivre
        [
          ANTICIPATIONS_PAYMENT_METHOD_OPTIONS[2].value,
          ANTICIPATIONS_PAYMENT_METHOD_OPTIONS[3].value,
        ]
      : [PAYMENT_METHOD_OPTIONS[2].value]

    await Promise.resolve(dispatch(payBudgetInstallments(payload)))
      .then(res => {
        setModalOpen(false)
        setIsLoading(false)

        const { batch = '', errorMessage = '' } = res
        // redirect to batch if payment creates batch or go back
        batchMethods.includes(payload.paymentMethod)
          ? navigate(`${FINANCE_ROUTES.BATCHES}/${batch?.id}`, {
              state: { created: true, info: errorMessage },
            })
          : handleSuccessPayment(true)
      })
      .catch(async err => {
        setIsLoading(false)
        const error = formatErrorMessage(err)

        if (error?.includes('Você deseja continuar')) {
          setDialogMessage({
            message: error,
            type: 'info',
          })

          const confirmed = await isConfirmed()
          if (confirmed) {
            const newPayload = { ...payload, forcePayment: true }
            return payInstallments({ payload: newPayload })
          }
        } else {
          handleSuccessPayment(false, error)
        }
        return null
      })
  }

  const handleConfirmPayment = useCallback(
    data => {
      const idsToPay = selectedIds.filter(id => selectedItems.includes(id))
      const payload = {
        ids: idsToPay,
        paymentMethod: data.paymentMethod,
        datetimePaid: data.datetimePayment.toISOString(),
        screen: fromAnticipations ? 'anticipations' : 'installments',
      }

      if (data.paymentMethod === PAYMENT_METHOD_OPTIONS[2].value) {
        // if payment method is equal to automatic_ted
        if (idsToPay.length < 2) {
          setInfoDialog({
            isOpen: true,
            icon: DangerIcon,
            type: 'error',
            subject: 'Erro',
            description:
              'Não é possível processeguir com a ação. Para pagar via TED Automático é necessário pagar duas ou mais parcelas',
          })
          return
        }
      }

      payInstallments({ payload })
    },
    [payInstallments, selectedItems, selectedIds]
  )

  const handleRemovePayment = () => {
    setIsLoading(true)
    Promise.resolve(dispatch(removeBudgetInstallments({ ids: selectedIds })))
      .then(() => {
        setModalOpen(false)
        handleSuccessPayment(true)
      })
      .catch(err => {
        console.warn('error', err)
        handleSuccessPayment(false, formatErrorMessage(err) || '')
        setIsLoading(false)
      })
  }

  const totalMenuValues = useMemo(() => {
    const selectedItemsSet = new Set(selectedItems)
    const filteredItems = items.filter(item => selectedItemsSet.has(item.id))

    const totalPriceServiceProvider = formatCurrency(
      filteredItems.reduce((acc, curr) => acc + curr.providerValue, 0)
    )
    const totalValue = formatCurrency(filteredItems.reduce((acc, curr) => acc + curr.value, 0))
    const interestValue = formatCurrency(
      filteredItems.reduce((acc, curr) => acc + curr.anticipationTax, 0)
    )
    return { totalPriceServiceProvider, totalValue, interestValue }
  }, [items, selectedItems])

  const defaultDate = useMemo(
    () => (fromAnticipations ? items?.find(item => item.datetimePaid)?.datetimePaid : null),
    [fromAnticipations, items]
  )

  const handleRemoveNonPermitted = async () => {
    setIsLoading(true)
    await dispatch(getVerifyInstallmentsStepStatus({ ids: formatArrayToString(selectedItems) }))
      .then(res => {
        const { permittedIds = [] } = res
        setSelectedItems(permittedIds)
        showToast({ type: 'success' })
        setIsLoading(false)
      })
      .catch(() => {
        showToast({ type: 'error' })
        setIsLoading(false)
      })
  }

  const handleTitle = () => {
    if (menuType === 'pay') {
      return 'Confirmação do pagamento'
    }
    if (menuType === 'remove') {
      return fromAnticipations ? 'Remover aprovação' : 'Remover pagamento'
    }
    return ''
  }

  return (
    <Grid>
      <Loader hasBackdrop open={isLoading} label="Aguarde..." />
      <HeaderTitle title={handleTitle()}>
        <>
          <Grid style={{ display: 'flex', gap: '8px' }}>
            {menuType === 'pay' && (
              <Button variant="ghost" onClick={handleRemoveNonPermitted}>
                Desmarcar não aptas
              </Button>
            )}
            <Button color="red" variant="secondary" onClick={toggleMenu}>
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="primary"
              onClick={handleConfirmModal}
              disabled={selectedItems.length === 0}
            >
              Confirmar
            </Button>
          </Grid>
        </>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        {menuType === 'remove' && (
          <Alert
            severity="error"
            title="Ao clicar em confirmar, você irá remover os pagamentos selecionados da listagem."
          />
        )}
        <TotalAnticipationValues totalMenuValues={totalMenuValues} />
        <Grid className={styles.mainTable}>
          <Datagrid
            rows={items.length ? items : []}
            columns={paymentConfirmationColumns({ styles })}
            paginationMode="client"
            autoHeight={items.length < 10}
            onRowClick={params =>
              window.open(
                `${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}/orcamento/${params?.row?.budget}`,
                '_blank',
                'noopener,noreferrer'
              )
            }
            columnVisibilityModel={{ actions: false, __check__: !fromAnticipations }}
            rowCount={items?.length}
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            checkboxSelection
            disableSelectionOnClick={fromAnticipations}
            onSelectionModelChange={setSelectedItems}
            keepNonExistentRowsSelected
            selectionModel={selectedItems}
            sx={{
              fontSize: '1.4rem',
              '& .MuiDataGrid-cell:hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Grid>
      </Grid>
      {modalOpen && (
        <ConfirmDateAndPaymentModal
          open={modalOpen}
          onConfirm={handleConfirmPayment}
          onCancel={() => setModalOpen(false)}
          defaultDate={defaultDate}
        />
      )}
      {dialogMessage?.message && <ConfirmRemoveDialog {...dialogMessage} />}
      {infoDialog.isOpen && (
        <Dialog
          open={infoDialog.isOpen}
          icon={infoDialog.icon}
          type={infoDialog.type}
          subject={infoDialog.subject}
          description={infoDialog.description}
          onApprove={() => setInfoDialog(defaultInfoDialog)}
        />
      )}
    </Grid>
  )
}

export default PaymentConfirmation
