import humps from 'humps'
import { defineAction } from '_utils/redux'
import * as profiles from '_/services/profiles'
import * as userService from '_services/user'

export const GET_PROFILE_DATA_PERMISSION = defineAction('GET_PROFILE_DATA_PERMISSION')
export const GET_PROFILE_ACTION_PERMISSION = defineAction('GET_PROFILE_ACTION_PERMISSION')
export const POST_PROFILE_GROUP_PERMISSION = defineAction('POST_PROFILE_GROUP_PERMISSION')
export const PATCH_PROFILE_GROUP_PERMISSION = defineAction('PATCH_PROFILE_GROUP_PERMISSION')
export const PATCH_PROFILE_PERMISSION = defineAction('PATCH_PROFILE_PERMISSION')
export const GET_PROFILE_LIST = defineAction('GET_PROFILE_LIST')
export const GET_PROFILE = defineAction('GET_PROFILE')
export const RESEND_PASSWORD = defineAction('RESEND_PASSWORD')
export const GET_PROFILE_GROUP = defineAction('GET_PROFILE_GROUP')

export const getProfileDataPermissionList = (id, params) => async (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = await profiles.getProfilePermission(getState().user.authToken)(id, {
    type: 'data',
    ...formattedParams,
  })

  dispatch({
    type: GET_PROFILE_DATA_PERMISSION.ACTION,
    payload: async () => data,
    meta: params,
  })

  return data
}

export const getProfileActionPermissionList = (id, params) => async (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = await profiles.getProfilePermission(getState().user.authToken)(id, {
    type: 'action',
    ...formattedParams,
  })

  dispatch({
    type: GET_PROFILE_ACTION_PERMISSION.ACTION,
    payload: async () => data,
    meta: params,
  })

  return data
}

export const getProfileList = params => async (dispatch, getState) => {
  const data = await profiles.getProfileList(getState().user.authToken)(
    humps.decamelizeKeys(params)
  )

  dispatch({
    type: GET_PROFILE_LIST.ACTION,
    payload: async () => data,
    meta: params,
  })

  return data
}

export const getProfileById = id => async (dispatch, getState) => {
  const data = await profiles.getProfileById(getState().user.authToken)(id)

  dispatch({
    type: GET_PROFILE.ACTION,
    payload: async () => data,
  })

  return data
}

export const updateProfilePermission = payload => async (dispatch, getState) => {
  const data = await profiles.patchProfilePermission(getState().user.authToken)(payload)

  dispatch({
    type: PATCH_PROFILE_PERMISSION.ACTION,
    payload: async () => data,
  })

  return data
}

export const createPermissionGroup = payload => async (dispatch, getState) => {
  const data = await profiles.postProfilePermissionGroup(getState().user.authToken)(payload)

  dispatch({
    type: POST_PROFILE_GROUP_PERMISSION.ACTION,
    payload: async () => data,
  })

  return data
}

export const editPermissionGroup = (id, payload) => async (dispatch, getState) => {
  const data = await profiles.patchProfilePermissionGroup(getState().user.authToken)(id, payload)

  dispatch({
    type: PATCH_PROFILE_GROUP_PERMISSION.ACTION,
    payload: async () => data,
  })

  return data
}

export const getProfilePermissionGroup = params => async (dispatch, getState) => {
  const data = await profiles.getProfilePermissionGroup(getState().user.authToken)(
    humps.decamelizeKeys(params)
  )

  dispatch({
    type: GET_PROFILE_GROUP.ACTION,
    payload: async () => data,
    meta: params,
  })

  return data
}

export const resendPasswordAction = userId => async (dispatch, getState) => {
  const data = await userService.resendPassword(getState().user.authToken)(userId)

  dispatch({
    type: RESEND_PASSWORD.ACTION,
    payload: async () => data,
  })

  return data
}
