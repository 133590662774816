import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  modal: {
    position: 'absolute',
    zIndex: 4,
    top: '40%',
    left: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing(16),
    paddingTop: spacing(16),
  },
  dialogTitle: {
    color: Theme.Colors.Primary.Base,
    cursor: 'grab',
    width: '100%',

    '&:active': {
      cursor: 'grabbing',
    },
  },
  contentContainer: {
    width: 'auto',
    display: 'grid',
    gridTemplateColumns: props => (props.modalType === 'payment' ? '1fr 1fr 1fr' : '1fr 1fr'),
    gap: spacing(24),
  },
  listInput: {
    fontWeight: '500 !important',
    width: spacing(140),
    fontSize: spacing(14),
    display: 'flex',

    '& .MuiInputBase-input': {
      color: Theme.Colors.Grayscale.Eighty,
      padding: '8px 0px !important',
    },
  },
  datePicker: {
    '& .MuiInputBase-input': {
      color: Theme.Colors.Grayscale.OneThousand,
      padding: '9px 0px !important',
    },
    '& .MuiInput-underline': {
      borderBottom: 'none !important',
      '&:before': {
        borderBottom: props =>
          props?.errors?.paymentDate
            ? `2px solid ${Theme.Colors.Red.Base} !important`
            : `2px solid ${Theme.Colors.Grayscale.Twelve} !important`,
      },
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: `2px solid ${Theme.Colors.Grayscale.ThirtyTwo} !important`,
    },
  },
  titleBlue: {
    color: Theme.Colors.Primary.Base,
    cursor: 'grab',
    width: '100%',

    '&:active': {
      cursor: 'grabbing',
    },
  },
  totalValueContainer: {
    display: 'flex',
    width: '100%',
    gap: spacing(16),
    justifyContent: 'flex-end',
    margin: '8px 0',
  },
  totalValue: {
    fontWeight: '500 !important',
    fontSize: spacing(16),
    color: Theme.Colors.Grayscale.Eighty,
  },
  errorMessage: {
    fontSize: spacing(12),
    color: Theme.Colors.Red.Base,
    zIndex: 2,
    marginTop: spacing(4),
  },
  select: {
    color: Theme.Colors.Grayscale.Eighty,
    fontSize: spacing(16),
    borderBottom: `2px solid ${Theme.Colors.Grayscale.Twelve}`,
    padding: '6px 0px !important',
    position: 'relative',
  },

  // inconsistent installment modal
  iconModal: {
    width: spacing(40),
    height: spacing(40),
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: spacing(25),
    borderRadius: '100%',
    width: 'fit-content',
    backgroundColor: Theme.Colors.Primary.Lightest,
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(16),
    alignItems: 'center',
    padding: spacing(24, 30),
  },
  description: {
    display: 'flex',
    textAlign: 'center',
    fontSize: spacing(18),
    lineHeight: spacing(24),
  },
  dialogActionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(16),
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionButtons: {
    display: 'flex',
    gap: spacing(16),
  },
}))
