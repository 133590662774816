import { Record, Map, OrderedMap, List } from 'immutable'
import { normalize } from 'normalizr'

import { getPage } from '_utils/helpers'
import { createReducer } from '_utils/redux'
import Provider, { providerSchema } from '_models/provider'
import StaffUser from '_models/staff-user'

import {
  GET_PROVIDERS,
  GET_PROVIDERS_LIST,
  SET_TABLE_PAGE,
  GET_NEXT_PAGE_PROVIDERS,
  GET_NEXT_PAGE_LIST_PROVIDERS,
  GET_PROVIDER,
  UPDATE_PROVIDER,
  SET_FILTER,
  GET_PROVIDERS_FILTERED,
  GET_PROVIDERS_LIST_FILTERED,
  GET_PROVIDERS_WITH_NO_PAGINATION,
  CREATE_PROVIDER,
  DELETE_PROVIDER_ATTACHMENT,
  ADD_PROVIDER_STAFF_USER,
  DELETE_PROVIDER_STAFF_USER,
  UPDATE_PROVIDER_STAFF_USER,
  GET_CONNECTION_MESSAGE_TOOL,
  GET_SUGGESTIONS_PROVIDERS,
  GET_PROVIDERS_ORDERS,
} from './actions'

const INITIAL_STATE = new Record({
  count: undefined,
  next: undefined,
  previous: undefined,
  connection: List(),
  results: Map(),
  order: Map(),
  page: 0,
  size: 10,
  currentProvider: '',
  filter: Map(),
  loadedPages: List(),
  providerSuggestionsList: [],
  suggestedProvidersBody: undefined,
  messageNotAllowedToUpdate: null,
})()

export default createReducer(INITIAL_STATE, {
  [GET_PROVIDERS.FULFILLED]: (state, { payload, meta }) => {
    const { results, count, next, previous } = payload
    const normalizeProviders = normalize(results, providerSchema)
    return state
      .set('results', Map(normalizeProviders.entities.provider))
      .set('order', normalizeProviders.result)
      .set('next', next ? getPage(next) : undefined)
      .set('count', count)
      .set('previous', previous)
      .set('size', meta.pageSize)
      .set('page', 0)
      .set('loadedPages', List([1]))
  },

  [GET_PROVIDERS_LIST.FULFILLED]: (state, { payload, meta }) => {
    const { results, count, next, previous } = payload
    const normalizeProviders = normalize(results, providerSchema)
    return state
      .set('results', Map(normalizeProviders.entities.provider))
      .set('order', normalizeProviders.result)
      .set('next', next ? getPage(next) : undefined)
      .set('count', count)
      .set('previous', previous)
      .set('size', meta.pageSize)
      .set('page', 0)
      .set('loadedPages', List([1]))
  },

  [GET_PROVIDER.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(['results', payload.id], new Provider({ ...payload }).set('staffUsers', OrderedMap()))
      .set('currentProvider', payload.id)
  },
  [GET_NEXT_PAGE_PROVIDERS.FULFILLED]: (state, { payload }) => {
    const normalizeProviders = normalize(payload.results, providerSchema)
    const requestedPage = Number.isNaN(getPage(payload.next))
      ? getPage(payload.next) - 1
      : state.get('page') + 1

    return state
      .set('count', payload.count)
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .mergeIn(['results'], Map(normalizeProviders.entities.provider))
      .set('order', [...state.order, ...normalizeProviders.result])
      .setIn(
        ['loadedPages'],
        state.loadedPages.includes(requestedPage)
          ? state.loadedPages
          : state.loadedPages.push(requestedPage)
      )
  },
  [GET_NEXT_PAGE_LIST_PROVIDERS.FULFILLED]: (state, { payload }) => {
    const normalizeProviders = normalize(payload.results, providerSchema)
    const requestedPage = Number.isNaN(getPage(payload.next))
      ? getPage(payload.next) - 1
      : state.get('page') + 1

    return state
      .set('count', payload.count)
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .mergeIn(['results'], Map(normalizeProviders.entities.provider))
      .set('order', [...state.order, ...normalizeProviders.result])
      .setIn(
        ['loadedPages'],
        state.loadedPages.includes(requestedPage)
          ? state.loadedPages
          : state.loadedPages.push(requestedPage)
      )
  },
  [GET_PROVIDERS_FILTERED.FULFILLED]: (state, { payload }) => {
    const normalizeProviders = normalize(payload.results, providerSchema)

    return state
      .set('cities', payload)
      .set('count', payload.count)
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .set('order', normalizeProviders.result)
      .set('results', Map(normalizeProviders.entities.provider))
      .set('loadedPages', List([1]))
      .set('page', 0)
  },

  [GET_PROVIDERS_LIST_FILTERED.FULFILLED]: (state, { payload }) => {
    const normalizeProviders = normalize(payload.results, providerSchema)

    return state
      .set('cities', payload)
      .set('count', payload.count)
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .set('order', normalizeProviders.result)
      .set('results', Map(normalizeProviders.entities.provider))
      .set('loadedPages', List([1]))
      .set('page', 0)
  },

  [GET_PROVIDERS_WITH_NO_PAGINATION.FULFILLED]: (state, { payload }) => {
    const normalizeProviders = normalize(payload, providerSchema)
    return state
      .set('order', normalizeProviders.result)
      .set('results', Map(normalizeProviders.entities.provider))
  },

  [SET_TABLE_PAGE]: (state, { payload }) => {
    return state.set('page', payload.page)
  },

  [UPDATE_PROVIDER.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(['results', payload.id], new Provider(payload))
      .set('currentProvider', payload.id)
  },
  [DELETE_PROVIDER_ATTACHMENT.FULFILLED]: (state, { meta: { attachmentId, providerId } }) => {
    const attachments = state
      .getIn(['results', providerId, 'companyAttachments'])
      .filter(file => file.id !== attachmentId)
    return state.setIn(['results', providerId, 'companyAttachments'], attachments)
  },

  [CREATE_PROVIDER.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(['results', payload.id], new Provider(payload))
      .set('currentProvider', payload.id)
  },

  [UPDATE_PROVIDER_STAFF_USER.FULFILLED]: (state, { payload, meta: { providerId } }) => {
    return state.setIn(
      ['results', providerId, 'staffUsers', payload.id],
      new StaffUser({ ...payload })
    )
  },
  [ADD_PROVIDER_STAFF_USER.FULFILLED]: (state, { payload, meta: { providerId } }) => {
    return state.setIn(
      ['results', providerId, 'staffUsers', payload.id],
      new StaffUser({ ...payload })
    )
  },
  [DELETE_PROVIDER_STAFF_USER.FULFILLED]: (state, { meta: { providerId, staffId } }) => {
    return state.deleteIn(['results', providerId, 'staffUsers', staffId])
  },

  [SET_FILTER]: (state, { payload }) => {
    const { name, value } = payload
    return state.mergeIn(['filter'], { [name]: value })
  },
  [GET_CONNECTION_MESSAGE_TOOL.FULFILLED]: (state, { payload }) => {
    return state.setIn(['connection'], List(payload.results))
  },
  [GET_SUGGESTIONS_PROVIDERS.FULFILLED]: (state, { payload }) => {
    const { suggestedProviders, providers } = payload
    return state
      .set('providerSuggestionsList', providers)
      .set('suggestedProvidersBody', suggestedProviders)
  },
  [GET_PROVIDERS_ORDERS.FULFILLED]: (state, { payload }) => {
    return state.set('providerOrdersId', payload)
  },
})
