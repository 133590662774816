/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
} from '@material-ui/core'

import { IconButton, Alert as ReferaAlert, Toast } from '@refera/ui-web'
import { CloseCircle } from '@refera/ui-icons'
import Theme from '@refera/ui-core'

import Button from '_components/button'
import DollarCircleIcon from '_assets/icons/ic-dollar-circle-outlined.svg'
import Svg from '_/components/svg'
import useStyles from './styles'
import { getServiceOrder, updateDistanceBudget } from '_modules/service-orders/actions'
import { getBudgetByServiceOrder, getStepStatusLog } from '_modules/budget/actions'
import { useParams } from '@reach/router'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { getHistoryLogs } from '_modules/history-logs/actions'
import { formatAddress } from '_utils/helpers'
import { useToast } from '_/hooks/use-toast'

const BudgetWithoutVisitDialog = ({ modalDialog, setModalDialog, user }) => {
  const classes = useStyles()
  const { serviceOrderId } = useParams()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const [toastOpen, setToastOpen] = useState(false)
  const [toastInfo, setToastInfo] = useState({})
  const dispatch = useDispatch()
  const [option, setOption] = useState('')
  const { showToast } = useToast()

  const handleSuccess = useCallback(() => {
    setToastOpen(true)
    setToastInfo({ type: 'success', message: 'Ação executada com sucesso.' })
    dispatch(getHistoryLogs(serviceOrderId))
    if (option) {
      setTimeout(() => {
        dispatch(getBudgetByServiceOrder(serviceOrderId))
      }, 3000)
    } else {
      setTimeout(() => {
        dispatch(getServiceOrder(serviceOrderId))
        dispatch(getStepStatusLog(serviceOrderId))
        sendWhatsApp()
      }, 3000)
    }
  }, [setToastOpen, setToastInfo, serviceOrder?.distanceBudget, serviceOrderId])

  const handleFailure = useCallback(error => {
    setToastOpen(true)
    if (error?.distance_error) {
      showToast({
        message: `Você não pode aceitar chamados da categoria ${
          serviceOrder?.category.toJS()[0]?.name
        } escolhendo
        a opção de orçamento sem visita. Selecione outra opção de agendamento de visita para
        prosseguir.`,
        type: 'error',
      })
    } else {
      setToastInfo({
        type: 'error',
        message: 'Não foi possível executar esta ação, tente novamente.',
      })
    }
  }, [])

  const onSubmit = useCallback(
    value => {
      setOption(value)
      const payload = {
        distanceBudget: value,
      }
      dispatch(updateDistanceBudget({ ...payload }))
        .then(() => handleSuccess())
        .catch(error => handleFailure(error))
      handleCloseModal()
    },
    [serviceOrder]
  )

  const handleCloseModal = () => {
    setModalDialog({ ...modalDialog, isOpen: false })
    if (modalDialog?.onClick) modalDialog?.onClick()
  }

  const sendWhatsApp = useCallback(() => {
    const agency = `${serviceOrder?.agency.get('name')}`
    const fullAddress = formatAddress(serviceOrder?.property, false, true, false)

    const text =
      `Olá, sou o prestador ${user?.companyName}, parceiro da Refera!\n\n` +
      `Estou entrando em contato com você para informar que farei o orçamento do chamado ${serviceOrderId} à distância. ` +
      `Esse chamado está vinculado a intermediária ${agency} e refere-se ao imóvel localizado no endereço abaixo.\n` +
      `${fullAddress}`

    const encodedText = window.encodeURIComponent(text)
    const celular = `${serviceOrder.contactNumber?.toString().slice(1)}`

    window.open(`https://web.whatsapp.com/send?phone=${celular}&text=${encodedText}`, '_blank')
  }, [serviceOrder])

  return (
    <Grid>
      <Dialog open={modalDialog?.isOpen} classes={{ paper: classes.dialog }}>
        <DialogTitle className={classes.dialogTitle}>
          <Box mt={5} />
          <Button disabled className={classes.titleIconWarning}>
            <Svg icon={DollarCircleIcon} className={classes.iconSVG} />
          </Button>
          <IconButton variant="ghost" onClick={handleCloseModal} className={classes.referaButton}>
            <CloseCircle color={Theme.Colors.Grayscale.SixtyFour} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h6">{modalDialog?.title}</Typography>
          <Box mt={20} />
          <Typography variant="">{modalDialog?.subTitle}</Typography>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            variant="outlined"
            color="red"
            className={classes.button}
            onClick={() => onSubmit(false)}
          >
            {modalDialog?.textOkButton ? modalDialog?.textOkButton : 'Não'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => onSubmit(true)}
          >
            {modalDialog?.textOkButton ? modalDialog?.textOkButton : 'Sim'}
          </Button>
        </DialogActions>
      </Dialog>
      <Toast
        draggable
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => {
          setToastOpen(false)
          setToastInfo({})
        }}
      >
        <ReferaAlert
          severity={toastInfo.type}
          title={toastInfo.message}
          onClose={() => {
            setToastOpen(false)
            setToastInfo({})
          }}
        />
      </Toast>
    </Grid>
  )
}

export default BudgetWithoutVisitDialog
