import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  container: {
    margin: '10px',
    height: '320px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  noContainer: {
    display: 'flex',
    alignItems: 'justify',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '-100px',
    marginLeft: '32%',
    marginRight: '25%',
  },
  image: {
    width: '60px',
    height: '60px',
    margin: '20px',
  },
  title: {
    width: '498px',
    height: '40px',
    left: '471px',
    top: '216px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '22px',
    lineHeight: '40px',
    color: '#141414',
  },
  subTitle: {
    fontSize: '13px',
    lineHeight: '24px',
    color: '#696969',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    textAlign: 'center',
    width: '400px',
    height: '47px',
    left: '440px',
    fontFamily: 'Roboto',
  },
  textTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '30px',
    fontWeight: '500',
    fontSize: '13px',
    color: 'black',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  button: {
    height: '40px',
    width: '272px',
    padding: '10px 16.5px',
    borderRadius: '24px',
    margin: '15px',
    alignItems: 'center',
  },
  optional: {
    fontSize: '10px',
    color: '#696969',
    fontFamily: 'Roboto',
  },
  stars: {},
  iconWhatsApp: {
    width: '20px',
    marginRight: '6px',
  },
  whatsButton: {
    background: palette.green.light,
    marginTop: '8%',
    marginLeft: '45%',
    color: 'white',
  },
  textRefera: {
    color: '#696969',
    position: 'relative',
    Width: '368px',
    Height: '20px',
    marginTop: '217px',
    textAlign: 'center',
  },
}))
