import { makeStyles } from '@material-ui/styles'

import { theme } from '../theme'

export default makeStyles(({ spacing, breakpoints }) => ({
  backButton: {
    padding: spacing(10),
    textTransform: 'capitalize',
    borderRadius: spacing(24),
    color: theme.palette.gray[80],
  },
  button: {
    padding: spacing(10),
    textTransform: 'capitalize',
    borderRadius: spacing(24),
  },
  buttons: {
    width: '100%',
    display: 'flex',
    gap: 15,
    marginTop: '1rem',

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    height: '1.2rem',
  },
}))
