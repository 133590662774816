import React from 'react'
import { BaseContractModal } from '_components/modal/contracts/base'
import { Box } from '@mui/material'

function ContractNotSignedYetModal({
  onConfirm = () => {},
  onCancel = () => {},
  deadlineDate = '',
}) {
  return (
    <BaseContractModal.Root>
      <BaseContractModal.Icon iconType="contract" sx={{ marginTop: '24px' }} />
      <Box display="flex" flexDirection="column" width="100%" marginTop="24px">
        <BaseContractModal.Header variant="variant2" sx={{ margin: '0 22px' }}>
          Atenção! Seu contrato com a Refera ainda não foi assinado!
        </BaseContractModal.Header>
        <BaseContractModal.Paragraph sx={{ margin: '16px 32px 0' }}>
          Foi detectado que sua empresa ainda não tem um contrato assinado com a Refera. Este
          documento deve ser assinado até <strong>{deadlineDate}.</strong>
          <br />
          <br />
          Deseja proceder com a análise do contrato e sua assinatura?
        </BaseContractModal.Paragraph>
      </Box>
      <BaseContractModal.ActionButtonContainer sx={{ margin: '24px 0 16px' }}>
        <BaseContractModal.ActionButton
          variant="secondary"
          sx={{ width: '165px' }}
          color="red"
          onClick={onCancel}
        >
          Não
        </BaseContractModal.ActionButton>
        <BaseContractModal.ActionButton
          variant="primary"
          onClick={onConfirm}
          sx={{ width: '165px' }}
        >
          Sim
        </BaseContractModal.ActionButton>
      </BaseContractModal.ActionButtonContainer>
    </BaseContractModal.Root>
  )
}

export default ContractNotSignedYetModal
