import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from '_/hooks/use-toast'

import { Grid } from '@mui/material'
import { Datagrid } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'

import { profileColumns, TABLE_SX } from './constants'
import { navigate } from '@reach/router'
import { ROUTES } from '_/utils/constants'
import { getProfilePermissionGroup } from '_/modules/profile/actions'
import { getPermissionsGroupSelector } from '_/modules/profile/selectors'
import Button from '_/components/button'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

export function PermissionGroup() {
  const dispatch = useDispatch()
  const styles = useStyles()

  const { checkIfUserDoesNotPermission } = useRolePermission()

  const [isLoading, setIsLoading] = useState(false)

  const groupsPermission = useSelector(getPermissionsGroupSelector)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [orderBy, setOrderBy] = useState('desc')
  const [formStateDefault, setFormStateDefault] = useState(groupsPermission?.results || [])

  const { showToast } = useToast()

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('desc')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const getPermissionsGroup = useCallback(() => {
    setIsLoading(true)

    return dispatch(getProfilePermissionGroup({ pageSize, page: page + 1, orderBy }))
      .then(data => {
        setFormStateDefault(data?.results || [])
        return setIsLoading(false)
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
        return setIsLoading(false)
      })
  }, [dispatch, orderBy, page, pageSize])

  const handleCellClick = useCallback(({ row }) => {
    if (
      checkIfUserDoesNotPermission(PERMISSIONS.PERMISSION_GROUPS, [
        PERMISSIONS_ACTIONS.ADD,
        PERMISSIONS_ACTIONS.EDIT,
      ])
    ) {
      return
    }

    navigate(`${ROUTES.MANAGER_PERMISSIONS}/${row?.id}`)
  }, [])

  const handleNewButtonClick = useCallback(() => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.PERMISSION_GROUPS, PERMISSIONS_ACTIONS.ADD)) {
      return
    }

    navigate(`${ROUTES.MANAGER_PERMISSIONS}`)
  }, [])

  const goBack = () => {
    window?.history?.length <= 2 ? navigate(`/`) : navigate(-1)
  }

  useEffect(() => {
    getPermissionsGroup()
  }, [orderBy, page, pageSize])

  return (
    <Grid className={styles.page}>
      <HeaderTitle title="Grupos de permissões" backButtonAction={goBack}>
        <Grid style={{ display: 'flex', gap: '8px' }}>
          <Button className={styles.button} form="add-permissions" onClick={handleNewButtonClick}>
            Novo
          </Button>
        </Grid>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'desc', sort: 'asc' }],
              },
            }}
            loading={isLoading}
            rows={formStateDefault}
            columns={profileColumns()}
            onCellClick={handleCellClick}
            rowCount={groupsPermission?.count || 0}
            sx={TABLE_SX}
            noRowsLabel="Nenhum resultado encontrado"
            paginationMode="server"
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            onSortModelChange={handleOrderBy}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
