import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  children: {
    gridArea: 'children',
    overflowX: 'hidden',
  },
  app: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    flex: 1,
    position: 'relative',
    height: '100%',
  },
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: spacing(60),
    gap: spacing(40),
  },
  input: {
    maxWidth: spacing(450),
  },
  label: {
    color: palette.gray[64],
    fontSize: spacing(12),
  },
  button: {
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,
    padding: spacing(8, 16),
    margin: '0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    minWidth: spacing(120),

    backgroundColor: 'transparent',
    fontSize: spacing(14),
    borderRadius: '75px',

    '&:not(:disabled):hover': {
      opacity: '0.8',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'visible',
      color: palette.gray[32],
      backgroundColor: palette.gray[4],
    },
  },
  buttonRed: {
    background: 'transparent',
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,

    '&:not(:disabled):hover': {
      background: palette.error.main,
      color: palette.gray[4],
    },

    '&:disabled': {
      border: `1px solid ${palette.error.main}`,
    },
  },
  buttonFullBlue: {
    background: palette.primary.main,
    color: palette.gray[4],
    border: 'none',

    '&:not(:disabled):hover': {
      background: palette.primary.main,
      opacity: '0.8',
    },

    '&:disabled': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
  buttonBlue: {
    background: 'transparent',
    color: palette.primary.main,
    border: `1px solid ${palette.primary.main}`,

    '&:not(:disabled):hover': {
      background: palette.primary.main,
      color: palette.gray[4],
    },

    '&:disabled': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
}))
