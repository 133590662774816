import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from '_/hooks/use-toast'

import { Button, Grid } from '@mui/material'
import { Datagrid } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'

import { profileColumns, TABLE_SX } from './constants'
import { navigate, useParams } from '@reach/router'

import {
  getProfileActionPermissionList,
  getProfileById,
  updateProfilePermission,
} from '_/modules/profile/actions'
import {
  getProfileActionPermissionsSelector,
  getProfileSelector,
} from '_/modules/profile/selectors'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

export function ProfilePermissionsActions() {
  const profilesActions = useSelector(getProfileActionPermissionsSelector)
  const profile = useSelector(getProfileSelector)

  const { checkIfUserDoesNotPermission } = useRolePermission()

  const { perfilId } = useParams()

  const dispatch = useDispatch()
  const styles = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [orderBy, setOrderBy] = useState('permissionName')
  const [formState, setFormState] = useState([])
  const [formStateDefault, setFormStateDefault] = useState(profilesActions?.results || [])

  function handleSetValue(row, field, value) {
    setFormState(prevState => {
      const itemIndex = prevState.findIndex(item => item.id === row?.id)
      if (itemIndex === -1) {
        // If the item doesn't exist, add it to the array
        return [
          ...prevState,
          {
            id: row?.id,
            [field]: value,
          },
        ]
      }
      // If the item exists, update it
      return prevState.map((item, index) =>
        index === itemIndex ? { ...item, [field]: value } : item
      )
    })
  }

  const { showToast } = useToast()

  const getProfilesActions = useCallback(() => {
    setIsLoading(true)

    return dispatch(getProfileActionPermissionList(perfilId, { pageSize, page: page + 1, orderBy }))
      .then(data => {
        setFormStateDefault(data?.results || [])
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
      })
      .finally(() => setIsLoading(false))
  }, [dispatch, pageSize, page, orderBy, perfilId])

  const getProfile = useCallback(() => {
    setIsLoading(true)

    return dispatch(getProfileById(perfilId))
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os profiles.' })
      })
      .finally(() => setIsLoading(false))
  }, [dispatch, perfilId])

  const goBack = () => {
    window?.history?.length <= 2 ? navigate(`/`) : navigate(-1)
  }

  const perfilName = useMemo(() => {
    const name = profile?.desc

    return name ?? ''
  }, [profile, perfilId])

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('permissionName')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const handleClickSave = useCallback(() => {
    if (
      checkIfUserDoesNotPermission(PERMISSIONS.PROFILE_PERMISSIONS, [
        PERMISSIONS_ACTIONS.ADD,
        PERMISSIONS_ACTIONS.EDIT,
      ])
    ) {
      return
    }

    const newData = formState.map(item => {
      const newItem = { ...item }
      if (item?.update !== undefined) {
        newItem.create = item?.update
      }
      return newItem
    })

    dispatch(
      updateProfilePermission({
        permissions: newData,
      })
    )
      .then(() => {
        showToast({
          type: 'success',
          message: 'Campos salvos com sucesso.',
        })
        return setIsLoading(false)
      })
      .catch(() => {
        showToast({
          type: 'error',
          message: 'Ocorreu um erro ao salvar os dados. Tente novamente mais tarde.',
        })
        return setIsLoading(false)
      })
  }, [formState, perfilId])

  function handleClickCancel() {
    setIsLoading(true)
    setFormState([])
    setFormStateDefault([])
    getProfilesActions()
  }

  useEffect(() => {
    getProfilesActions()
  }, [orderBy, page, pageSize])

  useEffect(() => {
    getProfile()
  }, [perfilId])

  return (
    <Grid className={styles.page}>
      <HeaderTitle
        title={`Permissões de acesso a ações do Perfil ${perfilName}`}
        backButtonAction={goBack}
      >
        <Grid style={{ display: 'flex', gap: '8px' }}>
          <Button className={[styles.buttonRed, styles.button]} onClick={() => handleClickCancel()}>
            Cancelar
          </Button>
          <Button className={[styles.buttonFullBlue, styles.button]} onClick={handleClickSave}>
            Salvar
          </Button>
        </Grid>
      </HeaderTitle>

      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'permissionName', sort: 'asc' }],
              },
            }}
            loading={isLoading}
            rows={formStateDefault}
            columns={profileColumns(handleSetValue, formState)}
            rowCount={profilesActions?.count || 0}
            sx={TABLE_SX}
            noRowsLabel="Nenhum resultado encontrado"
            paginationMode="server"
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            onSortModelChange={handleOrderBy}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
