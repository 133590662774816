import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: props => (props.fullscreen ? '100vh' : '85vh'),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette.primary.main,
    height: spacing(72),
    width: '100%',
    padding: spacing(0, 26.67),
    position: 'absolute',
  },
  logoIcon: {
    width: spacing(74.67),
    height: spacing(32),
  },
  mainSvg: {
    fontSize: props => (props.size ? props.size : '333px'),
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(10),

    [breakpoints.down('sm')]: {
      padding: spacing(15),
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(10),
    maxWidth: spacing(600),

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(30),
    flexDirection: 'column',
    maxWidth: spacing(800),
  },
  icon: {
    fontSize: spacing(30),
  },
  message: {
    color: palette.primary.main,
    fontSize: spacing(27),
    lineHeight: '1.2',
    textAlign: 'center',
  },
  messageContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    lineHeight: '1.5',
    textAlign: 'center',
  },
  date: {
    paddingTop: '10px',
  },
  support: {
    flexDirection: 'row',
    paddingTop: '15px',
    maxWidth: '29vh',
    lineHeight: '1.5',
  },
  link: {
    color: 'black',
    textDecoration: 'underline',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: palette.primary.lightest,
    },
  },
}))
