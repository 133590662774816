import { Grid } from '@mui/material'
import * as Icons from '@refera/ui-icons'
import { Button, Typography } from '@refera/ui-web'
import Theme from '@refera/ui-core'
import React, { useCallback } from 'react'
import { ROUTES } from '_utils/constants'
import moment from 'moment'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { readNotifications } from '_modules/notifications/actions'
import { userSelector } from '_modules/authentication/selectors'
import { STATUS_RATING } from '_/utils/constants/service-order'

const NotificationOptions = ({ notification }) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const IconElement = Icons[notification.icon]
  const whiteFontIcons = ['red', 'blue', '#1671ED']
  const { getRoles } = useSelector(userSelector).toJS()

  const handleGoToServiceOrder = useCallback(
    currentServiceOrder => {
      dispatch(readNotifications(notification.id))

      if (
        notification?.type === STATUS_RATING.SERVICE_APPROVED ||
        notification?.type === STATUS_RATING.SERVICE_REPROVED
      ) {
        window.open(
          `${ROUTES.DETAIL_SERVICE_ORDER}/${currentServiceOrder}`,
          '_blank',
          'noopener,noreferrer'
        )
      }
      if (getRoles.includes('tradesman') && notification.type !== 'service_order_lost')
        window.open(
          `${ROUTES.DETAIL_SERVICE_ORDER}/${currentServiceOrder}`,
          '_blank',
          'noopener,noreferrer'
        )
      if (!getRoles.includes('tradesman'))
        window.open(
          `${ROUTES.SERVICE_ORDER}/${currentServiceOrder}`,
          '_blank',
          'noopener,noreferrer'
        )
    },
    [getRoles, notification]
  )

  const handleFormatDate = date => {
    let formattedDate = moment(moment.now())

    if (formattedDate.diff(date, 'minutes') < 1) {
      return 'Agora'
    }

    if (formattedDate.diff(date, 'minutes') < 60) {
      return `Há ${formattedDate.diff(date, 'minutes')}min`
    }

    if (formattedDate.diff(date, 'hours') < 24) {
      return `Há ${formattedDate.diff(date, 'hours')}h`
    }

    if (formattedDate.diff(date, 'months') < 1) {
      formattedDate = formattedDate.diff(date, 'days')
      return `Há ${formattedDate} ${formattedDate === 1 ? 'dia' : 'dias'}`
    }

    formattedDate = formattedDate.diff(date, 'months')
    return `Há ${formattedDate} ${formattedDate === 1 ? 'mês' : 'meses'}`
  }

  return (
    <Grid className={styles.container}>
      <Grid>
        <Grid className={styles.icon} backgroundColor={notification.iconColor}>
          <IconElement
            fontSize={20}
            color={
              whiteFontIcons.some(value => value === notification.iconColor)
                ? Theme.Colors.Grayscale.Zero
                : Theme.Colors.Grayscale.Eighty
            }
          />
        </Grid>
      </Grid>
      <Grid className={styles.wrapper}>
        <Typography className={styles.title}>
          [{notification.serviceOrder}] - {notification.title}
        </Typography>
        <Typography className={styles.description}>{notification.description}</Typography>
        {notification.actionToBeTaken && (
          <Button
            variant="ghost"
            className={styles.link}
            onClick={() => handleGoToServiceOrder(notification.serviceOrder)}
            endIcon={<Icons.ArrowRight3 color={Theme.Colors.Grayscale.ThirtyTwo} />}
          >
            {notification.actionToBeTaken}
          </Button>
        )}
        <Typography className={styles.date}>
          {handleFormatDate(notification.datetimeCreation)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default NotificationOptions
