import { createReducer } from '_utils/redux'
import { GET_MENU_ITEMS } from './actions'
import { List, Map } from 'immutable'

const INITIAL_STATE = Map({
  results: List(),
})

export default createReducer(INITIAL_STATE, {
  [GET_MENU_ITEMS.FULFILLED]: (state, { payload }) =>
    state.update('results', () =>
      List(
        payload.results
          .map(item => item.menuitem)
          .filter((menuitem, index, distinctMenus) => {
            return distinctMenus.findIndex(item => item.id === menuitem.id) === index
          })
      )
    ),
})
