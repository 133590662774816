import { GET_BANK_LIST, GET_CITIES, GET_CSREFERA, GET_STATES } from '_modules/utils/actions'

export const getCitiesLoading = state => !!state.loading.get(GET_CITIES.ACTION)
export const getStatesLoading = state => !!state.loading.get(GET_STATES.ACTION)
export const getCitiesSelector = state => state.utils.cities

export const getArticlesSelector = state => state.utils.articles

export const getCitiesOptions = state =>
  state.utils.cities
    ?.valueSeq()
    ?.toArray()
    .sort((a, b) => a.name.localeCompare(b.name))
    ?.map(value => ({
      label: value.name,
      value: value.slug,
      state: value.state,
    }))

export const getStatesOptions = state =>
  state.utils.states
    ?.valueSeq()
    ?.toArray()
    .sort((a, b) => a?.name.localeCompare(b?.name))
    ?.map(value => ({
      label: value.name,
      value: value.uf,
    }))

export const getSortedCitiesOptionsSelector = state =>
  state.utils.cities
    ?.valueSeq()
    ?.toArray()
    ?.sort(
      (a, b) =>
        a.state?.toJS()?.uf?.localeCompare(b.state?.toJS()?.uf) || a.name.localeCompare(b.name)
    )
    .map(city => ({
      value: city.name,
      label: `${city.state.toJS().uf} - ${city.name}`,
    }))

export const getCsreferaLoading = state => !!state.loading.get(GET_CSREFERA.ACTION)
export const getCsreferaSelector = state => state.utils.csrefera

export const getSectorsOptionsSelector = state => state.utils.sectorsOptions

export const getCsreferaOptions = state =>
  state.utils.csrefera
    ?.valueSeq()
    ?.toArray()
    ?.map(value => ({
      name: value.name,
      email: value.email,
      id: value.id,
    }))

export const banksSelector = state => state.utils.banks
export const bankOptionSelector = state =>
  state.utils.banks
    .valueSeq()
    .toArray()
    .map(value => ({
      label: value.name,
      value: value.id,
    }))
export const banksLoadingSelector = state => !!state.loading.get(GET_BANK_LIST.ACTION)

export const snackBarMessageSelector = state => state.utils?.get('snackbarError')
export const cancelReasonsSelector = state => state.utils?.get('cancel')
export const cancelReasonsServiceSelector = state => state.utils?.get('cancelReason')
export const showContractModalManagerSelector = state =>
  state.utils?.get('showContractModalManager')

export const roleListSelector = state => state.utils?.get('roleList')
export const roleGroupListSelector = state => state.utils?.get('roleGroupList')
