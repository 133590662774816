import { createReducer } from '_utils/redux'
import { GET_BUSINESSTIME } from './actions'
import { List, Record } from 'immutable'

const INITIAL_STATE = new Record({
  results: List(),
})()

const businessTimes = createReducer(INITIAL_STATE, {
  [GET_BUSINESSTIME.FULFILLED]: (state, { payload }) => {
    return state.set('results', List(payload))
  },
})

export default businessTimes
