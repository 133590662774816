import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core'

import useStyles from './styles'

export default function MessageQueueDialog({ closeModal }) {
  const styles = useStyles()

  return (
    <Dialog open onClose={closeModal}>
      <DialogContent>
        <DialogContentText className={styles.description}>
          A mensagem está na fila de envio.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

MessageQueueDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
}
