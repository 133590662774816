import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${Theme.Colors.Grayscale.Zero}`,
    borderRadius: '8px',
    width: '156px',
    minHeight: '140px',
    overflow: 'hidden',
  },
  button: {
    flex: 1,
    borderRadius: 0,

    '&:hover': {
      backgroundColor: Theme.Colors.Grayscale.Four,
    },
  },
  mainContent: {
    display: 'grid',
    gridTemplateAreas: "'icon number number .' 'description description description arrowIcon'",
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: spacing(40, 32),
    rowGap: spacing(8),
    columnGap: spacing(16),
    height: '100%',
    marginBottom: 'auto',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing(8),
  },
  icon: {
    gridArea: 'icon',
    width: '40px',
    height: '40px',
  },
  gif: {
    gridArea: 'icon',
    width: '60px',
    height: '60px',
    borderRadius: 100,
    marginLeft: '-10px',
    marginRight: '-10px',
  },
  smallIcon: {
    gridArea: 'icon',
    width: '16px',
    height: '16px',
  },
  number: {
    gridArea: 'number',
    fontSize: Theme.Typography.FontSize.XLarge,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    color: Theme.Colors.Grayscale.OneThousand,
    fontWeight: Theme.Typography.FontWeight.Medium,
  },
  description: {
    gridArea: 'description',
    flex: 1,
    fontSize: Theme.Typography.FontSize.Tiny,
    textAlign: 'left',
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
  },
  redDescription: {
    gridArea: 'description',
    flex: 1,
    fontSize: Theme.Typography.FontSize.Tiny,
    textAlign: 'left',
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    color: Theme.Colors.Red.Base,
  },
  redColor: {
    color: Theme.Colors.Red.Base,
  },
  arrowIcon: {
    gridArea: 'arrowIcon',
    width: '16px',
    height: '16px',
    color: Theme.Colors.Grayscale.SixtyFour,
  },
}))
