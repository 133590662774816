import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  container: {
    fontSize: '3rem',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: palette.gray[32],
    marginBottom: spacing(6),
  },
  button: {
    width: spacing(154),
    height: spacing(40),
    fontWeight: 'normal',
    fontSize: spacing(15),
  },
}))
