import React, { useMemo } from 'react'

// Icons
import { Danger as DangerIcon, TickCircle as TickCircleIcon } from '@refera/ui-icons'

// Components
import { Dialog } from '@refera/ui-web'

export default ({ isOpen, type, subject, description, onApprove }) => {
  const Icon = useMemo(() => (type === 'success' ? TickCircleIcon : DangerIcon), [type])
  return (
    <Dialog
      open={isOpen}
      icon={Icon}
      type={type || 'info'}
      subject={subject}
      description={description}
      onApprove={() => onApprove()}
    />
  )
}
