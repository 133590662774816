import { useParams } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'

import BudgetPDF from '_views/service-order/budget-pdf'
import { getBudgetByIdSelector } from '_modules/budget/selectors'
import { getAgency } from '_modules/agency/actions'
import { getFormattedAddress } from '_modules/property/actions'
import { getAgencyLogoSelector, getAgencyShowReferaValue } from '_modules/agency/selectors'
import { getFormattedAddressSelector } from '_modules/property/selectors'
import { Button } from '@refera/ui-web'
import useBudgetPdfFileName from '_hooks/use-budget-pdf-file-name'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import Loading from '_components/loading'
import useStyles from './styles'

// FIXME: Fix prop drilling on BudgetPDF
const DownloadPdfButton = ({ installments, onCancel }) => {
  const { budgetId } = useParams()
  const dispatch = useDispatch()
  const styles = useStyles()

  const serviceOrder = useSelector(currentServiceOrderSelector)
  const budget = useSelector(getBudgetByIdSelector(budgetId))?.toJS()
  const formattedAddress = useSelector(getFormattedAddressSelector)
  const agencyLogo = useSelector(getAgencyLogoSelector)
  const showReferaValue = useSelector(getAgencyShowReferaValue)

  const pdfFileName = useBudgetPdfFileName({ budgetId })
  const [isLoading, setIsLoading] = useState(false)
  const [isAddressLoading, setAddressLoading] = useState(false)

  useEffect(() => {
    if (!formattedAddress && !isAddressLoading) {
      setAddressLoading(true)
      dispatch(getFormattedAddress(serviceOrder.property.get('id'))).finally(() => {
        setAddressLoading(false)
      })
    }
  }, [dispatch, serviceOrder, formattedAddress, isAddressLoading])

  useEffect(() => {
    if (serviceOrder?.agency?.toJS()?.id && !agencyLogo && !showReferaValue) {
      setIsLoading(true)
      dispatch(getAgency(serviceOrder?.agency?.toJS()?.id))
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false))
    }
  }, [agencyLogo, serviceOrder?.agency?.toJS()?.id, showReferaValue])

  if (!Object.keys(budget).length > 0 && formattedAddress && budget.createdAt) {
    return null
  }

  if (isLoading) {
    return (
      <div className={styles.loaderOverlay}>
        <Loading className={styles.loader} />
      </div>
    )
  }

  return (
    <PDFDownloadLink
      disabled
      fileName={pdfFileName}
      document={
        <BudgetPDF
          budget={budget}
          property={formattedAddress}
          serviceOrder={serviceOrder}
          agencyLogo={agencyLogo}
          dateBudget={budget.createdAt}
          installments={installments}
          showReferaValue={showReferaValue}
        />
      }
    >
      <Button
        onClick={() => {
          // eslint-disable-next-line func-names
          setTimeout(function () {
            onCancel()
          }, 1200)
        }}
        disabled={installments?.length < 1}
        variant="contained"
        color="primary"
      >
        Baixar
      </Button>
    </PDFDownloadLink>
  )
}

export default DownloadPdfButton
