import { navigate, useLocation } from '@reach/router'
import useRolePermission from '_/hooks/use-role-permission'
import { useToast } from '_/hooks/use-toast'
import { ROUTES } from '_/utils/constants'
import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'

function DashboardReport() {
  const userToken = useSelector(x => x.user.get('authToken'))
  const { pathname } = useLocation()
  const { checkPathPermission } = useRolePermission()
  const { showToast } = useToast()

  const reportUrl = useMemo(
    () =>
      `${process.env.REACT_APP_URL_DATASTUDIO}?params=${encodeURI(
        JSON.stringify({ token: userToken })
      )}`,
    [userToken]
  )

  useEffect(() => {
    if (!checkPathPermission(pathname)) {
      showToast({
        type: 'error',
        message:
          'Você não tem permissão para visualizar os dados desta área da aplicação. Por favor, fale com seu administrador.',
      })
      navigate(ROUTES.WIDGETS)
    }
  }, [pathname, checkPathPermission])

  const renderContent = useMemo(
    () => (
      <div
        style={{
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          height: '100vh',
          width: '100%',
          position: 'relative',
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={reportUrl}
          frameBorder="0"
          style={{
            border: 0,
            width: '100%',
            height: '100%',
          }}
          allowFullScreen
        />
      </div>
    ),
    [reportUrl]
  )

  return <div>{renderContent}</div>
}

export default DashboardReport
