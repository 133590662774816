import { defineAction } from '_/utils/redux'

import * as businessFrontService from '_services/businessFront'

export const GET_BUSINESSFRONTS = defineAction('GET_BUSINESSFRONTS')
export const GET_USER_AGENCY_FLOWS = defineAction('GET_USER_AGENCY_FLOWS')

export const getBusinessFronts = () => (dispatch, getState) =>
  dispatch({
    type: GET_BUSINESSFRONTS.ACTION,
    payload: businessFrontService.getBusinessFronts(getState().user.authToken),
  })

export const getUserAgencyFlows = () => (dispatch, getState) =>
  dispatch({
    type: GET_USER_AGENCY_FLOWS.ACTION,
    payload: businessFrontService.getUserAgencyFlows(getState().user.authToken),
  })
