import { createReducer } from '_utils/redux'
import {
  GET_MESSAGE_OPTIONS,
  GET_MESSAGES_BY_SERVICE_ORDER_ID,
  GET_MESSAGE_SUBJECT_OPTIONS,
  GET_ALL_NOTES,
  UPDATE_NOTE,
} from './actions'
import { List, Map } from 'immutable'

const INITIAL_STATE = Map({
  messages: Map(),
  messagesSubjectOptions: List(),
  currentMessage: List(),
  allNotes: Map(),
})

export default createReducer(INITIAL_STATE, {
  [GET_MESSAGE_OPTIONS.FULFILLED]: (state, { payload }) => state.set('messages', Map(payload)),

  [GET_MESSAGE_SUBJECT_OPTIONS.FULFILLED]: (state, { payload }) =>
    state.set('messagesSubjectOptions', List(payload)),

  [GET_MESSAGES_BY_SERVICE_ORDER_ID.FULFILLED]: (state, { payload }) =>
    state.set('currentMessage', List(payload)),

  [GET_ALL_NOTES.FULFILLED]: (state, { payload }) => state.set('allNotes', Map(payload)),

  [UPDATE_NOTE.FULFILLED]: (state, { payload }) => {
    return state.updateIn(['allNotes'], notes =>
      notes.withMutations(notesMap => {
        const noteTypes = Object.keys(notesMap.toJS())

        noteTypes.forEach(key => {
          const notesArray = notesMap.get(key)
          const index = notesArray.findIndex(note => note.id === payload.id)

          if (index !== -1) {
            if (key === 'important' && !payload.isImportant) {
              notesMap.deleteIn([key, index])
            } else {
              const note = notesArray[index]
              notesMap.setIn([key, index], { ...note, ...payload })
            }
          }

          if (index === -1 && key === 'important' && payload.isImportant) {
            notesMap.updateIn([key], importantNotes => [...importantNotes, payload])
          }
        })
      })
    )
  },
})
