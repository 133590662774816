import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  content: {
    width: '100%',
    padding: spacing(32, 50),
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    maxWidth: 1000,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(40, 50),
  },
  formControl: {
    width: '100%',
    gap: spacing(8),
    display: 'flex',
    justifyContent: 'center',
  },
  fillWidth: {
    width: '100%',
  },
  inputWrapper: {
    display: 'flex',
    columnGap: 'inherit',
    alignItems: 'center',
  },
  inputLabel: {
    fontSize: spacing(10.5),
  },
  required: {
    color: Theme.Colors.Red.Base,
    marginLeft: spacing(4),
  },
  checkBoxLabel: {
    '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
      color: '#9F9F9F',
    },
  },
  customAsterisk: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk.MuiFormLabel-asterisk': {
      color: Theme.Colors.Red.Base,
    },
  },
  contentState: {
    width: '100%',
    '& .MuiChip-deleteIcon': {
      color: 'white !important',
    },
  },
  customTag: {
    color: 'white',
    fontSize: spacing(12),
    lineHeight: spacing(20),
    backgroundColor: palette.primary.main,
  },
  errorMessage: {
    color: '#C90000 !important',
    margin: 0,
    fontSize: spacing(10.5),
    fontFamily: 'Roboto',
    height: spacing(10),
  },
  selectHeightCompensation: {
    marginBottom: spacing(-2),
  },
  sectionTitle: {
    fontSize: spacing(22),
    fontWeight: 500,
    lineHeight: spacing(20),
    color: Theme.Colors.Primary.Base,
  },
  autoCompletePointer: {
    '& .MuiAutocomplete-input': {
      cursor: 'pointer',
    },
  },
  autoCompleteDisabled: {
    '& .MuiAutocomplete-input': {
      cursor: 'not-allowed',
    },
  },
}))
