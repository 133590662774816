import { defineAction } from '_utils/redux'
import * as optionsTypeServices from '_services/options-type'

export const GET_OPTIONS_TYPE_DELAY = defineAction('GET_OPTIONS_TYPE_DELAY')
export const GET_OPTIONS_REASON_DELAY = defineAction('GET_OPTIONS_REASON_DELAY')

export const getOptionsTypesDelay = () => (dispatch, getState) => {
  const data = optionsTypeServices.getOptionsTypesDelay(getState().user.authToken)
  dispatch({
    type: GET_OPTIONS_TYPE_DELAY.ACTION,
    payload: data,
  })
  return data
}

export const getOptionsReasonDelay = () => (dispatch, getState) => {
  const data = optionsTypeServices.getOptionsReasonDelay(getState().user.authToken)
  dispatch({
    type: GET_OPTIONS_REASON_DELAY.ACTION,
    payload: data,
  })
  return data
}
