import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'

import Button from '_components/button'

import useStyles from './styles'

const ActionModal = ({ handleModal, children, handleSubmit, disabledSubmit, isLoading }) => {
  const styles = useStyles()

  return (
    <Dialog open onClose={handleModal} className={classnames(styles.modalContainer)}>
      <DialogContent className={classnames(styles.content)}>{children}</DialogContent>
      <DialogActions className={classnames(styles.actions)}>
        <Button
          disabled={disabledSubmit}
          className={classnames(styles.textButton)}
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          isLoading={isLoading}
        >
          SIM
        </Button>
        <Button
          className={classnames(styles.textButton, styles.textButtonActive)}
          onClick={handleModal}
          color="primary"
        >
          NÂO
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ActionModal.propTypes = {
  children: PropTypes.node.isRequired,
  disabledSubmit: PropTypes.bool,
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
}

ActionModal.defaultProps = {
  handleSubmit: () => {},
  disabledSubmit: false,
  isLoading: false,
}
export default React.memo(ActionModal)
