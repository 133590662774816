import { makeStyles } from '@material-ui/styles'

export default makeStyles((/* { spacing, palette } */) => ({
  subjectField: {
    width: '200px',
    maxHeight: '200px',
    fontSize: '20px',
    color: '#B4B4B4',

    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  accordion: {
    display: 'grid',
  },
}))
