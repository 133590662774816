import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  downloadButton: {
    border: '1px solid rgba(180, 180, 180, 1)',
  },
  loader: {
    width: 'auto',
  },
  loaderOverlay: {
    top: 0,
    left: 0,
    backgroundColor: `rgba(0, 0, 0, 0.1)`,
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 99,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
