import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  titleBlue: {
    color: '#1671ED',
    cursor: 'move',
  },
  dialog: {
    marginTop: '6%',
    height: '68%',
  },
  container: {
    display: 'flex',
    width: '1100px',
    maxWidth: '1100px',
    height: '100%',
    padding: '20px',
    flexDirection: 'column',
    borderTop: '2px solid #E3E3E3',
    overflowX: 'hidden',
  },
  dialogAction: {
    padding: '20px 30px',
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0 8px 8px 0',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  statusField: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    height: '40px',
    width: 'auto',
    padding: '10px 16.5px',
  },
  footer: {
    padding: '30px 30px 25px 0px',
  },
  icon: {
    fontSize: '22px',
    paddingRight: '2px',
    paddingBottom: '4px',
  },
  empty: {
    height: '100%',
    width: '100%',
    textAlign: 'center',
  },
  textHeader: {
    fontSize: '20px',
    color: '#1671ED',
    marginTop: '5px',
    textAlign: 'center',
    lineHeight: '32px',
  },
  textSubheader: {
    fontSize: '16px',
    color: '#696969',
    marginTop: '10px',
    textAlign: 'center',
  },
  muiButton: {
    height: '40px',
    width: 'auto',
    padding: '10px 16.5px',
    borderRadius: '20px',
    textTransform: 'none',
    fontWeight: '500',
  },
  muiArrow: {
    height: '40px',
    width: 'auto',
    borderRadius: '20px',
  },
}))
