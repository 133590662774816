import React, { useCallback, useMemo, useState } from 'react'
import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  IconButton,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import Filter from './filter'
import TablePaginationActions from './table-pagination-actions'
import useStyles from './styles'

const Table = ({
  className,
  total,
  handleChangePage,
  handleRowClick,
  handleChangeRowsPerPage,
  headers,
  isLoading,
  onRequestSort,
  order,
  orderBy,
  page,
  renderTableCell,
  rows,
  rowsPerPage,
  tableClassName,
  handleApplyFilter,
  loadedPages,
  handleFilterOption,
  filter,
  handleSearch,
}) => {
  const styles = useStyles()

  const [filterSelected, setFilterSelected] = useState()

  const handleChangeNewPage = useCallback(
    (event, newPage) => {
      handleChangePage(newPage - 1)
    },
    [handleChangePage]
  )

  const handleRowsPerPage = useCallback(
    event => {
      handleChangeRowsPerPage(+event.target.value)
    },
    [handleChangeRowsPerPage]
  )
  const renderLabelDisplayedRows = useCallback(
    ({ from, to, count: totalCount }) => `Mostrando ${from}-${to} de ${totalCount} itens`,
    []
  )

  const createSortHandler = useCallback(
    property => event => {
      onRequestSort(event, property)
    },
    [onRequestSort]
  )

  const handleOpenFilter = useCallback(
    name => () => {
      setFilterSelected(filterSelected === name ? '' : name)
    },
    [filterSelected]
  )
  const applyFilter = useCallback(() => {
    handleApplyFilter()
    setFilterSelected('')
  }, [handleApplyFilter])

  const renderTableBody = useCallback(() => {
    const pageIndex = loadedPages
      .sort((a, b) => a - b)
      .findIndex(loadedPage => loadedPage === page + 1)

    const emptyRows = Math.abs(rowsPerPage * loadedPages.size - rows.length)
    if (emptyRows > 0 && Array.isArray(rows)) {
      Array(emptyRows)
        .fill(0)
        .forEach(() => {
          rows?.push(undefined)
        })
    }
    const rowSliced = rows?.slice(pageIndex * rowsPerPage, pageIndex * rowsPerPage + rowsPerPage)
    return rowSliced.map(row => {
      return row ? (
        <>
          <TableRow
            className={styles.tableRow}
            hover
            key={`table-row-${row.id}`}
            onClick={handleRowClick}
            id={row.id}
            name={row.id}
          >
            {headers.map(({ id, minWidth, maxWidth }) => (
              <TableCell
                style={{ minWidth, maxWidth }}
                className={classnames(styles.cell)}
                key={`cell-${row.id}-${id}`}
              >
                {renderTableCell(row, id)}
              </TableCell>
            ))}
          </TableRow>
        </>
      ) : (
        ''
      )
    })
  }, [
    handleRowClick,
    headers,
    loadedPages,
    page,
    renderTableCell,
    rows,
    rowsPerPage,
    styles.cell,
    styles.tableRow,
  ])

  const renderTableHeader = useMemo(() => {
    return headers?.map(header => {
      if (header.sortable) {
        return (
          <TableCell
            key={header.id}
            className={styles.cellHead}
            style={{ minWidth: header.minWidth, maxWidth: header.maxWidth }}
          >
            <TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : 'asc'}
              onClick={createSortHandler(header.id)}
            >
              {header.label}
            </TableSortLabel>
          </TableCell>
        )
      }

      if (header?.filterOptions) {
        return (
          <TableCell
            key={header.id}
            className={styles.cellHead}
            style={{ minWidth: header.minWidth }}
          >
            <Grid className={styles.filter}>
              {header.label}
              <IconButton
                aria-label="filter"
                className={styles.filterIcon}
                onClick={handleOpenFilter(header.id)}
              >
                {filterSelected === header.id ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </IconButton>
              {filterSelected === header.id && (
                <Filter
                  open={filterSelected === header.id}
                  options={header.filterOptions}
                  value={header.filterName}
                  search={header.search}
                  placeholder={header?.placeholder}
                  handleModal={handleOpenFilter(header.id)}
                  handleApplyFilter={applyFilter}
                  handleOption={handleFilterOption}
                  filter={filter}
                  handleSearch={handleSearch(header.id)}
                />
              )}
            </Grid>
          </TableCell>
        )
      }

      return (
        <TableCell
          key={header?.id}
          className={styles.cellHead}
          style={{ minWidth: header?.minWidth }}
        >
          {header.label}
        </TableCell>
      )
    })
  }, [
    applyFilter,
    createSortHandler,
    filter,
    filterSelected,
    handleFilterOption,
    handleOpenFilter,
    handleSearch,
    headers,
    order,
    orderBy,
    styles.cellHead,
    styles.filter,
    styles.filterIcon,
  ])

  return (
    <TableContainer component="div" className={classnames(styles.container, className)}>
      <MuiTable
        className={classnames(styles.table, tableClassName)}
        aria-label="Tabela de gestão de chamados"
      >
        <TableHead>
          <TableRow className={styles.tableRow}>{renderTableHeader}</TableRow>
        </TableHead>

        {isLoading ? (
          <Grid
            component="tbody"
            container
            justify="center"
            alignItems="center"
            className={styles.loading}
          >
            <CircularProgress size={40} />
          </Grid>
        ) : (
          <TableBody>{renderTableBody()}</TableBody>
        )}
      </MuiTable>
      {!isLoading && (
        <TablePagination
          labelRowsPerPage="Linhas por páginas"
          className={styles.pagination}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={renderLabelDisplayedRows}
          page={page}
          onChangePage={handleChangeNewPage}
          onChangeRowsPerPage={handleRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </TableContainer>
  )
}

Table.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      minWidth: PropTypes.number,
    })
  ).isRequired,
  handleRowClick: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  renderTableCell: PropTypes.func,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  tableClassName: PropTypes.string,
  page: PropTypes.number.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleApplyFilter: PropTypes.func,
  handleFilterOption: PropTypes.func,
  filter: PropTypes.shape({}),
  handleSearch: PropTypes.func,
  loadedPages: PropTypes.arrayOf(PropTypes.shape({})),
}

Table.defaultProps = {
  className: '',
  renderTableCell: (value, key) => value[key],
  onRequestSort: () => {},
  orderBy: '',
  tableClassName: '',
  order: 'asc',
  isLoading: false,
  handleApplyFilter: () => {},
  handleFilterOption: () => {},
  filter: [],
  handleSearch: () => {},
  loadedPages: [],
}

export default React.memo(Table)
