import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from '_/hooks/use-toast'

import { Grid } from '@mui/material'
import { Datagrid } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'

import { profileColumns, TABLE_SX } from './constants'
import { navigate } from '@reach/router'
import { ROUTES } from '_/utils/constants'
import { getProfileList } from '_/modules/profile/actions'
import { getProfilesSelector } from '_/modules/profile/selectors'

export function Profiles() {
  const dispatch = useDispatch()
  const styles = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const profiles = useSelector(getProfilesSelector)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [orderBy, setOrderBy] = useState('')
  const [formStateDefault, setFormStateDefault] = useState(profiles?.results || [])

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('permissionName')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const { showToast } = useToast()

  const getProfiles = useCallback(() => {
    setIsLoading(true)

    return dispatch(getProfileList({ pageSize, page: page + 1, orderBy }))
      .then(data => {
        setFormStateDefault(data?.results)
        return setIsLoading(false)
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
        return setIsLoading(false)
      })
  }, [dispatch])

  const handleCellClick = useCallback(({ field, row }) => {
    if (field !== 'desc') {
      navigate(`${ROUTES.PROFILES}/${field}/${row?.id}`)
    }
  }, [])

  const goBack = () => {
    window?.history?.length <= 2 ? navigate(`/`) : navigate(-1)
  }

  // On page first load
  useEffect(() => {
    getProfiles()
  }, [])

  return (
    <Grid className={styles.page}>
      <HeaderTitle title="Permissões de acesso a dados do Perfil" backButtonAction={goBack} />
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'permissionName', sort: 'asc' }],
              },
            }}
            loading={isLoading}
            rows={formStateDefault}
            columns={profileColumns()}
            onCellClick={handleCellClick}
            rowCount={profiles?.count || 0}
            sx={TABLE_SX}
            noRowsLabel="Nenhum resultado encontrado"
            paginationMode="server"
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            onSortModelChange={handleOrderBy}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
