import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },

  content: {
    display: 'flex',
    gap: '25px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    padding: spacing(32, 50),
  },

  coverage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  typography: {
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontWeight: '500',
    fontSize: Theme.Typography.FontSize.XSmall,
    color: Theme.Colors.Grayscale.Eighty,
  },
  textField: {
    width: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '10px',
  },
  inputLabel: {
    fontWeight: Theme.Typography.FontWeight.XSmall,
    fontSize: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '15px',
  },

  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  icon: {
    fontSize: '80px',
    marginBottom: '60px',
    '@media (max-width: 768px)': {
      fontSize: '60px',
      marginBottom: '40px',
    },
    '@media (max-width: 480px)': {
      fontSize: '40px',
      marginBottom: '30px',
    },
  },
  specialRule: {
    marginTop: '180px',
    '@media (max-width: 768px)': {
      marginTop: '120px',
    },
    '@media (max-width: 480px)': {
      marginTop: '80px',
    },
  },
  link: {
    textDecorationLine: 'underline',
    color: '#1671ED',
    marginLeft: spacing(4),

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  },
  tooltipIcon: {
    fontSize: '2.4rem',
    fontWeight: '500',
    lineHeight: '2.4rem',
    fill: palette.gray[64],
  },
  tooltipText: {
    fontSize: '1.4rem',
    color: 'white',
    textAlign: 'right',
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    width: '700px',
    height: '70px',
    padding: '6px 16px',
    borderRadius: '5px',
    border: '4px solid #1671ED',
    borderTop: '0px',
    borderRight: '0px',
    borderLeft: '0px',
    backgroundColor: '#ECF4FE',
  },
  circleIcon: {
    marginRight: '15px',
  },
}))
